<template>
    <v-ons-dialog
        :visible.sync="visible"
        cancelable
    >
        <v-ons-toolbar inline="true">
            <div class="center">{{ title }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="close" title="Отмена">
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" style="top: 56px; bottom: 0;">
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list
                v-show="templates.length > 0"
                :style="{
                    maxHeight: ($window.height - (allowSelectGroup ? 240 : 110)) + 'px',
                    overflowY: 'auto'
                }"
            >
                <div v-for="template in templates" :key="template.id">
                    <v-ons-list-item @click="onClickGroup(template)">
                        <div class="left" style="margin-right: 10px; min-width: 0;">
                            <v-ons-radio
                                v-model="selectedTemplate"
                                :input-id="'asTmp-' + groupId +  'radio-' + template.id"
                                :value="template.id"
                            ></v-ons-radio>
                        </div>

                        <label :for="'asTmp-' + groupId +  'radio-' + template.id" class="label-reset center">
                            {{ template.name }}
                        </label>
                    </v-ons-list-item>

                    <div v-if="template.id === 'myproducts' && template.groups.length > 0" style="margin-left: 27px;">
                        <v-ons-list-item v-for="group in template.groups" :key="group.id">
                            <div class="left" style="margin-right: 10px; min-width: 0;">
                                <v-ons-checkbox
                                    v-model="selectedGroups"
                                    :input-id="'asTmp-' + groupId +  'radio-' + group.id"
                                    :value="group.id"
                                    :disabled="selectedTemplate !== 'myproducts'"
                                ></v-ons-checkbox>
                            </div>

                            <label :for="'asTmp-' + groupId +  'radio-' + group.id" class="label-reset center">
                                {{ group.name }}
                            </label>
                        </v-ons-list-item>
                    </div>
                </div>
            </v-ons-list>

            <div v-show="templates.length === 0">
                <p style="line-height: 216px; text-align: center; font-size: 20px; color: #a8a8a8;">Список пуст</p>
            </div>

            <v-ons-list v-show="allowSelectGroup">
                <v-ons-list-header>Группировать по:</v-ons-list-header>

                <v-ons-list-item v-for="(label, type) in typesGrouping" :key="type">
                    <div class="left" style="margin-right: 10px; min-width: 0;">
                        <v-ons-radio
                            :input-id="'tmp-' + groupId + '-radio-' + type"
                            :value="type"
                            :disabled="!selectedTemplate && type === 'byUserGroup'"
                            v-model="selectedGroupBy"
                        ></v-ons-radio>
                    </div>

                    <label :for="'tmp-' + groupId + '-radio-' + type" class="label-reset center">
                        {{ label }}
                    </label>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-row class="text-center">
                <v-ons-col width="50%">
                    <v-ons-button
                        modifier="quiet"
                        style="margin: 5px 0;"
                        @click="uncheckAll"
                    >Сбросить
                    </v-ons-button>
                </v-ons-col>

                <v-ons-col width="50%">
                    <v-ons-button
                        style="margin: 5px 0;"
                        @click="choose"
                    >Выбрать
                    </v-ons-button>
                </v-ons-col>
            </v-ons-row>
        </div>
    </v-ons-dialog>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Привязать к шаблону',
        },
        assignedTemplates: {
            type: Array,
            default() {
                return [];
            },
        },
        allowSelectGroup: {
            type: Boolean,
            default: false,
        },
        groupedBy: {
            type: String,
        },
        url: {
            type: String,
            default: '/api/user-products/templates',
        },
    },
    data() {
        return {
            loading: true,
            typesGrouping: {
                'byUserGroup': 'группам товара пользователя',
                'byCatalogGroup': 'разделам прайса',
            },

            selectedGroupBy: null,
            visible: false,
            templates: [],
            groupId: null,

            selectedTemplate: null,
            selectedGroups: [],
        }
    },
    created() {
        this.groupId = this.getUniqueId();
    },
    methods: {
        uncheckAll() {
            this.selectedTemplate = null;
            this.selectedGroups = [];

            this.choose();
        },
        choose() {
            this.visible = false;

            this.$emit('choose', this.selected);
            this.$emit('setGroupBy', this.selectedGroupBy);
        },
        onClickGroup(group) {
            if (group.id !== 'myproducts') {
                return;
            }

            this.selectedGroups = this.myProductsGroups.map(t => t.id)
        },
        close() {
            this.visible = false;
        },
        show() {
            this.load();
            this.visible = true;

            this.selectedTemplate = null;
            this.selectedGroups = this.myProductsGroups.map(t => t.id)

            if (this.templates.length === 1) {
                this.selectedTemplate = this.templates[0].id;
            }

            if (this.assignedTemplates && this.assignedTemplates.length > 0) {
                let groups = this.assignedTemplates.filter(t => t.startsWith('grp-'));

                if (groups.length > 0) {
                    this.selectedTemplate = 'myproducts';
                    this.selectedGroups = groups;
                } else {
                    this.selectedTemplate = this.assignedTemplates[0];
                }
            }

            if (this.groupedBy) {
                this.selectedGroupBy = this.groupedBy;
            }

            if (!this.selectedTemplate) {
                this.selectedGroupBy = 'byCatalogGroup';
            }
        },
        load() {
            this.loading = true;

            return this.$http.get(this.url + '/assign-list').then(response => {
                this.templates = response.data;

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.loading = false;

                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
    watch: {
        selectedTemplate() {
            if (!this.selectedTemplate) {
                this.selectedGroupBy = 'byCatalogGroup';
                return;
            }

            this.selectedGroupBy = 'byUserGroup';
        },
    },
    computed: {
        selected() {
            if (this.selectedTemplate === 'myproducts') {
                let myproducts = this.templates.find(g => g.id === 'myproducts');

                if (this.selectedGroups.length === this.myProductsGroups.length) {
                    return [myproducts];
                }

                return this.myProductsGroups.filter(t => this.selectedGroups.includes(t.id));
            }

            return this.templates.filter(g => g.id === this.selectedTemplate);
        },
        myProductsGroups() {
            let myproducts = this.templates.find(g => g.id === 'myproducts');
            if (!myproducts || !myproducts.groups) {
                return [];
            }

            return myproducts.groups;
        },
    },
};
</script>