var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("div", { staticClass: "login-page" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.view == "error",
                expression: "view == 'error'"
              }
            ],
            staticClass: "text-center"
          },
          [
            _c("v-ons-card", [
              _vm._v(
                "\n                Ссылка для смены пароля неправильна или устарела. Пожалуйста, попробуйте запросить новую ссылку.\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "v-ons-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "login" })
                  }
                }
              },
              [_vm._v("Назад")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.view == "password",
                expression: "view == 'password'"
              }
            ],
            staticClass: "form"
          },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("v-ons-password", {
                  attrs: { name: "password", placeholder: "Новый пароль" },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-ons-button", { on: { click: _vm.submit } }, [
                  _vm._v("Готово")
                ])
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.view == "sended",
                expression: "view == 'sended'"
              }
            ],
            staticClass: "text-center"
          },
          [
            _c("v-ons-card", [
              _vm._v(
                "\n                Ваш пароль был успешно изменен.\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "v-ons-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "login" })
                  }
                }
              },
              [_vm._v("Ok")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        {
          attrs: { visible: _vm.loading },
          on: {
            "update:visible": function($event) {
              _vm.loading = $event
            }
          }
        },
        [
          _c(
            "v-ons-row",
            { attrs: { "vertical-align": "center" } },
            [
              _c(
                "v-ons-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { width: "70px" }
                },
                [
                  _c("v-ons-progress-circular", {
                    attrs: { indeterminate: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-col", [
                _c("div", { staticStyle: { "line-height": "100px" } }, [
                  _vm._v("Загрузка...")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6567a23a", { render: render, staticRenderFns: staticRenderFns })
  }
}