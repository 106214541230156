<template>
    <v-ons-page class="report-deletions-from-orders" >
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Вычерки из заявок клиентов</div>

            <div class="right" >
                <v-ons-toolbar-button v-show="isGenerated && report.length > 0" @click="print">
                    <v-ons-icon icon="md-print"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-show="!isGenerated" >
                <ons-list>
                    <ons-list-header>Период</ons-list-header>
                    <ons-list-item>
                        <div class="wrap xl-gutter-8" >
                            <div class="col xl-1-2" >
                                <v-input-datepicker
                                    v-model="filters.dateFrom"
                                    :highlighted="highlightedDatepicker"
                                    placeholder='От'
                                />
                            </div>

                            <div class="col xl-1-2" >
                                <v-input-datepicker
                                    v-model="filters.dateTo"
                                    :highlighted="highlightedDatepicker"
                                    placeholder='До'
                                />
                            </div>
                        </div>
                    </ons-list-item>

                    <ons-list-item>
                        <label for="showEntity" class="center label-reset" >
                            Показать клиентов
                        </label>
                        <div class="right">
                            <v-ons-checkbox
                                v-model="filters.showEntity"
                                input-id="showEntity"
                                style="vertical-align: middle; margin: 0 12px 0 0;"
                            ></v-ons-checkbox>
                        </div>
                    </ons-list-item>

                    <ons-list-item v-if="$user.isAdmin">
                        <div class="center">
                            Менеджеры
                            <span v-show="filters.managers.length > 0" >({{ filters.managers.length }})</span>
                        </div>
                        <div class="right">
                            <v-ons-button @click="chooseManagers" >Выбрать</v-ons-button>
                        </div>
                    </ons-list-item>
                </ons-list>

                <div style="margin-top: 10px; text-align: center" >
                    <v-ons-button @click="generate" >
                        Сформировать
                    </v-ons-button>
                </div>
            </div>

            <div v-show="isGenerated" >
                <div v-if="report.length > 0" >
                    <div style="text-align: center; margin: 5px 0;" >
                        {{ periodLabel }}
                    </div>

                    <table class="table">
                        <tbody>
                            <tr v-for="item in report" :class="'tr-' + item.type + (item.level ? ' level-' + item.level : '')" >
                                <div v-frag v-if="item.type === 'manager'" >
                                    <td colspan="2">
                                        Менеджер:
                                        {{ item.name }}
                                        {{ item.email }}
                                    </td>
                                    <td>
                                        {{ item.totalFormatted }}
                                    </td>
                                </div>

                                <div v-frag v-if="item.type === 'user'" >
                                    <td colspan="2">
                                        Клиент:
                                        #{{ item.id }}
                                        {{ item.email }}
                                    </td>
                                    <td>
                                        {{ item.totalFormatted }}
                                    </td>
                                </div>

                                <div v-frag v-if="item.type === 'product'" >
                                    <td>{{ item.name }}</td>
                                    <td class="col-count">
                                        {{ item.count }}
                                        {{ item.unit }}
                                    </td>
                                    <td>
                                        {{ item.totalFormatted }}
                                    </td>
                                </div>

                                <div v-frag v-if="item.type === 'total'" >
                                    <td colspan="2">
                                        Общий итог:
                                    </td>
                                    <td>
                                        {{ item.totalFormatted }}
                                    </td>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-show="report.length === 0" style="text-align: center; font-size: 15px; margin-top: 50px" >Ничего не найдено</div>
            </div>
        </div>

        <v-list-dialog ref="managersDialog" multiple="true" allowselectall="true" title="Менеджеры" :items="managers" />
    </v-ons-page>
</template>

<script>

import InputDatepicker from '~/component/InputDatepicker'
import ListDialog from "~/component/ListDialog";

export default {
    data() {
        return {
            isGenerated: false,
            loading: false,
            filters: {
                showEntity: false,
                dateFrom: '',
                dateTo: '',
                managers: [],
            },
            urlRequest: null,
            report: [],
            managers: [],
            highlightedDatepicker: {
                dates: [new Date()],
            },
        }
    },
    created() {
        this.$http.get('/api/manager/users/managers').then(response => {
            this.managers = response.data.map(i => {
                return {
                    id: i.id,
                    name: i.name + ' ' + i.email,
                };
            });
        });
    },
    methods: {
        generate() {
            if (!this.filters.dateFrom) {
                this.$ons.notification.toast({
                    message: 'Укажите начало периода отгрузки',
                    buttonLabel: 'OK'
                });
                return;
            }

            if (this.$user.isAdmin && this.filters.managers.length === 0) {
                this.$ons.notification.toast({
                    message: 'Выберите менеджера',
                    buttonLabel: 'OK'
                });
                return;
            }

            this.loading = true;

            this.$http.get('/api/manager/reports/deletions-from-orders', {params: {filters: this.filters}}).then(response => {
                this.urlRequest = response.url;
                this.isGenerated = true;
                this.loading = false;
                this.report = response.data;
            }, () => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });

                this.loading = false;
            });
        },
        chooseManagers() {
            this.$refs.managersDialog.show({selected: this.filters.managers}).then(response => {
                this.filters.managers = response;
            });
        },
        print() {
            let parts = this.urlRequest.split('?');
            parts[0] = parts[0] + '-pdf';
            let url = parts.join('?');

            let win = window.open(`${url}&format=pdf&access-token=${this.$user.token}`, '_blank');
            win.focus();
        },
        reset() {
            this.isGenerated = false;
            this.report = [];
            this.urlRequest = null;
        },
        goBack() {
            if (this.isGenerated) {
                this.reset();
                return;
            }

            this.$router.push({
                name: this.$route.matched[this.$route.matched.length - 2].name
            });
        },
    },
    computed: {
        periodLabel() {
            let periodLabel = this.$moment(this.filters.dateFrom).format('L') + ' - ';

            if (this.filters.dateTo) {
                periodLabel += this.$moment(this.filters.dateTo).format('L');
            } else {
                periodLabel += '...';
            }

            return periodLabel;
        },
    },
    components: {
        'v-input-datepicker': InputDatepicker,
        'v-list-dialog': ListDialog,
    },
};
</script>