var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Отчёты")])
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        [
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/manager/reports/sales-for-period")
                }
              }
            },
            [_vm._v("\n            Обороты за период\n        ")]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/manager/reports/special-prices")
                }
              }
            },
            [_vm._v("\n            Отчёт по специальным ценам\n        ")]
          ),
          _vm._v(" "),
          _vm.$user.isAdmin
            ? _c(
                "ons-list-item",
                {
                  attrs: { modifier: "chevron longdivider", tappable: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/manager/reports/search-queries")
                    }
                  }
                },
                [_vm._v("\n            Отчёт по запросам\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    "/manager/reports/deletions-from-orders"
                  )
                }
              }
            },
            [_vm._v("\n            Вычерки из заявок клиентов\n        ")]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/manager/reports/emails")
                }
              }
            },
            [_vm._v("\n            Emails\n        ")]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/manager/reports/no-photos")
                }
              }
            },
            [_vm._v("\n            Нет фото\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bcd82c9a", { render: render, staticRenderFns: staticRenderFns })
  }
}