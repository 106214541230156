var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$router.push("/")
                  }
                }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "center",
            staticStyle: { "justify-content": "center" }
          },
          [
            _c(
              "div",
              {
                staticClass: "button--material",
                staticStyle: {
                  background: "#fff",
                  color: "#009688",
                  cursor: "pointer"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/register")
                  }
                }
              },
              [_vm._v("\n                Получить цены\n            ")]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$router.push("/login")
                  }
                }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-sign-in" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.itemsByRows.length > 0,
                expression: "itemsByRows.length > 0"
              }
            ],
            staticClass: "catalog-list-component"
          },
          [
            _c(
              "v-ons-list",
              { staticClass: "tile-catalog" },
              [
                _c("v-ons-lazy-repeat", {
                  ref: "lazyRepeat",
                  attrs: {
                    "render-item": _vm.renderItem,
                    length: _vm.itemsLength,
                    "calculate-item-height": _vm.calculateItemHeight
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.itemsByRows.length === 0,
                expression: "itemsByRows.length === 0"
              }
            ],
            staticStyle: {
              "text-align": "center",
              "font-size": "15px",
              "margin-top": "50px"
            }
          },
          [_vm._v("Ничего\n            не найдено\n        ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4d455003", { render: render, staticRenderFns: staticRenderFns })
  }
}