<template>
    <v-ons-page class="manager-user-settings" >
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Настройки: {{ user.email }}</div>

            <div class="right">
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-header>Тип цены:</v-ons-list-header>

            <div v-frag v-for="type in priceTypes" :key="type.id" modifier="longdivider" >
                <v-ons-list-item @click="selectedPriceType = type.id.toString()" >
                    <div class="left" >
                        <v-ons-radio
                            :value="type.id"
                            v-model="selectedPriceType"
                        >
                        </v-ons-radio>
                    </div>
                    <label class="center label-reset" :for="'radio-' + templateId +'-' + type.id" >
                        {{ type.name }}
                    </label>
                </v-ons-list-item>

                <v-ons-list-item style="padding-left: 55px" v-show="type.id == 1 && selectedPriceType == type.id" >
                    <div class="left" >
                        <v-ons-checkbox
                            input-id="hideGetDiscountButton"
                            v-model="hideGetDiscountButton"
                        ></v-ons-checkbox>
                    </div>
                    <label for="hideGetDiscountButton" class="center label-reset" >Скрыть "Получить скидку"</label>
                </v-ons-list-item>
            </div>

            <v-ons-list-item @click="goToSettingsCustomPriceType" >
                <div class="left" >
                    <v-ons-icon icon="md-arrow-right" size="23px"></v-ons-icon>
                </div>
                <div class="center">Индивидуальный тип цен</div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Способ отгрузки по умолчанию:</v-ons-list-header>

            <v-ons-list-item>
                <div class="select-material select--material select">
                    <select class="select-input select-input--material" v-model="defaultShippingMethod" >
                        <option :value="''" >не выбрано</option>

                        <option v-for="shippingMethod in getShippingMethods" :key="shippingMethod.id" :value="shippingMethod.id" >
                            {{ shippingMethod.name }}
                        </option>
                    </select>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-item class="list-header--material" >
                <div class="center" >
                    Заблокировать способ отгрузки:
                </div>
                <div class="right" >
                    <v-ons-button @click="addBlockedShippingMethods" :disabled="getShippingMethodsForBlock.length === 0" >
                        <v-ons-icon icon="md-plus"></v-ons-icon>
                    </v-ons-button>
                </div>
            </v-ons-list-item>

            <v-ons-list-item v-show="blockedShippingMethods" v-for="method in getBlockedShippingMethods" :key="method.id" >
                <div class="center" >
                    {{ method.name }}
                </div>
                <div class="right" >
                    <v-ons-button @click="removeBlockedShippingMethods(method)" style="margin-left: 5px;" modifier="outline" >
                        <v-ons-icon icon="md-close" ></v-ons-icon>
                    </v-ons-button>
                </div>
            </v-ons-list-item>

            <v-ons-list-item style="justify-content: center;" >
                <v-list-dialog
                    ref="shippingMethodsForBlockDialog"
                    title="Заблокировать способ отгрузки"
                    btnTitle="Блокировать"
                    :multiple="true"
                    :items="getShippingMethodsForBlock" />
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Способы оплаты по умолчанию</v-ons-list-header>

            <div v-if="entities.length > 0" >
                <v-ons-list-item v-for="entity in entities" :key="entity.id" >
                    <div class="center" >
                        {{ entity.name }} - {{ entity.defaultPaymentMethod }}
                    </div>
                    <div class="right" >
                        <v-ons-button style="padding: 0 10px;">
                            <v-ons-icon
                                @click="$router.push($router.currentRoute.path + '/entities/' + entity.id)"
                                icon="md-edit"
                                class="cursor-pointer list-item__icon"
                                size="14px"
                            ></v-ons-icon>
                        </v-ons-button>
                    </div>
                </v-ons-list-item>
            </div>
            <div v-else >
                <v-ons-list-item>
                    <div class="center" >
                        <v-ons-button @click="$router.push({name: 'manager-user-settings-entity-create'})" >Добавить юр. лицо</v-ons-button>
                    </div>
                </v-ons-list-item>
            </div>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

import ListDialog from '~/component/ListDialog'

export default {
    data() {
        return {
            userId: null,
            user: {},
            priceTypes: [],
            selectedPriceType: null,
            shippingMethods: [],
            defaultShippingMethod: '',
            blockedShippingMethods: [],
            defaultProviderEntity: '',
            providerEntities: [],
            hideGetDiscountButton: false,
            entities: [],
            templateId: null,
        };
    },
    created() {
        this.userId = this.$route.params.user_id;
        this.load();
        this.templateId = this.getUniqueId();

        this.$bus.$on('entities-load', this.entitiesLoad);
        this.$bus.$on('update-custom-price-type', this.load);
    },
    destroyed() {
        this.$bus.$off('entities-load', this.entitiesLoad);
        this.$bus.$off('update-custom-price-type', this.load);
    },
    methods: {
        load() {
            this.$http.get('/api/manager/users/' + this.$route.params.user_id).then(response => {
                this.user = response.data;
            });

            this.$http.get(`/api/manager/users/${this.userId}/settings`).then(response => {
                let data = response.data;

                this.priceTypes = data.priceTypes;
                this.shippingMethods = data.shippingMethods;
                this.hideGetDiscountButton = data.hideGetDiscountButton;
                this.paymentMethodList = data.paymentMethodList;

                this.entities = data.entities.map(e => {
                    let defaultPaymentMethod = this.paymentMethodList.find(p => p.id == e.default_payment_method);

                    defaultPaymentMethod = defaultPaymentMethod ? defaultPaymentMethod.name : 'не указан';

                    return {
                        id: e.id,
                        name: e.legal_name,
                        defaultPaymentMethod: defaultPaymentMethod,
                    }
                });

                this.$nextTick(() => {
                    this.defaultShippingMethod = data.defaultShippingMethod || '';
                    this.selectedPriceType = data.selectedPriceType.toString();
                    this.blockedShippingMethods = data.blockedShippingMethods || [];
                });
            });
        },
        entitiesLoad() {
            this.$http.get('/api/manager/users/'+ this.$route.params.user_id  +'/entities').then(response => {
                this.entities = response.data.map(e => {
                    let defaultPaymentMethod = this.paymentMethodList.find(p => p.id == e.default_payment_method);

                    defaultPaymentMethod = defaultPaymentMethod ? defaultPaymentMethod.name : 'не указан';

                    return {
                        id: e.id,
                        name: e.legal_name,
                        defaultPaymentMethod: defaultPaymentMethod,
                    }
                });
            });
        },
        pushSettings() {
            let settings = {
                price_type: this.selectedPriceType,
                default_shipping_method: this.defaultShippingMethod,
                blocked_shipping_methods: this.blockedShippingMethods,
                hide_get_discount_button: this.hideGetDiscountButton,
            };

            let data = {
                id: this.$route.params.user_id,
                settings: settings,
            };

            return this.$http.post(`/api/manager/users/${this.userId}/settings`, data);
        },
        save() {
            this.pushSettings().then(() => {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });

                this.$router.goRouteBack();
            }, () => {
                this.$ons.notification.toast({
                    message: 'Не удалось сохранить.',
                });
            });
        },
        addBlockedShippingMethods() {
            this.$refs.shippingMethodsForBlockDialog.show().then(response => {
                response.forEach(i => {
                    this.blockedShippingMethods.push(i.toString());
                });
            });
        },
        removeBlockedShippingMethods(method) {
            this.blockedShippingMethods = this.blockedShippingMethods.filter(i => i != method.id.toString());
        },
        goToSettingsCustomPriceType() {
            this.$router.push({name: 'manager-user-settings-custom-price-type'});
        },
    },
    computed: {
        getShippingMethods() {
            return this.shippingMethods
                .filter(i => {
                    return this.blockedShippingMethods.indexOf(i.id.toString()) === -1;
                });
        },
        getBlockedShippingMethods() {
            return this.blockedShippingMethods
                .map(i => {
                    return this.shippingMethods.find(ii => i == ii.id)
                });
        },
        getShippingMethodsForBlock() {
            return this.shippingMethods
                .filter(i => this.blockedShippingMethods.indexOf(i.id.toString()) === -1);
        },
    },
    watch: {
        blockedShippingMethods: {
            handler() {
                if (!this.defaultShippingMethod) {
                    return;
                }

                let availMethods = this.getShippingMethods.map(i => i.id.toString());
                if (availMethods.indexOf(this.defaultShippingMethod.toString()) === -1) {
                    this.defaultShippingMethod = '';
                }
            },

            deep: true,
        },
    },
    components: {
        'v-list-dialog': ListDialog,
    },
};
</script>