<template>
    <ons-list-item
        :key="product.id"
        :style="product.added ? 'background: #77B61F; color: #fff;' : ''"
        modifier="longdivider"
        tappable
        @click="choose(product)">

        <div class="center">
            <span class="list-item__title">{{ product.name }}</span>
            <span class="list-item__subtitle">
                Цена: {{ product.price }}
                Остаток: {{ (product.is_deleted && product.is_avail ? 'ждём' : product.rest) }}
            </span>
        </div>
    </ons-list-item>
</template>

<script>

export default {
    props: [
        'product',
    ],
    methods: {
        choose(product) {
            this.$emit('choose', product);
        }
    }
};
</script>