var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Склад: " + _vm._s(_vm.storage.name))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Сохранить" }, on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-checkbox", {
                staticStyle: {
                  "vertical-align": "middle",
                  margin: "0 12px 0 0"
                },
                attrs: { "input-id": "storage.is_active" },
                model: {
                  value: _vm.storage.is_active,
                  callback: function($$v) {
                    _vm.$set(_vm.storage, "is_active", $$v)
                  },
                  expression: "storage.is_active"
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "label-reset",
                  attrs: { for: "storage.is_active" }
                },
                [_vm._v("Активировать склад")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.storage.is_active,
              expression: "storage.is_active"
            }
          ]
        },
        [
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-checkbox", {
                staticStyle: {
                  "vertical-align": "middle",
                  margin: "0 12px 0 0"
                },
                attrs: { "input-id": "storage.is_default" },
                model: {
                  value: _vm.storage.is_default,
                  callback: function($$v) {
                    _vm.$set(_vm.storage, "is_default", $$v)
                  },
                  expression: "storage.is_default"
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "label-reset",
                  attrs: { for: "storage.is_default" }
                },
                [_vm._v("Склад по умолчанию")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-header", [_vm._v("Фактическое наименование")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "storage_real_name" },
                model: {
                  value: _vm.storage.real_name,
                  callback: function($$v) {
                    _vm.$set(_vm.storage, "real_name", $$v)
                  },
                  expression: "storage.real_name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-header", [_vm._v("Адрес склада")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "storage_address" },
                model: {
                  value: _vm.storage.address,
                  callback: function($$v) {
                    _vm.$set(_vm.storage, "address", $$v)
                  },
                  expression: "storage.address"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-header", [_vm._v("Графика самовывоза")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table", staticStyle: { margin: "0 16px" } },
            [
              _c(
                "tbody",
                _vm._l(_vm.weekdays, function(weekday) {
                  return _c("tr", [
                    _c("td", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(weekday.label) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v("\n                    с\n                    "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: weekday.from_time,
                            expression: "weekday.from_time"
                          }
                        ],
                        staticStyle: {
                          "text-align": "center",
                          margin: "0 auto",
                          "max-width": "100px"
                        },
                        attrs: { type: "time" },
                        domProps: { value: weekday.from_time },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(weekday, "from_time", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v("\n                    до\n                    "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: weekday.to_time,
                            expression: "weekday.to_time"
                          }
                        ],
                        staticStyle: {
                          "text-align": "center",
                          margin: "0 auto",
                          "max-width": "100px"
                        },
                        attrs: { type: "time" },
                        domProps: { value: weekday.to_time },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(weekday, "to_time", $event.target.value)
                          }
                        }
                      })
                    ])
                  ])
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c("v-ons-list-header", [_vm._v("Юр. лицо")]),
          _vm._v(" "),
          _vm._l(_vm.entities, function(entity, key) {
            return _c("v-ons-list-item", { key: entity.id }, [
              _c(
                "label",
                {
                  staticClass: "center label-reset",
                  attrs: { for: "storage-entity-" + key }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(entity.legal_name) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("v-ons-checkbox", {
                    staticStyle: {
                      "vertical-align": "middle",
                      margin: "0 12px 0 0"
                    },
                    attrs: {
                      value: entity.id,
                      "input-id": "storage-entity-" + key,
                      disabled: _vm.entities.length === 1
                    },
                    model: {
                      value: _vm.storage.entities,
                      callback: function($$v) {
                        _vm.$set(_vm.storage, "entities", $$v)
                      },
                      expression: "storage.entities"
                    }
                  })
                ],
                1
              )
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-35f2cfce", { render: render, staticRenderFns: staticRenderFns })
  }
}