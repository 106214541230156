<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>
            <div class="center">Получить скидку</div>
            <div class="right">
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon v-if="sending" icon="md-spinner" spin="true" ></v-ons-icon>
                    <span v-else style="font-size: 15px" >Отправить</span>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-card v-show="$user.discounts.getDiscount.enabled && !$user.params.contacts_sended" >
            Для получения скидки, вам нужно отправить ваши контакты и реквизиты. Ваш менеджер проверит данные и, если это возможно, активирует скидку (в некоторых случаях требуется заключение договора)
        </v-ons-card>

        <v-ons-card v-show="$user.discounts.getDiscount.enabled" >
            Если у вас возникли любые вопросы, <router-link to="/mymanager">свяжитесь с менеджером.</router-link> Для оперативного ответа пишите на почту или на любой месседжер.
        </v-ons-card>

        <v-ons-card v-show="$user.discounts.getDiscount.enabled && $user.params.contacts_sended" >
            Ваши данные отправлены.
        </v-ons-card>

        <v-ons-list>
            <v-ons-list-header>Имя</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="form.name" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Телефон</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="form.phone" type="tel" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Фактическое название торговой точки</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="form.actual_organisation_name" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Фактический адрес</v-ons-list-header>
            <v-ons-list-item>
                <v-address-form v-model="form.address" :title="'Фактический адрес'" ></v-address-form>
            </v-ons-list-item>

            <v-ons-list-header>Юр. название</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="form.legal_organisation_name" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>ИНН</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="form.inn" type="number" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

import AddressFormComponent from '../component/AddressFormComponent'

export default {
    data() {
        return {
            sending: false,
            form: {
                name: '',
                phone: '',
                actual_organisation_name: '',
                address: '',
                legal_organisation_name: '',
                inn: '',
            }
        }
    },
    created() {
        if (!this.$user.discounts.getDiscount.enabled) {
            this.$router.push('/');
        }

        this.$http.get('/api/settings/contacts-for-discounts').then(response => {
            this.form = response.data;
        });
    },
    methods: {
        save() {
            if (this.sending) {
                return;
            }

            this.sending = true;

            this.$http.post('/api/settings/contacts-for-discounts', this.form).then(() => {
                if (this.$user.discounts.getDiscount.enabled) {
                    this.sending = false;

                    if (typeof ym !== 'undefined') {
                        ym(52291717, 'reachGoal', 'CONTACTS_FOR_DISCOUNTS');
                    }

                    this.$user.params.contacts_sended = true;
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Ваши данные отправлены',
                        buttonLabel: 'OK',
                    });
                }
            }, response => {
                this.sending = false;

                if (response.status === 403) {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: response.data.message,
                        buttonLabel: 'OK',
                    });

                    return;
                }

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK',
                });
            });
        }
    },
    components: {
        'v-address-form': AddressFormComponent,
    }
};
</script>