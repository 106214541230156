var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSearchActive,
              expression: "!isSearchActive"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c("v-ons-back-button", {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.goBack.apply(null, arguments)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v("Выберите пользователей")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchActive } },
                [_c("v-ons-icon", { attrs: { icon: "md-search" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "toolbar-popover-menu",
                { attrs: { "popover-class": "stick-right" } },
                [
                  _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: "Опции просмотра",
                      icon: "md-format-list-bulleted"
                    },
                    on: { click: _vm.showViewParamsModal }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.save } },
                [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSearchActive,
              expression: "isSearchActive"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchDeactive } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery"
                }
              ],
              ref: "searchInput",
              staticStyle: {
                color: "#fff",
                background: "none",
                border: "none",
                height: "34px",
                "line-height": "34px",
                "border-bottom": "1px solid #fff"
              },
              domProps: { value: _vm.searchQuery },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchQuery = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.save } },
                [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.users.length > 0,
                  expression: "users.length > 0"
                }
              ]
            },
            [
              _vm._l(_vm.users, function(user, index) {
                return _c(
                  "div",
                  { key: user.id },
                  [
                    _c(
                      "ons-list-item",
                      {
                        attrs: { modifier: "longdivider" },
                        on: {
                          click: function($event) {
                            return _vm.toggleUser(user.id)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left",
                            staticStyle: {
                              "min-width": "40px",
                              "text-align": "center"
                            }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.choosed,
                                  expression: "choosed"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: user.id,
                                checked: Array.isArray(_vm.choosed)
                                  ? _vm._i(_vm.choosed, user.id) > -1
                                  : _vm.choosed
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.choosed,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = user.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.choosed = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.choosed = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.choosed = $$c
                                  }
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "center" },
                          [
                            _c("span", { staticClass: "list-item__title" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(user.email) +
                                  "\n                            "
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: user.user_type === "manager",
                                      expression: "user.user_type === 'manager'"
                                    }
                                  ],
                                  staticStyle: {
                                    "margin-left": "5px",
                                    color: "#ff5a00"
                                  }
                                },
                                [_vm._v("M")]
                              )
                            ]),
                            _vm._v(" "),
                            _c("v-list-item-subtitles", {
                              attrs: {
                                item: user,
                                fields: _vm.viewParams,
                                selected: _vm.$user.usersSelectedSubtitles
                              }
                            }),
                            _vm._v(" "),
                            user.managerLabel
                              ? _c(
                                  "span",
                                  { staticClass: "list-item__subtitle" },
                                  [
                                    _vm._v(
                                      "\n                            Менеджер: " +
                                        _vm._s(user.managerLabel) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    index == _vm.myUsersIndex - 1 && index !== 0
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "13px",
                              color: "#606061",
                              padding: "14px",
                              "border-bottom": "1px solid #eee"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Мои клиенты\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.page < _vm.pageCount,
                      expression: "page < pageCount"
                    }
                  ],
                  staticStyle: { "text-align": "center", margin: "10px 0" }
                },
                [
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { "min-width": "145px" },
                      on: { click: _vm.loadMore }
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loadingMore,
                              expression: "!loadingMore"
                            }
                          ]
                        },
                        [_vm._v("Показать ещё")]
                      ),
                      _vm._v(" "),
                      _c("v-ons-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loadingMore,
                            expression: "loadingMore"
                          }
                        ],
                        attrs: { size: "30px", spin: "", icon: "md-spinner" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.users.length === 0,
                  expression: "users.length === 0"
                }
              ],
              staticClass: "list-empty",
              style: { height: _vm.$window.height - 56 + "px" }
            },
            [_vm._v("\n            Ничего не найдено\n        ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "viewParamsModal",
        attrs: { multiple: "true", title: "Опции просмотра" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2f89948d", { render: render, staticRenderFns: staticRenderFns })
  }
}