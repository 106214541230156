var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Профиль")])
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: _vm.$user.isManager
                      ? "profile-manager-contacts"
                      : "profile-contacts"
                  })
                }
              }
            },
            [_vm._v("\n            Контакты\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isCustomer || _vm.$user.canSee("branches"),
                  expression: "$user.isCustomer || $user.canSee('branches')"
                }
              ],
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/profile/branches")
                }
              }
            },
            [_vm._v("\n            Филиалы\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isManager,
                  expression: "$user.isManager"
                }
              ],
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/profile/manager-entities")
                }
              }
            },
            [_vm._v("\n            Юридические лица\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isCustomer || _vm.$user.canSee("entities"),
                  expression: "$user.isCustomer || $user.canSee('entities')"
                }
              ],
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/profile/entities")
                }
              }
            },
            [_vm._v("\n            Юридические лица\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isCustomer,
                  expression: "$user.isCustomer"
                }
              ],
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/profile/workers")
                }
              }
            },
            [_vm._v("\n            Сотрудники\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.$user.isAdmin ||
                    _vm.$user.hasPermission("canManageCatalog"),
                  expression:
                    "$user.isAdmin || $user.hasPermission('canManageCatalog')"
                }
              ],
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/profile/manager-storages")
                }
              }
            },
            [_vm._v("\n            Склады\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6c727f42", { render: render, staticRenderFns: staticRenderFns })
  }
}