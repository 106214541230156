var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "address-form-component" },
    [
      _c("div", { staticClass: "address-form-component-wrap" }, [
        _c("div", { staticClass: "text-label", on: { click: _vm.open } }, [
          _vm._v("\n            " + _vm._s(_vm.fullAddress) + "\n        ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "copy-icon cursor-pointer",
            attrs: { title: "Скопировать адрес" },
            on: { click: _vm.copyAddress }
          },
          [_c("v-ons-icon", { attrs: { icon: "md-copy" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        {
          attrs: { visible: _vm.visible, modifier: "fullscreen" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "v-ons-page",
            [
              _c("v-ons-toolbar", [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c(
                      "v-ons-toolbar-button",
                      { on: { click: _vm.close } },
                      [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "center" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "v-ons-toolbar-button",
                      { on: { click: _vm.save } },
                      [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-ons-list",
                [
                  _c("v-ons-list-header", [_vm._v("Регион")]),
                  _vm._v(" "),
                  _c(
                    "v-ons-list-item",
                    [
                      _c("v-ons-input", {
                        model: {
                          value: _vm.oblast,
                          callback: function($$v) {
                            _vm.oblast = $$v
                          },
                          expression: "oblast"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-ons-list-header", [_vm._v("Населённый пункт")]),
                  _vm._v(" "),
                  _c(
                    "v-ons-list-item",
                    [
                      _c("v-ons-input", {
                        model: {
                          value: _vm.city,
                          callback: function($$v) {
                            _vm.city = $$v
                          },
                          expression: "city"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-ons-list-header", [_vm._v("Улица")]),
                  _vm._v(" "),
                  _c(
                    "v-ons-list-item",
                    [
                      _c("v-ons-input", {
                        model: {
                          value: _vm.street,
                          callback: function($$v) {
                            _vm.street = $$v
                          },
                          expression: "street"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-ons-list-header", [_vm._v("Дом")]),
                  _vm._v(" "),
                  _c(
                    "v-ons-list-item",
                    [
                      _c("v-ons-input", {
                        model: {
                          value: _vm.house,
                          callback: function($$v) {
                            _vm.house = $$v
                          },
                          expression: "house"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-ons-list-header", [_vm._v("Этаж")]),
                  _vm._v(" "),
                  _c(
                    "v-ons-list-item",
                    [
                      _c("v-ons-input", {
                        model: {
                          value: _vm.floor,
                          callback: function($$v) {
                            _vm.floor = $$v
                          },
                          expression: "floor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7ec454e0", { render: render, staticRenderFns: staticRenderFns })
  }
}