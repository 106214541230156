<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Связь с 1С</div>
            <div class="right" >
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content" >
            <v-ons-list>
                <div v-for="connnection in connections" >
                    <v-ons-list-header>
                        {{ connnection.entity.name }}
                        <br>
                        {{ connnection.branch.name }}
                    </v-ons-list-header>
                    <v-ons-list-item>
                        <v-ons-input v-model="connnection.code" type="text" ></v-ons-input>
                    </v-ons-list-item>
                </div>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            changed: false,
            loaded: false,
            loading: true,
            userId: null,

            user: {},
            connections: [],
        }
    },
    created() {
        this.userId = this.$route.params.user_id;

        let requests = [
            this.$http.get(`/api/manager/users/${this.userId}`),
            this.$http.get(`/api/manager/users/${this.userId}/onec-connections`),
        ];

        Promise.all(requests).then(responses => {
            this.user = responses[0].data;
            this.connections = responses[1].data;

            this.$nextTick(() => {
                this.loading = false;
                this.loaded = true;
            });
        }, () => {
            this.$nextTick(() => {
                this.loaded = true;
                this.loading = false;
            });

            this.$ons.notification.toast({
                buttonLabel: 'OK',
                message: 'Произошла ошибка.',
            });
        });
    },
    methods: {
        goBack() {
            if (!this.changed) {
                this.$router.goRouteBack();
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.goRouteBack();
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        save() {
            if (this.loading) {
                return;
            }

            this.$http.post(`/api/manager/users/${this.userId}/onec-connections`, {connections: this.connections}).then(() => {
                this.$router.goRouteBack();

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Связт с 1С сохранены',
                    buttonLabel: 'OK',
                });
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                    buttonLabel: 'OK',
                });
            });
        },
    },
    watch: {
        connections: {
            handler() {
                if (!this.loaded) {
                    return;
                }

                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>