<template>
    <v-ons-splitter-side
        swipeable
        width="200px"
        :collapse="$window.isDesktop ? 'portrait' : ''"
        side="left"
        :open.sync="open"
        class="sidebar"
        >
        <v-ons-page>
            <sidebar-list :type="userType" @click="onClick" ></sidebar-list>
        </v-ons-page>
    </v-ons-splitter-side>
</template>

<script>

import AdminList from './sidebarMenu/Admin'
import ManagerList from './sidebarMenu/Manager'
import CustomerList from './sidebarMenu/Customer'
import WorkerList from './sidebarMenu/Worker'
import DefaultList from './sidebarMenu/Default'

export default {
    data() {
        return {
            open: false,
        }
    },
    methods: {
        onClick(e) {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
    },
    computed: {
        userType() {
            if (this.$user.isAdmin) {
                return 'admin';
            }

            if (this.$user.isManager) {
                return 'manager';
            }

            if (this.$user.isCustomer) {
                return 'customer';
            }

            if (this.$user.isWorker) {
                return 'worker';
            }

            return 'default';
        }
    },
    components: {
        'sidebar-list': {
            render(createElement) {
                let params = {
                    on: {
                        click: () => this.$emit('click'),
                    },
                };

                if (this.type == 'admin') {
                    return createElement(AdminList, params);
                }

                if (this.type == 'manager') {
                    return createElement(ManagerList, params);
                }

                if (this.type == 'customer') {
                    return createElement(CustomerList, params);
                }

                if (this.type == 'worker') {
                    return createElement(WorkerList, params);
                }

                return createElement(DefaultList, params);
            },
            props: {
                type: {
                    type: String,
                }
            }
        },
    }
}
</script>
