<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">{{ user.email }}</div>

            <div class="right">
                <v-ons-toolbar-button v-show="!loading" @click="save" title="Сохранить" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <ons-list-item>
                    <label for="permissionsSeeReports" class="center label-reset" >
                        Отчёты
                    </label>
                    <div class="right">
                        <v-ons-checkbox
                            v-model="permissions.canSeeReports"
                            :value="true"
                            input-id="permissionsSeeReports"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>

                <ons-list-item>
                    <label for="permissionsProductLinks" class="center label-reset" >
                        Редактирование связей товаров и OOS
                    </label>
                    <div class="right">
                        <v-ons-checkbox
                            v-model="permissions.canManageProductLinks"
                            :value="true"
                            input-id="permissionsProductLinks"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>

                <ons-list-item>
                    <label for="permissionsCatalog" class="center label-reset" >
                        Настройка каталога
                    </label>
                    <div class="right">
                        <v-ons-checkbox
                            v-model="permissions.canManageCatalog"
                            :value="true"
                            input-id="permissionsCatalog"
                            :disabled="!permissions.canManageProductLinks"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>

                <ons-list-item>
                    <label for="permissionsPriceAndPhoto" class="center label-reset" >
                        Скачивание прайса и фото товара
                    </label>
                    <div class="right">
                        <v-ons-checkbox
                            v-model="permissions.canDownloadPriceAndPhoto"
                            :value="true"
                            input-id="permissionsPriceAndPhoto"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>

                <ons-list-item>
                    <label for="permissionsUpdateProduct" class="center label-reset" >
                        Редактирование карточки товара
                    </label>
                    <div class="right">
                        <v-ons-checkbox
                            v-model="permissions.canUpdateProduct"
                            :value="true"
                            input-id="permissionsUpdateProduct"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            changed: false,
            loaded: false,
            loading: true,
            userId: null,

            user: {},
            permissions: {
                canSeeReports: false,
                canManageProductLinks: false,
                canManageCatalog: false,
            },
        }
    },
    created() {
        this.userId = this.$route.params.user_id;

        let requests = [
            this.$http.get('/api/manager/users/' + this.userId),
            this.$http.get('/api/manager/users/'+ this.userId  +'/permissions'),
        ];

        Promise.all(requests).then(responses => {
            this.user = responses[0].data;
            this.permissions = responses[1].data;

            this.$nextTick(() => {
                this.loading = false;
                this.loaded = true;
            });
        }, () => {
            this.$nextTick(() => {
                this.loaded = true;
                this.loading = false;
            });

            this.$ons.notification.toast({
                buttonLabel: 'OK',
                message: 'Произошла ошибка.',
            });
        });
    },
    methods: {
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        save() {
            this.loading = true;

            this.$http.post('/api/manager/users/'+ this.userId  +'/permissions', {permissions: this.permissions}).then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Сохранено',
                    buttonLabel: 'OK',
                });
                this.$router.go(-1);
            }, () => {
                this.loading = false;
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
    },
    watch: {
        permissions: {
            handler() {
                if (!this.loaded) {
                    return;
                }

                if (!this.permissions.canManageProductLinks) {
                    this.permissions.canManageCatalog = false;
                }

                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>