<template>
    <v-ons-page>
        <v-ons-toolbar v-show="!hasChecked">
            <div class="left">
                <v-ons-back-button @click="onBack"></v-ons-back-button>
            </div>
            <div class="center">{{ template.name }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="$refs.assignTemplateModal.show()" :disabled="templates.length === 0">
                    <v-ons-icon icon="md-collection-item"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    title="Создать заказ"
                    :disabled="!hasPositions"
                    @click="createOrder"
                >
                    <v-ons-icon icon="md-shopping-cart"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    style="font-size: 28px"
                    :disabled="!hasPositions"
                    @click="openCatalog"
                >
                    <span class="font-rouble">o</span>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="hasChecked">
            <div class="left">
                <v-ons-toolbar-button @click="uncheckAll">
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center">Выбрано: {{ checkedCount }}</div>

            <div class="right">
                <v-ons-toolbar-button @click="$refs.copyToTemplateModal.show()">
                    <v-ons-icon icon="md-copy"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="$refs.moveToTemplateModal.show()">
                    <v-ons-icon icon="md-replay"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="removeChecked">
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}">
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <assign-template-modal
                ref="assignTemplateModal"
                :title="'Добавить из шаблона'"
                :url="entrypoint + '/templates'"
                @choose="onBindTemplate" />

            <div class="user-product-page" v-bind:style="{'padding-bottom': paddingBottom}"
                 style="position: relative;">
                <div class="positions">
                    <div class="tbody">
                        <v-position
                            v-for="position in positionsLocal"
                            :id="`template-${template.id}-position-${position.pos_id}`"
                            :key="position.posId"
                            :position="position"
                            @remove="onRemovePosition"
                            @update="onUpdatePosition"
                        />

                        <input-proposal-create
                            ref="inputProposal"
                            :scrollPageOnFocus="true"
                            :disableScrollPage="true"
                            :addedPositions="addedPositions"
                            :urlSearch="urlSearch"
                            :columns="$user.canSeePrice ? ['name', 'price'] : ['name']"
                            @choose="chooseProposal"
                        />
                    </div>
                </div>
            </div>
        </div>

        <templates-modal ref="moveToTemplateModal" @choose="moveToTemplate" :title="'Переместить в шаблон'"/>

        <templates-modal ref="copyToTemplateModal" @choose="copyToTemplate" :title="'Скопировать в шаблон'"/>

        <v-ons-fab v-show="hasCheckedOne" position="bottom left" @click="orderUp" style="bottom: 90px">
            <v-ons-icon icon="long-arrow-up"></v-ons-icon>
        </v-ons-fab>

        <v-ons-fab v-show="hasCheckedOne" position="bottom left" @click="orderDown">
            <v-ons-icon icon="long-arrow-down"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

import $ from 'jquery'
import TemplatesModal from './TemplatesModal'
import ListDialog from "~/component/ListDialog";
import UserProductPosition from '~/component/userProduct/Position'
import InputProposalCreate from '~/component/InputProposalCreate'
import AssignTemplateModal from '~/component/AssignTemplateModal'

export default {
    data() {
        return {
            loading: true,
            positions: [],
            timer: null,
            units: [],
            template: {},
            templates: [],
            paddingBottom: 0,
            entrypoint: '/api/user-products',
            hasChecked: false,
            hasCheckedOne: false,
            checkedCount: 0,
            urlSearch : null,
        }
    },
    created() {
        if (this.$route.meta.userProductEntrypoint) {
            let userProductEntrypoint = this.$route.meta.userProductEntrypoint;
            this.entrypoint = userProductEntrypoint.replace('$userId', this.$route.params.user_id)
        }

        if (this.$route.meta.userProductSearchEntrypoint) {
            let userProductSearchEntrypoint = this.$route.meta.userProductSearchEntrypoint;
            this.urlSearch = userProductSearchEntrypoint.replace('$userId', this.$route.params.user_id)
        }

        this.templateId = parseInt(this.$route.params.id);
        this.loadPositions();
        this.loadTemplates();

        this.$http.get('/api/product/get-units').then(response => {
            this.units = response.data;
        });

        this.paddingBottom = ($(window).height() - 90) + 'px';
    },
    methods: {
        onBack(e) {
            if (this.$route.params.back) {
                e.preventDefault();
                this.$router.go(-1);
            }
        },
        copyToTemplate(template) {
            let positions = this.positions
                .filter(i => i.isChecked)
                .map(i => {
                    i.isChecked = false;
                    return i.id
                })

            if (positions.length === 0) {
                return;
            }

            this.$http.post(
                this.entrypoint + '/templates/link-positions-to-template',
                {template: template.id, positions: positions}
            ).then(() => {
                this.loadPositions();
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Позиции скопированы в шаблон.',
                });
            }, () => {
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        moveToTemplate(template) {
            let positions = this.positions
                .filter(i => i.isChecked)
                .map(i => {
                    i.isChecked = false;
                    return i.id
                })

            if (positions.length === 0) {
                return;
            }

            this.$http.post(
                this.entrypoint + '/templates/move-positions',
                {positions: positions, from: this.template.id, to: template.id}
            ).then(() => {
                this.loadPositions();
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Позиции перемещены.',
                });
            }, () => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                });
            });
        },
        loadTemplates() {
            this.$http.get(this.entrypoint + '/templates',
                {params: {fields: ['positions'], withMyProducts: true}}
            ).then(response => {
                this.templates = response.data.filter(t => t.id !== this.templateId && t.positions.length > 0);
            }, () => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        loadPositions() {
            this.loading = true
            return this.$http.get(
                this.entrypoint + '/templates/' + this.templateId
            ).then((response) => {
                this.template = response.data;
                this.positions = response.data.positions;
                this.loading = false
            });
        },
        chooseProposal(proposal) {
            this.$refs.inputProposal.clear();
            this.$refs.inputProposal.setFocus();

            if (proposal.id || proposal.name) {
                let addedPosition = this.positions
                    .filter(position => !position.isDeleted)
                    .find(p => proposal.id === p.product || proposal.name === p.name);

                if (addedPosition) {
                    this.$nextTick(() => {
                        let elId = `template-${this.template.id}-position-${addedPosition.pos_id}`;
                        let el = document.getElementById(elId);
                        this.scrollPage(el);
                    });

                    return;
                }
            }

            let position = {
                product_id: proposal.id,
                name: proposal.name,
            }

            this.$http.post(this.entrypoint + '/templates/' + this.template.id, {position: position}).then(response => {
                this.positions = response.data.positions.map(position => {
                    position.isDeleted = false
                    position.isChecked = false
                    position.index = 1
                    return position
                });

                this.$nextTick(() => {
                    let elId = `template-${this.template.id}-position-${this.positions[this.positions.length - 1].pos_id}`;
                    let el = document.getElementById(elId);
                    this.scrollPage(el);
                });
            })
        },
        removeChecked() {
            this.positions
                .filter(i => i.isChecked)
                .forEach(position => position.isDeleted = true)

            this.removePositions()
        },
        removePositions() {
            let ids = this.positions
                .filter(position => position.isDeleted)
                .map(position => position.id)

            this.$http.post(
                this.entrypoint + '/templates/unlink-positions',
                {positions: ids, template: this.template.id}
            ).then(() => {
                this.loadPositions();

                this.$ons.notification.toast({
                    timeout: 2000,
                    message: 'Удалено ' + ids.length + ' позиции(-й)',
                    buttonLabel: 'OK',
                });
            }, () => {
                this.positions
                    .filter(i => i.isChecked)
                    .forEach(position => position.isDeleted = false)

                this.$ons.notification.toast({
                    timeout: 2000,
                    message: 'Не удалось удалить позиции из шаблона.',
                });
            });
        },
        onBindTemplate(templates) {
            let positions = [];
            this.loading = true;

            templates.forEach(template => {
                template.positions.forEach(item => {
                    let existsPosition = this.positions.find(i => {
                        return (item.prd_id && item.prd_id == i.prd_id) || (item.name === i.name);
                    });

                    if (existsPosition) {
                        return;
                    }

                    positions.push({
                        product_id: item.product,
                        name: item.name,
                        unit: item.unit,
                    });
                });
            });

            this.$http.post(
                `${this.entrypoint}/templates/${this.templateId}`,
                {positions: positions}
            ).then(() => {
                this.loadPositions();
            }, () => {
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        orderUp() {
            let id = this.positions.find(i => i.isChecked).id;

            this.$http.post(
                `${this.entrypoint}/templates/${this.template.id}/${id}/reorder`,
                {order: 'up'}
            ).then(() => {
                this.loadPositions().then(() => {
                    this.positions.find(i => i.id === id).isChecked = true;
                });
            });
        },
        orderDown() {
            let id = this.positions.find(i => i.isChecked).id;

            this.$http.post(
                `${this.entrypoint}/templates/${this.template.id}/${id}/reorder`,
                {order: 'down'}
            ).then(() => {
                this.loadPositions().then(() => {
                    this.positions.find(i => i.id === id).isChecked = true;
                });
            });
        },
        uncheckAll() {
            this.positions.forEach(i => i.isChecked = false);
        },
        onChooseAdded(proposal, position) {
            let $position = $('[data-product="' + proposal.id + '"]');
            this.scrollPage($position.find('input'));
            position.isDeleted = true;
        },
        openCatalog() {
            if (!this.hasPositions) {
                return;
            }

            this.$router.push({name: 'catalog', params: {template: 'tmp-' + this.template.id}});
        },
        createOrder() {
            if (!this.hasPositions) {
                return;
            }

            this.$http.post('/api/order/create-from-template', {template_id: this.template.id}).then(response => {
                this.$router.push({name: 'order', params: {order_id: response.data.id}});
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        scrollPage(target) {
            let $target = $(target);
            let offset = $target.position().top;
            $target = $target.offsetParent();

            while (!$target.hasClass('page__content')) {
                offset += $target.position().top;
                $target = $target.offsetParent();
            }

            offset += $target.scrollTop();

            offset -= 35;

            $('.page__content').animate({
                scrollTop: offset
            }, 300);
        },
        onRemovePosition(posId) {
            let position = this.positions.find(position => position.pos_id === posId)
            position.isDeleted = true
            this.removePositions()
        },
        onUpdatePosition() {

        },
    },
    computed: {
        hasPositions() {
            return this.positions
                .filter(i => i.product)
                .length > 0
        },
        addedPositions() {
            return this.positions
                .filter(i => i.product)
                .filter(i => !i.isDeleted)
                .map(i => {
                    return {
                        id: i.product,
                        index: i.index,
                    };
                });
        },
        positionsLocal() {
            let index = 1;
            return this.positions
                .filter(position => !position.isDeleted)
                .map(position => {
                    position.index = index++
                    return position
                });
        },
    },
    watch: {
        positions: {
            handler() {
                let checkedCount = this.positions
                    .filter(i => i.isChecked && !i.isDeleted)
                    .length;

                this.hasChecked = checkedCount > 0;
                this.hasCheckedOne = checkedCount === 1;
                this.checkedCount = checkedCount
            },
            deep: true
        }
    },
    components: {
        TemplatesModal,
        'v-list-dialog': ListDialog,
        InputProposalCreate,
        'v-position': UserProductPosition,
        AssignTemplateModal,
    },
};
</script>