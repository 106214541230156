<template>
    <v-ons-list-item class="list-item-category" >
        {{ item.name }}
    </v-ons-list-item>
</template>

<script>
export default {
    props: [
        'item',
    ],
};
</script>
