<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">{{ user.email }}</div>
        </v-ons-toolbar>

        <ons-list >
            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'worker'"
                @click="$router.push({name: 'manager-user', params: {user_id: director.id}})" >

                <div class="center" >Руководитель: {{ director.email }}</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'customer'"
                @click="$router.push({name: 'manager-user-orders'})" >

                <div class="center" >Заказы</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'customer'"
                @click="$router.push($router.currentRoute.path + '/products')" >

                <div class="center" >Товары</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'customer'"
                @click="$router.push({name: 'manager-user-special-prices'})" >

                <div class="center" >Спецпредложения</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'customer'"
                @click="$router.push({name: 'manager-user-catalog'})" >

                <div class="center" >Каталог клиента</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'customer'"
                @click="$router.push({name: 'manager-user-settings'})" >

                <div class="center" >Коммерческие настройки</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                @click="$router.push({name: 'manager-user-profile', params: {user: user}})" >

                <div class="center" >Настройки профиля</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'customer'"
                @click="$router.push({name: 'manager-user-onec-connection', params: {user: user}})" >

                <div class="center" >Связь с 1С</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                @click="$router.push({name: 'manager-user-admin', params: {user: user}})" >

                <div class="center" >Администрирование</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'manager'"
                @click="$router.push({name: 'manager-user-permission'})" >

                <div class="center" >Права доступа</div>
            </ons-list-item>
        </ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            user: {},
            director: {},
        }
    },
    created() {
        this.load();
        this.$bus.$on('updateUser', this.load);
    },
    destroyed() {
        this.$bus.$off('updateUser', this.load);
    },
    methods: {
        load() {
            this.$http.get('/api/manager/users/' + this.$route.params.user_id).then(response => {
                this.user = response.data;

                if (this.user.user_type === 'worker') {
                    this.$http.get(`/api/manager/users/${this.user.id}/director`).then(response => {
                        this.director = response.data;
                    });
                }
            });
        },
    },
    watch: {
        '$route.params.user_id'() {
            this.load();
        }
    }
};
</script>