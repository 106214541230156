var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Ценообразование разделов")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.categories.length > 0 && !_vm.isCheckedAll,
                    expression: "categories.length > 0 && !isCheckedAll"
                  }
                ],
                attrs: { title: "Выбрать все разделы" },
                on: { click: _vm.checkAll }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.categories.length > 0 && _vm.isCheckedAll,
                    expression: "categories.length > 0 && isCheckedAll"
                  }
                ],
                attrs: { title: "Снять выделение" },
                on: { click: _vm.uncheckAll }
              },
              [
                _c("v-ons-icon", {
                  staticStyle: { color: "yellow" },
                  attrs: { icon: "md-check-all" }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.categories.length > 0,
                  expression: "categories.length > 0"
                }
              ],
              staticStyle: { "margin-bottom": "70px" }
            },
            _vm._l(_vm.categories, function(category, key) {
              return _c("ons-list-item", { key: category.category_id }, [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "category-" + key }
                  },
                  [
                    _c("span", { staticClass: "list-item__title" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(category.name) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    category.priceHint
                      ? _c("span", { staticClass: "list-item__subtitle" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(category.priceHint) +
                              "\n                    "
                          )
                        ])
                      : _c(
                          "span",
                          {
                            staticClass: "list-item__subtitle",
                            staticStyle: { color: "red" }
                          },
                          [
                            _vm._v(
                              "\n                        Не настроена цена\n                    "
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: category.is_deleted,
                            expression: "category.is_deleted"
                          }
                        ],
                        staticClass: "list-item__subtitle",
                        staticStyle: { color: "red" }
                      },
                      [
                        _vm._v(
                          "\n                        Раздел не найден\n                    "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: {
                        value: category.category_id,
                        "input-id": "category-" + key
                      },
                      model: {
                        value: _vm.checked,
                        callback: function($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked"
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.categories.length === 0,
                  expression: "categories.length === 0"
                }
              ],
              staticClass: "list-empty",
              style: {
                flexFlow: "column",
                height: _vm.$window.height - 56 + "px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-bottom": "10px", "font-size": "15px" }
                },
                [_vm._v("\n                Данные не обнаружены\n            ")]
              ),
              _vm._v(" "),
              _c(
                "ons-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "manager-catalog-settings-includes"
                      })
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                Настройте выгрузку разделов\n            "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.categories.length > 0,
              expression: "categories.length > 0"
            }
          ],
          attrs: { disabled: !_vm.hasChecked, position: "bottom right" },
          on: { click: _vm.pricing }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-money-box" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7a72567a", { render: render, staticRenderFns: staticRenderFns })
  }
}