<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center">Настройки каталога</div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item
                tappable
                @click="$router.push($router.currentRoute.path + '/includes')"
                modifier="chevron longdivider"
                class="cursor-pointer"
            >
                Выгрузка разделов
            </v-ons-list-item>

            <v-ons-list-item
                tappable
                @click="$router.push($router.currentRoute.path + '/pricing')"
                modifier="chevron longdivider"
                class="cursor-pointer"
            >
                <div class="center">Ценообразование разделов</div>

                <div class="right">
                    <span v-show="errors.pricing" style="color: red; margin-left: 10px;" >Есть ошибки</span>
                </div>
            </v-ons-list-item>

            <v-ons-list-item
                tappable
                @click="$router.push($router.currentRoute.path + '/categories')"
                modifier="chevron longdivider"
                class="cursor-pointer"
            >
                <div class="center">Управление разделами</div>

                <div class="right">
                    <span v-show="errors.categories" style="color: red; margin-left: 10px;" >Есть ошибки</span>
                </div>
            </v-ons-list-item>

            <v-ons-list-item
                tappable
                @click="$router.push($router.currentRoute.path + '/product-rules')"
                modifier="chevron longdivider"
                class="cursor-pointer"
            >
                <div class="center">Перемещение/Удаление товаров</div>

                <div class="right">
                    <span v-show="errors.productRules" style="color: red; margin-left: 10px;" >Есть ошибки</span>
                </div>
            </v-ons-list-item>

            <v-ons-list-item
                tappable
                @click="$router.push($router.currentRoute.path + '/control-min-price')"
                modifier="chevron longdivider"
                class="cursor-pointer"
            >
                Контроль минимальной цены
            </v-ons-list-item>

            <v-ons-list-item
                tappable
                @click="$router.push($router.currentRoute.path + '/rounding-price')"
                modifier="chevron longdivider"
                class="cursor-pointer"
            >
                Округление цен
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            errors: {},
        }
    },
    created() {
        this.checkErrors();
        this.$bus.$on('checkErrors', this.checkErrors);
    },
    destroyed() {
        this.$bus.$off('checkErrors', this.checkErrors);
    },
    methods: {
        checkErrors() {
            this.$http.get(
                '/api/manager/catalog-settings/check-errors',
                {params: {storageId: this.$route.params.storage_id}}
            ).then(response => {
                this.errors = response.data;
            });
        },
    },
};
</script>