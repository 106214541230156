var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$window.isDesktop
    ? _c(
        "v-ons-toolbar-button",
        {
          on: {
            click: function($event) {
              return _vm.$bus.$emit("toggle-sidebar")
            }
          }
        },
        [
          _c("v-ons-icon", { attrs: { icon: "ion-navicon, material:md-menu" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7cc05d02", { render: render, staticRenderFns: staticRenderFns })
  }
}