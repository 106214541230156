var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { visible: _vm.visible, cancelable: "" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "center" }, [_vm._v("График доставки")])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.id != 2,
              expression: "id != 2"
            }
          ],
          staticStyle: { margin: "18px 0" }
        },
        [
          _c("p", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v(_vm._s(_vm.address))
          ]),
          _vm._v(" "),
          _c("div", [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Доставка")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("День заказа")])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.schedule, function(day) {
                  return day.beforeDayName
                    ? _c("tr", [
                        _c("td", [_vm._v(_vm._s(day.name))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(day.beforeDayName) +
                              " до " +
                              _vm._s(day.beforeTime) +
                              "\n                        "
                          )
                        ])
                      ])
                    : _vm._e()
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-left": "10px" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.freeDeliveryFrom,
                      expression: "freeDeliveryFrom"
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n                    Бесплатная доставка при заказе от " +
                      _vm._s(_vm.freeDeliveryFrom) +
                      " "
                  ),
                  _c("span", { staticClass: "font-rouble" }, [_vm._v("o")])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.deliveryCost,
                      expression: "deliveryCost"
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n                    Стоимость доставки: " +
                      _vm._s(_vm.deliveryCost) +
                      " "
                  ),
                  _c("span", { staticClass: "font-rouble" }, [_vm._v("o")])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.refusalDeliveryTo,
                      expression: "refusalDeliveryTo"
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n                    Минимальный заказ: " +
                      _vm._s(_vm.refusalDeliveryTo) +
                      " "
                  ),
                  _c("span", { staticClass: "font-rouble" }, [_vm._v("o")])
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.id == 2,
              expression: "id == 2"
            }
          ],
          staticStyle: { margin: "25px 10px" }
        },
        [
          _vm._v(
            "\n        По этому адресу не осуществляется плановых доставок.\n    "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5db620db", { render: render, staticRenderFns: staticRenderFns })
  }
}