<template>
    <v-ons-list @click="onClick" >
        <v-ons-list-item tappable >
            <router-link to="/">Главная</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable class="cursor-pointer" @click="goToOrders">
            <div class="center">
                Заказы
            </div>

            <div class="right" v-show="$user.hasNewOrders" >
                <v-ons-icon icon="md-email" size="22px" style="color: #ffbb3d" />
            </div>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/manager/users" >Клиенты</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable>
            <router-link to="/catalog" >Каталог</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable>
            <router-link to="/manager/oos" >Out of stock</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable class="cursor-pointer" @click="goToProductLink">
            <div class="center">
                Связи товаров
            </div>

            <div class="right" v-show="$user.hasProductLinksErrors" >
                <v-ons-icon icon="md-arrow-merge" size="25px" style="color: #ffbb3d" />
            </div>
        </v-ons-list-item>

        <v-ons-list-item tappable @click="$router.push('/manager/import')" >
            <div class="center">
                Загрузка данных
            </div>

            <div class="right" v-show="hasJsonImportError">
                <v-ons-icon icon="md-alert-circle-o" size="25px" style="color: #ffbb3d" />
            </div>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/manager/reports" >Отчёты</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/manager/management">Управление</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/profile">Профиль</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/settings">Настройки</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable @click="reload">
            Обновить
        </v-ons-list-item>
    </v-ons-list>
</template>

<script>
export default {
    data() {
        return {
            hasJsonImportError: false,
        };
    },
    created() {
        this.checkJsonImportError();
    },
    socket() {
        return [
            {
                namespace: '/import',
                events: {
                    completed() {
                        this.checkJsonImportError();
                    },
                    halted() {
                        this.checkJsonImportError();
                    },
                },
            },
        ];
    },
    methods: {
        checkJsonImportError() {
            this.$http.get('/api/catalog/has-json-import-error').then(response => {
                this.hasJsonImportError = response.data;
            });
        },
        goToOrders() {
            if (this.$route.name === 'manager-orders') {
                this.$bus.$emit('orders-update');
            } else {
                this.$router.push('/manager/order');
            }
        },
        goToProductLink() {
            if (this.$route.name === 'manager-product-link-products') {
                this.$bus.$emit('loadProductsLink');
            } else {
                this.$router.push('/manager/product-link/products')
            }
        },
        onClick() {
            this.$emit('click');
        },
        reload() {
            location.reload();
        },
    },
}
</script>