<template>
    <div class="input-password" >
        <v-ons-input v-model="password" @input="handleInput" :type="showPassword ? 'text' : 'password'" :placeholder="placeholder" :name="name" />

        <div class="icon-wrap" >
            <v-ons-icon v-show="!showPassword" @click="showPassword = true" icon="md-eye-off"></v-ons-icon>
            <v-ons-icon v-show="showPassword" @click="showPassword = false" icon="md-eye"></v-ons-icon>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'value',
        'name',
        'placeholder',
    ],
    data() {
        return {
            password: '',
            showPassword: false,
        }
    },
    created() {
        this.password = this.value;
    },
    methods: {
        handleInput (e) {
            this.$emit('input', e.target.value);
        }
    },
    watch: {
        value() {
            this.password = this.value;
        },
    },
};
</script>
