<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>
            <div class="center">Склады</div>
        </v-ons-toolbar>

        <v-ons-list v-show="storages.length > 0" >
            <v-ons-list-item
                v-for="storage in storages"
                :key="storage.id"
                modifier="chevron longdivider"
                :style="{color: storage.is_active ? '' : '#ccc'}"
            >
                <div class="center cursor-pointer" @click="$router.push('/profile/manager-storages/' + storage.id)" >
                    <span class="list-item__title">
                        {{ storage.real_name }}
                    </span>

                    <span v-show="storage.is_default" class="list-item__subtitle">
                        По умолчанию
                    </span>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <div v-show="!storages.length" class="" >
            <div style="font-size: 20px; color: #a8a8a8; position: absolute; top: 50%; text-align: center; width: 100%; line-height: 50px; margin-top: -25px;" >
                Список пуст<br>
            </div>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            storages: [],
        }
    },
    created() {
        this.load();
        this.$bus.$on('update-storages', this.load);
    },
    destroyed() {
        this.$bus.$off('update-storages', this.load);
    },
    methods: {
        load() {
            this.$http.get(
                '/api/manager/storages',
                {params: {all: true}}
            ).then(response => {
                this.storages = response.data;
            });
        },
    },
};
</script>