<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center" >Главная</div>
        </v-ons-toolbar>

        <div class="home-page" >
            <div class="links-block" >
                <div class="row-wrap" >
                    <router-link class="col-wrap button--material" to="/catalog">
                        <span>Каталог</span>
                    </router-link>
                </div>

                <div class="row-wrap" >
                    <router-link class="col-wrap button--material" :class="{highlight: highlightCreateOrderLink}" to="/order/create">
                        <span>Написать заявку</span>
                    </router-link>

                    <router-link class="col-wrap button--material" to="/order">
                        <span>Заказы</span>
                    </router-link>
                </div>

            </div>

            <div v-if="user.id" >
                <div v-if="user.discounts.getDiscount.enabled" >
                    <v-ons-card>
                        <p>Для получения скидки и статуса корпоративного клиента вам необходимо <router-link to="/contacts-for-discounts">ввести данные о вашей фирме</router-link>. После проверки и подтверждения информации менеджер активирует вашу учетную запись и отправит вам уведомление</p>

                        <div class="wrap" >
                            <div class="col xl-1-1 xl-center" >
                                <router-link class="button button--material send-contacts" to="/contacts-for-discounts">
                                    <span>Заполните анкету<br>и получите скидку</span>
                                </router-link>
                            </div>
                        </div>
                    </v-ons-card>
                </div>

                <div v-if="!user.hasUserGroupOrTemplate" >
                    <div v-if="!user.filledUserProduct" >
                        <v-ons-card>
                            <p>Собственный товарный перечень можно создать через раздел <router-link to="/product-list">"Мои товары"</router-link>, добавляя каждую позицию вручную.</p>
                            <p>А также в разделе <router-link to="/catalog">"Каталог"</router-link> можно выделять галочкой товары и нажимать кнопку "звёздочка", отправляя их в <router-link to="/product-list">"Мои товары"</router-link>.</p>
                            <p>Собственный товарный перечень поможет вам быстро делать выборку из каталога только по товарам, которые вы используете, а также создавать заказы по шаблонам.</p>

                            <div class="wrap" >
                                <div class="col xl-1-1 xl-center" >
                                    <router-link class="button button--material" to="/product-list">
                                        <span>Создайте собственную<br>товарную подборку</span>
                                    </router-link>
                                </div>
                            </div>
                        </v-ons-card>
                    </div>
                    <div v-else >
                        <v-ons-card>
                            <p>Управляйте списком <router-link to="/product-list">"Мои товары"</router-link> для получения более высокой скорости оформления ваших заказов.</p>
                            <p>Выделяя товары галочкой, можно менять их положение в списке и размещать их по собственным товарным группам.</p>

                            <div class="wrap" >
                                <div class="col xl-1-1 xl-center" >
                                    <router-link class="button button--material" to="/product-list">
                                        <span>Мои товары</span>
                                    </router-link>
                                </div>
                            </div>
                        </v-ons-card>
                    </div>
                </div>
            </div>

            <div style="margin-top: 10px">
                <v-discount-info />
            </div>
        </div>
    </v-ons-page>
</template>

<script>

import DiscountInfoComponent from "~/component/DiscountInfoComponent";

export default {
    data() {
        return {
            highlightCreateOrderLink: false,
            highlightCreateInvLink: false,
            user: {},
        }
    },
    created() {
        this.$http.get('/api/inventory/has-unprocessed-inv').then(response => {
            this.highlightCreateOrderLink = response.data;
        });

        this.$http.get('/api/inventory/need-new-inv').then(response => {
            this.highlightCreateInvLink = response.data;
        });

        this.$http.get('/api/user').then(response => {
            this.user = response.data;
        });

        this.$bus.$on('authorized', this.onAuthorized);
    },
    destroyed() {
        this.$bus.$off('authorized');
    },
    methods: {
        onAuthorized() {
            this.$nextTick(() => {
                let element = document.getElementsByClassName('home-page')[0];
                if (element) {
                    element.parentElement.scrollTop = 0;
                }
            });
        },
    },
    components: {
        'v-discount-info': DiscountInfoComponent,
    },
};
</script>