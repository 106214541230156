var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "div",
            {
              staticClass: "user-product-page",
              staticStyle: { position: "relative" },
              style: { "padding-bottom": _vm.paddingBottom }
            },
            [
              _c("div", { staticClass: "positions" }, [
                _c(
                  "div",
                  { staticClass: "tbody" },
                  [
                    _vm._l(_vm.positionGroupsLocal, function(group) {
                      return _c(
                        "div",
                        {
                          key: group.id + "-" + group.length,
                          staticClass: "group-wrap"
                        },
                        [
                          _vm.shouldShowGroupName
                            ? _c("div", { staticClass: "group-name" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(group.name) +
                                    "\n                        "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.getPositionsByGroup(group.id), function(
                            position
                          ) {
                            return _c("v-position", {
                              key: position.id,
                              attrs: {
                                id: "position-" + position.pos_id,
                                position: position,
                                addedPositions: _vm.addedPositions
                              },
                              on: {
                                remove: _vm.onRemovePosition,
                                update: _vm.onUpdatePosition
                              }
                            })
                          })
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _c("input-proposal-create", {
                      ref: "inputProposal",
                      attrs: {
                        scrollPageOnFocus: true,
                        disableScrollPage: true,
                        addedPositions: _vm.addedPositions,
                        urlSearch: _vm.urlSearch,
                        columns: _vm.$user.canSeePrice
                          ? ["name", "price"]
                          : ["name"]
                      },
                      on: { choose: _vm.chooseProposal }
                    })
                  ],
                  2
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("select-add-method-modal", { ref: "selectAddMethodModal" })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCheckedOne,
              expression: "hasCheckedOne"
            }
          ],
          staticStyle: { bottom: "90px" },
          attrs: { position: "bottom left" },
          on: { click: _vm.orderUp }
        },
        [_c("v-ons-icon", { attrs: { icon: "long-arrow-up" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCheckedOne,
              expression: "hasCheckedOne"
            }
          ],
          attrs: { position: "bottom left" },
          on: { click: _vm.orderDown }
        },
        [_c("v-ons-icon", { attrs: { icon: "long-arrow-down" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0ff142ea", { render: render, staticRenderFns: staticRenderFns })
  }
}