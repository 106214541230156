<!-- src/screen/order/CreateBranch.vue -->
<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div v-if="id" class="center">Филиал: {{ branch.actual_name }}</div>
            <div v-if="!id" class="center">Создать филиал</div>

            <div class="right">
                <v-ons-toolbar-button @click="remove" v-if="id" title="Удалить филиал" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" title="Сохранить" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <v-ons-list>
            <v-ons-list-header>Фактическое наименование (Вывеска)</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="branch.actual_name" name="actual_name" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Фактический адрес (Адрес доставки)</v-ons-list-header>
            <v-ons-list-item>
                <v-address-form v-model="branch.address" :title="'Фактический адрес (Адрес доставки)'" ></v-address-form>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>График работы</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="branch.work_time" name="work_time" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <ons-list-header>Юр. лицо</ons-list-header>
            <ons-list-item v-for="(entity, key) in entities" :key="entity.id" >
                <label :for="'entity-' + key" class="center label-reset" >
                    {{ entity.legal_name }}
                </label>
                <div class="right">
                    <v-ons-checkbox
                        v-model="branch.entities"
                        :value="entity.id"
                        :input-id="'entity-' + key"
                        :disabled="entities.length == 1"
                        style="vertical-align: middle; margin: 0 12px 0 0;"
                    ></v-ons-checkbox>
                </div>
            </ons-list-item>

            <v-ons-list-item v-show="entities.length === 0" >
                <v-ons-button @click="$router.push({name: 'branches-create-entity'})" >Добавить юр. лицо</v-ons-button>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

import AddressFormComponent from '../../component/AddressFormComponent'

export default {
    data() {
        return {
            id: null,
            loading: true,
            changed: false,
            loaded: false,
            branch: {
                address: {},
                entities: [],
            },
            entities: [],
        }
    },
    created() {
        this.id = this.$route.params.branch_id;
        this.$bus.$on('entities-load', this.loadEntities);
        this.load();
    },
    destroyed() {
        this.$bus.$off('entities-load');
    },
    methods: {
        load() {
            this.loadEntities();

            if (!this.id) {
                this.$nextTick(() => {
                    this.loaded = true;
                    this.loading = false;
                });
                return;
            }

            this.$http.get('/api/user/branches/' + this.id).then(response => {
                this.branch = response.data;
                this.branch.entities = this.branch.entities.map(i => {
                    return i.id.toString();
                });

                this.loading = false;

                this.$nextTick(() => {
                    this.loaded = true;
                });
            });
        },
        loadEntities() {
            this.$http.get('/api/settings/entities').then(response => {
                this.entities = response.data;

                if (!this.id && this.entities.length === 1)  {
                    this.branch.entities = [this.entities[0].id.toString()];
                }
            });
        },
        save() {
            let promise;
            if (this.id) {
                promise = this.$http.post('/api/user/branches/' + this.id, this.branch);
            } else {
                promise = this.$http.post('/api/user/branches', this.branch);
            }

            promise.then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Филиал сохранен',
                    buttonLabel: 'OK',
                });
                this.$bus.$emit('load');
                this.$bus.$emit('load-branches');
                this.$router.go(-1);
            }, (response) => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                });
            });
        },
        remove() {
            this.$ons.notification.confirm('Удалить филиал?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.delete('/api/user/branches/' + this.id).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Филиал удалён',
                        buttonLabel: 'OK',
                    });
                    this.$bus.$emit('load');
                    this.$router.go(-1);
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
    },
    watch: {
        branch: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
    components: {
        'v-address-form': AddressFormComponent,
    },
};
</script>
