<template>
    <div :id="'category-' + item.id" :class="{'category-opened': item.isOpen}">
        <div class="tile-item-category" @click="$emit('clickCategory', item)" style="cursor:pointer;">
            <img class="arrow" src="/img/right-arrow.svg" alt="">
            {{ item.name }}
        </div>

        <div v-if="item.loading" style="text-align: center; margin: 15px 0;">
            <v-ons-progress-circular indeterminate></v-ons-progress-circular>
        </div>

        <div v-if="item.isOpen">
            <div v-for="row in rows" class="tile-row-product">
                <product-item-tile
                    v-for="product in row"
                    :key="product.id"
                    :item="product"
                    :actionColType="actionColType"
                    :allowInputs="allowInputs"
                    :canOpenProductPage="canOpenProductPage"
                    :checked="checked"
                    :counts="counts"
                    :isDesktop="isDesktop"
                    :lastImportDate="lastImportDate"
                    :selectedPriceType="selectedPriceType"
                    :showInputs="showInputs"
                    :width="(100 / numberColumns) + '%'"
                    @clickGoToView="$emit('clickGoToView', $event)"
                    @toggleFavor="$emit('toggleFavor', $event)"
                    @countEnterDown="$emit('countEnterDown', $event)"
                    @countShiftEnterDown="$emit('ountShiftEnterDown', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script>

import ProductItemTile from '~/component/catalog/ProductItemTile'
import CategoryItemList from "./CategoryItemList.vue";

export default {
    props: [
        'item',
        'isDesktop',
        'actionColType',
        'canOpenProductPage',
        'showInputs',
        'selectedPriceType',
        'counts',
        'checked',
        'lastImportDate',
        'allowInputs',
    ],
    computed: {
        rows() {
            let arr = this.item.products
            let size = this.numberColumns

            return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
                arr.slice(i * size, i * size + size)
            );
        },
        numberColumns() {
            if (this.$window.width > 800) {
                return 3;
            }

            return 2;
        },
    },
    components: {
        CategoryItemList,
        ProductItemTile,
    },
};
</script>