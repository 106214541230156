<template>
    <v-ons-toolbar-button v-if="!$window.isDesktop" @click="$bus.$emit('toggle-sidebar')" >
        <v-ons-icon icon="ion-navicon, material:md-menu"></v-ons-icon>
    </v-ons-toolbar-button>
</template>

<script>
export default {

};
</script>
