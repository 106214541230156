<template>
    <div v-if="$user.discounts && $user.discounts.orderAmountMoreMonth && $user.canSeePrice" >
        <v-ons-card v-show="$user.discounts.orderAmountMoreMonth.enabled" >
            <span v-show="$user.discounts.orderAmountMoreMonth.has" >
                Ваша дополнительная скидка составляет {{ $user.discounts.orderAmountMoreMonth.percent }}%
            </span>

            <span v-show="!$user.discounts.orderAmountMoreMonth.has" >
                Сумма заказов за 30 дней - {{ $user.discounts.orderAmountMoreMonth.monthAmount }} р.
                Для получения скидки {{ $user.discounts.orderAmountMoreMonth.percent }}%,
                вам нужно выбрать товара всего на {{ $user.discounts.orderAmountMoreMonth.restSum }} р.
            </span>
        </v-ons-card>

        <v-ons-card v-show="$user.discounts.orderAmountMore.enabled" >
            Вы можете получить скидку {{ $user.discounts.orderAmountMore.percent }}%
            сразу на текущий заказ от суммы {{ $user.discounts.orderAmountMore.amount }} р.
        </v-ons-card>
    </div>
</template>

<script>

export default {
    data() {
        return {

        }
    },
    created() {

    },
};
</script>