<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Настройки заказа</div>
            <div class="right">
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-header>Способ отгрузки по умолчанию</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-select style="width: 100%" v-model="defaultShippingMethod">
                        <option v-for="item in shippingMethodList" :value="item.id">
                            {{ item.name }}
                        </option>
                    </v-ons-select>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Способы оплаты по умолчанию</v-ons-list-header>

                <div v-if="entities.length > 0" >
                    <v-ons-list-item v-for="entity in entities" :key="entity.id" >
                        <div class="center" >
                            {{ entity.name }} - {{ entity.defaultPaymentMethod }}
                        </div>
                        <div class="right" >
                            <v-ons-icon
                                @click="$router.push('/profile/entities/' + entity.id)"
                                icon="md-edit"
                                class="cursor-pointer list-item__icon"
                            ></v-ons-icon>
                        </div>
                    </v-ons-list-item>
                </div>
                <div v-else >
                    <v-ons-list-item>
                        <div class="center" >
                            <v-ons-button @click="$router.push('/profile/entities/create')" >Добавить юр. лицо</v-ons-button>
                        </div>
                    </v-ons-list-item>
                </div>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            defaultShippingMethod: null,
            paymentMethodList: [],
            shippingMethodList: [],
            entities: [],
        }
    },
    created() {
        this.$http.get('/api/settings/checkout').then(response => {
            let data  = response.data;
            this.paymentMethodList = data.paymentMethodList;
            this.shippingMethodList = data.shippingMethodList;

            this.entities = data.entities.map(e => {
                let defaultPaymentMethod = this.paymentMethodList.find(p => p.id == e.default_payment_method);

                defaultPaymentMethod = defaultPaymentMethod ? defaultPaymentMethod.name : 'не указан';

                return {
                    id: e.id,
                    name: e.legal_name,
                    defaultPaymentMethod: defaultPaymentMethod,
                }
            });

            this.$nextTick(() => {
                this.defaultShippingMethod = data.defaultShippingMethod;
            });

            this.loading = false;
        });

        this.$bus.$on('entities-load', this.entitiesLoad);
    },
    destroyed() {
        this.$bus.$off('entities-load', this.entitiesLoad);
    },
    methods: {
        save() {
            var data = {
                defaultShippingMethod: this.defaultShippingMethod,
            };

            this.$http.post('/api/settings/checkout', data).then(response => {
                this.$router.go(-1);
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Не удалось сохранить.',
                });
            });
        },
        entitiesLoad() {
            this.$http.get('/api/settings/entities').then(response => {
                this.entities = response.data.map(e => {
                    let defaultPaymentMethod = this.paymentMethodList.find(p => p.id == e.default_payment_method);

                    defaultPaymentMethod = defaultPaymentMethod ? defaultPaymentMethod.name : 'не указан';

                    return {
                        id: e.id,
                        name: e.legal_name,
                        defaultPaymentMethod: defaultPaymentMethod,
                    }
                });
            });
        },
    }
};
</script>
