import Index from '~/screen/manager/Index';
import Import from './Import';
import Managment from './Managment';
import Oos from './Oos';
import Order from './Order';
import ProductAvail from './ProductAvail';
import ProductLink from './ProductLink';
import Products from './Products';
import Reports from './Reports';
import Users from './Users';

let routes = [
    {
        path: '/manager',
        name: 'manager',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            ...Import,
            ...Managment,
            ...Oos,
            ...Order,
            ...ProductAvail,
            ...ProductLink,
            ...Products,
            ...Reports,
            ...Users,
        ],
    },
];

export default routes;