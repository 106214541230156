<template>
    <v-ons-alert-dialog modifier="rowfooter" :visible.sync="visible" >
        <span slot="title">{{ title }}</span>

        <div>{{ message }}</div>

        <div style="margin-top: 5px;" >
            <v-ons-checkbox :input-id="'chk'" v-model="isChecked" :value="true" style="vertical-align: middle;" ></v-ons-checkbox>
            <label for="chk" style="font-weight: normal; vertical-align: middle; margin: 0" >{{ checkboxLabel }}</label>
        </div>

        <template slot="footer">
            <v-ons-alert-dialog-button @click="cancel">Отмена</v-ons-alert-dialog-button>
            <v-ons-alert-dialog-button @click="ok">ОК</v-ons-alert-dialog-button>
        </template>
    </v-ons-alert-dialog>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            message: '',
            checkboxLabel: '',

            visible: false,
            isChecked: true,

            resolve: null,
            reject: null,
        }
    },
    methods: {
        confirm(params) {
            this.title = params.title;
            this.message = params.message;
            this.checkboxLabel = params.checkboxLabel;
            this.visible = true;
            this.isChecked = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;

            if (this.resolve) {
                this.resolve({response: false, isChecked: this.isChecked});
            }
        },
        ok() {
            this.visible = false;

            if (this.resolve) {
                this.resolve({response: true, isChecked: this.isChecked});
            }
        },
    },
};
</script>
