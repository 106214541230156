<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div v-if="id" class="center">Филиал: {{ branch.actual_name }}</div>
            <div v-if="!id" class="center">Создать филиал</div>

            <div class="right">
                <v-ons-toolbar-button @click="remove" v-if="id" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-header>Фактическое наименование (Вывеска)</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="branch.actual_name" name="actual_name" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Фактический адрес (Адрес доставки)</v-ons-list-header>
            <v-ons-list-item>
                <v-address-form v-model="branch.address" :title="'Фактический адрес (Адрес доставки)'" ></v-address-form>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Маршрут</v-ons-list-header>
            <v-ons-list-item>
                <div class="select-material select--material select">
                    <select v-model="branch.route_id" class="select-input select-input--material" >
                        <option :value="''" >не выбрано</option>

                        <option v-for="route in routes" :key="route.id" :value="route.id" >
                            {{ route.name }}
                        </option>
                    </select>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>График работы</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="branch.work_time" name="work_time" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Комментарий о проезде и разгрузке</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="branch.delivery_comment" name="delivery_comment" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Юр. лицо</v-ons-list-header>
            <v-ons-list-item v-for="(entity, key) in entities" :key="entity.id" >
                <label :for="'entity-' + key" class="center label-reset" >
                    {{ entity.legal_name }}
                </label>
                <div class="right">
                    <v-ons-checkbox
                        v-model="branch.entities"
                        :value="entity.id"
                        :input-id="'entity-' + key"
                        :disabled="entities.length === 1"
                        style="vertical-align: middle; margin: 0 12px 0 0;"
                    ></v-ons-checkbox>
                </div>
            </v-ons-list-item>

            <v-ons-list-item v-show="entities.length === 0" >
                <v-ons-button @click="$router.push($router.currentRoute.path + '/entity')" >Добавить юр. лицо</v-ons-button>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Схема проезда</v-ons-list-header>
            <div v-if="branch.map_path" style="padding: 0 20px" >
                <img :src="'/images/' + branch.map_path" alt="" style="max-width: 90%; box-shadow: 0 1px 6px #e8e8e8;">
            </div>
            <v-ons-list-item>
                <v-ons-button
                    @click="$upload.select('imageMap')"
                    :disabled="$upload.meta('imageMap').status === 'sending'"
                >
                    <v-ons-icon icon="md-upload" size="20px"></v-ons-icon>
                </v-ons-button>

                <v-ons-button
                    style="margin-left: 10px"
                    v-show="branch.map_path"
                    @click="branch.map_path = ''"
                >
                    <v-ons-icon icon="md-delete" size="20px"></v-ons-icon>
                </v-ons-button>

                <v-ons-button
                    style="margin-left: 10px"
                    v-show="branch.map_path"
                    @click="downloadMap"
                >
                    <v-ons-icon icon="md-download" size="20px"></v-ons-icon>
                </v-ons-button>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

import AddressFormComponent from '../../../component/AddressFormComponent'

export default {
    data() {
        return {
            id: null,
            changed: false,
            loaded: false,
            userId: null,
            entities: [],
            branch: {
                address: {},
                entities: [],
            },
            routes: [],
        }
    },
    created() {
        this.id = this.$route.params.branch_id;
        this.userId = this.$route.params.user_id;
        this.load();
        this.$bus.$on('entities-load', this.entitiesLoad);

        this.$upload.new('imageMap', {
            url: '/api/upload-image',
            extensions: ['jpeg', 'jpg', 'png'],
            onError() {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка при загрузке изображения.',
                    buttonLabel: 'OK'
                });
            },
            onSuccess(response) {
                this.branch.map_path = response.data;
            }
        });
    },
    destroyed() {
        this.$bus.$off('entities-load', this.entitiesLoad);
        this.$upload.reset('imageMap');
        delete this.$upload.watch.uploaders.imageMap;
    },
    methods: {
        load() {
            this.$http.get('/api/manager/users/'+ this.$route.params.user_id  +'/routes').then(response => {
                this.routes = response.data;
            });

            this.entitiesLoad();

            if (!this.id) {
                this.$nextTick(() => {
                    this.loaded = true;
                });
                return;
            }

            this.$http.get('/api/manager/users/'+ this.userId  +'/branches/' + this.id).then(response => {
                this.branch = response.data;
                this.branch.entities = this.branch.entities.map(i => {
                    return i.id.toString();
                });

                this.$nextTick(() => {
                    this.loaded = true;
                });
            });
        },
        entitiesLoad() {
            this.$http.get('/api/manager/users/'+ this.$route.params.user_id  +'/entities').then(response => {
                this.entities = response.data;

                if (!this.id && this.entities.length === 1)  {
                    this.branch.entities = [this.entities[0].id.toString()];
                }
            });
        },
        save() {
            let promise;
            if (this.id) {
                promise = this.$http.post('/api/manager/users/'+ this.userId  +'/branches/' + this.id, this.branch);
            } else {
                promise = this.$http.post('/api/manager/users/'+ this.userId  +'/branches', this.branch);
            }

            promise.then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Филиал сохранен',
                    buttonLabel: 'OK',
                });
                this.$bus.$emit('load');
                this.$bus.$emit('load-branches');
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
            }, (response) => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                });
            });
        },
        remove() {
            this.$ons.notification.confirm('Удалить филиал?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.delete('/api/manager/users/'+ this.userId  +'/branches/' + this.id).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Филиал удалён',
                        buttonLabel: 'OK',
                    });
                    this.$bus.$emit('load');
                    this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        downloadMap() {
            this.$http.get('/images/' + this.branch.map_path, {responseType: 'arraybuffer'}).then(response => {
                let blob = new Blob([response.data], {type: response.headers.get('content-type')});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = this.branch.map_path;
                link.click();
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
    watch: {
        branch: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
    components: {
        'v-address-form': AddressFormComponent,
    },
};
</script>