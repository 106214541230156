<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">{{ product.name }}</div>

            <div class="right" v-show="!loading && users.length > 0">
                <v-ons-toolbar-button v-show="hasChecked" @click="uncheckAll" >
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="!hasChecked" @click="checkAll" >
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list v-show="users" >
                <ons-list-item v-show="storages.length > 1" modifier="chevron longdivider" @click="showChooseStorage">
                    <span class="list-item__title">Склад: {{ selectedStorageName }}</span>
                    <span v-if="selectedStorage && !selectedStorage.is_configured" style="color: red" class="list-item__subtitle">
                        Не настроено
                    </span>
                </ons-list-item>

                <ons-list-item
                    v-for="user in users"
                    :key="user.id"
                    modifier="longdivider"
                    @click="user.isChecked = !user.isChecked" >

                    <div class="left" style="min-width: 40px; text-align: center;" >
                        <v-ons-checkbox v-model="user.isChecked" />
                    </div>
                    <div class="center" >
                        {{ user.id }} - {{ user.email }}
                    </div>
                </ons-list-item>
            </v-ons-list>

            <div v-show="users.length === 0" class="list-empty" :style="{height: $window.height - 56 + 'px', flexDirection: 'column'}" >
                <div style="margin-bottom: 10px; font-size: 15px;" >
                    Нет связей
                </div>
            </div>
        </div>

        <v-ons-fab v-show="hasChecked" position="bottom right" @click="goToReplace" >
            <v-ons-icon icon="md-arrow-right"></v-ons-icon>
        </v-ons-fab>

        <v-list-dialog ref="chooseStorageDialog" title="Выберите склад" :items="storages" />
    </v-ons-page>
</template>

<script>

import ListDialog from "~/component/ListDialog";

export default {
    data() {
        return {
            loading: true,

            product: {},
            users: [],
            choseStorage: null,
            storages: [],
        }
    },
    created() {
        this.id = this.$route.params.prd_id;
        this.choseStorage = this.$user.selectedStorage;

        this.loadStorages().then(() => {
            this.load();
        });

        this.$bus.$on('catalog-product-users-load', this.load);
    },
    destroyed() {
        this.$bus.$off('catalog-product-users-load', this.load);
    },
    methods: {
        loadStorages() {
            return this.$http.get(
                '/api/manager/storages'
            ).then(response => {
                this.storages = response.data;

                if (!this.choseStorage) {
                    this.choseStorage = this.storages.find(s => s.is_default).id;
                }

                if (!this.choseStorage) {
                    this.choseStorage = this.storages[0].id;
                }
            });
        },
        showChooseStorage() {
            this.$refs.chooseStorageDialog.show().then(response => {
                this.choseStorage = response;
                this.load();
            });
        },
        goToReplace() {
            let replaceRoute = this.$router.getChildrenByPath('replace');
            this.$router.push({
                name: replaceRoute.name,
                params: {
                    users: this.checkedUserIds,
                    selectedStorage: this.choseStorage
                },
            })
        },
        checkAll() {
            this.users.forEach(i => {
                i.isChecked = true;
            })
        },
        uncheckAll() {
            this.users.forEach(i => {
                i.isChecked = false;
            })
        },
        load() {
            let requests = [
                this.$http.get('/api/catalog/view', {params: {id: this.id}}),
                this.$http.get('/api/catalog/product-users',
                    {
                        params: {
                            id: this.id,
                            storageId: this.choseStorage,
                        },
                    },
                ),
            ];

            Promise.all(requests).then(responses => {
                this.product = responses[0].data;
                this.users = responses[1].data.map(i => { i.isChecked = false; return i; });

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
    },
    computed: {
        hasChecked() {
            return this.users
                .filter(i => i.isChecked)
                .length > 0;
        },
        checkedUserIds() {
            return this.users
                .filter(i => i.isChecked)
                .map(i => i.id);
        },
        selectedStorage() {
            return this.storages.find(s => s.id === parseInt(this.choseStorage));
        },
        selectedStorageName() {
            let storage = this.storages.find(s => s.id === parseInt(this.choseStorage));

            if (!storage) {
                return 'Неизвестно';
            }

            return storage.name;
        },
    },
    components: {
        'v-list-dialog': ListDialog,
    },
};
</script>