var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$user.discounts &&
    _vm.$user.discounts.orderAmountMoreMonth &&
    _vm.$user.canSeePrice
    ? _c(
        "div",
        [
          _c(
            "v-ons-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.discounts.orderAmountMoreMonth.enabled,
                  expression: "$user.discounts.orderAmountMoreMonth.enabled"
                }
              ]
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$user.discounts.orderAmountMoreMonth.has,
                      expression: "$user.discounts.orderAmountMoreMonth.has"
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n            Ваша дополнительная скидка составляет " +
                      _vm._s(_vm.$user.discounts.orderAmountMoreMonth.percent) +
                      "%\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$user.discounts.orderAmountMoreMonth.has,
                      expression: "!$user.discounts.orderAmountMoreMonth.has"
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n            Сумма заказов за 30 дней - " +
                      _vm._s(
                        _vm.$user.discounts.orderAmountMoreMonth.monthAmount
                      ) +
                      " р.\n            Для получения скидки " +
                      _vm._s(_vm.$user.discounts.orderAmountMoreMonth.percent) +
                      "%,\n            вам нужно выбрать товара всего на " +
                      _vm._s(_vm.$user.discounts.orderAmountMoreMonth.restSum) +
                      " р.\n        "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-ons-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.discounts.orderAmountMore.enabled,
                  expression: "$user.discounts.orderAmountMore.enabled"
                }
              ]
            },
            [
              _vm._v(
                "\n        Вы можете получить скидку " +
                  _vm._s(_vm.$user.discounts.orderAmountMore.percent) +
                  "%\n        сразу на текущий заказ от суммы " +
                  _vm._s(_vm.$user.discounts.orderAmountMore.amount) +
                  " р.\n    "
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5a9501b3", { render: render, staticRenderFns: staticRenderFns })
  }
}