var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["position-card-wrap", _vm.isDeleted ? "position-deleted" : ""] },
    [
      _c("div", { staticClass: "row-position-wrap" }, [
        _c("div", { staticClass: "col col-checkbox" }, [
          !_vm.position.isNew
            ? _c(
                "div",
                { staticClass: "remove-wrap" },
                [
                  _c("v-ons-checkbox", {
                    model: {
                      value: _vm.position.isChecked,
                      callback: function($$v) {
                        _vm.$set(_vm.position, "isChecked", $$v)
                      },
                      expression: "position.isChecked"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col col-position-card" },
          [
            _c("v-ons-card", { staticClass: "position-card" }, [
              _c("div", { staticClass: "position-wrap" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEditing,
                        expression: "!isEditing"
                      }
                    ],
                    staticClass: "popover-menu",
                    attrs: { id: _vm.popoverId },
                    on: {
                      click: function($event) {
                        _vm.popoverMenuVisible = true
                      }
                    }
                  },
                  [
                    _c("v-ons-icon", { attrs: { icon: "md-more-vert" } }),
                    _vm._v(" "),
                    _c(
                      "v-ons-popover",
                      {
                        staticClass: "order-popover-menu",
                        attrs: {
                          cancelable: "",
                          visible: _vm.popoverMenuVisible,
                          target: "#" + _vm.popoverId,
                          "cover-target": "true",
                          direction: "down"
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.popoverMenuVisible = $event
                          }
                        }
                      },
                      [
                        _c(
                          "ons-list",
                          {
                            on: {
                              click: function($event) {
                                _vm.popoverMenuVisible = false
                              }
                            }
                          },
                          [
                            _c(
                              "ons-list-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isEditing,
                                    expression: "!isEditing"
                                  }
                                ],
                                on: { click: _vm.update }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "left icon" },
                                  [
                                    _c("v-ons-icon", {
                                      attrs: { icon: "md-edit" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "center" }, [
                                  _vm._v("Изменить товар")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ons-list-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.position.product,
                                    expression: "position.product"
                                  }
                                ],
                                on: { click: _vm.goToView }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "left icon" },
                                  [
                                    _c("v-ons-icon", {
                                      attrs: { icon: "md-image-o" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "center" }, [
                                  _vm._v("Карточка товара")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("ons-list-item", { on: { click: _vm.remove } }, [
                              _c(
                                "div",
                                { staticClass: "left icon" },
                                [
                                  _c("v-ons-icon", {
                                    attrs: { icon: "md-delete" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "center" }, [
                                _vm._v("Удалить")
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "position-index" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.position.index) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row-wrap" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isEditing,
                          expression: "!isEditing"
                        }
                      ],
                      staticClass: "col col-name",
                      on: { click: _vm.goToView }
                    },
                    [
                      _c("div", { staticClass: "position-name" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.position.name) +
                            "\n\n                                "
                        ),
                        _vm.position.is_archive
                          ? _c("div", [
                              _c("small", [
                                _vm._v(
                                  "этого товара нет у выбранного поставщика"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.position.product
                          ? _c("div", { staticStyle: { color: "grey" } }, [
                              _vm._v(
                                "\n                                    (пользовательский товар)\n                                "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEditing,
                          expression: "isEditing"
                        }
                      ],
                      staticClass: "col col-name"
                    },
                    [
                      _c("input-proposal-create", {
                        ref: "input",
                        attrs: {
                          scrollPageOnFocus: true,
                          disableScrollPage: true,
                          addedPositions: _vm.addedPositions,
                          columns: _vm.$user.canSeePrice
                            ? ["name", "price"]
                            : ["name"],
                          preventAddedChoose: true,
                          urlSearch: _vm.urlSearch
                        },
                        on: {
                          choose: _vm.chooseProposal,
                          showProposals: function($event) {
                            return _vm.$emit("showProposals")
                          },
                          hideProposals: function($event) {
                            return _vm.$emit("hideProposals")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col col-image" }, [
                    _vm.position.images &&
                    _vm.position.images.thumb100 &&
                    _vm.position.images.thumb100.hasImage
                      ? _c("img", {
                          class: ["product-image", "cursor-pointer"],
                          attrs: {
                            src: _vm.position.images.thumb100.path,
                            width: _vm.position.images.thumb100.width,
                            height: _vm.position.images.thumb100.height
                          },
                          on: { click: _vm.goToView }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isEditing,
                          expression: "!isEditing"
                        }
                      ],
                      staticClass: "col col-price"
                    },
                    [
                      _vm.position.price
                        ? _c("div", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.position.price) +
                                " р.\n                            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.position.unit) +
                            "\n                            "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEditing,
                          expression: "isEditing"
                        }
                      ],
                      staticClass: "col col-cancel-update"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn-cancel",
                          on: { click: _vm.cancelUpdate }
                        },
                        [_c("v-ons-icon", { attrs: { icon: "md-block" } })],
                        1
                      )
                    ]
                  )
                ])
              ])
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-518f2582", { render: render, staticRenderFns: staticRenderFns })
  }
}