var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v(
            "Инвентаризация №" +
              _vm._s(_vm.inventory.id) +
              " от " +
              _vm._s(_vm._f("moment")(_vm.inventory.created_at, "L"))
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$router.push(
                      "/inventory/update/" + _vm.inventory.id
                    )
                  }
                }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-edit" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        _vm._l(_vm.inventory.positions, function(position) {
          return _c(
            "ons-list-item",
            {
              key: position.id,
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    "/inventory/view/" +
                      _vm.inventory.id +
                      "/product/" +
                      position.usr_prd_id
                  )
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(_vm._s(position.name))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v(
                    "Остаток: " +
                      _vm._s(position.count) +
                      " " +
                      _vm._s(position.unit)
                  )
                ])
              ])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-677dd7dc", { render: render, staticRenderFns: staticRenderFns })
  }
}