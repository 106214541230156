<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center" >Главная</div>
        </v-ons-toolbar>

        <div class="home-page manager-page" >
            <div class="wrap xl-gutter-8 button-wrap" >
                <div class="col xl-1-1" >
                    <v-ons-button @click="$router.push('/catalog')" modifier="large" >Каталог</v-ons-button>
                </div>
            </div>

            <div class="wrap xl-gutter-8 button-wrap" >
                <div class="col xl-1-2" >
                    <v-ons-button @click="$router.push('/manager/users')" modifier="large" >Клиенты</v-ons-button>
                </div>
                <div class="col xl-1-2" >
                    <v-ons-button @click="$router.push('/manager/order')" modifier="large" >Заказы</v-ons-button>
                </div>
            </div>

            <br>
            <div class="wrap xl-gutter-8 button-wrap" >
                <div class="col xl-1-1" >
                    <v-ons-button @click="copyLink" modifier="large" >
                        <v-ons-icon icon="md-copy"></v-ons-icon>
                        Ссылка для регистрации
                    </v-ons-button>
                </div>
            </div>

            <div class="wrap xl-gutter-8 button-wrap" >
                <div class="col xl-1-1" >
                    <v-ons-button @click="promptInvitation" modifier="large" >
                        <v-ons-icon icon="md-account-add" ></v-ons-icon>
                        Отправить приглашение
                    </v-ons-button>
                </div>
            </div>

            <div class="wrap xl-gutter-8 button-wrap" v-if="$user.hasProductLinksErrors" style="margin-top: 50px" >
                <div class="col xl-1-1" >
                    <v-ons-button
                        @click="$router.push('/manager/product-link/products')"
                        modifier="large"
                        style="background: #f39f00"
                    >
                        <v-ons-icon icon="md-arrow-merge" size="25px" ></v-ons-icon>
                        Ошибки связей товаров
                    </v-ons-button>
                </div>
            </div>
        </div>

        <v-prompt-invitation-dialog ref="promptInvitationDialog" />

        <v-list-dialog
            ref="storagesDialog"
            title="Выберите склад"
            :items="storages"
        />
    </v-ons-page>
</template>

<script>

import copy from 'copy-text-to-clipboard'
import PromptInvitationDialog from './PromptInvitationDialog'
import ListDialog from "~/component/ListDialog";

export default {
    data() {
        return {
            user: {},
            storages: [],
        }
    },
    created() {
        this.$http.get('/api/user').then(response => {
            this.user = response.data;
        });
        this.loadStorages();

        this.$bus.$on('authorized', this.onAuthorized);
    },
    destroyed() {
        this.$bus.$off('authorized');
    },
    methods: {
        loadStorages() {
            return this.$http.get(
                '/api/manager/storages',
                {params: {withConfigured: true}}
            ).then(response => {
                this.storages = response.data.map(s => {
                    return {
                        id: s.id,
                        name: s.name,
                        is_default: s.is_default,
                    }
                });

                return Promise.resolve();
            });
        },
        copyLink() {
            if (this.storages.length > 1) {
               this.$refs.storagesDialog.show().then(response => {
                   let storage = this.storages.find(s => s.id === response)

                   let url = 'https://prod-zakaz.ru/register?manager=' + this.user.id

                   if (storage) {
                       url += '&storage=' + storage.id
                   }

                   copy(url);
                   this.$ons.notification.toast({
                       timeout: 3000,
                       message: 'Скопировано',
                       buttonLabel: 'OK',
                   });
               });
            } else {
                copy('https://prod-zakaz.ru/register?manager=' + this.user.id);

                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Скопировано',
                    buttonLabel: 'OK',
                });
            }
        },
        onAuthorized() {
            this.$nextTick(() => {
                let element = document.getElementsByClassName('home-page')[0];
                if (element) {
                    element.parentElement.scrollTop = 0;
                }
            });
        },
        promptInvitation() {
            this.$refs.promptInvitationDialog.show(this.storages);
        },
    },
    components: {
        'v-prompt-invitation-dialog': PromptInvitationDialog,
        'v-list-dialog': ListDialog,
    },
};
</script>