<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center" >
                Объединение товаров
            </div>

            <div class="right">
                <v-ons-toolbar-button @click="merge" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content" >
            <v-ons-list>
                <v-ons-list-header>Источник данных</v-ons-list-header>
                <v-ons-list-item>
                    <span class="list-item__title" >
                        {{ fromProduct.name }}
                    </span>
                    <span class="list-item__subtitle" >
                        Остаток: {{ fromProduct.rest }} {{ fromProduct.unit }} /
                        Кратность: {{ numeral(fromProduct.mult).format('0[.][000]') }} {{ fromProduct.unit }}
                    </span>
                    <span class="list-item__subtitle" >
                        Цена: {{ fromProduct.price }} руб.
                    </span>
                </v-ons-list-item>

                <v-ons-list-item @click="swap" >
                    <div class="center" style="justify-content: center; padding: 30px 0;" >
                        <v-ons-icon icon="md-refresh" style="font-size: 40px" ></v-ons-icon>
                    </div>
                </v-ons-list-item>

                <v-ons-list-header>Целевой товар</v-ons-list-header>
                <v-ons-list-item>
                    <span class="list-item__title" >
                        {{ toProduct.name }}
                    </span>
                    <span class="list-item__subtitle" >
                        Остаток: {{ toProduct.rest }} {{ toProduct.unit }} /
                        Кратность: {{ numeral(toProduct.mult).format('0[.][000]') }} {{ toProduct.unit }}
                    </span>
                    <span class="list-item__subtitle" >
                        Цена: {{ toProduct.price }} руб.
                    </span>
                </v-ons-list-item>

                <v-ons-list-header>Дополнительные опции копирования данных:</v-ons-list-header>
                <v-ons-list-item modifier="longdivider" >
                    <div class="left" >
                        <v-ons-checkbox input-id="optionsCopyUnit" v-model="options.copyUnit" ></v-ons-checkbox>
                    </div>
                    <label for="optionsCopyUnit" class="center label-reset">единица измерения</label>
                </v-ons-list-item>

                <v-ons-list-item modifier="longdivider" >
                    <div class="left" >
                        <v-ons-checkbox input-id="optionsCopyMult" v-model="options.copyMult" ></v-ons-checkbox>
                    </div>
                    <label for="optionsCopyMult" class="center label-reset">кратность</label>
                </v-ons-list-item>

                <v-ons-list-item modifier="longdivider" >
                    <div class="left" >
                        <v-ons-checkbox input-id="optionsLinks" v-model="options.copyLinks" :disabled="!storage" ></v-ons-checkbox>
                    </div>
                    <label for="optionsLinks" class="center label-reset">
                        связи товара и спец.цены (склад: {{ storage ? storage.name : 'Неизвестно' }})
                    </label>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-card>
                После нажатия на галочку из товара-источника в целевой товар скопируются все данные (название, фото, кратность, единица изм., ключевые слова) и все свойства (связи товара, спец. цены).
                Если товара-источника нет в наличии, то он пропадёт из каталога.
            </v-ons-card>
        </div>
    </v-ons-page>
</template>

<script>

import numeral from 'numeral'

export default {
    data() {
        return {
            loading: true,
            fromProduct: {},
            toProduct: {},
            storages: [],
            storageId: null,
            storage: null,
            options: {
                copyUnit: true,
                copyMult: true,
                copyLinks: true,
            }
        }
    },
    created() {
        if (!this.$route.params.choosed) {
            this.$router.push({ name: this.$route.matched[this.$route.matched.length - 3].name });
            return;
        }

        this.storageId = this.$user.selectedStorage ? parseInt(this.$user.selectedStorage) : null;

        this.load();
    },
    methods: {
        load() {
            let requests = [
                this.$http.get(
                    '/api/catalog/view',
                    {params: {id: this.$route.params.prd_id, storageId: this.storageId}}
                ),
                this.$http.get(
                    '/api/catalog/view',
                    {params: {id: this.$route.params.choosed, storageId: this.storageId}}
                ),
                this.$http.get('/api/manager/storages'),
            ];

            Promise.all(requests).then(responses => {
                this.toProduct = responses[0].data;
                this.fromProduct = responses[1].data;
                this.storages = responses[2].data;

                if (this.$user.priceType) {
                    this.toProduct.price = this.toProduct.prices.find(i => this.$user.priceType == i.typeId).price;
                    this.fromProduct.price = this.fromProduct.prices.find(i => this.$user.priceType == i.typeId).price;
                }

                if (this.storageId) {
                    this.storage = this.storages.find(s => s.id === this.storageId);
                } else {
                    this.storage = this.storages.find(s => s.is_default);
                }

                if (this.storage) {
                    this.storageId = this.storage.id;
                } else {
                    this.options.copyLinks = false;
                }

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        merge() {
            this.loading = true;

            let params = {
                fromProduct: this.fromProduct.id,
                toProduct: this.toProduct.id,
                options: this.options,
                storageId: this.storageId,
            };

            this.$http.post('/api/catalog/merge', params).then(() => {
                if (this.$route.name === 'manager-products-merge-confirm') {
                    this.$router.push({ name: 'manager-product-view', params: {prd_id: this.toProduct.id} });
                } else {
                    this.$router.push({ name: this.$route.matched[this.$route.matched.length - 3].name, params: {prd_id: this.toProduct.id} });
                    this.$bus.$emit('catalog-update-product')
                }

                this.$bus.$emit('catalog-update-product', this.fromProduct.id);
                this.$bus.$emit('catalog-update-product', this.toProduct.id);

                if (!this.toProduct.rest) {
                    this.$bus.$emit('catalog-hide-product', this.toProduct.id);
                }
                this.$bus.$emit('oosTabsUpdate');
                this.$bus.$emit('productsMerged');
            }, () => {
                this.loading = false;
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        swap() {
            let tmp = this.fromProduct;
            this.fromProduct = this.toProduct;
            this.toProduct = tmp;
        },
        numeral(s) {
            return numeral(s);
        },
    },
    computed: {
        storageName() {
        },
    }
};
</script>