import Index from '~/screen/manager/users/order/Index'
import Order from '~/screen/manager/order/Order'
import OrderCatalog from '~/screen/manager/order/Catalog'
import OrderCheckout from '~/screen/manager/order/Checkout'
import OrderCheckoutBranch from '~/screen/order/CreateBranch'
import OrderCheckoutEntity from '~/screen/order/CreateEntity'

import ProductRoute from '~/routes/ProductRoute'

let meta = {
    requiresLogin: true,
    requiredPermissions: ['manager'],
}

let route = [
    {
        path: 'orders',
        name: 'manager-user-orders',
        component: Index,
        meta: meta,
        children: [
            {
                path: ':order_id',
                name: 'manager-user-order',
                component: Order,
                meta: meta,
                children: [
                    {
                        path: 'catalog',
                        name: 'manager-order-catalog',
                        component: OrderCatalog,
                        params: true,
                        meta: {
                            requiresLogin: true,
                        },
                        children: [
                            ProductRoute('manager-user-order-catalog'),
                        ]
                    },
                    {
                        path: 'checkout',
                        name: 'manager-order-checkout',
                        component: OrderCheckout,
                        params: true,
                        meta: {
                            requiresLogin: true,
                        },
                        children: [
                            {
                                path: 'shipping-addresses-create',
                                name: 'manager-order-checkout-create-branch',
                                component: OrderCheckoutBranch,
                                params: true,
                                meta: {
                                    requiresLogin: true,
                                },
                                children: [
                                    {
                                        path: 'entity',
                                        name: 'manager-order-checkout-create-entity',
                                        component: OrderCheckoutEntity,
                                        meta: {
                                            requiresLogin: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    ProductRoute('manager-user-order'),
                ]
            },
        ]
    },
]

export default route;
