var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Права доступа: " + _vm._s(_vm.worker.email))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loaded,
                    expression: "loaded"
                  }
                ],
                on: { click: _vm.save }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Способ отправки заказа")]),
          _vm._v(" "),
          _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
            _c(
              "div",
              { staticClass: "select-material select--material select" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.permissions.orderSendMethod,
                        expression: "permissions.orderSendMethod"
                      }
                    ],
                    staticClass: "select-input select-input--material",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.permissions,
                          "orderSendMethod",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: "direct" } }, [
                      _vm._v("Напрямую")
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: "indirect" } }, [
                      _vm._v("Руководителю")
                    ])
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.permissions.orderSendMethod == "indirect",
                  expression: "permissions.orderSendMethod == 'indirect'"
                }
              ],
              attrs: { modifier: "longdivider" }
            },
            [
              _c(
                "label",
                {
                  staticClass: "label-reset center",
                  attrs: { for: "permissionsHidePrice" }
                },
                [_vm._v("Скрыть цены")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("v-ons-checkbox", {
                    attrs: { "input-id": "permissionsHidePrice" },
                    model: {
                      value: _vm.permissions.hidePrice,
                      callback: function($$v) {
                        _vm.$set(_vm.permissions, "hidePrice", $$v)
                      },
                      expression: "permissions.hidePrice"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.permissions.orderSendMethod == "direct",
              expression: "permissions.orderSendMethod == 'direct'"
            }
          ]
        },
        [
          _c("v-ons-list-header", [_vm._v("Оповещения")]),
          _vm._v(" "),
          _vm._l(_vm.notificationTypes, function(notificationType) {
            return _c(
              "v-ons-list-item",
              {
                key: notificationType.name,
                attrs: { modifier: "longdivider" }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "label-reset center",
                    attrs: { for: "notificationType-" + notificationType.name }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(notificationType.label) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      attrs: {
                        "input-id": "notificationType-" + notificationType.name,
                        value: notificationType.name
                      },
                      model: {
                        value: _vm.notificationValues,
                        callback: function($$v) {
                          _vm.notificationValues = $$v
                        },
                        expression: "notificationValues"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Доступные разделы")]),
          _vm._v(" "),
          _vm._l(_vm.pages, function(page) {
            return _c(
              "v-ons-list-item",
              { key: page.name, attrs: { modifier: "longdivider" } },
              [
                _c(
                  "label",
                  {
                    staticClass: "label-reset center",
                    attrs: { for: "page-" + page.name }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(page.label) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      attrs: {
                        "input-id": "page-" + page.name,
                        value: page.name,
                        disabled: _vm.isPageDisable(page.name)
                      },
                      model: {
                        value: _vm.permissions.availPages,
                        callback: function($$v) {
                          _vm.$set(_vm.permissions, "availPages", $$v)
                        },
                        expression: "permissions.availPages"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2947fc56", { render: render, staticRenderFns: staticRenderFns })
  }
}