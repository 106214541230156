var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("catalog-page", {
    attrs: {
      toolbarButtons: _vm.toolbarButtons,
      showBackButton: true,
      showInputs: true,
      allowInputs: true,
      canOpenProductPage: true,
      redirectToOrderWhenAddPositions: true,
      actionColType: "favor-tiny"
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-331e92ae", { render: render, staticRenderFns: staticRenderFns })
  }
}