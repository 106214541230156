<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Уведомления о заказах</div>
            <div class="right">
                <v-ons-toolbar-button @click="send" v-show="!loading" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <v-ons-list>
            <v-ons-list-item class="list-header--material" >
                <div class="center" >
                    Дублировать уведомления о новых заказах
                </div>
                <div class="right" >
                    <v-ons-button @click="addEmail">
                        <v-ons-icon icon="md-plus"></v-ons-icon>
                    </v-ons-button>
                </div>
            </v-ons-list-item>

            <v-ons-list-item v-for="(email, index) in emails" :key="index" >
                <div class="center" >
                    {{ email }}
                </div>
                <div class="right" >
                    <v-ons-button @click="remove(email)" modifier="outline" >
                        <v-ons-icon icon="md-close" ></v-ons-icon>
                    </v-ons-button>
                </div>
            </v-ons-list-item>

            <v-ons-list-item modifier="longdivider" >
                <div class="left" >
                    <v-ons-checkbox
                      v-model="isConfirmEmailSendingEnabled"
                      input-id="isConfirmEmailSendingEnabledLabel"
                    ></v-ons-checkbox>
                </div>
                <label class="center label-reset" for="isConfirmEmailSendingEnabledLabel">Автоматически отправлять подтверждение заказа</label>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            emails: [],
            isConfirmEmailSendingEnabled: false,
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.$http.get('/api/settings/notifications').then(response => {
                this.emails = response.data.order_emails;
                this.isConfirmEmailSendingEnabled = response.data.isConfirmEmailSendingEnabled;

                this.$nextTick(() => {
                    this.loading = false;
                });
            });
        },
        send() {
            this.$http.post('/api/settings/notifications', {emails: this.emails, isConfirmEmailSendingEnabled: this.isConfirmEmailSendingEnabled}).then(response => {
                this.$user.isConfirmEmailSendingEnabled = this.isConfirmEmailSendingEnabled;
                this.$router.go(-1);

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                    buttonLabel: 'OK',
                });
            });
        },
        remove(email) {
            this.emails = this.emails.filter(i => i != email);
        },
        addEmail() {
            this.$ons.notification.prompt('Email', {
                title: 'Добавить email',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (!response) {
                    return;
                }

                this.emails.push(response);
            });
        },
    },
};
</script>
