<template>
    <div>
        <v-ons-list @click="onClick" >
            <v-ons-list-item tappable >
                <router-link to="/settings">Вход</router-link>
            </v-ons-list-item>

            <v-ons-list-item tappable >
                <router-link to="/mymanager">Ваш менеджер</router-link>
            </v-ons-list-item>

            <v-ons-list-item tappable >
                <router-link to="/">Каталог</router-link>
            </v-ons-list-item>

            <v-ons-list-item tappable @click="$refs.downloadCatalogPromptDialog.show()" >
                <router-link to="">Скачать прайс</router-link>
            </v-ons-list-item>

            <v-ons-list-item tappable class="info-bar" >
                <router-link to="/register">Доступ к прайсу</router-link>
            </v-ons-list-item>
        </v-ons-list>

        <download-catalog-prompt-dialog ref="downloadCatalogPromptDialog" />
    </div>
</template>

<script>

import DownloadCatalogPromptDialog from '../DownloadCatalogPromptDialog'

export default {
    methods: {
        onClick() {
            this.$emit('click');
        },
    },
    components: {
        DownloadCatalogPromptDialog,
    },
}
</script>