<template>
    <v-ons-page class="default-home-page">
        <v-ons-toolbar>
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center" style="justify-content: center;">
                <div @click="$router.push('/register')" class="button--material"
                     style="background: #fff; color: #009688; cursor: pointer;">
                    Получить цены
                </div>
            </div>

            <div class="right">
                <v-ons-toolbar-button @click="$router.push('/login')">
                    <v-ons-icon icon="md-sign-in"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}">
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-card class="introduce-card">
                Поставки продуктов для вашего бизнеса.
            </v-ons-card>

            <div class="catalog-list-component">
                <v-ons-list v-for="storage in storages" :key="storage.id" class="tile-catalog">
                    <v-ons-catalog-item-tile
                        v-for="(item, index) in storage.positions"
                        :key="index"
                        :item="item"
                        :isDesktop="$window.isDesktop"
                        :numberColumns="numberColumns"
                        @clickGoToView="(e) => {clickGoToView(e, storage)}"
                    ></v-ons-catalog-item-tile>

                    <div class="text-center">
                        <v-ons-button
                            modifier="quiet"
                            @click="$router.push('/register')"
                        >
                            Показать полностью
                        </v-ons-button>
                    </div>
                </v-ons-list>
            </div>
        </div>
    </v-ons-page>
</template>

<script>

import Vue from "vue";
import CategoryItemTile from "~/component/catalog/CategoryItemTile";
import ProductItemTile from "./ProductItemTile";

export default {
    data() {
        return {
            loading: true,
            storages: [],
        }
    },
    created() {
        this.loading = false;

        if (this.$route.query.storage) {
            this.$bus.referralManagerStorage = this.$route.query.storage;
        }

        if (this.$route.query.manager) {
            this.$bus.referralManager = this.$route.query.manager;
        }

        this.$http.get(
            '/api/catalog/popular-by-storages',
            {
                params: {
                    first: this.$route.query.first || this.$route.query.storage || this.$bus.referralManagerStorage || null
                }
            }
        ).then(response => {
            let storages = response.data;

            this.storages = storages.map(storage => {
                let items = storage.products.map(product => {
                    product.type = 'product'
                    return product
                })

                items.unshift({
                    name: 'склад "' + storage.name + '"',
                    type: 'category',
                })

                storage.positions = this.getItemsByRows(items)
                return storage
            })

            this.loading = false;
        });
    },
    methods: {
        clickGoToView() {
            this.$router.push('/login')
        },
        getItemsByRows(items) {
            let row = [];
            let itemsByRows = [];

            for (let item of items) {
                if (item.type === 'category') {
                    if (row.length > 0) {
                        itemsByRows.push(row);
                        row = [];
                    }

                    itemsByRows.push([item]);
                    continue;
                }

                row.push(item);

                if (row.length === this.numberColumns) {
                    itemsByRows.push(row);
                    row = [];
                }
            }

            if (row.length > 0) {
                itemsByRows.push(row);
            }

            return itemsByRows;
        },
        renderItem(i) {
            let self = this;
            let item = this.itemsByRows[i];

            return new Vue({
                render(h) {
                    if (!item) {
                        return h('v-ons-list-item');
                    }

                    if (item.length === 1 && item[0].type === 'category') {
                        return h(CategoryItemTile, {
                            props: {
                                item: item[0],
                            }
                        });
                    }

                    let children = [];
                    for (let child of item) {
                        children.push(h(ProductItemTile, {
                            props: {
                                item: child,
                                isDesktop: self.isDesktop,
                                actionColType: self.actionColType,
                                canOpenProductPage: self.canOpenProductPage,
                                showInputs: self.showInputs,
                                allowInputs: self.allowInputs,
                                selectedPriceType: self.selectedPriceType,
                                counts: self.counts,
                                checked: self.checked,
                                lastImportDate: self.lastImportDate,
                                width: (100 / self.numberColumns) + '%',
                            },
                            on: {
                                clickGoToView: self.clickGoToView,
                            },
                        }))
                    }

                    return h('div',
                        {
                            attrs: {
                                class: 'tile-row-product',
                            },
                        },
                        children
                    );
                }
            });
        },
    },
    computed: {
        numberColumns() {
            if (this.$window.width > 800) {
                return 3;
            }

            return 2;
        },
        itemsLength() {
            return this.itemsByRows.length;
        },
        width() {
            return (100 / this.numberColumns) + '%';
        },
    },
    components: {
        'v-ons-catalog-item-tile': {
            render(h) {
                let item = this.item;

                if (!item) {
                    return h('v-ons-list-item');
                }

                if (item.length === 1 && item[0].type === 'category') {
                    return h(CategoryItemTile, {
                        props: {
                            item: item[0],
                        }
                    });
                }

                let children = [];
                for (let child of item) {
                    children.push(h(ProductItemTile, {
                        props: {
                            item: child,
                            isDesktop: this.isDesktop,
                            actionColType: this.actionColType,
                            canOpenProductPage: this.canOpenProductPage,
                            showInputs: this.showInputs,
                            allowInputs: this.allowInputs,
                            selectedPriceType: this.selectedPriceType,
                            counts: this.counts,
                            checked: this.checked,
                            lastImportDate: this.lastImportDate,
                            width: (100 / this.numberColumns) + '%',
                        },
                        on: {
                            toggleFavor: e => this.$emit('toggleFavor', e),
                            clickGoToView: e => this.$emit('clickGoToView', e),
                            countEnterDown: e => this.$emit('onCountEnterDown', e),
                            countShiftEnterDown: e => this.$emit('onCountShiftEnterDown', e),
                        },
                    }))
                }

                return h('div',
                    {
                        attrs: {
                            class: 'tile-row-product',
                        },
                    },
                    children
                );
            },
            props: [
                "item",
                "isDesktop",
                "actionColType",
                "checked",
                "canOpenProductPage",
                "showInputs",
                "allowInputs",
                "selectedPriceType",
                "counts",
                "catalogViewType",
                "numberColumns",
            ],
        },
    }
};
</script>