<template>
    <users-list
        :title="'Сделать сотрудником: ' + user.email"
        :url="'/api/manager/users/' + $route.params.user_id + '/possible-directors'"
        showBackButton="true"
        @choose="choose"
        shouldShowViewParams="true"
    />
</template>

<script>

import UsersList from '../component/user/UsersList'

export default {
    data() {
        return {
            user: {},
        }
    },
    created() {
        this.$http.get('/api/manager/users/' + this.$route.params.user_id).then(response => {
            this.user = response.data;
        });

        this.$http.get('/api/manager/users/managers').then(response => {
            this.managers = response.data;
        });
    },
    methods: {
        choose(user) {
            this.$http.post(`/api/manager/users/${this.user.id}/become-worker`, {director: user.id}).then(() => {
                this.$router.go(-1);
                this.$bus.$emit('updateUser');
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Сотрудник закреплен.',
                    buttonLabel: 'OK',
                });
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK',
                });
            });
        }
    },
    components: {
        UsersList,
    }
};
</script>