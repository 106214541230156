var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Связь с 1С")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            _vm._l(_vm.connections, function(connnection) {
              return _c(
                "div",
                [
                  _c("v-ons-list-header", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(connnection.entity.name) +
                        "\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    " +
                        _vm._s(connnection.branch.name) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-ons-list-item",
                    [
                      _c("v-ons-input", {
                        attrs: { type: "text" },
                        model: {
                          value: connnection.code,
                          callback: function($$v) {
                            _vm.$set(connnection, "code", $$v)
                          },
                          expression: "connnection.code"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1dd73c9e", { render: render, staticRenderFns: staticRenderFns })
  }
}