var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSearchActive,
              expression: "!isSearchActive"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _vm.showBackButton
                ? _c("v-ons-back-button")
                : _c("v-ons-toggle-sidebar-button")
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchActive } },
                [_c("v-ons-icon", { attrs: { icon: "md-search" } })],
                1
              ),
              _vm._v(" "),
              _vm.shouldShowViewParams || _vm.shouldShowCreateUserButton
                ? _c(
                    "toolbar-popover-menu",
                    { attrs: { "popover-class": "stick-right" } },
                    [
                      _vm.shouldShowViewParams
                        ? _c("toolbar-popover-menu-item", {
                            attrs: {
                              text: "Опции просмотра",
                              icon: "md-format-list-bulleted"
                            },
                            on: { click: _vm.showViewParamsModal }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.shouldShowCreateUserButton
                        ? _c("toolbar-popover-menu-item", {
                            attrs: {
                              text: "Создать пользователя",
                              icon: "md-plus"
                            },
                            on: { click: _vm.createUserModal }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSearchActive,
              expression: "isSearchActive"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchDeactive } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _c(
              "form",
              {
                attrs: { action: "", autocomplete: "off" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return function() {}.apply(null, arguments)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchQuery,
                      expression: "searchQuery"
                    }
                  ],
                  staticStyle: {
                    color: "#fff",
                    background: "none",
                    border: "none",
                    height: "34px",
                    "line-height": "34px",
                    "border-bottom": "1px solid #fff"
                  },
                  attrs: {
                    id: "search-input",
                    autocomplete: "off",
                    name: "input-search"
                  },
                  domProps: { value: _vm.searchQuery },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchQuery = $event.target.value
                    }
                  }
                })
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.users,
                  expression: "users"
                }
              ]
            },
            [
              _vm._l(_vm.users, function(user) {
                return _c(
                  "ons-list-item",
                  {
                    key: user.id,
                    attrs: { modifier: "chevron longdivider", tappable: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("choose", user)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "left",
                        staticStyle: {
                          "min-width": "40px",
                          "text-align": "center"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(user.id) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "center" },
                      [
                        _c("span", { staticClass: "list-item__title" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(user.email) +
                              "\n                        "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: user.user_type === "manager",
                                  expression: "user.user_type === 'manager'"
                                }
                              ],
                              staticStyle: {
                                "margin-left": "5px",
                                color: "#ff5a00"
                              }
                            },
                            [_vm._v("M")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: user.user_type === "worker",
                                  expression: "user.user_type === 'worker'"
                                }
                              ],
                              staticStyle: {
                                "margin-left": "5px",
                                color: "#001fff"
                              }
                            },
                            [_vm._v("W")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item-subtitles", {
                          attrs: {
                            item: user,
                            fields: _vm.viewParams,
                            selected: _vm.$user.usersSelectedSubtitles
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.page < _vm.pageCount,
                      expression: "page < pageCount"
                    }
                  ],
                  staticStyle: { "text-align": "center", margin: "10px 0" }
                },
                [
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { "min-width": "145px" },
                      on: { click: _vm.loadMore }
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loadingMore,
                              expression: "!loadingMore"
                            }
                          ]
                        },
                        [_vm._v("Показать ещё")]
                      ),
                      _vm._v(" "),
                      _c("v-ons-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loadingMore,
                            expression: "loadingMore"
                          }
                        ],
                        attrs: { size: "30px", spin: "", icon: "md-spinner" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.users.length === 0,
                  expression: "users.length === 0"
                }
              ],
              staticClass: "list-empty",
              style: { height: _vm.$window.height - 56 + "px" }
            },
            [_vm._v("\n            Ничего не найдено\n        ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "viewParamsModal",
        attrs: { multiple: "true", title: "Опции просмотра" }
      }),
      _vm._v(" "),
      _c("v-create-user-modal", {
        ref: "createUserModal",
        attrs: { storages: _vm.storages }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3ba90c60", { render: render, staticRenderFns: staticRenderFns })
  }
}