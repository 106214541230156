<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Инвентаризация №{{ inventory.id }} от {{ inventory.created_at | moment('L') }}</div>
            <div class="right" >
                <v-ons-toolbar-button @click="$router.push('/inventory/update/'+ inventory.id)" >
                    <v-ons-icon icon="md-edit"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <ons-list >
            <ons-list-item
                v-for="position in inventory.positions"
                :key="position.id"
                modifier="chevron longdivider"
                tappable
                @click="$router.push('/inventory/view/'+ inventory.id +'/product/'+position.usr_prd_id)" >
                <div class="center" >
                    <span class="list-item__title">{{ position.name }}</span>
                    <span class="list-item__subtitle">Остаток: {{ position.count }} {{ position.unit }}</span>
                </div>
            </ons-list-item>
        </ons-list>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            inventory: {},
        }
    },
    created() {
        this.$http.get('/api/inventory/view', {params: {id: this.$route.params.id}}).then(response => {
            this.inventory = response.data;
        });
    },
};
</script>

