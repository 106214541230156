var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-splitter",
    [
      _c("sidebar", { ref: "sidebar" }),
      _vm._v(" "),
      _c(
        "v-ons-splitter-content",
        [
          _c("v-ons-navigator", {
            attrs: {
              id: "navigator",
              swipeable: "",
              "swipe-target-width": "200px",
              "page-stack": _vm.pageStack,
              "pop-page": _vm.goBack
            },
            on: {
              "toggle-sidebar": function($event) {
                return _vm.$refs.sidebar.toggle()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7ba5bd90", { render: render, staticRenderFns: staticRenderFns })
  }
}