var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "user-special-prices" },
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSearchActive,
              expression: "!isSearchActive"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c("v-ons-back-button", {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.goBack.apply(null, arguments)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [_vm._v("Спецпредложения")]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loaded,
                  expression: "loaded"
                }
              ],
              staticClass: "right"
            },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchActive } },
                [_c("v-ons-icon", { attrs: { icon: "md-search" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "toolbar-popover-menu",
                { attrs: { "popover-class": "stick-right" } },
                [
                  _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: _vm.showMinPrice
                        ? "Скрыть мин. цену"
                        : "Показать мин. цену",
                      icon: _vm.showMinPrice ? "md-eye" : "md-eye-off"
                    },
                    on: {
                      click: function($event) {
                        _vm.showMinPrice = !_vm.showMinPrice
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: "Добавить из шаблона",
                      icon: "md-collection-plus"
                    },
                    on: { click: _vm.addFromTemplate }
                  }),
                  _vm._v(" "),
                  _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: "Скачать спецификацию",
                      icon: "md-download"
                    },
                    on: { click: _vm.download }
                  }),
                  _vm._v(" "),
                  _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: "Жёлтые ценники",
                      icon: "md-bookmark-outline"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$router.push({
                          name: "manager-user-special-prices-settings"
                        })
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("toolbar-popover-menu-item", {
                    attrs: { text: "Очистить", icon: "md-delete" },
                    on: { click: _vm.deleteAll }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSearchActive,
              expression: "isSearchActive"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchDeactive } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _c(
              "form",
              {
                attrs: { action: "", autocomplete: "off" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return function() {}.apply(null, arguments)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchQuery,
                      expression: "searchQuery"
                    }
                  ],
                  staticStyle: {
                    color: "#fff",
                    background: "none",
                    border: "none",
                    height: "34px",
                    "line-height": "34px",
                    "border-bottom": "1px solid #fff"
                  },
                  attrs: {
                    id: "specialprice-search-input",
                    autocomplete: "off",
                    name: "input-search"
                  },
                  domProps: { value: _vm.searchQuery },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchQuery = $event.target.value
                    }
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchActive } },
                [_c("v-ons-icon", { attrs: { icon: "md-search" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "toolbar-popover-menu",
                { attrs: { "popover-class": "stick-right" } },
                [
                  _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: _vm.showMinPrice
                        ? "Скрыть мин. цену"
                        : "Показать мин. цену",
                      icon: _vm.showMinPrice ? "md-eye" : "md-eye-off"
                    },
                    on: {
                      click: function($event) {
                        _vm.showMinPrice = !_vm.showMinPrice
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: "Скачать спецификацию",
                      icon: "md-download"
                    },
                    on: { click: _vm.download }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loaded,
                expression: "!loaded"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-item", [
                _vm._v(
                  "\n                ID клиента: " +
                    _vm._s(_vm.userId) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _vm._v(
                  "\n                Юр. лицо: " +
                    _vm._s(
                      _vm.entities
                        .map(function(i) {
                          return i.legal_name
                        })
                        .filter(function(i) {
                          return i
                        })
                        .join(", ")
                    ) +
                    "\n            "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { style: { "padding-bottom": _vm.$window.height - 369 + "px" } },
            [
              _vm._l(_vm.items, function(product, index) {
                return _c(
                  "div",
                  { key: product.product_id, staticClass: "product-wrap" },
                  [
                    _c("div", { staticClass: "product-name" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(index + 1) +
                          " -\n                    " +
                          _vm._s(product.name) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("table", { staticClass: "table-params" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { title: "Удалить", rowspan: "2" },
                              on: {
                                click: function($event) {
                                  return _vm.remove(product)
                                }
                              }
                            },
                            [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [_vm._v("Ед.")]),
                          _vm._v(" "),
                          _c("td", [_vm._v("Цена прайс")]),
                          _vm._v(" "),
                          _c("td", [_vm._v("Цена спец.")])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(product.unit))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(product.productPrice) +
                                "\n                                "
                            ),
                            _c("span", { staticClass: "font-rouble" }, [
                              _vm._v("o")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.prices[product.id],
                                  expression: "prices[product.id]"
                                }
                              ],
                              style: {
                                width: "70px",
                                textAlign: "center",
                                border: _vm.emptyPositions.includes(product.id)
                                  ? "1px solid red"
                                  : "1px solid #a9a9a9"
                              },
                              attrs: {
                                type: "number",
                                autocomplete: "off",
                                name: "special-price-" + product.id,
                                id: "special-price-" + product.id
                              },
                              domProps: { value: _vm.prices[product.id] },
                              on: {
                                keydown: function($event) {
                                  return _vm.onKeydown($event, product.id)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.prices,
                                    product.id,
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                    Наличие:\n                    "
                      ),
                      parseFloat(product.rest) > 0
                        ? _c("span", [_vm._v("Да")])
                        : _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("Нет")
                          ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showMinPrice,
                            expression: "showMinPrice"
                          }
                        ]
                      },
                      [
                        _vm._v(
                          "\n                    Мин. цена:\n                    "
                        ),
                        _vm.prices[product.id] &&
                        parseFloat(_vm.prices[product.id]) <
                          parseFloat(product.minPrice)
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(product.minPrice))
                            ])
                          : _c("span", [_vm._v(_vm._s(product.minPrice))])
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                { staticStyle: { "margin-bottom": "250px" } },
                [
                  _c("input-proposal", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      scrollPageOnFocus: true,
                      disableScrollPage: true,
                      model: _vm.product,
                      addedPositions: _vm.products.map(function(i) {
                        return i.product_id
                      }),
                      urlSearch:
                        "/api/manager/users/" + _vm.userId + "/catalog-search"
                    },
                    on: {
                      "update:model": function($event) {
                        _vm.product = $event
                      },
                      choose: _vm.chooseProduct
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "addFromTemplateDialog",
        attrs: { title: "Добавить из шаблона", multiple: true }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5a860410", { render: render, staticRenderFns: staticRenderFns })
  }
}