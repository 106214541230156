var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v(_vm._s(_vm.user.email))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                attrs: { title: "Сохранить" },
                on: { click: _vm.save }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "permissionsSeeReports" }
                  },
                  [_vm._v("\n                    Отчёты\n                ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: {
                        value: true,
                        "input-id": "permissionsSeeReports"
                      },
                      model: {
                        value: _vm.permissions.canSeeReports,
                        callback: function($$v) {
                          _vm.$set(_vm.permissions, "canSeeReports", $$v)
                        },
                        expression: "permissions.canSeeReports"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "permissionsProductLinks" }
                  },
                  [
                    _vm._v(
                      "\n                    Редактирование связей товаров и OOS\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: {
                        value: true,
                        "input-id": "permissionsProductLinks"
                      },
                      model: {
                        value: _vm.permissions.canManageProductLinks,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.permissions,
                            "canManageProductLinks",
                            $$v
                          )
                        },
                        expression: "permissions.canManageProductLinks"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "permissionsCatalog" }
                  },
                  [
                    _vm._v(
                      "\n                    Настройка каталога\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: {
                        value: true,
                        "input-id": "permissionsCatalog",
                        disabled: !_vm.permissions.canManageProductLinks
                      },
                      model: {
                        value: _vm.permissions.canManageCatalog,
                        callback: function($$v) {
                          _vm.$set(_vm.permissions, "canManageCatalog", $$v)
                        },
                        expression: "permissions.canManageCatalog"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "permissionsPriceAndPhoto" }
                  },
                  [
                    _vm._v(
                      "\n                    Скачивание прайса и фото товара\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: {
                        value: true,
                        "input-id": "permissionsPriceAndPhoto"
                      },
                      model: {
                        value: _vm.permissions.canDownloadPriceAndPhoto,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.permissions,
                            "canDownloadPriceAndPhoto",
                            $$v
                          )
                        },
                        expression: "permissions.canDownloadPriceAndPhoto"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "permissionsUpdateProduct" }
                  },
                  [
                    _vm._v(
                      "\n                    Редактирование карточки товара\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: {
                        value: true,
                        "input-id": "permissionsUpdateProduct"
                      },
                      model: {
                        value: _vm.permissions.canUpdateProduct,
                        callback: function($$v) {
                          _vm.$set(_vm.permissions, "canUpdateProduct", $$v)
                        },
                        expression: "permissions.canUpdateProduct"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5d948472", { render: render, staticRenderFns: staticRenderFns })
  }
}