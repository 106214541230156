var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-alert-dialog",
    {
      attrs: { modifier: "rowfooter", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("Подтвердить заказ?")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInvoiceNumber,
              expression: "showInvoiceNumber"
            }
          ],
          staticStyle: { "margin-top": "5px", "margin-bottom": "15px" }
        },
        [
          _c("p", [_vm._v("Номер накладной:")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("v-ons-input", {
                model: {
                  value: _vm.invoiceNumber,
                  callback: function($$v) {
                    _vm.invoiceNumber = $$v
                  },
                  expression: "invoiceNumber"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showChoiceWithNoty
        ? _c(
            "div",
            { staticStyle: { "margin-top": "5px" } },
            [
              _c("v-ons-checkbox", {
                staticStyle: { "vertical-align": "middle" },
                attrs: { "input-id": "confirm-order-dialog-with-noty" },
                model: {
                  value: _vm.withNoty,
                  callback: function($$v) {
                    _vm.withNoty = $$v
                  },
                  expression: "withNoty"
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticStyle: {
                    "font-weight": "normal",
                    "vertical-align": "middle",
                    margin: "0"
                  },
                  attrs: { for: "confirm-order-dialog-with-noty" }
                },
                [_vm._v("Отправить уведомление")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("v-ons-alert-dialog-button", { on: { click: _vm.ok } }, [
            _vm._v("Подтвердить")
          ]),
          _vm._v(" "),
          _c("v-ons-alert-dialog-button", { on: { click: _vm.cancel } }, [
            _vm._v("Отмена")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c1cbb11a", { render: render, staticRenderFns: staticRenderFns })
  }
}