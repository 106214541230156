import ProductIndex from '~/screen/catalog/Index';
import ProductView from '~/screen/catalog/View';
import ProductUpdate from '~/screen/manager/product/Update';
import ProductSpecial from '~/screen/manager/product/Special';
import ProductMergeReplacements from '~/screen/manager/product/MergeReplacements';
import ProductMergeConfirm from '~/screen/manager/product/MergeConfirm';
import ProductUsers from '~/screen/manager/product/Users';
import ProductUsersReplace from '~/screen/manager/product/Replace';
import ProductAvailabilityBalance from '~/screen/manager/product/ProductAvailabilityBalance';

let route = [
    {
        path: 'products',
        name: 'manager-product',
        component: ProductIndex,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: ':prd_id',
                name: 'manager-product-view',
                component: ProductView,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'update',
                        name: 'manager-product-update',
                        component: ProductUpdate,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                    {
                        path: 'special',
                        name: 'manager-product-special',
                        component: ProductSpecial,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                    {
                        path: 'merge',
                        name: 'manager-product-merge-replacements',
                        component: ProductMergeReplacements,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                        children: [
                            {
                                path: ':choosed',
                                name: 'manager-product-merge-confirm',
                                component: ProductMergeConfirm,
                                meta: {
                                    requiresLogin: true,
                                    requiredPermissions: ['manager'],
                                },
                            },
                        ]
                    },
                    {
                        path: 'users',
                        name: 'manager-product-users',
                        component: ProductUsers,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                        children: [
                            {
                                path: 'replace',
                                name: 'manager-product-users-replace',
                                component: ProductUsersReplace,
                                meta: {
                                    requiresLogin: true,
                                    requiredPermissions: ['manager'],
                                },
                            },
                        ],
                    },
                    {
                        path: 'availability-balance',
                        name: 'manager-product-availability-balance',
                        component: ProductAvailabilityBalance,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ]
            },
            {
                path: 'merge/:prd_id/:choosed',
                name: 'manager-products-merge-confirm',
                component: ProductMergeConfirm,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
        ]
    },
];

export default route;