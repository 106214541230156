<template>
    <v-ons-list-item class="list-item-product" v-show="!item.isHidden" >
        <label v-if="actionColType === 'checkbox'" class="col-checkbox" >
            <input type="checkbox" v-model="isChecked" />
        </label>

        <label v-if="actionColType === 'favor' || (actionColType === 'favor-tiny' && isDesktop)" class="col-checkbox cursor-pointer" @click="toggleFavor" >
            <v-ons-icon
                icon="md-star"
                :title="favorButtonTitle"
                class="icon"
                size="20px"
                :style="{color: item.isInMyProducts ? '#ffe000' : '#e3e3e3'}"
            ></v-ons-icon>
        </label>

        <div :class="['col-name', canOpenProductPage ? 'cursor-pointer' : '']" @click="clickGoToView" >
            <div class="name" >
                {{ item.name }}
            </div>

            <div class="product-icons" >
                <div
                    v-show="item.is_special"
                    class="vip-icon icon"
                >
                    VIP
                </div>
                <v-ons-icon
                    v-show="actionColType === 'favor-tiny' && item.isInMyProducts && !isDesktop"
                    icon="md-star"
                    title='Товар добавлен в "Мои товары"'
                    class="star-icon icon"
                ></v-ons-icon>
                <div
                    title="У этого товара есть фото"
                    v-show="item.images && item.images.thumb100.hasImage"
                    class="has-image-icon icon"
                ></div>
            </div>
        </div>

        <div v-show="isDesktop && shouldShowRest" class="col-rest" >
            <span v-if="item.is_wait" >0 {{ item.unit }}</span>
            <span v-else >{{ item.rest }} {{ item.unit }}</span>
        </div>

        <div :class="['col-price', item.specialPrice.isShowMark ? 'is-low-special-price' : '']" v-show="$user.canSeePrice" >
            <div class="price-block text-right" >
                <span v-if="item.is_wait" >ждём</span>
                <span v-else >{{ calcPrice(item) }} р.</span>
            </div>

            <div class="text-right" >
                <span v-show="!item.is_deleted" >{{ item.unit }}</span>
                <span
                    class="notify-icon"
                    @click="showOverweightRestAlert(item)"
                    v-show="shouldShowOverweightRestAlert"
                >
                    !
                </span>
            </div>

            <div class="text-left" v-show="!isDesktop && shouldShowRest" style="font-size: 10px; color: grey;" >
                <span v-if="item.is_wait" >0 {{ item.unit }}</span>
                <span v-else >{{ item.rest }} {{ item.unit }}</span>
            </div>
        </div>

        <div v-if="allowInputs && (showInputs || isDesktop)" class="col-count" >
            <input
                type="number"
                v-model="counts[item.id]"
                v-round
                :id="'position-count-' + item.id"
                :step="item.mult"
                @keydown="countKeydownHandler"
            />
        </div>
    </v-ons-list-item>
</template>

<script>

import numeral from 'numeral'

export default {
    props: [
        'item',
        'isDesktop',
        'actionColType',
        'checked',
        'canOpenProductPage',
        'showInputs',
        'allowInputs',
        'selectedPriceType',
        'counts',
        'lastImportDate',
        'shouldShowRest',
    ],
    data() {
        return {
            isChecked: false,
        }
    },
    created() {
        this.isChecked = this.checked.includes(this.item.id);
        this.$bus.$on('product-update-favor-' + this.item.id, this.updateFavor);
    },
    destroyed() {
        this.$bus.$off('product-update-favor-' + this.item.id, this.updateFavor);
    },
    methods: {
        numeral(s) {
            return numeral(s);
        },
        toggleFavor() {
            this.$emit('toggleFavor', this.item);
        },
        updateFavor(value) {
            this.item.isInMyProducts = value;
        },
        clickGoToView() {
            this.$emit('clickGoToView', this.item);
        },
        showOverweightRestAlert(position) {
            let message = '';
            let lastImportDate = this.$moment.unix(this.lastImportDate).format("HH:mm от L");
            let rest = numeral(position.rest).format('0[.]00');

            if (parseInt(position.rest) === 0) {
                message += `Товар кончился: ${position.deletedAt} <br><br>`;
                message += `${position.name} <br> <b>Нет в наличии</b> <br><br>`;
            } else {
                message += `По данным на <b>${lastImportDate}</b> <br><br>`;
                message += `${position.name} <br> всего <b>${rest} ${position.unit}</b> <br><br>`;
            }

            message += `
                Вы можете добавить товар в полном объёме и отправить заказ как есть, или можете подобрать замену.<br><br>
                Если товар ещё не поступил, то менеджер по возможности предложит альтернативу.`;

            this.$ons.notification.alert({title: '', message: message});
        },
        calcPrice(item) {
            if (!this.$user.isManager || !item.prices) {
                return item.price;
            }

            if (!this.selectedPriceType) {
                return item.price;
            }

            let pr = item.prices.find(i => this.selectedPriceType == i.typeId);

            if (pr) {
                return pr.price;
            }

            return item.price;
        },
        incCount() {
            let mult = 1;
            let count = this.counts[this.item.id] || 0;

            if (this.item.mult) {
                mult = parseFloat(this.item.mult);
            }

            if (!count) {
                count = mult;
            } else {
                count = parseFloat(count) + mult;
            }

            this.$set(this.counts, this.item.id, numeral(count).format('0[.][000]'));
        },
        decCount() {
            let mult = 1;
            let count = this.counts[this.item.id] || 0;

            if (this.item.mult) {
                mult = parseFloat(this.item.mult);
            }

            count = parseFloat(count) - mult;

            if (count <= 0) {
                count = '';
            } else {
                count = numeral(count).format('0[.][000]');
            }

            this.$set(this.counts, this.item.id, numeral(count).format('0[.][000]'));
        },
        countKeydownHandler(e) {
            // plus
            if (e.keyCode === 107 || e.keyCode === 187) {
                e.preventDefault();
                this.incCount();
                return;
            }

            // minus
            if (e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
                this.decCount();
                return;
            }

            if ((e.keyCode === 13 || e.keyCode === 9) && e.shiftKey) {
                e.preventDefault();
                this.$emit('countShiftEnterDown', this.item);
                return;
            }

            if (e.keyCode === 13 || e.keyCode === 9) {
                e.preventDefault();
                this.$emit('countEnterDown', this.item);
            }
        },
    },
    computed: {
        shouldShowOverweightRestAlert() {
            if (!this.showInputs) {
                return false;
            }

            if (this.item.isInMyProducts && this.$user.restAlwaysAvailable) {
                return false;
            }

            return parseFloat(this.counts[this.item.id]) > parseFloat(this.item.rest);
        },
        favorButtonTitle() {
            if (this.item.isInMyProducts) {
                return 'Товар в избранном';
            }

            return 'Добавить в "Мои товары"';
        },
    },
    watch: {
        isChecked() {
            if (this.isChecked && !this.checked.includes(this.item.id)) {
                this.checked.push(this.item.id);
            }

            if (!this.isChecked && this.checked.includes(this.item.id)) {
                this.checked.splice(this.checked.indexOf(this.item.id), 1);
            }
        },
        checked() {
            this.isChecked = this.checked.includes(this.item.id);
        }
    },
};
</script>