var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "product-view" },
    [
      _c("v-ons-toolbar", [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v(_vm._s(_vm.product.category))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading && _vm.product.id,
                expression: "!loading && product.id"
              }
            ],
            staticClass: "right"
          },
          [
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Поделиться" }, on: { click: _vm.share } },
              [_c("v-ons-icon", { attrs: { icon: "md-share" } })],
              1
            ),
            _vm._v(" "),
            _vm.shouldShowUpdateButton
              ? _c(
                  "v-ons-toolbar-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push(
                          _vm.$router.currentRoute.path + "/update"
                        )
                      }
                    }
                  },
                  [
                    _c("v-ons-icon", {
                      attrs: {
                        icon: "md-edit",
                        title: "Редактировать карточку товара"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.$user.isCustomer
              ? _c(
                  "v-ons-toolbar-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.shouldShowAddFavorButton,
                        expression: "shouldShowAddFavorButton"
                      }
                    ],
                    attrs: { title: 'Добавить в "Мои товары"' },
                    on: { click: _vm.addFavor }
                  },
                  [_c("v-ons-icon", { attrs: { icon: "md-star" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.$user.isCustomer
              ? _c(
                  "v-ons-toolbar-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.shouldShowDeleteFavorButton,
                        expression: "shouldShowDeleteFavorButton"
                      }
                    ],
                    attrs: { title: 'Удалить из "Мои товары"' },
                    on: { click: _vm.deleteFavor }
                  },
                  [
                    _c("v-ons-icon", {
                      staticStyle: { color: "#ffe000" },
                      attrs: { icon: "md-star" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.$user.isCustomer
              ? _c(
                  "v-ons-toolbar-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.shouldShowAddCartButton,
                        expression: "shouldShowAddCartButton"
                      }
                    ],
                    attrs: { title: "Добавить в заказ" },
                    on: { click: _vm.addCart }
                  },
                  [_c("v-ons-icon", { attrs: { icon: "md-shopping-cart" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "toolbar-popover-menu",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.shouldShowAddFavorButton ||
                      _vm.shouldShowDeleteFavorButton ||
                      _vm.shouldShowAddCartButton ||
                      _vm.$user.isManager,
                    expression:
                      "shouldShowAddFavorButton || shouldShowDeleteFavorButton || shouldShowAddCartButton || $user.isManager"
                  }
                ],
                attrs: { "popover-class": "stick-right" }
              },
              [
                _c("toolbar-popover-menu-item", {
                  attrs: { text: "Поделиться", icon: "md-share" },
                  on: { click: _vm.share }
                }),
                _vm._v(" "),
                _vm.$user.isCustomer
                  ? _c("toolbar-popover-menu-item", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.shouldShowAddFavorButton,
                          expression: "shouldShowAddFavorButton"
                        }
                      ],
                      attrs: {
                        text: 'Добавить в "Мои товары"',
                        icon: "md-star"
                      },
                      on: { click: _vm.addFavor }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.$user.isCustomer
                  ? _c("toolbar-popover-menu-item", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.shouldShowDeleteFavorButton,
                          expression: "shouldShowDeleteFavorButton"
                        }
                      ],
                      attrs: {
                        text: 'Удалить из "Мои товары"',
                        icon: "md-star"
                      },
                      on: { click: _vm.deleteFavor }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.$user.isCustomer
                  ? _c("toolbar-popover-menu-item", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.shouldShowAddCartButton,
                          expression: "shouldShowAddCartButton"
                        }
                      ],
                      attrs: {
                        text: "Добавить в заказ",
                        icon: "md-shopping-cart"
                      },
                      on: { click: _vm.addCart }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.$user.isManager
                  ? _c("toolbar-popover-menu-item", {
                      attrs: { text: "Специальный товар", icon: "md-flash" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            _vm.$router.currentRoute.path + "/special"
                          )
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.$user.isManager
                  ? _c("toolbar-popover-menu-item", {
                      attrs: { text: "Скопировать параметры", icon: "md-link" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            _vm.$router.currentRoute.path + "/merge"
                          )
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.shouldShowProductLinksButton
                  ? _c("toolbar-popover-menu-item", {
                      style: !_vm.availProductLinksButton
                        ? "color: #b4b4b4;"
                        : "",
                      attrs: {
                        disabled: !_vm.availProductLinksButton,
                        text: _vm.availProductLinksButton
                          ? "Связи товара"
                          : "Нет связей товара",
                        icon: "md-arrow-merge"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            _vm.$router.currentRoute.path + "/users"
                          )
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.shouldShowProductBalanceButton
                  ? _c("toolbar-popover-menu-item", {
                      attrs: {
                        text: "Наличие и остаток",
                        icon: "md-arrow-merge"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            _vm.$router.currentRoute.path +
                              "/availability-balance"
                          )
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        !_vm.failed
          ? _c(
              "div",
              [
                !_vm.loading
                  ? _c("v-product-card", { attrs: { product: _vm.product } })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.failed
          ? _c(
              "div",
              {
                staticClass: "page-failed",
                style: { height: _vm.$window.height - 56 + "px" }
              },
              [
                _c("div", { staticClass: "message" }, [
                  _vm._v(_vm._s(_vm.errorMessage))
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "sushiroduct-logo" }, [
          _c("div", { staticClass: "wrap xl-gutter-16" }, [
            _c("div", { staticClass: "col col-logo" }, [
              _c("img", {
                attrs: { src: "/img/logo-icon-180x180.png", alt: "Закуп!" }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col col-text" }, [
              _c("div", { staticClass: "wrap-text" }, [
                _vm._v(
                  "\n                        prod-zakaz.ru\n                    "
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      !_vm.$user.isGuest
        ? _c("select-add-method-modal", { ref: "selectAddMethodModal" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-67a72275", { render: render, staticRenderFns: staticRenderFns })
  }
}