var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      staticClass: "merge-categories-dialog",
      attrs: { visible: _vm.visible, cancelable: "" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "alert-dialog-container alert-dialog-container--material"
        },
        [
          _c(
            "div",
            { staticClass: "alert-dialog-title alert-dialog-title--material" },
            [_vm._v("\n            Объединение в группу:\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "alert-dialog-content alert-dialog-content--material"
            },
            [
              _c(
                "v-ons-list",
                { staticClass: "category-list" },
                _vm._l(_vm.categories, function(category, key) {
                  return _c(
                    "ons-list-item",
                    {
                      key: category.id,
                      staticClass: "cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.copyName(category)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "center" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(category.name) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("v-ons-icon", {
                            attrs: {
                              icon: "md-copy",
                              title: "Скопировать имя в поле ввода"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "input-title" }, [
                _vm._v("\n                Новое имя раздела\n            ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-wrap" },
                [
                  _c("ons-input", { attrs: { modifier: "material" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.valueProp,
                          expression: "valueProp"
                        }
                      ],
                      ref: "input",
                      staticClass: "text-input text-input--material",
                      attrs: { type: "text" },
                      domProps: { value: _vm.valueProp },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.valueProp = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass:
                        "text-input__label text-input--material__label"
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn-clear", on: { click: _vm.clear } },
                    [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "alert-dialog-footer alert-dialog-footer--rowfooter alert-dialog-footer--material alert-dialog-footer--rowfooter--material"
            },
            [
              _c("v-ons-alert-dialog-button", { on: { click: _vm.choose } }, [
                _vm._v("OK")
              ]),
              _vm._v(" "),
              _c("v-ons-alert-dialog-button", { on: { click: _vm.close } }, [
                _vm._v("Отмена")
              ])
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0d7bfdc6", { render: render, staticRenderFns: staticRenderFns })
  }
}