<template>
    <div class="tile-item-product" :style="{width: width}">
        <div class="tile-item-product-inner">
            <div class="image-wrap">
                <img
                    :class="'product-image cursor-pointer' + (item.images.thumb100.hasImage ? 'has-product-image' : '')"
                    :src="item.images.thumb100.path"
                    :alt="item.name"
                    :title="item.name"
                    :width="item.images.thumb100.width"
                    :height="item.images.thumb100.height"
                    @click="clickGoToView"
                />
            </div>

            <div class="product-attributes">
                <div class="product-name cursor-pointer" @click="clickGoToView">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        'item',
        'width',
    ],
    methods: {
        clickGoToView() {
            this.$emit('clickGoToView', this.item);
        },
    },
};
</script>