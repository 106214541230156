var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Нет фото")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.storages.length > 1,
                  expression: "storages.length > 1"
                }
              ],
              attrs: { modifier: "chevron longdivider" },
              on: { click: _vm.showChooseStorage }
            },
            [
              _c("span", { staticClass: "list-item__title" }, [
                _vm._v("Склад: " + _vm._s(_vm.selectedStorageName))
              ]),
              _vm._v(" "),
              _vm.selectedStorage && !_vm.selectedStorage.is_configured
                ? _c(
                    "span",
                    {
                      staticClass: "list-item__subtitle",
                      staticStyle: { color: "red" }
                    },
                    [_vm._v("\n                Не настроено\n            ")]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.items.length > 0
            ? _c(
                "v-ons-list",
                { staticClass: "list-catalog", attrs: { id: "list-catalog" } },
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      item.type === "category"
                        ? _c(
                            "v-ons-list-item",
                            { staticClass: "list-item-category" },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.name) +
                                  "\n                "
                              )
                            ]
                          )
                        : _c(
                            "v-ons-list-item",
                            { staticClass: "list-item-product" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-name cursor-pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        _vm.$router.currentRoute.path +
                                          "/" +
                                          item.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.name) +
                                        "\n                        "
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.items.length === 0,
                  expression: "items.length === 0"
                }
              ]
            },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "line-height": "216px",
                    "text-align": "center",
                    "font-size": "20px",
                    color: "#a8a8a8"
                  }
                },
                [_vm._v("Список пуст")]
              )
            ]
          ),
          _vm._v(" "),
          _c("v-list-dialog", {
            ref: "chooseStorageDialog",
            attrs: { title: "Выберите склад", items: _vm.storages }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2ba61e58", { render: render, staticRenderFns: staticRenderFns })
  }
}