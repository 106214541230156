<template>
    <tr class="manager-order-position-view">
        <td>{{ index }}</td>
        <td class="col-name cursor-pointer" @click="$router.push($router.currentRoute.path + '/' + position.prd_id)" >
            {{ position.name }}
        </td>
        <td class="col-count" >
            {{ position.count }} {{ position.unit }}
        </td>
        <td class="col-price" v-if="hasDiscount" >
            {{ position.price_discount }} руб.
            <br>
            <small style="font-size: 11px;" >({{ position.price }})</small>
        </td>
        <td class="col-price" v-else >{{ position.price }} руб.</td>
        <td v-show="isShowColAction" class="col-action" @click="startEdit" >
            <v-ons-icon :style="editable.length > 0 ? 'color: #a5a5a5;' : ''" icon="md-edit"></v-ons-icon>
        </td>
    </tr>
</template>

<script>

export default {
    props: [
        'position',
        'index',
        'hasDiscount',
        'isShowColAction',
        'editable',
    ],
    methods: {
        startEdit() {
            if (this.editable.length > 0) {
                return;
            }

            this.$emit('startEdit', this.position.pos_id);
        }
    }
};
</script>
