<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div class="center">Права доступа: {{ worker.email }}</div>
            <div class="right">
                <v-ons-toolbar-button v-show="loaded" @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <v-ons-list>
            <v-ons-list-header>Способ отправки заказа</v-ons-list-header>

            <v-ons-list-item modifier="longdivider" >
                <div class="select-material select--material select">
                    <select class="select-input select-input--material" v-model="permissions.orderSendMethod" >
                        <option :value="'direct'" >Напрямую</option>
                        <option :value="'indirect'" >Руководителю</option>
                    </select>
                </div>
            </v-ons-list-item>

            <v-ons-list-item v-show="permissions.orderSendMethod == 'indirect'" modifier="longdivider" >
                <label class="label-reset center" for="permissionsHidePrice" >Скрыть цены</label>
                <div class="right" >
                    <v-ons-checkbox
                        input-id="permissionsHidePrice"
                        v-model="permissions.hidePrice"
                    ></v-ons-checkbox>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list v-show="permissions.orderSendMethod == 'direct'" >
            <v-ons-list-header>Оповещения</v-ons-list-header>

            <v-ons-list-item v-for="notificationType in notificationTypes" :key="notificationType.name" modifier="longdivider" >
                <label class="label-reset center" :for="'notificationType-' + notificationType.name" >
                    {{ notificationType.label }}
                </label>
                <div class="right" >
                    <v-ons-checkbox
                        :input-id="'notificationType-' + notificationType.name"
                        v-model="notificationValues"
                        :value="notificationType.name"
                    ></v-ons-checkbox>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Доступные разделы</v-ons-list-header>

            <v-ons-list-item v-for="page in pages" :key="page.name" modifier="longdivider" >
                <label class="label-reset center" :for="'page-' + page.name" >
                    {{ page.label }}
                </label>
                <div class="right" >
                    <v-ons-checkbox
                        :input-id="'page-' + page.name"
                        :value="page.name"
                        :disabled="isPageDisable(page.name)"
                        v-model="permissions.availPages"
                    ></v-ons-checkbox>
                </div>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            id: null,
            changed: false,
            loaded: false,
            loading: true,
            worker: {},
            defaultShippingMethod: null,
            permissions: {
                orderSendMethod: 'indirect',
                disabledNotifications: [],
                availPages: [],
            },
            notificationTypes: [
                {
                    name: 'new_order',
                    label: 'Уведомление о новом заказе',
                },
                {
                    name: 'confirm_order',
                    label: 'Подтверждение заказа поставщиком',
                },
                {
                    name: 'cancel_order_by_worker',
                    label: 'Отмена заказа сотрудником',
                },
                {
                    name: 'cancel_order_by_manager',
                    label: 'Отмена заказа поставщиком',
                },
            ],
            pages: [
                {
                    name: 'myproducts',
                    label: 'Мои товары',
                },
                {
                    name: 'branches',
                    label: 'Филиалы',
                },
                {
                    name: 'entities',
                    label: 'Юр. лица',
                },
                {
                    name: 'catalog',
                    label: 'Каталог',
                },
                {
                    name: 'orders',
                    label: 'Заказы',
                },
                {
                    name: 'mymanager',
                    label: 'Ваш менеджер',
                },
            ],
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.load();
    },
    methods: {
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        load() {
            this.$http.get(`/api/workers/${this.id}/permissions`).then(response => {
                let data = response.data;

                this.worker = data.worker;
                if (this.worker.permissions) {
                    this.permissions = this.worker.permissions;
                }
                this.defaultShippingMethod = data.defaultShippingMethod;

                this.loading = false;

                this.$nextTick(() => {
                    this.loaded = true;
                });
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        save() {
            this.$http.post(`/api/workers/${this.id}/permissions`, {
                permissions: this.permissions,
                defaultShippingMethod: this.defaultShippingMethod,
            }).then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });
                this.$router.go(-1);
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        branchSubtitle(branch) {
            if (!branch.entities || branch.entities.length == 0) {
                return '';
            }

            return branch.entities
                .map(e => e.legal_name)
                .filter(e => e)
                .join(', ');
        },
        isPageDisable(name) {
            if (this.permissions.orderSendMethod == 'direct') {
                return ['orders', 'mymanager', 'catalog'].includes(name);
            } else {
                return ['branches', 'entities', 'orders'].includes(name);
            }
        },
    },
    computed: {
        notificationValues: {
            get() {
                return this.notificationTypes
                    .filter(i => !this.permissions.disabledNotifications.includes(i.name))
                    .map(i => i.name);
            },
            set(newValue) {
                this.permissions.disabledNotifications = this.notificationTypes
                    .filter(i => !newValue.includes(i.name))
                    .map(i => i.name)

                this.notificationTypes = this.notificationTypes.filter(i => i);
            },
        },
    },
    watch: {
        permissions: {
            handler(e) {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
        'permissions.orderSendMethod'() {
            if (!this.loaded) {
                return;
            }

            if (this.permissions.orderSendMethod == 'direct') {
                this.permissions.availPages = ['myproducts', 'catalog', 'orders', 'mymanager'];
            } else {
                this.permissions.availPages = ['catalog', 'orders'];
            }
        },
    },
};
</script>
