var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Сотрудники")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _vm.workers.length == 0
        ? _c(
            "div",
            {
              staticClass: "list-empty",
              style: { height: _vm.$window.height - 56 + "px" }
            },
            [_vm._v("\n        Список пуст\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-ons-list",
        _vm._l(_vm.workers, function(worker) {
          return _c("v-ons-list-item", { key: worker.id }, [
            _c(
              "div",
              {
                staticClass: "center",
                on: {
                  click: function($event) {
                    return _vm.$router.push({
                      name: "profile-worker",
                      params: { id: worker.id }
                    })
                  }
                }
              },
              [
                _vm._v(
                  "\n                " + _vm._s(worker.email) + "\n            "
                )
              ]
            )
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          attrs: { position: "bottom right" },
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "profile-worker-create" })
            }
          }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cf415cb8", { render: render, staticRenderFns: staticRenderFns })
  }
}