<template>
    <v-ons-dialog :visible.sync="visible" cancelable class="catalog-settings-rename-prompt" >
        <div class="alert-dialog-container alert-dialog-container--material">
            <div class="alert-dialog-title alert-dialog-title--material">
                Новое имя раздела
            </div>
            <div class="alert-dialog-content alert-dialog-content--material">
                <div class="input-wrap" >
                    <ons-input modifier="material">
                        <input v-model="valueProp" ref="input" type="text" class="text-input text-input--material">
                        <span class="text-input__label text-input--material__label"></span>
                    </ons-input>

                    <button class="btn-clear" @click="clear" >
                        <v-ons-icon icon="md-close"></v-ons-icon>
                    </button>
                </div>
            </div>

            <div class="alert-dialog-footer alert-dialog-footer--rowfooter alert-dialog-footer--material alert-dialog-footer--rowfooter--material">
                <v-ons-alert-dialog-button @click="choose" >OK</v-ons-alert-dialog-button>
                <v-ons-alert-dialog-button @click="close" >Отмена</v-ons-alert-dialog-button>
                <v-ons-alert-dialog-button @click="reset" v-show="originalTitle" >Сбросить</v-ons-alert-dialog-button>
            </div>
        </div>
    </v-ons-dialog>
</template>

<script>
export default {
    props: [
        'title',
        'value',
    ],
    data() {
        return {
            titleProp: null,
            valueProp: '',
            originalTitle: '',
            visible: false,
            resolve: null,
            reject: null,
        }
    },
    methods: {
        show(params) {
            params = params || {};
            this.titleProp = params.title || this.title;
            this.valueProp = params.value || this.value;
            this.originalTitle = params.originalTitle || '';

            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        clear() {
            this.valueProp = '';
            this.$refs.input.focus();
        },
        close() {
            this.visible = false;
        },
        choose() {
            if (!this.valueProp) {
                return;
            }

            this.visible = false;
            this.resolve(this.valueProp);
        },
        reset() {
            this.valueProp = this.originalTitle;
        }
    },
};
</script>

