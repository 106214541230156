var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Главная")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "home-page manager-page" }, [
        _c("div", { staticClass: "wrap xl-gutter-8 button-wrap" }, [
          _c(
            "div",
            { staticClass: "col xl-1-1" },
            [
              _c(
                "v-ons-button",
                {
                  attrs: { modifier: "large" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/catalog")
                    }
                  }
                },
                [_vm._v("Каталог")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wrap xl-gutter-8 button-wrap" }, [
          _c(
            "div",
            { staticClass: "col xl-1-2" },
            [
              _c(
                "v-ons-button",
                {
                  attrs: { modifier: "large" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/manager/users")
                    }
                  }
                },
                [_vm._v("Клиенты")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col xl-1-2" },
            [
              _c(
                "v-ons-button",
                {
                  attrs: { modifier: "large" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/manager/order")
                    }
                  }
                },
                [_vm._v("Заказы")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "wrap xl-gutter-8 button-wrap" }, [
          _c(
            "div",
            { staticClass: "col xl-1-1" },
            [
              _c(
                "v-ons-button",
                { attrs: { modifier: "large" }, on: { click: _vm.copyLink } },
                [
                  _c("v-ons-icon", { attrs: { icon: "md-copy" } }),
                  _vm._v(
                    "\n                    Ссылка для регистрации\n                "
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wrap xl-gutter-8 button-wrap" }, [
          _c(
            "div",
            { staticClass: "col xl-1-1" },
            [
              _c(
                "v-ons-button",
                {
                  attrs: { modifier: "large" },
                  on: { click: _vm.promptInvitation }
                },
                [
                  _c("v-ons-icon", { attrs: { icon: "md-account-add" } }),
                  _vm._v(
                    "\n                    Отправить приглашение\n                "
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.$user.hasProductLinksErrors
          ? _c(
              "div",
              {
                staticClass: "wrap xl-gutter-8 button-wrap",
                staticStyle: { "margin-top": "50px" }
              },
              [
                _c(
                  "div",
                  { staticClass: "col xl-1-1" },
                  [
                    _c(
                      "v-ons-button",
                      {
                        staticStyle: { background: "#f39f00" },
                        attrs: { modifier: "large" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push(
                              "/manager/product-link/products"
                            )
                          }
                        }
                      },
                      [
                        _c("v-ons-icon", {
                          attrs: { icon: "md-arrow-merge", size: "25px" }
                        }),
                        _vm._v(
                          "\n                    Ошибки связей товаров\n                "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("v-prompt-invitation-dialog", { ref: "promptInvitationDialog" }),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "storagesDialog",
        attrs: { title: "Выберите склад", items: _vm.storages }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5f023770", { render: render, staticRenderFns: staticRenderFns })
  }
}