var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "default-home-page" },
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "center",
            staticStyle: { "justify-content": "center" }
          },
          [
            _c(
              "div",
              {
                staticClass: "button--material",
                staticStyle: {
                  background: "#fff",
                  color: "#009688",
                  cursor: "pointer"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/register")
                  }
                }
              },
              [_vm._v("\n                Получить цены\n            ")]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$router.push("/login")
                  }
                }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-sign-in" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("v-ons-card", { staticClass: "introduce-card" }, [
            _vm._v(
              "\n            Поставки продуктов для вашего бизнеса.\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "catalog-list-component" },
            _vm._l(_vm.storages, function(storage) {
              return _c(
                "v-ons-list",
                { key: storage.id, staticClass: "tile-catalog" },
                [
                  _vm._l(storage.positions, function(item, index) {
                    return _c("v-ons-catalog-item-tile", {
                      key: index,
                      attrs: {
                        item: item,
                        isDesktop: _vm.$window.isDesktop,
                        numberColumns: _vm.numberColumns
                      },
                      on: {
                        clickGoToView: function(e) {
                          _vm.clickGoToView(e, storage)
                        }
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-ons-button",
                        {
                          attrs: { modifier: "quiet" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/register")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        Показать полностью\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-93985248", { render: render, staticRenderFns: staticRenderFns })
  }
}