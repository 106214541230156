<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Контроль минимальной цены</div>

            <div class="right">
                <v-ons-toolbar-button @click="save" v-show="!loading" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <ons-list-item>
                    <label for="params_enabled" class="center label-reset" >
                        Включить контроль
                    </label>
                    <div class="right" >
                        <v-ons-checkbox
                            v-model="params.enabled"
                            :value="true"
                            input-id="params_enabled"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>

                <v-ons-list-header>Тип цен для сравнения:</v-ons-list-header>
                <ons-list-item>
                    <div class="select-material select--material select">
                        <select v-model="params.priceType" class="select-input select-input--material" :disabled="!params.enabled" >
                            <option v-for="priceType in priceTypes" :key="priceType.id" :value="priceType.id" >
                                {{ priceType.name }}
                            </option>
                        </select>
                    </div>
                </ons-list-item>

                <ons-list-item>
                    <label for="params_reprice" class="center label-reset" >
                        Переоценить товар, если импортируемая цена меньше
                    </label>
                    <div class="right" >
                        <v-ons-checkbox
                            v-model="params.reprice"
                            :value="true"
                            :disabled="!params.enabled"
                            input-id="params_reprice"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            changed: false,
            loaded: false,

            priceTypes: [],
            params: {
                enabled: false,
                priceType: null,
                reprice: false,
            },
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;

            let requests = [
                this.$http.get(
                    '/api/manager/catalog-settings/control-min-price',
                    {params: {storageId: this.$route.params.storage_id}}
                ),
                this.$http.get(
                    '/api/manager/catalog-settings/price-types',
                    {params: {storageId: this.$route.params.storage_id}}
                ),
            ];

            Promise.all(requests).then(responses => {
                let params = responses[0].data;

                if (params) {
                    this.params.enabled = params.enabled;
                    this.params.priceType = params.priceType;
                    this.params.reprice = params.reprice;
                }

                this.priceTypes = responses[1].data;

                this.$nextTick(() => {
                    this.loading = false;
                    this.loaded = true;
                });
            }, () => {
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        save() {
            if (this.params.enabled && !this.params.priceType) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Выберите тип цены для сравнения',
                    buttonLabel: 'OK',
                });
                return;
            }

            this.loading = true;

            this.$http.post(
                '/api/manager/catalog-settings/control-min-price',
                {params: this.params},
                {params: {storageId: this.$route.params.storage_id}}
        ).then(() => {
                this.loading = false;

                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Сохранено',
                    buttonLabel: 'OK',
                });

                this.$router.go(-1);
            }, () => {
                this.loading = false;
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
    },
    watch: {
        params: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>