var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSearchActive,
              expression: "!isSearchActive"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [_vm._v("Emails")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { title: "Обновить" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update")
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-refresh" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchActive } },
                [_c("v-ons-icon", { attrs: { icon: "md-search" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSearchActive,
              expression: "isSearchActive"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchDeactive } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery"
                }
              ],
              staticStyle: {
                color: "#fff",
                background: "none",
                border: "none",
                height: "34px",
                "line-height": "34px",
                "border-bottom": "1px solid #fff"
              },
              attrs: { id: "search-input" },
              domProps: { value: _vm.searchQuery },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchQuery = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { title: "Обновить" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update")
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-refresh" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "ons-list",
            _vm._l(_vm.emails, function(email) {
              return _c(
                "ons-list-item",
                { key: email.id, attrs: { modifier: "chevron longdivider" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "center",
                      attrs: { target: "_blank" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            "/manager/reports/emails/" + email.id
                          )
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "list-item__title" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(email.subject) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "list-item__subtitle" }, [
                        _vm._v(
                          "\n                        To: " +
                            _vm._s(email.to) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "list-item__subtitle" }, [
                        _vm._v(
                          "\n                        From: " +
                            _vm._s(email.from) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "list-item__subtitle" }, [
                        _vm._v(
                          "\n                        ReplyTo: " +
                            _vm._s(email.reply_to) +
                            "\n                    "
                        )
                      ])
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.page < _vm.pageCount,
                  expression: "page < pageCount"
                }
              ],
              staticStyle: { "text-align": "center", margin: "10px 0" }
            },
            [
              _c(
                "v-ons-button",
                {
                  staticStyle: { "min-width": "145px" },
                  on: { click: _vm.loadMore }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.loadingMore,
                          expression: "!loadingMore"
                        }
                      ]
                    },
                    [_vm._v("Показать ещё")]
                  ),
                  _vm._v(" "),
                  _c("v-ons-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loadingMore,
                        expression: "loadingMore"
                      }
                    ],
                    attrs: { size: "30px", spin: "", icon: "md-spinner" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-46223440", { render: render, staticRenderFns: staticRenderFns })
  }
}