<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar inline="true" >
            <div class="center">График доставки</div>
        </v-ons-toolbar>

        <div style="margin: 18px 0;" v-show="id != 2" >
            <p style="margin-left: 10px;" >{{ address }}</p>

            <div>
                <table class="table" >
                    <thead>
                        <tr>
                            <th>Доставка</th>
                            <th>День заказа</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="day in schedule" v-if="day.beforeDayName" >
                            <td>{{ day.name }}</td>
                            <td>
                                {{ day.beforeDayName }} до {{ day.beforeTime }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div style="margin-left: 10px;" >
                    <div v-show="freeDeliveryFrom" >
                        Бесплатная доставка при заказе от {{ freeDeliveryFrom }} <span class="font-rouble" >o</span>
                    </div>

                    <div v-show="deliveryCost" >
                        Стоимость доставки: {{ deliveryCost }} <span class="font-rouble" >o</span>
                    </div>

                    <div v-show="refusalDeliveryTo" >
                        Минимальный заказ: {{ refusalDeliveryTo }} <span class="font-rouble" >o</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="id == 2" style="margin: 25px 10px;" >
            По этому адресу не осуществляется плановых доставок.
        </div>
    </v-ons-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            id: null,
            address: '',
            schedule: [],
            freeDeliveryFrom: null,
            deliveryCost: null,
            refusalDeliveryTo: null,
        }
    },
    created() {
    },
    methods: {
        show(branch) {
            this.visible = true;
            this.id = branch.route.id;
            this.address = branch.stringAddressWithName;
            this.schedule = branch.route.schedule;
            this.freeDeliveryFrom = branch.route.free_delivery_from;
            this.deliveryCost = branch.route.delivery_cost;
            this.refusalDeliveryTo = branch.route.refusal_delivery_to;
        },
    },
};
</script>
