<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar inline="true" >
            <div class="center">Выберите склад</div>
            <div class="right" >
                <v-ons-toolbar-button @click="close" title="Закрыть" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list :style="{maxHeight: ($window.height - 110) + 'px', overflowY: 'auto'}" >
            <v-ons-list-item v-for="item in items" :key="item.id" @click="choose(item)">
                <label class="center" style="font-weight: 400; margin: 0; padding: 8px 6px 8px 0;" :for="'chk-' + item.id" >
                    {{ item.name }}
                </label>
                <div class="right" style="padding: 8px 16px 8px 0;" >
                    {{ item.rest }} {{ unit }}
                </div>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-dialog>
</template>

<script>
export default {
    props: [
        'items',
        'unit',
    ],
    data() {
        return {
            visible: false,
            resolve: null,
            reject: null,
        }
    },
    methods: {
        show() {
            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.visible = false;
        },
        choose(item) {
            this.visible = false;
            this.resolve(item.id);
        },
    },
};
</script>