<template>
    <ons-list-item @click="onClick" >
        <div class="left icon" v-if="icon" >
            <v-ons-icon :icon="icon" :style="{color: iconColor}"></v-ons-icon>
        </div>
        <div class="center" v-if="icon" :style="{color: disabled ? '#999999' : '#1f1f21'}" >{{ text }}</div>

        <span v-if="!icon" :style="{color: disabled ? '#999999' : '#1f1f21'}" >
            {{ text }}
        </span>
    </ons-list-item>
</template>

<script>
export default {
    props: [
        'icon',
        'iconColor',
        'text',
        'disabled',
    ],
    methods: {
        onClick(e) {
            if (this.disabled) {
                return false;
            }

            this.$emit('click', e);
        }
    }
};
</script>