<template>
    <v-ons-page>
        <v-ons-toolbar v-show="!hasChecked" modifier="noshadow">
            <div v-if="showBackButton" class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>
            <div v-else class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>
            <div class="center">Мои товары</div>

            <div class="right">
                <v-ons-toolbar-button title="Добавить шаблон" v-show="activeIndex === 1" @click="showNewTemplateDialog">
                    <v-ons-icon icon="md-plus"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    title="Создать заказ"
                    :disabled="!canCreateOrder"
                    v-show="activeIndex === 0 && displayedButtons.includes('createOrder')"
                    @click="createOrder"
                >
                    <v-ons-icon icon="md-shopping-cart"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    title="Каталог"
                    :disabled="!canOpenCatalog"
                    v-show="activeIndex === 0 && displayedButtons.includes('showGroups')"
                    @click="openCatalog"
                >
                    <span class="font-rouble">o</span>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right">
                    <toolbar-popover-menu-item
                        text="Группы товаров"
                        icon="md-storage"
                        @click="$refs.groupsModal.show()"
                        v-if="displayedButtons.includes('showGroups')"
                    />

                    <toolbar-popover-menu-item
                        text="История"
                        icon="md-time-restore-setting"
                        v-if="displayedButtons.includes('showHistory')"
                        @click="$router.push($router.currentRoute.path + '/history')"
                    />

                    <ons-list-item @click="showAlwaysAvailableModal" v-if="$user.isManager" >
                        <div class="left icon">
                            <v-ons-icon
                                icon="md-shopping-cart-plus"
                                :style="{color: user.rest_always_available ? '#ffbb3d' : '#8a8a8a'}"
                            ></v-ons-icon>
                        </div>
                        <div class="center">Всё в наличии</div>
                    </ons-list-item>
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="hasChecked" modifier="noshadow">
            <div class="left">
                <v-ons-toolbar-button @click="uncheckAll">
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center"></div>

            <div class="right">
                <v-ons-toolbar-button
                    title="Переместить в группу"
                    v-show="activeIndex === 0"
                    @click="$refs.moveToGroupModal.move()"
                >
                    <v-ons-icon icon="md-storage"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    title="Добавить в шаблон"
                    v-show="activeIndex === 0"
                    @click="$refs.moveToTemplateModal.show()"
                >
                    <v-ons-icon icon="md-group"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button title="Удалить" @click="removeChecked">
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-tabbar
            position="auto"
            :tabs="tabs"
            :visible="true"
            :index.sync="activeIndex"
            @prechange="rechangeTab"
            @checked="hasChecked = true"
            @unchecked="hasChecked = false"
            @positionsCountUpdate="onPositionsCountUpdate"
        />

        <move-to-group-modal
            ref="moveToGroupModal"
            title="Переместить в"
            @renameGroup="renameGroup"
            @deleteGroup="deleteGroup"
            @update="$bus.$emit('productTab.updatePositions')"
            @choose="moveToGroup"
        />

        <move-to-group-modal
            ref="groupsModal"
            title="Группы товаров"
            @renameGroup="renameGroup"
            @deleteGroup="deleteGroup"
            @update="$bus.$emit('productTab.updatePositions')"/>

        <templates-modal ref="moveToTemplateModal" @choose="moveToTemplate" :title="'Добавить в шаблон'"/>

        <always-available-modal ref="alwaysAvailableModal" />
    </v-ons-page>
</template>

<script>

import ProductTab from './ProductTab'
import TemplateTab from './TemplateTab'
import MoveToGroupModal from './MoveToGroupModal'
import TemplatesModal from './TemplatesModal'
import AlwaysAvailableModal from './AlwaysAvailableModal'

export default {
    data() {
        return {
            user: {},
            userId: null,
            hasChecked: false,
            activeIndex: 0,
            canOpenCatalog: false,
            canCreateOrder: false,
            entrypoint: '/api/user-products',
            showBackButton: false,
            tabs: [
                {
                    label: 'Товары',
                    page: ProductTab,
                    key: "productTab"
                },
                {
                    label: 'Шаблоны',
                    page: TemplateTab,
                    key: "templateTab"
                },
            ]
        }
    },
    created() {
        if (this.$user.isManager) {
            this.userId = this.$route.params.user_id;
            this.$http.get('/api/manager/users/' + this.userId).then(response => {
                this.user = response.data;
            });
        }

        if (this.$route.meta.userProductEntrypoint) {
            let userProductEntrypoint = this.$route.meta.userProductEntrypoint;
            this.entrypoint = userProductEntrypoint.replace('$userId', this.$route.params.user_id)
        }

        if (this.$route.meta.showBackButton) {
            this.showBackButton = this.$route.meta.showBackButton
        }
    },
    methods: {
        onPositionsCountUpdate(params) {
            this.canOpenCatalog = params.canOpenCatalog;
            this.canCreateOrder = params.canCreateOrder;
        },
        uncheckAll() {
            this.$bus.$emit('uncheck');
        },
        renameGroup() {
            this.$bus.$emit('productTab.updatePositions');
        },
        deleteGroup() {
            this.$bus.$emit('productTab.updatePositions');
        },
        checked() {
            this.hasChecked = true;
        },
        moveToGroup(data) {
            this.$bus.$emit('moveToGroup', data);
        },
        moveToTemplate(data) {
            this.$bus.$emit('moveToTemplate', data);
        },
        rechangeTab() {
            this.hasChecked = false;
            this.$bus.$emit('uncheck');
            this.$bus.$emit('productTab.updatePositions');
        },
        removeChecked() {
            this.$bus.$emit('removeChecked');
        },
        restoreFromHistory() {
            this.$bus.$emit('restoreFromHistory');
        },
        showNewTemplateDialog() {
            this.$ons.notification.prompt('Название', {
                title: 'Создать новый шаблон',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (!response) {
                    return;
                }

                this.$http.put(this.entrypoint + '/templates', {name: response}).then(() => {
                    this.$bus.$emit('templateTab.loadTemplates');
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        openCatalog() {
            if (!this.canOpenCatalog) {
                return;
            }

            this.$router.push({name: 'order-catalog', params: {order_id: 'create', template: 'myproducts'}})
        },
        createOrder() {
            if (!this.canCreateOrder) {
                return;
            }

            this.$http.post('/api/order/create-from-my-products').then(response => {
                this.$router.push({name: 'order', params: {order_id: response.data.id}});
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        showAlwaysAvailableModal() {
            this.$refs.alwaysAvailableModal.show(this.user.id).then(response => {
                this.user.rest_always_available = response;
            });
        },
    },
    computed: {
        displayedButtons() {
            return this.$route.meta.displayedButtons || []
        },
    },
    components: {
        MoveToGroupModal,
        TemplatesModal,
        AlwaysAvailableModal,
    }
};
</script>