<template>
    <div>
        <v-ons-dialog
            :visible.sync="dialogVisible"
            cancelable
            @preshow="loadTemplates"
        >
            <v-ons-toolbar inline="true" >
                <div class="center">{{ title }}</div>
                <div class="right">
                    <v-ons-toolbar-button @click="showNewTemplateDialog" >
                        <v-ons-icon icon="md-plus"></v-ons-icon>
                    </v-ons-toolbar-button>
                    <v-ons-toolbar-button @click="close" >
                        <v-ons-icon icon="md-close"></v-ons-icon>
                    </v-ons-toolbar-button>
                </div>
            </v-ons-toolbar>

            <div
                v-show="templates.length === 0"
                style="line-height: 216px; text-align: center; font-size: 20px; color: #a8a8a8;"
                >
                Список пуст
            </div>

            <v-ons-list v-show="templates.length > 0" style="height: 216px; overflow-y: auto" >
                <v-ons-list-item v-for="template in templates" :key="template.id" >
                    <div class="center" @click="choose(template)" >
                        {{ template.name }}
                    </div>
                    <div class="right" >
                        <v-ons-icon
                            @click="deleteTemplate(template)"
                            icon="md-delete"
                            class="list-item__icon"
                            style="margin: 0 4px; cursor: pointer;"
                        ></v-ons-icon>

                        <v-ons-icon
                            @click="showRenameTemplateDialog(template)"
                            icon="md-edit"
                            class="list-item__icon"
                            style="margin: 0 4px; cursor: pointer;"
                        ></v-ons-icon>
                    </div>
                </v-ons-list-item>
            </v-ons-list>
        </v-ons-dialog>

        <v-ons-dialog
            :visible="newTemplateDialogVisible"
        >
            <v-ons-toolbar inline="true" >
                <div class="center">Новый шаблон</div>
            </v-ons-toolbar>

            <div style="margin: 20px 15px;" >
                <v-ons-input style="width: 100%" placeholder="Название" :model="name" />
            </div>

            <v-ons-row>
                <v-ons-col>
                    <v-ons-button
                        modifier="quiet"
                        style="width: 100%;"
                        @click="dialogVisible = false"
                    >Отмена</v-ons-button>
                </v-ons-col>

                <v-ons-col>
                    <v-ons-button
                        style="width: 100%;"
                        @click="dialogVisible = false"
                    >Добавить</v-ons-button>
                </v-ons-col>
            </v-ons-row>

        </v-ons-dialog>
    </div>
</template>

<script>
export default {
    props: ['title'],
    data() {
        return {
            dialogVisible: false,
            newTemplateDialogVisible: false,
            templates: [],
            name: '',
            entrypoint: '/api/user-products',
        }
    },
    created() {
        if (this.$route.meta.userProductEntrypoint) {
            let userProductEntrypoint = this.$route.meta.userProductEntrypoint;
            this.entrypoint = userProductEntrypoint.replace('$userId', this.$route.params.user_id)
        }
    },
    methods: {
        showNewTemplateDialog() {
            this.dialogVisible = false;
            this.$ons.notification.prompt('Название', {
                title: 'Создать новый шаблон',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (!response) {
                    this.dialogVisible = true;
                    return;
                }

                this.$http.put(this.entrypoint + '/templates', {name: response}).then(response => {
                    let template = response.data;
                    this.dialogVisible = false;
                    this.$emit('choose', template);
                    this.$bus.$emit('templateTab.loadTemplates');
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        deleteTemplate(template) {
            this.dialogVisible = false;
            this.$ons.notification.confirm('Удалить шаблон?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.post(this.entrypoint + '/templates/delete-template', {template_id: template.id}).then(() => {
                    this.loadTemplates();
                    this.dialogVisible = true;
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });

                    this.dialogVisible = true;
                });
            });
        },
        showRenameTemplateDialog(template) {
            this.dialogVisible = false;
            this.$ons.notification.prompt('Название', {
                title: 'Переименовать шаблон',
                defaultValue: template.name,
                buttonLabels: ['Отмена', 'OK']
            }).then(name => {
                if (!name || name === template.name) {
                    this.dialogVisible = true;
                    return;
                }

                this.$http.post(`${this.entrypoint}/templates/${template.id}/rename`, {name: name}).then(() => {
                    this.loadTemplates();
                    this.dialogVisible = true;
                });
            });
        },
        choose(template) {
            this.dialogVisible = false;
            this.$emit('choose', template);
        },
        close() {
            this.dialogVisible = false;
        },
        show() {
            this.dialogVisible = true;
        },
        loadTemplates() {
            this.$http.get(this.entrypoint + '/templates').then(response => {
                this.templates = response.body;
            }, () => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    }
};
</script>