import Index from '~/screen/manager/users/Index';
import User from '~/screen/manager/users/User';
import Orders from './Orders';
import Catalog from './Catalog';
import UserProducts from './Products';
import SpecialPrices from './SpecialPrices';
import Permission from './Permission';
import Settings from './Settings';
import Admin from './Admin';
import Profile from './Profile';
import OnecConnection from '~/screen/manager/users/OnecConnection';

let route = [
    {
        path: 'users',
        name: 'manager-users',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: ':user_id',
                name: 'manager-user',
                component: User,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    ...Catalog,
                    ...Orders,
                    ...SpecialPrices,
                    ...Permission,
                    ...Settings,
                    ...Admin,
                    ...Profile,
                    UserProducts('default'),
                    {
                        path: 'onec-connections',
                        name: 'manager-user-onec-connection',
                        component: OnecConnection,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ]
            },
        ]
    },
];

export default route;