<template>
    <div>
        <div @click="isShippingDateDialogVisible = true" style="border-bottom: 1px solid #afafaf; line-height: 28px;" >
            <v-ons-icon icon="md-calendar" style="margin-right: 8px; font-size: 20px; vertical-align: middle;" ></v-ons-icon>
            {{ date | moment("DD.MM.YYYY") }}

            <span v-show="!date && placeholder" style="color: #ccc" >
                {{ placeholder }}
            </span>
        </div>

        <v-ons-dialog cancelable :visible.sync="isShippingDateDialogVisible" >
            <v-ons-toolbar inline="true" >
                <div class="center" >
                    {{ label }}
                </div>
                <div class="right" >
                    <v-ons-toolbar-button @click="isShippingDateDialogVisible = false;" >
                        <v-ons-icon icon="md-close"></v-ons-icon>
                    </v-ons-toolbar-button>
                </div>
            </v-ons-toolbar>

            <datepicker
                v-model="date"
                :inline="true"
                :monday-first="true"
                :language="ru"
                :highlighted="highlighted"
                @selected="isShippingDateDialogVisible = false"
            ></datepicker>
        </v-ons-dialog>
    </div>
</template>

<script>

import Datepicker from 'vuejs-datepicker';
import {ru} from 'vuejs-datepicker/dist/locale'

export default {
    props: [
        'value',
        'label',
        'placeholder',
        'highlighted',
    ],
    data() {
        return {
            ru: ru,
            isShippingDateDialogVisible: false,
            date: null,
        };
    },
    created() {
        if (this.value && this.value.includes('-')) {
            const [year, month, day] = this.value.split("-");
            this.date = new Date(year, month - 1, day);
        }
    },
    watch: {
        date(val) {
            this.$emit('input', this.$moment(val).format("YYYY-MM-DD"));
        },
        value(val) {
            if (!val || !val.includes('-')) {
                return;
            }

            const [year, month, day] = val.split("-");
            this.date = new Date(year, month - 1, day);
        },
    },
    components: {
        Datepicker,
    },

};
</script>
