export default class {
    constructor() {
        this.key = 'CatalogCountsKeeperSessions';
        this.ls = window.localStorage;
        this.sessionId = Math.floor(Date.now());
        this.removeOlds();
    }

    save(counts, groupBy) {
        if (!counts || Object.keys(counts).length === 0) {
            return;
        }

        let session = {
            id: this.sessionId,
            updated: Date.now(),
            counts: counts,
            groupBy: groupBy,
        };

        this.saveSession(session);
    }

    getSession(id) {
        for (let session of this.getSessions()) {
            if (session.id === id) {
                return session;
            }
        }
    }

    getSessions() {
        let sessions = this.ls.getItem(this.key) || '[]';
        sessions = JSON.parse(sessions);
        sessions.sort((a, b) => a.updated - b.updated);
        return sessions;
    }

    setSessions(sessions) {
        sessions = JSON.stringify(sessions);
        this.ls.setItem(this.key, sessions);
    }

    removeOlds() {
        let sessions = this.getSessions();
        let count = 3;

        if (sessions.length > count) {
            sessions.splice(0, sessions.length - count);
            this.setSessions(sessions);
        }
    }

    saveSession(session) {
        let sessions = this.getSessions();

        if (sessions.findIndex(s => s.id === session.id) !== -1) {
            for (let i = 0; i < sessions.length; i++) {
                let s = sessions[i];

                if (s.id === session.id) {
                    sessions[i] = session;
                    break;
                }
            }
        } else {
            sessions.push(session);
        }

        this.setSessions(sessions);
    }
}