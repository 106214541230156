var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.blurHandler,
          expression: "blurHandler"
        }
      ],
      staticClass: "input-proposal-wrap"
    },
    [
      _c(
        "form",
        {
          attrs: { action: "", autocomplete: "off" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return function() {}.apply(null, arguments)
            }
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            ref: "input",
            staticClass: "input-proposal",
            style: _vm.focus ? "border-color: #009688" : "",
            attrs: {
              type: "text",
              autocomplete: "off",
              spellcheck: "false",
              name: "input-proposal",
              placeholder: "Нажмите, чтобы добавить товар",
              disabled:
                (_vm.canInputDisabled &&
                  _vm.inputDisabled &&
                  !_vm.canEditInput) ||
                _vm.disabled
            },
            domProps: { value: _vm.name },
            on: {
              keydown: _vm.keydownHandler,
              keyup: _vm.keyupHandler,
              focus: function($event) {
                return _vm.focusHandler($event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.focus,
              expression: "focus"
            }
          ],
          staticClass: "clear-input-button",
          on: { click: _vm.clear }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canInputDisabled && _vm.inputDisabled,
              expression: "canInputDisabled && inputDisabled"
            }
          ],
          staticClass: "enable-edit-input",
          on: { click: _vm.enableEditInput }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-edit" } })],
        1
      ),
      _vm._v(" "),
      _vm.proposals.length > 0 && _vm.isVisibleProposals
        ? _c("div", { staticClass: "proposals-outer" }, [
            _c(
              "div",
              { staticClass: "proposals-wrap" },
              _vm._l(_vm.proposals, function(proposal, proposalIndex) {
                return _c(
                  "div",
                  {
                    staticClass: "proposal-wrap wrap",
                    class: {
                      selected: proposalIndex == _vm.selectedProposal,
                      added: _vm.isAdded(proposal)
                    },
                    attrs: {
                      id: "proposal-" + proposalIndex,
                      title: proposal.isInMyProducts
                        ? 'Товар добавлен в "Мои товары"'
                        : ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.chooseProposal(proposal)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowColumn("name"),
                            expression: "isShowColumn('name')"
                          }
                        ],
                        staticClass: "col col-name"
                      },
                      [
                        _c(
                          "div",
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(proposal.name) +
                                "\n\n                        "
                            ),
                            _c("v-ons-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: proposal.isInMyProducts,
                                  expression: "proposal.isInMyProducts"
                                }
                              ],
                              staticClass: "star-icon",
                              attrs: {
                                icon: "md-star",
                                title: 'Товар добавлен в "Мои товары"'
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowColumn("count"),
                            expression: "isShowColumn('count')"
                          }
                        ],
                        staticClass: "col col-count"
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(proposal.unit) +
                              "\n                    "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowColumn("price"),
                            expression: "isShowColumn('price')"
                          }
                        ],
                        staticClass: "col col-price"
                      },
                      [
                        proposal.is_wait
                          ? _c("div", [_vm._v("ждём")])
                          : _c(
                              "div",
                              {
                                class: [
                                  "price-wrap",
                                  proposal.specialPrice.isShowMark
                                    ? "is-low-special-price"
                                    : ""
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(proposal.price) +
                                    " р/" +
                                    _vm._s(proposal.unit) +
                                    "\n                    "
                                )
                              ]
                            )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-07a6ac61", { render: render, staticRenderFns: staticRenderFns })
  }
}