<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>
            <div class="center">Специальный график работы</div>
        </v-ons-toolbar>

        <v-ons-list v-show="weeks.length > 0" >
            <v-ons-list-item v-for="week in weeks" :key="week.id" modifier="chevron longdivider" >
                <div
                    class="center cursor-pointer"
                    @click="$router.push({name: 'manager-management-special-schedule-view', params: {id: week.id}})"
                >
                    {{ week.name }}
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <div v-show="!weeks.length" class="" >
            <div style="font-size: 20px; color: #a8a8a8; position: absolute; top: 50%; text-align: center; width: 100%; line-height: 50px; margin-top: -25px;" >
                Список пуст<br>
                 <v-ons-button @click="$router.push({name: 'manager-management-special-schedules-create'})" >Добавить график</v-ons-button>
            </div>
        </div>

        <v-ons-fab
            v-show="weeks.length"
            position="bottom right"
            @click="$router.push({name: 'manager-management-special-schedules-create'})"
            title="Добавить график"
        >
            <v-ons-icon icon="md-plus"></v-ons-icon>
        </v-ons-fab>

    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            weeks: [],
        }
    },
    created() {
        this.load();
        this.$bus.$on('load', this.load);
    },
    destroyed() {
        this.$bus.$off('load');
    },
    methods: {
        load() {
            this.$http.get(
                '/api/manager/management/special-schedules',
                {params: {storageId: this.$route.params.storage_id}}
            ).then(response => {
                this.weeks = response.data;
            });
        },
    },
};
</script>