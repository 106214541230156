import Home from '~/screen/Home';

import Orders from '~/screen/order/Orders';
import Order from '~/screen/order/Order';
import OrderCheckout from '~/screen/order/Checkout';
import OrderCheckoutBranch from '~/screen/order/CreateBranch';
import OrderCheckoutEntity from '~/screen/order/CreateEntity';
import UserProductList from '~/screen/userProduct/List';
import UserPrdHistory from '~/screen/userProduct/History';
import SettingsMain from '~/screen/settings/Main';
import SettingsCheckout from '~/screen/settings/Checkout';
import SettingsNotifications from '~/screen/settings/Notifications';
import SettingsSpecialPricesNotifications from '~/screen/settings/SpecialPricesNotifications';
import SettingsOnecConnection from '~/screen/settings/OnecConnection';
import SettingsChangePassword from '~/screen/settings/ChangePassword';
import InventoryList from '~/screen/inventory/List';
import InventoryView from '~/screen/inventory/View';
import InventoryCreate from '~/screen/inventory/Create';
import PageNotFound from '~/screen/PageNotFound';
import OrderCatalog from '~/screen/order/Catalog';
import Login from '~/screen/Login';
import Forgot from '~/screen/Forgot';
import Recover from '~/screen/Recover';
import Register from '~/screen/Register';
import RegisterByInvite from '~/screen/RegisterByInvite';
import BecomeWorker from '~/screen/BecomeWorker';
import TemplateView from '~/screen/userProduct/TemplateView';
import Catalog from '~/screen/catalog/Index';
import CatalogView from '~/screen/catalog/View';
import MyManager from '~/screen/MyManager';
import MyManagerRoutes from '~/screen/MyManagerRoutes';

import ContactsForDiscounts from '~/screen/ContactsForDiscounts';
import Profile from '~/screen/profile/Index';
import ProfileContacts from '~/screen/profile/Contacts';
import ProfileEntities from '~/screen/profile/Entities';
import ProfileEntity from '~/screen/profile/Entity';
import ProfileManagerEntities from '~/screen/profile/ManagerEntities';
import ProfileManagerEntity from '~/screen/profile/ManagerEntity';
import ProfileManagerStorages from '~/screen/profile/ManagerStorages';
import ProfileManagerStorage from '~/screen/profile/ManagerStorage';
import ProfileManagerContacts from '~/screen/profile/ManagerContacts';
import Branches from '~/screen/profile/Branches';
import Branch from '~/screen/profile/Branch';
import ProfileWorkers from '~/screen/profile/worker/Workers';
import ProfileWorkerMenu from '~/screen/profile/worker/WorkerMenu';
import ProfileWorkerPermissions from '~/screen/profile/worker/WorkerPermissions';
import ProfileWorkerRules from '~/screen/profile/worker/WorkerRules';
import ProfileWorkerCreate from '~/screen/profile/worker/WorkerCreate';
import ProductRoute from './ProductRoute';

import Manager from './Manager';

let routes =  [
    {
        path: '/',
        component: Home,
        name: 'home',
        children: [
            {
                path: '/login',
                component: Login,
                name: 'login',
            },
            {
                path: '/forgot',
                component: Forgot,
                name: 'forgot',
            },
            {
                path: '/recover/:code',
                component: Recover,
                name: 'recover',
            },
            {
                path: '/register',
                component: Register,
                name: 'register',
                props: true,
            },
            {
                path: '/register-by-invite',
                component: RegisterByInvite,
                name: 'register-by-invite',
                props: true,
            },
            {
                path: 'become-worker',
                component: BecomeWorker,
                name: 'become-worker',
                meta: {
                    requiresLogin: true,
                },
            },
        ],
    },
    {
        path: '/catalog',
        component: Catalog,
        name: 'catalog',
        props: true,
        meta: {
            requiresLogin: true,
        },
        children: [
            ProductRoute('catalog'),
        ]
    },
    {
        path: '/product-list',
        component: UserProductList,
        name: 'product-list',
        meta: {
            requiresLogin: true,
            displayedButtons: ['createOrder', 'showCatalog', 'showGroups', 'showHistory'],
        },
        children: [
            {
                path: 'template/:id',
                name: 'template-view',
                component: TemplateView,
                params: true,
                meta: {
                    requiresLogin: true,
                },
                children: [
                    {
                        path: ':prd_id',
                        name: 'template-product-view',
                        component: CatalogView,
                        meta: {
                            requiresLogin: true,
                        },
                    },
                ]
            },
            {
                path: 'history',
                name: 'product-list-history',
                component: UserPrdHistory,
                meta: {
                    requiresLogin: true,
                },
                children: [
                    {
                        path: ':prd_id',
                        name: 'product-list-history-product-view',
                        component: CatalogView,
                        meta: {
                            requiresLogin: true,
                        },
                    },
                ]
            },
            {
                path: ':prd_id',
                name: 'product-view',
                component: CatalogView,
                meta: {
                    requiresLogin: true,
                },
            },
        ],
    },
    {
        path: '/order',
        component: Orders,
        name: 'order-list',
        meta: {
            requiresLogin: true,
        },
        children: [
            {
                path: ':order_id',
                name: 'order',
                component: Order,
                meta: {
                    requiresLogin: true,
                },
                children: [
                    {
                        path: 'catalog',
                        name: 'order-catalog',
                        component: OrderCatalog,
                        params: true,
                        meta: {
                            requiresLogin: true,
                        },
                        children: [
                            {
                                path: ':prd_id',
                                name: 'order-catalog-view',
                                component: CatalogView,
                                meta: {
                                    requiresLogin: true,
                                },
                            },
                        ]
                    },
                    {
                        path: 'checkout',
                        name: 'order-checkout',
                        component: OrderCheckout,
                        params: true,
                        meta: {
                            requiresLogin: true,
                        },
                        children: [
                            {
                                path: 'shipping-addresses-create',
                                name: 'order-checkout-create-branch',
                                component: OrderCheckoutBranch,
                                params: true,
                                meta: {
                                    requiresLogin: true,
                                },
                                children: [
                                    {
                                        path: 'entity',
                                        name: 'order-checkout-create-entity',
                                        component: OrderCheckoutEntity,
                                        meta: {
                                            requiresLogin: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: ':prd_id',
                        name: 'order-position-view',
                        component: CatalogView,
                        meta: {
                            requiresLogin: true,
                        },
                    },
                ]
            },
        ],
    },
    {
        path: '/inventory',
        component: InventoryList,
        name: 'inventory-list',
        meta: {
            requiresLogin: true,
        },
        children: [
            {
                path: 'create',
                name: 'inventory-create',
                component: InventoryCreate,
                meta: {
                    requiresLogin: true,
                },
            },
            {
                path: 'update/:inventory',
                name: 'inventory-update',
                component: InventoryCreate,
                meta: {
                    requiresLogin: true,
                },
            },
            {
                path: 'view/:id',
                name: 'inventory-view',
                component: InventoryView,
                meta: {
                    requiresLogin: true,
                },
                children: [
                    {
                        path: 'product/:prd_id',
                        name: 'inventory-product-view',
                        component: CatalogView,
                        meta: {
                            requiresLogin: true,
                        },
                    }
                ],
            }
        ],
    },
    {
        path: '/settings',
        component: SettingsMain,
        name: 'settings',
        meta: {
            requiresLogin: true,
        },
        children: [
            {
                path: 'checkout',
                name: 'settings-checkout',
                component: SettingsCheckout,
                meta: {
                    requiresLogin: true,
                },
                children: [
                    {
                        path: 'entity',
                        name: 'settings-checkout-create-entity',
                        component: ProfileEntity,
                        meta: {
                            requiresLogin: true,
                        },
                    },
                ],
            },
            {
                path: 'change-password',
                name: 'change-password',
                component: SettingsChangePassword,
                meta: {
                    requiresLogin: true,
                },
            },
            {
                path: 'notifications',
                name: 'settings-notifications',
                component: SettingsNotifications,
                meta: {
                    requiresLogin: true,
                },
            },
            {
                path: 'special-prices-notifications',
                name: 'settings-special-prices-notifications',
                component: SettingsSpecialPricesNotifications,
                meta: {
                    requiresLogin: true,
                },
            },
            {
                path: 'onec-connection',
                name: 'settings-onec-connection',
                component: SettingsOnecConnection,
                meta: {
                    requiresLogin: true,
                },
            },
        ],
    },
    {
        path: '/profile',
        component: Profile,
        name: 'profile',
        meta: {
            requiresLogin: true,
        },
        children: [
            {
                path: 'contacts',
                name: 'profile-contacts',
                component: ProfileContacts,
                meta: {
                    requiresLogin: true,
                },
            },
            {
                path: 'manager-contacts',
                name: 'profile-manager-contacts',
                component: ProfileManagerContacts,
                meta: {
                    requiresLogin: true,
                },
            },
            {
                path: 'branches',
                name: 'branches',
                component: Branches,
                meta: {
                    requiresLogin: true,
                },
                children: [
                    {
                        path: 'create',
                        name: 'branches-create',
                        component: Branch,
                        meta: {
                            requiresLogin: true,
                        },
                        children: [
                            {
                                path: 'entity',
                                name: 'branches-create-entity',
                                component: ProfileEntity,
                                meta: {
                                    requiresLogin: true,
                                },
                            },
                        ],
                    },
                    {
                        path: ':branch_id',
                        name: 'branch-view',
                        component: Branch,
                        meta: {
                            requiresLogin: true,
                        },
                        children: [
                            {
                                path: 'entity',
                                name: 'branches-view-entity',
                                component: ProfileEntity,
                                meta: {
                                    requiresLogin: true,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'entities',
                name: 'entity',
                component: ProfileEntities,
                meta: {
                    requiresLogin: true,
                },
                children: [
                    {
                        path: 'create',
                        name: 'entities-create',
                        component: ProfileEntity,
                        meta: {
                            requiresLogin: true,
                        },
                    },
                    {
                        path: ':entity_id',
                        name: 'entity-view',
                        component: ProfileEntity,
                        meta: {
                            requiresLogin: true,
                        },
                    },
                ],
            },
            {
                path: 'manager-entities',
                name: 'manager-entity',
                component: ProfileManagerEntities,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'create',
                        name: 'manager-entities-create',
                        component: ProfileManagerEntity,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                    {
                        path: ':entity_id',
                        name: 'manager-entity-view',
                        component: ProfileManagerEntity,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ],
            },
            {
                path: 'manager-storages',
                name: 'manager-storage',
                component: ProfileManagerStorages,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: ':storage_id',
                        name: 'manager-storage-view',
                        component: ProfileManagerStorage,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ],
            },
            {
                path: 'workers',
                name: 'profile-workers',
                component: ProfileWorkers,
                meta: {
                    requiresLogin: true,
                },
                children: [
                    {
                        path: 'create',
                        name: 'profile-worker-create',
                        component: ProfileWorkerCreate,
                    },
                    {
                        path: ':id',
                        name: 'profile-worker',
                        component: ProfileWorkerMenu,
                        children: [
                            {
                                path: 'permissions',
                                name: 'profile-worker-permissions',
                                component: ProfileWorkerPermissions,
                            },
                            {
                                path: 'rules',
                                name: 'profile-worker-rules',
                                component: ProfileWorkerRules,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/mymanager',
        component: MyManager,
        name: 'mymanager',
        children: [
            {
                path: 'routes',
                name: 'mymanager-routes',
                component: MyManagerRoutes,
            },
        ]
    },
    ...Manager,
    {
        path: '/contacts-for-discounts',
        component: ContactsForDiscounts,
        name: 'contacts-for-discounts',
        meta: {
            requiresLogin: true,
        },
    },
    {
        path: '*',
        component: PageNotFound
    },
];

export default routes;