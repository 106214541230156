import Index from '~/screen/manager/report/Index';
import SalesForPeriod from '~/screen/manager/report/SalesForPeriod';
import SalesForPeriodOrders from '~/screen/manager/report/SalesForPeriodOrders';
import SpecialPrices from '~/screen/manager/report/SpecialPrices';
import SearchQueries from '~/screen/manager/report/SearchQueries';
import EmailIndex from '~/screen/manager/report/email/Index';
import EmailView from '~/screen/manager/report/email/View';
import NoPhotosIndex from '~/screen/manager/report/noPhotos/Index';
import DeletionsFromOrders from '~/screen/manager/report/DeletionsFromOrders';
import ProductRoute from '~/routes/ProductRoute';

let route = [
    {
        path: 'reports',
        name: 'manager-reports',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'sales-for-period',
                name: 'report-sales-for-period',
                component: SalesForPeriod,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'orders',
                        name: 'report-sales-for-period-orders',
                        component: SalesForPeriodOrders,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ],
            },
            {
                path: 'special-prices',
                name: 'report-special-prices',
                component: SpecialPrices,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'search-queries',
                name: 'report-search-queries',
                component: SearchQueries,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'emails',
                name: 'report-emails-index',
                component: EmailIndex,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: ':id',
                        name: 'report-emails-view',
                        component: EmailView,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ]
            },
            {
                path: 'no-photos',
                name: 'report-no-photos-index',
                component: NoPhotosIndex,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    ProductRoute('report-no-photos-products'),
                ]
            },
            {
                path: 'deletions-from-orders',
                name: 'deletions-from-orders',
                component: DeletionsFromOrders,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
        ],
    },
];

export default route;