<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Перемещение/Удаление товара</div>

            <div class="right">
                <v-ons-toolbar-button @click="remove" v-if="id" title="Удалить" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" title="Сохранить" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <ons-list-item>
                    {{ rule.product.name }}
                </ons-list-item>

                <ons-list-item>
                    <div>Из раздела:</div>

                    <span style="margin-left: 10px;" >
                        {{ categoryFromName }}
                    </span>
                </ons-list-item>
            </v-ons-list>

            <v-ons-list-item>
                <div class="left" style="margin-right: 10px; min-width: 0;">
                    <v-ons-radio
                        input-id="delete"
                        value="delete"
                        v-model="rule.type"
                    ></v-ons-radio>
                </div>

                <label for="delete" style="background: none" class="label-reset center" >
                    Удалить
                </label>
            </v-ons-list-item>

            <v-ons-list-item>
                <div class="left" style="margin-right: 10px; min-width: 0;">
                    <v-ons-radio
                        input-id="replace"
                        value="replace"
                        v-model="rule.type"
                    ></v-ons-radio>
                </div>

                <label for="replace" style="background: none" class="label-reset center" >
                    Переместить
                </label>
            </v-ons-list-item>

            <v-ons-list v-show="rule.type === 'replace'" >
                <ons-list-item>
                    <div>В раздел:</div>
                    <div class="select-material select--material select" style="margin-left: 10px; width: auto;" >
                        <select v-model="rule.categoryTo.id" class="select-input select-input--material" >
                            <option v-if="rule.categoryTo.isDeleted" :disabled="true" :value="''" >Раздел не найден</option>

                            <option v-for="category in categories" :key="category.id" :value="category.id" >
                                {{ category.name }}
                            </option>
                        </select>
                    </div>
                </ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            changed: false,
            loaded: false,
            loading: true,

            id: null,
            categories: [],

            rule: {
                product: {},
                categoryFrom: {},
                categoryTo: {},
                type: '',
            },
        }
    },
    created() {
        this.id = this.$route.params.product_id;
        this.load();
    },
    methods: {
        load() {
            this.loading = true;

            let requests = [
                this.$http.get(
                    '/api/manager/catalog-settings/product-rule/' + this.id,
                    {params: {storageId: this.$route.params.storage_id}}
                ),
                this.$http.get(
                    '/api/manager/catalog-settings/product-rule-categories',
                    {params: {storageId: this.$route.params.storage_id}}
                ),
            ];

            Promise.all(requests).then(responses => {
                this.rule = responses[0].data;
                this.categories = responses[1].data;

                if (this.rule.categoryFrom && this.rule.categoryFrom.id) {
                    this.categories = this.categories.filter(c => {
                        return c.id != this.rule.categoryFrom.id;
                    });
                }

                this.$nextTick(() => {
                    this.loading = false;
                    this.loaded = true;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        remove() {
            this.$ons.notification.confirm('Удалить?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    return;
                }

                this.$http.delete(
                    '/api/manager/catalog-settings/product-rule/' + this.id,
                    {params: {storageId: this.$route.params.storage_id}}
                ).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Удалено',
                        buttonLabel: 'OK',
                    });

                    this.$router.go(-1);

                    this.$bus.$emit('update');
                }, () => {
                    this.$ons.notification.toast({
                        buttonLabel: 'OK',
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        save() {
            this.$http.post(
                '/api/manager/catalog-settings/product-rule/' + this.id,
                {type: this.rule.type, category: this.rule.categoryTo.id},
                {params: {storageId: this.$route.params.storage_id}}
        ).then(() => {
                this.$router.go(-1);
                this.$bus.$emit('update');
            }, () => {
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
    },
    computed: {
        categoryFromName() {
            let noName = 'Раздел не найден';

            if (!this.rule || !this.rule.categoryFrom) {
                return noName;
            }

            if (!this.rule.categoryFrom.name) {
                return noName;
            }

            return this.rule.categoryFrom.name
        }
    },
    watch: {
        rule: {
            handler() {
                if (!this.loaded) {
                    return;
                }

                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>