<template>
    <v-ons-page>
        <v-ons-toolbar v-show="!hasChecked" >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center">Каталог удалений</div>

            <div class="right">
                <v-ons-toolbar-button >
                    <v-ons-icon title="Разделы каталога" icon="md-filter-list" @click="chooseGroupFilter" :style="{color: filters.groups.length > 0 ? '#ffe000' : ''}" ></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="hasChecked" >
            <div class="left">
                <v-ons-toolbar-button title="Отменить выделение" @click="uncheckAll" >
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center" >
                Выбрано: {{ checked.length }}
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-if="items.length > 0">
                <v-ons-list v-if="isDesktop" id="list-header-catalog" class="list-catalog is-desktop">
                    <v-ons-list-item class="table-header-wrap" :style="{width: listCatalogWidth + 'px'}" >
                        <div class="col-name" >Наименование</div>
                        <div v-if="isDesktop && $user.isManager" class="col-rest" >Остаток</div>
                        <div class="col-price" v-show="$user.canSeePrice" >Цена</div>
                        <div v-show="showInputs && !$user.isManager" class="col-count" >Заказ</div>
                    </v-ons-list-item>
                </v-ons-list>

                <v-ons-list v-if="isFullCatalog" id="list-catalog" class="list-catalog">
                    <v-ons-catalog-item
                        v-for="(item, index) in items"
                        :key="index"
                        :item="item"
                        :isDesktop="isDesktop"
                        :canOpenProductPage="canOpenProductPage"
                        :showInputs="showInputs"
                        :selectedPriceType="selectedPriceType"
                        :counts="counts"
                        :checked="checked"
                        >
                    </v-ons-catalog-item>
                </v-ons-list>

                <v-ons-list v-else class="list-catalog">
                    <v-ons-lazy-repeat
                        ref="lazyRepeat"
                        :render-item="renderItem"
                        :length="items.length"
                        >
                    </v-ons-lazy-repeat>
                </v-ons-list>

                <div style="width: 100%; height: 80px;" ></div>
            </div>

            <div v-show="items.length === 0" class="list-empty" :style="{height: $window.height - 56 + 'px', flexDirection: 'column'}" >
                <div style="margin-bottom: 10px; font-size: 15px;" >
                    Ничего не найдено
                </div>

                <v-ons-button @click="reset" >Сбросить</v-ons-button>
            </div>
        </div>

        <v-list-dialog ref="groupFilterModal" multiple="true" title="Фильтр по группам" showResetButton="false" allowEmptyList="true" />

        <v-ons-fab :disabled="!hasChecked" @click="undoDelete" position="bottom right" >
            <v-ons-icon icon="md-undo"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

import {from, merge, of} from 'rxjs'
import {catchError, debounceTime, map, switchMap, tap} from 'rxjs/operators'

import Vue from 'vue'

import CategoryItem from './catalog/CategoryItem'
import ProductItem from './catalog/ProductItem'
import ListDialog from '~/component/ListDialog'

export default {
    props: {
        showInputs: {
            type: Boolean,
            default: false
        },
        canOpenProductPage: {
            type: Boolean,
            default: true
        },
        actionColType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            items: [],
            categories: [],
            filters: {
                groups: [],
            },
            checked: [],
            counts: {},
            loading: true,
            isDesktop: false,
            selectedPriceType: null,
            groupBy: 'byCatalogGroup',
            lastImportDate: null,
            adding: false,
            listCatalogWidth: 0,
            isFullCatalog: false,
        }
    },
    created() {
        this.isDesktop = document.body.clientWidth > 767;

        this.$http.get('/api/catalog/last-import-date').then(response => {
            this.lastImportDate = response.data;
        });

        this.$emit('update');

        this.$bus.$on('catalog-update-product', this.updateProduct);
        this.$window.$on('resize', this.updateListCatalogWidth);
        this.$on('updated', this.updateListCatalogWidth);

        this.$http.get(
            '/api/manager/catalog-settings/product-rule-categories',
            {params: {storageId: this.$route.params.storage_id}}
        ).then(response => {
            this.categories = response.data;
        }, () => {
            this.$ons.notification.toast({
                message: 'Произошла ошибка.',
                buttonLabel: 'OK'
            });
        });
    },
    destroyed() {
        this.$bus.$off('catalog-update-product', this.updateProduct);
        this.$window.$off('resize', this.updateListCatalogWidth);
        this.$off('updated', this.updateListCatalogWidth);

    },
    subscriptions () {
        const update$ = this.$eventToObservable('update').pipe(
            debounceTime(100),
            tap(() => {
                this.loading = true;
                this.items = [];
            })
        )

        return {
            results: merge(update$).pipe(
                switchMap(() => from(
                    this.$http.get(
                        '/api/manager/catalog-settings/product-rules-deleted-catalog',
                        {
                            params: {
                                filters: this.filters,
                                groupBy: this.groupBy,
                                storageId: this.$route.params.storage_id
                            }
                        }
                    )
                )),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of({
                        products: [],
                        categories: [],
                    });
                }),
                tap(items => {
                    this.loading = false;
                    this.items = items.products;
                    this.categories = items.categories;
                    this.$emit('updated');
                }),
            )
        }
    },
    methods: {
        undoDelete() {
            let checked = this.checked;
            this.checked = [];

            this.$ons.notification.confirm('Восстановить товары?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    return;
                }

                this.loading = true;

                this.$http.post(
                    '/api/manager/catalog-settings/delete-product-rules',
                    {ids: checked},
                    {params: {storageId: this.$route.params.storage_id}}
            ).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Товары восстановлены',
                        buttonLabel: 'OK',
                    });

                    this.$router.go(-1);

                    this.$bus.$emit('update');
                }, () => {
                    this.loading = true;

                    this.$ons.notification.toast({
                        buttonLabel: 'OK',
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        chooseGroupFilter() {
            this.$refs.groupFilterModal.show({items: this.categories, selected: this.filters.groups}).then(response => {
                this.filters.groups = response;
                this.$emit('update');
            })
        },
        uncheckAll() {
            this.checked = [];
        },
        reset() {
            this.isSearchActive = false;
            this.filters = {
                q: '',
                groups: [],
                templates: [],
                showSpecialProducts: false,
            };
            this.$emit('update');
        },
        renderItem(i) {
            let self = this;
            let item = this.items[i];

            return new Vue({
                render(h) {
                    if (!item) {
                        return h('v-ons-list-item');
                    }

                    if (item.type === 'category') {
                        return h(CategoryItem, {
                            props: {
                                item: item,
                            }
                        });
                    } else {
                        return h(ProductItem, {
                            props: {
                                item: item,
                                isDesktop: self.isDesktop,
                                actionColType: 'checkbox',
                                canOpenProductPage: self.canOpenProductPage,
                                showInputs: self.showInputs,
                                selectedPriceType: self.selectedPriceType,
                                counts: self.counts,
                                checked: self.checked,
                                lastImportDate: self.lastImportDate,
                            },
                        });
                    }
                }
            });
        },
        updateListCatalogWidth() {
            this.$nextTick(() => {
                let listCatalog = document.getElementById("list-header-catalog");
                if (!listCatalog) {
                    return;
                }
                this.listCatalogWidth = listCatalog.offsetWidth;
            });
        },
    },
    computed: {
        hasChecked() {
            return this.checked.length > 0;
        },
    },
    components: {
        'v-list-dialog': ListDialog,
        'v-ons-catalog-item': {
            render(h) {
                if (!this.item) {
                    return h('v-ons-list-item');
                }

                if (this.item.type === 'category') {
                    return h(CategoryItem, {
                        props: {
                            item: this.item,
                        }
                    });
                } else {
                    return h(ProductItem, {
                        props: {
                            item: this.item,
                            isDesktop: this.isDesktop,
                            actionColType: this.actionColType,
                            canOpenProductPage: this.canOpenProductPage,
                            showInputs: this.showInputs,
                            selectedPriceType: this.selectedPriceType,
                            counts: this.counts,
                            checked: this.checked,
                        },
                    });
                }
            },
            props: [
                "item",
                "isDesktop",
                "actionColType",
                "canOpenProductPage",
                "showInputs",
                "selectedPriceType",
                "counts",
                "checked",
            ],
        }
    },
};
</script>