var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Профиль: " + _vm._s(_vm.user.email))
        ])
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        [
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-user-auth" })
                }
              }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Авторизоваться")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: { click: _vm.changePassword }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Изменить пароль")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.user.user_type == "customer" && !_vm.user.hasWorkers,
                  expression: "user.user_type == 'customer' && !user.hasWorkers"
                }
              ],
              attrs: { modifier: "longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-choose-director" })
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Сделать сотрудником")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isAdmin && _vm.user.user_type == "customer",
                  expression: "$user.isAdmin && user.user_type == 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-choose-manager" })
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Закрепить менеджера")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isAdmin && _vm.user.user_type == "customer",
                  expression: "$user.isAdmin && user.user_type == 'customer'"
                }
              ],
              attrs: { modifier: "longdivider", tappable: "" },
              on: { click: _vm.turnToManager }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Сделать менеджером")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isAdmin && _vm.user.user_type == "manager",
                  expression: "$user.isAdmin && user.user_type == 'manager'"
                }
              ],
              attrs: { modifier: "longdivider", tappable: "" },
              on: { click: _vm.turnToCustomer }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Сделать клиентом")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type == "worker",
                  expression: "user.user_type == 'worker'"
                }
              ],
              attrs: { modifier: "longdivider", tappable: "" },
              on: { click: _vm.leaveDirector }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Сделать независимым клиентом")
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirmTurnToManagerDialog" }),
      _vm._v(" "),
      _c("change-password", { ref: "changePasswordModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-68929ffc", { render: render, staticRenderFns: staticRenderFns })
  }
}