var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { visible: _vm.visible, cancelable: "" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.titleProp,
              expression: "titleProp"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c("div", { staticClass: "center" }, [_vm._v(_vm._s(_vm.titleProp))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.allowselectall && !_vm.isSelectAll,
                      expression: "allowselectall && !isSelectAll"
                    }
                  ],
                  attrs: { title: "Выбрать всё" },
                  on: { click: _vm.selectAll }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.allowselectall && _vm.isSelectAll,
                      expression: "allowselectall && isSelectAll"
                    }
                  ],
                  attrs: { title: "Снять выделение" },
                  on: { click: _vm.unselectAll }
                },
                [
                  _c("v-ons-icon", {
                    staticStyle: { color: "yellow" },
                    attrs: { icon: "md-check-all" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                { attrs: { title: "Закрыть" }, on: { click: _vm.close } },
                [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      !_vm.multiple
        ? _c(
            "div",
            [
              _c(
                "v-ons-list",
                {
                  style: {
                    maxHeight: _vm.$window.height - 110 + "px",
                    overflowY: "auto"
                  }
                },
                _vm._l(_vm.itemsData, function(item) {
                  return _c(
                    "v-ons-list-item",
                    {
                      key: item.id,
                      on: {
                        click: function($event) {
                          return _vm.choose(item.id)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.name) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-ons-list",
                {
                  style: {
                    maxHeight: _vm.$window.height - 110 + "px",
                    overflowY: "auto"
                  }
                },
                _vm._l(_vm.itemsData, function(item) {
                  return _c("v-ons-list-item", { key: item.id }, [
                    _c(
                      "label",
                      {
                        staticClass: "center",
                        staticStyle: {
                          "font-weight": "400",
                          margin: "0",
                          padding: "8px 6px 8px 0"
                        },
                        attrs: { for: "chk-" + item.id }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.name) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "right",
                        staticStyle: { padding: "8px 16px 8px 0" }
                      },
                      [
                        _c("v-ons-checkbox", {
                          attrs: {
                            "input-id": "chk-" + item.id,
                            value: item.id
                          },
                          model: {
                            value: _vm.selected,
                            callback: function($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected"
                          }
                        })
                      ],
                      1
                    )
                  ])
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-row",
                {
                  staticClass: "text-center",
                  staticStyle: {
                    "margin-bottom": "5px",
                    "justify-content": "center"
                  }
                },
                [
                  _c(
                    "v-ons-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showResetButton && _vm.multiple,
                          expression: "showResetButton && multiple"
                        }
                      ],
                      attrs: { width: "50%" }
                    },
                    [
                      _c(
                        "v-ons-button",
                        {
                          attrs: { modifier: "quiet" },
                          on: {
                            click: function($event) {
                              return _vm.reset()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Сбросить\n                "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-ons-col",
                    { attrs: { width: "50%" } },
                    [
                      _c(
                        "v-ons-button",
                        {
                          attrs: {
                            disabled:
                              _vm.selected.length === 0 && !_vm.allowEmptyList
                          },
                          on: {
                            click: function($event) {
                              return _vm.choose()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.btnTitleProp) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-40e34f51", { render: render, staticRenderFns: staticRenderFns })
  }
}