<template>
    <div>
        <v-ons-dialog cancelable :visible.sync="visible" >
            <v-ons-toolbar inline="true" >
                <div class="center">Скачать как</div>
                <div class="right" >
                    <v-ons-toolbar-button @click="close" >
                        <v-ons-icon icon="md-close"></v-ons-icon>
                    </v-ons-toolbar-button>
                </div>
            </v-ons-toolbar>

            <v-ons-list>
                <v-ons-list-item @click="choose('pdf')" >
                    <div class="center">PDF</div>
                </v-ons-list-item>
                <v-ons-list-item @click="choose('xls')" >
                    <div class="center">Excel</div>
                </v-ons-list-item>
            </v-ons-list>
        </v-ons-dialog>

        <v-ons-dialog :visible="loading" >
            <v-ons-row vertical-align="center" >
                <v-ons-col width="70px" style="text-align: center"  >
                    <v-ons-progress-circular indeterminate></v-ons-progress-circular>
                </v-ons-col>

                <v-ons-col>
                    <div style="line-height: 100px" >Скачивание...</div>
                </v-ons-col>
            </v-ons-row>
        </v-ons-dialog>
    </div>
</template>

<script>
export default {
    props: {
        userProductsEntrypoint: {
            type: String,
            default: '/api/user-products',
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            params: {
                filters: null,
                groupBy: null,
            },
        }
    },
    methods: {
        show(params) {
            this.visible = true;

            if (params) {
                this.params = params;
            }
        },
        close() {
            this.visible = false;
        },
        choose(format) {
            this.visible = false;
            this.loading = true;
            this.downloadPriceList(format).then(() => this.loading = false);
        },
        downloadPriceList(format) {
            let params = {
                format: format,
                filters: this.params.filters,
                groupBy: this.params.groupBy,
                priceType: this.$user.priceType,
                manager: this.$bus.referralManager,
                storageId: this.$user.selectedStorage,
            }

            return this.$http.post(this.userProductsEntrypoint + '/download-price-list', params, {responseType: 'arraybuffer'}).then(response => {
                let contentDisposition = response.headers.get('Content-Disposition') || '';
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(contentDisposition);
                let filename;

                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                } else {
                    filename = 'price.' + format;
                }

                let blob = new Blob([response.data], { type: response.headers.get('content-type') });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
};
</script>