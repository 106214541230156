var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Округление цен")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                on: { click: _vm.save }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "params_enabled" }
                  },
                  [
                    _vm._v(
                      "\n                    Округлять копейки\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: { value: true, "input-id": "params_enabled" },
                      model: {
                        value: _vm.params.enabled,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "enabled", $$v)
                        },
                        expression: "params.enabled"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "params_enabled" }
                  },
                  [
                    _vm._v(
                      "\n                    для цен дороже\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.compare,
                        expression: "params.compare"
                      }
                    ],
                    staticStyle: { width: "50px", "text-align": "center" },
                    attrs: {
                      id: "params_enabled",
                      disabled: !_vm.params.enabled
                    },
                    domProps: { value: _vm.params.compare },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.params, "compare", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Способ округления")]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "params_method_up" }
                  },
                  [
                    _vm._v(
                      "\n                    округлять вверх\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-radio", {
                      staticStyle: { width: "50px", "text-align": "center" },
                      attrs: {
                        "input-id": "params_method_up",
                        value: "up",
                        disabled: !_vm.params.enabled
                      },
                      model: {
                        value: _vm.params.method,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "method", $$v)
                        },
                        expression: "params.method"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "params_method_down" }
                  },
                  [
                    _vm._v(
                      "\n                    округлять вниз\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-radio", {
                      staticStyle: { width: "50px", "text-align": "center" },
                      attrs: {
                        "input-id": "params_method_down",
                        value: "down",
                        disabled: !_vm.params.enabled
                      },
                      model: {
                        value: _vm.params.method,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "method", $$v)
                        },
                        expression: "params.method"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "params_method_math" }
                  },
                  [
                    _vm._v(
                      "\n                    округлять математически\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-radio", {
                      staticStyle: { width: "50px", "text-align": "center" },
                      attrs: {
                        "input-id": "params_method_math",
                        value: "math",
                        disabled: !_vm.params.enabled
                      },
                      model: {
                        value: _vm.params.method,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "method", $$v)
                        },
                        expression: "params.method"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3bc7c05c", { render: render, staticRenderFns: staticRenderFns })
  }
}