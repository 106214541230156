var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Профиль: " + _vm._s(_vm.user.email))
        ])
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        [
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-user-contacts" })
                }
              }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Контакты")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "customer",
                  expression: "user.user_type === 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-user-branches" })
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Филиалы и маршруты")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "customer",
                  expression: "user.user_type === 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-user-entities" })
                }
              }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Юридические лица")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "customer",
                  expression: "user.user_type === 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider" },
              on: { click: _vm.showChooseStorage }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Склад: " + _vm._s(_vm.selectedStorageName))
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-radio-dialog", {
        ref: "chooseStorageDialog",
        attrs: { title: "Выберите склад", items: _vm.storages }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-160f4fd4", { render: render, staticRenderFns: staticRenderFns })
  }
}