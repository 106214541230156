var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-list",
    { on: { click: _vm.onClick } },
    [
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Главная")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [_c("router-link", { attrs: { to: "/catalog" } }, [_vm._v("Каталог")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        {
          staticClass: "cursor-pointer",
          attrs: { tappable: "" },
          on: { click: _vm.goToOrders }
        },
        [
          _c("div", { staticClass: "center" }, [
            _vm._v("\n            Заказы\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.hasNewOrders,
                  expression: "$user.hasNewOrders"
                }
              ],
              staticClass: "right"
            },
            [
              _c("v-ons-icon", {
                staticStyle: { color: "#ffbb3d" },
                attrs: { icon: "md-email", size: "22px" }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [
          _c("router-link", { attrs: { to: "/manager/users" } }, [
            _vm._v("Клиенты")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$user.hasPermission("canManageProductLinks"),
              expression: "$user.hasPermission('canManageProductLinks')"
            }
          ],
          attrs: { tappable: "" }
        },
        [
          _c("router-link", { attrs: { to: "/manager/oos" } }, [
            _vm._v("Out of stock")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$user.hasPermission("canManageProductLinks"),
              expression: "$user.hasPermission('canManageProductLinks')"
            }
          ],
          staticClass: "cursor-pointer",
          attrs: { tappable: "" },
          on: { click: _vm.goToProductLink }
        },
        [
          _c("div", { staticClass: "center" }, [
            _vm._v("\n        Связи товаров\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.hasProductLinksErrors,
                  expression: "$user.hasProductLinksErrors"
                }
              ],
              staticClass: "right"
            },
            [
              _c("v-ons-icon", {
                staticStyle: { color: "#ffbb3d" },
                attrs: { icon: "md-arrow-merge", size: "25px" }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$user.hasPermission("canSeeReports"),
              expression: "$user.hasPermission('canSeeReports')"
            }
          ],
          attrs: { tappable: "" }
        },
        [
          _c("router-link", { attrs: { to: "/manager/reports" } }, [
            _vm._v("Отчёты")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [
          _c("router-link", { attrs: { to: "/manager/management" } }, [
            _vm._v("Управление")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [_c("router-link", { attrs: { to: "/profile" } }, [_vm._v("Профиль")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [
          _c("router-link", { attrs: { to: "/settings" } }, [
            _vm._v("Настройки")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" }, on: { click: _vm.reload } },
        [_vm._v("\n        Обновить\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-aef46884", { render: render, staticRenderFns: staticRenderFns })
  }
}