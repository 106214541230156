<template>
    <v-ons-page>
        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}">
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div class="user-product-page" v-bind:style="{'padding-bottom': paddingBottom}" style="position: relative;">
                <div class="positions">
                    <div class="tbody">
                        <div v-for="group in positionGroupsLocal" :key="group.id + '-' + group.length" class="group-wrap">
                            <div class="group-name" v-if="shouldShowGroupName">
                                {{ group.name }}
                            </div>

                            <v-position
                                v-for="position in getPositionsByGroup(group.id)"
                                :id="'position-' + position.pos_id"
                                :key="position.id"
                                :position="position"
                                :addedPositions="addedPositions"
                                @remove="onRemovePosition"
                                @update="onUpdatePosition"
                            />
                        </div>

                        <input-proposal-create
                            ref="inputProposal"
                            :scrollPageOnFocus="true"
                            :disableScrollPage="true"
                            :addedPositions="addedPositions"
                            :urlSearch="urlSearch"
                            :columns="$user.canSeePrice ? ['name', 'price'] : ['name']"
                            @choose="chooseProposal"
                        />
                    </div>
                </div>
            </div>

            <select-add-method-modal ref="selectAddMethodModal"/>
        </div>

        <v-ons-fab v-show="hasCheckedOne" position="bottom left" @click="orderUp" style="bottom: 90px">
            <v-ons-icon icon="long-arrow-up"></v-ons-icon>
        </v-ons-fab>

        <v-ons-fab v-show="hasCheckedOne" position="bottom left" @click="orderDown">
            <v-ons-icon icon="long-arrow-down"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

import $ from 'jquery'
import SelectAddMethodModal from '~/component/SelectAddMethodModal'
import UserProductPosition from '~/component/userProduct/Position'
import InputProposalCreate from '~/component/InputProposalCreate'

export default {
    data() {
        return {
            loading: true,

            _positions: [],
            positions: [],
            positionGroups: [],
            timer: null,
            units: [],
            paddingBottom: 0,
            hasChecked: false,
            hasCheckedOne: false,
            entrypoint: '/api/user-products',
            urlSearch : null,
        }
    },
    created() {
        if (this.$route.meta.userProductEntrypoint) {
            let userProductEntrypoint = this.$route.meta.userProductEntrypoint;
            this.entrypoint = userProductEntrypoint.replace('$userId', this.$route.params.user_id)
        }

        if (this.$route.meta.userProductSearchEntrypoint) {
            let userProductSearchEntrypoint = this.$route.meta.userProductSearchEntrypoint;
            this.urlSearch = userProductSearchEntrypoint.replace('$userId', this.$route.params.user_id)
        }

        this.loadPositions();

        this.$http.get('/api/product/get-units').then(response => {
            this.units = response.data;
        });

        this.$bus.$on('moveToGroup', data => this.moveToGroup(data.group));
        this.$bus.$on('moveToTemplate', data => this.moveToTemplate(data));
        this.$bus.$on('removeChecked', () => this.removeChecked());
        this.$bus.$on('productTab.updatePositions', () => this.updatePositions());
        this.$bus.$on('productTab.loadPositions', () => this.loadPositions());
        this.$bus.$on('uncheck', () => {
            this.positions.forEach(i => i.isChecked = false);
        });
        window.addEventListener('keydown', this.onKeydown);

        this.$nextTick(() => {
            if (this.$user.isManager) {
                this.paddingBottom = ($(window).height() - 141) + 'px';
                return;
            }

            if (this.getPositionsByGroup(0).length > 1) {
                this.paddingBottom = ($(window).height() - 103) + 'px';
            } else {
                this.paddingBottom = ($(window).height() - 138) + 'px';
            }
        });
    },
    destroyed() {
        this.$bus.$off('moveToGroup');
        this.$bus.$off('moveToTemplate');
        this.$bus.$off('removeChecked');
        this.$bus.$off('productTab.renameGroup');
        this.$bus.$off('productTab.loadPositions');
        this.$bus.$off('uncheck');
        window.removeEventListener('keydown', this.onKeydown);
    },
    methods: {
        updatePositions() {
            let checkeds = this.positions
                .filter(i => i.isChecked)
                .map(i => i.id)

            this.loadPositions().then(() => {
                this.positions
                    .filter(i => checkeds.indexOf(i.id) !== -1)
                    .forEach(i => i.isChecked = true)
            });
        },
        moveToGroup(group) {
            let positions = this.positions
                .filter(i => i.isChecked)
                .map(i => {
                    i.isChecked = false;
                    return i.id
                })

            if (positions.length === 0) {
                return;
            }

            this.$http.post(this.entrypoint + `/groups/${group}/move-positions-to-group`, {positions: positions}).then(() => {
                this.loadPositions();
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Позиции перемещены.',
                });
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Не удалось переместить позиции.',
                });
            });
        },
        moveToTemplate(template) {
            let positions = this.positions
                .filter(i => i.isChecked)
                .map(i => {
                    i.isChecked = false;
                    return i.id
                })

            if (positions.length === 0) {
                return;
            }

            this.$http.post(this.entrypoint + '/templates/link-positions-to-template', {
                template: template.id,
                positions: positions
            }).then((response) => {
                this.loadPositions();

                this.$ons.notification.toast({
                    timeout: 1000,
                    message: response.data.message,
                });
            }, () => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                });
            });
        },
        getPositionsByGroup(group) {
            return this.positionsLocal.filter(function (position) {
                return parseInt(position.group_id) === parseInt(group);
            });
        },
        loadPositions() {
            this.loading = true;

            return this.$http.get(this.entrypoint).then((response) => {
                this.loading = false;

                if (JSON.stringify(response.data) === this._positions) {
                    return;
                }

                this._positions = JSON.stringify(response.data);

                let groups = response.data.groups;

                // Добавляем группу "Без группы"
                groups.push({
                    id: 0,
                    name: 'Без группы',
                });

                this.positionGroups = groups;
                this.positions = response.data.positions;
            });
        },
        chooseProposal(proposal) {
            this.$refs.inputProposal.clear();
            this.$refs.inputProposal.setFocus();

            if (proposal.id || proposal.name) {
                let addedPosition = this.positions
                    .filter(position => !position.isDeleted)
                    .find(p => proposal.id === p.product || proposal.name === p.name);

                if (addedPosition) {
                    this.$nextTick(() => {
                        let elId = 'position-' + addedPosition.pos_id;
                        let el = document.getElementById(elId);
                        this.scrollPage(el);
                    });

                    return;
                }
            }

            let position = {
                product_id: proposal.id,
                name: proposal.name,
            }

            this.$http.put(this.entrypoint, {position: position}).then((response) => {
                let groups = response.data.groups;

                // Добавляем группу "Без группы"
                groups.push({
                    id: 0,
                    name: 'Без группы',
                });

                this.positionGroups = groups;
                this.positions = response.data.positions.map(position => {
                    position.isDeleted = false
                    position.isChecked = false
                    position.index = 1
                    return position
                });

                this.$nextTick(() => {
                    let elId = 'position-' + this.positions[this.positions.length - 1].pos_id;
                    let el = document.getElementById(elId);
                    this.scrollPage(el);
                });
            })
        },
        removeChecked() {
            this.positions.forEach(position => {
                if (position.isChecked) {
                    position.isDeleted = true;
                }
            });

            this.removePositions()
        },
        orderUp() {
            let id = this.positions.find(i => i.isChecked).id;

            this.$http.post(
                this.entrypoint + '/position-reorder',
                {id: id, order: 'up'}
            ).then(() => {
                this.loadPositions().then(() => {
                    this.positions.find(i => i.id === id).isChecked = true;
                });
            });
        },
        orderDown() {
            let id = this.positions.find(i => i.isChecked).id;

            this.$http.post(
                this.entrypoint + '/position-reorder',
                {id: id, order: 'down'}
            ).then(() => {
                this.loadPositions().then(() => {
                    this.positions.find(i => i.id === id).isChecked = true;
                });
            });
        },
        onKeydown(e) {
            if (!this.hasCheckedOne) {
                return;
            }

            if (![40, 38, 13, 27].includes(e.keyCode)) {
                return;
            }

            // Down
            if (e.keyCode === 40) {
                this.orderDown();
            }

            // Up
            if (e.keyCode === 38) {
                this.orderUp();
            }

            // Enter Esc
            if (e.keyCode === 13 || e.keyCode === 27) {
                this.$bus.$emit('uncheck');
            }

            e.preventDefault();
        },
        scrollPage(target) {
            let $target = $(target);
            let offset = $target.position().top;
            $target = $target.offsetParent();

            while (!$target.hasClass('page__content')) {
                offset += $target.position().top;
                $target = $target.offsetParent();
            }

            offset += $target.scrollTop();

            offset -= 15;

            $('.page__content').animate({
                scrollTop: offset
            }, 300);
        },
        onRemovePosition(posId) {
            let position = this.positions.find(position => position.pos_id === posId)
            position.isDeleted = true

            this.removePositions()
        },
        onUpdatePosition(userProductId, proposal) {
            let addedPosition = this.positions.find(p => proposal.id && proposal.id === p.prd_id);

            if (addedPosition) {
                return;
            }

            let position = {
                product_id: proposal.id,
                id: userProductId,
                name: proposal.name,
            }

            this.$http.post(
                this.entrypoint + '/save',
                {position: position}
            ).then(response => {
                let index = this.positions.findIndex(position => position.id === userProductId)
                this.$set(this.positions, index, response.data)
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка',
                });
            });
        },
        removePositions() {
            let ids = this.positions
                .filter(position => position.isDeleted)
                .map(position => position.id)

            this.$http.post(
                this.entrypoint + '/delete-to-history',
                {positions: ids}
            ).then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Удалено ' + ids.length + ' позиции(-й)',
                    buttonLabel: 'ОТМЕНИТЬ'
                }).then(resp => {
                    if (resp === 0) {
                        this.$http.post(
                            this.entrypoint + '/undo-delete-to-history',
                            {positions: ids}
                        ).then(() => {
                            this.loadPositions();
                        });
                    }
                });
            }, () => {
                this.positions
                    .filter(position => ids.includes(position.id))
                    .forEach(position => position.isDeleted = false)

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Не удалось удалить позиции.',
                });
            });
        },
    },
    computed: {
        positionGroupsLocal() {
            return this.positionGroups.filter(group => this.getPositionsByGroup(group.id).length > 0)
        },
        positionsLocal() {
            let index = 1;
            return this.positions
                .filter(position => !position.isDeleted)
                .map(position => {
                    position.index = index++
                    return position
                });
        },
        shouldShowGroupName() {
            if (this.positionGroupsLocal.length > 1) {
                return true;
            }

            return this.positionGroupsLocal.length === 0 && this.positionGroupsLocal[0].name !== 'Без группы';
        },
        addedPositions() {
            return this.positions
                .filter(i => i.product)
                .filter(i => !i.isDeleted)
                .map(i => {
                    return {
                        id: i.product,
                        index: i.index,
                    };
                });
        },
        displayedButtons() {
            return this.$route.meta.displayedButtons || []
        },
    },
    watch: {
        positions: {
            handler() {
                let canOpenCatalog = this.positions
                    .filter(i => i.product)
                    .length > 0;

                let canCreateOrder = this.positions
                    .filter(i => i.product || i.name)
                    .length > 0;

                this.$emit(
                    'positionsCountUpdate',
                    {canCreateOrder: canCreateOrder, canOpenCatalog: canOpenCatalog}
                );

                let hasChecked = this.positions
                    .filter(i => i.isChecked && !i.isDeleted)
                    .length > 0;

                let hasCheckedOne = this.positions
                    .filter(i => i.isChecked && !i.isDeleted)
                    .length === 1;

                if (hasChecked) {
                    this.$emit('checked');
                } else {
                    this.$emit('unchecked');
                }

                this.hasChecked = hasChecked;
                this.hasCheckedOne = hasCheckedOne;
            },
            deep: true
        }
    },
    components: {
        InputProposalCreate,
        SelectAddMethodModal,
        'v-position': UserProductPosition,
    },
};
</script>