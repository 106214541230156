<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center">{{ email.subject }}</div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-item>
                    <span class="list-item__title" >{{ email.subject }}</span>
                    <span class="list-item__subtitle" >Тема</span>
                </v-ons-list-item>

                <v-ons-list-item>
                    <span class="list-item__title" >{{ email.to }}</span>
                    <span class="list-item__subtitle" >Кому</span>
                </v-ons-list-item>

                <v-ons-list-item>
                    <span class="list-item__title" >{{ email.reply_to }}</span>
                    <span class="list-item__subtitle" >Reply</span>
                </v-ons-list-item>

                <v-ons-list-item>
                    <span class="list-item__title" >{{ email.sendedAt }}</span>
                    <span class="list-item__subtitle" >Отправлено</span>
                </v-ons-list-item>
            </v-ons-list>

            <iframe v-if="iframeSrc" style="margin-top: 30px; width: 100%;" ref="iframe" @load="iframeLoad" :src="iframeSrc" > </iframe>
            <div v-else >
                <pre style="margin: 30px 16px 0" >Письмо не найдено</pre>
            </div>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            id: null,
            email: {},
            loading: true,
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.$http.get('/api/email-loger/' + this.id).then(response => {
            this.email = response.data;
            this.loading = false;
        });
    },
    methods: {
        iframeLoad() {
            let iframe = this.$refs.iframe;

            if (!iframe) {
                return;
            }

            iframe.style.height = (iframe.contentDocument.body.scrollHeight + 45) +'px';
        }
    },
    computed: {
        iframeSrc() {
            if (!this.id || !this.email.emlFilename) {
                return;
            }

            return '/api/email-loger/' + this.id + '/body?access-token=' + this.$user.token;
        }
    }
};
</script>
