var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "category-opened": _vm.item.isOpen },
      attrs: { id: "category-" + _vm.item.id }
    },
    [
      _c(
        "v-ons-list-item",
        {
          staticClass: "list-item-category",
          staticStyle: { cursor: "pointer" },
          on: {
            click: function($event) {
              return _vm.$emit("clickCategory", _vm.item)
            }
          }
        },
        [
          _c("img", {
            staticClass: "arrow",
            attrs: { src: "/img/right-arrow.svg", alt: "" }
          }),
          _vm._v("\n        " + _vm._s(_vm.item.name) + "\n    ")
        ]
      ),
      _vm._v(" "),
      _vm.item.loading
        ? _c(
            "div",
            { staticStyle: { "text-align": "center", margin: "15px 0" } },
            [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.isOpen
        ? _c(
            "div",
            _vm._l(_vm.item.products, function(product) {
              return _c("product-item-list", {
                key: product.id,
                attrs: {
                  item: product,
                  actionColType: _vm.actionColType,
                  allowInputs: _vm.allowInputs,
                  canOpenProductPage: _vm.canOpenProductPage,
                  checked: _vm.checked,
                  counts: _vm.counts,
                  isDesktop: _vm.isDesktop,
                  lastImportDate: _vm.lastImportDate,
                  selectedPriceType: _vm.selectedPriceType,
                  showInputs: _vm.showInputs,
                  shouldShowRest: _vm.shouldShowRest
                },
                on: {
                  clickGoToView: function($event) {
                    return _vm.$emit("clickGoToView", $event)
                  },
                  toggleFavor: function($event) {
                    return _vm.$emit("toggleFavor", $event)
                  },
                  countEnterDown: function($event) {
                    return _vm.$emit("countEnterDown", $event)
                  },
                  countShiftEnterDown: function($event) {
                    return _vm.$emit("countShiftEnterDown", $event)
                  }
                }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ac2140a0", { render: render, staticRenderFns: staticRenderFns })
  }
}