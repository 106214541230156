<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>
            <div class="center"></div>
        </v-ons-toolbar>

    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    created() {

    }
};
</script>

