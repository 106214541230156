<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" v-show="!isSearchActive" >
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Выберите пользователей</div>

            <div class="right" >
                <v-ons-toolbar-button  @click="searchActive" >
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item text="Опции просмотра" icon="md-format-list-bulleted" @click="showViewParamsModal" />
                </toolbar-popover-menu>

                <v-ons-toolbar-button  @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar inline="true" v-show="isSearchActive" >
            <div class="left">
                <v-ons-toolbar-button @click="searchDeactive">
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center" >
                <input ref="searchInput" v-model="searchQuery" style="
                    color: #fff;
                    background: none;
                    border: none;
                    height: 34px;
                    line-height: 34px;
                    border-bottom: 1px solid #fff;
                " />
            </div>

            <div class="right" >
                <v-ons-toolbar-button  @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <ons-list v-show="users.length > 0" >
                <div v-for="(user, index) in users" :key="user.id">
                    <ons-list-item @click="toggleUser(user.id)" modifier="longdivider">
                        <div class="left" style="min-width: 40px; text-align: center;" >
                            <input type="checkbox" v-model="choosed" :value="user.id"  />
                        </div>
                        <div class="center" >
                            <span class="list-item__title" >
                                {{ user.email }}
                                <span v-show="user.user_type === 'manager'" style="margin-left: 5px; color: #ff5a00" >M</span>
                            </span>

                            <v-list-item-subtitles :item="user" :fields="viewParams" :selected="$user.usersSelectedSubtitles" />

                            <span v-if="user.managerLabel" class="list-item__subtitle" >
                                Менеджер: {{ user.managerLabel }}
                            </span>
                        </div>
                    </ons-list-item>

                    <div
                        v-if="index == myUsersIndex - 1 && index !== 0"
                        style="font-size: 13px; color: #606061; padding: 14px; border-bottom: 1px solid #eee;"
                    >
                        Мои клиенты
                    </div>
                </div>

                <div style="text-align: center; margin: 10px 0;" v-show="page < pageCount" >
                    <v-ons-button @click="loadMore" style="min-width: 145px;" >
                        <span v-show="!loadingMore" >Показать ещё</span>
                        <v-ons-icon v-show="loadingMore" size="30px" spin icon="md-spinner"></v-ons-icon>
                    </v-ons-button>
                </div>
            </ons-list>

            <div v-show="users.length === 0" class="list-empty" :style="{height: $window.height - 56 + 'px'}" >
                Ничего не найдено
            </div>
        </div>

        <v-list-dialog ref="viewParamsModal" multiple="true" title="Опции просмотра" />
    </v-ons-page>
</template>

<script>

import {from, merge, of} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, filter, map, pluck, switchMap, tap} from 'rxjs/operators'

import ListDialog from '~/component/ListDialog'
import ListItemSubtitles from '~/component/ListItemSubtitles'

export default {
    data() {
        return {
            productId: null,
            initChoosed: [],
            choosed: [],
            loading: false,
            loadingMore: false,
            changed: false,
            loaded: false,
            isSearchActive: false,
            searchQuery: '',
            viewParams: [
                {
                    name: 'name',
                    label: 'Имя',
                },
                {
                    name: 'phone',
                    label: 'Телефон',
                },
                {
                    name: 'legal_names',
                    label: 'Юр. лицо',
                },
                {
                    name: 'actual_names',
                    label: 'Наименование',
                }
            ],
            users: [],
            page: 1,
            pageCount: null,
            filters: {
                q: '',
            },
            myUsersIndex: 0,
        }
    },
    created() {
        this.$emit('update');
        this.productId = this.$route.params.prd_id;

        this.$http.get(`/api/catalog/${this.productId}/special-users`).then(response => {
            this.choosed = response.data.map(i => i.id);
            this.initChoosed = response.data.map(i => i.id);
        }, () => {
            this.$ons.notification.toast({
                message: 'Произошла ошибка.',
                buttonLabel: 'OK'
            });
        });
    },
    subscriptions () {
        const $q = this.$watchAsObservable('searchQuery').pipe(
            filter(() => this.searchActive),
            pluck('newValue'),
            map(q => q.trim()),
            filter(q => q.length > 2),
            debounceTime(1000),
            distinctUntilChanged(),

            tap((q) => {
                this.filters.q = q;
                this.loading = true;
                this.users = [];
                this.page = 1;
            })
        );

        const $searchDeactive = this.$eventToObservable('searchDeactive').pipe(
            filter(() => this.filters.q.length > 0),
            tap(() => {
                this.filters.q = '';
                this.loading = true;
                this.users = [];
                this.page = 1;
            })
        );

        const $loadMore = this.$watchAsObservable('page').pipe(
            tap(() => this.loadingMore = true),
        )

        const $update = this.$eventToObservable('update').pipe(
            debounceTime(100),
            tap(() => {
                this.loading = true;
                this.users = [];
                this.page = 1;
            })
        );

        return {
            results: merge($q, $searchDeactive, $update, $loadMore).pipe(
                switchMap(() => from(this.$http.get(`/api/catalog/${this.productId}/avail-special-users`, {params: {filters: this.filters, page: this.page}}))),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of([]);
                }),
                map(response => {
                    let managers = response.managers;
                    let users = response.items.map(u => {
                        if (u.manager_id == this.$user.id) {
                            return u;
                        }

                        let manager = managers.find(m => m.id == u.manager_id);
                        u.managerLabel = manager.name + ' ' + manager.email;
                        return u;
                    });

                    this.users = this.users.concat(users);
                    this.pageCount = response.pageCount;

                    this.myUsersIndex = 0;

                    for (let i = 0; i < this.users.length; i++) {
                        this.myUsersIndex = i;

                        if (this.users[i].manager_id == this.$user.id) {
                            break;
                        }
                    }
                }),
                tap(() => {
                    this.loadingMore = false;
                    this.loading = false;
                }),
            )
        }
    },
    methods: {
        save() {
            this.$http.post(
                `/api/catalog/${this.productId}/special-users`,
                {users: this.choosed}
            ).then(() => {
                this.$bus.$emit('catalog-update-product', this.productId);
                this.$router.go(-1);
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        loadMore() {
            if (this.page != null && this.pageCount != null && this.page > this.pageCount) {
                return;
            }

            if (this.loadingMore) {
                return;
            }

            this.page += 1;
        },
        toggleUser(id) {
            if (this.choosed.includes(id)) {
                this.choosed = this.choosed.filter(i => i != id);
            } else {
                this.choosed.push(id);
            }
        },
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        showViewParamsModal() {
            let items = this.viewParams.map(i => {
                return {
                    id: i.name,
                    name: i.label,
                }
            });

            this.$refs.viewParamsModal.show({items: items, selected: this.$user.usersSelectedSubtitles}).then(selected => {
                this.$user.setUsersSelectedSubtitles(selected);
            });
        },
        searchActive() {
            this.isSearchActive = true;
            this.searchQuery = '';
            this.$nextTick(() => {
                this.$refs.searchInput.focus();
            });
        },
        searchDeactive() {
            this.isSearchActive = false;
            this.$emit('searchDeactive');
        },
    },
    components: {
        'v-list-dialog': ListDialog,
        'v-list-item-subtitles': ListItemSubtitles,
    },
    watch: {
        choosed: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>