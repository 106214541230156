var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      staticClass: "catalog-settings-rename-prompt",
      attrs: { visible: _vm.visible, cancelable: "" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "alert-dialog-container alert-dialog-container--material"
        },
        [
          _c(
            "div",
            { staticClass: "alert-dialog-title alert-dialog-title--material" },
            [_vm._v("\n            Новое имя раздела\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "alert-dialog-content alert-dialog-content--material"
            },
            [
              _c(
                "div",
                { staticClass: "input-wrap" },
                [
                  _c("ons-input", { attrs: { modifier: "material" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.valueProp,
                          expression: "valueProp"
                        }
                      ],
                      ref: "input",
                      staticClass: "text-input text-input--material",
                      attrs: { type: "text" },
                      domProps: { value: _vm.valueProp },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.valueProp = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass:
                        "text-input__label text-input--material__label"
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn-clear", on: { click: _vm.clear } },
                    [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "alert-dialog-footer alert-dialog-footer--rowfooter alert-dialog-footer--material alert-dialog-footer--rowfooter--material"
            },
            [
              _c("v-ons-alert-dialog-button", { on: { click: _vm.choose } }, [
                _vm._v("OK")
              ]),
              _vm._v(" "),
              _c("v-ons-alert-dialog-button", { on: { click: _vm.close } }, [
                _vm._v("Отмена")
              ]),
              _vm._v(" "),
              _c(
                "v-ons-alert-dialog-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.originalTitle,
                      expression: "originalTitle"
                    }
                  ],
                  on: { click: _vm.reset }
                },
                [_vm._v("Сбросить")]
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-38b753c2", { render: render, staticRenderFns: staticRenderFns })
  }
}