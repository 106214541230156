<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>
            <div class="center"></div>
        </v-ons-toolbar>

        <p style="text-align: center; margin: 40px 0; font-size: 25px;" >Страница не найдена</p>

        <div class="text-center" >
            <v-ons-button @click="$router.push('/')" >
                Вернуться на главную
            </v-ons-button>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
        }
    },
};
</script>


