<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Выгрузка разделов</div>

            <div class="right" v-show="!loading" >
                <v-ons-toolbar-button @click="save" title="Сохранить" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-card>
                Выберите какие разделы должны выгружаться в ваш Каталог
            </v-ons-card>

            <v-ons-list>
                <ons-list-item v-for="(category, key) in categories" :key="category.id" @click.prevent="toggleCheckbox(category)" >
                    <div class="center" >
                        <span class="list-item__title" >
                            {{ category.name }}
                        </span>

                        <span class="list-item__subtitle" style="color: red" v-show="category.is_deleted" >Раздел не найден</span>
                    </div>
                    <div class="right">
                        <v-ons-icon
                            icon="md-check"
                            :style="{
                                fontSize: '20px',
                                color: includes.includes(category.id.toString()) ? '#009688' : '#cccaca',
                                fontWeight: includes.includes(category.id.toString()) ? 'bold' : '',
                            }"
                        ></v-ons-icon>
                    </div>
                </ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            changed: false,
            loaded: false,

            categories: [],
            includes: [],
            categoriesInUserProducts: [],
        }
    },
    created() {
        let requests = [
            this.$http.get(
                '/api/manager/catalog-settings/common-categories',
                {params: {storageId: this.$route.params.storage_id}}
    ),
            this.$http.get(
                '/api/manager/catalog-settings/included-categories',
                {params: {storageId: this.$route.params.storage_id}}
            ),
            this.$http.get(
                '/api/manager/catalog-settings/categories-in-user-products',
                {params: {storageId: this.$route.params.storage_id}}
            ),
        ];

        Promise.all(requests).then(responses => {
            this.categories = responses[0].data;
            this.includes = responses[1].data.map(i => i.id.toString());
            this.categoriesInUserProducts = responses[2].data;

            this.$nextTick(() => {
                this.loaded = true;
                this.loading = false;
            });
        }, () => {
            this.$ons.notification.toast({
                buttonLabel: 'OK',
                message: 'Произошла ошибка.',
            });
        });
    },
    methods: {
        save() {
            this.$http.post(
                '/api/manager/catalog-settings/included-categories',
                {includes: this.includes},
                {params: {storageId: this.$route.params.storage_id}}
                ).then(() => {
                this.$bus.$emit('checkErrors');
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Сохранено',
                    buttonLabel: 'OK',
                });
                this.$router.go(-1);
            }, () => {
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        toggleCheckbox(category) {
            let id = category.id.toString();

            if (this.includes.includes(id)) {
                if (this.categoriesInUserProducts.includes(id)) {
                    this.$ons.notification.confirm('Товары из этого раздела у пользователя в избранном. Продолжить?', {
                        title: 'Подтверждение',
                        buttonLabels: ['Нет', 'Да']
                    }).then(response => {
                        if (response === 0) {
                            return;
                        }

                        let index = this.includes.indexOf(id);
                        this.includes.splice(index, 1);
                    });

                    return;
                }

                let index = this.includes.indexOf(id);
                this.includes.splice(index, 1);
            } else {
                this.includes.push(id);
            }
        },
    },
    watch: {
        includes: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>