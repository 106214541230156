var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { visible: _vm.visible, cancelable: "" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "center" }, [_vm._v("Изменить тип цен на:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Закрыть" }, on: { click: _vm.close } },
              [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          style: {
            maxHeight: _vm.$window.height - 110 + "px",
            overflowY: "auto"
          }
        },
        [
          _vm.customPriceTypes.length > 0
            ? _c("v-ons-list-header", [
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    "\n                Индивидуальное ценообразование\n            "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.customPriceTypes, function(item) {
            return _c("v-ons-list-item", { key: item.id }, [
              _c(
                "label",
                {
                  staticClass: "label-reset center",
                  attrs: { for: "radio-" + item.id }
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(item.name) + "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right", staticStyle: { "min-width": "0" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.markup != 0,
                          expression: "item.markup != 0"
                        }
                      ],
                      staticStyle: { "margin-right": "10px", color: "grey" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.markup) +
                          "%\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-ons-radio", {
                    attrs: { "input-id": "radio-" + item.id, value: item.id },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            ])
          }),
          _vm._v(" "),
          _c("v-ons-list-header", [
            _c("div", { staticClass: "center" }, [
              _vm._v("\n                Общее ценообразование\n            ")
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.nonCustomPriceTypes, function(item) {
            return _c("v-ons-list-item", { key: item.id }, [
              _c(
                "label",
                {
                  staticClass: "label-reset center",
                  attrs: { for: "radio-" + item.id }
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(item.name) + "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right", staticStyle: { "min-width": "0" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.markup != 0,
                          expression: "item.markup != 0"
                        }
                      ],
                      staticStyle: { "margin-right": "10px", color: "grey" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.markup) +
                          "%\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-ons-radio", {
                    attrs: { "input-id": "radio-" + item.id, value: item.id },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            ])
          }),
          _vm._v(" "),
          _vm.shouldShowDisabledDiscount
            ? _c("v-ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "label-reset center",
                    attrs: { for: "disabledDiscountInput" }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.discount.name) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right", staticStyle: { "min-width": "0" } },
                  [
                    _c("v-ons-checkbox", {
                      attrs: { "input-id": "disabledDiscountInput" },
                      model: {
                        value: _vm.discount.enabled,
                        callback: function($$v) {
                          _vm.$set(_vm.discount, "enabled", $$v)
                        },
                        expression: "discount.enabled"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-ons-list-item", [
            _vm._v(
              "\n            % указаны без учёта ручных корректировок цен. Ручные корректировки цен сбросятся.\n        "
            )
          ]),
          _vm._v(" "),
          _vm.showNotySendAlert
            ? _c(
                "v-ons-list-item",
                { staticStyle: { color: "#dd4b39", "font-weight": "500" } },
                [
                  _vm._v(
                    "\n            ВНИМАНИЕ! Клиент получит уведомление об увеличении/уменьшении итоговой суммы заказа в %\n        "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ons-row",
        {
          staticClass: "text-center",
          staticStyle: { "margin-bottom": "5px", "justify-content": "center" }
        },
        [
          _c(
            "ons-col",
            { attrs: { width: "50%" } },
            [
              _c(
                "v-ons-button",
                {
                  attrs: { disabled: _vm.selected === null },
                  on: {
                    click: function($event) {
                      return _vm.choose()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.isRecalc ? "Пересчитать" : "Выбрать") +
                      "\n            "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4262ce75", { render: render, staticRenderFns: staticRenderFns })
  }
}