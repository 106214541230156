import Branches from '~/screen/manager/users/Branches'
import Branch from '~/screen/manager/users/Branch'
import Entity from '~/screen/manager/users/Entity'


let route = [
    {
        path: 'branches',
        name: 'manager-user-branches',
        component: Branches,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'create',
                name: 'manager-user-branch-create',
                component: Branch,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'entity',
                        name: 'manager-user-branch-create-entity-create',
                        component: Entity,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ],
            },
            {
                path: ':branch_id',
                name: 'manager-user-branch',
                component: Branch,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'entity',
                        name: 'manager-user-branch-create-entity',
                        component: Entity,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ],
            },
        ],
    },
]

export default route;
