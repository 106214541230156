var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "manager-user-settings" },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Индивидуальный тип цен")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        [
          _c("div", { staticClass: "wrap" }, [
            _c("div", { staticClass: "col xl-1-2" }, [
              _c("div", { staticClass: "list-header list-header--material" }, [
                _vm._v(
                  "\n                    Типы клиентов/цен:\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col xl-1-2" }, [
              _c(
                "div",
                {
                  staticClass: "list-header list-header--material",
                  staticStyle: { "text-align": "right" }
                },
                [
                  _vm._v("\n                    % наценки"),
                  _c("br"),
                  _vm._v("от базового типа цен\n                ")
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.priceTypes, function(type) {
            return _c(
              "ons-list-item",
              { key: type.id, attrs: { modifier: "longdivider" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "left",
                    staticStyle: { "margin-right": "10px", "min-width": "0" }
                  },
                  [
                    _c("v-ons-radio", {
                      attrs: {
                        "input-id": "radio-" + _vm.templateId + "-" + type.id,
                        value: type.id
                      },
                      model: {
                        value: _vm.priceType,
                        callback: function($$v) {
                          _vm.priceType = $$v
                        },
                        expression: "priceType"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "radio-" + _vm.templateId + "-" + type.id }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(type.name) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: type.markup,
                        expression: "type.markup"
                      }
                    ],
                    staticClass: "without-arrow",
                    staticStyle: { width: "50px", "text-align": "center" },
                    attrs: { type: "number" },
                    domProps: { value: type.markup },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(type, "markup", $event.target.value)
                      }
                    }
                  })
                ])
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "ons-list",
        [
          _c("ons-list-item", { attrs: { modifier: "longdivider" } }, [
            _c("div", { staticClass: "center" }, [
              _c("b", [_vm._v("Комиссия при оплате по терминалу (%)")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.terminalTax,
                    expression: "terminalTax"
                  }
                ],
                staticClass: "without-arrow",
                staticStyle: { "text-align": "center", width: "50px" },
                attrs: { type: "number", placeholder: "0" },
                domProps: { value: _vm.terminalTax },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.terminalTax = $event.target.value
                  }
                }
              })
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "list-header--material" }, [
        _vm._v("Способ предоставления скидки клиенту:")
      ]),
      _vm._v(" "),
      _c("discount-section", {
        attrs: {
          title: _vm.discounts.get_discount.name,
          forPriceType: _vm.discounts.get_discount.forPriceType,
          gotoPriceType: _vm.discounts.get_discount.gotoPriceType,
          isEnabled: _vm.discounts.get_discount.isEnabled,
          priceTypes: _vm.priceTypes
        },
        on: {
          "update:forPriceType": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "forPriceType", $event)
          },
          "update:for-price-type": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "forPriceType", $event)
          },
          "update:gotoPriceType": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "gotoPriceType", $event)
          },
          "update:goto-price-type": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "gotoPriceType", $event)
          },
          "update:isEnabled": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "isEnabled", $event)
          },
          "update:is-enabled": function($event) {
            return _vm.$set(_vm.discounts.get_discount, "isEnabled", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("discount-section", {
        attrs: {
          title: _vm.discounts.order_amount_more.name,
          forPriceType: _vm.discounts.order_amount_more.forPriceType,
          gotoPriceType: _vm.discounts.order_amount_more.gotoPriceType,
          isEnabled: _vm.discounts.order_amount_more.isEnabled,
          amount: _vm.discounts.order_amount_more.amount,
          hasAmount: true,
          priceTypes: _vm.priceTypes
        },
        on: {
          "update:forPriceType": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "forPriceType",
              $event
            )
          },
          "update:for-price-type": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "forPriceType",
              $event
            )
          },
          "update:gotoPriceType": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "gotoPriceType",
              $event
            )
          },
          "update:goto-price-type": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "gotoPriceType",
              $event
            )
          },
          "update:isEnabled": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "isEnabled",
              $event
            )
          },
          "update:is-enabled": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more,
              "isEnabled",
              $event
            )
          },
          "update:amount": function($event) {
            return _vm.$set(_vm.discounts.order_amount_more, "amount", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("discount-section", {
        attrs: {
          title: _vm.discounts.order_amount_more_month.name,
          forPriceType: _vm.discounts.order_amount_more_month.forPriceType,
          gotoPriceType: _vm.discounts.order_amount_more_month.gotoPriceType,
          isEnabled: _vm.discounts.order_amount_more_month.isEnabled,
          amount: _vm.discounts.order_amount_more_month.amount,
          hasAmount: true,
          priceTypes: _vm.priceTypes
        },
        on: {
          "update:forPriceType": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "forPriceType",
              $event
            )
          },
          "update:for-price-type": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "forPriceType",
              $event
            )
          },
          "update:gotoPriceType": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "gotoPriceType",
              $event
            )
          },
          "update:goto-price-type": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "gotoPriceType",
              $event
            )
          },
          "update:isEnabled": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "isEnabled",
              $event
            )
          },
          "update:is-enabled": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "isEnabled",
              $event
            )
          },
          "update:amount": function($event) {
            return _vm.$set(
              _vm.discounts.order_amount_more_month,
              "amount",
              $event
            )
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-68516ec2", { render: render, staticRenderFns: staticRenderFns })
  }
}