import Vue from 'vue';

import Confirm from './Confirm'

export default Vue.extend({
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        confirm(title) {
            let Confirmlass = Vue.extend(Confirm);
            let instance = new Confirmlass();
            instance.$mount();

            document.body.appendChild(instance.$el);

            return instance.show(title).then(response => {
                instance.$destroy();
                return response;
            });
        }
    },
});
