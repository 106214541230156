<template>
    <v-ons-page>
        <v-ons-toolbar inline="true">
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack"></v-ons-back-button>
            </div>
            <div class="center">Склад: {{ storage.name }}</div>

            <div class="right">
                <v-ons-toolbar-button @click="save" title="Сохранить">
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item>
                <v-ons-checkbox
                    v-model="storage.is_active"
                    input-id='storage.is_active'
                    style="vertical-align: middle; margin: 0 12px 0 0;"
                ></v-ons-checkbox>
                <label for="storage.is_active" class="label-reset">Активировать склад</label>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list v-show="storage.is_active">
            <v-ons-list-item>
                <v-ons-checkbox
                    v-model="storage.is_default"
                    input-id='storage.is_default'
                    style="vertical-align: middle; margin: 0 12px 0 0;"
                ></v-ons-checkbox>
                <label for="storage.is_default" class="label-reset">Склад по умолчанию</label>
            </v-ons-list-item>

            <v-ons-list-header>Фактическое наименование</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="storage.real_name" name="storage_real_name" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Адрес склада</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="storage.address" name="storage_address" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Графика самовывоза</v-ons-list-header>

            <table class="table" style="margin: 0 16px">
                <tbody>
                <tr v-for="weekday in weekdays" >
                    <td>
                        {{ weekday.label }}
                    </td>

                    <td class="text-center" >
                        с
                        <input
                            type="time"
                            v-model="weekday.from_time"
                            style="text-align: center; margin: 0 auto; max-width: 100px"
                        />
                    </td>

                    <td class="text-center" >
                        до
                        <input
                            type="time"
                            v-model="weekday.to_time"
                            style="text-align: center; margin: 0 auto; max-width: 100px"
                        />
                    </td>
                </tr>
                </tbody>
            </table>

            <v-ons-list-header>Юр. лицо</v-ons-list-header>
            <v-ons-list-item v-for="(entity, key) in entities" :key="entity.id" >
                <label :for="'storage-entity-' + key" class="center label-reset" >
                    {{ entity.legal_name }}
                </label>
                <div class="right">
                    <v-ons-checkbox
                        v-model="storage.entities"
                        :value="entity.id"
                        :input-id="'storage-entity-' + key"
                        :disabled="entities.length === 1"
                        style="vertical-align: middle; margin: 0 12px 0 0;"
                    ></v-ons-checkbox>
                </div>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            changed: false,
            loaded: false,

            id: null,
            storage: {
                entities: [],
            },
            weekdays: [],
            entities: [],
        }
    },
    created() {
        this.id = this.$route.params.storage_id;
        this.load();
    },
    methods: {
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        load() {
            let requests = [
                this.$http.get('/api/settings/entities'),
                this.$http.get('/api/manager/storages/' + this.id),
                this.$http.get(
                    '/api/manager/management/pickup-shipment-schedule',
                    {params: {storageId: this.id}}
                ),
            ];

            Promise.all(requests).then(responses => {
                this.entities = responses[0].data;
                this.storage = responses[1].data;
                this.storage.entities = this.storage.entities.map(i => {
                    return i.id.toString();
                });
                this.weekdays = responses[2].data;

                this.$nextTick(() => {
                    this.loaded = true;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loaded = true;
                });

                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            })
        },
        save() {
            let storage = this.storage;
            storage.weekdays = this.weekdays;

            this.$http.post(
                '/api/manager/storages/' + this.id,
                {storage: storage}
            ).then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Сохранено',
                    buttonLabel: 'OK',
                });

                this.$bus.$emit('update-storages');

                this.$router.go(-1);
            }, (response) => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                });
            });
        },
    },
    watch: {
        storage: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>