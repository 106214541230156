<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar inline="true" >
            <div class="center">Изменить тип цен на:</div>
            <div class="right" >
                <v-ons-toolbar-button @click="close" title="Закрыть" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list :style="{maxHeight: ($window.height - 110) + 'px', overflowY: 'auto'}" >
            <v-ons-list-header v-if="customPriceTypes.length > 0" >
                <div class="center" >
                    Индивидуальное ценообразование
                </div>
            </v-ons-list-header>

            <v-ons-list-item v-for="item in customPriceTypes" :key="item.id">
                <label :for="'radio-' + item.id" class="label-reset center" >
                    {{ item.name }}
                </label>

                <div class="right" style="min-width: 0;">
                    <div v-show="item.markup != 0" style="margin-right: 10px; color: grey;" >
                        {{ item.markup }}%
                    </div>

                    <v-ons-radio
                        :input-id="'radio-' + item.id"
                        :value="item.id"
                        v-model="selected"
                    ></v-ons-radio>
                </div>
            </v-ons-list-item>

            <v-ons-list-header>
                <div class="center" >
                    Общее ценообразование
                </div>
            </v-ons-list-header>

            <v-ons-list-item v-for="item in nonCustomPriceTypes" :key="item.id" >
                <label :for="'radio-' + item.id" class="label-reset center" >
                    {{ item.name }}
                </label>

                <div class="right" style="min-width: 0;">
                    <div v-show="item.markup != 0" style="margin-right: 10px; color: grey;" >
                        {{ item.markup }}%
                    </div>

                    <v-ons-radio
                        :input-id="'radio-' + item.id"
                        :value="item.id"
                        v-model="selected"
                    ></v-ons-radio>
                </div>
            </v-ons-list-item>

            <v-ons-list-item v-if="shouldShowDisabledDiscount" >
                <label class="label-reset center" for="disabledDiscountInput" >
                    {{ discount.name }}
                </label>

                <div class="right" style="min-width: 0;">
                    <v-ons-checkbox v-model="discount.enabled" input-id="disabledDiscountInput" ></v-ons-checkbox>
                </div>
            </v-ons-list-item>

            <v-ons-list-item>
                % указаны без учёта ручных корректировок цен. Ручные корректировки цен сбросятся.
            </v-ons-list-item>

            <v-ons-list-item v-if="showNotySendAlert" style="color: #dd4b39; font-weight: 500;" >
                ВНИМАНИЕ! Клиент получит уведомление об увеличении/уменьшении итоговой суммы заказа в %
            </v-ons-list-item>
        </v-ons-list>


        <ons-row class="text-center" style="margin-bottom: 5px; justify-content: center;" >
            <ons-col width="50%">
                <v-ons-button @click="choose()" :disabled="selected === null" >
                    {{ isRecalc ? 'Пересчитать' : 'Выбрать' }}
                </v-ons-button>
            </ons-col>
        </ons-row>
    </v-ons-dialog>
</template>

<script>
export default {
    props: [
        'showNotySendAlert',
    ],
    data() {
        return {
            original: null,
            selected: null,
            visible: false,
            resolve: null,
            reject: null,

            order: {},
            itemsData: [],
            discount: {},
        }
    },
    methods: {
        show(params) {
            this.original = params.selected.toString();
            this.selected = params.selected;
            this.itemsData = params.items;
            this.discount = params.discount;
            this.order = params.order;
            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.visible = false;
        },
        choose() {
            this.visible = false;

            this.resolve({
                priceType: this.selected,
                isDiscountEnable: this.discount.enabled,
            });
        },
    },
    computed: {
        nonCustomPriceTypes() {
            return this.itemsData.filter(i => !i.isCustom);
        },
        customPriceTypes() {
            return this.itemsData.filter(i => i.isCustom);
        },
        isRecalc() {
            if (this.selected != this.original) {
                return;
            }

            let item = this.itemsData.find(i => i.id.toString() == this.selected);

            if (!item) {
                return false;
            }

            return item.markup;
        },
        shouldShowDisabledDiscount() {
            if (this.selected != this.original) {
                return false;
            }

            if (this.order.isDiscountAvailable) {
                return true;
            }

            return false;
        },
    },
};
</script>

