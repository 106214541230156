<template>
    <div v-frag >
        <!-- Desktop -->
        <tr v-if="$window.isDesktop" class="manager-order-position-update" :id="'position-' + model.pos_id" >
            <td @click="save" >
                <v-ons-button>
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-button>
            </td>
            <td class="col-name" >{{ position.name }}</td>
            <td class="col-count" >
                <div style="display: flex; justify-content: center; gap: 10px;">
                    <div class="count-wrap">
                        <div class="wrap">
                            <div class="col col-button" >
                                <div @click="decCount" class="btn" >-</div>
                            </div>

                            <div class="col col-input">
                                <input
                                    ref="inputCount"
                                    :id="'position-count-' + model.pos_id"
                                    type="number"
                                    class="input-count without-arrow"
                                    v-model="model.count"
                                >
                            </div>
                            <div class="col col-button" >
                                <div @click="incCount" class="btn" >+</div>
                            </div>
                        </div>
                    </div>

                    <v-ons-select v-if="!model.prd_id" v-model="model.unit" style="width: 50px;" >
                        <option v-for="unit in units" :value="unit.id">
                            {{ unit.name }}
                        </option>
                    </v-ons-select>
                </div>
            </td>
            <td class="col-price" >
                <input type="number" class="input-price without-arrow" v-model="model.price" />
            </td>
            <td class="col-action" @click="$emit('startEdit', model.pos_id)" >
                <div class="wrap xl-gutter-8" >
                    <div class="col xl-1-2" >
                        <v-ons-button @click="remove" style="background: #d60000;" >
                            <v-ons-icon icon="md-close"></v-ons-icon>
                        </v-ons-button>
                    </div>
                    <div class="col xl-1-2" >
                        <v-ons-button @click="cancel" >
                            <v-ons-icon icon="md-block"></v-ons-icon>
                        </v-ons-button>
                    </div>
                </div>
            </td>
        </tr>

        <!-- Mobile -->
        <tr v-if="!$window.isDesktop" class="manager-order-position-update" :id="'position-' + model.pos_id" >
            <td>{{ index }}</td>
            <td class="col-name" colspan="2" >{{ model.name }}</td>
            <td class="col-action" colspan="2" >
                <div class="wrap" >
                    <div class="col xl-1-2 text-right" >
                        <v-ons-button @click="remove" style="background: #d60000; margin: 0 2px;" >
                            <v-ons-icon icon="md-close"></v-ons-icon>
                        </v-ons-button>
                    </div>
                    <div class="col xl-1-2 text-right" >
                        <v-ons-button @click="cancel" style="margin: 0 2px" >
                            <v-ons-icon icon="md-block"></v-ons-icon>
                        </v-ons-button>
                    </div>
                </div>
            </td>
        </tr>
        <tr v-if="!$window.isDesktop" class="manager-order-position-update mobile" >
            <td colspan="5" >
                <div class="wrap" style="display: flex; justify-content: space-between" >
                    <div class="col xl-1-6 text-center" >
                        <v-ons-button @click="save" >
                            <v-ons-icon icon="md-check"></v-ons-icon>
                        </v-ons-button>
                    </div>
                    <div class="col xl-2-6" style="min-width: 180px" >
                        <div style="display: flex; justify-content: center; gap: 10px;">
                            <div class="count-wrap">
                                <div class="wrap">
                                    <div class="col col-button" >
                                        <div @click="decCount" class="btn" >-</div>
                                    </div>

                                    <div class="col col-input">
                                        <input
                                            ref="inputCount"
                                            :id="'position-count-' + model.pos_id"
                                            type="number"
                                            class="input-count without-arrow"
                                            v-model="model.count"
                                        >
                                    </div>
                                    <div class="col col-button" >
                                        <div @click="incCount" class="btn" >+</div>
                                    </div>
                                </div>
                            </div>

                            <v-ons-select v-if="!model.prd_id" v-model="position.unit" style="width: 50px;" >
                                <option v-for="unit in units" :value="unit.id">
                                    {{ unit.name }}
                                </option>
                            </v-ons-select>
                        </div>

                    </div>
                    <div class="col xl-2-6" >
                        <input type="number" class="input-price without-arrow" v-model="model.price" />
                    </div>
                </div>
            </td>
        </tr>
    </div>
</template>

<script>

import InputProposal from '~/component/InputProposal'
import numeral from 'numeral'

export default {
    props: [
        'position',
        'index',
        'hasDiscount',
        'urlSearch',
        'units',
    ],
    data() {
        return {
            model: {},
        }
    },
    created() {
        let pos = Object.assign({}, this.position);
        pos.product = pos.prd_id;

        if (this.hasDiscount) {
            pos.price = pos.price_discount;
        }

        this.model = pos;

        window.addEventListener('keydown', this.keyupListener);
    },
    destroyed() {
        window.removeEventListener('keydown', this.keyupListener);
    },
    methods: {
        incCount() {
            let mult = 1;
            let count = this.model.count;

            if (this.model.mult) {
                mult = parseFloat(this.model.mult);
            }

            if (!count) {
                count = mult;
            } else {
                count = parseFloat(count) + mult;
            }

            this.model.count = numeral(count).format('0[.][000]');

            if (!this.$window.isDesktop) {
                this.$refs.inputCount.focus();
            }
        },
        decCount() {
            let mult = 1;
            let count = this.model.count;

            if (this.model.mult) {
                mult = parseFloat(this.model.mult);
            }

            count = parseFloat(count) - mult;

            if (count <= 0) {
                count = '';
            } else {
                count = numeral(count).format('0[.][000]');
            }

            this.model.count = count;

            this.$emit('updateCount', this.model.pos_id);

            if (!this.$window.isDesktop) {
                this.$refs.inputCount.focus();
            }
        },
        save() {
            let position = {
                pos_id: this.position.pos_id,
                prd_id: this.model.product,
                name: this.model.name,
                count: this.model.count,
                price: this.model.price,
                unit: this.model.unit,
            }

            this.$emit('save', position);
        },
        remove() {
            this.$emit('remove', this.position.pos_id);
        },
        cancel() {
            this.$emit('cancel', this.position.pos_id);
        },
        keyupListener(event) {
            // Esc
            if (event.keyCode === 27) {
                this.cancel();
                event.preventDefault();
                return false;
            }

            // Enter
            if (event.keyCode === 13) {
                this.save();
                event.preventDefault();
                return false;
            }

            // plus
            if (event.keyCode === 107 || event.keyCode === 187) {
                event.preventDefault();
                this.incCount();
                return;
            }

            // minus
            if (event.keyCode === 109 || event.keyCode === 189) {
                event.preventDefault();
                this.decCount();

            }
        },
    },
    components: {
        InputProposal,
    },
};
</script>