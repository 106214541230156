<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Auth: {{ user.email }}</div>
        </v-ons-toolbar>

        <v-ons-card> Текущая сессия завершится!  </v-ons-card>

        <br />

        <div style="text-align: center" >
            <v-ons-button @click="click" >Авторизоваться</v-ons-button>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            user: {},
            token: {},
        }
    },
    created() {
        this.$http.get('/api/manager/users/' + this.$route.params.user_id).then(response => {
            this.user = response.data;
        });

        this.$http.get('/api/manager/users/get-auth-token', {params: {id: this.$route.params.user_id}}).then(response => {
            this.token = response.data;
        });
    },
    methods: {
        click() {
            this.$http.post('/api/auth/sign-in', {authToken: this.token}).then(response => {
                this.$user.reset();
                this.$user.setToken(response.data.token);

                this.$router.push('/');
            }, response => {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Произошла ошибка',
                });
            });
        }
    }
};
</script>
