<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">{{ product.name }}</div>

            <div class="right">
                <v-ons-toolbar-button v-show="hasChecked" @click="uncheckAll">
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="!hasChecked" @click="checkAll">
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}">
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <ons-list v-show="users">
                <ons-list-item
                    v-for="user in users"
                    :key="user.id"
                    modifier="longdivider"
                >
                    <div class="left" style="min-width: 40px; text-align: center;">
                        <v-ons-checkbox v-model="user.isChecked"/>
                    </div>
                    <div class="center">
                        {{ user.id }} - {{ user.email }}
                    </div>

                    <div
                        class="right cursor-pointer"
                        @click="user.popoverMenuVisible = true"
                        :id="'popoverMenuVisible-productLinkUsers-' + user.id"
                    >
                        <v-ons-icon icon="md-more-vert" size="20px"></v-ons-icon>

                        <v-ons-popover
                            cancelable
                            :visible.sync="user.popoverMenuVisible"
                            :target="'#popoverMenuVisible-productLinkUsers-' + user.id"
                            cover-target="true"
                            direction="down"
                            class="order-popover-menu"
                        >
                            <ons-list @click="user.popoverMenuVisible = false;">
                                <ons-list-item
                                    @click="$router.push($router.currentRoute.path + '/products-' + user.id)">
                                    <div class="left icon">
                                        <v-ons-icon icon="md-folder-star-alt"></v-ons-icon>
                                    </div>
                                    <div class="center">Перейти в товары клиента</div>
                                </ons-list-item>
                            </ons-list>
                        </v-ons-popover>
                    </div>
                </ons-list-item>
            </ons-list>

            <v-ons-fab
                v-show="hasChecked"
                position="bottom right"
                @click="$router.push({name: 'manager-product-link-products-product', params: {users: checkedUserIds}})"
            >
                <v-ons-icon icon="md-arrow-right"></v-ons-icon>
            </v-ons-fab>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            storageId: null,

            product: {},
            users: [],
        }
    },
    created() {
        this.id = this.$route.params.product_id;
        this.type = this.$route.params.type;
        this.storageId = this.$route.params.storage_id;

        this.load();
    },
    methods: {
        checkAll() {
            this.users.forEach(i => {
                i.isChecked = true;
            })
        },
        uncheckAll() {
            this.users.forEach(i => {
                i.isChecked = false;
            })
        },
        load() {
            let requests = [];

            if (this.type === 'product') {
                requests = [
                    this.$http.get(
                        '/api/manager/product-link/get-product-from-catalog',
                        {params: {id: this.id}}
                    ),
                    this.$http.get(
                        '/api/manager/product-link/get-users-by-product',
                        {params: {id: this.id, storageId: this.storageId}}
                    ),
                ];
            } else {
                requests = [
                    this.$http.get(
                        '/api/manager/product-link/get-product',
                        {params: {id: this.id}}
                    ),
                    this.$http.get(
                        '/api/manager/product-link/get-users-by-userproduct',
                        {params: {id: this.id}}
                    ),
                ];
            }

            Promise.all(requests).then(responses => {
                this.product = responses[0].data;
                this.users = responses[1].data.map(i => {
                    i.isChecked = false;
                    i.popoverMenuVisible = false;

                    return i;
                });

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
    },
    computed: {
        hasChecked() {
            return this.users
                .filter(i => i.isChecked)
                .length > 0;
        },
        checkedUserIds() {
            return this.users
                .filter(i => i.isChecked)
                .map(i => i.id);
        }
    }
};
</script>