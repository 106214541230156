var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.id
          ? _c("div", { staticClass: "center" }, [
              _vm._v("Филиал: " + _vm._s(_vm.branch.actual_name))
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.id
          ? _c("div", { staticClass: "center" }, [_vm._v("Создать филиал")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.id
              ? _c(
                  "v-ons-toolbar-button",
                  { on: { click: _vm.remove } },
                  [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [
            _vm._v("Фактическое наименование (Вывеска)")
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "actual_name" },
                model: {
                  value: _vm.branch.actual_name,
                  callback: function($$v) {
                    _vm.$set(_vm.branch, "actual_name", $$v)
                  },
                  expression: "branch.actual_name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [
            _vm._v("Фактический адрес (Адрес доставки)")
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-address-form", {
                attrs: { title: "Фактический адрес (Адрес доставки)" },
                model: {
                  value: _vm.branch.address,
                  callback: function($$v) {
                    _vm.$set(_vm.branch, "address", $$v)
                  },
                  expression: "branch.address"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Маршрут")]),
          _vm._v(" "),
          _c("v-ons-list-item", [
            _c(
              "div",
              { staticClass: "select-material select--material select" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.branch.route_id,
                        expression: "branch.route_id"
                      }
                    ],
                    staticClass: "select-input select-input--material",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.branch,
                          "route_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: "" } }, [
                      _vm._v("не выбрано")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.routes, function(route) {
                      return _c(
                        "option",
                        { key: route.id, domProps: { value: route.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(route.name) +
                              "\n                    "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("График работы")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "work_time" },
                model: {
                  value: _vm.branch.work_time,
                  callback: function($$v) {
                    _vm.$set(_vm.branch, "work_time", $$v)
                  },
                  expression: "branch.work_time"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [
            _vm._v("Комментарий о проезде и разгрузке")
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "delivery_comment" },
                model: {
                  value: _vm.branch.delivery_comment,
                  callback: function($$v) {
                    _vm.$set(_vm.branch, "delivery_comment", $$v)
                  },
                  expression: "branch.delivery_comment"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Юр. лицо")]),
          _vm._v(" "),
          _vm._l(_vm.entities, function(entity, key) {
            return _c("v-ons-list-item", { key: entity.id }, [
              _c(
                "label",
                {
                  staticClass: "center label-reset",
                  attrs: { for: "entity-" + key }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(entity.legal_name) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("v-ons-checkbox", {
                    staticStyle: {
                      "vertical-align": "middle",
                      margin: "0 12px 0 0"
                    },
                    attrs: {
                      value: entity.id,
                      "input-id": "entity-" + key,
                      disabled: _vm.entities.length === 1
                    },
                    model: {
                      value: _vm.branch.entities,
                      callback: function($$v) {
                        _vm.$set(_vm.branch, "entities", $$v)
                      },
                      expression: "branch.entities"
                    }
                  })
                ],
                1
              )
            ])
          }),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.entities.length === 0,
                  expression: "entities.length === 0"
                }
              ]
            },
            [
              _c(
                "v-ons-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        _vm.$router.currentRoute.path + "/entity"
                      )
                    }
                  }
                },
                [_vm._v("Добавить юр. лицо")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Схема проезда")]),
          _vm._v(" "),
          _vm.branch.map_path
            ? _c("div", { staticStyle: { padding: "0 20px" } }, [
                _c("img", {
                  staticStyle: {
                    "max-width": "90%",
                    "box-shadow": "0 1px 6px #e8e8e8"
                  },
                  attrs: { src: "/images/" + _vm.branch.map_path, alt: "" }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c(
                "v-ons-button",
                {
                  attrs: {
                    disabled: _vm.$upload.meta("imageMap").status === "sending"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$upload.select("imageMap")
                    }
                  }
                },
                [
                  _c("v-ons-icon", {
                    attrs: { icon: "md-upload", size: "20px" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.branch.map_path,
                      expression: "branch.map_path"
                    }
                  ],
                  staticStyle: { "margin-left": "10px" },
                  on: {
                    click: function($event) {
                      _vm.branch.map_path = ""
                    }
                  }
                },
                [
                  _c("v-ons-icon", {
                    attrs: { icon: "md-delete", size: "20px" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.branch.map_path,
                      expression: "branch.map_path"
                    }
                  ],
                  staticStyle: { "margin-left": "10px" },
                  on: { click: _vm.downloadMap }
                },
                [
                  _c("v-ons-icon", {
                    attrs: { icon: "md-download", size: "20px" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-57f57f76", { render: render, staticRenderFns: staticRenderFns })
  }
}