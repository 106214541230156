<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center" >
                <div v-show="!isSearchActive" >
                    {{ product.name }}
                </div>

                <div v-show="isSearchActive" class="search-wrap" >
                    <input id="merge-replacements-search-input" v-model="searchQuery" style="
                        color: #fff;
                        background: none;
                        border: none;
                        height: 34px;
                        line-height: 34px;
                        border-bottom: 1px solid #fff;
                    " />
                </div>
            </div>

            <div class="right">
                <v-ons-toolbar-button v-show="!isSearchActive" @click="searchActive" >
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="isSearchActive" @click="searchDeactivate" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content" >
            <div v-if="items.length > 0">
                <v-ons-list>
                    <v-ons-lazy-repeat
                        ref="lazyRepeat"
                        :render-item="renderItem"
                        :length="items.length"
                    >
                    </v-ons-lazy-repeat>
                </v-ons-list>
            </div>

            <div v-show="items.length === 0" class="list-empty" :style="{height: $window.height - 56 + 'px', flexDirection: 'column'}" >
                <div>Ничего не найдено</div>
            </div>

            <div style="height: 100px;" ></div>
        </div>
    </v-ons-page>
</template>

<script>
import {catchError, concatMap, debounceTime, distinctUntilChanged, filter, map, pluck, tap} from "rxjs/operators";
import {from, merge, of} from "rxjs";
import Vue from "vue";
import ProductAvailLinkItem from "~/screen/manager/component/ProductAvailLinkItem";

export default {
    data() {
        return {
            loading: true,
            isSearchActive: false,
            searchQuery: '',
            product: {},
            items: [],
        }
    },
    created() {
        this.loadProduct();
        this.$emit('update');
    },
    subscriptions () {
        const searchQuery$ = this.$watchAsObservable('searchQuery').pipe(
            filter(() => this.searchActive),
            pluck('newValue'),
            map(q => q.trim()),
            debounceTime(1000),
        );

        const searchDeactivate$ = this.$eventToObservable('searchDeactivate').pipe(
            tap(() => {
                this.searchQuery = '';
                this.isSearchActive = false;
            }),
            map(() => this.searchQuery)
        );

        const search$ = merge(searchQuery$, searchDeactivate$).pipe(
            distinctUntilChanged(),
            tap(() => {
                this.loading = true;
                this.items = [];
            })
        );

        const update$ = this.$eventToObservable('update').pipe(
            debounceTime(100),
            tap(() => {
                this.loading = true;
                this.items = [];
            })
        )

        return {
            results: merge(search$, update$).pipe(
                concatMap(() => {
                    return from(this.loadReplacements());
                }),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of([]);
                }),
                tap(items => {
                    this.loading = false;
                    this.items = items;
                }),
            )
        }
    },
    methods: {
        loadProduct() {
            this.$http.get(
                '/api/catalog/view',
                {params: {id: this.$route.params.prd_id}}
            ).then(response => {
                this.product = response.data;
            });
        },
        loadReplacements() {
            return this.$http.get(
                '/api/catalog/merge-replacements',
                {
                    params: {
                        id: this.$route.params.prd_id,
                        storageId: this.$user.selectedStorage,
                        query: this.searchQuery,
                    }
                }
            );
        },
        choose(product) {
            this.$router.push(this.$router.currentRoute.path + '/' + product.id);
        },
        searchActive() {
            this.isSearchActive = true;
            this.searchQuery = '';
            this.$nextTick(() => document.getElementById("merge-replacements-search-input").focus());
        },
        searchDeactivate() {
            this.$emit('searchDeactivate');
        },
        renderItem(i) {
            let self = this;
            let item = this.items[i];

            return new Vue({
                render(h) {
                    return h(ProductAvailLinkItem, {
                        props: {
                            product: item,
                        },
                        on: {
                            choose: self.choose,
                        },
                    });
                }
            });
        },
    },
};
</script>