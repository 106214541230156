var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasChecked,
              expression: "!hasChecked"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [_vm._v("История")]),
          _vm._v(" "),
          _c("div", { staticClass: "right" })
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChecked,
              expression: "hasChecked"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.uncheckAll } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v("Выбрано: " + _vm._s(_vm.checkedCount))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.checkAll } },
                [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.deleteChecked } },
                [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.restore } },
                [_c("v-ons-icon", { attrs: { icon: "md-undo" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.positions.length > 0,
                expression: "positions.length > 0"
              }
            ],
            staticClass: "table-positions"
          },
          [
            _c(
              "div",
              { staticClass: "tbody" },
              _vm._l(_vm.positions, function(position, index) {
                return _c(
                  "div",
                  {
                    key: position.posId,
                    staticClass: "position-wrap",
                    attrs: { id: "position-" + index }
                  },
                  [
                    _c("div", { staticClass: "row-wrap" }, [
                      _c("div", { staticClass: "col col-remove" }, [
                        _c(
                          "div",
                          { staticClass: "remove-wrap" },
                          [
                            _c("v-ons-checkbox", {
                              model: {
                                value: position.isChecked,
                                callback: function($$v) {
                                  _vm.$set(position, "isChecked", $$v)
                                },
                                expression: "position.isChecked"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col col-name",
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "product-list-history-product-view",
                                params: { prd_id: position.id }
                              })
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "name-wrap" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(position.name) +
                                "\n                            "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-unit" }, [
                        _c("div", { staticClass: "unit-wrap" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(position.unit) +
                              "\n                            "
                          )
                        ])
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.positions.length == 0,
                expression: "positions.length == 0"
              }
            ],
            staticClass: "list-empty",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_vm._v("\n            История пуста\n        ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7df3d97f", { render: render, staticRenderFns: staticRenderFns })
  }
}