<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button v-if="showBackButton" ></v-ons-back-button>
                <v-ons-toggle-sidebar-button v-else ></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">График доставки</div>

            <div class="right" v-show="hiddenRoutes.length > 0" >
                <v-ons-toolbar-button @click="showHidden = !showHidden" >
                    <v-ons-icon
                        :title="showHidden ? 'Показать свои маршруты' : 'Показать все маршруты'"
                        :icon="showHidden ? 'md-pin-off' : 'md-pin'"
                    />
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div>
            <v-ons-card v-for="route in routes" style="margin-bottom: 15px;" :key="route.id" >
                <div v-if="route.specialSchedule" >
                    <div class="title" style="font-size: 18px" >
                        {{ route.name }} - график {{ route.specialSchedule.name }}
                    </div>

                    <table class="table" >
                        <thead>
                            <tr>
                                <th v-if="route.name === 'Самовывоз'" style="width: 45%" >Отгрузка</th>
                                <th v-else style="width: 45%" >Доставка</th>

                                <th style="width: 55%" >День заказа</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="day in route.specialSchedule.schedule" v-if="day.hasDelivery" >
                                <td>{{ day.name }}</td>
                                <td>
                                    {{ day.beforeDayName }} до {{ day.beforeTime }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="title" style="font-size: 18px" >
                    {{ route.name }}<span v-show="route.specialSchedule" > - основной график</span>
                </div>

                <table class="table" >
                    <thead>
                        <tr>
                            <th v-if="route.name === 'Самовывоз'" style="width: 45%" >Отгрузка</th>
                            <th v-else style="width: 45%" >Доставка</th>

                            <th style="width: 55%" >День заказа</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="day in route.schedule" v-if="day.hasDelivery" >
                            <td>
                                {{ day.name }}

                                <div v-if="route.name === 'Самовывоз' && day.workTime" >
                                     {{ day.workTime }}
                                </div>
                            </td>
                            <td>
                                {{ day.beforeDayName }}
                                <br v-if="route.name === 'Самовывоз'" >
                                до {{ day.beforeTime }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p style="margin-left: 10px;" v-show="route.name !== 'Самовывоз' && route.free_delivery_from" >
                    Доставка бесплатная при заказе от {{ route.free_delivery_from }} р.
                </p>
            </v-ons-card>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            routeList: [],
            hiddenRoutes: [],
            showHidden: false,
        }
    },
    created() {
        this.$http.get('/api/user/mymanager-routes', {params: {manager: this.$bus.referralManager}}).then(response => {
            this.routeList = response.data.routes;
            this.hiddenRoutes = response.data.hiddenRoutes;
        });
    },
    computed: {
        routes() {
            if (this.showHidden) {
                return this.routeList;
            }

            return this.routeList.filter(r => {
                return !this.hiddenRoutes.includes(r.id);
            });
        },
        showBackButton() {
            return this.$route.matched.length > 1;
        }
    },
};
</script>