<script>

import View from './View'
import Create from './Create'
import LoadingPage from '~/component/LoadingPage'
import ErrorPage from '~/component/ErrorPage'

export default {
    data() {
        return {
            id: null,
            loading: true,
            order: null,
            error: '',
        }
    },
    created() {
        this.id = this.$route.params.order_id;

        this.$http.get('/api/manager/orders/' + this.id).then(response => {
            this.order = response.data.order;
            this.loading = false;
        }, ({data}) => {
            this.order = data.order
            this.error = data.message
            this.loading = false
        });
    },
    render(createElement) {
        if (this.loading || this.order === null) {
            return createElement(LoadingPage);
        }

        if (this.error) {
            return createElement(ErrorPage, {
                props: {
                    errorMessage: this.error
                }
            });
        }

        let params = {
            props: {
                orderProp: this.order,
            }
        }

        if (this.order && this.order.status_id === 'manager_draft') {
            return createElement(Create, params);
        }

        return createElement(View, params);
    },
};
</script>