<template>
    <v-ons-alert-dialog modifier="rowfooter" :visible.sync="visible" >
        <span slot="title">Отправить заказ в обработку?</span>

        <div style="margin-top: 5px;" >
            <v-ons-checkbox :input-id="'chk'" v-model="withNoty" :value="true" style="vertical-align: middle;" ></v-ons-checkbox>
            <label for="chk" style="font-weight: normal; vertical-align: middle; margin: 0; padding-left: 10px;" >
                Отправить уведомление<br>клиенту
            </label>
        </div>

        <template slot="footer">
            <v-ons-alert-dialog-button @click="ok">ОК</v-ons-alert-dialog-button>
            <v-ons-alert-dialog-button @click="cancel">Отмена</v-ons-alert-dialog-button>
        </template>
    </v-ons-alert-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            withNoty: true,

            resolve: null,
        }
    },
    methods: {
        show() {
            this.visible = true;
            this.withNoty = true;

            return new Promise(resolve => {
                this.resolve = resolve;
            });
        },
        ok() {
            this.visible = false;
            this.resolve({withNoty: this.withNoty});
        },
        cancel() {
            this.visible = false;
            this.withNoty = true;
        },
    },
};
</script>
