<template>
    <v-ons-dialog :visible.sync="visible" cancelable class="merge-categories-dialog" >
        <div class="alert-dialog-container alert-dialog-container--material">
            <div class="alert-dialog-title alert-dialog-title--material">
                Объединение в группу:
            </div>

            <div class="alert-dialog-content alert-dialog-content--material">
                <v-ons-list class="category-list" >
                    <ons-list-item v-for="(category, key) in categories" :key="category.id" class="cursor-pointer" @click="copyName(category)" >
                        <div class="center" >
                            {{ category.name }}
                        </div>

                        <div class="right" >
                            <v-ons-icon icon="md-copy" title="Скопировать имя в поле ввода" ></v-ons-icon>
                        </div>
                    </ons-list-item>
                </v-ons-list>

                <div class="input-title">
                    Новое имя раздела
                </div>
                <div class="input-wrap" >
                    <ons-input modifier="material">
                        <input v-model="valueProp" ref="input" type="text" class="text-input text-input--material">
                        <span class="text-input__label text-input--material__label"></span>
                    </ons-input>

                    <button class="btn-clear" @click="clear" >
                        <v-ons-icon icon="md-close"></v-ons-icon>
                    </button>
                </div>
            </div>

            <div class="alert-dialog-footer alert-dialog-footer--rowfooter alert-dialog-footer--material alert-dialog-footer--rowfooter--material">
                <v-ons-alert-dialog-button @click="choose" >OK</v-ons-alert-dialog-button>
                <v-ons-alert-dialog-button @click="close" >Отмена</v-ons-alert-dialog-button>
            </div>
        </div>
    </v-ons-dialog>
</template>

<script>
export default {
    props: [
        'title',
        'value',
    ],
    data() {
        return {
            categories: [],
            valueProp: '',
            visible: false,
            resolve: null,
            reject: null,
        }
    },
    methods: {
        copyName(category) {
            this.valueProp = category.name;
        },
        show(categories) {
            this.categories = categories;
            this.valueProp = '';

            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        clear() {
            this.valueProp = '';
            this.$refs.input.focus();
        },
        close() {
            this.visible = false;
        },
        choose() {
            if (!this.valueProp) {
                return;
            }

            this.visible = false;
            this.resolve(this.valueProp);
        }
    },
};
</script>

