<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" v-show="!isSearchActive" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Emails</div>

            <div class="right" >
                <v-ons-toolbar-button  @click="$emit('update')" title="Обновить" >
                    <v-ons-icon icon="md-refresh"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button  @click="searchActive" >
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar inline="true" v-show="isSearchActive" >
            <div class="left">
                <v-ons-toolbar-button @click="searchDeactive">
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center" >
                <input id="search-input" v-model="searchQuery" style="
                    color: #fff;
                    background: none;
                    border: none;
                    height: 34px;
                    line-height: 34px;
                    border-bottom: 1px solid #fff;
                " />
            </div>

            <div class="right" >
                <v-ons-toolbar-button  @click="$emit('update')" title="Обновить" >
                    <v-ons-icon icon="md-refresh"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <ons-list>
                <ons-list-item v-for="email in emails" :key="email.id" modifier="chevron longdivider" >
                    <div target="_blank" class="center" @click="$router.push('/manager/reports/emails/' + email.id)" >
                        <span class="list-item__title" >
                            {{ email.subject }}
                        </span>
                        <span class="list-item__subtitle" >
                            To: {{ email.to }}
                        </span>
                        <span class="list-item__subtitle" >
                            From: {{ email.from }}
                        </span>
                        <span class="list-item__subtitle" >
                            ReplyTo: {{ email.reply_to }}
                        </span>
                    </div>
                </ons-list-item>
            </ons-list>

            <div style="text-align: center; margin: 10px 0;" v-show="page < pageCount" >
                <v-ons-button @click="loadMore" style="min-width: 145px;" >
                    <span v-show="!loadingMore" >Показать ещё</span>
                    <v-ons-icon v-show="loadingMore" size="30px" spin icon="md-spinner"></v-ons-icon>
                </v-ons-button>
            </div>
        </div>
    </v-ons-page>
</template>

<script>

import {from, merge} from 'rxjs'
import {debounceTime, distinctUntilChanged, filter, map, pluck, switchMap, tap} from 'rxjs/operators'

export default {
    data() {
        return {
            emails: [],
            page: 1,
            pageCount: null,
            loading: false,
            loadingMore: false,
            isSearchActive: false,
            searchQuery: '',
            filters: {
                q: '',
            },
        }
    },
    subscriptions () {
        const $q = this.$watchAsObservable('searchQuery').pipe(
            pluck('newValue'),
            map((q) => q.trim()),
            filter(text => text.length > 1),
            debounceTime(500),
            distinctUntilChanged(),

            tap((q) => {
                this.filters.q = q;
                this.loading = true;
                this.emails = [];
                this.page = 1;
            })
        )

        const $searchDeactive = this.$eventToObservable('searchDeactive').pipe(
            tap(() => {
                this.filters.q = '';
                this.loading = true;
                this.emails = [];
                this.page = 1;
            })
        )

        const $loadMore = this.$watchAsObservable('page').pipe(
            tap(() => this.loadingMore = true),
        )

        const $init = from([1]).pipe(
            tap(() => this.loading = true),
        )

        const $update = this.$eventToObservable('update').pipe(
            tap(() => {
                this.loading = true;
                this.emails = [];
                this.page = 1;
            })
        )

        return {
            results: merge($q, $searchDeactive, $loadMore, $update, $init).pipe(
                switchMap(() => from(this.$http.get('/api/email-loger', {params: {filters: this.filters, page: this.page}}))),
                map(response => {
                    this.emails = this.emails.concat(response.data.items);
                    this.pageCount = response.data.pageCount;
                }),
                tap(() => {
                    this.loadingMore = false;
                    this.loading = false;
                }),
            )
        }
    },
    created() {
    },
    methods: {
        loadMore() {
            if (this.page != null && this.pageCount != null && this.page > this.pageCount) {
                return;
            }

            if (this.loadingMore) {
                return;
            }

            this.page += 1;
        },
        searchActive() {
            this.isSearchActive = true;
            this.searchQuery = '';
            this.$nextTick(() => document.getElementById("search-input").focus());
        },
        searchDeactive() {
            this.isSearchActive = false;
            this.$emit('searchDeactive');
        },
    },
};
</script>