import Index from '~/screen/manager/management/catalogSettings/Index';
import Includes from '~/screen/manager/management/catalogSettings/Includes';
import Categories from '~/screen/manager/management/catalogSettings/Categories';
import ProductRules from '~/screen/manager/management/catalogSettings/ProductRules';
import ProductRuleCreate from '~/screen/manager/management/catalogSettings/ProductRuleCreate';
import ProductRuleDeletedCatalog from '~/screen/manager/management/catalogSettings/ProductRuleDeletedCatalog';
import ProductRuleUpdate from '~/screen/manager/management/catalogSettings/ProductRuleUpdate';
import Pricing from '~/screen/manager/management/catalogSettings/Pricing';
import PriceMode from '~/screen/manager/management/catalogSettings/PriceMode';
import ControlMinPrice from '~/screen/manager/management/catalogSettings/ControlMinPrice';
import RoundingPrice from '~/screen/manager/management/catalogSettings/RoundingPrice';

let route = [
    {
        path: 'catalog-settings',
        name: 'manager-catalog-settings',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'includes',
                name: 'manager-catalog-settings-includes',
                component: Includes,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'categories',
                name: 'manager-catalog-settings-categories',
                component: Categories,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'product-rules',
                name: 'manager-catalog-settings-product-rules',
                component: ProductRules,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'deleted-catalog',
                        name: 'manager-catalog-settings-product-rules-deleted-catalog',
                        component: ProductRuleDeletedCatalog,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                    {
                        path: 'create',
                        name: 'manager-catalog-settings-product-rules-create',
                        component: ProductRuleCreate,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                    {
                        path: ':product_id',
                        name: 'manager-catalog-settings-product-rules-update',
                        component: ProductRuleUpdate,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ],
            },
            {
                path: 'pricing',
                name: 'manager-catalog-settings-pricing',
                component: Pricing,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'mode',
                        name: 'manager-catalog-settings-mode',
                        component: PriceMode,
                        params: true,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                ],
            },
            {
                path: 'control-min-price',
                name: 'manager-catalog-settings-control-min-price',
                component: ControlMinPrice,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'rounding-price',
                name: 'manager-catalog-settings-rounding-price',
                component: RoundingPrice,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
        ]
    }
];

export default route;