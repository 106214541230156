<template>
    <v-ons-page>
        <v-ons-toolbar v-show="!hasChecked && !isSearchActive">
            <div class="left">
                <v-ons-back-button v-if="showBackButton" title="Назад"></v-ons-back-button>
                <v-ons-toggle-sidebar-button v-else></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">
                {{ title }}
            </div>

            <div class="right">
                <v-ons-toolbar-button>
                    <v-ons-icon
                        title="Разделы каталога"
                        icon="md-filter-list"
                        @click="chooseGroupFilter"
                        :style="{color: filters.groups.length > 0 ? '#ffe000' : ''}"
                    ></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    v-if="toolbarButtons.includes('catalogSettingsButton')"
                    @click="showCatalogSettingsDialog"
                >
                    <v-ons-icon icon="md-settings"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    v-if="toolbarButtons.includes('showAssignTemplateModalButton')"
                    title="Выборка по Моим товарам"
                    @click="$refs.assignTemplateModal.show()"
                >
                    <v-ons-icon :icon="getAssignTemplateModalIconName()"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button title="Поиск" @click="searchActive">
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button id="toolbarButtonSettings" @click="popoverToolbarMenuVisible = true" title="Дополнительные функции" >
                    <v-ons-icon icon="md-more-vert"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="hasChecked">
            <div class="left">
                <v-ons-toolbar-button title="Отменить выделение" @click="uncheckAll">
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center">
                Выбрано: {{ checked.length }}
            </div>

            <div class="right">
                <v-ons-toolbar-button
                  title="Изменить"
                  v-if="$user.isManager"
                  v-show="hasCheckedOne"
                  @click="clickGoToUpdate"
                >
                    <v-ons-icon icon="md-edit"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                  title="Копировать данные"
                  v-if="$user.isManager"
                  v-show="hasCheckedTwo"
                  @click="clickCopyData"
                >
                    <v-ons-icon icon="md-link"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="isSearchActive">
            <div class="left">
                <v-ons-toolbar-button @click="searchDeactivate">
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center">
                <div class="search-wrap">
                    <form action="" autocomplete="off" @submit.prevent="() => {}">
                        <input
                            id="search-input"
                            v-model="searchQuery"
                            style="
                            color: #fff;
                            background: none;
                            border: none;
                            height: 34px;
                            line-height: 34px;
                            border-bottom: 1px solid #fff; "
                            autocomplete="off"
                            spellcheck="false"
                        />
                    </form>
                </div>
            </div>

            <div class="right">
                <v-ons-toolbar-button
                    title="Копировать данные"
                    v-if="$user.isManager"
                    v-show="hasCheckedTwo"
                    @click="clickCopyData"
                >
                    <v-ons-icon icon="md-link"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button>
                    <v-ons-icon icon="md-filter-list" @click="chooseGroupFilter"
                                :style="{color: filters.groups.length > 0 ? '#ffe000' : ''}"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-if="toolbarButtons.includes('showAssignTemplateModalButton')"
                                      @click="$refs.assignTemplateModal.show()">
                    <v-ons-icon :icon="getAssignTemplateModalIconName()"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button
                    v-if="$user.isManager && toolbarButtons.includes('showCatalogSettingsDialog')"
                    @click="showCatalogSettingsDialog"
                >
                    <v-ons-icon icon="md-settings"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button id="toolbarButtonSettingsSearching" @click="popoverToolbarMenuVisible = true" title="Дополнительные функции" >
                    <v-ons-icon icon="md-more-vert"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-popover
            cancelable
            :visible.sync="popoverToolbarMenuVisible"
            :target="isSearchActive ? '#toolbarButtonSettingsSearching' : '#toolbarButtonSettings'"
            cover-target="true"
            direction="down"
            class="popover-menu stick-right"
        >
            <ons-list @click="popoverToolbarMenuVisible = false" >
                <toolbar-popover-menu-item
                    text="Разделы каталога"
                    icon="md-filter-list"
                    @click="chooseGroupFilter"
                />

                <toolbar-popover-menu-item
                    v-if="toolbarButtons.includes('downloadCatalogButton')"
                    text="Скачать прайс"
                    icon="md-download"
                    @click="showDownloadCatalogPromptDialog"
                />

                <toolbar-popover-menu-item
                    v-if="toolbarButtons.includes('showAssignTemplateModalButton')"
                    text="Выборка по Моим товарам"
                    :icon="getAssignTemplateModalIconName()"
                    @click="$refs.assignTemplateModal.show()"
                />

                <toolbar-popover-menu-item
                    v-if="isShowShoppingCartFab"
                    :disabled="!hasPositionsToAdd"
                    text="Добавить к заказу"
                    icon="md-shopping-cart"
                    @click="add"
                />

                <toolbar-popover-menu-item
                    v-if="!$user.isManager && catalogCountsKeeper.getSessions().length > 0"
                    text="Восстановление данных"
                    icon="md-time-restore"
                    @click="openCatalogCountsKeeper"
                />

                <v-ons-list-header>Отображать как:</v-ons-list-header>

                <toolbar-popover-menu-item
                    text="Список"
                    icon="md-view-list"
                    :iconColor="catalogViewType === 'list' ? '#000' : ''"
                    @click="changeCatalogViewType('list')"
                />

                <toolbar-popover-menu-item
                    text="Плитка"
                    icon="md-view-module"
                    :iconColor="catalogViewType === 'tile' ? '#000' : ''"
                    @click="changeCatalogViewType('tile')"
                />
            </ons-list>
        </v-ons-popover>

        <catalog-settings-dialog ref="catalogSettingsDialog"/>

        <list-dialog
            ref="catalogCountsKeeperDialog"
            title="Восстановление данных"
            :items="catalogCountsKeeperItems"/>

        <assign-template-modal
            :assignedTemplates="assignedTemplates"
            :allowSelectGroup="true"
            :groupedBy="groupBy"
            :url="templateEntrypoint"
            ref="assignTemplateModal"
            @setGroupBy="setGroupBy"
            @choose="onChooseTemplate"/>

        <group-filter-modal ref="groupFilterModal"/>

        <download-catalog-prompt-dialog
            ref="downloadCatalogPromptDialog"
            :userProductsEntrypoint="userProductsEntrypoint"
        />

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}">
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-if="catalog.length > 0">
                <v-ons-list v-if="isDesktop && catalogViewType === 'list'" id="list-header-catalog" class="list-catalog is-desktop">
                    <v-ons-list-item class="table-header-wrap" :style="{width: listCatalogWidth + 'px'}">
                        <div class="col-checkbox"></div>
                        <div class="col-name">Наименование</div>
                        <div v-show="isDesktop && shouldShowRest" class="col-rest">Остаток
                        </div>
                        <div class="col-price" v-show="$user.canSeePrice">Цена</div>
                        <div class="col-count" v-show="allowInputs && (showInputs || isDesktop)">
                            <span>Заказ</span>
                        </div>
                    </v-ons-list-item>
                </v-ons-list>

                <v-ons-list id="list-catalog" :class="catalogViewType === 'list' ? 'list-catalog' : 'tile-catalog'">
                    <div v-if="catalogViewType === 'list'">
                        <category-item-list
                            v-for="category in catalog"
                            :key="category.id"
                            :item="category"
                            :isDesktop="isDesktop"
                            :actionColType="actionColType"
                            :canOpenProductPage="canOpenProductPage"
                            :showInputs="showInputs"
                            :selectedPriceType="selectedPriceType"
                            :counts="counts"
                            :checked="checked"
                            :shouldShowRest="shouldShowRest"
                            :lastImportDate="lastImportDate"
                            :allowInputs="allowInputs"
                            @clickGoToView="clickGoToView"
                            @toggleFavor="toggleFavor"
                            @countEnterDown="onCountEnterDown"
                            @countShiftEnterDown="onCountShiftEnterDown"
                            @clickCategory="onClickCategory"
                        />
                    </div>
                    <div v-else>
                        <category-item-tile
                            v-for="category in catalog"
                            :key="category.id"
                            :item="category"
                            :isDesktop="isDesktop"
                            :actionColType="actionColType"
                            :canOpenProductPage="canOpenProductPage"
                            :showInputs="showInputs"
                            :selectedPriceType="selectedPriceType"
                            :counts="counts"
                            :checked="checked"
                            :lastImportDate="lastImportDate"
                            :allowInputs="allowInputs"
                            @clickGoToView="clickGoToView"
                            @toggleFavor="toggleFavor"
                            @countEnterDown="onCountEnterDown"
                            @countShiftEnterDown="onCountShiftEnterDown"
                            @clickCategory="onClickCategory"
                        />
                    </div>
                </v-ons-list>
                <div style="width: 100%; height: 80px;"></div>
            </div>

            <div v-show="catalog.length === 0" class="list-empty"
                 :style="{height: $window.height - 56 + 'px', flexDirection: 'column'}">
                <div>Ничего не найдено</div>
                <v-ons-button @click="reset">Сбросить</v-ons-button>
            </div>
        </div>

        <select-add-method-modal ref="selectAddMethodModal"/>

        <v-ons-fab
            title="Добавить к заказу"
            position="bottom right"
            :disabled="!hasPositionsToAdd"
            v-show="isShowShoppingCartFab"
            @click="add"
        >
            <v-ons-icon v-show="!adding" icon="md-shopping-cart"></v-ons-icon>
            <v-ons-icon v-show="adding" size="30px" spin icon="md-spinner"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

import VueScrollTo from 'vue-scrollto';
import {from, merge, of} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, filter, map, pluck, switchMap, tap} from 'rxjs/operators'

import AssignTemplateModal from './AssignTemplateModal'
import GroupFilterModal from './GroupFilterModal'
import DownloadCatalogPromptDialog from './DownloadCatalogPromptDialog'
import SelectAddMethodModal from './SelectAddMethodModal'
import CatalogSettingsDialog from './CatalogSettingsDialog'

import CategoryItemList from './catalog/CategoryItemList'
import CategoryItemTile from './catalog/CategoryItemTile'
import CatalogCountsKeeper from './CatalogCountsKeeper'
import ListDialog from "~/component/ListDialog";

export default {
    props: {
        catalogEntrypoint: {
            type: String,
            default: '/api/catalog',
        },
        orderEntrypoint: {
            type: String,
            default: '/api/orders',
        },
        userProductsEntrypoint: {
            type: String,
            default: '/api/user-products',
        },
        templateEntrypoint: {
            type: String,
            default: '/api/user-products/templates',
        },
        toolbarButtons: {
            type: Array,
            default() {
                return [];
            },
        },
        showGroupFilterModal: {
            type: Boolean,
            default: false,
        },
        showBackButton: {
            type: Boolean,
            default: false
        },
        showInputs: {
            type: Boolean,
            default: false
        },
        allowInputs: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Каталог',
        },
        canOpenProductPage: {
            type: Boolean,
            default: true
        },
        canChooseProduct: {
            type: Boolean,
            default: false
        },
        actionColType: {
            type: String,
            default: null,
        },
        redirectToOrderWhenAddPositions: {
            type: Boolean,
            default: false
        },
        defaultCatalogViewType: {
            type: String,
            default: null,
        },
        forceHideRest: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            catalog: [],
            itemsByRows: [],
            filters: {
                q: '',
                groups: [],
                templates: [],
                showSpecialProducts: false,
            },
            checked: [],
            counts: {},
            loading: true,
            searchQuery: '',
            isSearchActive: false,
            assignedTemplates: [],
            isDesktop: false,
            selectedPriceType: null,
            groupBy: 'byCatalogGroup',
            lastImportDate: null,
            adding: false,
            listCatalogWidth: 0,
            searched: false,
            catalogCountsKeeper: {},
            catalogViewType: 'list',
            popoverToolbarMenuVisible: false,
        }
    },
    created() {
        this.catalogCountsKeeper = new CatalogCountsKeeper();
        this.isDesktop = document.body.clientWidth > 767;

        if (this.defaultCatalogViewType) {
            this.catalogViewType = this.defaultCatalogViewType;
        }

        if (window.localStorage.getItem('catalogViewType')) {
            this.catalogViewType = window.localStorage.getItem('catalogViewType');
        }

        this.$http.get('/api/catalog/last-import-date').then(response => {
            this.lastImportDate = response.data;
        });

        if (this.$route.params.template) {
            let tmp = this.$route.params.template;
            this.filters.templates = [tmp];
            this.assignedTemplates = [tmp];
            this.groupBy = 'byUserGroup';
        }

        this.$emit('update');

        if (this.$user.isManager) {
            if (!this.selectedPriceType) {
                this.$http.get('/api/catalog/get-active-price-type').then(response => {
                    this.selectedPriceType = response.data.id.toString();
                    this.$user.priceType = this.selectedPriceType;
                });
            }

            if (!this.$user.selectedStorage) {
                this.$http.get('/api/catalog/get-active-storage').then(response => {
                    this.$user.selectedStorage = response.data.toString();
                });
            }
        }

        this.$bus.$on('catalog-update-product', this.updateProduct);
        this.$bus.$on('catalog-hide-product', this.hideProduct);
        this.$bus.$on('productsMerged', this.uncheckAll);
        this.$window.$on('resize', this.updateListCatalogWidth);
        this.$on('updated', this.updateListCatalogWidth);
    },
    destroyed() {
        this.$bus.$off('catalog-update-product', this.updateProduct);
        this.$bus.$off('catalog-hide-product', this.hideProduct);
        this.$bus.$off('productsMerged', this.uncheckAll);
        this.$window.$off('resize', this.updateListCatalogWidth);
        this.$off('updated', this.updateListCatalogWidth);

    },
    subscriptions() {
        const searchQuery$ = this.$watchAsObservable('searchQuery').pipe(
            filter(() => this.searchActive),
            pluck('newValue'),
            map(q => q.trim()),
            debounceTime(1000),
            tap((q) => {
                this.filters.q = q;
                this.searched = true;
            })
        )

        const searchDeactivate$ = this.$eventToObservable('searchDeactivate').pipe(
            tap(() => {
                this.searchQuery = '';
                this.filters.q = '';
                this.isSearchActive = false;
            }),
            map(() => this.searchQuery)
        )

        const search$ = merge(searchQuery$, searchDeactivate$).pipe(
            filter(() => this.searched),
            distinctUntilChanged(),
            tap(() => {
                this.loading = true;
                this.catalog = [];
            })
        )

        const update$ = this.$eventToObservable('update').pipe(
            debounceTime(100),
            tap(() => {
                this.loading = true;
                this.catalog = [];
            })
        )

        return {
            results: merge(search$, update$).pipe(
                switchMap(() => from(
                    this.$http.get(
                        this.catalogEntrypoint + '/categories',
                        {
                            params: {
                                filters: this.filters,
                                groupBy: this.groupBy,
                                storageId: this.$user.selectedStorage,
                            }
                        }
                    )
                )),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of([]);
                }),
                tap(categories => {
                    this.loading = false;
                    this.catalog = categories
                    this.$emit('updated');
                }),
            )
        }
    },
    methods: {
        onClickCategory(category) {
            let openCategory = this.catalog.find(c => c.isOpen)

            if (openCategory && openCategory.id === category.id) {
                category.isOpen = false
                return
            }

            let options = {
                container: '.content.page__content',
                easing: 'ease-in',
                lazy: false,
                offset: -28,
                force: true,
                cancelable: true,
                x: false,
                y: true
            }

            this.$nextTick(() => {
                VueScrollTo.scrollTo('#category-' + category.id, 100, options)
            })

            for (let c of this.catalog) {
                c.isOpen = false
            }

            if (category.products.length !== 0) {
                category.isOpen = true
                return
            }

            category.loading = true

            this.$http.get(
                this.catalogEntrypoint + '/get-product-by-category', {
                    params: {
                        filters: this.filters,
                        groupBy: this.groupBy,
                        storageId: this.$user.selectedStorage,
                        categoryId: category.id,
                    }
                }
            ).then(response => {
                category.products = response.data
                category.isOpen = true
                category.loading = false
                VueScrollTo.scrollTo('#category-' + category.id, 100, options)
            })
        },
        onChooseTemplate(templates) {
            this.filters.templates = templates.map(i => {
                return i.id;
            });
            this.assignedTemplates = this.filters.templates;
            this.$emit('update');
        },
        searchActive() {
            this.isSearchActive = true;
            this.$nextTick(() => document.getElementById("search-input").focus());
        },
        searchDeactivate() {
            this.$emit('searchDeactivate');
        },
        add() {
            if (this.adding) {
                return;
            }

            this.adding = true;

            let positions = Object.keys(this.counts)
                .map(key => {
                    return {
                        prd_id: parseInt(key),
                        count: parseFloat(this.counts[key]),
                    }
                })
                .filter(i => i.count > 0);

            if (this.redirectToOrderWhenAddPositions && (this.$route.name === 'order-catalog' || this.$route.name === 'manager-order-catalog')) {
                let orderId = this.$route.params.order_id;
                let promise = null;

                if (orderId === 'create') {
                    promise = this.$http.put(this.orderEntrypoint, {grouped: this.groupBy}).then(response => {
                        this.$route.params.order_id = response.data.id;
                        return Promise.resolve(response.data.id);
                    });
                } else {
                    promise = new Promise((resolve) => {
                        resolve(orderId);
                    });
                }

                promise.then(orderId => {
                    this.$http.put(
                        `${this.orderEntrypoint}/${orderId}/positions`,
                        {positions: positions, grouped: this.groupBy}
                    ).then(() => {
                        this.$bus.$emit('orders-update', orderId);
                        this.clearCounts();
                        this.$router.goRouteBack();
                    }, () => {
                        this.$ons.notification.toast({
                            message: 'Произошла ошибка.',
                            buttonLabel: 'OK'
                        });
                    });
                });
            } else {
                this.adding = false;
                this.$refs.selectAddMethodModal.add(positions, this.groupBy).then(() => {
                    this.clearCounts();
                    this.uncheckAll();
                });
            }
        },
        toggleFavor(position) {
            if (position.isInMyProducts) {
                position.isInMyProducts = false;

                this.$http.post(this.userProductsEntrypoint + '/delete-products', {products: [position.id]}).then(() => {
                }, () => {
                    position.isInMyProducts = true;

                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            } else {
                position.isInMyProducts = true;

                this.$http.post(this.userProductsEntrypoint + '/add-products', {positions: [position.id]}).then(() => {
                }, () => {
                    position.isInMyProducts = false;

                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            }
        },
        clickGoToView(position) {
            if (this.$user.isManager && !this.$user.permissions.canDownloadPriceAndPhoto) {
                this.$ons.notification.alert({title: '', message: 'Просмотр карточки товара доступен по подписке'});
                return
            }

            if (!position.id && this.hasCheckedOne) {
                this.$router.push(this.$router.currentRoute.path + '/' + this.checked[0])
                return;
            }

            if (this.canOpenProductPage) {
                this.$router.push(this.$router.currentRoute.path + '/' + position.id)
                return;
            }

            if (this.canChooseProduct) {
                this.$router.go(-1);
                position.price = this.calcPrice(position);
                this.$bus.$emit('choose-product', position);
            }
        },
        onCountEnterDown(position) {
            // TODO
            return
            let nextIndex = this.items.findIndex(i => i.id === position.id) + 1;
            let nextPosition = null;

            while (true) {
                if (nextIndex >= this.items) {
                    return;
                }

                nextPosition = this.items[nextIndex];

                if (!nextPosition) {
                    return;
                }

                if (nextPosition.type === 'product') {
                    break;
                }

                nextIndex++;
            }

            document.getElementById('position-count-' + nextPosition.id).focus();
        },
        onCountShiftEnterDown(position) {
            return
            let prevIndex = this.items.findIndex(i => i.id === position.id) - 1;
            let prevPosition = null;

            while (true) {
                if (prevIndex < 0) {
                    return;
                }

                prevPosition = this.items[prevIndex];

                if (!prevPosition) {
                    return;
                }

                if (prevPosition.type === 'product') {
                    break;
                }

                prevIndex--;
            }

            document.getElementById('position-count-' + prevPosition.id).focus();
        },
        clickGoToUpdate() {
            if (!this.$user.isManager) {
                return;
            }

            let id = this.checked[0];
            this.$router.push(`/manager/products/${id}/update`);
            this.$bus.$once('catalogUpdated', () => this.checked = []);
        },
        clickCopyData() {
            let firstId = this.checked[0];
            let secondId = this.checked[1];

            this.$router.push(this.$router.currentRoute.path + `/${firstId}/merge/${secondId}`)
        },
        chooseGroupFilter() {
            this.$refs.groupFilterModal.show(this.filters.groups).then(response => {
                this.filters.groups = response;
                this.$emit('update');
            })
        },
        getAssignTemplateModalIconName() {
            if (this.assignedTemplates.length === 0) {
                return 'md-collection-item';
            }

            if (this.assignedTemplates.length > 9) {
                return 'md-collection-item-9-plus';
            }

            return 'md-collection-item-' + this.assignedTemplates.length;
        },
        setGroupBy(groupBy) {
            this.groupBy = groupBy;
            this.$emit('update');
        },
        uncheckAll() {
            this.checked = [];
        },
        updateProduct(id) {
            this.$http.get('/api/catalog/view', {params: {id: id}}).then(response => {
                let product = response.data;
                product.type = 'product';
                let index = this.items.findIndex(i => i.type === 'product' && i.id === id);
                this.$set(this.items, index, product)
            });
        },
        hideProduct(id) {
            let item = this.items.find(i => i.type === 'product' && i.id === id);
            item.isHidden = true;
        },
        showCatalogSettingsDialog() {
            let params = {
                showSpecialProducts: this.filters.showSpecialProducts,
                selectedPriceType: this.$user.priceType,
                selectedStorage: this.$user.selectedStorage,
                showCatalogRest: this.$user.showCatalogRest,
            }

            this.$refs.catalogSettingsDialog.show(params).then(response => {
                let oldSelectedStorage = this.$user.selectedStorage;

                this.selectedPriceType = response.selectedPriceType;
                this.$user.priceType = response.selectedPriceType;
                this.$user.selectedStorage = response.selectedStorage;
                this.$user.showCatalogRest = response.showCatalogRest;

                if (oldSelectedStorage !== this.$user.selectedStorage) {
                    this.filters.groups = [];
                    this.filters.templates = [];
                }

                if (
                    oldSelectedStorage !== this.$user.selectedStorage ||
                    this.filters.showSpecialProducts !== response.showSpecialProducts
                ) {
                    this.filters.showSpecialProducts = response.showSpecialProducts;
                    this.$emit('update');
                }
            })
        },
        reset() {
            this.isSearchActive = false;
            this.filters.q = '';
            this.$emit('update');
        },
        updateListCatalogWidth() {
            this.$nextTick(() => {
                let listCatalog = document.getElementById("list-header-catalog");
                if (!listCatalog || listCatalog.offsetWidth === 0) {
                    return;
                }

                this.listCatalogWidth = listCatalog.offsetWidth;
            });
        },
        clearCounts() {
            this.$set(this, 'counts', {});
        },
        openCatalogCountsKeeper() {
            this.$refs.catalogCountsKeeperDialog.show().then(response => {
                if (!response) {
                    return;
                }

                let session = this.catalogCountsKeeper.getSession(response);
                let groupBy = session.groupBy || 'byCatalogGroup'
                let positions = Object.keys(session.counts)
                    .map(key => {
                        return {
                            prd_id: parseInt(key),
                            count: parseFloat(session.counts[key]),
                        }
                    })
                    .filter(i => i.count > 0);

                this.$refs.selectAddMethodModal.add(positions, groupBy);
            });
        },
        changeCatalogViewType(type) {
            if (this.$user.isManager && !this.$user.permissions.canDownloadPriceAndPhoto && type === 'tile') {
                this.$ons.notification.alert({title: '', message: 'Просмотр каталога с картинками доступен по подписке'});
                return
            }

            this.catalogViewType = type;
            window.localStorage.setItem('catalogViewType', type);

            if (type === 'list') {
                this.updateListCatalogWidth();
            }
        },
        setItemsByRows(items) {
            let row = [];
            this.itemsByRows = [];

            for (let item of items) {
                if (item.type === 'category') {
                    if (row.length > 0) {
                        this.itemsByRows.push(row);
                        row = [];
                    }

                    this.itemsByRows.push([item]);
                    continue;
                }

                row.push(item);

                if (row.length === this.numberColumns) {
                    this.itemsByRows.push(row);
                    row = [];
                }
            }

            if (row.length > 0) {
                this.itemsByRows.push(row);
            }
        },
        showDownloadCatalogPromptDialog() {
            if (this.$user.isManager && !this.$user.permissions.canDownloadPriceAndPhoto) {
                this.$ons.notification.alert({title: '', message: 'Скачивание прайса доступно по подписке'});
                return
            }

            this.$refs.downloadCatalogPromptDialog.show({filters: this.filters, groupBy: this.groupBy})
        },
    },
    computed: {
        hasChecked() {
            return this.checked.length > 0;
        },
        hasCheckedOne() {
            return this.checked.length === 1;
        },
        hasCheckedTwo() {
            return this.checked.length === 2;
        },
        hasPositionsToAdd() {
            if (Object.keys(this.counts).length === 0) {
                return false;
            }

            for (let id in this.counts) {
                if (this.counts[id] > 0) {
                    return true;
                }
            }

            return false;
        },
        isShowShoppingCartFab() {
            if (this.loading) {
                return false;
            }

            return this.allowInputs && (this.showInputs || this.hasPositionsToAdd);
        },
        catalogCountsKeeperItems() {
            return this.catalogCountsKeeper.getSessions().map(i => {
                return {
                    id: i.id,
                    name: this.$moment(i.updated).format("DD.MM HH:mm:ss")
                }
            });
        },
        shouldShowRest() {
            if (this.forceHideRest) {
                return false;
            }

            return this.$user.isManager && this.$user.showCatalogRest
        },
    },
    components: {
        AssignTemplateModal,
        GroupFilterModal,
        DownloadCatalogPromptDialog,
        SelectAddMethodModal,
        CatalogSettingsDialog,
        CategoryItemList,
        CategoryItemTile,
        ListDialog,
    },
    watch: {
        '$route.name'() {
            if (this.$route.name === 'manager-product') {
                this.$emit('turnBack');
            }
        },
        counts: {
            handler() {
                this.catalogCountsKeeper.save(this.counts, this.groupBy);
            },
            deep: true,
        },
    },
};
</script>