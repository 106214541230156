<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar inline="true" >
            <div class="center">{{ isPickup ? 'График самовывоза' : 'График доставки' }} </div>
        </v-ons-toolbar>

        <div style="margin: 18px 0;" v-show="id != 2" >
            <p v-if="!isPickup" style="margin-left: 10px;" >{{ name }}</p>

            <div>
                <table class="table" >
                    <thead>
                        <tr>
                            <th>{{ isPickup ? 'Отгрузка' : 'Доставка' }} </th>
                            <th>День заказа</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="day in schedule" v-if="day.beforeDayName" >
                            <td>
                                {{ day.name }}

                                <div v-if="name === 'Самовывоз' && day.workTime" >
                                    {{ day.workTime }}
                                </div>
                            </td>
                            <td>
                                {{ day.beforeDayName }}
                                <br v-if="name === 'Самовывоз'" >
                                до {{ day.beforeTime }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="!isPickup" style="margin-left: 10px;" >
                    <div v-show="freeDeliveryFrom" >
                        Бесплатная доставка при заказе от {{ freeDeliveryFrom }} <span class="font-rouble" >o</span>
                    </div>

                    <div v-show="deliveryCost" >
                        Стоимость доставки: {{ deliveryCost }} <span class="font-rouble" >o</span>
                    </div>

                    <div v-show="refusalDeliveryTo" >
                        Минимальный заказ: {{ refusalDeliveryTo }} <span class="font-rouble" >o</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="id == 2" style="margin: 25px 10px;" >
            По этому адресу не осуществляется плановых доставок.
        </div>
    </v-ons-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            id: null,
            name: '',
            schedule: [],
            freeDeliveryFrom: null,
            deliveryCost: null,
            refusalDeliveryTo: null,
            isPickup: false,
        }
    },
    created() {
    },
    methods: {
        show(route) {
            this.visible = true;
            this.id = route.id;
            this.name = route.name;
            this.schedule = route.schedule;
            this.freeDeliveryFrom = route.free_delivery_from;
            this.deliveryCost = route.delivery_cost;
            this.refusalDeliveryTo = route.refusal_delivery_to;
            this.isPickup = route.is_pickup;
        },
    },
};
</script>