<template>
    <v-ons-page>
        <v-ons-toolbar inline="true">
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center"></div>
        </v-ons-toolbar>

        <div class="content">
            <div class="page-failed" :style="{height: $window.height - 56 + 'px'}">
                <div class="message">{{ errorMessage }}</div>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    props: [
        'errorMessage',
    ],
};
</script>