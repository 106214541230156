<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center" >{{ title }}</div>

            <div class="right" >
                <v-ons-toolbar-button @click="$refs.assignTemplateModal.show()" title="Добавить из шаблона" >
                    <v-ons-icon icon="md-collection-plus"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item
                        text="Отклонить заказ"
                        icon="md-undo"
                        v-show="'id' in order && !$user.isWorker && order.worker"
                        @click="rejectOrder()" />
                    <toolbar-popover-menu-item
                        text="Удалить заказ"
                        icon="md-delete"
                        v-show="'id' in order"
                        @click="deleteOrder()" />
                    <toolbar-popover-menu-item
                        text="Скачать в PDF"
                        icon="md-download"
                        v-show="'id' in order"
                        @click="downloadPdf()" />
                    <toolbar-popover-menu-item
                        text="Добавить из шаблона"
                        icon="md-collection-plus"
                        @click="$refs.assignTemplateModal.show()" />
                    <toolbar-popover-menu-item
                        text="Добавить из каталога"
                        icon="md-playlist-plus"
                        @click="openCatalog" />
                    <toolbar-popover-menu-item
                        text="Отправить заказ"
                        icon="md-mail-send"
                        @click="checkout" />
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <assign-template-modal
            ref="assignTemplateModal"
            :title="'Добавить из шаблона'"
            @choose="onBindTemplate" />

        <group-positions-modal ref="groupPositionsModal"
            :isGrouped="order && grouped" />

        <v-unavailable-position-modal ref="unavailablePositionModal" />

        <order-comment-dialog ref="orderCommentDialog" />

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div v-show="!loading" class="order-create-page" :style="{'padding-bottom': paddingBottom}" >
            <div v-if="order.worker && !$user.isWorker" >
                <p>Способ отгрузки: {{ order.shippingMethodLabel }}</p>
                <p v-show="order.shippingMethod === 'delivery'" >
                    Адрес доставки:
                    <span v-if="order.branch" >
                        {{ order.branch.shortAddress }}
                    </span>
                    <span v-else >не указан</span>
                    <span v-if="order.entity" >
                        ({{ order.entity.legal_name }})
                    </span>
                </p>
                <p>Дата отгрузки: {{ order.shippingDate }}</p>
                <p>Ответственный: <a :href="'/profile/workers/' + order.worker.id" >{{ order.worker.email }}</a></p>
            </div>

            <div class="table-positions" v-show="order" >
                <div v-if="grouped === 'byUserGroup'" class="tbody">
                    <div v-for="group in positionsByGroups" class="group-wrap" :key="group.id" >
                        <div class="group-name" v-show="group.id != 0 || positionsByGroups.length > 1" >
                            {{ group.name }}
                        </div>

                        <v-create-position
                            v-for="position in group.positions"
                            :class="removed.includes(position.pos_id) ? 'position-card-wrap-hidden' : ''"
                            :key="position.pos_id"
                            :id="'position-' + position.pos_id"
                            :position="position"
                            :inventoryPositions="inventoryPositions"
                            :units="units"
                            :addedPositions="addedPositions"
                            :index="position.index"
                            :lastImportDate="lastImportDate"
                            @positionView="positionView"
                            @update="updatePosition"
                            @updateCount="updatePositionCount"
                            @updateUnit="updatePositionUnit"
                            @remove="onRemovePosition"
                            @showProposals="fabsVisible = false;"
                            @hideProposals="fabsVisible = true;"
                            @countEnterDown="onCountEnterDown"
                            @countShiftEnterDown="onCountShiftEnterDown"
                        />
                    </div>
                </div>
                <div v-else class="tbody">
                    <v-create-position
                        v-for="position in getPositions()"
                        :class="removed.includes(position.pos_id) ? 'position-card-wrap-hidden' : ''"
                        :key="position.pos_id"
                        :id="'position-' + position.pos_id"
                        :position="position"
                        :inventoryPositions="inventoryPositions"
                        :units="units"
                        :index="position.index"
                        :addedPositions="addedPositions"
                        :lastImportDate="lastImportDate"
                        @positionView="positionView"
                        @update="updatePosition"
                        @updateCount="updatePositionCount"
                        @updateUnit="updatePositionUnit"
                        @remove="onRemovePosition"
                        @showProposals="fabsVisible = false;"
                        @hideProposals="fabsVisible = true;"
                        @countEnterDown="onCountEnterDown"
                        @countShiftEnterDown="onCountShiftEnterDown"
                    />
                </div>

                <input-proposal-create
                    ref="inputProposal"
                    :scrollPageOnFocus="true"
                    :disableScrollPage="true"
                    :addedPositions="addedPositions"
                    :columns="$user.canSeePrice ? ['name', 'price'] : ['name']"

                    @choose="chooseProposal"
                    @showProposals="fabsVisible = false;"
                    @hideProposals="fabsVisible = true;"
                    />
            </div>

            <div class="total-order-wrap" v-if="$user.canSeePrice" >
                <div class="wrap total-order">
                    <div class="col col-label">
                        <div>Сумма</div>
                    </div>
                    <div class="col col-total-price">
                        <div class="total-price-wrap">
                            <span v-if="order.isNearlyCost" >~</span>
                            <span>{{ order.totalCost || 0 }}</span>
                            <span class="font-rouble" >o</span>
                        </div>
                    </div>
                </div>

                <div v-if="order && order.hasDiscount" class="wrap total-order">
                    <div class="col col-label">
                        <div>Сумма со скидкой</div>
                    </div>
                    <div class="col col-total-price">
                        <div class="total-price-wrap">
                            <span v-if="order.isNearlyCost" >~</span>
                            <span>{{ order.totalCostWithDiscount || 0 }}</span>
                            <span class="font-rouble" >o</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-ons-fab
            v-show="!loading && fabsVisible"
            position="bottom left"
            title="Перейти в режим каталога"
            @click="openCatalog"
        >
          <v-ons-icon icon="md-playlist-plus"></v-ons-icon>
        </v-ons-fab>

        <v-ons-fab
            v-show="!loading && fabsVisible"
            :disabled="!canCheckout"
            position="bottom right"
            title="Перейти к отправке заказа"
            @click="checkout"
        >
          <v-ons-icon v-show="!orderCheckouting" icon="md-mail-send"></v-ons-icon>
          <v-ons-icon v-show="orderCheckouting " size="30px" spin icon="md-spinner"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

import $ from "jquery"
import numeral from 'numeral'
import GroupPositionsModal from '~/component/order/GroupPositionsModal'
import UnavailablePositionModal from '~/component/order/UnavailablePositionModal'
import OrderCommentDialog from '~/component/order/OrderCommentDialog'
import AssignTemplateModal from '~/component/AssignTemplateModal'
import InputProposalCreate from '~/component/InputProposalCreate'
import CreatePosition from '~/component/order/CreatePosition'

import {from, merge, of} from 'rxjs'
import {catchError, concatMap, map, tap} from 'rxjs/operators'

export default {
    props: [
        'orderProp',
    ],
    subscriptions() {
        const positionUpdate$ = merge(
            this.$eventToObservable('position-update'),
            this.$eventToObservable('position-update-unit'),
        );

        const positionUpdateCount$ = this.$eventToObservable('position-update-count');
        const positionRemove$ = this.$eventToObservable('position-remove');
        const orderUpdate$ = this.$eventToObservable('order-update');

        const positionsAdd$ = merge(
            this.$eventToObservable('positions-add'),
            this.$eventToObservable('position-add'),
        ).pipe(
            map(({ msg }) => msg),
        )

        return {
            orderUpdate: orderUpdate$.pipe(
                tap(() => this.countQueries++),
                concatMap(() => from(this.$http.get(`/api/orders/${this.order.id}`)).pipe(
                    catchError(() => {
                        this.countQueries--;

                        this.$ons.notification.toast({
                            message: 'Произошла ошибка.',
                            buttonLabel: 'OK'
                        });

                        return of({});
                    }),
                )),
                tap(response => {
                    if (!response.data) {
                        return;
                    }

                    this.countQueries--;
                    let order = response.data;
                    this.order = order
                    this.positions = order.positions;
                    this.grouped = order.grouped;
                }),
            ),
            positionsAdd: positionsAdd$.pipe(
                tap(() => this.countQueries++),
                concatMap(args => {
                    if (this.order.id) {
                        return of({
                            orderId: this.order.id,
                            positions: args.positions,
                        })
                    }

                    return from(this.$http.put('/api/orders', {grouped: args.groupBy})).pipe(
                        map(response => {
                            this.order = response.data;
                            this.$router.replace('/order/' + response.data.id)

                            return {
                                orderId: response.data.id,
                                positions: args.positions,
                            };
                        })
                    );
                }),
                concatMap(msg => {
                    let from$ = from(
                        this.$http.put(
                            `/api/orders/${this.order.id}/positions`,
                            {positions: msg.positions, grouped: msg.groupBy}
                        )
                    );

                    return from$.pipe(
                        map(response => {
                            return {
                                order: response.data,
                                positionsAddedCount: msg.positions.length,
                            }
                        }),
                        catchError(response => {
                            let message = 'Произошла ошибка.';

                            if (response.data.message === 'already_sended') {
                                message = 'Заказ уже отправлен';

                                this.countQueries--;
                                this.sended = true;
                                this.$router.push({
                                    name: 'order-list',
                                });
                            }

                            this.$ons.notification.toast({
                                timeout: 3000,
                                message: message,
                                buttonLabel: 'OK'
                            });

                            return of({});
                        }),
                    )
                }),
                tap(data => {
                    if (!data.order) {
                        return;
                    }

                    this.countQueries--;
                    this.order = data.order
                    this.positions = data.order.positions;

                    if (data.positionsAddedCount == 1) {
                        this.$emit('position-added', this.positions[this.positions.length - 1]);
                    } else {
                        this.$emit('positions-added');
                    }
                }),
                catchError(() => {
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                }),
            ),
            positionUpdateCount: positionUpdateCount$.pipe(
                map(({ msg }) => msg),
                tap(() => this.countQueries++),
                concatMap(msg => from(this.$http.post(`/api/orders/${this.order.id}/positions/${msg.posId}`, {position: msg})).pipe(
                    catchError(response => {
                        let message = 'Произошла ошибка.';

                        this.countQueries--;

                        if (response.data.message === 'already_sended') {
                            message = 'Заказ уже отправлен';

                            this.sended = true;
                            this.$router.push({
                                name: 'order-list',
                            });
                        }

                        this.$ons.notification.toast({
                            timeout: 3000,
                            message: message,
                            buttonLabel: 'OK'
                        });

                        return of({});
                    }),
                )),
                tap(response => {
                    if (!response.data) {
                        return;
                    }

                    this.countQueries--;
                    this.order = response.data
                }),
            ),
            positionUpdate: positionUpdate$.pipe(
                map(({ msg }) => msg),
                tap(msg => {
                    console.log(msg)
                }),
                tap(() => this.countQueries++),
                concatMap(msg => from(this.$http.post(`/api/orders/${this.order.id}/positions/${msg.posId}`, {position: msg.position})).pipe(
                    catchError(response => {
                        let message = 'Произошла ошибка.';

                        this.countQueries--;

                        if (response.data.message === 'already_sended') {
                            message = 'Заказ уже отправлен';

                            this.sended = true;
                            this.$router.push({
                                name: 'order-list',
                            });
                        }

                        this.$ons.notification.toast({
                            timeout: 3000,
                            message: message,
                            buttonLabel: 'OK'
                        });

                        return of({});
                    }),
                )),
                tap(response => {
                    if (!response.data) {
                        return;
                    }

                    this.countQueries--;
                    this.order = response.data
                    this.positions = this.order.positions;
                }),
            ),
            positionRemove: positionRemove$.pipe(
                map(({ msg }) => msg),
                tap(() => this.countQueries++),
                concatMap(posId => from(this.$http.delete(`/api/orders/${this.order.id}/positions/${posId}`)).pipe(
                    catchError(response => {
                        let message = 'Произошла ошибка.';

                        this.countQueries--;

                        if (response.data.message === 'already_sended') {
                            message = 'Заказ уже отправлен';

                            this.sended = true;
                            this.$router.push({
                                name: 'order-list',
                            });
                        }

                        this.$ons.notification.toast({
                            timeout: 3000,
                            message: message,
                            buttonLabel: 'OK'
                        });

                        return of({});
                    }),
                )),
                tap(response => {
                    if (!response.data) {
                        return;
                    }

                    this.countQueries--;
                    this.order = response.data.order
                }),
            ),
        }
    },
    data() {
        return {
            countQueries: 0,
            order: {},
            grouped: null,
            positions: [],
            counts: {},
            units: [],
            removed: [],

            fabsVisible: true,
            timer: null,
            paddingBottom: 0,
            inventoryPositions: [],
            groups: {},
            orderCheckouting: false,
            lastImportDate: null,
            myproducts: [],
            sended: false,
            loading: true,
        }
    },
    created() {
        this.order = this.orderProp;
        this.uuid = this.orderProp.uuid;

        if (!this.uuid && !this.id) {
            this.uuid = this.uuidv4();
        }

        let requests = [
            this.$http.get('/api/catalog/last-import-date'),
            this.$http.get('/api/product/get-units'),
            this.$http.get('/api/order/get-inventory-positions'),
            this.$http.get('/api/order/get-groups', {params: {orderId: this.order.id}}),
        ];

        Promise.all(requests).then(responses => {
            this.lastImportDate = responses[0].data;
            this.units = responses[1].data;
            this.inventoryPositions = responses[2].data;
            this.groups = responses[3].data;

            if (responses[4]) {
                this.myproducts = responses[4].data.positions;
            }

            if (Object.keys(this.order).length !== 0) {
                this.positions = this.order.positions;
                this.grouped = this.order.grouped;
            }

            if ($('.total-order-wrap').length > 0) {
                this.paddingBottom = ($(window).height() - 110) +'px';
            } else {
                this.paddingBottom = ($(window).height() - 40) +'px';
            }

            this.$bus.$on('order-sended', this.onOrderSended);

            this.loading = false;

            this.$nextTick(() => {
                if (this.$route.name !== 'order') {
                    return;
                }

                if (!this.order || !this.order.positions || !this.order.positions.length) {
                    $('.input-proposal').focus();
                }
            });
        }, () => {
            this.loading = false;

            this.$ons.notification.toast({
                message: 'Произошла ошибка.',
                buttonLabel: 'OK'
            });
        });

        this.$on('position-added', this.onPositionAdded);
        this.$on('positions-added', this.onPositionsAdded);
        this.$bus.$on('orders-update', this.onOrdersUpdate);
    },
    destroyed() {
        this.$bus.$off('order-sended', this.onOrderSended);
        this.$bus.$off('orders-update', this.onOrdersUpdate);
        this.$off('position-added', this.onPositionAdded);
        this.$off('positions-added', this.onPositionsAdded);

        if (this.sended) {
            this.$bus.$emit('orders-update');
            return;
        }

        this.$bus.$emit('order-update', this.order.id);
    },
    methods: {
        onBindTemplate(templates) {
            let positions = [];
            let groupBy = 'byUserGroup';
            this.loading = true;

            templates.forEach(template => {
                template.positions.forEach(item => {
                    let existsPosition = this.positions.find(i => {
                        return (item.prd_id && item.prd_id == i.prd_id) || (item.name === i.name);
                    });

                    if (existsPosition) {
                        return;
                    }

                    positions.push({
                        prd_id: item.product,
                        name: item.name,
                        unit: item.unit,
                    });
                });
            });

            if (templates.length > 1 || templates.find(t => t.type === 'template')) {
                groupBy = 'byCustom';
            }

            this.grouped = 'byUserGroup';

            this.$emit('positions-add', {positions: positions, groupBy: groupBy});
        },
        rejectOrder() {
            this.$ons.notification.confirm('Отклонить заказ?', {title: 'Подтверждение', buttonLabels: ["Отмена", "OK"]}).then((response) => {
                if (response !== 1) {
                    return;
                }

                this.$http.post('/api/order/reject', {id: this.order.id}).then(() => {
                    this.$bus.$emit('order-remove', {id: this.order.id});
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: `Заказ №${this.order.id} отклонен`,
                    });
                    this.$router.push('/order');
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                })
            });
        },
        deleteOrder() {
            this.$ons.notification.confirm('Удалить заказ?', {title: 'Подтверждение', buttonLabels: ["Отмена", "OK"]}).then((response) => {
                if (response !== 1) {
                    return;
                }

                this.$http.post('/api/order/delete', {orders: [this.order.id]}).then(() => {
                    this.order.status = 'deleted';
                    this.$bus.$emit('order-remove', this.order.id);
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: `Заказ №${this.order.id} удален`,
                    });
                    this.$router.push('/order');
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                })
            });
        },
        checkout() {
            if (!this.canCheckout || this.orderCheckouting) {
                return;
            }

            this.orderCheckouting = true;

            Promise.resolve()
                .then(() => {
                    if (this.countQueries <= 0) {
                        return Promise.resolve();
                    }

                    let resolve = null;
                    let localPromise = new Promise(r => {
                        resolve = r;
                    });

                    this.$once('queriesCompleted', () => {
                        resolve();
                    });

                    return localPromise;
                })
                .then(() => {
                    if (this.$user.isWorker && !this.$user.canSeePrice) {
                        return Promise.resolve('skip');
                    }

                    return Promise.resolve();
                })
                .then(response => {
                    if (response === 'skip') {
                        return Promise.resolve();
                    }

                    let resolve = null;
                    let reject = null;

                    let localPromise = new Promise((res, rej) => {
                        resolve = res;
                        reject = rej;
                    });

                    this.$http.post(`/api/orders/${this.order.id}/check-rest`).then(response => {
                        let positions = response.data.positions;

                        if (positions.length === 0) {
                            resolve();
                            return;
                        }

                        let params = {
                            positions: positions,
                            lastImportDate: response.data.lastImportDate,
                        }

                        this.orderCheckouting = false;
                        this.$refs.unavailablePositionModal.show(params).then(response => {
                            this.orderCheckouting = true;
                            if (response.command === 'resume') {
                                resolve();
                            }

                            if (response.command === 'scrollTo') {
                                let elId = 'position-' + response.pos_id;
                                let el = document.getElementById(elId);
                                this.scrollPage(el);
                                reject();
                            }

                            reject();
                        }, () => {
                            reject();
                        });
                    }, () => {
                        resolve();
                    })

                    return localPromise;
                })
                .then(() => {
                    this.orderCheckouting = false;
                    this.$router.push({name: 'order-checkout', params: {order: this.order}});
                })
                .catch(() =>    {
                    this.orderCheckouting = false;
                })
        },
        openCatalog() {
            this.$router.push({name: 'order-catalog'});
        },
        chooseProposal(proposal) {
            this.fabsVisible = true;
            this.$refs.inputProposal.clear();
            this.$refs.inputProposal.setFocus();

            if (proposal.id) {
                let addedPosition = this.positions
                    .filter(i => !this.removed.includes(i.pos_id))
                    .find(p => proposal.id == p.prd_id);

                if (addedPosition) {
                    this.$nextTick(() => {
                        let elId = 'position-' + addedPosition.pos_id;
                        let el = document.getElementById(elId);
                        this.scrollPage(el);
                        $(el).find('.col-count .input-count').focus();
                    });

                    return;
                }
            }

            let position = {
                prd_id: proposal.id,
                name: proposal.name,
            }

            let args = {
                positions: [position],
                groupBy: this.grouped,
            }

            this.$emit('position-add', args);
        },
        getPositions() {
            let index = 1;
            let positions = this.positions;

            positions.forEach(p => {
                p.index = index++;
            });

            return positions;
        },
        getPositionsByGroup(group) {
            if (this.grouped === 'byCatalogGroup') {
                return this.positions.filter(i => i.category_id == group.id);
            } else if (this.grouped === 'byUserGroup') {
                return this.positions.filter(i => i.user_group == group.id);
            }
        },
        orderByWaiting() {
            this.positions.sort((a, b) => {
                if (a.is_wait == b.is_wait) {
                    return 0;
                }

                if (!a.is_wait && b.is_wait) {
                    return -1;
                } else {
                    return 1;
                }
            });

            this.positions.forEach((i, index) => {
                i.pos_id = index + 1;
            });

            this.counter = this.positions.length + 1;

            this.save();

            for (var i = 0; i < this.positions.length; i++) {
                if (this.positions[i].is_wait) {
                    $('#position-' + i).find('.input-proposal').focus();
                    break;
                }
            }
        },
        numeral(s) {
            return numeral(s);
        },
        downloadPdf() {
            return this.$http.post(
                '/api/order/download-order-pdf',
                {id: this.order.id},
                {responseType: 'arraybuffer'}
            ).then(response => {
                let filename = 'Заказ №' + this.order.id;

                let blob = new Blob([response.data], { type: response.headers.get('content-type') });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        onOrderSended() {
            this.sended = true;
        },
        scrollPage(target, speed = 300) {
            let $target = $(target);
            let offset = $target.position().top;
            $target = $target.offsetParent();

            while (!$target.hasClass('page__content')) {
                offset += $target.position().top;
                $target = $target.offsetParent();
            }

            offset += $target.scrollTop();

            offset -= 15;

            $('.page__content').animate({
                scrollTop: offset
            }, speed);
        },
        positionView(position) {
            this.$router.push({name: 'order-position-view', params: {prd_id: position.prd_id}});
        },
        updatePosition(posId, proposal) {
            let addedPosition = this.positions.find(p => proposal.id && proposal.id === p.prd_id);

            if (addedPosition) {
                return;
            }

            this.$emit('position-update', {posId: posId, position: {prd_id: proposal.id}});
        },
        updatePositionCount(posId) {
            let index = this.positions.findIndex(p => p.pos_id === posId);
            let position = this.positions[index];

            if (!position) {
                return
            }

            this.$emit('position-update-count', {posId: posId, count: position.count});
        },
        updatePositionUnit(posId) {
            let index = this.positions.findIndex(p => p.pos_id === posId);
            let position = this.positions[index];

            this.$emit('position-update-unit', {posId: posId, position: {unit: position.unit}});
        },
        onRemovePosition(posId) {
            this.removed.push(posId);
            this.$emit('position-remove', posId);
        },
        onCountEnterDown(position) {
            let nextPosition = null;

            if (this.grouped) {
                nextPosition = this.getNextGroupedPosition(position);
            } else {
                let nextIndex = this.positions.indexOf(position) + 1;

                if (nextIndex !== this.positions.length) {
                    nextPosition = this.positions[nextIndex];
                }
            }

            if (!nextPosition) {
                $('.input-proposal').focus();
                return;
            }

            let $pos = $(`#position-${nextPosition.pos_id}`);
            let offsetTop = $pos.offset().top;
            let height = $pos.outerHeight();
            let offsetBottom = offsetTop + height;
            let windowHeight = $(window).height();
            let lowerLine = windowHeight - height * 2;

            let offset = $pos.position().top;
            let $target = $pos.offsetParent();

            while (!$target.hasClass('page__content')) {
                offset += $target.position().top;
                $target = $target.offsetParent();
            }

            offset += $target.scrollTop();

            if (offsetBottom > lowerLine) {
                $('.page__content').animate({
                    scrollTop: offset - windowHeight + (height * 3),
                }, 200);
            }

            $pos.find('.input-count').focus();
        },
        onCountShiftEnterDown(position) {
            let prevPosition = null;

            if (this.grouped) {
                prevPosition = this.getPrevGroupedPosition(position);
            } else {
                let prevIndex = this.positions.indexOf(position) - 1;

                if (prevIndex >= 0) {
                    prevPosition = this.positions[prevIndex];
                } else {
                    prevPosition = this.positions[this.positions.length - 1];
                }
            }

            if (!prevPosition) {
                return;
            }

            let $pos = $(`#position-${prevPosition.pos_id}`);
            let offsetTop = $pos.offset().top;
            let height = $pos.outerHeight();
            let topLine = height + 30;

            let offset = $pos.position().top;
            let $target = $pos.offsetParent();

            while (!$target.hasClass('page__content')) {
                offset += $target.position().top;
                $target = $target.offsetParent();
            }

            offset += $target.scrollTop();

            if (offsetTop < topLine) {
                $('.page__content').animate({
                    scrollTop: offset - height,
                }, 200);
            }

            $pos.find('.input-count').focus();
        },
        getNextGroupedPosition(position) {
            let found = false;

            for (let group of this.positionsByGroups) {
                for (let pos of group.positions) {
                    if (found) {
                        return pos;
                    }

                    if (pos === position) {
                        found = true;
                    }
                }
            }
        },
        getPrevGroupedPosition(position) {
            let found = false;
            let lastPosition = null;

            for (let group of this.positionsByGroups.reverse()) {
                for (let pos of group.positions.reverse()) {
                    if (lastPosition === null) {
                        lastPosition = pos;
                    }

                    if (found) {
                        return pos;
                    }

                    if (pos === position) {
                        found = true;
                    }
                }
            }

            return lastPosition;
        },
        onPositionsAdded() {
            this.loading = false;
        },
        onPositionAdded(position) {
            this.loading = false;
            this.$nextTick(() => {
                let el = document.getElementById('position-' + position.pos_id);
                this.scrollPage(el, 150);
                $(el).find('.col-count .input-count').focus();
            });
        },
        onOrdersUpdate(id) {
            if (this.order.id != id) {
                return;
            }

            this.$emit('order-update');
        },
    },
    computed: {
        canCheckout() {
            if (!this.order) {
                return false;
            }

            return this.positions.filter((i => i.name && i.count > 0)).length !== 0;
        },
        title() {
            if (this.order && this.order.id) {
                return 'Заказ №'+ this.order.id +' от ' + this.order.createdAtFormat;
            }

            return 'Создание новой заявки';
        },
        addedPositions() {
            return this.positions
                .filter(i => i.prd_id)
                .filter(i => !this.removed.includes(i.pos_id))
                .map(i => {
                    return {
                        id: i.prd_id,
                        index: i.index,
                    };
                });
        },
        positionsByGroups() {
            let groups = [];

            if (!this.groups || !this.order) {
                return [];
            }

            if (this.grouped === 'byCatalogGroup') {
                if (!this.groups.categories) {
                    return [];
                }

                let ids = this.positions
                    .filter(p => !this.removed.includes(p.pos_id))
                    .map(i => i.category_id);

                groups = this.groups.categories.filter(i => ids.includes(i.id));
            } else if (this.grouped === 'byUserGroup') {
                if (!this.groups.user_groups) {
                    return [];
                }

                let ids = this.positions
                    .filter(p => !this.removed.includes(p.pos_id))
                    .map(i => i.user_group ? i.user_group : 0);

                groups = this.groups.user_groups.filter(i => ids.indexOf(i.id) !== -1);
            }

            let index = 1;

            groups.forEach(g => {
                let positions = this.getPositionsByGroup(g);

                positions.forEach(p => {
                    p.index = index++;
                });

                g.positions = positions;
            })

            return groups;
        },
    },
    watch: {
        countQueries() {
            if (this.countQueries === 0) {
                this.$emit('queriesCompleted');
            }
        },
        '$route.params.order_id'() {
            this.order.id = this.$route.params.order_id;
            this.$emit('order-update');
        },
    },
    components: {
        InputProposalCreate,
        AssignTemplateModal,
        GroupPositionsModal,
        OrderCommentDialog,
        'v-unavailable-position-modal': UnavailablePositionModal,
        'v-create-position': CreatePosition,
    },
};
</script>