<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Создать аккаунт</div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="register-page" >
            <v-ons-card class="info-block" >
                Для завершения регистрации придумайте и введите пароль
            </v-ons-card>

            <div class="form" >
                <div class="form-group" >
                    <v-ons-input v-model="password" name="password" type="password" placeholder="Пароль"  />
                </div>
                <div class="form-submit" >
                    <v-ons-button @click="submit" >Подтвердить</v-ons-button>
                </div>
            </div>
        </div>

        <v-ons-dialog :visible.sync="registration" >
            <v-ons-row vertical-align="center" >
                <v-ons-col width="70px" style="text-align: center"  >
                    <v-ons-progress-circular indeterminate></v-ons-progress-circular>
                </v-ons-col>

                <v-ons-col>
                    <div style="line-height: 100px" >Авторизация...</div>
                </v-ons-col>
            </v-ons-row>
        </v-ons-dialog>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            registration: false,
            password: null,
            loading: true,
            inviteToken: '',
        }
    },
    created() {
        this.loading = false;
        this.inviteToken = this.$route.query['invite-token'];

        if (!this.inviteToken) {
            this.$router.push('/');
        }
    },
    methods: {
        submit() {
            if (this.registration) {
                return;
            }

            if (!this.password) {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Укажите пароль',
                });
                return;
            }

            this.registration = true;

            let data = {
                password: this.password,
                token: this.inviteToken,
            }

            this.$http.post('/api/auth/sign-up-by-invite', data).then(response => {
                this.registration = false;
                this.$user.setToken(response.data.token);

                this.$bus.$emit('authorized');
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/');
                }
            }, response => {
                this.registration = false;

                this.$ons.notification.toast({
                    message: response.data.message,
                    buttonLabels: 'Ок',
                });
            });
        }
    }
};
</script>
