import Contacts from '~/screen/manager/users/Contacts'

let route = [
    {
        path: 'contacts',
        name: 'manager-user-contacts',
        component: Contacts,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
    },
]

export default route;
