<template>
    <catalog :catalogTitle="catalogTitle" />
</template>

<script>

import CatalogPage from '~/component/CatalogPage'
import CatalogGuestPage from '~/component/catalogGuest/CatalogGuestPage'

export default {
    data() {
        return {
            storages: [],
        }
    },
    async created() {
        if (this.$user.isManager) {
            await this.$http.get('/api/manager/storages').then(response => {
                this.storages = response.data;
            });
        }
    },
    computed: {
        catalogTitle() {
            let storage = null;
            let storageId = parseInt(this.$user.selectedStorage);

            if (this.storages.length < 2) {
                return 'Каталог';
            }

            if (storageId) {
                storage = this.storages.find(s => s.id === storageId);
            }

            if (!storage) {
                storage = this.storages.find(s => s.is_default);
            }

            if (!storage) {
                return 'Каталог';
            }

            let selectedStorageName = storage.name || storage.real_name;

            if (!selectedStorageName) {
                return 'Каталог';

            }

            return 'Каталог: ' + selectedStorageName;
        }
    },
    components: {
        CatalogPage,
        'catalog': {
            render(h) {
                if (this.$user.isGuest) {
                    return h(CatalogGuestPage)
                }

                if (this.$user.isManager) {
                    return h(CatalogPage, {
                        props: {
                            title: this.catalogTitle,
                            showInputs: false,
                            toolbarButtons: [
                                'catalogSettingsButton',
                                'downloadCatalogButton',
                                'showCatalogSettingsDialog',
                            ],
                            showAssignTemplateModal: false,
                            showGroupFilterModal: true,
                            actionColType: 'checkbox',
                        }
                    });
                }

                return h(CatalogPage, {
                    props: {
                        toolbarButtons: [
                            'showAssignTemplateModalButton',
                            'downloadCatalogButton',
                        ],
                        allowInputs: true,
                        actionColType: 'favor',
                    }
                });
            },
            props: [
                'catalogTitle',
            ],
        }
    },
};
</script>