<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Оформление жёлтых ценников</div>

            <div class="right" v-show='loaded' >
                <v-ons-toolbar-button @click="save" title="Сохранить" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="!loaded" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-item v-for="modeOption in modeOptions" :key="modeOption.id" modifier="longdivider" >
                    <div class="left" style="min-width: 36px; text-align: center;" >
                        <v-ons-radio
                          :input-id="'modeOption-'+modeOption.id"
                          :value="modeOption.id"
                          v-model="settings.mode"
                        >
                        </v-ons-radio>
                    </div>
                    <label class="center" style="font-weight: normal; margin: 0;" :for="'modeOption-'+modeOption.id" >{{ modeOption.name }}</label>
                </v-ons-list-item>

                <v-ons-list-item style="padding-left: 55px" v-show="settings.mode == 'lowPrice'" >
                    <div class="left" >
                        <v-ons-checkbox
                            input-id="crossOutPriceLabel"
                            v-model="settings.cross_out_price"
                        ></v-ons-checkbox>
                    </div>
                    <label for="crossOutPriceLabel" class="center label-reset" >Отображать зачеркнутой прошлую цену</label>
                </v-ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loaded: false,
            changed: false,

            userId: null,
            settings: {
                mode: null,
                cross_out_price: false,
            },
            modeOptions: [
                {
                    id: 'disabled',
                    name: 'Не использовать оформление жёлтых ценников',
                },
                {
                    id: 'all',
                    name: 'Отмечать жёлтым ценником все спец. цены',
                },
                {
                    id: 'lowPrice',
                    name: 'Отмечать жёлтым, только спец. цены, которые ниже прайса',
                },
            ],
        }
    },
    created() {
        this.userId = this.$route.params.user_id;
        this.load();
    },
    methods: {
        load() {
            this.$http.get(`/api/manager/users/${this.userId}/special-prices-settings`).then(response => {
                this.settings = response.data;

                this.$nextTick(() => {
                    this.loaded = true;
                });
            }, () => {
                this.loaded = true;

                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        save() {
            this.$http.post(`/api/manager/users/${this.userId}/special-prices-settings`, {settings: this.settings}).then(response => {
                this.$router.goRouteBack();
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.goRouteBack();
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.goRouteBack();
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
    },
    watch: {
        settings: {
            handler() {
                if (!this.loaded) {
                    return;
                }

                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>
