<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar inline="true" >
            <div class="center">Отправить приглашение</div>
            <div class="right" >
                <v-ons-toolbar-button @click="close" title="Закрыть" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="alert-dialog-content alert-dialog-content--material">
            <div v-if="storages.length > 1">
                Склад:
                <select style="display: block; width: 100%; margin-bottom: 20px;" class="select-input select-input--material" v-model="storage" >
                    <option v-for="storage in storages" :key="storage.id" :value="storage.id" >
                        {{ storage.name }}
                    </option>
                </select>
            </div>

            <div>
                Email:
                <v-ons-input v-model="email" type="text" />

                <div class="input-error" style="min-height: 20px;" >
                    {{ error }}
                </div>
            </div>
        </div>

        <div class="alert-dialog-footer alert-dialog-footer--rowfooter alert-dialog-footer--material alert-dialog-footer--rowfooter--material">
            <v-ons-button class="alert-dialog-button--material alert-dialog-button" @click="send()" :disabled="sending" modifier="quiet" >
                {{ resend ? 'Отправить повторно' : 'Отправить' }}
            </v-ons-button>
        </div>

    </v-ons-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            storages: [],
            storage: null,

            error: '',
            email: '',
            resend: false,
            sending: false,
        }
    },
    methods: {
        show(storages) {
            this.visible = true;
            this.email = '';
            this.error = '';
            this.resend = false;
            this.sending = false;
            this.storages = storages;

            if (this.storages.length > 1) {
                let storage = this.storages.find(s => s.is_default)
                this.storage = storage ? storage.id : this.storages[0].id;
            }
        },
        close() {
            this.visible = false;
        },
        send() {
            if (!this.email) {
                this.error = 'Укажите email';
                return;
            }

            this.error = '';
            this.sending = true;

            this.$http.post(
                '/api/manager/users/send-invitation',
                {email: this.email, force: this.resend, storage: this.storage}
            ).then(() => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Приглашение отправлено.',
                    buttonLabel: 'OK'
                });

                this.sending = false;
                this.resend = false;
                this.visible = false;

            }, response => {
                this.sending = false;
                this.resend = false;

                if (response.status === 400) {
                    if (response.data.message === 'already_sended') {
                        this.resend = true;
                        this.error = 'Приглашение уже отправлено';
                        return;
                    } if (response.data.message === 'already_registered') {
                        this.resend = true;
                        this.error = 'Клиент уже зарегистрирован.';
                    } else {
                        this.error = response.data.message;
                    }
                } else {
                    this.error = 'Произошла ошибка.';
                }
            });
        },
    },
    watch: {
        email() {
            this.error = '';
            this.resend = false;
        }
    },
};
</script>