<template>
    <users-list
        title="Клиенты"
        url="/api/manager/users"
        shouldShowCreateUserButton="true"
        shouldShowViewParams="true"
        @choose="choose"
    />
</template>

<script>

import UsersList from '../component/user/UsersList'

export default {
    methods: {
        choose(user) {
            this.$router.push({name: 'manager-user', params: {user_id: user.id}})
        }
    },
    components: {
        UsersList,
    }
};
</script>