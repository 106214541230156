var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["position-card-wrap", _vm.isDeleted ? "position-deleted" : ""] },
    [
      _c("v-ons-card", { staticClass: "position-card" }, [
        _c("div", { staticClass: "position-wrap" }, [
          !_vm.isEditing
            ? _c(
                "div",
                {
                  staticClass: "popover-menu",
                  attrs: { id: _vm.popoverId },
                  on: {
                    click: function($event) {
                      _vm.popoverMenuVisible = true
                    }
                  }
                },
                [
                  _c("v-ons-icon", { attrs: { icon: "md-more-vert" } }),
                  _vm._v(" "),
                  _c(
                    "v-ons-popover",
                    {
                      staticClass: "order-popover-menu",
                      attrs: {
                        cancelable: "",
                        visible: _vm.popoverMenuVisible,
                        target: "#" + _vm.popoverId,
                        "cover-target": "true",
                        direction: "down"
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.popoverMenuVisible = $event
                        }
                      }
                    },
                    [
                      _c(
                        "ons-list",
                        {
                          on: {
                            click: function($event) {
                              _vm.popoverMenuVisible = false
                            }
                          }
                        },
                        [
                          _c(
                            "ons-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isEditing && false,
                                  expression: "!isEditing && false"
                                }
                              ],
                              on: { click: _vm.update }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "left icon" },
                                [
                                  _c("v-ons-icon", {
                                    attrs: { icon: "md-edit" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "center" }, [
                                _vm._v("Изменить товар")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "ons-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.position.prd_id,
                                  expression: "position.prd_id"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.$emit("positionView", _vm.position)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "left icon" },
                                [
                                  _c("v-ons-icon", {
                                    attrs: { icon: "md-image-o" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "center" }, [
                                _vm._v("Карточка товара")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("ons-list-item", { on: { click: _vm.remove } }, [
                            _c(
                              "div",
                              { staticClass: "left icon" },
                              [
                                _c("v-ons-icon", {
                                  attrs: { icon: "md-delete" }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "center" }, [
                              _vm._v("Удалить")
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "position-index" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.position.index) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row-wrap" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isEditing,
                    expression: "!isEditing"
                  }
                ],
                staticClass: "col col-name"
              },
              [
                _c("div", { staticClass: "position-name" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.position.name) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _vm.position.is_archive
                  ? _c("div", [
                      _c("small", [
                        _vm._v("этого товара нет у выбранного поставщика")
                      ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isEditing,
                    expression: "isEditing"
                  }
                ],
                staticClass: "col col-name"
              },
              [
                _c("input-proposal-create", {
                  ref: "input",
                  attrs: {
                    scrollPageOnFocus: true,
                    disableScrollPage: true,
                    addedPositions: _vm.addedPositions,
                    columns: _vm.$user.canSeePrice
                      ? ["name", "price"]
                      : ["name"],
                    preventAddedChoose: true,
                    urlSearch: _vm.urlSearch
                  },
                  on: {
                    choose: _vm.chooseProposal,
                    showProposals: function($event) {
                      return _vm.$emit("showProposals")
                    },
                    hideProposals: function($event) {
                      return _vm.$emit("hideProposals")
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col col-unit" }, [
              _vm.position.prd_id
                ? _c("div", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.position.unit) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.$window.isDesktop
              ? _c("div", { staticClass: "col col-count" }, [
                  _c("div", { staticClass: "count-wrap" }, [
                    _c("div", { staticClass: "wrap" }, [
                      !_vm.position.count || _vm.position.count == 0
                        ? _c("div", { staticClass: "col col-button" }, [
                            _c(
                              "button",
                              {
                                attrs: { tabindex: "-1" },
                                on: { click: _vm.remove }
                              },
                              [_c("i", { staticClass: "fa fa-fw fa-remove" })]
                            )
                          ])
                        : _c("div", { staticClass: "col col-button" }, [
                            _c(
                              "button",
                              {
                                attrs: { tabindex: "-1" },
                                on: { click: _vm.onPositionDecCount }
                              },
                              [_vm._v("-")]
                            )
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-input" }, [
                        _c("input", {
                          directives: [
                            { name: "round", rawName: "v-round" },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.position.count,
                              expression: "position.count"
                            }
                          ],
                          staticClass: "input-count",
                          attrs: {
                            type: "number",
                            step: _vm.position.mult,
                            name: "count-a-" + _vm.position.pos_id
                          },
                          domProps: { value: _vm.position.count },
                          on: {
                            change: _vm.onChangeCount,
                            blur: _vm.onBlur,
                            keydown: _vm.chooseCountHandler,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.position,
                                "count",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-button" }, [
                        _c(
                          "button",
                          {
                            attrs: { tabindex: "-1" },
                            on: { click: _vm.onPositionIncCount }
                          },
                          [_vm._v("+")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.position.mult != 1 && _vm.position.count > 0,
                            expression:
                              "position.mult != 1 && position.count > 0"
                          }
                        ],
                        staticClass: "package-wrap"
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.positionPackage(_vm.position)) +
                            "\n                        "
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$user.canSeePrice
              ? _c("div", { staticClass: "col col-price" }, [
                  _vm.position.prd_id
                    ? _c("div", [
                        _vm.canEditPrice
                          ? _c("div", [
                              _c("div", { staticClass: "wrap-input-price" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.position.price,
                                      expression: "position.price"
                                    }
                                  ],
                                  staticClass: "input-price without-arrow",
                                  attrs: { type: "number" },
                                  domProps: { value: _vm.position.price },
                                  on: {
                                    change: _vm.onChangePrice,
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.position,
                                        "price",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    class: [
                                      "font-rouble",
                                      _vm.platform === "Win32" ? "win" : ""
                                    ]
                                  },
                                  [_vm._v("o")]
                                )
                              ])
                            ])
                          : _c("div", [
                              _c(
                                "div",
                                {
                                  class: [
                                    "price-wrap",
                                    _vm.position.specialPrice.isShowMark
                                      ? "is-low-special-price"
                                      : ""
                                  ]
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.position.price)
                                  ),
                                  _vm.position.price
                                    ? _c(
                                        "span",
                                        { staticClass: "font-rouble" },
                                        [_vm._v("o")]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]),
                        _vm._v(" "),
                        _vm.isShowOverweightRestAlert && _vm.$window.isDesktop
                          ? _c(
                              "div",
                              {
                                staticClass: "overweight-rest-alert",
                                on: {
                                  click: function($event) {
                                    return _vm.showOverweightRestAlert(
                                      _vm.position
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            Остаток " +
                                    _vm._s(
                                      _vm._f("numeral")(
                                        _vm.position.rest,
                                        "0[.]00"
                                      )
                                    ) +
                                    " " +
                                    _vm._s(_vm.position.unit) +
                                    "\n                            "
                                ),
                                _c("span", { staticClass: "notify-icon" }, [
                                  _vm._v("?")
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    : _c(
                        "div",
                        [
                          _c(
                            "v-ons-select",
                            {
                              on: { change: _vm.onChangeUnit },
                              model: {
                                value: _vm.position.unit,
                                callback: function($$v) {
                                  _vm.$set(_vm.position, "unit", $$v)
                                },
                                expression: "position.unit"
                              }
                            },
                            _vm._l(_vm.units, function(unit) {
                              return _c(
                                "option",
                                {
                                  key: unit.id,
                                  domProps: { value: unit.name }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(unit.name) +
                                      "\n                            "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.$window.isDesktop
            ? _c("div", { staticClass: "count-row-wrap" }, [
                _c("div", { staticClass: "col col-remove" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.inventoryPositions.length > 0,
                          expression: "inventoryPositions.length > 0"
                        }
                      ]
                    },
                    [
                      _vm.getPositionInInventoryCount(_vm.position)
                        ? _c("div", { staticClass: "inventory-rest-wrap" }, [
                            _c("div", { staticClass: "ttl" }, [
                              _vm._v("Остаток")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "rest-wrap" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.getPositionInInventoryCount(
                                      _vm.position
                                    )
                                  ) +
                                  "\n                            "
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col col-count" }, [
                  _c("div", { staticClass: "count-wrap" }, [
                    _c("div", { staticClass: "ttl" }, [
                      _vm._v(
                        "\n                            Заказ, " +
                          _vm._s(_vm.position.unit) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "wrap", staticStyle: { width: "auto" } },
                      [
                        !_vm.position.count || _vm.position.count == 0
                          ? _c("div", { staticClass: "col col-button" }, [
                              _c(
                                "button",
                                {
                                  attrs: { tabindex: "-1" },
                                  on: { click: _vm.remove }
                                },
                                [_c("i", { staticClass: "fa fa-fw fa-remove" })]
                              )
                            ])
                          : _c("div", { staticClass: "col col-button" }, [
                              _c(
                                "button",
                                {
                                  attrs: { tabindex: "-1" },
                                  on: { click: _vm.onPositionDecCount }
                                },
                                [_vm._v("-")]
                              )
                            ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col col-input" }, [
                          _c("input", {
                            directives: [
                              { name: "round", rawName: "v-round" },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.position.count,
                                expression: "position.count"
                              }
                            ],
                            staticClass: "input-count",
                            attrs: {
                              type: "number",
                              step: _vm.position.mult,
                              name: "count-b-" + _vm.position.pos_id
                            },
                            domProps: { value: _vm.position.count },
                            on: {
                              change: _vm.onChangeCount,
                              blur: _vm.onBlur,
                              keydown: _vm.chooseCountHandler,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.position,
                                  "count",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col col-button" }, [
                          _c(
                            "button",
                            {
                              attrs: { tabindex: "-1" },
                              on: { click: _vm.onPositionIncCount }
                            },
                            [_vm._v("+")]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.position.mult != 1 && _vm.position.count > 0,
                            expression:
                              "position.mult != 1 && position.count > 0"
                          }
                        ],
                        staticClass: "package-wrap"
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.positionPackage(_vm.position)) +
                            "\n                        "
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$user.canSeePrice,
                        expression: "$user.canSeePrice"
                      }
                    ],
                    staticClass: "col col-total"
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isShowOverweightRestAlert,
                              expression: "isShowOverweightRestAlert"
                            }
                          ],
                          staticClass: "overweight-rest-alert",
                          on: {
                            click: function($event) {
                              return _vm.showOverweightRestAlert(_vm.position)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                            Остаток " +
                              _vm._s(
                                _vm._f("numeral")(_vm.position.rest, "0[.]00")
                              ) +
                              " " +
                              _vm._s(_vm.position.unit) +
                              "\n                            "
                          ),
                          _c("span", { staticClass: "notify-icon" }, [
                            _vm._v("?")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.total > 0,
                              expression: "total > 0"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("numeral")(_vm.total, "0[.][000]")
                              ) +
                              "\n                            "
                          ),
                          _c("span", { staticClass: "font-rouble" }, [
                            _vm._v("o")
                          ])
                        ]
                      )
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEditing,
                  expression: "isEditing"
                }
              ],
              staticClass: "update-buttons"
            },
            [
              _c(
                "button",
                { staticClass: "btn-cancel", on: { click: _vm.cancelUpdate } },
                [_c("v-ons-icon", { attrs: { icon: "md-block" } })],
                1
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e4bfc19e", { render: render, staticRenderFns: staticRenderFns })
  }
}