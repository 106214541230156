/**
 * This is where all the authorization login is stored
 */

export default function UserHasPermissions (router) {
    /**
     * Before each route we will see if the current user is authorized
     * to access the given route
     */
    router.beforeEach((to, from, next) => {
        let token = window.localStorage.getItem('token');

        let user = window.localStorage.getItem('user');
        if (user) {
            user = JSON.parse(window.localStorage.getItem('user'));
        }

        if (to.meta.requiresLogin && !token) {
            next({path: '/login', query: {redirect: to.fullPath}});
        } else if (to.meta.requiredPermissions && to.meta.requiredPermissions.indexOf('manager') != -1 && user.type != 'manager') {
            next({path: '/'});
        } else {
            next();
        }
    })
}