<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center">Ценообразование разделов</div>

            <div class="right" >
                <v-ons-toolbar-button v-show="categories.length > 0 && !isCheckedAll" @click="checkAll" title="Выбрать все разделы" >
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="categories.length > 0 && isCheckedAll" @click="uncheckAll" title="Снять выделение" >
                    <v-ons-icon style="color: yellow;" icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list v-show="categories.length > 0" style="margin-bottom: 70px" >
                <ons-list-item v-for="(category, key) in categories" :key="category.category_id" >
                    <label :for="'category-' + key" class="center label-reset" >
                        <span class="list-item__title" >
                            {{ category.name }}
                        </span>

                        <span class="list-item__subtitle" v-if="category.priceHint">
                            {{ category.priceHint }}
                        </span>
                        <span class="list-item__subtitle" v-else  style="color: red;">
                            Не настроена цена
                        </span>

                        <span class="list-item__subtitle" v-show="category.is_deleted" style="color: red;">
                            Раздел не найден
                        </span>
                    </label>
                    <div class="right" >
                        <v-ons-checkbox
                            v-model="checked"
                            :value="category.category_id"
                            :input-id="'category-' + key"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>
            </v-ons-list>

            <div v-show="categories.length === 0" class="list-empty" :style="{flexFlow: 'column', height: $window.height - 56 + 'px'}" >
                <div style="margin-bottom: 10px; font-size: 15px;" >
                    Данные не обнаружены
                </div>

                <ons-button @click="$router.push({name: 'manager-catalog-settings-includes'})" >
                    Настройте выгрузку разделов
                </ons-button>
            </div>
        </div>

        <v-ons-fab v-show="categories.length > 0" :disabled="!hasChecked" @click="pricing" position="bottom right" >
            <v-ons-icon icon="md-money-box"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,

            checked: [],
            categories: [],
        }
    },
    created() {
        this.load();
        this.$bus.$on('update', this.load);
    },
    destroyed() {
        this.$bus.$off('update', this.load);
    },
    methods: {
        load() {
            this.loading = true;
            this.checked = [];

            let requests = [
                this.$http.get(
                    '/api/manager/catalog-settings/pricing-categories',
                    {params: {storageId: this.$route.params.storage_id}}
                ),
            ];

            Promise.all(requests).then(responses => {
                this.categories = responses[0].data;

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.loading = false;
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        pricing() {
            let categories = this.categories.filter(i => this.checked.includes(i.category_id.toString()));
            this.$router.push({name: 'manager-catalog-settings-mode', params: {categories: categories}});
        },
        checkAll() {
            this.checked = this.categories.map(i => i.category_id.toString());
        },
        uncheckAll() {
            this.checked = [];
        },
    },
    computed: {
        hasChecked() {
            return this.checked.length > 0;
        },
        isCheckedAll() {
            return this.checked.length === this.categories.length;
        },
    },
};
</script>