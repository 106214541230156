<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>
            <div class="center">Профиль</div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item tappable @click="$router.push({name: $user.isManager ? 'profile-manager-contacts' : 'profile-contacts'})" class="cursor-pointer" >
                Контакты
            </v-ons-list-item>

            <v-ons-list-item v-show="$user.isCustomer || $user.canSee('branches')" tappable @click="$router.push('/profile/branches')" class="cursor-pointer" >
                Филиалы
            </v-ons-list-item>

            <v-ons-list-item v-show="$user.isManager" tappable @click="$router.push('/profile/manager-entities')" class="cursor-pointer" >
                Юридические лица
            </v-ons-list-item>

            <v-ons-list-item v-show="$user.isCustomer || $user.canSee('entities')"  tappable @click="$router.push('/profile/entities')" class="cursor-pointer" >
                Юридические лица
            </v-ons-list-item>

            <v-ons-list-item v-show="$user.isCustomer" tappable @click="$router.push('/profile/workers')" class="cursor-pointer" >
                Сотрудники
            </v-ons-list-item>

            <v-ons-list-item v-show="$user.isAdmin || $user.hasPermission('canManageCatalog')" tappable @click="$router.push('/profile/manager-storages')" class="cursor-pointer" >
                Склады
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>
export default {};
</script>