import SpecialPrices from '~/screen/manager/users/SpecialPrices';
import SpecialPricesSettings from '~/screen/manager/users/SpecialPricesSettings';

let route = [
    {
        path: 'special-prices',
        name: 'manager-user-special-prices',
        component: SpecialPrices,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'settings',
                name: 'manager-user-special-prices-settings',
                component: SpecialPricesSettings,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            }
        ]
    },
];

export default route;