var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Восстановить пароль")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "login-page" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.sended,
                expression: "!sended"
              }
            ],
            staticClass: "form"
          },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("v-ons-input", {
                  attrs: { type: "text", placeholder: "Email" },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("v-ons-button", { on: { click: _vm.back } }, [
                  _vm._v("Назад")
                ]),
                _vm._v(" "),
                _c("v-ons-button", { on: { click: _vm.submit } }, [
                  _vm._v("Продолжить")
                ])
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.sended,
                expression: "sended"
              }
            ],
            staticClass: "text-center"
          },
          [
            _c("v-ons-card", [
              _vm._v(
                "\n                Вам отправлено письмо с инструкциями по смене пароля.\n            "
              )
            ]),
            _vm._v(" "),
            _c("v-ons-button", { on: { click: _vm.back } }, [_vm._v("Ok")])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        {
          attrs: { visible: _vm.loading },
          on: {
            "update:visible": function($event) {
              _vm.loading = $event
            }
          }
        },
        [
          _c(
            "v-ons-row",
            { attrs: { "vertical-align": "center" } },
            [
              _c(
                "v-ons-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { width: "70px" }
                },
                [
                  _c("v-ons-progress-circular", {
                    attrs: { indeterminate: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-col", [
                _c("div", { staticStyle: { "line-height": "100px" } }, [
                  _vm._v("Загрузка...")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-31e0b83d", { render: render, staticRenderFns: staticRenderFns })
  }
}