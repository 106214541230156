<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Отчёт по запросам</div>

            <div class="right" >
                <v-ons-toolbar-button v-show="isGenerated" @click="reset" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-card>
                <div v-show="!isGenerated" >
                    <ons-list>
                        <ons-list-header>Период</ons-list-header>
                        <ons-list-item>
                            <div class="wrap xl-gutter-8" >
                                <div class="col xl-1-2" >
                                    <v-input-datepicker
                                        v-model="filters.dateFrom"
                                        placeholder='От'
                                    />
                                </div>

                                <div class="col xl-1-2" >
                                    <v-input-datepicker
                                        v-model="filters.dateTo"
                                        placeholder='До'
                                    />
                                </div>
                            </div>
                        </ons-list-item>
                    </ons-list>

                    <div style="margin-top: 10px; text-align: center" >
                        <v-ons-button :disabled="!canGenerate" @click="generate" >
                            Сформировать
                        </v-ons-button>
                    </div>
                </div>

                <div v-show="isGenerated" >
                    <div style="font-weight: bold" >Список запросов, по которым ничего не найдено</div>
                    <table class="table table-positions" >
                        <thead>
                            <tr>
                                <th>Запрос</th>
                                <th>Кол-во строк</th>
                                <th>Кол-во запросов</th>
                            </tr>
                        </thead>
                        <tbody v-show="rowsZero.length" >
                            <tr v-for="row in rowsZero" >
                                <td>{{ row.query }}</td>
                                <td>{{ row.found_count }}</td>
                                <td>{{ row.query_count }}</td>
                            </tr>
                        </tbody>

                        <tbody v-show="rowsZero.length == 0" >
                            <tr>
                                <td colspan="3" >Ничего не найдено</td>
                            </tr>
                        </tbody>
                    </table>

                    <div style="font-weight: bold" >Список запросов, по которым найдено больше 10</div>
                    <table class="table table-positions" >
                        <thead>
                            <tr>
                                <th>Запрос</th>
                                <th>Кол-во строк</th>
                                <th>Кол-во запросов</th>
                            </tr>
                        </thead>
                        <tbody v-show="rowsMoreTen.length" >
                            <tr v-for="row in rowsMoreTen" >
                                <td>{{ row.query }}</td>
                                <td>{{ row.found_count }}</td>
                                <td>{{ row.query_count }}</td>
                            </tr>
                        </tbody>

                        <tbody v-show="rowsMoreTen.length == 0" >
                            <tr>
                                <td colspan="3" >Ничего не найдено</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </v-ons-card>
        </div>
    </v-ons-page>
</template>

<script>

import InputDatepicker from '../../../component/InputDatepicker'

export default {
    data() {
        return {
            isGenerated: false,
            loading: false,
            filters: {
                dateFrom: '',
                dateTo: [],
            },
            rowsZero: [],
            rowsMoreTen: [],
        }
    },
    methods: {
        generate() {
            this.loading = true;

            this.$http.get('/api/manager/reports/search-queries', {params: {filters: this.filters}}).then(response => {
                this.isGenerated = true;
                this.loading = false;
                this.rowsZero = response.data.rowsZero;
                this.rowsMoreTen = response.data.rowsMoreTen;
            }, response => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });

                this.loading = false;
            });
        },
        reset() {
            this.isGenerated = false;
        },
    },
    computed: {
        canGenerate() {
            if (!this.filters.dateFrom) {
                return false;
            }

            if (!this.filters.dateTo) {
                return false;
            }

            return true;
        },
    },
    components: {
        'v-input-datepicker': InputDatepicker,
    },
};
</script>