<template>
    <v-ons-dialog cancelable :visible.sync="dialogVisible" >
        <div class="alert-dialog-container alert-dialog-container--material" >
            <div class="alert-dialog-content alert-dialog-content--material" >
                {{ message }}
            </div>

            <div style="padding: 0 20px 4px;">
                <v-ons-button style="width: 100%; margin: 10px 0;" v-for="(button, key) in buttons" @click="onClick(key)" :key="key" >
                    {{ button }}
                </v-ons-button>
            </div>
        </div>
    </v-ons-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            message: '',
            buttons: {},
            callback: null,
        }
    },
    created() {
    },
    methods: {
        onClick(key) {
            this.dialogVisible = false;
            this.callback.call(this.$parent, key);
        },
        show(message, buttons, callback) {
            this.message = message;
            this.buttons = buttons;
            this.callback = callback;
            this.dialogVisible = true;
        }
    },
};
</script>
