<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">Управление</div>
        </v-ons-toolbar>

        <ons-list >
            <ons-list-item v-show="storages.length > 1" modifier="chevron longdivider" @click="showChooseStorage">
                <span class="list-item__title">Склад: {{ selectedStorageName }}</span>
                <span v-if="selectedStorage && !selectedStorage.is_configured" style="color: red" class="list-item__subtitle">
                    Не настроено
                </span>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="$user.isAdmin || $user.hasPermission('canManageCatalog')"
                @click="$router.push(currentRoute + '/catalog-settings')" >

                <div class="center" >Настройка каталога</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                @click="$router.push(currentRoute + '/shipping-payment-methods')" >

                <div class="center" >Типы цен, скидки, способы отгрузки</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                @click="$router.push(currentRoute + '/shipping-by-routes')" >

                <div class="center" >График приемки заказов и доставки по маршрутам</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                @click="$router.push(currentRoute + '/pickup-shipment-schedule')" >

                <div class="center" >График отгрузки самовывоза</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                @click="$router.push(currentRoute + '/special-schedules')" >

                <div class="center" >Специальный график работы</div>
            </ons-list-item>
        </ons-list>

        <v-list-dialog ref="chooseStorageDialog" title="Выберите склад" :items="storages" />
    </v-ons-page>
</template>

<script>
import ListDialog from "~/component/ListDialog";

export default {
    data() {
        return {
            choseStorage: null,
            storages: [],
        }
    },
    created() {
        if (this.$route.params.storage_id) {
            this.choseStorage = this.$route.params.storage_id;
        }

        this.loadStorages();
        this.$bus.$on('checkErrors', this.loadStorages);
    },
    destroyed() {
        this.$bus.$off('checkErrors', this.loadStorages);
    },
    methods: {
        loadStorages() {
            this.$http.get(
                '/api/manager/storages',
                {params: {withConfigured: true}}
            ).then(response => {
                this.storages = response.data;

                if (!this.choseStorage) {
                    let storage = this.storages.find(s => s.is_default) || this.storages[0]
                    if (storage) {
                        this.choseStorage = storage.id;
                    }
                }
            });
        },
        showChooseStorage() {
            this.$refs.chooseStorageDialog.show().then(response => {
                this.choseStorage = response;
            });
        },
    },
    computed: {
        selectedStorage() {
            return this.storages.find(s => s.id === parseInt(this.choseStorage));
        },
        selectedStorageName() {
            let storage = this.storages.find(s => s.id === parseInt(this.choseStorage));

            if (!storage) {
                return 'Неизвестно';
            }

            return storage.name;
        },
        currentRoute() {
            let currentRoute = this.$router.currentRoute.path;
            currentRoute = currentRoute.replace(/\/storage-\d$/g, '');

            return currentRoute + '/storage-' + this.choseStorage;
        }
    },
    components: {
        'v-list-dialog': ListDialog,
    },
};
</script>