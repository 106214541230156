<!-- src/screen/order/CreateEntity.vue -->
<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div v-if="id" class="center">Юр. лицо: {{ entity.legal_name }}</div>
            <div v-if="!id" class="center">Создать юр. лицо</div>

            <div class="right" v-show="!loading" >
                <v-ons-toolbar-button @click="remove" v-if="id" title="Удалить юр. лицо" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" title="Сохранить" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-header>Юридическое наименование</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.legal_name" name="legal_name" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Юридический адрес</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.legal_address" name="legal_address" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>ИНН</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.inn" type="number" name="inn" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>КПП</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.kpp" type="number" name="kpp" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>ОГРН</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.ogrn" type="number" name="ogrn" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Дата ОГРН</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.ogrn_created_at" name="ogrn_created_at" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Способ оплаты по умолчанию:</v-ons-list-header>

                <v-ons-list-item>
                    <div class="select-material select--material select">
                        <select class="select-input select-input--material" v-model="entity.default_payment_method" >
                            <option :value="''" >не выбрано</option>

                            <option v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" :value="paymentMethod.id" >
                                {{ paymentMethod.name }}
                            </option>
                        </select>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <ons-list-header>Адрес ведения деятельности</ons-list-header>
                <ons-list-item v-for="(branch, key) in branches" :key="branch.id" >
                    <label :for="'branch-' + key" class="center label-reset" >
                        {{ [branch.stringAddress, branch.actual_name].filter(i => i).join(', ')  }}
                    </label>
                    <div class="right" v-if="!isOnlyEntity" >
                        <v-ons-checkbox
                            v-model="entity.branchIds"
                            :value="branch.id"
                            :input-id="'branch-' + key"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                    <div class="right" v-else >
                        <v-ons-checkbox
                            v-model="isOnlyEntity"
                            value="true"
                            checked="true"
                            disabled="true"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            changed: false,
            loaded: false,

            id: null,
            entity: {
                branchIds: [],
            },
            paymentMethods: [],
            branches: [],
            entities: [],
        }
    },
    created() {
        this.id = this.$route.params.entity_id;
        this.load();
    },
    methods: {
        load() {
            this.loading = true;

            let requests = [
                this.$http.get('/api/user/payment-methods'),
                this.$http.get('/api/user/branches'),
                this.$http.get('/api/settings/entities'),
            ];

            if (this.id) {
                requests.push(this.$http.get('/api/settings/entities/' + this.id))
            }

            Promise.all(requests).then(responses => {
                this.paymentMethods = responses[0].data;
                this.branches = responses[1].data;
                this.entities = responses[2].data;

                if (this.id) {
                    this.entity = responses[3].data;
                    this.entity.branchIds = this.entity.branchIds.map(i => i.toString());
                }

                this.$nextTick(() => {
                    this.loaded = true;
                    this.loading = false;
                });
            }, () => {
                this.loading = false;

                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        save() {
            let promise;
            if (this.id) {
                promise = this.$http.post('/api/settings/entities/' + this.id, this.entity);
            } else {
                promise = this.$http.post('/api/settings/entities', this.entity);
            }

            promise.then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Юр. лицо сохранено',
                    buttonLabel: 'OK',
                });
                this.$bus.$emit('load');
                this.$bus.$emit('entities-load');
                this.$router.go(-1);
            }, (response) => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                });
            });
        },
        remove() {
            this.$ons.notification.confirm('Удалить юр. лицо?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.delete('/api/settings/entities/' + this.id).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Юр. лицо удалено',
                        buttonLabel: 'OK',
                    });
                    this.$bus.$emit('load');
                    this.$router.go(-1);
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
    },
    computed: {
        isOnlyEntity() {
            if (this.entities.length == 0 && !this.id) {
                return true;
            }

            if (this.entities.length == 1 && this.id) {
                return true;
            }

            return false;
        },
    },
    watch: {
        entity: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>
