<template>
    <v-ons-list-item class="list-item-product" >
        <label v-if="actionColType === 'checkbox'" class="col-checkbox" >
            <input type="checkbox" v-model="isChecked" />
        </label>

        <div :class="['col-name', canOpenProductPage ? 'cursor-pointer' : '']" @click="clickGoToView(item)" >
            <div class="name" >
                {{ item.name }}
            </div>

            <div class="product-icons" >
                <div v-show="item.is_special" class="vip-icon icon" >VIP</div>
                <div title="У этого товара есть фото" v-show="item.image" class="has-image-icon icon" ></div>
            </div>
        </div>

        <div v-if="isDesktop && $user.isManager" class="col-rest" >
            <span v-if="item.is_wait" >0 {{ item.unit }}</span>
            <span v-else >{{ item.rest }} {{ item.unit }}</span>

            <span
                class="notify-icon"
                @click="showOverweightRestAlert(item)"
                v-show="showInputs && parseFloat(counts[item.id]) > parseFloat(item.rest)" >!</span>
        </div>

        <div :class="['col-price', item.specialPrice.isShowMark ? 'is-low-special-price' : '']" v-show="$user.canSeePrice" >
            <div class="price-block text-right" >
                <span v-if="item.is_wait" >ждём</span>
                <span v-else >{{ calcPrice(item) }} р.</span>
            </div>

            <div v-show="isDesktop" class="text-right" >
                {{ item.unit }}
                <span
                    class="notify-icon"
                    @click="showOverweightRestAlert(item)"
                    v-show="showInputs && parseFloat(counts[item.id]) > parseFloat(item.rest)" >!</span>
            </div>

            <div v-show="!isDesktop" >
                <div class="wrap" >
                    <div class="col xl-1-2 text-left" >
                        <div v-show="!item.is_wait || showInputs" >
                            {{ item.unit }}
                        </div>
                    </div>

                    <div class="col xl-1-2 text-right" >
                        <div>
                            <span
                                class="notify-icon"
                                @click="showOverweightRestAlert(item)"
                                v-show="showInputs && parseFloat(counts[item.id]) > parseFloat(item.rest)" >!</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(showInputs || isDesktop) && !$user.isManager" class="col-count" >
            <input type="number" v-model="counts[item.id]" v-round :step="item.mult" >
        </div>
    </v-ons-list-item>
</template>

<script>
import numeral from "numeral";

export default {
    props: [
        'item',
        'isDesktop',
        'actionColType',
        'checked',
        'canOpenProductPage',
        'showInputs',
        'selectedPriceType',
        'counts',
        'lastImportDate',
    ],
    data() {
        return {
            isChecked: false,
        }
    },
    created() {
        this.isChecked = this.checked.includes(this.item.id);
    },
    methods: {
        clickGoToView(item) {
            this.$emit('clickGoToView', item);
        },
        showOverweightRestAlert(position) {
            let message = '';
            let lastImportDate = this.$moment.unix(this.lastImportDate).format("HH:mm от L");
            let rest = numeral(position.rest).format('0[.]00');

            if (parseInt(position.rest) === 0) {
                message += `Товар кончился: ${position.deletedAt} <br><br>`;
                message += `${position.name} <br> <b>Нет в наличии</b> <br><br>`;
            } else {
                message += `По данным на <b>${lastImportDate}</b> <br><br>`;
                message += `${position.name} <br> всего <b>${rest} ${position.unit}</b> <br><br>`;
            }

            message += `
                Вы можете добавить товар в полном объёме и отправить заказ как есть, или можете подобрать замену.<br><br>
                Если товар ещё не поступил, то менеджер по возможности предложит альтернативу.`;

            this.$ons.notification.alert({title: '', message: message});
        },
        calcPrice(item) {
            if (!this.$user.isManager) {
                return item.price;
            }

            if (!this.selectedPriceType) {
                return item.price;
            }

            let pr = item.prices.find(i => this.selectedPriceType == i.typeId);

            if (pr) {
                return pr.price;
            }

            return item.price;
        },
    },
    watch: {
        isChecked() {
            if (this.isChecked) {
                this.checked.push(this.item.id);
            } else {
                this.checked.splice(this.checked.indexOf(this.item.id), 1);
            }
        },
        checked() {
            this.isChecked = this.checked.includes(this.item.id);
        }
    },
};
</script>