var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-toolbar-button",
    {
      attrs: {
        id: "show-popover-menu-btn-" + _vm.id,
        title: "Дополнительные функции"
      },
      on: {
        click: function($event) {
          _vm.popoverMenuVisible = true
        }
      }
    },
    [
      _c("v-ons-icon", { attrs: { icon: "md-more-vert" } }),
      _vm._v(" "),
      _c(
        "v-ons-popover",
        {
          class: "popover-menu " + _vm.popoverClass,
          attrs: {
            cancelable: "",
            visible: _vm.popoverMenuVisible,
            target: "#show-popover-menu-btn-" + _vm.id,
            "cover-target": "true",
            direction: "down"
          },
          on: {
            "update:visible": function($event) {
              _vm.popoverMenuVisible = $event
            }
          }
        },
        [_c("ons-list", { on: { click: _vm.close } }, [_vm._t("default")], 2)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4d937674", { render: render, staticRenderFns: staticRenderFns })
  }
}