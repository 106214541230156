var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-alert-dialog",
    {
      attrs: { modifier: "rowfooter", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        prehide: _vm.onPreHide
      }
    },
    [
      _c(
        "span",
        {
          staticStyle: { "font-size": "18px" },
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Провести замену связей?")]
      ),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.productNameFrom))]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "center", margin: "10px 0" } },
        [
          _c("v-ons-icon", {
            attrs: { icon: "md-format-valign-bottom", size: "30px" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.productNameTo))]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("v-ons-alert-dialog-button", { on: { click: _vm.onOk } }, [
            _vm._v("Да")
          ]),
          _vm._v(" "),
          _c("v-ons-alert-dialog-button", { on: { click: _vm.onNo } }, [
            _vm._v("Нет")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3ad0b055", { render: render, staticRenderFns: staticRenderFns })
  }
}