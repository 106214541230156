<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>
            <div class="center">Филиалы: {{ user.email }}</div>
        </v-ons-toolbar>

        <v-ons-list v-show="branches" >
            <v-ons-list-item v-for="branch in branches" :key="branch.id" modifier="chevron longdivider" tappable >
                <div class="left" @click="showScheduleModal(branch)" style="min-width: 39px;" >
                    <v-ons-icon class="cursor-pointer" v-show="hasSchedule(branch)" icon="md-calendar-note" size="21px" title="График и условия доставки" ></v-ons-icon>
                </div>
                <div class="center cursor-pointer" @click="$router.push({name: 'manager-user-branch', params: {branch_id: branch.id}})" >
                    <span class="list-item__title" >
                        {{ branch.stringAddressWithName }}
                    </span>

                    <span v-if="branch.route" class="list-item__subtitle">
                        Маршрут: {{ branch.route.name }}
                    </span>

                    <span v-show="!branch.hasEntity" class="list-item__subtitle list-item__subtitle__warning" >
                        Не привязано юр. лицо
                    </span>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <div v-show="!branches.length" class="" >
            <div style="font-size: 20px; color: #a8a8a8; position: absolute; top: 50%; text-align: center; width: 100%; line-height: 50px; margin-top: -25px;" >
                Список пуст<br>
                 <v-ons-button @click="$router.push({name: 'manager-user-branch-create'})" >Добавить филиал</v-ons-button>
            </div>
        </div>

        <v-ons-fab v-show="branches.length" position="bottom right" @click="$router.push({name: 'manager-user-branch-create'})" >
          <v-ons-icon icon="md-plus"></v-ons-icon>
        </v-ons-fab>

        <schedule-modal ref="scheduleModal" />
    </v-ons-page>
</template>

<script>

import ScheduleModal from '~/component/ScheduleModal'

export default {
    data() {
        return {
            user: {},
            branches: [],
        }
    },
    created() {
        this.$http.get('/api/manager/users/' + this.$route.params.user_id).then(response => {
            this.user = response.data;
        });

        this.load();
        this.$bus.$on('load', this.load);
    },
    destroyed() {
        this.$bus.$off('load');
    },
    methods: {
        load() {
            this.$http.get('/api/manager/users/'+ this.$route.params.user_id  +'/branches').then(response => {
                this.branches = response.data;
            });
        },
        hasSchedule(branch) {
            if (!branch || !branch.route) {
                return false;
            }

            return true;
        },
        showScheduleModal(branch) {
            if (!this.hasSchedule(branch)) {
                return;
            }

            this.$refs.scheduleModal.show(branch);
        },
    },
    components: {
        ScheduleModal,
    },
};
</script>

