var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Филиалы")])
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.branches,
              expression: "branches"
            }
          ]
        },
        _vm._l(_vm.branches, function(branch) {
          return _c(
            "v-ons-list-item",
            { key: branch.id, attrs: { modifier: "chevron longdivider" } },
            [
              _c(
                "div",
                {
                  staticClass: "left",
                  staticStyle: { "min-width": "39px" },
                  on: {
                    click: function($event) {
                      return _vm.showScheduleModal(branch)
                    }
                  }
                },
                [
                  _c("v-ons-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasSchedule(branch),
                        expression: "hasSchedule(branch)"
                      }
                    ],
                    staticClass: "cursor-pointer",
                    attrs: {
                      icon: "md-calendar-note",
                      size: "21px",
                      title: "График и условия доставки"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "center cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/profile/branches/" + branch.id)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "list-item__title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(branch.stringAddressWithName) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  branch.route
                    ? _c("span", { staticClass: "list-item__subtitle" }, [
                        _vm._v(
                          "\n                    Маршрут: " +
                            _vm._s(branch.route.name) +
                            "\n                "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !branch.hasEntity,
                          expression: "!branch.hasEntity"
                        }
                      ],
                      staticClass:
                        "list-item__subtitle list-item__subtitle__warning"
                    },
                    [
                      _vm._v(
                        "\n                    Не привязано юр. лицо\n                "
                      )
                    ]
                  )
                ]
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.branches.length,
              expression: "!branches.length"
            }
          ]
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "20px",
                color: "#a8a8a8",
                position: "absolute",
                top: "50%",
                "text-align": "center",
                width: "100%",
                "line-height": "50px",
                "margin-top": "-25px"
              }
            },
            [
              _vm._v("\n            Список пуст"),
              _c("br"),
              _vm._v(" "),
              _c(
                "v-ons-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/profile/branches/create")
                    }
                  }
                },
                [_vm._v("Добавить филиал")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.branches.length,
              expression: "branches.length"
            }
          ],
          attrs: {
            position: "bottom right",
            title: "Создать новую точку доставки"
          },
          on: {
            click: function($event) {
              return _vm.$router.push("/profile/branches/create")
            }
          }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
        1
      ),
      _vm._v(" "),
      _c("schedule-modal", { ref: "scheduleModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1e361ee0", { render: render, staticRenderFns: staticRenderFns })
  }
}