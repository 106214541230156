var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Настройки заказа")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Способ отгрузки по умолчанию")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c(
                    "v-ons-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.defaultShippingMethod,
                        callback: function($$v) {
                          _vm.defaultShippingMethod = $$v
                        },
                        expression: "defaultShippingMethod"
                      }
                    },
                    _vm._l(_vm.shippingMethodList, function(item) {
                      return _c("option", { domProps: { value: item.id } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.name) +
                            "\n                    "
                        )
                      ])
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Способы оплаты по умолчанию")]),
              _vm._v(" "),
              _vm.entities.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.entities, function(entity) {
                      return _c("v-ons-list-item", { key: entity.id }, [
                        _c("div", { staticClass: "center" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(entity.name) +
                              " - " +
                              _vm._s(entity.defaultPaymentMethod) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c("v-ons-icon", {
                              staticClass: "cursor-pointer list-item__icon",
                              attrs: { icon: "md-edit" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    "/profile/entities/" + entity.id
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    }),
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-ons-list-item", [
                        _c(
                          "div",
                          { staticClass: "center" },
                          [
                            _c(
                              "v-ons-button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push(
                                      "/profile/entities/create"
                                    )
                                  }
                                }
                              },
                              [_vm._v("Добавить юр. лицо")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7678d648", { render: render, staticRenderFns: staticRenderFns })
  }
}