<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Профиль: {{ user.email }}</div>
        </v-ons-toolbar>

        <ons-list >
            <ons-list-item
                modifier="chevron longdivider"
                tappable
                @click="$router.push({name: 'manager-user-contacts'})" >

                <div class="center" >Контакты</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'customer'"
                @click="$router.push({name: 'manager-user-branches'})" >

                <div class="center" >Филиалы и маршруты</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="user.user_type === 'customer'"
                @click="$router.push({name: 'manager-user-entities'})" >

                <div class="center" >Юридические лица</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                v-show="user.user_type === 'customer'"
                @click="showChooseStorage"
            >

                <div class="center" >Склад: {{ selectedStorageName }}</div>
            </ons-list-item>
        </ons-list>

        <v-radio-dialog ref="chooseStorageDialog" title="Выберите склад" :items="storages"/>
    </v-ons-page>
</template>

<script>

import RadioDialog from "~/component/RadioDialog";

export default {
    data() {
        return {
            userId: null,
            user: {},
            storages: [],
        }
    },
    created() {
        this.userId = this.$route.params.user_id;
        this.$bus.$on('updateUser', this.load);
        this.load();
    },
    methods: {
        load() {
            this.$http.get('/api/manager/users/' + this.userId).then(response => {
                this.user = response.data;
            });

            this.$http.get('/api/manager/storages/get-active-storages').then(response => {
                this.storages = response.data;
            });
        },
        showChooseStorage() {
            this.$refs.chooseStorageDialog.show({selected: this.selectedStorageId}).then(response => {
                this.$http.post(
                    '/api/manager/users/' + this.userId + '/storage',
                    {storageId: response}
                ).then(response => {
                    this.user.storage_id = response.data;
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 3000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
    },
    computed: {
        selectedStorageName() {
            let storage = this.storages.find(s => s.id === this.user.storage_id);

            if (!storage) {
                return 'Неизвестно';
            }

            return storage.name;
        },
        selectedStorageId() {
            let storage = this.storages.find(s => s.id === this.user.storage_id);

            if (!storage) {
                return null;
            }

            return storage.id.toString();
        },
    },
    components: {
        'v-radio-dialog': RadioDialog,
    }
};
</script>