<template>
    <v-ons-page class="manager-user-entity">
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div v-if="id" class="center">Юр. лицо: {{ entity.legal_name }}</div>
            <div v-if="!id" class="center">Создать юр. лицо</div>

            <div class="right">
                <v-ons-toolbar-button @click="remove" v-if="id" title="Удалить юр. лицо" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" title="Сохранить" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-header>Юридическое наименование</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.legal_name" name="legal_name" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Юридический адрес</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.legal_address" name="legal_address" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>
                    ИНН
                    <a
                        v-show="entity.inn"
                        :href="'https://ya.ru/search/?text=' + entity.inn"
                        target="_blank" style="color: #757575; position: absolute; right: 0; padding: 0 30px;"
                        title="Проверить в Yandex"
                        >
                        <v-ons-icon icon="md-google"></v-ons-icon>
                    </a>
                </v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.inn" type="number" name="inn" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>КПП</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.kpp" type="number" name="kpp" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>ОГРН</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.ogrn" type="number" name="ogrn" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Дата ОГРН</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="entity.ogrn_created_at" name="ogrn_created_at" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Договоры:</v-ons-list-header>

                <div v-for="contract in contracts" :key="contract.id">
                    <v-ons-list-item modifier="longdivider" >
                        <div class="left" >
                            <v-ons-checkbox
                                :input-id="'checkbox-contract-' + contract.id"
                                :value="contract.id"
                                v-model="entity.contracts"
                            ></v-ons-checkbox>
                        </div>
                        <label class="center" :for="'checkbox-contract-' + contract.id" style="font-weight: normal; margin: 0;" >{{ contract.name }}</label>
                    </v-ons-list-item>

                    <div v-if="entity.contracts.includes(contract.id.toString())" class="wrap-contract-manager-entities" >
                        <v-ons-list-item modifier="longdivider"  v-for="managerEntity in managerEntities" :key="managerEntity.id">
                            <div class="left" >
                                <v-ons-checkbox
                                    :input-id="'checkbox-managerEntity-' + contract.id + '-' + managerEntity.id"
                                    :value="contract.id + '-' + managerEntity.id"
                                    v-model="signedContracts"
                                ></v-ons-checkbox>
                            </div>
                            <label class="center" :for="'checkbox-managerEntity-' + contract.id + '-' + managerEntity.id" style="font-weight: normal; margin: 0;" >
                                {{ managerEntity.alias }}
                            </label>
                        </v-ons-list-item>
                    </div>
                </div>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Основное юр.лицо для работы с этим клиентом</v-ons-list-header>

                <v-ons-list-item>
                    <div class="select-material select--material select">
                        <select class="select-input select-input--material" v-model="entity.default_provider_entity" >
                            <option :value="''" >не выбрано</option>

                            <option v-for="providerEntity in managerEntities" :key="providerEntity.id" :value="providerEntity.id" >
                                {{ providerEntity.alias }}
                            </option>
                        </select>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Способ оплаты по умолчанию:</v-ons-list-header>

                <v-ons-list-item>
                    <div class="select-material select--material select">
                        <select class="select-input select-input--material" v-model="entity.default_payment_method" >
                            <option :value="''" >не выбрано</option>

                            <option v-for="paymentMethod in getPaymentMethods" :key="paymentMethod.id" :value="paymentMethod.id" >
                                {{ paymentMethod.name }}
                            </option>
                        </select>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-item class="list-header--material" >
                    <div class="center" >
                        Заблокировать способ оплаты:
                    </div>
                    <div class="right" >
                        <v-ons-button @click="addBlockedPaymentMethods" :disabled="getPaymentMethodsForBlock.length === 0" >
                            <v-ons-icon icon="md-plus"></v-ons-icon>
                        </v-ons-button>
                    </div>
                </v-ons-list-item>

                <v-ons-list-item v-show="entity.blocked_payment_methods" v-for="method in getBlockedPaymentMethods" :key="method.id" >
                    <div class="center" >
                        {{ method.name }}
                    </div>
                    <div class="right" >
                        <v-ons-button @click="removeBlockedPaymentMethods(method)" style="margin-left: 5px;" modifier="outline" >
                            <v-ons-icon icon="md-close" ></v-ons-icon>
                        </v-ons-button>
                    </div>
                </v-ons-list-item>

                <v-ons-list-item style="justify-content: center;" >
                    <v-list-dialog
                        ref="paymentMethodsForBlockDialog"
                        title="Заблокировать способ оплаты"
                        btnTitle="Блокировать"
                        :multiple="true"
                        :items="getPaymentMethodsForBlock" />
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Адрес ведения деятельности</v-ons-list-header>
                <ons-list-item v-for="(branch, key) in branches" :key="branch.id" >
                    <label :for="'branch-' + key" class="center label-reset" >
                        {{ [branch.stringAddress, branch.actual_name].filter(i => i).join(', ')  }}
                    </label>
                    <div class="right" v-if="!isOnlyEntity" >
                        <v-ons-checkbox
                            v-model="entity.branchIds"
                            :value="branch.id"
                            :input-id="'branch-' + key"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                    <div class="right" v-else >
                        <v-ons-checkbox
                            v-model="isOnlyEntity"
                            value="true"
                            checked="true"
                            disabled="true"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>

import ListDialog from '~/component/ListDialog'

export default {
    data() {
        return {
            changed: false,
            loaded: false,
            loading: true,

            userId: null,
            id: null,
            entity: {
                blocked_payment_methods: [],
                contracts: [],
                branchIds: [],
            },
            contracts: [],
            paymentMethods: [],
            branches: [],
            entities: [],
            signedContracts: [],
            managerEntities: [],
        }
    },
    created() {
        this.id = this.$route.params.entity_id;
        this.userId = this.$route.params.user_id;
        this.load();
    },
    methods: {
        load() {
            this.loading = true;

            let requests = [
                this.$http.get('/api/manager/users/'+ this.userId  +'/entities/params'),
            ];

            if (this.id) {
                requests.push(this.$http.get('/api/manager/users/'+ this.userId  +'/entities/' + this.id))
            }

            Promise.all(requests).then(responses => {
                let params = responses[0].data;
                this.contracts = params.contracts;
                this.paymentMethods = params.paymentMethods;
                this.branches = params.branches;
                this.entities = params.entities;
                this.managerEntities = params.managerEntities;

                if (responses[1]) {
                    this.entity = responses[1].data;
                    this.entity.branchIds = this.entity.branchIds.map(i => i.toString());
                    this.entity.contracts = this.entity.contracts.map(c => c.id.toString());
                    this.signedContracts = this.entity.signedContracts.map(i => i.contract.id + '-' + i.vendorEntity.id)
                }

                this.$nextTick(() => {
                    this.loading = false;
                    this.loaded = true;
                });
            }, () => {
                this.loading = false;

                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            })
        },
        save() {
            let promise;
            let entity = this.entity

            entity.signedContracts = this.signedContracts.map(item => {
                let params = item.split('-')
                return {
                    contract_id: params[0],
                    vendor_entity_id: params[1],
                }
            })

            if (this.id) {
                promise = this.$http.post('/api/manager/users/'+ this.userId  +'/entities/' + this.id, entity);
            } else {
                promise = this.$http.post('/api/manager/users/'+ this.userId  +'/entities', entity);
            }

            promise.then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Юр. лицо сохранено',
                    buttonLabel: 'OK',
                });
                this.$bus.$emit('load');
                this.$bus.$emit('entities-load');
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
            }, (response) => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                });
            });
        },
        remove() {
            this.$ons.notification.confirm('Удалить юр. лицо?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.delete('/api/manager/users/'+ this.userId  +'/entities/' + this.id).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Юр. лицо удалено',
                        buttonLabel: 'OK',
                    });
                    this.$bus.$emit('load');
                    this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        addBlockedPaymentMethods() {
            this.$refs.paymentMethodsForBlockDialog.show().then(response => {
                response.forEach(i => {
                    this.entity.blocked_payment_methods.push(i.toString());
                });

                if (this.entity.blocked_payment_methods.includes(this.entity.default_payment_method.toString())) {
                    this.entity.default_payment_method = null;
                }
            });
        },
        removeBlockedPaymentMethods(method) {
            this.entity.blocked_payment_methods = this.entity.blocked_payment_methods.filter(i => i != method.id.toString());
        },
    },
    computed: {
        getPaymentMethods() {
            return this.paymentMethods
                // Если выбрана опция "Оф. договор", то добавляется способ оплаты: оплата б/н
                .filter(i => {
                    return !(i.id == 6 && this.entity.contracts.indexOf("1") === -1);
                })

                // Если выбрана опция "договор отсрочки" то добавляется способ оплаты: наличными с отсрочкой, на карту с отсрочкой
                .filter(i => {
                    return !((i.id == 4 || i.id == 8) && this.entity.contracts.indexOf("2") === -1);
                })

                // Если выбраны опции "Оф. договор" и "договор отсрочки" то добавляется способ оплаты: оплата б/н с отсрочкой
                .filter(i => {
                    return !(i.id == 7 && (this.entity.contracts.indexOf("1") === -1 || this.entity.contracts.indexOf("2") === -1));
                })

                .filter(i => {
                    return this.entity.blocked_payment_methods.indexOf(i.id.toString()) === -1;
                });
        },
        getBlockedPaymentMethods() {
            return this.entity.blocked_payment_methods
                .map(i => {
                    return this.paymentMethods.find(ii => i == ii.id)
                });
        },
        getPaymentMethodsForBlock() {
            return this.paymentMethods
                .filter(i => this.entity.blocked_payment_methods.indexOf(i.id.toString()) === -1);
        },
        isOnlyEntity() {
            if (this.entities.length === 0 && !this.id) {
                return true;
            }

            return !!(this.entities.length === 1 && this.id);
        },
    },
    watch: {
        entity: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
        contracts: {
            handler() {
                if (!this.defaultPaymentMethod) {
                    return;
                }

                let availMethods = this.getPaymentMethods.map(i => i.id.toString());
                if (availMethods.indexOf(this.defaultPaymentMethod.toString()) === -1) {
                    this.defaultPaymentMethod = '';
                }
            },

            deep: true,
        },
    },
    components: {
        'v-list-dialog': ListDialog,
    },
};
</script>