var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-ons-dialog",
        {
          attrs: { visible: _vm.visible, cancelable: "" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
            _c("div", { staticClass: "center" }, [_vm._v("Фильтр по группам")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "v-ons-toolbar-button",
                  { on: { click: _vm.close } },
                  [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "background" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "page-loading",
                staticStyle: { top: "56px", bottom: "0" }
              },
              [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "v-ons-list",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.groups.length > 0,
                      expression: "groups.length > 0"
                    }
                  ],
                  style: {
                    maxHeight: _vm.$window.height - 110 + "px",
                    overflowY: "auto"
                  }
                },
                _vm._l(_vm.groups, function(item) {
                  return _c("v-ons-list-item", { key: item.id }, [
                    _c(
                      "div",
                      {
                        staticClass: "left",
                        staticStyle: {
                          "margin-right": "10px",
                          "min-width": "0"
                        }
                      },
                      [
                        _c("v-ons-checkbox", {
                          attrs: {
                            "input-id": "chk-group-" + item.id,
                            value: item.id
                          },
                          model: {
                            value: _vm.choosed,
                            callback: function($$v) {
                              _vm.choosed = $$v
                            },
                            expression: "choosed"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "label-reset center",
                        attrs: { for: "chk-group-" + item.id }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.name) +
                            "\n                    "
                        )
                      ]
                    )
                  ])
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.groups.length === 0,
                      expression: "groups.length === 0"
                    }
                  ]
                },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "line-height": "216px",
                        "text-align": "center",
                        "font-size": "20px",
                        color: "#a8a8a8"
                      }
                    },
                    [_vm._v("Список пуст")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-ons-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-ons-col",
                    { attrs: { width: "50%" } },
                    [
                      _c(
                        "v-ons-button",
                        {
                          staticStyle: { margin: "5px 0" },
                          attrs: { modifier: "quiet" },
                          on: { click: _vm.reset }
                        },
                        [_vm._v("Сбросить\n                    ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-ons-col",
                    { attrs: { width: "50%" } },
                    [
                      _c(
                        "v-ons-button",
                        {
                          staticStyle: { margin: "5px 0" },
                          on: { click: _vm.choose }
                        },
                        [_vm._v("Выбрать\n                    ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3a98cfa1", { render: render, staticRenderFns: staticRenderFns })
  }
}