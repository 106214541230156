<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Восстановить пароль</div>
        </v-ons-toolbar>

        <div class="login-page" >
            <div v-show="!sended" class="form" >
                <div class="form-group" >
                    <v-ons-input v-model="email" type="text" placeholder="Email" />
                </div>

                <div>
                    <v-ons-button @click="back" >Назад</v-ons-button>
                    <v-ons-button @click="submit" >Продолжить</v-ons-button>
                </div>
            </div>

            <div v-show="sended" class="text-center" >
                <v-ons-card>
                    Вам отправлено письмо с инструкциями по смене пароля.
                </v-ons-card>

                <v-ons-button @click="back" >Ok</v-ons-button>
            </div>
        </div>

        <v-ons-dialog :visible.sync="loading" >
            <v-ons-row vertical-align="center" >
                <v-ons-col width="70px" style="text-align: center"  >
                    <v-ons-progress-circular indeterminate></v-ons-progress-circular>
                </v-ons-col>

                <v-ons-col>
                    <div style="line-height: 100px" >Загрузка...</div>
                </v-ons-col>
            </v-ons-row>
        </v-ons-dialog>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            sended: false,
            email: null,
        }
    },
    methods: {
        submit() {
            if (this.loading) {
                return;
            }

            if (!this.email) {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Укажите Email',
                });
                return;
            }

            this.loading = true;

            this.$http.post('/api/auth/request-reset', {email: this.email}).then(() => {
                this.loading = false;
                this.sended = true;
            }, response => {
                this.loading = false;

                if (response.status === 401) {
                    this.$ons.notification.toast({
                        timeout: 3000,
                        message: response.data.message,
                        buttonLabel: 'OK',
                    });
                    return;
                }

                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Произошла ошибка',
                    buttonLabel: 'OK',
                });
            });
        },
        back() {
            this.$router.go(-1);
        },
    }
};
</script>