var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Закрепить менеджера: " + _vm._s(_vm.user.email))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        _vm._l(_vm.managers, function(manager) {
          return _c(
            "v-ons-list-item",
            {
              key: manager.id,
              staticClass: "cursor-pointer",
              on: {
                click: function($event) {
                  _vm.user.manager_id = manager.id
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      [manager.name, manager.email]
                        .filter(function(i) {
                          return i
                        })
                        .join(" - ")
                    ) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("v-ons-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.user.manager_id == manager.id,
                        expression: "user.manager_id == manager.id"
                      }
                    ],
                    attrs: { icon: "md-check" }
                  })
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-40d435c3", { render: render, staticRenderFns: staticRenderFns })
  }
}