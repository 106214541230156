<script>

import View from './View'
import Create from './Create'
import LoadingPage from '~/component/LoadingPage'

export default {
    data() {
        return {
            id: null,
            loading: true,
            order: null,
        }
    },
    created() {
        this.id = this.$route.params.order_id;

        if (this.id === 'create') {
            this.order = {};
            this.loading = false;
        } else {
            this.load();
        }

        this.$bus.$on('order.create.load', this.load);
    },
    destroyed() {
        this.$bus.$off('order.create.load', this.load);
    },
    methods: {
        load() {
            this.loading = true;

            this.$http.get('/api/orders/' + this.id).then(response => {
                this.$nextTick(() => {
                    this.order = response.data;
                    this.loading = false;
                })
            });
        },
    },
    render(createElement) {
        if (this.loading || this.order === null) {
            return createElement(LoadingPage);
        }

        let params = {
            props: {
                orderProp: this.order,
            }
        }

        if (this.order && (['sended', 'confirmed', 'manager_editing'].includes(this.order.status_id))) {
            return createElement(View, params);
        }

        if (this.order && this.order.status_id === 'generated' && this.$user.isWorker) {
            return createElement(View, params);
        }

        return createElement(Create, params);
    },
};
</script>