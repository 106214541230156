<template>
    <v-ons-toolbar-button :id="'show-popover-menu-btn-' + id" @click="popoverMenuVisible = true" title="Дополнительные функции" >
        <v-ons-icon icon="md-more-vert"></v-ons-icon>

        <v-ons-popover cancelable
            :visible.sync="popoverMenuVisible"
            :target="'#show-popover-menu-btn-' + id"
            cover-target="true"
            direction="down"
            :class="'popover-menu ' + popoverClass"
        >
            <ons-list @click="close" >
                <slot></slot>
            </ons-list>
        </v-ons-popover>
    </v-ons-toolbar-button>
</template>

<script>
export default {
    props: {
        popoverClass: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            id: null,
            popoverMenuVisible: false,
        }
    },
    created() {
        if (window._toolbarPopoverMenuId === undefined) {
            window._toolbarPopoverMenuId = 0;
        }

        this.id = ++window._toolbarPopoverMenuId;
    },
    methods: {
        close() {
            this.popoverMenuVisible = false;
        }
    }
};
</script>
