var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "order-index" },
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasChecked,
              expression: "!hasChecked"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [_c("v-ons-toggle-sidebar-button")],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [_vm._v("Заказы")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { title: "Опции просмотра" },
                  on: { click: _vm.showViewParamsModal }
                },
                [
                  _c("v-ons-icon", {
                    attrs: { icon: "md-format-list-bulleted" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { title: "Обновить" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update")
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-refresh" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChecked,
              expression: "hasChecked"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.uncheckAll } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v("Выбрано: " + _vm._s(_vm.checkedCount))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { title: "Удалить" },
                  on: { click: _vm.deleteOrders }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        !_vm.failed
          ? _c(
              "div",
              [
                _c("v-discount-info"),
                _vm._v(" "),
                _c(
                  "ons-list",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.orders,
                        expression: "orders"
                      }
                    ]
                  },
                  _vm._l(_vm.orders, function(order) {
                    return _c(
                      "ons-list-item",
                      {
                        key: order.id,
                        class: ["order-item", order.isChecked ? "checked" : ""],
                        attrs: { modifier: "chevron longdivider" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left",
                            staticStyle: {
                              "min-width": "56px",
                              "padding-left": "16px",
                              "text-align": "center"
                            },
                            on: {
                              click: function($event) {
                                return _vm.checkOrder(order)
                              }
                            }
                          },
                          [
                            _c("v-ons-icon", {
                              staticClass: "list-item__icon",
                              style: _vm.orderStatus(order).style,
                              attrs: {
                                icon: _vm.orderStatus(order).icon,
                                title: _vm.orderStatus(order).title
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "center cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.$router.push("order/" + order.id)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "list-item__title" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.orderTitle(order)) +
                                  "\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "list-item__subtitle" }, [
                              _vm._v(
                                "\n                        Статус: " +
                                  _vm._s(order.status) +
                                  "\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("v-list-item-subtitles", {
                              attrs: {
                                item: order,
                                fields: _vm.$user.getOrderSubtitles(),
                                selected: _vm.$user.orderSelectedSubtitles
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.page < _vm.pageCount,
                        expression: "page < pageCount"
                      }
                    ],
                    staticStyle: { "text-align": "center", margin: "10px 0" }
                  },
                  [
                    _c(
                      "v-ons-button",
                      {
                        staticStyle: { "min-width": "145px" },
                        on: { click: _vm.loadMore }
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadingMore,
                                expression: "!loadingMore"
                              }
                            ]
                          },
                          [_vm._v("Показать ещё")]
                        ),
                        _vm._v(" "),
                        _c("v-ons-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loadingMore,
                              expression: "loadingMore"
                            }
                          ],
                          attrs: { size: "30px", spin: "", icon: "md-spinner" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.orders.length === 0,
                        expression: "orders.length === 0"
                      }
                    ],
                    staticClass: "list-empty",
                    style: { height: _vm.$window.height - 56 + "px" }
                  },
                  [
                    _vm._v(
                      "\n                Еще нет ни одного заказа\n            "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.failed,
                expression: "failed"
              }
            ],
            staticClass: "page-failed",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [
            _c("div", { staticClass: "message" }, [
              _vm._v("Произошла ошибка.")
            ]),
            _vm._v(" "),
            _c(
              "v-ons-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$emit("update")
                  }
                }
              },
              [_vm._v("\n                Повторить\n            ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "viewParamsModal",
        attrs: { multiple: "true", title: "Опции просмотра" }
      }),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          attrs: { position: "bottom right", title: "Создать заказ" },
          on: {
            click: function($event) {
              return _vm.$router.push("order/create")
            }
          }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5af0e2e0", { render: render, staticRenderFns: staticRenderFns })
  }
}