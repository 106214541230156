<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Смена пароля</div>
            <div class="right">
                <v-ons-toolbar-button :disabled="!canSended" @click="send" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-header>Текущий пароль</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="password" type="password" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-item></v-ons-list-item>

            <v-ons-list-header>Новый пароль</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="newPassword" type="password"  ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            password: null,
            newPassword: null,
        }
    },
    methods: {
        send() {
            this.$http.post('/api/settings/change-password', {password: this.password, newPassword: this.newPassword}).then(response => {
                this.$router.go(-1);

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Пароль изменен',
                    buttonLabel: 'OK',
                });
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                    buttonLabel: 'OK',
                });
            });
        },
    },
    computed: {
        canSended() {
            return this.password && this.newPassword;
        }
    },
};
</script>
