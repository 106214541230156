<template>
    <v-ons-page class="management-shipping-by-routes" >
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">График приемки заказов и доставки по маршрутам</div>

            <div class="right" style="min-width: 100px;" >
                <v-ons-toolbar-button @click="addRoute" >
                    <v-ons-icon icon="md-plus" ></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" v-if="items.length > 0">
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <table class="table" v-if="items.length > 0">
                <div v-frag v-for="(item, key) in items" :key="key" >
                    <thead>
                        <tr>
                            <th @click="item.menu.opened = true" class="cursor-pointer">
                                <v-ons-icon
                                    icon="md-more-vert"
                                    :id="'shipping-by-routes-page-popover-menu-' + key"
                                    style="font-size: 21px; vertical-align: middle; margin: 0 10px 0 10px;">
                                </v-ons-icon>

                                <v-ons-popover cancelable
                                    :visible.sync="item.menu.opened"
                                    :target="'#shipping-by-routes-page-popover-menu-' + key"
                                    cover-target="true"
                                    direction="down"
                                    :class="{
                                        'shipping-by-routes-page-popover-menu': true,
                                        'is-desktop': $window.isDesktop,
                                    }"
                                >
                                    <ons-list @click="item.menu.opened = false;" >
                                        <ons-list-item @click="viewRoute(item.route)" >
                                            <div class="left icon">
                                                <v-ons-icon icon="md-assignment"></v-ons-icon>
                                            </div>
                                            <div class="center">Просмотр</div>
                                        </ons-list-item>
                                    </ons-list>

                                    <ons-list v-if="!item.route.is_pickup" @click="item.menu.opened = false;" >
                                        <ons-list-item @click="renameRoute(item.route)" >
                                            <div class="left icon">
                                                <v-ons-icon icon="md-edit"></v-ons-icon>
                                            </div>
                                            <div class="center">Переименовать</div>
                                        </ons-list-item>
                                    </ons-list>

                                    <ons-list v-if="!item.route.is_pickup"  @click="item.menu.opened = false;" >
                                        <ons-list-item @click="removeRoute(item.route)" >
                                            <div class="left icon">
                                                <v-ons-icon icon="md-delete"></v-ons-icon>
                                            </div>
                                            <div class="center">Удалить</div>
                                        </ons-list-item>
                                    </ons-list>
                                </v-ons-popover>

                                {{ item.route.name }}
                            </th>
                            <th class="text-center" >
                                <span v-if="item.route.name === 'Самовывоз'" >Отгрузка</span>
                                <span v-else >Доставка</span>
                            </th>
                            <th>День заказа</th>
                            <th class="text-center" >До</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="weekday in item.route.schedule" >
                            <td>
                                {{ weekday.name }}
                            </td>

                            <td class="text-center" >
                                <input type="checkbox" v-model="weekday.hasDelivery" style="width: 15px; height: 15px;" />
                            </td>

                            <td class="text-center" >
                                <v-ons-select v-model="weekday.beforeDay" >
                                    <option v-for="w in weekdays" :value="w.id" >
                                        {{ w.name }}
                                    </option>
                                </v-ons-select>
                            </td>

                            <td class="text-center" >
                                <input type="time" v-model="weekday.beforeTime" style="text-align: center; margin: 0 auto; max-width: 100px"/>
                            </td>
                        </tr>
                        <tr v-if="!item.route.is_pickup">
                            <td colspan="4" >
                                <div @click="toggleExtraBlock(item.route.id)" class="cursor-pointer" >
                                    <v-ons-icon
                                        :icon="extraBlockUnfold.includes(item.route.id) ? 'md-chevron-down' : 'md-chevron-right'"
                                        style="font-size: 25px; vertical-align: middle; width: 16px; text-align: center;"
                                        ></v-ons-icon>

                                    <label
                                        :for="'extraBlockLabel-' + item.route.id"
                                        class="label-reset"
                                        style="vertical-align: middle; margin-left: 10px;"
                                    >Дополнительные условия</label>
                                </div>

                                <div v-show="extraBlockUnfold.includes(item.route.id)" >
                                    <table class="table" >
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th class="text-center" >Сумма</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Бесплатная доставка от</td>
                                                <td class="text-center" >
                                                    <input type="number" class="without-arrow text-center" style="width: 70px" v-model="item.route.free_delivery_from" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Стоимость доставки</td>
                                                <td class="text-center" >
                                                    <input type="number" class="without-arrow text-center" style="width: 70px" v-model="item.route.delivery_cost" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Отказ от доставки до</td>
                                                <td class="text-center" >
                                                    <input type="number" class="without-arrow text-center" style="width: 70px" v-model="item.route.refusal_delivery_to" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" >
                                <br><br><br>
                            </td>
                        </tr>
                    </tbody>
                </div>
            </table>

            <div v-show="items.length === 0" class="list-empty"
                 :style="{height: $window.height - 56 + 'px', flexDirection: 'column'}">
                <div>Ничего не найдено</div>
            </div>
        </div>

        <route-view-modal ref="routeViewModal" />
    </v-ons-page>
</template>

<script>

import RouteViewModal from './RouteViewModal'

export default {
    data() {
        return {
            loading: true,
            changed: false,
            loaded: false,
            weekdays: [],
            routes: [],
            popoverMenus: {},
            extraBlockUnfold: [],
        }
    },
    created() {
        this.load();
    },
    methods: {
        viewRoute(route) {
            this.$refs.routeViewModal.show(route);
        },
        renameRoute(route) {
            this.$ons.notification.prompt('Название', {
                title: 'Переименовать маршрут',
                defaultValue: route.name,
                buttonLabels: ['Отмена', 'OK']
            }).then(name => {
                let oldName = route.name;

                if (!name || name === oldName) {
                    return;
                }

                route.name = name;

                this.$http.post(
                    '/api/manager/management/rename-route',
                    {
                        route_id: route.id,
                        storage_id: this.$route.params.storage_id,
                        name: name,
                    }
                ).then(() => {

                }, () => {
                    route.name = oldName;

                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        removeRoute(route) {
            this.$ons.notification.confirm('Удалить маршрут?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.post(
                    '/api/manager/management/remove-route',
                    {
                        route_id: route.id,
                        storage_id: this.$route.params.storage_id,
                    }
                ).then(() => {
                    let index = this.routes.findIndex(r => r.id == route.id);
                    this.routes.splice(index, 1);

                    this.$ons.notification.toast({
                        timeout: 2000,
                        message: 'Маршрут удалён',
                        buttonLabel: 'OK',
                    });
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        load() {
            this.$http.get(
                '/api/manager/management/shipping-by-routes',
                {params: {storageId: this.$route.params.storage_id}}
            ).then(response => {
                this.routes = response.data.routes
                this.weekdays = response.data.weekdays;
                this.routes.forEach(route => {
                    this.$set(this.popoverMenus, route.id, {opened: false})
                })

                this.$nextTick(() => {
                    this.loading = false;
                    this.loaded = true;
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        save() {
            this.$http.post(
                '/api/manager/management/shipping-by-routes',
                {routes: this.routes},
                {params: {storageId: this.$route.params.storage_id}}
        ).then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });
                this.$router.go(-1);
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                });
            });
        },
        addRoute() {
            this.$ons.notification.prompt('Название', {
                title: 'Добавить новый маршрут',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (!response) {
                    return;
                }

                let route = {
                    name: response,
                    storage_id: this.$route.params.storage_id,
                }

                this.$http.post(
                    '/api/routes',
                    route,
                    {params: {storageId: this.$route.params.storage_id}}
                ).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Маршрут добавлен',
                        buttonLabel: 'OK',
                    });

                    this.load();
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        toggleExtraBlock(id) {
            if (this.extraBlockUnfold.includes(id)) {
                let index = this.extraBlockUnfold.indexOf(id);
                this.extraBlockUnfold.splice(index, 1);
            } else {
                this.extraBlockUnfold.push(id);
            }
        },
    },
    computed: {
        items() {
            let items = [];
            this.routes.forEach(route => {
                items.push({
                    route: route,
                    menu: this.popoverMenus[route.id],
                })
            });

            return items;
        }
    },
    watch: {
        routes: {
            handler() {
                this.routes.forEach(route => {
                    route.schedule.forEach(day => {
                        if (!day.hasDelivery) {
                            day.beforeDay = null;
                            day.beforeTime = null;
                        }
                    });
                });

                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        }
    },
    components: {
        RouteViewModal,
    },
};
</script>