var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-list-item",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.item.isHidden,
          expression: "!item.isHidden"
        }
      ],
      staticClass: "list-item-product"
    },
    [
      _vm.actionColType === "checkbox"
        ? _c("label", { staticClass: "col-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isChecked,
                  expression: "isChecked"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.isChecked)
                  ? _vm._i(_vm.isChecked, null) > -1
                  : _vm.isChecked
              },
              on: {
                change: function($event) {
                  var $$a = _vm.isChecked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isChecked = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isChecked = $$c
                  }
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionColType === "favor" ||
      (_vm.actionColType === "favor-tiny" && _vm.isDesktop)
        ? _c(
            "label",
            {
              staticClass: "col-checkbox cursor-pointer",
              on: { click: _vm.toggleFavor }
            },
            [
              _c("v-ons-icon", {
                staticClass: "icon",
                style: {
                  color: _vm.item.isInMyProducts ? "#ffe000" : "#e3e3e3"
                },
                attrs: {
                  icon: "md-star",
                  title: _vm.favorButtonTitle,
                  size: "20px"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: ["col-name", _vm.canOpenProductPage ? "cursor-pointer" : ""],
          on: { click: _vm.clickGoToView }
        },
        [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n            " + _vm._s(_vm.item.name) + "\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-icons" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.item.is_special,
                      expression: "item.is_special"
                    }
                  ],
                  staticClass: "vip-icon icon"
                },
                [_vm._v("\n                VIP\n            ")]
              ),
              _vm._v(" "),
              _c("v-ons-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.actionColType === "favor-tiny" &&
                      _vm.item.isInMyProducts &&
                      !_vm.isDesktop,
                    expression:
                      "actionColType === 'favor-tiny' && item.isInMyProducts && !isDesktop"
                  }
                ],
                staticClass: "star-icon icon",
                attrs: {
                  icon: "md-star",
                  title: 'Товар добавлен в "Мои товары"'
                }
              }),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.item.images && _vm.item.images.thumb100.hasImage,
                    expression: "item.images && item.images.thumb100.hasImage"
                  }
                ],
                staticClass: "has-image-icon icon",
                attrs: { title: "У этого товара есть фото" }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isDesktop && _vm.shouldShowRest,
              expression: "isDesktop && shouldShowRest"
            }
          ],
          staticClass: "col-rest"
        },
        [
          _vm.item.is_wait
            ? _c("span", [_vm._v("0 " + _vm._s(_vm.item.unit))])
            : _c("span", [
                _vm._v(_vm._s(_vm.item.rest) + " " + _vm._s(_vm.item.unit))
              ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$user.canSeePrice,
              expression: "$user.canSeePrice"
            }
          ],
          class: [
            "col-price",
            _vm.item.specialPrice.isShowMark ? "is-low-special-price" : ""
          ]
        },
        [
          _c("div", { staticClass: "price-block text-right" }, [
            _vm.item.is_wait
              ? _c("span", [_vm._v("ждём")])
              : _c("span", [_vm._v(_vm._s(_vm.calcPrice(_vm.item)) + " р.")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.item.is_deleted,
                    expression: "!item.is_deleted"
                  }
                ]
              },
              [_vm._v(_vm._s(_vm.item.unit))]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shouldShowOverweightRestAlert,
                    expression: "shouldShowOverweightRestAlert"
                  }
                ],
                staticClass: "notify-icon",
                on: {
                  click: function($event) {
                    return _vm.showOverweightRestAlert(_vm.item)
                  }
                }
              },
              [_vm._v("\n                !\n            ")]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isDesktop && _vm.shouldShowRest,
                  expression: "!isDesktop && shouldShowRest"
                }
              ],
              staticClass: "text-left",
              staticStyle: { "font-size": "10px", color: "grey" }
            },
            [
              _vm.item.is_wait
                ? _c("span", [_vm._v("0 " + _vm._s(_vm.item.unit))])
                : _c("span", [
                    _vm._v(_vm._s(_vm.item.rest) + " " + _vm._s(_vm.item.unit))
                  ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.allowInputs && (_vm.showInputs || _vm.isDesktop)
        ? _c("div", { staticClass: "col-count" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.counts[_vm.item.id],
                  expression: "counts[item.id]"
                },
                { name: "round", rawName: "v-round" }
              ],
              attrs: {
                type: "number",
                id: "position-count-" + _vm.item.id,
                step: _vm.item.mult
              },
              domProps: { value: _vm.counts[_vm.item.id] },
              on: {
                keydown: _vm.countKeydownHandler,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.counts, _vm.item.id, $event.target.value)
                }
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2964ceaf", { render: render, staticRenderFns: staticRenderFns })
  }
}