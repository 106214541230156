import Index from '~/screen/manager/management/Index';
import ShippingPaymentMethods from '~/screen/manager/management/ShippingPaymentMethods';
import ShippingByRoutes from '~/screen/manager/management/ShippingByRoutes';
import PickupShipmentSchedule from '~/screen/manager/management/PickupShipmentSchedule';
import SpecialSchedules from '~/screen/manager/management/SpecialSchedules';
import SpecialSchedule from '~/screen/manager/management/SpecialSchedule';

import CatalogSettings from './CatalogSettings';

let route = [
    {
        path: 'management',
        name: 'manager-management-index',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'storage-:storage_id',
                name: 'manager-management-index-storage',
                component: Index,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'shipping-payment-methods',
                        name: 'manager-management-shipping-payment-methods',
                        component: ShippingPaymentMethods,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                    {
                        path: 'shipping-by-routes',
                        name: 'manager-management-shipping-by-routes',
                        component: ShippingByRoutes,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                    {
                        path: 'pickup-shipment-schedule',
                        name: 'manager-management-pickup-shipment-schedule',
                        component: PickupShipmentSchedule,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                    },
                    {
                        path: 'special-schedules',
                        name: 'manager-management-special-schedules',
                        component: SpecialSchedules,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                        children: [
                            {
                                path: 'create',
                                name: 'manager-management-special-schedules-create',
                                component: SpecialSchedule,
                                meta: {
                                    requiresLogin: true,
                                    requiredPermissions: ['manager'],
                                },
                            },
                            {
                                path: ':id',
                                name: 'manager-management-special-schedule-view',
                                component: SpecialSchedule,
                                meta: {
                                    requiresLogin: true,
                                    requiredPermissions: ['manager'],
                                },
                            },
                        ]
                    },
                    ...CatalogSettings,

                ]
            },
        ],
    },
];

export default route;