<template>
    <v-ons-page>
        <div v-show="!templates.length" class="content" >
            <div style="font-size: 20px; color: #a8a8a8; position: absolute; top: 50%; text-align: center; width: 100%; line-height: 50px; margin-top: -25px;" >
                Список пуст
            </div>
        </div>

        <v-ons-list v-show="templates.length > 0" >
            <v-ons-list-item v-for="template in templates" :key="template.id" class="cursor-pointer" >
                <div class="center" @click="$router.push($router.currentRoute.path + '/template/'+template.id)" >
                    {{ template.name }}
                </div>
                <div class="right" >
                    <v-ons-icon
                        @click="deleteTemplate(template)"
                        icon="md-delete"
                        class="list-item__icon"
                        style="margin: 0 4px; cursor: pointer"
                    ></v-ons-icon>

                    <v-ons-icon
                        @click="showRenameTemplateDialog(template)"
                        icon="md-edit"
                        class="list-item__icon"
                        style="margin: 0 4px; cursor: pointer"
                    ></v-ons-icon>
                </div>
            </v-ons-list-item>
        </v-ons-list>

    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            templates: [],
            entrypoint: '/api/user-products',
        }
    },
    created() {
        if (this.$route.meta.userProductEntrypoint) {
            let userProductEntrypoint = this.$route.meta.userProductEntrypoint;
            this.entrypoint = userProductEntrypoint.replace('$userId', this.$route.params.user_id)
        }

        this.loadTemplates();
        this.$bus.$on('templateTab.loadTemplates', () => this.loadTemplates());
    },
    destroyed() {
        this.$bus.$off('templateTab.loadTemplates');
    },
    methods: {
        loadTemplates() {
            this.$http.get(this.entrypoint + '/templates').then(response => {
                this.templates = response.body;
            }, () => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        deleteTemplate(template) {
            this.$ons.notification.confirm('Удалить шаблон?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.post(`${this.entrypoint}/templates/${template.id}/delete`).then(() => {
                    this.loadTemplates();
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        showRenameTemplateDialog(template) {
            this.$ons.notification.prompt('Название', {
                title: 'Переименовать шаблон',
                defaultValue: template.name,
                buttonLabels: ['Отмена', 'OK']
            }).then(name => {
                if (!name || name === template.name) {
                    return;
                }

                this.$http.post(`${this.entrypoint}/templates/${template.id}/rename`, {name: name}).then(() => {
                    this.loadTemplates();
                });
            });
        },
    }
};
</script>