var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Создать аккаунт")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$router.push("/login")
                  }
                }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-sign-in" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "register-page" },
        [
          _c(
            "v-ons-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.push("/")
                }
              }
            },
            [_vm._v("Посмотреть каталог")]
          ),
          _vm._v(" "),
          _c("v-ons-card", { staticClass: "info-block" }, [
            _vm._v(
              "\n            Все актуальные цены будут доступны сразу после ввода почты и пароля.\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("v-ons-input", {
                  attrs: { name: "email", type: "text", placeholder: "Email" },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("v-ons-password", {
                  attrs: { name: "password", placeholder: "Пароль" },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-submit" },
              [
                _c("v-ons-button", { on: { click: _vm.submit } }, [
                  _vm._v("Создать аккаунт")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "40px", "text-align": "center" } },
              [
                _c(
                  "v-ons-button",
                  {
                    attrs: { modifier: "outline" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push({
                          name: "forgot",
                          params: { email: _vm.email }
                        })
                      }
                    }
                  },
                  [_vm._v("Восстановить пароль")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        {
          attrs: { visible: _vm.loading },
          on: {
            "update:visible": function($event) {
              _vm.loading = $event
            }
          }
        },
        [
          _c(
            "v-ons-row",
            { attrs: { "vertical-align": "center" } },
            [
              _c(
                "v-ons-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { width: "70px" }
                },
                [
                  _c("v-ons-progress-circular", {
                    attrs: { indeterminate: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-col", [
                _c("div", { staticStyle: { "line-height": "100px" } }, [
                  _vm._v("Авторизация...")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6e604b3d", { render: render, staticRenderFns: staticRenderFns })
  }
}