var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Ваш менеджер")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$router.push("/mymanager/routes")
                  }
                }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-calendar-note" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _vm.manager.name
            ? _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v("Имя")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v(_vm._s(_vm.manager.name))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.manager.email
            ? _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c(
                  "a",
                  {
                    staticClass: "center",
                    staticStyle: { color: "#1f1f21" },
                    attrs: { href: "mailto:" + _vm.manager.email }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "list-item__title",
                        staticStyle: { "text-decoration": "underline" }
                      },
                      [_vm._v(_vm._s(_vm.manager.email))]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "list-item__subtitle" }, [
                      _vm._v("Email")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "right cursor-pointer",
                    staticStyle: { margin: "0 5px" },
                    on: {
                      click: function($event) {
                        return _vm.copyValue(_vm.manager.email)
                      }
                    }
                  },
                  [
                    _c("v-ons-icon", {
                      attrs: { icon: "md-copy", size: "20px" }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.manager.phone
            ? _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c(
                  "a",
                  {
                    staticClass: "center",
                    staticStyle: { color: "#1f1f21" },
                    attrs: { href: "tel:" + _vm.manager.phone }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "list-item__title",
                        staticStyle: { "text-decoration": "underline" }
                      },
                      [_vm._v(_vm._s(_vm.manager.phone))]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "list-item__subtitle" }, [
                      _vm._v("Телефон")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "right cursor-pointer" }, [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.whatsappLink,
                          expression: "whatsappLink"
                        }
                      ],
                      staticStyle: {
                        "line-height": "40px",
                        width: "40px",
                        "text-align": "center",
                        margin: "0 5px"
                      },
                      attrs: { href: _vm.whatsappLink }
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "25",
                          src: "/img/whatsapp.svg",
                          alt: "whatsapp"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.viberLink,
                          expression: "viberLink"
                        }
                      ],
                      staticStyle: {
                        "line-height": "40px",
                        width: "40px",
                        "text-align": "center",
                        margin: "0 5px"
                      },
                      attrs: { href: _vm.viberLink }
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "25",
                          src: "/img/viber.svg",
                          alt: "viber"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { "line-height": "40px", margin: "0 5px" },
                      on: {
                        click: function($event) {
                          return _vm.copyValue(_vm.manager.phone)
                        }
                      }
                    },
                    [
                      _c("v-ons-icon", {
                        attrs: { icon: "md-copy", size: "20px" }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.manager.organisation
            ? _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(_vm._s(_vm.manager.organisation))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v("Фактическое наименование компании")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.manager.office_address
            ? _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(_vm._s(_vm.manager.office_address))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v("Адрес офиса")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.manager.office_work_schedule
            ? _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(_vm._s(_vm.manager.office_work_schedule))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v("График работы")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.manager.storages && _vm.manager.storages.length > 0
            ? _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c(
                  "span",
                  { staticClass: "list-item__title" },
                  _vm._l(_vm.manager.storages, function(storage) {
                    return _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-bottom": "10px",
                          display: "block"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(storage.name) +
                            "\n                    "
                        ),
                        _c("br")
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v("Адрес склада")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.manager.about_company
            ? _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(_vm._s(_vm.manager.about_company))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v("О компании")
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-49a3b737", { render: render, staticRenderFns: staticRenderFns })
  }
}