var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasChecked,
              expression: "!hasChecked"
            }
          ],
          attrs: { modifier: "noshadow" }
        },
        [
          _vm.showBackButton
            ? _c(
                "div",
                { staticClass: "left" },
                [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
                1
              )
            : _c(
                "div",
                { staticClass: "left" },
                [_c("v-ons-toggle-sidebar-button")],
                1
              ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [_vm._v("Мои товары")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeIndex === 1,
                      expression: "activeIndex === 1"
                    }
                  ],
                  attrs: { title: "Добавить шаблон" },
                  on: { click: _vm.showNewTemplateDialog }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.activeIndex === 0 &&
                        _vm.displayedButtons.includes("createOrder"),
                      expression:
                        "activeIndex === 0 && displayedButtons.includes('createOrder')"
                    }
                  ],
                  attrs: {
                    title: "Создать заказ",
                    disabled: !_vm.canCreateOrder
                  },
                  on: { click: _vm.createOrder }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-shopping-cart" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.activeIndex === 0 &&
                        _vm.displayedButtons.includes("showGroups"),
                      expression:
                        "activeIndex === 0 && displayedButtons.includes('showGroups')"
                    }
                  ],
                  attrs: { title: "Каталог", disabled: !_vm.canOpenCatalog },
                  on: { click: _vm.openCatalog }
                },
                [_c("span", { staticClass: "font-rouble" }, [_vm._v("o")])]
              ),
              _vm._v(" "),
              _c(
                "toolbar-popover-menu",
                { attrs: { "popover-class": "stick-right" } },
                [
                  _vm.displayedButtons.includes("showGroups")
                    ? _c("toolbar-popover-menu-item", {
                        attrs: { text: "Группы товаров", icon: "md-storage" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.groupsModal.show()
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayedButtons.includes("showHistory")
                    ? _c("toolbar-popover-menu-item", {
                        attrs: {
                          text: "История",
                          icon: "md-time-restore-setting"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push(
                              _vm.$router.currentRoute.path + "/history"
                            )
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$user.isManager
                    ? _c(
                        "ons-list-item",
                        { on: { click: _vm.showAlwaysAvailableModal } },
                        [
                          _c(
                            "div",
                            { staticClass: "left icon" },
                            [
                              _c("v-ons-icon", {
                                style: {
                                  color: _vm.user.rest_always_available
                                    ? "#ffbb3d"
                                    : "#8a8a8a"
                                },
                                attrs: { icon: "md-shopping-cart-plus" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "center" }, [
                            _vm._v("Всё в наличии")
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChecked,
              expression: "hasChecked"
            }
          ],
          attrs: { modifier: "noshadow" }
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.uncheckAll } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeIndex === 0,
                      expression: "activeIndex === 0"
                    }
                  ],
                  attrs: { title: "Переместить в группу" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.moveToGroupModal.move()
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-storage" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeIndex === 0,
                      expression: "activeIndex === 0"
                    }
                  ],
                  attrs: { title: "Добавить в шаблон" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.moveToTemplateModal.show()
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-group" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { title: "Удалить" },
                  on: { click: _vm.removeChecked }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("v-ons-tabbar", {
        attrs: {
          position: "auto",
          tabs: _vm.tabs,
          visible: true,
          index: _vm.activeIndex
        },
        on: {
          "update:index": function($event) {
            _vm.activeIndex = $event
          },
          prechange: _vm.rechangeTab,
          checked: function($event) {
            _vm.hasChecked = true
          },
          unchecked: function($event) {
            _vm.hasChecked = false
          },
          positionsCountUpdate: _vm.onPositionsCountUpdate
        }
      }),
      _vm._v(" "),
      _c("move-to-group-modal", {
        ref: "moveToGroupModal",
        attrs: { title: "Переместить в" },
        on: {
          renameGroup: _vm.renameGroup,
          deleteGroup: _vm.deleteGroup,
          update: function($event) {
            return _vm.$bus.$emit("productTab.updatePositions")
          },
          choose: _vm.moveToGroup
        }
      }),
      _vm._v(" "),
      _c("move-to-group-modal", {
        ref: "groupsModal",
        attrs: { title: "Группы товаров" },
        on: {
          renameGroup: _vm.renameGroup,
          deleteGroup: _vm.deleteGroup,
          update: function($event) {
            return _vm.$bus.$emit("productTab.updatePositions")
          }
        }
      }),
      _vm._v(" "),
      _c("templates-modal", {
        ref: "moveToTemplateModal",
        attrs: { title: "Добавить в шаблон" },
        on: { choose: _vm.moveToTemplate }
      }),
      _vm._v(" "),
      _c("always-available-modal", { ref: "alwaysAvailableModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-11e2d0c3", { render: render, staticRenderFns: staticRenderFns })
  }
}