var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { visible: _vm.visible, cancelable: "" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "center" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Отмена" }, on: { click: _vm.close } },
              [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            staticStyle: { top: "56px", bottom: "0" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.templates.length > 0,
                  expression: "templates.length > 0"
                }
              ],
              style: {
                maxHeight:
                  _vm.$window.height -
                  (_vm.allowSelectGroup ? 240 : 110) +
                  "px",
                overflowY: "auto"
              }
            },
            _vm._l(_vm.templates, function(template) {
              return _c(
                "div",
                { key: template.id },
                [
                  _c(
                    "v-ons-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.onClickGroup(template)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "left",
                          staticStyle: {
                            "margin-right": "10px",
                            "min-width": "0"
                          }
                        },
                        [
                          _c("v-ons-radio", {
                            attrs: {
                              "input-id":
                                "asTmp-" + _vm.groupId + "radio-" + template.id,
                              value: template.id
                            },
                            model: {
                              value: _vm.selectedTemplate,
                              callback: function($$v) {
                                _vm.selectedTemplate = $$v
                              },
                              expression: "selectedTemplate"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "label-reset center",
                          attrs: {
                            for: "asTmp-" + _vm.groupId + "radio-" + template.id
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(template.name) +
                              "\n                    "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  template.id === "myproducts" && template.groups.length > 0
                    ? _c(
                        "div",
                        { staticStyle: { "margin-left": "27px" } },
                        _vm._l(template.groups, function(group) {
                          return _c("v-ons-list-item", { key: group.id }, [
                            _c(
                              "div",
                              {
                                staticClass: "left",
                                staticStyle: {
                                  "margin-right": "10px",
                                  "min-width": "0"
                                }
                              },
                              [
                                _c("v-ons-checkbox", {
                                  attrs: {
                                    "input-id":
                                      "asTmp-" +
                                      _vm.groupId +
                                      "radio-" +
                                      group.id,
                                    value: group.id,
                                    disabled:
                                      _vm.selectedTemplate !== "myproducts"
                                  },
                                  model: {
                                    value: _vm.selectedGroups,
                                    callback: function($$v) {
                                      _vm.selectedGroups = $$v
                                    },
                                    expression: "selectedGroups"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "label-reset center",
                                attrs: {
                                  for:
                                    "asTmp-" + _vm.groupId + "radio-" + group.id
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(group.name) +
                                    "\n                        "
                                )
                              ]
                            )
                          ])
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.templates.length === 0,
                  expression: "templates.length === 0"
                }
              ]
            },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "line-height": "216px",
                    "text-align": "center",
                    "font-size": "20px",
                    color: "#a8a8a8"
                  }
                },
                [_vm._v("Список пуст")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.allowSelectGroup,
                  expression: "allowSelectGroup"
                }
              ]
            },
            [
              _c("v-ons-list-header", [_vm._v("Группировать по:")]),
              _vm._v(" "),
              _vm._l(_vm.typesGrouping, function(label, type) {
                return _c("v-ons-list-item", { key: type }, [
                  _c(
                    "div",
                    {
                      staticClass: "left",
                      staticStyle: { "margin-right": "10px", "min-width": "0" }
                    },
                    [
                      _c("v-ons-radio", {
                        attrs: {
                          "input-id": "tmp-" + _vm.groupId + "-radio-" + type,
                          value: type,
                          disabled:
                            !_vm.selectedTemplate && type === "byUserGroup"
                        },
                        model: {
                          value: _vm.selectedGroupBy,
                          callback: function($$v) {
                            _vm.selectedGroupBy = $$v
                          },
                          expression: "selectedGroupBy"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "label-reset center",
                      attrs: { for: "tmp-" + _vm.groupId + "-radio-" + type }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(label) +
                          "\n                "
                      )
                    ]
                  )
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-ons-row",
            { staticClass: "text-center" },
            [
              _c(
                "v-ons-col",
                { attrs: { width: "50%" } },
                [
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { margin: "5px 0" },
                      attrs: { modifier: "quiet" },
                      on: { click: _vm.uncheckAll }
                    },
                    [_vm._v("Сбросить\n                ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-col",
                { attrs: { width: "50%" } },
                [
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { margin: "5px 0" },
                      on: { click: _vm.choose }
                    },
                    [_vm._v("Выбрать\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d546e92e", { render: render, staticRenderFns: staticRenderFns })
  }
}