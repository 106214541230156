var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.blurHandler,
          expression: "blurHandler"
        }
      ],
      staticClass: "input-proposal-wrap with-md-plus-icon"
    },
    [
      _c(
        "form",
        {
          attrs: { action: "", autocomplete: "off" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return function() {}.apply(null, arguments)
            }
          }
        },
        [
          _c("v-ons-icon", { attrs: { icon: "md-plus" } }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            ref: "input",
            staticClass: "input-proposal",
            attrs: {
              type: "text",
              autocomplete: "off",
              spellcheck: "false",
              name: "input-proposal",
              placeholder: "Добавить товар",
              disabled: _vm.inputDisabled || _vm.disabled
            },
            domProps: { value: _vm.name },
            on: {
              keydown: _vm.keydownHandler,
              keyup: _vm.keyupHandler,
              focus: _vm.focusHandler,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isVisibleProposals && !_vm.isLoading,
              expression: "isVisibleProposals && !isLoading"
            }
          ],
          staticClass: "clear-input-button",
          on: { click: _vm.clear }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          staticClass: "input-progress-circular"
        },
        [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
        1
      ),
      _vm._v(" "),
      _vm.proposals.length > 0 && _vm.isVisibleProposals
        ? _c("div", { staticClass: "proposals-outer" }, [
            _c(
              "div",
              { staticClass: "proposals-wrap" },
              _vm._l(_vm.proposals, function(proposal, proposalIndex) {
                return _c(
                  "div",
                  {
                    staticClass: "proposal-wrap wrap",
                    class: {
                      selected: proposalIndex == _vm.selectedProposal,
                      added: _vm.isAdded(proposal)
                    },
                    style: proposal.isCustom ? "color: grey" : "",
                    attrs: { id: "proposal-" + proposalIndex },
                    on: {
                      click: function($event) {
                        return _vm.chooseProposal(proposal)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowColumn("name"),
                            expression: "isShowColumn('name')"
                          }
                        ],
                        staticClass: "col col-name"
                      },
                      [
                        _c(
                          "div",
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(proposal.name) +
                                "\n\n                        "
                            ),
                            _vm.isAdded(proposal)
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-top": "5px" } },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        "Товар уже добавлен, строка " +
                                          _vm._s(
                                            _vm.addedPositions.find(function(
                                              p
                                            ) {
                                              return proposal.id == p.id
                                            }).index
                                          )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            proposal.isCustom
                              ? _c("span", [
                                  _vm._v(" (пользовательский товар)")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("v-ons-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: proposal.isInMyProducts,
                                  expression: "proposal.isInMyProducts"
                                }
                              ],
                              staticClass: "star-icon",
                              attrs: {
                                icon: "md-star",
                                title: 'Товар добавлен в "Мои товары"'
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !proposal.isCustom && _vm.isShowColumn("count"),
                            expression:
                              "!proposal.isCustom && isShowColumn('count')"
                          }
                        ],
                        staticClass: "col col-count"
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(proposal.unit) +
                              "\n                    "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !proposal.isCustom && _vm.isShowColumn("price"),
                            expression:
                              "!proposal.isCustom && isShowColumn('price')"
                          }
                        ],
                        staticClass: "col col-price"
                      },
                      [
                        proposal.is_wait
                          ? _c("div", [_vm._v("ждём")])
                          : _c(
                              "div",
                              {
                                class: [
                                  "price-wrap",
                                  proposal.specialPrice.isShowMark
                                    ? "is-low-special-price"
                                    : ""
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(proposal.price) +
                                    " р/" +
                                    _vm._s(proposal.unit) +
                                    "\n                    "
                                )
                              ]
                            )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-211310bd", { render: render, staticRenderFns: staticRenderFns })
  }
}