import Index from '~/screen/manager/users/Admin'
import Auth from '~/screen/manager/users/Auth'
import ChooseManager from '~/screen/manager/users/ChooseManager'
import ChooseDirector from '~/screen/manager/users/ChooseDirector'

let route = [
    {
        path: 'admin',
        name: 'manager-user-admin',
        component: Index,
        params: true,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'auth',
                name: 'manager-user-auth',
                component: Auth,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'choose-director',
                name: 'manager-choose-director',
                component: ChooseDirector,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'choose-manager',
                name: 'manager-choose-manager',
                component: ChooseManager,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
        ],
    },
]

export default route;
