<template>
    <v-ons-page class="report-sales-for-period" >
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Обороты за период</div>

            <div class="right" >
                <v-ons-toolbar-button v-show="isGenerated && report.length > 0" @click="print">
                    <v-ons-icon icon="md-print"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-show="!isGenerated" >
                <ons-list>
                    <ons-list-header>Дата отгрузки</ons-list-header>
                    <ons-list-item>
                        <div class="wrap xl-gutter-8" >
                            <div class="col xl-1-2" >
                                <v-input-datepicker
                                    v-model="filters.dateShipmentFrom"
                                    :highlighted="highlightedDatepicker"
                                    placeholder='От'
                                />
                            </div>

                            <div class="col xl-1-2" >
                                <v-input-datepicker
                                    v-model="filters.dateShipmentTo"
                                    :highlighted="highlightedDatepicker"
                                    placeholder='До'
                                />
                            </div>
                        </div>
                    </ons-list-item>

                    <ons-list-item>
                        <div class="center">
                            Статус заказа
                        </div>
                        <div class="right">
                            <div class="select-material select--material select" >
                                <select class="select-input select-input--material" v-model="filters.orderStatus" >
                                    <option v-for="status in orderStatuses" :value="status.id" >
                                        {{ status.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </ons-list-item>

                    <ons-list-item>
                        <label for="showEntity" class="center label-reset" >
                            Показать клиентов
                        </label>
                        <div class="right">
                            <v-ons-checkbox
                                v-model="filters.showEntity"
                                input-id="showEntity"
                                style="vertical-align: middle; margin: 0 12px 0 0;"
                            ></v-ons-checkbox>
                        </div>
                    </ons-list-item>

                    <ons-list-item>
                        <label for="showProduct" class="center label-reset" >
                            Показать товары
                        </label>
                        <div class="right">
                            <v-ons-checkbox
                                v-model="filters.showProduct"
                                input-id="showProduct"
                                style="vertical-align: middle; margin: 0 12px 0 0;"
                            ></v-ons-checkbox>
                        </div>
                    </ons-list-item>

                    <ons-list-item v-if="$user.isAdmin">
                        <div class="center">
                            Менеджеры
                            <span v-show="filters.managers.length > 0" >({{ filters.managers.length }})</span>
                        </div>
                        <div class="right">
                            <v-ons-button @click="chooseManagers" >Выбрать</v-ons-button>
                        </div>
                    </ons-list-item>

                    <ons-list-item>
                        <div class="center">
                            Фильтр по заказам
                            <span v-show="filters.ids.length > 0" >({{ filters.ids.length }})</span>
                        </div>
                        <div class="right">
                            <v-ons-button
                                @click="chooseOrders"
                                :disabled="filterByOrdersButtonIsDisabled" >
                                Выбрать
                            </v-ons-button>
                        </div>
                    </ons-list-item>
                </ons-list>

                <div style="margin-top: 10px; text-align: center" >
                    <v-ons-button @click="generate" >
                        Сформировать
                    </v-ons-button>
                </div>
            </div>

            <div v-show="isGenerated" >
                <div v-if="report.length > 0" >
                    <div style="text-align: center; margin: 5px 0;" >
                        {{ periodLabel }}
                    </div>

                    <table class="table">
                        <tbody>
                            <tr v-for="item in report" :class="'tr-' + item.type + (item.level ? ' level-' + item.level : '')" >
                                <div v-frag v-if="item.type === 'header'" >
                                    <td colspan="2" >{{ item.content }}</td>
                                    <td colspan="2" class="td-amount" >{{ item.amount }} р.</td>
                                </div>

                                <div v-frag v-if="item.type === 'row'" >
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.count }}</td>
                                    <td class="td-amount" >{{ item.amount }} р.</td>
                                </div>

                                <div v-frag v-if="item.type === 'footer'" >
                                    <td colspan="2" >{{ item.content }}</td>
                                    <td colspan="2" class="td-amount" >{{ item.amount }} р.</td>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-show="report.length === 0" style="text-align: center; font-size: 15px; margin-top: 50px" >Ничего не найдено</div>
            </div>
        </div>

        <v-list-dialog ref="managersDialog" multiple="true" allowselectall="true" title="Менеджеры" :items="managers" />
    </v-ons-page>
</template>

<script>

import InputDatepicker from '~/component/InputDatepicker'
import ListDialog from '~/component/ListDialog'

export default {
    data() {
        return {
            isGenerated: false,
            loading: false,
            filters: {
                showEntity: false,
                showProduct: false,
                dateShipmentFrom: '',
                dateShipmentTo: '',
                orderStatus: '',
                ids: [],
                managers: [],
            },
            urlRequest: null,
            report: [],
            totalAmount: null,
            managers: [],
            orderStatuses: [
                {
                    id: '',
                    name: 'Все',
                },
                {
                    id: 'sended',
                    name: 'Отправленные',
                },
                {
                    id: 'confirmed',
                    name: 'Подтвержденные',
                },
            ],
            highlightedDatepicker: {
                dates: [new Date()],
            },
        }
    },
    created() {
        this.$http.get('/api/manager/users/managers').then(response => {
            this.managers = response.data.map(i => {
                return {
                    id: i.id,
                    name: i.name + ' ' + i.email,
                };
            });
        });
    },
    methods: {
        generate() {
            if (!this.filters.dateShipmentFrom) {
                this.$ons.notification.toast({
                    message: 'Укажите начало периода отгрузки',
                    buttonLabel: 'OK'
                });
                return;
            }

            if (this.$user.isAdmin && this.filters.managers.length === 0) {
                this.$ons.notification.toast({
                    message: 'Выберите менеджера',
                    buttonLabel: 'OK'
                });
                return;
            }

            this.loading = true;

            this.$http.get('/api/manager/reports/sales-for-period', {params: {filters: this.filters}}).then(response => {
                this.urlRequest = response.url;
                this.isGenerated = true;
                this.loading = false;
                this.report = response.data;
            }, () => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });

                this.loading = false;
            });
        },
        print() {
            let parts = this.urlRequest.split('?');
            parts[0] = parts[0] + '-pdf';
            let url = parts.join('?');

            let win = window.open(`${url}&format=pdf&access-token=${this.$user.token}`, '_blank');
            win.focus();
        },
        reset() {
            this.isGenerated = false;
            this.report = [];
            this.urlRequest = null;
        },
        chooseOrders() {
            this.$router.push({
                name: 'report-sales-for-period-orders',
                params: {
                    filters: this.filters,
                },
            });
        },
        chooseManagers() {
            this.$refs.managersDialog.show({selected: this.filters.managers}).then(response => {
                this.filters.managers = response;
            });
        },
        goBack() {
            if (this.isGenerated) {
                this.reset();
                return;
            }

            this.$router.push({
                name: this.$route.matched[this.$route.matched.length - 2].name
            });
        },
    },
    computed: {
        periodLabel() {
            let periodLabel = this.$moment(this.filters.dateShipmentFrom).format('L') + ' - ';

            if (this.filters.dateShipmentTo) {
                periodLabel += this.$moment(this.filters.dateShipmentTo).format('L');
            } else {
                periodLabel += '...';
            }

            return periodLabel;
        },
        filterByOrdersButtonIsDisabled() {
            if (!this.filters.dateShipmentFrom || !this.filters.dateShipmentTo) {
                return true;
            }

            if (this.$user.isAdmin) {
                return this.filters.managers.length === 0;
            }

            return false;
        },
    },
    watch: {
        '$route.params.checkedOrders': function() {
            if (!this.$route.params.checkedOrders) {
                return;
            }

            this.filters.ids = this.$route.params.checkedOrders;
        }
    },
    components: {
        'v-input-datepicker': InputDatepicker,
        'v-list-dialog': ListDialog,
    },
};
</script>