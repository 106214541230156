var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { directives: [{ name: "frag", rawName: "v-frag" }] },
    _vm._l(_vm.subtitles, function(subtitle) {
      return _c("span", { staticClass: "list-item__subtitle" }, [
        _vm._v(
          "\n        " +
            _vm._s(subtitle.name) +
            ": " +
            _vm._s(subtitle.value) +
            "\n    "
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-44dc3b8f", { render: render, staticRenderFns: staticRenderFns })
  }
}