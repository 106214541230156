<template>
    <div :class="['position-card-wrap', isDeleted ? 'position-deleted' : '']" >
        <div class="row-position-wrap">
            <div class="col col-checkbox">
                <div class="remove-wrap" v-if="!position.isNew">
                    <v-ons-checkbox v-model="position.isChecked"/>
                </div>
            </div>
            <div class="col col-position-card">
                <v-ons-card class="position-card">
                    <div class="position-wrap">
                        <div v-show="!isEditing" class="popover-menu" :id="popoverId" @click="popoverMenuVisible = true">
                            <v-ons-icon icon="md-more-vert"></v-ons-icon>

                            <v-ons-popover
                                cancelable
                                :visible.sync="popoverMenuVisible"
                                :target="'#' + popoverId"
                                cover-target="true"
                                direction="down"
                                class="order-popover-menu"
                            >
                                <ons-list @click="popoverMenuVisible = false;">
                                    <ons-list-item v-show="!isEditing" @click="update">
                                        <div class="left icon">
                                            <v-ons-icon icon="md-edit"></v-ons-icon>
                                        </div>
                                        <div class="center">Изменить товар</div>
                                    </ons-list-item>

                                    <ons-list-item v-show="position.product" @click="goToView">
                                        <div class="left icon">
                                            <v-ons-icon icon="md-image-o"></v-ons-icon>
                                        </div>
                                        <div class="center">Карточка товара</div>
                                    </ons-list-item>

                                    <ons-list-item @click="remove">
                                        <div class="left icon">
                                            <v-ons-icon icon="md-delete"></v-ons-icon>
                                        </div>
                                        <div class="center">Удалить</div>
                                    </ons-list-item>
                                </ons-list>
                            </v-ons-popover>
                        </div>

                        <div class="position-index">
                            {{ position.index }}
                        </div>

                        <div class="row-wrap">
                            <div v-show="!isEditing" class="col col-name" @click="goToView">
                                <div class="position-name">
                                    {{ position.name }}

                                    <div v-if="position.is_archive">
                                        <small>этого товара нет у выбранного поставщика</small>
                                    </div>

                                    <div v-if="!position.product" style="color: grey;">
                                        (пользовательский товар)
                                    </div>
                                </div>
                            </div>

                            <div v-show="isEditing" class="col col-name">
                                <input-proposal-create
                                    ref="input"
                                    :scrollPageOnFocus="true"
                                    :disableScrollPage="true"
                                    :addedPositions="addedPositions"
                                    :columns="$user.canSeePrice ? ['name', 'price'] : ['name']"
                                    :preventAddedChoose="true"
                                    :urlSearch="urlSearch"

                                    @choose="chooseProposal"
                                    @showProposals="$emit('showProposals')"
                                    @hideProposals="$emit('hideProposals')"
                                />
                            </div>

                            <div class="col col-image">
                                <img
                                    v-if="position.images && position.images.thumb100 && position.images.thumb100.hasImage"
                                    :class="['product-image', 'cursor-pointer']"
                                    :src="position.images.thumb100.path"
                                    :width="position.images.thumb100.width"
                                    :height="position.images.thumb100.height"
                                    @click="goToView"
                                />
                            </div>

                            <div v-show="!isEditing" class="col col-price">
                                <div v-if="position.price">
                                    {{ position.price }} р.
                                </div>
                                <div>
                                    {{ position.unit }}
                                </div>
                            </div>

                            <div v-show="isEditing" class="col col-cancel-update">
                                <button class="btn-cancel" @click="cancelUpdate">
                                    <v-ons-icon icon="md-block"></v-ons-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </v-ons-card>
            </div>
        </div>
    </div>
</template>

<script>

import InputProposalCreate from '~/component/InputProposalCreate'

export default {
    props: [
        'position',
        'addedPositions',
        'units',
        'index',
        'lastImportDate',
        'canEditPrice',
        'urlSearch',
    ],
    data() {
        return {
            popoverMenuVisible: false,
            isEditing: false,
            isDeleted: false,
            popoverId: null,
        }
    },
    created() {
        this.popoverId = 'position-create-popover-menu-' + this.position.pos_id + '-' + Math.floor(Math.random() * 10000);
    },
    methods: {
        chooseProposal(proposal) {
            this.isEditing = false;
            this.$emit('update', this.position.id, proposal);
        },
        update() {
            this.isEditing = true;
            this.$refs.input.name = '';

            this.$nextTick(() => {
                this.$refs.input.setFocus();
            });
        },
        cancelUpdate() {
            this.isEditing = false;
        },
        remove() {
            this.isDeleted = true;
            this.$emit('remove', this.position.pos_id);
        },
        goToView() {
            if (!this.position.product) {
                return
            }

            this.$router.push(this.$router.currentRoute.path + '/' + this.position.product)
        },
    },
    components: {
        InputProposalCreate,
    },
};
</script>