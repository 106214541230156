<template>
    <v-ons-dialog cancelable :visible.sync="visible" >
        <v-ons-toolbar inline="true" >
            <div class="center">Настройки прайса</div>
            <div class="right" v-show="!loading">
                 <v-ons-toolbar-button @click="ok" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" style="top: 56px; bottom: 0;" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-item v-show="storages.length > 1">
                    <div class="center">
                        <div class="select-material select--material select">
                            <select v-model="selectedStorage" class="select-input select-input--material">
                                <option
                                    v-for="storage in storages"
                                    :key="storage.id"
                                    :value="storage.id"
                                    :disabled="!storage.is_configured"
                                >
                                    {{ storage.name }}
                                    {{ !storage.is_configured ? '(не настроено)' : '' }}
                                </option>
                            </select>
                        </div>
                    </div>
                </v-ons-list-item>

                <v-ons-list-header>Типы цен</v-ons-list-header>
                <v-ons-list-item v-for="(type, $index) in priceTypes" :key="$index" >
                    <div class="left" >
                        <v-ons-radio
                            :input-id="'price-radio-' + $index"
                            :value="type.id"
                            v-model="selectedPriceType"
                        >
                        </v-ons-radio>
                    </div>
                    <label :for="'price-radio-' + $index" class="center label-reset" >{{ type.name }}</label>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-item>
                    <div class="left" >
                        <v-ons-checkbox v-model="showCatalogRest" input-id="show-catalog-rest" />
                    </div>
                    <label for="show-catalog-rest" class="center label-reset" >Отображать остатки</label>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-item>
                    <div class="left" >
                        <v-ons-checkbox v-model="showSpecialProducts" input-id="show-special-products" />
                    </div>
                    <label for="show-special-products" class="center label-reset" >Отображать спец. товары</label>
                </v-ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-dialog>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            selectedStorage: null,
            storages: [],
            priceTypes: [],

            visible: false,

            showSpecialProducts: false,
            showCatalogRest: false,
            selectedPriceType: 1,

            resolve: null,
            reject: null,
        }
    },
    created() {
        this.loadStorages().then(() => {
            this.$watch('selectedStorage', () => {
                this.loading = true;

                this.loadPriceTypes().then(() => {
                    this.loading = false;
                });
            });
        });
    },
    methods: {
        loadStorages() {
            return this.$http.get(
                '/api/manager/storages',
                {params: {withConfigured: true}}
            ).then(response => {
                this.storages = response.data;

                return Promise.resolve();
            });
        },
        loadPriceTypes() {
            return this.$http.get(
                '/api/catalog/get-price-types',
                {params: {storageId: this.selectedStorage}}
            ).then(response => {
                this.priceTypes = response.data.map(i => {
                    i.choosed = false;
                    return i;
                });

                return Promise.resolve();
            });
        },
        show(params) {
            this.selectedPriceType = params.selectedPriceType;
            this.selectedStorage = params.selectedStorage;
            this.showSpecialProducts = params.showSpecialProducts;
            this.showCatalogRest = params.showCatalogRest;

            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;

            if (this.resolve) {
                this.resolve();
            }
        },
        ok() {
            this.visible = false;

            let params = {
                showSpecialProducts: this.showSpecialProducts,
                selectedPriceType: this.selectedPriceType,
                selectedStorage: this.selectedStorage,
                showCatalogRest: this.showCatalogRest,
            }

            if (this.resolve) {
                this.resolve(params);
            }
        },
    },
};
</script>