<template>
    <v-ons-page>
        <v-ons-toolbar inline="true">
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">Отчёты</div>
        </v-ons-toolbar>

        <ons-list>
            <ons-list-item modifier="chevron longdivider" tappable
                           @click="$router.push('/manager/reports/sales-for-period')">
                Обороты за период
            </ons-list-item>

            <ons-list-item modifier="chevron longdivider" tappable
                           @click="$router.push('/manager/reports/special-prices')">
                Отчёт по специальным ценам
            </ons-list-item>

            <ons-list-item modifier="chevron longdivider" tappable
                           v-if="$user.isAdmin"
                           @click="$router.push('/manager/reports/search-queries')">
                Отчёт по запросам
            </ons-list-item>

            <ons-list-item modifier="chevron longdivider" tappable
                           @click="$router.push('/manager/reports/deletions-from-orders')">
                Вычерки из заявок клиентов
            </ons-list-item>

            <ons-list-item modifier="chevron longdivider" tappable @click="$router.push('/manager/reports/emails')">
                Emails
            </ons-list-item>

            <ons-list-item modifier="chevron longdivider" tappable @click="$router.push('/manager/reports/no-photos')">
                Нет фото
            </ons-list-item>
        </ons-list>
    </v-ons-page>
</template>

<script>
export default {};
</script>