var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "border-bottom": "1px solid #afafaf",
            "line-height": "28px"
          },
          on: {
            click: function($event) {
              _vm.isShippingDateDialogVisible = true
            }
          }
        },
        [
          _c("v-ons-icon", {
            staticStyle: {
              "margin-right": "8px",
              "font-size": "20px",
              "vertical-align": "middle"
            },
            attrs: { icon: "md-calendar" }
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("moment")(_vm.date, "DD.MM.YYYY")) +
              "\n\n        "
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.date && _vm.placeholder,
                  expression: "!date && placeholder"
                }
              ],
              staticStyle: { color: "#ccc" }
            },
            [_vm._v("\n            " + _vm._s(_vm.placeholder) + "\n        ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        {
          attrs: { cancelable: "", visible: _vm.isShippingDateDialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.isShippingDateDialogVisible = $event
            }
          }
        },
        [
          _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
            _c("div", { staticClass: "center" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.label) + "\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "v-ons-toolbar-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.isShippingDateDialogVisible = false
                      }
                    }
                  },
                  [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("datepicker", {
            attrs: {
              inline: true,
              "monday-first": true,
              language: _vm.ru,
              highlighted: _vm.highlighted
            },
            on: {
              selected: function($event) {
                _vm.isShippingDateDialogVisible = false
              }
            },
            model: {
              value: _vm.date,
              callback: function($$v) {
                _vm.date = $$v
              },
              expression: "date"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2e34306b", { render: render, staticRenderFns: staticRenderFns })
  }
}