<template>
    <v-ons-page class="user-special-prices" >
        <v-ons-toolbar v-show="!isSearchActive" >
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Спецпредложения</div>

            <div class="right" v-show='loaded' >
                <v-ons-toolbar-button @click="searchActive" >
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item
                        :text="showMinPrice ? 'Скрыть мин. цену' : 'Показать мин. цену'"
                        :icon="showMinPrice ? 'md-eye' : 'md-eye-off'"
                        @click="showMinPrice = !showMinPrice" />

                    <toolbar-popover-menu-item
                        text="Добавить из шаблона"
                        icon="md-collection-plus"
                        @click="addFromTemplate" />

                    <toolbar-popover-menu-item
                        text="Скачать спецификацию"
                        icon="md-download"
                        @click="download" />

                    <toolbar-popover-menu-item
                        text="Жёлтые ценники"
                        icon="md-bookmark-outline"
                        @click="$router.push({name: 'manager-user-special-prices-settings'})" />

                    <toolbar-popover-menu-item
                        text="Очистить"
                        icon="md-delete"
                        @click="deleteAll" />

                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="isSearchActive" >
            <div class="left">
                <v-ons-toolbar-button @click="searchDeactive">
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center" >
                <form action="" autocomplete="off" @submit.prevent="() => {}" >
                <input
                    id="specialprice-search-input"
                    v-model="searchQuery"
                    style="
                        color: #fff;
                        background: none;
                        border: none;
                        height: 34px;
                        line-height: 34px;
                        border-bottom: 1px solid #fff; "
                    autocomplete="off"
                    name="input-search"
                />
                </form>
            </div>

            <div class="right">
                <v-ons-toolbar-button @click="searchActive" >
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item
                        :text="showMinPrice ? 'Скрыть мин. цену' : 'Показать мин. цену'"
                        :icon="showMinPrice ? 'md-eye' : 'md-eye-off'"
                        @click="showMinPrice = !showMinPrice" />

                    <toolbar-popover-menu-item
                        text="Скачать спецификацию"
                        icon="md-download"
                        @click="download" />
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="!loaded" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-item>
                    ID клиента: {{ userId }}
                </v-ons-list-item>

                <v-ons-list-item>
                    Юр. лицо: {{ entities.map(i => i.legal_name).filter(i => i).join(', ') }}
                </v-ons-list-item>

            </v-ons-list>

            <div :style="{'padding-bottom': ($window.height - 369) + 'px'}" >
                <div v-for="(product, index) in items" :key="product.product_id" class="product-wrap" >
                    <div class="product-name" >
                        {{ index + 1 }} -
                        {{ product.name }}
                    </div>

                    <table class="table-params" >
                        <tbody>
                            <tr>
                                <td @click="remove(product)" class="cursor-pointer" title="Удалить" rowspan="2" >
                                    <v-ons-icon icon="md-close"></v-ons-icon>
                                </td>
                                <td>Ед.</td>
                                <td>Цена прайс</td>
                                <td>Цена спец.</td>
                            </tr>
                            <tr>
                                <td>{{ product.unit }}</td>
                                <td>
                                    {{ product.productPrice }}
                                    <span class="font-rouble" >o</span>
                                </td>
                                <td>
                                    <input
                                        v-model="prices[product.id]"
                                        type="number"
                                        autocomplete="off"
                                        :style="{
                                            width: '70px',
                                            textAlign: 'center',
                                            border: emptyPositions.includes(product.id) ? '1px solid red' : '1px solid #a9a9a9',
                                        }"
                                        :name="'special-price-' + product.id"
                                        :id="'special-price-' + product.id"
                                        @keydown="onKeydown($event, product.id)"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div>
                        Наличие:
                        <span v-if="parseFloat(product.rest) > 0" >Да</span>
                        <span v-else style="color: red" >Нет</span>
                    </div>

                    <div v-show="showMinPrice" >
                        Мин. цена:
                        <span v-if="prices[product.id] && parseFloat(prices[product.id]) < parseFloat(product.minPrice)" style="color: red" >{{ product.minPrice }}</span>
                        <span v-else >{{ product.minPrice }}</span>
                    </div>
                </div>

                <v-ons-list-item style="margin-bottom: 250px;" >
                    <input-proposal
                        style="width: 100%"
                        :scrollPageOnFocus="true"
                        :disableScrollPage="true"
                        :model.sync="product"
                        :addedPositions="products.map((i) => i.product_id)"
                        :urlSearch="'/api/manager/users/' + userId + '/catalog-search'"
                        @choose="chooseProduct"
                    />
                </v-ons-list-item>
            </div>
        </div>

        <v-list-dialog
            ref="addFromTemplateDialog"
            title="Добавить из шаблона"
            :multiple="true"
        />
    </v-ons-page>
</template>

<script>

import {from, merge, of} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, filter, map, pluck, switchMap, tap} from 'rxjs/operators'

import InputProposal from '~/component/InputProposal'
import ListDialog from '~/component/ListDialog'

export default {
    data() {
        return {
            loaded: false,

            isSearchActive: false,
            searchQuery: '',
            foundIds: null,

            userId: null,
            prices: {},
            product: '',
            products: [],
            entities: [],
            showMinPrice: true,
            templates: [],
            emptyPositions: [],
        }
    },
    created() {
        this.userId = this.$route.params.user_id;

        this.$http.get('/api/manager/users/'+ this.userId  +'/entities').then(response => {
            this.entities = response.data;
        });

        this.$http.get(`/api/manager/users/${this.userId}/templates/assign-list`).then(response => {
            this.templates = response.data;
        });

        this.load();
    },
    subscriptions () {
        const prices$ = this.$watchAsObservable('prices', {deep: true}).pipe(
            filter(() => this.loaded),
            debounceTime(1500),
        )

        const $q = this.$watchAsObservable('searchQuery').pipe(
            filter(() => this.searchActive),
            pluck('newValue'),
            map(q => q.trim()),
            filter(q => q.length > 2),
            debounceTime(1000),
            distinctUntilChanged(),
        );

        const $searchDeactive = this.$eventToObservable('searchDeactive').pipe(
            filter(() => this.filters.q.length > 0),
            tap(() => {
                this.foundIds = null;
            })
        );

        return {
            results: prices$.pipe(
                switchMap(() => from(this.save())),
            ),
            search: merge($q, $searchDeactive).pipe(
                switchMap(() => from(this.$http.get('/api/manager/users/' + this.$route.params.user_id + '/catalog-search', {params: {text: this.searchQuery}}))),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of([]);
                }),
                map(response => {
                    this.foundIds = response.map(i => i.id);
                }),
            )
        }
    },
    methods: {
        download() {
            let link = document.createElement('a');
            link.href = `/api/manager/users/special-prices-pdf?id=${this.userId}&access-token=${this.$user.token}`;
            link.download = true;
            link.click();
        },
        openSpecPricePdf() {
            let link = document.createElement('a');
            link.href = `/api/manager/users/special-prices-pdf?id=${this.userId}&access-token=${this.$user.token}`;
            link.target = '_blank';
            link.click();
        },
        addFromTemplate() {
            this.$refs.addFromTemplateDialog.show({items: this.templates}).then(response => {
                let productIds = [];
                let templates = this.templates.filter(t => response.includes(t.id)).map(i => i.positions);
                let addedProductIds = this.products.map(p => p.product_id);

                templates.forEach(positions => {
                    positions.forEach(position => {
                        if (productIds.includes(position.product)) {
                            return;
                        }

                        if (addedProductIds.includes(position.product)) {
                            return;
                        }

                        productIds.push(position.product);
                    });
                });

                if (!productIds) {
                    return;
                }

                this.$http.put(
                    '/api/manager/users/special-prices',
                    {ids: productIds},
                    {params: {id: this.userId}}
                ).then(() => {
                    this.load().then(() => {
                        this.$nextTick(() => {
                            let id = this.products.find(p => !p.price).id;

                            if (id) {
                                let input = document.getElementById('special-price-' + id);
                                input.focus();
                            }
                        });
                    });
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        searchActive() {
            this.isSearchActive = true;
            this.searchQuery = '';
            this.$nextTick(() => document.getElementById("specialprice-search-input").focus());
        },
        searchDeactive() {
            this.isSearchActive = false;
        },
        chooseProduct(product) {
            this.$http.put(
                '/api/manager/users/special-prices',
                {ids: [product.id]},
                {params: {id: this.userId}}
            ).then(() => {
                this.product = '';
                this.load().then(() => {
                    this.$nextTick(() => {
                        let id = this.products[this.products.length - 1].id;
                        let input = document.getElementById('special-price-' + id);
                        input.focus();
                    });
                });
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                });
            });
        },
        load() {
            return this.$http.get('/api/manager/users/special-prices', {params: {id: this.userId}}).then(response => {
                this.products = response.data;

                this.prices = {};
                this.products.map(i => {
                    this.$set(this.prices, i.id, i.price);
                });

                this.$nextTick(() => {
                    this.loaded = true;
                });

                return Promise.resolve();
            });
        },
        save() {
            return this.$http.post(
                '/api/manager/users/special-prices',
                {prices: this.prices},
                {params: {id: this.userId}}
            ).then(() => {

            }, () => {
                this.load();

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                });
            });
        },
        remove(product) {
            this.$noty.confirm('Удалить?').then(response => {
                if (!response) {
                    return;
                }

                this.$http.delete(
                    '/api/manager/users/delete-special-price',
                    {params: {userId: this.userId, id: product.id}}
                ).then(() => {
                    this.load();
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        goBack() {
            this.emptyPositions = Object.entries(this.prices)
                .filter(entry => !entry[1])
                .map(entry => parseInt(entry[0]));

            if (this.emptyPositions.length > 0) {
                this.$ons.notification.toast({
                    message: 'Не установлены спец. цены',
                    buttonLabel: 'OK'
                });

                return;
            }

            this.save().then(() => {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
            });
        },
        onKeydown(e, productId) {
            if (e.keyCode === 13) {
                let items = this.items;
                let index = items.findIndex(i => i.id == productId);
                let input = null;

                if (e.shiftKey) {
                    let prevIndex = null;
                    if (index === 0) {
                        prevIndex = items.length - 1;
                    } else {
                        prevIndex = index - 1;
                    }

                    let prevItem = this.items[prevIndex];
                    input = document.getElementsByName('special-price-' + prevItem.id);
                } else {
                    if (index === items.length - 1) {
                        input = document.getElementsByName('input-proposal');

                        if (!input) {
                            return;
                        }

                        input[0].focus();
                        return;
                    }

                    let nextItem = this.items[index + 1];
                    input = document.getElementsByName('special-price-' + nextItem.id);
                }

                if (!input) {
                    return;
                }

                input[0].focus();
            }
        },
        deleteAll() {
            this.$ons.notification.confirm('Очистить таблицу спецпредложений?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'Очистить']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.delete(
                    '/api/manager/users/special-prices',
                    {params: {id: this.userId}}
                ).then(() => {
                    this.load();
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
    },
    computed: {
        items() {
            if (!this.isSearchActive || !this.searchQuery || this.foundIds === null) {
                return this.products;
            }

            return this.products.filter(p => this.foundIds.includes(p.product_id));
        }
    },
    components: {
        InputProposal,
        'v-list-dialog': ListDialog,
    },
};
</script>