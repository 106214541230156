<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">
                <div v-show="!isSearchActive">
                    {{ product.name }}
                </div>

                <div v-show="isSearchActive" class="search-wrap">
                    <input id="search-input" v-model="searchQuery" style="
                        color: #fff;
                        background: none;
                        border: none;
                        height: 34px;
                        line-height: 34px;
                        border-bottom: 1px solid #fff;
                    "/>
                </div>
            </div>

            <div class="right">
                <v-ons-toolbar-button v-show="!isSearchActive" @click="searchActive">
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="isSearchActive" @click="searchDeactivate">
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}">
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-if="items.length > 0">
                <v-ons-list>
                    <v-ons-lazy-repeat
                        ref="lazyRepeat"
                        :render-item="renderItem"
                        :length="items.length"
                    >
                    </v-ons-lazy-repeat>
                </v-ons-list>
            </div>

            <div
                v-show="items.length === 0"
                class="list-empty"
                :style="{height: $window.height - 56 + 'px', flexDirection: 'column'}"
            >
                <div>Ничего не найдено</div>
            </div>

            <div style="height: 100px;"></div>
        </div>

        <v-ons-fab v-show="!loading && product" position="bottom left" class="fab-text" @click="setArchive">
            Удалить
        </v-ons-fab>

        <v-ons-fab
            v-show="!loading && product && product.product_id"
            position="bottom right"
            class="fab-text"
            @click="setAvail"
        >
            Ожидаем поступление
        </v-ons-fab>
    </v-ons-page>
</template>

<script>
import Vue from "vue";
import ProductAvailLinkItem from "~/screen/manager/component/ProductAvailLinkItem";
import {catchError, concatMap, debounceTime, distinctUntilChanged, filter, map, pluck, tap} from "rxjs/operators";
import {from, merge, of} from "rxjs";

export default {
    data() {
        return {
            loading: true,
            isSearchActive: false,
            searchQuery: '',
            searchedItems: [],
            product: {},
            storageId: null,
            items: [],
        }
    },
    created() {
        this.storageId = this.$route.params.storage_id;
        this.loadProduct();
        this.$emit('update');
    },
    subscriptions () {
        const searchQuery$ = this.$watchAsObservable('searchQuery').pipe(
            filter(() => this.searchActive),
            pluck('newValue'),
            map(q => q.trim()),
            debounceTime(1000),
        );

        const searchDeactivate$ = this.$eventToObservable('searchDeactivate').pipe(
            tap(() => {
                this.searchQuery = '';
                this.isSearchActive = false;
            }),
            map(() => this.searchQuery)
        );

        const search$ = merge(searchQuery$, searchDeactivate$).pipe(
            distinctUntilChanged(),
            tap(() => {
                this.loading = true;
                this.items = [];
            })
        );

        const update$ = this.$eventToObservable('update').pipe(
            debounceTime(100),
            tap(() => {
                this.loading = true;
                this.items = [];
            })
        )

        return {
            results: merge(search$, update$).pipe(
                concatMap(() => {
                    return from(this.loadReplacements());
                }),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of([]);
                }),
                tap(items => {
                    this.loading = false;
                    this.items = items;
                }),
            )
        }
    },
    methods: {
        loadProduct() {
            this.$http.get(
                '/api/manager/product-link/get-product',
                {params: {id: this.$route.params.product_id}}
            ).then(response => {
                this.product = response.data;
            });
        },
        loadReplacements() {
            return this.$http.get(
                '/api/manager/product-link/get-replacements',
                {
                        params: {
                            id: this.$route.params.product_id,
                            storageId: this.storageId,
                            query: this.searchQuery,
                        }
                    }
            )
        },
        searchDeactivate() {
            this.$emit('searchDeactivate');
        },
        choose(product) {
            this.$bus.$emit('chooseProduct', {product_id: this.product.id, replacement: product});
            this.$router.go(-1);
        },
        searchActive() {
            this.isSearchActive = true;
            this.searchQuery = '';
            this.$nextTick(() => document.getElementById("search-input").focus());
        },
        setAvail() {
            this.$bus.$emit('setAvail', {product_id: this.product.id});
            this.$router.go(-1);
        },
        setArchive() {
            this.$bus.$emit('setArchive', {product_id: this.product.id});
            this.$router.go(-1);
        },
        renderItem(i) {
            let self = this;
            let item = this.items[i];

            return new Vue({
                render(h) {
                    return h(ProductAvailLinkItem, {
                        props: {
                            product: item,
                        },
                        on: {
                            choose: self.choose,
                        },
                    });
                }
            });
        },
    },
};
</script>