import Entity from '~/screen/manager/users/Entity'
import Entities from '~/screen/manager/users/Entities'

let route = [
    {
        path: 'entities',
        name: 'manager-user-entities',
        component: Entities,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'create',
                name: 'manager-user-entity-create',
                component: Entity,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: ':entity_id',
                name: 'manager-user-entity',
                component: Entity,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
        ],
    },
]

export default route;

