var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Ошибки связей")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "toolbar-popover-menu",
              { attrs: { "popover-class": "stick-right" } },
              [
                _c("ons-list-item", { on: { click: _vm.onRemoveAll } }, [
                  _vm._v("Удалить всё")
                ]),
                _vm._v(" "),
                _c(
                  "ons-list-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/manager/product-avail")
                      }
                    }
                  },
                  [_vm._v("В ожидании")]
                ),
                _vm._v(" "),
                _c("ons-list-header", [_vm._v("Отображать по:")]),
                _vm._v(" "),
                _c(
                  "ons-list-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push({
                          name: "manager-product-link-users"
                        })
                      }
                    }
                  },
                  [
                    _c("div", {
                      staticClass: "left",
                      staticStyle: {
                        "min-width": "25px",
                        "justify-content": "center"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "center",
                        staticStyle: { "padding-left": "0" }
                      },
                      [
                        _vm._v(
                          "\n                        пользователям\n                    "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ons-list-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push({
                          name: "manager-product-link-products"
                        })
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "left",
                        staticStyle: {
                          "min-width": "25px",
                          "justify-content": "center"
                        }
                      },
                      [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "center",
                        staticStyle: { "padding-left": "0" }
                      },
                      [
                        _vm._v(
                          "\n                        товарам\n                    "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.storages.length > 1,
                  expression: "storages.length > 1"
                }
              ],
              attrs: { modifier: "chevron longdivider" },
              on: { click: _vm.showChooseStorage }
            },
            [
              _c("span", { staticClass: "list-item__title" }, [
                _vm._v("Склад: " + _vm._s(_vm.selectedStorageName))
              ]),
              _vm._v(" "),
              _vm.selectedStorage && !_vm.selectedStorage.is_configured
                ? _c(
                    "span",
                    {
                      staticClass: "list-item__subtitle",
                      staticStyle: { color: "red" }
                    },
                    [_vm._v("\n                Не настроено\n            ")]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("v-ons-card", [
            _vm._v(
              '\n            Эти товары добавлены у клиентов в избранное, но они закончились.\n            Замените товары на аналоги или присвойте товару статус "ждём"\n        '
            )
          ]),
          _vm._v(" "),
          _c(
            "ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.products,
                  expression: "products"
                }
              ]
            },
            _vm._l(_vm.products, function(product) {
              return _c(
                "ons-list-item",
                {
                  key: product.id,
                  attrs: { modifier: "chevron longdivider", tappable: "" },
                  on: {
                    click: function($event) {
                      return _vm.clickProduct(product)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "center" }, [
                    _c("span", { staticClass: "list-item__title" }, [
                      _vm._v(_vm._s(product.name))
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: "product" in product && !product.product,
                            expression:
                              "'product' in product && !product.product"
                          }
                        ],
                        staticClass: "list-item__subtitle"
                      },
                      [
                        _vm._v(
                          "\n                        Пользовательский товар\n                    "
                        )
                      ]
                    )
                  ])
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.products.length === 0,
                  expression: "products.length === 0"
                }
              ],
              staticStyle: {
                "text-align": "center",
                "font-size": "15px",
                "margin-top": "50px"
              }
            },
            [_vm._v("\n            Ошибок связей не найдено\n        ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "chooseStorageDialog",
        attrs: { title: "Выберите склад", items: _vm.storages }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-08693bc0", { render: render, staticRenderFns: staticRenderFns })
  }
}