<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>
            <div class="center">Настройки</div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item v-show="$user.isManager" tappable @click="$router.push('/settings/onec-connection')" class="cursor-pointer" >
                Связь с 1С
            </v-ons-list-item>

            <v-ons-list-item v-show="$user.isManager" tappable @click="$router.push('/settings/notifications')" class="cursor-pointer" >
                Уведомления о заказах
            </v-ons-list-item>

            <v-ons-list-item v-show="$user.isManager" tappable @click="$router.push('/settings/special-prices-notifications')" class="cursor-pointer" >
                Уведомления о спец. ценах
            </v-ons-list-item>

            <v-ons-list-item v-show="$user.isCustomer" tappable @click="$router.push('/settings/checkout')" class="cursor-pointer" >
                Настройки заказа
            </v-ons-list-item>

            <v-ons-list-item tappable @click="$router.push('/settings/change-password')" class="cursor-pointer" >
                Сменить пароль
            </v-ons-list-item>

            <v-ons-list-item tappable @click="reload" class="cursor-pointer" >
                Обновить
            </v-ons-list-item>

            <v-ons-list-item tappable @click="logout" class="cursor-pointer" >
                Выход
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    methods: {
        reload() {
            location.reload();
        },
        logout() {
            this.$user.reset();
            this.$router.push('/');
        },
    }
};
</script>