var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-ons-list-item", { staticClass: "list-item-category" }, [
    _vm._v("\n    " + _vm._s(_vm.item.name) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c0bdc58c", { render: render, staticRenderFns: staticRenderFns })
  }
}