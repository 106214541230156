var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v(_vm._s(_vm.product.name))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasChecked,
                    expression: "hasChecked"
                  }
                ],
                on: { click: _vm.uncheckAll }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hasChecked,
                    expression: "!hasChecked"
                  }
                ],
                on: { click: _vm.checkAll }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.users,
                  expression: "users"
                }
              ]
            },
            _vm._l(_vm.users, function(user) {
              return _c(
                "ons-list-item",
                { key: user.id, attrs: { modifier: "longdivider" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "left",
                      staticStyle: {
                        "min-width": "40px",
                        "text-align": "center"
                      }
                    },
                    [
                      _c("v-ons-checkbox", {
                        model: {
                          value: user.isChecked,
                          callback: function($$v) {
                            _vm.$set(user, "isChecked", $$v)
                          },
                          expression: "user.isChecked"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "center" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(user.id) +
                        " - " +
                        _vm._s(user.email) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "right cursor-pointer",
                      attrs: {
                        id: "popoverMenuVisible-productLinkUsers-" + user.id
                      },
                      on: {
                        click: function($event) {
                          user.popoverMenuVisible = true
                        }
                      }
                    },
                    [
                      _c("v-ons-icon", {
                        attrs: { icon: "md-more-vert", size: "20px" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-ons-popover",
                        {
                          staticClass: "order-popover-menu",
                          attrs: {
                            cancelable: "",
                            visible: user.popoverMenuVisible,
                            target:
                              "#popoverMenuVisible-productLinkUsers-" + user.id,
                            "cover-target": "true",
                            direction: "down"
                          },
                          on: {
                            "update:visible": function($event) {
                              return _vm.$set(
                                user,
                                "popoverMenuVisible",
                                $event
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "ons-list",
                            {
                              on: {
                                click: function($event) {
                                  user.popoverMenuVisible = false
                                }
                              }
                            },
                            [
                              _c(
                                "ons-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        _vm.$router.currentRoute.path +
                                          "/products-" +
                                          user.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "left icon" },
                                    [
                                      _c("v-ons-icon", {
                                        attrs: { icon: "md-folder-star-alt" }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "center" }, [
                                    _vm._v("Перейти в товары клиента")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-fab",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasChecked,
                  expression: "hasChecked"
                }
              ],
              attrs: { position: "bottom right" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: "manager-product-link-products-product",
                    params: { users: _vm.checkedUserIds }
                  })
                }
              }
            },
            [_c("v-ons-icon", { attrs: { icon: "md-arrow-right" } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-41353c56", { render: render, staticRenderFns: staticRenderFns })
  }
}