<template>
    <div class="address-form-component">
        <div class="address-form-component-wrap">
            <div @click="open" class="text-label">
                {{ fullAddress }}
            </div>
            <div class="copy-icon cursor-pointer" @click="copyAddress" title="Скопировать адрес">
                <v-ons-icon icon="md-copy"></v-ons-icon>
            </div>
        </div>

        <v-ons-dialog :visible.sync="visible" modifier="fullscreen">
            <v-ons-page>
                <v-ons-toolbar>
                    <div class="left">
                        <v-ons-toolbar-button @click="close">
                            <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                        </v-ons-toolbar-button>
                    </div>
                    <div class="center">{{ title }}</div>
                    <div class="right">
                        <v-ons-toolbar-button @click="save">
                            <v-ons-icon icon="md-check"></v-ons-icon>
                        </v-ons-toolbar-button>
                    </div>
                </v-ons-toolbar>

                <v-ons-list>
                    <v-ons-list-header>Регион</v-ons-list-header>
                    <v-ons-list-item>
                        <v-ons-input v-model="oblast"></v-ons-input>
                    </v-ons-list-item>

                    <v-ons-list-header>Населённый пункт</v-ons-list-header>
                    <v-ons-list-item>
                        <v-ons-input v-model="city"></v-ons-input>
                    </v-ons-list-item>

                    <v-ons-list-header>Улица</v-ons-list-header>
                    <v-ons-list-item>
                        <v-ons-input v-model="street"></v-ons-input>
                    </v-ons-list-item>

                    <v-ons-list-header>Дом</v-ons-list-header>
                    <v-ons-list-item>
                        <v-ons-input v-model="house"></v-ons-input>
                    </v-ons-list-item>

                    <v-ons-list-header>Этаж</v-ons-list-header>
                    <v-ons-list-item>
                        <v-ons-input v-model="floor"></v-ons-input>
                    </v-ons-list-item>
                </v-ons-list>
            </v-ons-page>
        </v-ons-dialog>
    </div>
</template>

<script>

import copy from 'copy-text-to-clipboard'

export default {
    props: [
        'value',
        'title',
    ],
    data() {
        return {
            visible: false,
            oldValue: null,

            oblast: '',
            city: '',
            street: '',
            house: '',
            floor: '',
        }
    },
    created() {
        if (this.value) {
            this.oblast = this.value.oblast;
            this.city = this.value.city;
            this.street = this.value.street;
            this.house = this.value.house;
            this.floor = this.value.floor;
        }
    },
    methods: {
        open() {
            if (this.value) {
                this.oblast = this.value.oblast;
                this.city = this.value.city;
                this.street = this.value.street;
                this.house = this.value.house;
                this.floor = this.value.floor;
            }

            this.oldValue = this.jsonValue;

            this.visible = true;
        },
        copyAddress() {
            copy(this.fullAddress);

            this.$ons.notification.toast({
                timeout: 3000,
                message: 'Скопировано',
                buttonLabel: 'OK',
            });
        },
        save() {
            this.visible = false;

            this.$emit('input', {
                oblast: this.oblast,
                city: this.city,
                street: this.street,
                house: this.house,
                floor: this.floor,
            });
        },
        close() {
            if (this.oldValue == this.jsonValue) {
                this.visible = false;
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        }
    },
    computed: {
        fullAddress() {
            if (!this.value) {
                return '';
            }

            let s = '';
            let oblast = this.value.oblast ? this.value.oblast.trim() : '';
            let city = this.value.city ? this.value.city.trim() : '';
            let street = this.value.street ? this.value.street.trim() : '';
            let house = this.value.house ? this.value.house.trim() : '';
            let floor = this.value.floor ? this.value.floor.trim() : '';

            if (oblast) {
                s += oblast;
            }

            if (city) {
                s += ', ' + city;
            }

            if (street) {
                s += ', ' + street;
            }

            if (house) {
                s += ', ' + house;
            }

            if (floor) {
                s += ', ' + floor;
            }

            return s.replace(/^,+/g, '').trim();
        },
        jsonValue() {
            return JSON.stringify({
                oblast: this.oblast,
                city: this.city,
                street: this.street,
                house: this.house,
                floor: this.floor,
            });
        },
    },
    watch: {
        value() {
            if (this.value) {
                this.oblast = this.value.oblast;
                this.city = this.value.city;
                this.street = this.value.street;
                this.house = this.value.house;
                this.floor = this.value.floor;
            }
        },
    }
};
</script>