<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div class="center">Правила работы: {{ worker.email }}</div>
            <div class="right">
                <v-ons-toolbar-button v-show="loaded" @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <v-ons-list>
            <v-ons-list-header>Привязка к филиалу</v-ons-list-header>

            <v-ons-list-item v-show="branches.length > 0" v-for="branch in branches" :key="branch.id" modifier="longdivider" >
                <label :for="'branch-' + branch.id" class="center label-reset" >
                    <span class="list-item__title" >
                        {{ branch.alias }}
                    </span>
                    <span class="list-item__subtitle" >
                        {{ branchSubtitle(branch) }}
                    </span>
                </label>
                <div class="right">
                    <v-ons-checkbox
                        :input-id="'branch-' + branch.id"
                        :value="branch.id"
                        v-model="permissions.availableBranches"
                    ></v-ons-checkbox>
                </div>
            </v-ons-list-item>

            <v-ons-list-item v-show="branches.length == 0" modifier="longdivider" >
                Нет ни одного филиала
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Способ отгрузки по умолчанию:</v-ons-list-header>

            <v-ons-list-item modifier="longdivider" >
                <div class="select-material select--material select">
                    <select class="select-input select-input--material" v-model="defaultShippingMethod" >
                        <option :value="''" >-</option>
                        <option v-for="method in shippingMethods" :value="method.id" >{{ method.name }}</option>
                    </select>
                </div>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            id: null,
            changed: false,
            loaded: false,
            loading: true,
            worker: {},
            shippingMethods: [],
            branches: [],
            defaultShippingMethod: null,
            permissions: {
                availableBranches: [],
                disabledNotifications: [],
            },
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.load();
    },
    methods: {
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        load() {
            this.$http.get(`/api/workers/${this.id}/permissions`).then(response => {
                let data = response.data;

                this.worker = data.worker;
                if (this.worker.permissions) {
                    this.permissions = this.worker.permissions;
                }
                this.defaultShippingMethod = data.defaultShippingMethod;
                this.branches = data.branches;
                this.shippingMethods = data.shippingMethods;

                this.loading = false;

                this.$nextTick(() => {
                    this.loaded = true;
                });
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        save() {
            this.$http.post(`/api/workers/${this.id}/permissions`, {
                permissions: this.permissions,
                defaultShippingMethod: this.defaultShippingMethod,
            }).then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });
                this.$router.go(-1);
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        branchSubtitle(branch) {
            if (!branch.entities || branch.entities.length == 0) {
                return '';
            }

            return branch.entities
                .map(e => e.legal_name)
                .filter(e => e)
                .join(', ');
        },
    },
    watch: {
        permissions: {
            handler(e) {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>
