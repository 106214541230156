<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Создать аккаунт</div>

            <div class="right">
                <v-ons-toolbar-button @click="$router.push('/login')" >
                    <v-ons-icon icon="md-sign-in"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="register-page" >
            <v-ons-button @click="$router.push('/')" >Посмотреть каталог</v-ons-button>

            <v-ons-card class="info-block" >
                Все актуальные цены будут доступны сразу после ввода почты и пароля.
            </v-ons-card>

            <div class="form" >
                <div class="form-group" >
                    <v-ons-input v-model="email" name="email" type="text" placeholder="Email" />
                </div>
                <div class="form-group" >
                    <v-ons-password v-model="password" name="password" placeholder="Пароль" />
                </div>
                <div class="form-submit" >
                    <v-ons-button @click="submit" >Создать аккаунт</v-ons-button>
                </div>

                <div style="margin-top: 40px; text-align: center;" >
                    <v-ons-button @click="$router.push({name: 'forgot', params: {email: email}})" modifier="outline" >Восстановить пароль</v-ons-button>
                </div>
            </div>
        </div>

        <v-ons-dialog :visible.sync="loading" >
            <v-ons-row vertical-align="center" >
                <v-ons-col width="70px" style="text-align: center"  >
                    <v-ons-progress-circular indeterminate></v-ons-progress-circular>
                </v-ons-col>

                <v-ons-col>
                    <div style="line-height: 100px" >Авторизация...</div>
                </v-ons-col>
            </v-ons-row>
        </v-ons-dialog>
    </v-ons-page>
</template>

<script>

import PasswordInput from '~/component/PasswordInput'

export default {
    data() {
        return {
            loading: false,
            email: null,
            password: null,
        }
    },
    created() {
        if (this.$route.params.email) {
            this.email = this.$route.params.email;
        }

        if (this.$route.params.password) {
            this.password = this.$route.params.password;
        }

        if (this.$route.query.manager) {
            this.$bus.referralManager = this.$route.query.manager;
        }

        if (this.$route.query.storage) {
            this.$bus.referralManagerStorage = this.$route.query.storage;
        }
    },
    methods: {
        submit() {
            if (this.loading) {
                return;
            }

            if (!this.email) {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Укажите email',
                });
                return;
            }

            if (!this.password) {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Укажите пароль',
                });
                return;
            }

            this.loading = true;

            let data = {
                email: this.email,
                password: this.password,
                manager: this.$bus.referralManager,
                storage: this.$bus.referralManagerStorage,
            }

            this.$http.post('/api/auth/sign-up', data).then(response => {
                this.loading = false;
                this.$user.setToken(response.data.token);

                if (typeof ym !== 'undefined' && !response.data.registered) {
                    ym(52291717, 'reachGoal', 'REGISTERED_USER');
                }

                this.$bus.$emit('authorized');
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/');
                }
            }, response => {
                this.loading = false;

                if (response.status === 401) {
                    this.$ons.notification.toast({
                        timeout: 3000,
                        message: response.data.message,
                    });
                    return;
                }

                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Произошла ошибка',
                });
            });
        }
    },
    components: {
        'v-ons-password': PasswordInput,
    },
};
</script>