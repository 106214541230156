<template>
    <v-ons-alert-dialog modifier="rowfooter" :visible.sync="visible" >
        <span slot="title">Отправить заказ?</span>

        <div style="margin-top: 10px" >
            <textarea v-model="comment" class="textarea" placeholder="Комментарий к заказу" rows="6" style="width: 100%;" ></textarea>
        </div>

        <template slot="footer">
            <v-ons-alert-dialog-button @click="send">Отправить</v-ons-alert-dialog-button>
            <v-ons-alert-dialog-button @click="cancel">Отмена</v-ons-alert-dialog-button>
        </template>
    </v-ons-alert-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            comment: '',

            resolve: null,
            reject: null,
        }
    },
    methods: {
        show() {
            this.visible = true;
            this.comment = '';

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;

            if (this.resolve) {
                this.resolve({response: false, comment: this.comment});
            }
        },
        send() {
            this.visible = false;

            if (this.resolve) {
                this.resolve({response: true, comment: this.comment});
            }
        },
    },
};
</script>
