var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-alert-dialog",
    {
      attrs: { modifier: "rowfooter", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("Отменить заказ?")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "5px" } },
        [
          _c("v-ons-checkbox", {
            staticStyle: { "vertical-align": "middle" },
            attrs: { "input-id": "chk", value: true },
            model: {
              value: _vm.withNoty,
              callback: function($$v) {
                _vm.withNoty = $$v
              },
              expression: "withNoty"
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticStyle: {
                "font-weight": "normal",
                "vertical-align": "middle",
                margin: "0"
              },
              attrs: { for: "chk" }
            },
            [_vm._v("Отправить уведомление")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "10px" } }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.reason,
              expression: "reason"
            }
          ],
          staticClass: "textarea",
          staticStyle: { width: "100%" },
          attrs: {
            disabled: !_vm.withNoty,
            placeholder: "Укажите причину",
            rows: "4"
          },
          domProps: { value: _vm.reason },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.reason = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("v-ons-alert-dialog-button", { on: { click: _vm.ok } }, [
            _vm._v("ОК")
          ]),
          _vm._v(" "),
          _c("v-ons-alert-dialog-button", { on: { click: _vm.cancel } }, [
            _vm._v("Отмена")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2c440262", { render: render, staticRenderFns: staticRenderFns })
  }
}