import Index from '~/screen/manager/users/Profile'
import Entities from './Entities'
import Contacts from './Contacts'
import Branches from './Branches'

let route = [
    {
        path: 'profile',
        name: 'manager-user-profile',
        component: Index,
        params: true,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            ...Contacts,
            ...Branches,
            ...Entities,
        ],
    },
]

export default route;
