var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Настройки")])
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isManager,
                  expression: "$user.isManager"
                }
              ],
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/settings/onec-connection")
                }
              }
            },
            [_vm._v("\n            Связь с 1С\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isManager,
                  expression: "$user.isManager"
                }
              ],
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/settings/notifications")
                }
              }
            },
            [_vm._v("\n            Уведомления о заказах\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isManager,
                  expression: "$user.isManager"
                }
              ],
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    "/settings/special-prices-notifications"
                  )
                }
              }
            },
            [_vm._v("\n            Уведомления о спец. ценах\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.isCustomer,
                  expression: "$user.isCustomer"
                }
              ],
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/settings/checkout")
                }
              }
            },
            [_vm._v("\n            Настройки заказа\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/settings/change-password")
                }
              }
            },
            [_vm._v("\n            Сменить пароль\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: { click: _vm.reload }
            },
            [_vm._v("\n            Обновить\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "" },
              on: { click: _vm.logout }
            },
            [_vm._v("\n            Выход\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-16ba623b", { render: render, staticRenderFns: staticRenderFns })
  }
}