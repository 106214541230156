var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Оформление заказа")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loaded,
                expression: "!loaded"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-item", { staticClass: "list-header--material" }, [
                _c(
                  "div",
                  {
                    staticClass: "center",
                    staticStyle: { background: "none" }
                  },
                  [
                    _vm._v(
                      "\n                    Способ отгрузки\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.shippingMethods.length !==
                          _vm.allShippingMethods.length,
                        expression:
                          "shippingMethods.length !== allShippingMethods.length"
                      }
                    ],
                    staticClass: "right",
                    staticStyle: { background: "none" },
                    on: { click: _vm.showAllShippingMethods }
                  },
                  [
                    _c("v-ons-icon", {
                      attrs: { icon: "md-tag-more", size: "20px" }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.getShippingMethods, function(method) {
                return _c(
                  "v-ons-list-item",
                  { key: method.id, attrs: { tappable: "" } },
                  [
                    _c(
                      "label",
                      { staticClass: "left" },
                      [
                        _c("v-ons-radio", {
                          attrs: {
                            "input-id": "radio-" + method.id,
                            value: method.id,
                            name: "shipping"
                          },
                          model: {
                            value: _vm.shippingMethod,
                            callback: function($$v) {
                              _vm.shippingMethod = $$v
                            },
                            expression: "shippingMethod"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "center",
                        attrs: { for: "radio-" + method.id }
                      },
                      [_vm._v(_vm._s(method.name))]
                    )
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shippingMethod === "delivery",
                  expression: "shippingMethod === 'delivery'"
                }
              ]
            },
            [
              _c("v-ons-list-header", [_vm._v("Адрес доставки")]),
              _vm._v(" "),
              _vm._l(_vm.branches, function(brn) {
                return _c(
                  "v-ons-list-item",
                  { key: brn.id, attrs: { tappable: "" } },
                  [
                    _c(
                      "label",
                      { staticClass: "left" },
                      [
                        _c("v-ons-radio", {
                          attrs: {
                            "input-id": "radio-" + brn.id,
                            value: brn.id,
                            name: "branch"
                          },
                          model: {
                            value: _vm.branch,
                            callback: function($$v) {
                              _vm.branch = $$v
                            },
                            expression: "branch"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "center",
                        attrs: { for: "radio-" + brn.id }
                      },
                      [
                        _c("span", { staticClass: "list-item__title" }, [
                          _vm._v(_vm._s(brn.shortAddress))
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: brn.route && brn.route.id == 2,
                                expression: "brn.route && brn.route.id == 2"
                              }
                            ],
                            staticClass: "list-item__subtitle",
                            staticStyle: { color: "#ef3e29" }
                          },
                          [
                            _vm._v(
                              "\n                        Доставка не осуществляется\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !brn.route,
                                expression: "!brn.route"
                              }
                            ],
                            staticClass: "list-item__subtitle",
                            staticStyle: { color: "#ef3e29" }
                          },
                          [
                            _vm._v(
                              "\n                        Для этого адреса еще не согласован маршрут и график доставки.\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.availBranchByOrderCost(brn),
                                expression: "!availBranchByOrderCost(brn)"
                              }
                            ],
                            staticClass: "list-item__subtitle",
                            staticStyle: { color: "#ef3e29" }
                          },
                          [
                            _vm._v(
                              "\n                        Минимальная сумма для заказа: " +
                                _vm._s(
                                  brn.route ? brn.route.refusal_delivery_to : ""
                                ) +
                                " руб.\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.hasDeliveryCharge(brn),
                                expression: "hasDeliveryCharge(brn)"
                              }
                            ],
                            staticClass: "list-item__subtitle",
                            staticStyle: { color: "#ef3e29" }
                          },
                          [
                            _vm._v(
                              "\n                        Стоимость доставки: " +
                                _vm._s(
                                  brn.route ? brn.route.delivery_cost : ""
                                ) +
                                " руб.\n                    "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !brn.route,
                            expression: "!brn.route"
                          }
                        ],
                        staticClass: "right",
                        on: {
                          click: function($event) {
                            return _vm.linkRoute(brn)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "notify-icon",
                            staticStyle: { "font-size": "13px" }
                          },
                          [_c("v-ons-icon", { attrs: { icon: "md-gps-dot" } })],
                          1
                        )
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.branches.length,
                      expression: "!branches.length"
                    }
                  ]
                },
                [
                  _c(
                    "v-ons-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "order-checkout-create-branch"
                          })
                        }
                      }
                    },
                    [_vm._v("Добавить адрес")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canShowShippingDate,
                  expression: "canShowShippingDate"
                }
              ]
            },
            [
              _vm.shippingMethod === "delivery"
                ? _c("v-ons-list-header", [_vm._v("Дата доставки")])
                : _vm._e(),
              _vm._v(" "),
              _vm.shippingMethod === "pickup"
                ? _c("v-ons-list-header", [_vm._v("Дата самовывоза")])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                { on: { click: _vm.openShippingDateDialog } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "311px",
                        "border-bottom": "1px solid #afafaf",
                        "line-height": "28px"
                      }
                    },
                    [
                      _c("v-ons-icon", {
                        staticStyle: {
                          "margin-right": "8px",
                          "font-size": "20px",
                          "vertical-align": "middle"
                        },
                        attrs: { icon: "md-calendar" }
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("moment")(_vm.shippingDate, "DD.MM.YYYY")
                          ) +
                          "\n                "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-ons-dialog",
                    {
                      attrs: {
                        cancelable: "",
                        visible: _vm.isShippingDateDialogVisible
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.isShippingDateDialogVisible = $event
                        }
                      }
                    },
                    [
                      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
                        _c("div", { staticClass: "center" }, [
                          _vm.shippingMethod === "delivery"
                            ? _c("div", [_vm._v("Дата доставки")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.shippingMethod === "pickup"
                            ? _c("div", [_vm._v("Дата самовывоза")])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c(
                              "v-ons-toolbar-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.isShippingDateDialogVisible = false
                                  }
                                }
                              },
                              [
                                _c("v-ons-icon", {
                                  attrs: { icon: "md-close" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("datepicker", {
                        attrs: {
                          inline: true,
                          "monday-first": true,
                          language: _vm.ru,
                          disabledDates: { customPredictor: _vm.disabledDates }
                        },
                        on: {
                          selected: function($event) {
                            _vm.isShippingDateDialogVisible = false
                          }
                        },
                        model: {
                          value: _vm.shippingDate,
                          callback: function($$v) {
                            _vm.shippingDate = $$v
                          },
                          expression: "shippingDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.whetherShowEntityList,
                  expression: "whetherShowEntityList"
                }
              ]
            },
            [
              _c("v-ons-list-item", { staticClass: "list-header--material" }, [
                _c(
                  "div",
                  {
                    staticClass: "center",
                    staticStyle: { background: "none" }
                  },
                  [_vm._v("\n                    Юр. лицо\n                ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getEntities.length !== _vm.entities.length,
                        expression: "getEntities.length !== entities.length"
                      }
                    ],
                    staticClass: "right",
                    staticStyle: { background: "none" },
                    on: { click: _vm.showAllEntities }
                  },
                  [
                    _c("v-ons-icon", {
                      attrs: { icon: "md-tag-more", size: "20px" }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.getEntities, function(ent) {
                return _c(
                  "v-ons-list-item",
                  { key: ent.id, attrs: { tappable: "" } },
                  [
                    _c(
                      "label",
                      { staticClass: "left" },
                      [
                        _c("v-ons-radio", {
                          attrs: {
                            "input-id": "entity-" + ent.id,
                            value: ent.id,
                            name: "entity"
                          },
                          model: {
                            value: _vm.entity,
                            callback: function($$v) {
                              _vm.entity = $$v
                            },
                            expression: "entity"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "center",
                        attrs: { for: "entity-" + ent.id }
                      },
                      [
                        _c("span", { staticClass: "list-item__title" }, [
                          _vm._v(_vm._s(ent.legal_name))
                        ])
                      ]
                    )
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.whetherShowAssignEntityCheckbox,
                  expression: "whetherShowAssignEntityCheckbox"
                }
              ]
            },
            [
              _c("v-ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "left",
                    attrs: { for: "assign-entity-checkbox" }
                  },
                  [
                    _c("v-ons-checkbox", {
                      attrs: {
                        "input-id": "assign-entity-checkbox",
                        disabled: !_vm.entity
                      },
                      model: {
                        value: _vm.isNeedToAssignEntity,
                        callback: function($$v) {
                          _vm.isNeedToAssignEntity = $$v
                        },
                        expression: "isNeedToAssignEntity"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "assign-entity-checkbox" }
                  },
                  [
                    _vm._v(
                      "\n                    Привязать это юр.лицо к филиалу\n                "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.whetherShowPaymentMethods,
                  expression: "whetherShowPaymentMethods"
                }
              ]
            },
            [
              _c("v-ons-list-item", { staticClass: "list-header--material" }, [
                _c(
                  "div",
                  {
                    staticClass: "center",
                    staticStyle: { background: "none" }
                  },
                  [
                    _vm._v(
                      "\n                    Способ оплаты\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.getPaymentMethods.length !==
                          _vm.paymentMethods.length,
                        expression:
                          "getPaymentMethods.length !== paymentMethods.length"
                      }
                    ],
                    staticClass: "right",
                    staticStyle: { background: "none" },
                    on: { click: _vm.showAllPaymentMethods }
                  },
                  [
                    _c("v-ons-icon", {
                      attrs: { icon: "md-tag-more", size: "20px" }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.getPaymentMethods, function(method) {
                return _c(
                  "v-ons-list-item",
                  { key: method.id, attrs: { tappable: "" } },
                  [
                    _c(
                      "label",
                      { staticClass: "left" },
                      [
                        _c("v-ons-radio", {
                          attrs: {
                            "input-id": "paymentMethod-" + method.id,
                            value: method.id,
                            name: "paymentMethod"
                          },
                          model: {
                            value: _vm.paymentMethod,
                            callback: function($$v) {
                              _vm.paymentMethod = $$v
                            },
                            expression: "paymentMethod"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "center",
                        attrs: { for: "paymentMethod-" + method.id }
                      },
                      [
                        _c("span", { staticClass: "list-item__title" }, [
                          _vm._v(_vm._s(method.name))
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: method.id == 2 && method.tax > 0,
                                expression: "method.id == 2 && method.tax > 0"
                              }
                            ],
                            staticClass: "list-item__subtitle",
                            staticStyle: { color: "#ef3e29" }
                          },
                          [
                            _vm._v(
                              "\n                        Доп. комиссия +" +
                                _vm._s(parseFloat(method.tax)) +
                                "%\n                    "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Комментарий")]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.manager_comment,
                      expression: "manager_comment"
                    }
                  ],
                  staticClass: "textarea",
                  staticStyle: { width: "100%", height: "100px" },
                  attrs: { placeholder: "Комментарий к заказу для менеджера" },
                  domProps: { value: _vm.manager_comment },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.manager_comment = $event.target.value
                    }
                  }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { width: "100%", height: "50px" } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        {
          attrs: { visible: _vm.splashVisible },
          on: {
            "update:visible": function($event) {
              _vm.splashVisible = $event
            }
          }
        },
        [
          _c(
            "v-ons-row",
            { attrs: { "vertical-align": "center" } },
            [
              _c(
                "v-ons-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { width: "70px" }
                },
                [
                  _c("v-ons-progress-circular", {
                    attrs: { indeterminate: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-col", [
                _c("div", { staticStyle: { "line-height": "100px" } }, [
                  _vm._v("Отправка заказа...")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("pre-send-order-dialog", { ref: "preSendOrderDialog" }),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loaded,
              expression: "loaded"
            }
          ],
          attrs: { position: "bottom right", disabled: !_vm.canSend },
          on: { click: _vm.preSendOrder }
        },
        [
          _c("v-ons-icon", {
            attrs: { icon: "md-mail-send", title: "Отправить заказ" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-radio-dialog", {
        ref: "radioDialog",
        attrs: { title: "Опции менеджера" }
      }),
      _vm._v(" "),
      _c("v-send-order-dialog", { ref: "sendOrderDialog" }),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "linkRouteDialog",
        attrs: { title: "Привязать маршрут", items: _vm.routes }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7b45a7a3", { render: render, staticRenderFns: staticRenderFns })
  }
}