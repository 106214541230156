<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Закрепить менеджера: {{ user.email }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item
                    v-for="manager in managers"
                    @click="user.manager_id = manager.id"
                    class="cursor-pointer"
                    :key="manager.id"
            >
                <div class="center" >
                    {{ [manager.name, manager.email].filter(i => i).join(' - ') }}
                </div>

                <div class="right" >
                    <v-ons-icon v-show="user.manager_id == manager.id" icon="md-check"></v-ons-icon>
                </div>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            user: {},
            managers: [],
        }
    },
    created() {
        this.$http.get('/api/manager/users/' + this.$route.params.user_id).then(response => {
            this.user = response.data;
        });

        this.$http.get('/api/manager/users/managers').then(response => {
            this.managers = response.data;
        });
    },
    methods: {
        save() {
            this.$http.post('/api/manager/users/set-manager', {manager: this.user.manager_id}, {params: {id: this.$route.params.user_id}}).then(() => {
                this.$router.go(-1);
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Менеджер закреплен.',
                    buttonLabel: 'OK',
                });
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK',
                });
            });
        },
    },
};
</script>
