var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-ons-list",
        { on: { click: _vm.onClick } },
        [
          _c(
            "v-ons-list-item",
            { attrs: { tappable: "" } },
            [
              _c("router-link", { attrs: { to: "/settings" } }, [
                _vm._v("Вход")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            { attrs: { tappable: "" } },
            [
              _c("router-link", { attrs: { to: "/mymanager" } }, [
                _vm._v("Ваш менеджер")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            { attrs: { tappable: "" } },
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Каталог")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$refs.downloadCatalogPromptDialog.show()
                }
              }
            },
            [
              _c("router-link", { attrs: { to: "" } }, [
                _vm._v("Скачать прайс")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            { staticClass: "info-bar", attrs: { tappable: "" } },
            [
              _c("router-link", { attrs: { to: "/register" } }, [
                _vm._v("Доступ к прайсу")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("download-catalog-prompt-dialog", {
        ref: "downloadCatalogPromptDialog"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e7b9af5c", { render: render, staticRenderFns: staticRenderFns })
  }
}