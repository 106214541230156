import Products from '~/screen/userProduct/List';
import TemplateView from '~/screen/userProduct/TemplateView';
import ProductsHistory from '~/screen/userProduct/History';
import ProductRoute from '~/routes/ProductRoute';

export default function (basename) {
    return {
        path: 'products',
        name: basename + '-manager-user-products',
        component: Products,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
            userProductEntrypoint: '/api/manager/users/$userId/user-products',
            userProductSearchEntrypoint: '/api/manager/users/$userId/catalog-search',
            showBackButton: true,
            displayedButtons: ['showGroups'],
        },
        children: [
            {
                path: 'template/:id',
                name: basename + '-manager-template-view',
                component: TemplateView,
                params: true,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                    userProductEntrypoint: '/api/manager/users/$userId/user-products',
                    userProductSearchEntrypoint: '/api/manager/users/$userId/catalog-search',
                },
                children: [
                    ProductRoute(basename + '-manager-user-template'),
                ]
            },
            {
                path: 'history',
                name: basename + '-manager-product-list-history',
                component: ProductsHistory,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                    userProductEntrypoint: '/api/manager/users/$userId/user-products',
                },
            },
            ProductRoute(basename + '-manager-user'),
        ],
    };
}