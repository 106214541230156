var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Перемещение/Удаление товара")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.id
              ? _c(
                  "v-ons-toolbar-button",
                  { attrs: { title: "Удалить" }, on: { click: _vm.remove } },
                  [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Сохранить" }, on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("ons-list-item", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.rule.product.name) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c("div", [_vm._v("Из раздела:")]),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-left": "10px" } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.categoryFromName) +
                      "\n                "
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-item", [
            _c(
              "div",
              {
                staticClass: "left",
                staticStyle: { "margin-right": "10px", "min-width": "0" }
              },
              [
                _c("v-ons-radio", {
                  attrs: { "input-id": "delete", value: "delete" },
                  model: {
                    value: _vm.rule.type,
                    callback: function($$v) {
                      _vm.$set(_vm.rule, "type", $$v)
                    },
                    expression: "rule.type"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "label-reset center",
                staticStyle: { background: "none" },
                attrs: { for: "delete" }
              },
              [_vm._v("\n                Удалить\n            ")]
            )
          ]),
          _vm._v(" "),
          _c("v-ons-list-item", [
            _c(
              "div",
              {
                staticClass: "left",
                staticStyle: { "margin-right": "10px", "min-width": "0" }
              },
              [
                _c("v-ons-radio", {
                  attrs: { "input-id": "replace", value: "replace" },
                  model: {
                    value: _vm.rule.type,
                    callback: function($$v) {
                      _vm.$set(_vm.rule, "type", $$v)
                    },
                    expression: "rule.type"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "label-reset center",
                staticStyle: { background: "none" },
                attrs: { for: "replace" }
              },
              [_vm._v("\n                Переместить\n            ")]
            )
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.rule.type === "replace",
                  expression: "rule.type === 'replace'"
                }
              ]
            },
            [
              _c("ons-list-item", [
                _c("div", [_vm._v("В раздел:")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "select-material select--material select",
                    staticStyle: { "margin-left": "10px", width: "auto" }
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.rule.categoryTo.id,
                            expression: "rule.categoryTo.id"
                          }
                        ],
                        staticClass: "select-input select-input--material",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.rule.categoryTo,
                              "id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _vm.rule.categoryTo.isDeleted
                          ? _c(
                              "option",
                              {
                                attrs: { disabled: true },
                                domProps: { value: "" }
                              },
                              [_vm._v("Раздел не найден")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.categories, function(category) {
                          return _c(
                            "option",
                            {
                              key: category.id,
                              domProps: { value: category.id }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(category.name) +
                                  "\n                        "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f917a61e", { render: render, staticRenderFns: staticRenderFns })
  }
}