<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">{{ user.email }}</div>

            <div class="right">
                <v-ons-toolbar-button
                    @click="$router.push($router.currentRoute.path + '/products')"
                    title="Перейти к товарам клиента"
                >
                    <v-ons-icon icon="md-folder-star-alt"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <ons-list >
                <ons-list-item
                    v-for="product in products"
                    :key="product.id"
                    :modifier="product.replacement.id || product.avail || product.archive ? 'longdivider' : 'chevron longdivider'"
                    tappable >

                    <div class="center" @click="$router.push({name: 'manager-product-link-users-product', params: {product_id: product.id}})" >
                        <span class="list-item__title" >{{ product.name }}</span>
                        <span class="list-item__subtitle" v-show="'product' in product && !product.product" >Пользовательский товар</span>
                        <span class="list-item__subtitle" v-show="product.replacement.id" >{{ product.replacement.name }}</span>
                        <span class="list-item__subtitle" v-show="product.avail" >Ожидаем поступление</span>
                        <span class="list-item__subtitle" v-show="product.archive" >Удалить</span>
                    </div>

                    <div class="right" v-show="product.replacement.id" @click="cancel(product)" >
                        <v-ons-button modifier="quiet" >
                            <v-ons-icon icon="md-close"></v-ons-icon>
                        </v-ons-button>
                    </div>
                </ons-list-item>
            </ons-list>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            loading: true,

            user: {},
            products: [],
        }
    },
    created() {
        this.storageId = this.$route.params.storage_id;
        this.load();
        this.$bus.$on('chooseProduct', this.choose);
        this.$bus.$on('setAvail', this.setAvail);
        this.$bus.$on('setArchive', this.setArchive);
    },
    destroyed() {
        this.$bus.$off('chooseProduct');
        this.$bus.$off('setAvail');
        this.$bus.$off('setArchive');
    },
    methods: {
        load() {
            let requests = [
                this.$http.get(
                    '/api/manager/product-link/get-products-by-user',
                    {
                        params: {
                            id: this.$route.params.user_id,
                            storageId: this.storageId,
                        }
                    }
                ),
                this.$http.get('/api/manager/users/' + this.$route.params.user_id),
            ];

            Promise.all(requests).then(responses => {
                let products = responses[0].data;
                products.forEach(i => { i.replacement = {} });
                this.products = products;

                this.user = responses[1].data;

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        setAvail(data) {
            let product = this.products.find(i => i.id == data.product_id);

            product.replacement = {};
            product.avail = true;
            product.archive = false;
        },
        setArchive(data) {
            let product = this.products.find(i => i.id == data.product_id);

            product.replacement = {};
            product.avail = false;
            product.archive = true;
        },
        choose(data) {
            let product = this.products.find(i => i.id == data.product_id);

            product.replacement = data.replacement;
            product.avail = false;
            product.archive = false;
        },
        cancel(product) {
            product.replacement = {};
            product.avail = false;
            product.archive = false;
        },
        save() {
            let replacements = this.products
                .filter(i => i.replacement.id)
                .map(i => {
                    return {
                        user_product: i.id,
                        product: i.replacement.id
                    }
                });

            let avails = this.products
                .filter(i => i.avail)
                .map(i => {
                    if (i.product_id) {
                        return {product_id: i.product_id};
                    } else {
                        return {user_product_id: i.id};
                    }
                });

            let removes = this.products
                .filter(i => i.archive)
                .map(i => {
                    if (i.product_id) {
                        return {product_id: i.product_id};
                    } else {
                        return {user_product_id: i.id};
                    }
                });

            let data = {
                replacements: replacements,
                avails: avails,
                removes: removes,
                user_id: this.$route.params.user_id,
            };

            this.$http.post('/api/manager/product-link/relink', data).then(() => {
                this.$bus.$emit('loadUsersLink');
                this.$router.push({name: 'manager-product-link-users'});
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                });
            });
        },
        goBack() {
            let hasChange = false;

            for (let product of this.products) {
                if (product.replacement.id) {
                    hasChange = true;
                    break;
                }

                if (product.avail) {
                    hasChange = true;
                    break;
                }

                if (product.archive) {
                    hasChange = true;
                    break;
                }
            }

            if (!hasChange) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
    },
};
</script>