<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Связь с 1С</div>
            <div class="right" >
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content" >
            <div v-show="connections.length > 0" >
                <div
                    v-for="connection in connections"
                    :key="connection.storage.id"
                    style="margin-bottom: 15px"
                >
                    <div style="padding: 8px 16px 0px; font-size: 17px; font-weight: bold">
                        {{ connection.storage.name }}
                    </div>

                    <v-ons-list>
                        <v-ons-list-header>ID менеджера</v-ons-list-header>
                        <v-ons-list-item>
                            <v-ons-input v-model="connection.onecId" type="text" ></v-ons-input>
                        </v-ons-list-item>
                    </v-ons-list>

                    <v-ons-list>
                        <v-ons-list-header>ID универсального клиента для выгрузки заказов</v-ons-list-header>
                        <v-ons-list-item>
                            <v-ons-input v-model="connection.defaultOnecUserId" type="text" ></v-ons-input>
                        </v-ons-list-item>
                    </v-ons-list>
                </div>
            </div>

            <v-ons-list>
                <v-ons-list-item @click="$router.push('/profile/manager-entities')" class="cursor-pointer">
                    <div class="left">
                        <v-ons-icon icon="md-arrow-right" size="20px" style="margin: 0 auto"></v-ons-icon>
                    </div>
                    <div class="center">
                        Перейти к списку юр.лиц поставщика
                        <span class="list-item__subtitle">ID устанавливается в каждом юр.лице поставщика</span>
                    </div>
                </v-ons-list-item>

                <v-ons-list-item @click="$router.push('/manager/users')" class="cursor-pointer">
                    <div class="left">
                        <v-ons-icon icon="md-arrow-right" size="20px" style="margin: 0 auto"></v-ons-icon>
                    </div>
                    <div class="center">
                        Перейти к списку клиентов
                        <span class="list-item__subtitle">ID устанавливается в профиле каждого клиента</span>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <div v-show="!connections.length" class="" >
                <div style="font-size: 20px; color: #a8a8a8; position: absolute; top: 50%; text-align: center; width: 100%; line-height: 50px; margin-top: -25px;" >
                    Список пуст<br>
                </div>
            </div>

        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            connections: [],
            loading: true,
        }
    },
    created() {
        this.$http.get('/api/settings/onec-connections').then(response => {
            this.connections = response.data;
            console.log(this.connections)

            this.$nextTick(() => {
                this.loading = false;
            });
        });
    },
    methods: {
        save() {
            if (this.loading) {
                return;
            }

            let connections = this.connections.map(c => {
                c.storageId = c.storage.id
                delete c.storage
                return c
            })

            this.$http.post('/api/settings/onec-connections', {connections: connections}).then(() => {
                this.$router.goRouteBack();

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Ваши данные сохранены',
                    buttonLabel: 'OK',
                });
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                    buttonLabel: 'OK',
                });
            });
        },
    },
};
</script>