var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6c2e6130", { render: render, staticRenderFns: staticRenderFns })
  }
}