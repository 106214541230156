var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v(
            "Заказ №" +
              _vm._s(_vm.order.id) +
              " от " +
              _vm._s(_vm.order.createdAtFormat)
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "toolbar-popover-menu",
              { attrs: { "popover-class": "stick-right" } },
              [
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.order &&
                        (!_vm.$user.isWorker ||
                          _vm.$user.orderSendMethod === "direct"),
                      expression:
                        "order && (!$user.isWorker || $user.orderSendMethod === 'direct')"
                    }
                  ],
                  attrs: { text: "Отменить", icon: "md-block" },
                  on: {
                    click: function($event) {
                      return _vm.cancelOrder()
                    }
                  }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.order,
                      expression: "order"
                    }
                  ],
                  attrs: { text: "Скачать в PDF", icon: "md-download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf()
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "order-view-page" }, [
          _c("div", { staticClass: "form-group" }, [
            _vm._v(
              "\n                Номер заказа: " +
                _vm._s(_vm.order.id) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.order.status_id === "confirmed" &&
                    _vm.order.invoice_number,
                  expression:
                    "order.status_id === 'confirmed' && order.invoice_number"
                }
              ],
              staticClass: "form-group invoice-number"
            },
            [
              _vm._v(
                "\n                Номер накладной: " +
                  _vm._s(_vm.order.invoice_number) +
                  "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._v(
              "\n                Статус: " +
                _vm._s(_vm.order.status) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$user.isWorker,
                  expression: "!$user.isWorker"
                }
              ],
              staticClass: "form-group"
            },
            [
              _vm._v(
                "\n                Способ оплаты: " +
                  _vm._s(_vm.order.paymentMethodLabel) +
                  "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._v(
              "\n                Способ отгрузки: " +
                _vm._s(_vm.order.shippingMethodLabel) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.order.shippingMethod === "delivery",
                  expression: "order.shippingMethod === 'delivery'"
                }
              ],
              staticClass: "form-group"
            },
            [
              _vm._v("\n                Адрес доставки:\n                "),
              _vm.order.branch
                ? _c("span", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.order.branch.shortAddress) +
                        "\n                "
                    )
                  ])
                : _c("span", [_vm._v("не указан")]),
              _vm._v(" "),
              _vm.order.entity
                ? _c("span", [
                    _vm._v(
                      "\n                    (" +
                        _vm._s(_vm.order.entity.legal_name) +
                        ")\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.order.shippingMethod === "pickup" && _vm.order.entity
            ? _c("div", { staticClass: "form-group" }, [
                _vm._v(
                  "\n                Юр. лицо: " +
                    _vm._s(_vm.order.entity.legal_name) +
                    "\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._v(
              "\n                Дата отгрузки: " +
                _vm._s(_vm.order.shippingDate) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._v(
              "\n                Комментарий: " +
                _vm._s(_vm.order.comment ? _vm.order.comment : "не указан") +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table" },
            [
              _vm._l(_vm.unwaitingPositions, function(position, index) {
                return _c(
                  "tr",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.positionView(position)
                      }
                    }
                  },
                  [
                    _c("td", [_vm._v(_vm._s(index + 1))]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-name" }, [
                      _vm._v(_vm._s(position.name))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-count" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(position.count) +
                          " " +
                          _vm._s(position.unit) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$user.canSeePrice,
                            expression: "$user.canSeePrice"
                          }
                        ],
                        class: [
                          "col-price",
                          position.specialPrice.isShowMark
                            ? "is-low-special-price"
                            : ""
                        ]
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(position.price) +
                            " руб.\n                    "
                        )
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.waitingPositions.length > 0,
                      expression: "waitingPositions.length > 0"
                    }
                  ]
                },
                [
                  _c(
                    "td",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        background: "#e3e3e3"
                      },
                      attrs: { colspan: "4" }
                    },
                    [
                      _vm._v(
                        "\n                        Добавить к заказу, если есть в наличии\n                    "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.waitingPositions, function(position, index) {
                return _c(
                  "tr",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.positionView(position)
                      }
                    }
                  },
                  [
                    _c("td", [
                      _vm._v(_vm._s(index + _vm.unwaitingPositions.length + 1))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-name" }, [
                      _vm._v(_vm._s(position.name))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-count" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(position.count) +
                          " " +
                          _vm._s(position.unit) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$user.canSeePrice,
                            expression: "$user.canSeePrice"
                          }
                        ],
                        class: [
                          "col-price",
                          position.specialPrice.isShowMark
                            ? "is-low-special-price"
                            : ""
                        ]
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(position.price) +
                            " руб.\n                    "
                        )
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.order.hasCostForShipping > 0 &&
                        _vm.$user.canSeePrice,
                      expression:
                        "order.hasCostForShipping > 0 && $user.canSeePrice"
                    }
                  ]
                },
                [
                  _c("td"),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-name" }, [_vm._v("Доставка")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-count" }),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-price" }, [
                    _vm._v(_vm._s(_vm.order.cost_for_shipping) + " руб.")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.order.cost_for_shipping > 0 &&
                        !_vm.$user.canSeePrice,
                      expression:
                        "order.cost_for_shipping > 0 && !$user.canSeePrice"
                    }
                  ]
                },
                [
                  _c("td"),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "col-name", attrs: { colspan: "3" } },
                    [_vm._v("Платная доставка")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.order.paymentMethod > 0 &&
                        _vm.order.terminalTax &&
                        _vm.$user.canSeePrice,
                      expression:
                        "order.paymentMethod > 0 && order.terminalTax && $user.canSeePrice"
                    }
                  ]
                },
                [
                  _c("td"),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-name" }, [
                    _vm._v("Комиссия за оплату по терминалу")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-count" }),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-price" }, [
                    _vm._v(_vm._s(_vm.order.terminalTax) + " руб.")
                  ])
                ]
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$user.canSeePrice,
                  expression: "$user.canSeePrice"
                }
              ],
              staticClass: "total-order-wrap"
            },
            [
              _c("div", { staticClass: "wrap total-order" }, [
                _c("div", { staticClass: "col col-label" }, [
                  _c("div", [_vm._v("Сумма")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col col-total-price" }, [
                  _c("div", { staticClass: "total-price-wrap" }, [
                    _vm._v(_vm._s(_vm.order.totalCost) + " руб.")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.order.hasDiscount,
                      expression: "order.hasDiscount"
                    }
                  ],
                  staticClass: "wrap total-order"
                },
                [
                  _c("div", { staticClass: "col col-label" }, [
                    _c("div", [_vm._v("Сумма со скидкой")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col col-total-price" }, [
                    _c("div", { staticClass: "total-price-wrap" }, [
                      _vm._v(_vm._s(_vm.order.totalCostWithDiscount) + " руб.")
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm.order.worker ? _c("hr") : _vm._e(),
          _vm._v(" "),
          _vm.order.worker
            ? _c("div", [
                _c("p", [
                  _vm._v(
                    "Создан: " +
                      _vm._s(_vm._f("moment")(_vm.order.createdAt, "L LT"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("Отправлен: " + _vm._s(_vm.sendedTimeLabel))]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("Ответственный: "),
                  _c(
                    "a",
                    {
                      attrs: { href: "/manager/users/" + _vm.order.worker.id }
                    },
                    [_vm._v(_vm._s(_vm.order.worker.email))]
                  )
                ])
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-205d7760", { render: render, staticRenderFns: staticRenderFns })
  }
}