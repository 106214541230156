var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Перемещение/Удаление товаров")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "toolbar-popover-menu",
              { attrs: { "popover-class": "stick-right" } },
              [
                _c("toolbar-popover-menu-item", {
                  attrs: { text: "Каталог удалений", icon: "md-delete" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name:
                          "manager-catalog-settings-product-rules-deleted-catalog"
                      })
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content", staticStyle: { "padding-bottom": "160px" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.rules.length > 0,
                  expression: "rules.length > 0"
                }
              ]
            },
            _vm._l(_vm.rulesByTypes, function(rulesByType, key) {
              return _c(
                "div",
                { key: key },
                [
                  _c(
                    "v-ons-list",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: rulesByType.items.length > 0,
                          expression: "rulesByType.items.length > 0"
                        }
                      ],
                      staticStyle: { "margin-bottom": "30px" }
                    },
                    [
                      _c(
                        "v-ons-list-header",
                        { staticStyle: { "margin-bottom": "-10px" } },
                        [_vm._v(_vm._s(rulesByType.name))]
                      ),
                      _vm._v(" "),
                      _vm._l(rulesByType.items, function(rule, key) {
                        return _c(
                          "ons-list-item",
                          {
                            key: key,
                            attrs: { modifier: "chevron longdivider" },
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name:
                                    "manager-catalog-settings-product-rules-update",
                                  params: { product_id: rule.product.id }
                                })
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "center label-reset" }, [
                              _c("span", { staticClass: "list-item__title" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(rule.product.name) +
                                    "\n                            "
                                )
                              ]),
                              _vm._v(" "),
                              rule.product.is_deleted
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "list-item__subtitle",
                                      staticStyle: { color: "red" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Нет в наличии.\n                                " +
                                          _vm._s(
                                            rule.product.countProductLinks > 0
                                              ? "Ждём"
                                              : ""
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "list-item__subtitle" },
                                [
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        color:
                                          !rule.categoryFrom ||
                                          rule.categoryFrom.isDeleted
                                            ? "red"
                                            : ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        '\n                                    "' +
                                          _vm._s(
                                            rule.categoryFrom &&
                                              rule.categoryFrom.name
                                              ? rule.categoryFrom.name
                                              : "Раздел не найден"
                                          ) +
                                          '"\n                                '
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-ons-icon", {
                                    attrs: { icon: "md-arrow-right" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: rule.type === "delete",
                                          expression: "rule.type === 'delete'"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Товар удалён\n                                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: rule.type === "replace",
                                          expression: "rule.type === 'replace'"
                                        }
                                      ],
                                      style: {
                                        color: rule.categoryTo.isDeleted
                                          ? "red"
                                          : ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        '\n                                    "' +
                                          _vm._s(
                                            rule.categoryTo.name ||
                                              "Раздел не найден"
                                          ) +
                                          '"\n                                '
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.rules.length === 0,
                  expression: "rules.length === 0"
                }
              ],
              staticClass: "list-empty",
              style: {
                flexFlow: "column",
                height: _vm.$window.height - 56 + "px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-bottom": "10px", "font-size": "15px" }
                },
                [_vm._v("\n                Данные не обнаружены\n            ")]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          attrs: { position: "bottom right" },
          on: {
            click: function($event) {
              return _vm.$router.push({
                name: "manager-catalog-settings-product-rules-create"
              })
            }
          }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4d20d99b", { render: render, staticRenderFns: staticRenderFns })
  }
}