<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Сотрудники</div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div v-if="workers.length == 0" class="list-empty" :style="{height: $window.height - 56 + 'px'}" >
            Список пуст
        </div>

        <v-ons-list>
            <v-ons-list-item v-for="worker in workers" :key="worker.id" >
                <div class="center" @click="$router.push({name: 'profile-worker', params: {id: worker.id}})" >
                    {{ worker.email }}
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-fab position="bottom right" v-show="!loading" @click="$router.push({name: 'profile-worker-create'})" >
            <v-ons-icon icon="md-plus"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            workers: [],
        }
    },
    created() {
        this.load();
        this.$bus.$on('update-workers', this.load);
    },
    destroyed() {
        this.$bus.$off('update-workers');
    },
    methods: {
        load() {
            this.$http.get('/api/workers').then(response => {
                this.workers = response.data;
                this.loading = false;
            });
        },
    },
};
</script>
