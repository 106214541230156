<template>
    <div :class="['position-card-wrap', isDeleted ? 'position-deleted' : '']" >
        <v-ons-card class="position-card">
            <div class="position-wrap">
                <div v-if="!isEditing" class="popover-menu" :id="popoverId" @click="popoverMenuVisible = true">
                    <v-ons-icon icon="md-more-vert"></v-ons-icon>

                    <v-ons-popover cancelable
                                   :visible.sync="popoverMenuVisible"
                                   :target="'#' + popoverId"
                                   cover-target="true"
                                   direction="down"
                                   class="order-popover-menu"
                    >
                        <ons-list @click="popoverMenuVisible = false;">
                            <ons-list-item v-show="!isEditing && false" @click="update">
                                <div class="left icon">
                                    <v-ons-icon icon="md-edit"></v-ons-icon>
                                </div>
                                <div class="center">Изменить товар</div>
                            </ons-list-item>

                            <ons-list-item v-show="position.prd_id" @click="$emit('positionView', position)">
                                <div class="left icon">
                                    <v-ons-icon icon="md-image-o"></v-ons-icon>
                                </div>
                                <div class="center">Карточка товара</div>
                            </ons-list-item>

                            <ons-list-item @click="remove">
                                <div class="left icon">
                                    <v-ons-icon icon="md-delete"></v-ons-icon>
                                </div>
                                <div class="center">Удалить</div>
                            </ons-list-item>
                        </ons-list>
                    </v-ons-popover>
                </div>

                <div class="position-index">
                    {{ position.index }}
                </div>

                <div class="row-wrap">
                    <div v-show="!isEditing" class="col col-name">
                        <div class="position-name">
                            {{ position.name }}
                        </div>

                        <div v-if="position.is_archive">
                            <small>этого товара нет у выбранного поставщика</small>
                        </div>
                    </div>

                    <div v-show="isEditing" class="col col-name">
                        <input-proposal-create
                            ref="input"
                            :scrollPageOnFocus="true"
                            :disableScrollPage="true"
                            :addedPositions="addedPositions"
                            :columns="$user.canSeePrice ? ['name', 'price'] : ['name']"
                            :preventAddedChoose="true"
                            :urlSearch="urlSearch"

                            @choose="chooseProposal"
                            @showProposals="$emit('showProposals')"
                            @hideProposals="$emit('hideProposals')"
                        />
                    </div>

                    <div class="col col-unit">
                        <div v-if="position.prd_id">
                            {{ position.unit }}
                        </div>
                    </div>

                    <div class="col col-count" v-if="$window.isDesktop">
                        <div class="count-wrap">
                            <div class="wrap">
                                <div class="col col-button" v-if="!position.count || position.count == 0">
                                    <button @click="remove" tabindex="-1">
                                        <i class="fa fa-fw fa-remove"></i>
                                    </button>
                                </div>
                                <div class="col col-button" v-else>
                                    <button @click="onPositionDecCount" tabindex="-1">-</button>
                                </div>

                                <div class="col col-input">
                                    <input
                                        type="number"
                                        v-round
                                        class="input-count"
                                        v-model="position.count"
                                        :step="position.mult"
                                        :name="'count-a-' + position.pos_id"
                                        @change="onChangeCount"
                                        @blur="onBlur"
                                        @keydown="chooseCountHandler"
                                    >
                                </div>
                                <div class="col col-button">
                                    <button @click="onPositionIncCount" tabindex="-1">+</button>
                                </div>
                            </div>
                            <div class="package-wrap" v-show="position.mult != 1 && position.count > 0">
                                {{ positionPackage(position) }}
                            </div>
                        </div>
                    </div>

                    <div class="col col-price" v-if="$user.canSeePrice">
                        <div v-if="position.prd_id">
                            <div v-if="canEditPrice">
                                <div class="wrap-input-price">
                                    <input
                                        type="number"
                                        class="input-price without-arrow"
                                        v-model="position.price"
                                        @change="onChangePrice"
                                    />
                                    <span :class="['font-rouble', platform === 'Win32' ? 'win' : '']">o</span>
                                </div>
                            </div>
                            <div v-else>
                                <div :class="['price-wrap', position.specialPrice.isShowMark ? 'is-low-special-price' : '']">
                                    {{ position.price }}<span v-if="position.price" class="font-rouble">o</span>
                                </div>
                            </div>

                            <div v-if="isShowOverweightRestAlert && $window.isDesktop"
                                 @click="showOverweightRestAlert(position)" class="overweight-rest-alert">
                                Остаток {{ position.rest | numeral('0[.]00') }} {{ position.unit }}
                                <span class="notify-icon">?</span>
                            </div>
                        </div>

                        <div v-else>
                            <v-ons-select v-model="position.unit" @change="onChangeUnit">
                                <option v-for="unit in units" :key="unit.id" :value="unit.name">
                                    {{ unit.name }}
                                </option>
                            </v-ons-select>
                        </div>
                    </div>
                </div>
                <div class="count-row-wrap" v-if="!$window.isDesktop">
                    <div class="col col-remove">
                        <div v-show="inventoryPositions.length > 0">
                            <div v-if="getPositionInInventoryCount(position)" class="inventory-rest-wrap">
                                <div class="ttl">Остаток</div>
                                <div class="rest-wrap">
                                    {{ getPositionInInventoryCount(position) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-count">
                        <div class="count-wrap">
                            <div class="ttl">
                                Заказ, {{ position.unit }}
                            </div>
                            <div class="wrap" style="width: auto">
                                <div class="col col-button" v-if="!position.count || position.count == 0">
                                    <button @click="remove" tabindex="-1">
                                        <i class="fa fa-fw fa-remove"></i>
                                    </button>
                                </div>
                                <div class="col col-button" v-else>
                                    <button @click="onPositionDecCount" tabindex="-1">-</button>
                                </div>

                                <div class="col col-input">
                                    <input
                                        type="number"
                                        v-round
                                        class="input-count"
                                        v-model="position.count"
                                        :step="position.mult"
                                        :name="'count-b-' + position.pos_id"
                                        @change="onChangeCount"
                                        @blur="onBlur"
                                        @keydown="chooseCountHandler"
                                    >
                                </div>
                                <div class="col col-button">
                                    <button @click="onPositionIncCount" tabindex="-1">+</button>
                                </div>
                            </div>
                            <div class="package-wrap" v-show="position.mult != 1 && position.count > 0">
                                {{ positionPackage(position) }}
                            </div>
                        </div>
                    </div>
                    <div class="col col-total" v-show="$user.canSeePrice">
                        <div>
                            <div v-show="isShowOverweightRestAlert" @click="showOverweightRestAlert(position)"
                                 class="overweight-rest-alert">
                                Остаток {{ position.rest | numeral('0[.]00') }} {{ position.unit }}
                                <span class="notify-icon">?</span>
                            </div>

                            <div v-show="total > 0">
                                {{ total | numeral('0[.][000]') }}
                                <span class="font-rouble">o</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="update-buttons" v-show="isEditing">
                    <button class="btn-cancel" @click="cancelUpdate">
                        <v-ons-icon icon="md-block"></v-ons-icon>
                    </button>
                </div>
            </div>
        </v-ons-card>
    </div>

</template>

<script>

import $ from "jquery"
import numeral from 'numeral'
import InputProposalCreate from '~/component/InputProposalCreate'

export default {
    props: [
        'position',
        'addedPositions',
        'inventoryPositions',
        'units',
        'index',
        'lastImportDate',
        'canEditPrice',
        'urlSearch',
    ],
    data() {
        return {
            popoverMenuVisible: false,
            isEditing: false,
            platform: null,
            isDeleted: false,
        }
    },
    created() {
        this.platform = navigator.platform;
    },
    methods: {
        numeral(s) {
            return numeral(s);
        },
        getPositionInInventoryCount(position) {
            if (this.inventoryPositions.length === 0) {
                return false;
            }

            let result = this.inventoryPositions
                .find(i => {
                    if (position.prd_id) {
                        return i.prd_id == position.prd_id;
                    }

                    return i.name === position.name;
                })

            if (result) {
                return result.count;
            }
        },
        chooseCountHandler(e) {
            // plus
            if (e.keyCode === 107 || e.keyCode === 187) {
                e.preventDefault();
                this.incCount();
                return;
            }

            // minus
            if (e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
                this.decCount();
                return;
            }

            if (e.keyCode === 13 && e.shiftKey) {
                this.$emit('countShiftEnterDown', this.position);
                return;
            }

            if (e.keyCode === 13) {
                this.$emit('countEnterDown', this.position);
            }
        },
        showOverweightRestAlert: function (position) {
            let message = '';
            let lastImportDate = this.$moment.unix(this.lastImportDate).format("HH:mm от L");
            let rest = numeral(position.rest).format('0[.]00');

            if (parseInt(position.rest) === 0) {
                message += `Товар кончился: ${position.deletedAt} <br><br>`;
                message += `${position.name} <br> <b>Нет в наличии</b> <br><br>`;
            } else {
                message += `По данным на <b>${lastImportDate}</b> <br><br>`;
                message += `${position.name} <br> всего <b>${rest} ${position.unit}</b> <br><br>`;
            }

            message += `
                Вы можете добавить товар в полном объёме и отправить заказ как есть, или можете подобрать замену.<br><br>
                Если товар ещё не поступил, то менеджер по возможности предложит альтернативу.`;

            this.$ons.notification.alert({title: '', message: message});
        },
        cancelUpdate() {
            this.isEditing = false;
        },
        confirmUpdate() {
            if (!this.isEditing) {
                return;
            }

            this.isEditing = false;
        },
        update() {
            this.isEditing = true;
            this.$refs.input.name = '';

            this.$nextTick(() => {
                this.$refs.input.setFocus();
            });
        },
        chooseProposal(proposal) {
            this.isEditing = false;
            this.$emit('update', this.position.pos_id, proposal);
        },
        remove() {
            this.isDeleted = true;
            this.$emit('remove', this.position.pos_id);
        },
        positionPackage(position) {
            let unit = '';

            if (position.unit === 'шт') {
                unit = 'уп';
            }

            if (position.unit === 'кг') {
                unit = 'шт';
            }

            let count = numeral(position.count / position.mult).format('0[.]00');

            return count + ' ' + unit;
        },
        onBlur() {
            this.$emit('updateCount', this.position.pos_id);
        },
        onChangeCount() {
            this.$emit('updateCount', this.position.pos_id);
        },
        onChangeUnit() {
            this.$emit('updateUnit', this.position.pos_id);
        },
        onChangePrice() {
            this.$emit('updatePrice', this.position.pos_id);
        },
        onPositionIncCount() {
            this.incCount()

            if (this.$window.isDesktop) {
                $('#position-' + this.position.pos_id).find('.input-count').focus();
            }
        },
        onPositionDecCount() {
            this.decCount()

            if (this.$window.isDesktop) {
                $('#position-' + this.position.pos_id).find('.input-count').focus();
            }
        },
        incCount() {
            let mult = 1;
            let count = this.position.count;

            if (this.position.mult) {
                mult = parseFloat(this.position.mult);
            }

            if (!count) {
                count = mult;
            } else {
                count = parseFloat(count) + mult;
            }

            this.position.count = numeral(count).format('0[.][000]');

            this.$emit('updateCount', this.position.pos_id);
        },
        decCount() {
            let mult = 1;
            let count = this.position.count;

            if (this.position.mult) {
                mult = parseFloat(this.position.mult);
            }

            count = parseFloat(count) - mult;

            if (count <= 0) {
                count = '';
            } else {
                count = numeral(count).format('0[.][000]');
            }

            this.position.count = count;

            this.$emit('updateCount', this.position.pos_id);
        },
    },
    computed: {
        popoverId() {
            return 'position-create-popover-menu-' + this.position.pos_id;
        },
        isShowOverweightRestAlert() {
            if (this.isOverweightRest) {
                return true;
            }

            if (this.position.is_wait) {
                return true;
            }

            return !!(this.position.isInMyProducts && !parseFloat(this.position.rest));
        },
        isOverweightRest() {
            let position = this.position;

            if (!position.prd_id) {
                return false;
            }

            if (this.$user.restAlwaysAvailable && position.isInMyProducts) {
                return false;
            }

            if (!position.rest) {
                return true;
            }

            return parseFloat(position.count) > parseFloat(position.rest);
        },
        total() {
            return this.position.count * this.position.price;
        },
    },
    components: {
        InputProposalCreate,
    },
};
</script>