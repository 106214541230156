var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Auth: " + _vm._s(_vm.user.email))
        ])
      ]),
      _vm._v(" "),
      _c("v-ons-card", [_vm._v(" Текущая сессия завершится!  ")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c("v-ons-button", { on: { click: _vm.click } }, [
            _vm._v("Авторизоваться")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-db1d67ea", { render: render, staticRenderFns: staticRenderFns })
  }
}