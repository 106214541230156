<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div class="center">Контактные данные</div>
            <div class="right">
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-header>Имя</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="contacts.name" name="contacts_name" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Email</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="contacts.email" type="email" name="contacts_email" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Телефон</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="contacts.phone" type="tel" name="contacts_phone" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Фактическое наименование компании</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="contacts.organisation" name="contacts_organisation" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Адрес офиса</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="contacts.office_address" name="storage_office_address" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>График работы</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="contacts.office_work_schedule" name="storage_work_schedule" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>О компании</v-ons-list-header>
            <v-ons-list-item>
                <textarea style="width: 100%; height: 80px;" v-model="contacts.about_company" name="contacts_about_company" ></textarea>
            </v-ons-list-item>
        </v-ons-list>

        <div>
            <v-ons-list-header>Контактные лица</v-ons-list-header>

            <v-ons-list>
                <v-ons-list-item v-for="person in contacts.contactPersons" :key="person.id" >
                    <div class="center">
                        <v-ons-card style="width: 100%; cursor: pointer;" @click="updateContactPerson(person.id)" >
                            <div class="content">
                                <div v-show="person.post" >Должность: {{ person.post }}</div>
                                <div v-show="person.name" >Имя: {{ person.name }}</div>
                                <div v-show="person.phone" >Телефон: {{ person.phone }}</div>
                                <div v-show="person.email" >
                                    Email: {{ person.email }}
                                    <v-ons-icon v-show="person.need_to_notify" icon="md-mail-reply" size="14px" style="color: #009688; margin-left: 5px" ></v-ons-icon>
                                </div>
                            </div>
                        </v-ons-card>
                    </div>
                    <div class="right">
                        <v-ons-button modifier="quiet" @click="removeContactPerson(person.id)" >
                            <v-ons-icon icon="md-delete" size="22px" ></v-ons-icon>
                        </v-ons-button>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <div style="text-align: center" >
                <v-ons-button style="margin-bottom: 10px;" @click="addContactPerson" >
                    Добавить контактное лицо
                </v-ons-button>
            </div>
        </div>

        <contact-person-dialog ref="contactPersonDialog" />

    </v-ons-page>
</template>

<script>

import ContactPersonDialog from '~/component/ContactPersonDialog'

export default {
    data() {
        return {
            changed: false,
            loaded: false,
            contacts: {
                name: '',
                email: '',
                phone: '',
                about_company: '',
                contactPersons: [],
            }
        }
    },
    created() {
        this.$http.get('/api/settings/manager-contacts').then(response => {
            this.contacts = response.data;

            this.$nextTick(() => {
                this.loaded = true;
            });
        });
    },
    methods: {
        save() {
            this.$http.post(
                '/api/settings/manager-contacts',
                {contacts: this.contacts}
            ).then(() => {
                this.$router.go(-1);

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Ваши данные сохранены',
                    buttonLabel: 'OK',
                });
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data[0],
                    buttonLabel: 'OK',
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        addContactPerson() {
            this.$refs.contactPersonDialog.show().then(person => {
                if (!person.post && !person.name && !person.email && !person.phone) {
                    return;
                }

                this.contacts.contactPersons.push(person);
            });
        },
        updateContactPerson(id) {
            let index = this.contacts.contactPersons.findIndex(i => i.id == id);
            let person = this.contacts.contactPersons[index];

            this.$refs.contactPersonDialog.show(person).then(person => {
                if (!person.post && !person.name && !person.email && !person.phone) {
                    this.removeContactPerson(id);
                    return;
                }

                person.id = id;
                this.$set(this.contacts.contactPersons, index, person)
            });
        },
        removeContactPerson(id) {
            this.contacts.contactPersons = this.contacts.contactPersons.filter(i => i.id != id);
        },
    },
    components: {
        ContactPersonDialog,
    },
    watch: {
        contacts: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>