var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v(_vm._s(_vm.product.name))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasChecked,
                    expression: "hasChecked"
                  }
                ],
                on: { click: _vm.uncheckAll }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hasChecked,
                    expression: "!hasChecked"
                  }
                ],
                on: { click: _vm.checkAll }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.users,
              expression: "users"
            }
          ]
        },
        _vm._l(_vm.users, function(user) {
          return _c(
            "ons-list-item",
            {
              key: user.id,
              attrs: { modifier: "longdivider" },
              on: {
                click: function($event) {
                  user.isChecked = !user.isChecked
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "left",
                  staticStyle: { "min-width": "40px", "text-align": "center" }
                },
                [
                  _c("v-ons-checkbox", {
                    model: {
                      value: user.isChecked,
                      callback: function($$v) {
                        _vm.$set(user, "isChecked", $$v)
                      },
                      expression: "user.isChecked"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "center" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(user.id) +
                    " - " +
                    _vm._s(user.email) +
                    "\n            "
                )
              ])
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChecked,
              expression: "hasChecked"
            }
          ],
          attrs: { position: "bottom right" },
          on: {
            click: function($event) {
              return _vm.$router.push({
                name: "manager-product-avail-products-product",
                params: { users: _vm.checkedUserIds }
              })
            }
          }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-arrow-right" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5d1fe056", { render: render, staticRenderFns: staticRenderFns })
  }
}