<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div class="center">Уведомления о спец. ценах</div>
            <div class="right">
                <v-ons-toolbar-button @click="save" v-show="!loading" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <v-ons-card>
            Уведомления отправляются менеджеру, если товары из спецпредложения закончились или цены меньше минимально возможной
        </v-ons-card>

        <v-ons-list>
            <v-ons-list-item modifier="longdivider" >
                <div class="left" >
                    <v-ons-checkbox
                      v-model="settings.notifyEveryImports"
                      input-id="settingsNotifyEveryImports"
                    ></v-ons-checkbox>
                </div>
                <label class="center label-reset" for="settingsNotifyEveryImports">Уведомлять о новых ошибках, при каждом обновлении прайса</label>
            </v-ons-list-item>

            <v-ons-list-item modifier="longdivider" >
                <div class="left" >
                    <v-ons-checkbox
                      v-model="settings.notifySchedule"
                      input-id="settingsNotifySchedule"
                    ></v-ons-checkbox>
                </div>
                <label class="center label-reset" for="settingsNotifySchedule">Присылать отчёт с ошибками спец. цен по графику</label>
            </v-ons-list-item>

            <table class="table" style="margin-left: 72px; max-width: 230px;">
                <tbody>
                    <tr v-for="weekday in settings.weekdays" >
                        <td>
                            {{ weekday.name }}
                        </td>

                        <td class="text-center" >
                            <input type="time" v-model="weekday.value" style="text-align: center; margin: 0 auto; max-width: 100px" ></input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            changed: false,
            loaded: false,
            loading: true,

            settings: {
                notifyEveryImports: false,
                notifySchedule: false,
                weekdays: [],
            },
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.$http.get('/api/settings/special-prices-notifications').then(response => {
                this.settings = response.data;

                this.$nextTick(() => {
                    this.loading = false;
                    this.loaded = true;
                });
            });
        },
        save() {
            this.loading = true;

            this.$http.post('/api/settings/special-prices-notifications', {settings: this.settings}).then(response => {
                this.$router.goRouteBack();

                this.$ons.notification.toast({
                    timeout: 2000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });
            }, response => {
                this.loading = false;
                this.$ons.notification.toast({
                    message: response.data.message,
                    buttonLabel: 'OK',
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.goRouteBack();
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.goRouteBack();
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
    },
    watch: {
        settings: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>
