<template>
    <div class="input-proposal-wrap" v-click-outside="blurHandler" >
        <form action="" autocomplete="off" @submit.prevent="() => {}" >
        <input
            ref="input"
            type="text"
            autocomplete="off"
            spellcheck="false"
            v-model="name"
            class="input-proposal"
            name="input-proposal"
            @keydown="keydownHandler"
            @keyup="keyupHandler"
            @focus="focusHandler($event)"
            placeholder="Нажмите, чтобы добавить товар"
            :disabled="(canInputDisabled && inputDisabled && !canEditInput) || disabled"
            :style="focus ? 'border-color: #009688' : ''"
        />
        </form>

        <div v-show="focus" @click="clear" class="clear-input-button" >
            <v-ons-icon icon="md-close" ></v-ons-icon>
        </div>

        <div v-show="canInputDisabled && inputDisabled" @click="enableEditInput" class="enable-edit-input" >
            <v-ons-icon icon="md-edit" ></v-ons-icon>
        </div>

        <div v-if="proposals.length > 0 && isVisibleProposals" class="proposals-outer" >
            <div class="proposals-wrap">
                <div v-for="(proposal, proposalIndex) in proposals"
                    class="proposal-wrap wrap"
                    :id="'proposal-' + proposalIndex"
                    @click="chooseProposal(proposal)"
                    :class="{selected: proposalIndex == selectedProposal, added: isAdded(proposal)}"
                    :title='proposal.isInMyProducts ? "Товар добавлен в \"Мои товары\"" : ""'
                    >

                    <div class="col col-name" v-show="isShowColumn('name')" >
                        <div>
                            {{proposal.name}}

                            <v-ons-icon v-show="proposal.isInMyProducts" icon="md-star" title='Товар добавлен в "Мои товары"' class="star-icon" ></v-ons-icon>
                        </div>
                    </div>
                    <div class="col col-count" v-show="isShowColumn('count')"  >
                        <div>
                            {{proposal.unit}}
                        </div>
                    </div>
                    <div class="col col-price" v-show="isShowColumn('price')"  >
                        <div v-if="proposal.is_wait" >ждём</div>
                        <div v-else :class="['price-wrap', proposal.specialPrice.isShowMark ? 'is-low-special-price' : '']" >
                            {{ proposal.price }} р/{{ proposal.unit }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from "jquery"

export default {
    props: [
        'model',
        'scrollPageOnFocus',
        'disableScrollPage',
        'canInputDisabled',
        'inputDisabled',
        'isFocused',
        'columns',
        'addedPositions',
        'choosedPositionId',
        'disabled',
        'urlSearch',
        'urlSearchParams',
    ],
    data() {
        return {
            isVisibleProposals: false,
            name: this.model + '',
            previousName: '',
            proposals: [],
            selectedProposal: null,
            canEditInput: false,
            focus: false,
            previousRequest: null,
        }
    },
    destroyed() {
        this.blurHandler();
    },
    methods: {
        keyupHandler() {
            this.$emit('keyup');

            if (!this.name) {
                this.hideProposals();
                return;
            }

            let url = this.urlSearch || '/api/product/search';
            let params = this.urlSearchParams || {};

            params.text = this.name;

            let promise = this.$http.get(url, {
                params: params,
                before(request) {
                    if (this.previousRequest) {
                        this.previousRequest.abort();
                    }
                    this.previousRequest = request;
                },
            });

            promise.then(response => {
                if (!this.focus) {
                    return;
                }

                this.proposals = response.data;
                if (response.data.length !== 0) {
                    this.showProposals();
                } else {
                    this.hideProposals();
                }
            });
        },
        focusHandler(e) {
            this.$emit('focus', e);
            this.focus = true;

            if (this.scrollPageOnFocus) {
                this.scrollPage(e.target);
            }
        },
        blurHandler() {
            if (this.$window.hasFocus(this.$refs.input)) {
                return;
            }

            if (!this.focus) {
                return;
            }

            this.$emit('blur');
            this.hideProposals();
            this.focus = false;

            if (this.canInputDisabled) {
                this.canEditInput = false;
            }

            if (!this.name && this.previousName) {
                this.name = this.previousName;
                this.previousName = '';
            }
        },
        keydownHandler(e) {
            this.$emit('keydown');

            // 40 down
            if (e.keyCode === 40) {
                e.preventDefault();

                if (this.selectedProposal == null) {
                    this.selectedProposal = 0;
                } else {
                    if (this.selectedProposal + 2 > this.proposals.length) {
                        this.selectedProposal = 0;
                    } else {
                        this.selectedProposal += 1;
                    }
                }

                this.scrollToElement();

                return false;
            }

            // 38 up
            if (e.keyCode === 38) {
                e.preventDefault();

                if (this.selectedProposal == null) {
                    this.selectedProposal = 0;
                } else {
                    if (this.selectedProposal-1 < 0) {
                        this.selectedProposal = this.proposals.length - 1;
                    } else {
                        this.selectedProposal -= 1;
                    }
                }

                this.scrollToElement();

                return false;
            }

            if (e.keyCode === 13) {
                e.preventDefault();

                if (this.selectedProposal != null) {
                    this.chooseProposal(this.proposals[this.selectedProposal]);
                }

                this.$refs.input.blur();

                return false;
            }
        },
        scrollToElement() {
            let $selectedProposal = $('#proposal-' + this.selectedProposal);
            let $proposalsOuter = $('.proposals-outer');
            let outerScrollTop = $proposalsOuter.scrollTop();
            let scrollTop = 0;
            let outerHeight = $proposalsOuter.height();

            if (!$selectedProposal) {
                return;
            }

            let top = $selectedProposal.position().top;
            let height = $selectedProposal.height();

            // Если ижняя граница выходит за div то поднимаем наверх
            if (top + height > outerHeight) {
                scrollTop = outerScrollTop + (top - outerHeight + height);
            } else if(top < 10) {
                scrollTop = outerScrollTop + top - 10;
            } else {
                return;
            }

            $proposalsOuter.animate({
                scrollTop: scrollTop,
            }, 50);
        },
        chooseProposal(proposal) {
            if (this.isAdded(proposal) && proposal.id != this.choosedPositionId) {
                this.$emit('chooseadded', proposal);
                this.name = '';
                this.hideProposals();
                this.focus = false;
                return;
            }

            this.$emit('choose', proposal);
            this.name = proposal.name;
            this.hideProposals();
            this.focus = false;

            if (this.canInputDisabled) {
                this.canEditInput = false;
            }
        },
        showProposals() {
            this.$emit('showProposals');

            this.isVisibleProposals = true;

            if (this.disableScrollPage) {
                $('.page__content').addClass('no-scroll');
            }

            this.$nextTick(() => { this.resizeProposals() });
            $(window).on('resize', this.resizeProposals);
        },
        hideProposals() {
            this.$emit('hideProposals');

            this.isVisibleProposals = false;
            this.selectedProposal = null;

            if (this.disableScrollPage) {
                $('.page__content').removeClass('no-scroll');
            }

            $(window).off('resize', this.resizeProposals);
            this.$forceUpdate();
        },
        resizeProposals() {
            let $el = $(this.$el);
            if ($el.find('.proposals-outer').length === 0) {
                return;
            }
            let offset = $el.find('.proposals-outer').offset();
            let height = $(window).height() - (offset.top - $(document).scrollTop());
            $el.find('.proposals-outer').css({maxHeight: height + 'px'})
        },
        scrollPage(target) {
            let $target = $(target);
            let offset = $target.position().top;
            $target = $target.offsetParent();

            while (!$target.hasClass('page__content')) {
                offset += $target.position().top;
                $target = $target.offsetParent();
            }

            offset += $target.scrollTop();

            offset -= 15;

            $('.page__content').animate({
                scrollTop: offset
            }, 300);
        },
        enableEditInput() {
            let $input = $(this.$el).find('input.input-proposal');
            this.canEditInput = true;
            this.$nextTick(() => { $input.focus() });
        },
        isShowColumn(column) {
            if (!this.columns || !this.columns.length) {
                return true;
            }

            return this.columns.indexOf(column) !== -1;
        },
        isAdded(position) {
            return typeof this.addedPositions != 'undefined' && this.addedPositions.indexOf(position.id) !== -1;
        },
        clear() {
            this.previousName = this.name;
            this.name = '';
            this.hideProposals();
            this.proposals = [];

            if (this.previousRequest) {
                this.previousRequest.abort();
            }

            this.$refs.input.focus();
        },
    },
    watch: {
        model(val) {
            this.name = val + '';
        },
        name(val) {
            this.$emit('update:model', val);
        },
        focus(val) {
            this.$emit('update:isFocused', val);
        }
    }
}
</script>