<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div class="center">Контактные данные: {{ user.email }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-header>Имя</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="contacts.name" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Email</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="contacts.email" type="email" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>Телефон</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="contacts.phone" type="tel" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <div>
            <v-ons-list-header>Контактные лица</v-ons-list-header>

            <v-ons-list>
                <v-ons-list-item v-for="person in contacts.contactPersons" :key="person.id" >
                    <div class="center">
                        <v-ons-card style="width: 100%; cursor: pointer;" @click="updateContactPerson(person.id)" >
                            <div class="content">
                                <div v-show="person.post" >Должность: {{ person.post }}</div>
                                <div v-show="person.name" >Имя: {{ person.name }}</div>
                                <div v-show="person.phone" >Телефон: {{ person.phone }}</div>
                                <div v-show="person.email" >
                                    Email: {{ person.email }}
                                    <v-ons-icon v-show="person.need_to_notify" icon="md-mail-reply" size="14px" style="color: #009688; margin-left: 5px" ></v-ons-icon>
                                </div>
                                <div v-show="person.branches.length > 0" >
                                    <div v-html="personAddress(person)"></div>
                                </div>
                            </div>
                        </v-ons-card>
                    </div>
                    <div class="right">
                        <v-ons-button modifier="quiet" @click="removeContactPerson(person.id)" >
                            <v-ons-icon icon="md-delete" size="22px" ></v-ons-icon>
                        </v-ons-button>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <div style="text-align: center" >
                <v-ons-button style="margin-bottom: 10px;" @click="addContactPerson" >
                    Добавить контактное лицо
                </v-ons-button>
            </div>
        </div>

        <contact-person-dialog ref="contactPersonDialog" />

    </v-ons-page>
</template>

<script>

import ContactPersonDialog from '~/component/ContactPersonDialog'

export default {
    data() {
        return {
            changed: false,
            loaded: false,
            user: {},
            branches: [],
            contacts: {
                name: '',
                email: '',
                phone: '',
                contactPersons: [],
            }
        }
    },
    created() {
        let userId = this.$route.params.user_id;

        let requests = [
            this.$http.get(`/api/manager/users/${userId}/contacts`),
            this.$http.get(`/api/manager/users/${userId}/branches`),
            this.$http.get(`/api/manager/users/${userId}`),
        ];

        Promise.all(requests).then(responses => {
            this.contacts = responses[0].data;
            this.contacts.contactPersons = this.contacts.contactPersons.map(c => {
                c.branches = c.branches.map(b => b.id.toString())
                return c
            })

            this.branches = responses[1].data;
            this.user = responses[2].data;

            this.$nextTick(() => {
                this.loaded = true;
            });
        }, () => {
            this.$nextTick(() => {
                this.loaded = true;
            });
            this.$ons.notification.toast({
                buttonLabel: 'OK',
                message: 'Произошла ошибка.',
            });
        });


    },
    methods: {
        save() {
            let userId = this.$route.params.user_id;

            this.$http.post(`/api/manager/users/${userId}/contacts`, {contacts: this.contacts}).then(() => {
                this.$router.go(-1);
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data[0],
                    buttonLabel: 'OK',
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        addContactPerson() {
            this.$refs.contactPersonDialog.show(this.branches).then(person => {
                if (!person.post && !person.name && !person.email && !person.phone) {
                    return;
                }

                this.contacts.contactPersons.push(person);
            });
        },
        updateContactPerson(id) {
            let index = this.contacts.contactPersons.findIndex(i => i.id == id);
            let person = this.contacts.contactPersons[index];

            this.$refs.contactPersonDialog.show(this.branches, person).then(person => {
                if (!person.post && !person.name && !person.email && !person.phone) {
                    this.removeContactPerson(id);
                    return;
                }

                person.id = id;
                this.$set(this.contacts.contactPersons, index, person)
            });
        },
        removeContactPerson(id) {
            this.contacts.contactPersons = this.contacts.contactPersons.filter(i => i.id != id);
        },
        personAddress(person) {
            let branches = person.branches
            let personAddress = ''
            let branch = null

            for (const branchId of branches) {
                branch = this.branches.find(b => b.id === parseInt(branchId))

                if (!branch) {
                    continue
                }

                personAddress += 'Адрес: ' + branch.alias + '<br>'
            }

            return personAddress
        },
    },
    components: {
        ContactPersonDialog,
    },
    watch: {
        contacts: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>