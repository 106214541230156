<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar inline="true" >
            <div class="center">Выберите файл</div>
            <div class="right" >
                <v-ons-toolbar-button @click="close" title="Закрыть" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" style="top: 56px; bottom: 0;" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list :style="{minHeight: '150px', maxHeight: ($window.height - 110) + 'px', overflowY: 'auto'}" >
                <v-ons-list-item v-for="item in items" @click="choose(item)" :key="item.id" >
                    {{ item.title }}
                </v-ons-list-item>
            </v-ons-list>
        </div>

    </v-ons-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: true,
            items: [],
        }
    },
    methods: {
        show() {
            this.visible = true;
            this.loading = true;

            this.$http.post('/api/manager/import/list-uploaded-files').then(response => {
                this.items = response.data;

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, response => {
                this.loading = false;

                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        close() {
            this.visible = false;
        },
        choose(item) {
            this.loading = true;
            let filename = item.title + '.xls';

            this.$http.post('/api/manager/import/download-uploaded-file', {file: item.filename}, {responseType: 'arraybuffer'}).then(response => {
                let blob = new Blob([response.data], { type: response.headers.get('content-type') });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                this.visible = false;
            }, response => {
                this.loading = false;
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
};
</script>

