<template>
    <v-ons-page class="management-shipping-payment-methods-page" >
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Типы цен, скидки, способы отгрузки</div>

            <div class="right">
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <ons-list>
            <div class="wrap" >
                <div class="col xl-1-2" >
                    <div class="list-header list-header--material" >
                        Типы клиентов/цен:
                    </div>
                </div>

                <div class="col xl-1-2" >
                    <div class="list-header list-header--material" style="text-align: right;" >
                        % наценки<br>от базового типа цен
                    </div>
                </div>
            </div>

            <ons-list-item v-for="type in priceTypes" :key="type.id" modifier="longdivider" >
                <div class="left" style="min-width: 30px;" @click="renamePriceType(type)" >
                    <v-ons-icon icon="md-edit"></v-ons-icon>
                </div>
                <div class="center" >
                    <span class="list-item__title">
                        {{ type.name }}
                    </span>
                    <span v-if="type.id === 1" class="list-item__subtitle">
                        Этот тип цен отображается клиентам сразу после регистрации.<br>
                        Рекомендуется сделать завышение, а тип цен менять в процессе переговоров.
                    </span>
                </div>
                <div class="right" >
                    <input type="number" class="without-arrow" v-model="type.markup" style="width: 50px; text-align: center;" />
                </div>
            </ons-list-item>
        </ons-list>

        <br>
        <ons-list>
            <ons-list-item modifier="longdivider" >
                <div class="center" >
                    <b>Комиссия при оплате по терминалу (%)</b>
                </div>
                <div class="right" >
                    <input type="number" class="without-arrow" v-model="terminalTax" placeholder="0" style="text-align: center; width: 50px;" />
                </div>
            </ons-list-item>
        </ons-list>

        <br>
        <ons-list>
            <ons-list-item modifier="longdivider" >
                <div class="center" >
                    Отображать цены не авторизованным посетителям:
                </div>
                <div class="right" >
                    <v-ons-select v-model="guestPriceType" >
                        <option :value="''" >Не отображать</option>
                        <option v-for="type in priceTypes" :key="type.id" :value="type.id" >
                            {{ type.name }}
                        </option>
                    </v-ons-select>
                </div>
            </ons-list-item>
        </ons-list>

        <br>

        <div class="list-header--material" >Способ предоставления скидки клиенту:</div>

        <discount-section
            :title='discounts.get_discount.name'
            :forPriceType.sync="discounts.get_discount.forPriceType"
            :gotoPriceType.sync="discounts.get_discount.gotoPriceType"
            :isEnabled.sync="discounts.get_discount.isEnabled"
            :priceTypes="priceTypes" />

        <discount-section
            :title='discounts.order_amount_more.name'
            :forPriceType.sync="discounts.order_amount_more.forPriceType"
            :gotoPriceType.sync="discounts.order_amount_more.gotoPriceType"
            :isEnabled.sync="discounts.order_amount_more.isEnabled"
            :amount.sync="discounts.order_amount_more.amount"
            :hasAmount="true"
            :priceTypes="priceTypes" />

        <discount-section
            :title='discounts.order_amount_more_month.name'
            :forPriceType.sync="discounts.order_amount_more_month.forPriceType"
            :gotoPriceType.sync="discounts.order_amount_more_month.gotoPriceType"
            :isEnabled.sync="discounts.order_amount_more_month.isEnabled"
            :amount.sync="discounts.order_amount_more_month.amount"
            :hasAmount="true"
            :priceTypes="priceTypes" />

        <br>

        <table class="table" >
            <thead>
                <tr>
                    <th>Способы отгрузки<br>по типам клиентов/цен</th>
                    <th v-for="method in shippingMethods" :key="method.id" class="text-center" >{{ method.name }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="type in priceTypes" :key="type.id" >
                    <td>
                        {{ type.name }}
                    </td>

                    <td v-for="method in shippingMethods" :key="method.id" class="text-center" >
                        <v-ons-checkbox v-model="method.allow_by_price_type" :value="type.id" ></v-ons-checkbox>
                    </td>
                </tr>
            </tbody>
        </table>

        <br>

        <table class="table" >
            <thead>
                <tr>
                    <th>Способы оплаты<br>по типу отгрузки</th>
                    <th v-for="method in shippingMethods" :key="method.id" class="text-center" >{{ method.name }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" >
                    <td>
                        {{ paymentMethod.name }}
                    </td>

                    <td v-for="shippingMethod in shippingMethods" :key="shippingMethod.id" class="text-center" >
                        <v-ons-checkbox v-model="paymentMethod.allow_by_shipping_method" :value="shippingMethod.id" ></v-ons-checkbox>
                    </td>
                </tr>
            </tbody>
        </table>
    </v-ons-page>
</template>

<script>

import DiscountSection from '~/component/DiscountSection'

export default {
    data() {
        return {
            changed: false,
            loaded: false,
            priceTypes: [],
            paymentMethods: [],
            shippingMethods: [],
            terminalTax: 0,
            guestPriceType: null,
            discounts: {
                get_discount: {},
                order_amount_more: {},
                order_amount_more_month: {},
            },
        }
    },
    created() {
        this.$http.get(
            '/api/manager/management/shipping-payment-methods',
            {params: {storageId: this.$route.params.storage_id}}
        ).then(response => {
            this.priceTypes = response.data.priceTypes;
            this.paymentMethods = response.data.paymentMethods;
            this.shippingMethods = response.data.shippingMethods;
            this.terminalTax = response.data.terminalTax;

            this.$nextTick(() => {
                this.guestPriceType = response.data.guestPriceType;

                response.data.discounts.forEach(i => {
                    this.discounts[i.alias] = {
                        id: i.id,
                        name: i.name,
                        forPriceType: i.for_price_type.toString(),
                        gotoPriceType: i.goto_price_type.toString(),
                        isEnabled: !!i.is_enabled,
                        amount: i.amount ? i.amount.toString() : null,
                    };
                });

                this.$nextTick(() => {
                    this.loaded = true;
                });
            });
        });
    },
    methods: {
        goBack() {
            if (!this.changed) {
                this.$router.goRouteBack();
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.goRouteBack();
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        save() {
            let data = {
                priceTypes: this.priceTypes,
                discounts: this.discounts,
                paymentMethods: this.paymentMethods,
                shippingMethods: this.shippingMethods,
                terminalTax: this.terminalTax,
                guestPriceType: this.guestPriceType,
            };

            this.$http.post(
                '/api/manager/management/shipping-payment-methods',
                data,
                {params: {storageId: this.$route.params.storage_id}}
            ).then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });
                this.$router.go(-1);
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                });
            });
        },
        handlerChange() {
            if (!this.loaded) {
                return;
            }
            this.changed = true;
        },
        renamePriceType(priceType) {
            this.$ons.notification.prompt('Название', {
                title: 'Переименовать тип цен',
                defaultValue: priceType.name,
                buttonLabels: ['Отмена', 'OK']
            }).then(name => {
                if (!name || name === priceType.name) {
                    return;
                }

                this.$http.post(
                    '/api/manager/price-types/' + priceType.id,
                    {name: name},
                    {params: {storageId: this.$route.params.storage_id}}
                ).then(() => {
                    priceType.name = name;
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
    },
    components: {
        DiscountSection,
    },
    watch: {
        terminalTax: {
            handler(e) { this.handlerChange(e) },
            deep: true,
        },
        priceTypes: {
            handler(e) { this.handlerChange(e) },
            deep: true,
        },
        paymentMethods: {
            handler(e) { this.handlerChange(e) },
            deep: true,
        },
        shippingMethods: {
            handler(e) { this.handlerChange(e) },
            deep: true,
        },
        discounts: {
            handler(e) { this.handlerChange(e) },
            deep: true,
        },
    },
};
</script>