var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Юридические лица")])
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.entities.length > 0,
              expression: "entities.length > 0"
            }
          ]
        },
        _vm._l(_vm.entities, function(entity) {
          return _c(
            "v-ons-list-item",
            { key: entity.id, attrs: { modifier: "chevron longdivider" } },
            [
              _c(
                "div",
                {
                  staticClass: "center cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        _vm.$router.currentRoute.path + "/" + entity.id
                      )
                    }
                  }
                },
                [
                  _c("span", { staticClass: "list-item__title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(entity.legal_name) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !entity.hasBranch,
                          expression: "!entity.hasBranch"
                        }
                      ],
                      staticClass:
                        "list-item__subtitle list-item__subtitle__warning"
                    },
                    [
                      _vm._v(
                        "\n                    Не привязан филиал\n                "
                      )
                    ]
                  )
                ]
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.entities.length,
              expression: "!entities.length"
            }
          ]
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "20px",
                color: "#a8a8a8",
                position: "absolute",
                top: "50%",
                "text-align": "center",
                width: "100%",
                "line-height": "50px",
                "margin-top": "-25px"
              }
            },
            [
              _vm._v("\n            Список пуст"),
              _c("br"),
              _vm._v(" "),
              _c(
                "v-ons-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/profile/entities/create")
                    }
                  }
                },
                [_vm._v("Добавить юр. лицо")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.entities.length,
              expression: "entities.length"
            }
          ],
          attrs: {
            position: "bottom right",
            title: "Создать новую точку доставки"
          },
          on: {
            click: function($event) {
              return _vm.$router.push("/profile/entities/create")
            }
          }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0b5e8601", { render: render, staticRenderFns: staticRenderFns })
  }
}