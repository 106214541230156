var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v(_vm._s(_vm.email.subject))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-item", [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(_vm._s(_vm.email.subject))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v("Тема")
                ])
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(_vm._s(_vm.email.to))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v("Кому")
                ])
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(_vm._s(_vm.email.reply_to))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v("Reply")
                ])
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(_vm._s(_vm.email.sendedAt))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v("Отправлено")
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.iframeSrc
            ? _c("iframe", {
                ref: "iframe",
                staticStyle: { "margin-top": "30px", width: "100%" },
                attrs: { src: _vm.iframeSrc },
                on: { load: _vm.iframeLoad }
              })
            : _c("div", [
                _c("pre", { staticStyle: { margin: "30px 16px 0" } }, [
                  _vm._v("Письмо не найдено")
                ])
              ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-40a723b2", { render: render, staticRenderFns: staticRenderFns })
  }
}