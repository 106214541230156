<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar v-show="titleProp" inline="true" >
            <div class="center">{{ titleProp }}</div>
            <div class="right" >
                <v-ons-toolbar-button v-show="allowselectall && !isSelectAll" @click="selectAll" title="Выбрать всё" >
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="allowselectall && isSelectAll" @click="unselectAll" title="Снять выделение" >
                    <v-ons-icon style="color: yellow;" icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="close" title="Закрыть" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div v-if="!multiple" >
            <v-ons-list :style="{maxHeight: ($window.height - 110) + 'px', overflowY: 'auto'}" >
                <v-ons-list-item v-for="item in itemsData" @click="choose(item.id)" :key="item.id" >
                    {{ item.name }}
                </v-ons-list-item>
            </v-ons-list>
        </div>
        <div v-else >
            <v-ons-list :style="{maxHeight: ($window.height - 110) + 'px', overflowY: 'auto'}" >
                <v-ons-list-item v-for="item in itemsData" :key="item.id" >
                    <label class="center" style="font-weight: 400; margin: 0; padding: 8px 6px 8px 0;" :for="'chk-' + item.id" >
                        {{ item.name }}
                    </label>
                    <div class="right" style="padding: 8px 16px 8px 0;" >
                        <v-ons-checkbox :input-id="'chk-' + item.id" v-model="selected" :value="item.id" />
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-row class="text-center" style="margin-bottom: 5px; justify-content: center;" >
                <v-ons-col width="50%" v-show="showResetButton && multiple" >
                    <v-ons-button @click="reset()" modifier="quiet" >
                        Сбросить
                    </v-ons-button>
                </v-ons-col>

                <v-ons-col width="50%">
                    <v-ons-button @click="choose()" :disabled="selected.length === 0 && !allowEmptyList" >
                        {{ btnTitleProp }}
                    </v-ons-button>
                </v-ons-col>
            </v-ons-row>
        </div>
    </v-ons-dialog>
</template>

<script>
export default {
    props: [
        'title',
        'btnTitle',
        'items',
        'multiple',
        'allowselectall',
        'showResetButton',
        'allowEmptyList',
    ],
    data() {
        return {
            titleProp: null,
            btnTitleProp: null,
            selected: [],
            visible: false,
            resolve: null,
            reject: null,

            itemsData: [],
        }
    },
    methods: {
        show(params) {
            params = params || {};
            this.titleProp = params.title || this.title;
            this.btnTitleProp = this.btnTitle || 'Выбрать'

            this.selected = params.selected || [];
            this.itemsData = params.items || this.items || [];
            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.visible = false;
        },
        choose(item) {
            if (this.multiple) {
                this.visible = false;
                this.resolve(this.selected);
                this.selected = [];
                return;
            }

            this.visible = false;
            this.resolve(item);
        },
        reset() {
            this.selected = [];
            this.visible = false;
            this.resolve(this.selected);
        },
        selectAll() {
            this.selected = this.itemsData.map(i => i.id.toString());
        },
        unselectAll() {
            this.selected = [];
        },
    },
    computed: {
        isSelectAll() {
            return this.selected.length === this.itemsData.length;
        },
    },
};
</script>