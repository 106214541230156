var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasChecked && !_vm.isSearchActive,
              expression: "!hasChecked && !isSearchActive"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v("Перемещение/Удаление товаров")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                [
                  _c("v-ons-icon", {
                    style: {
                      color: _vm.filters.groups.length > 0 ? "#ffe000" : ""
                    },
                    attrs: {
                      title: "Разделы каталога",
                      icon: "md-filter-list"
                    },
                    on: { click: _vm.chooseGroupFilter }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                { attrs: { title: "Поиск" }, on: { click: _vm.searchActive } },
                [_c("v-ons-icon", { attrs: { icon: "md-search" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "toolbar-popover-menu",
                { attrs: { "popover-class": "stick-right" } },
                [
                  _c("toolbar-popover-menu-item", {
                    attrs: { text: "Разделы каталога", icon: "md-filter-list" },
                    on: { click: _vm.chooseGroupFilter }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChecked,
              expression: "hasChecked"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { title: "Отменить выделение" },
                  on: { click: _vm.uncheckAll }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v(
              "\n            Выбрано: " +
                _vm._s(_vm.checked.length) +
                "\n        "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSearchActive,
              expression: "isSearchActive"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchDeactive } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _c("div", { staticClass: "search-wrap" }, [
              _c(
                "form",
                {
                  attrs: { action: "", autocomplete: "off" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return function() {}.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchQuery,
                        expression: "searchQuery"
                      }
                    ],
                    staticStyle: {
                      color: "#fff",
                      background: "none",
                      border: "none",
                      height: "34px",
                      "line-height": "34px",
                      "border-bottom": "1px solid #fff"
                    },
                    attrs: { id: "search-input", autocomplete: "off" },
                    domProps: { value: _vm.searchQuery },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchQuery = $event.target.value
                      }
                    }
                  })
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("group-filter-modal", { ref: "groupFilterModal" }),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content", attrs: { id: "catalog" } }, [
        _vm.catalog.length > 0
          ? _c(
              "div",
              [
                _vm.isDesktop
                  ? _c(
                      "v-ons-list",
                      {
                        staticClass: "list-catalog is-desktop",
                        attrs: { id: "list-header-catalog" }
                      },
                      [
                        _c(
                          "v-ons-list-item",
                          {
                            staticClass: "table-header-wrap",
                            style: { width: _vm.listCatalogWidth + "px" }
                          },
                          [
                            _c("div", { staticClass: "col-checkbox" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-name" }, [
                              _vm._v("Наименование")
                            ]),
                            _vm._v(" "),
                            _vm.isDesktop && _vm.$user.isManager
                              ? _c("div", { staticClass: "col-rest" }, [
                                  _vm._v("Остаток")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.$user.canSeePrice,
                                    expression: "$user.canSeePrice"
                                  }
                                ],
                                staticClass: "col-price"
                              },
                              [_vm._v("Цена")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.showInputs && !_vm.$user.isManager,
                                    expression: "showInputs && !$user.isManager"
                                  }
                                ],
                                staticClass: "col-count"
                              },
                              [_vm._v("Заказ")]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-ons-list",
                  { staticClass: "list-catalog" },
                  _vm._l(_vm.catalog, function(category) {
                    return _c("category-item-list", {
                      key: category.id,
                      attrs: {
                        item: category,
                        isDesktop: _vm.isDesktop,
                        actionColType: _vm.actionColType,
                        canOpenProductPage: _vm.canOpenProductPage,
                        showInputs: _vm.showInputs,
                        selectedPriceType: _vm.selectedPriceType,
                        counts: _vm.counts,
                        checked: _vm.checked,
                        lastImportDate: _vm.lastImportDate
                      },
                      on: {
                        clickGoToView: _vm.clickGoToView,
                        clickCategory: _vm.onClickCategory
                      }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { width: "100%", height: "80px" } })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.catalog.length === 0,
                expression: "catalog.length === 0"
              }
            ],
            staticClass: "list-empty",
            style: {
              height: _vm.$window.height - 56 + "px",
              flexDirection: "column"
            }
          },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px", "font-size": "15px" } },
              [_vm._v("\n                Ничего не найдено\n            ")]
            ),
            _vm._v(" "),
            _c("v-ons-button", { on: { click: _vm.reset } }, [
              _vm._v("Сбросить")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          staticStyle: { bottom: "90px" },
          attrs: { disabled: !_vm.hasChecked, position: "bottom right" },
          on: { click: _vm.deleteRule }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          attrs: { disabled: !_vm.hasChecked, position: "bottom right" },
          on: { click: _vm.replaceRule }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-arrow-right" } })],
        1
      ),
      _vm._v(" "),
      _c("list-dialog", {
        ref: "categoriesDialog",
        attrs: { title: "Выберите раздел", items: _vm.categories }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-329778b8", { render: render, staticRenderFns: staticRenderFns })
  }
}