var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ons-list-item",
    {
      key: _vm.product.id,
      style: _vm.product.added ? "background: #77B61F; color: #fff;" : "",
      attrs: { modifier: "longdivider", tappable: "" },
      on: {
        click: function($event) {
          return _vm.choose(_vm.product)
        }
      }
    },
    [
      _c("div", { staticClass: "center" }, [
        _c("span", { staticClass: "list-item__title" }, [
          _vm._v(_vm._s(_vm.product.name))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "list-item__subtitle" }, [
          _vm._v(
            "\n            Цена: " +
              _vm._s(_vm.product.price) +
              "\n            Остаток: " +
              _vm._s(
                _vm.product.is_deleted && _vm.product.is_avail
                  ? "ждём"
                  : _vm.product.rest
              ) +
              "\n        "
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ae1ad3ca", { render: render, staticRenderFns: staticRenderFns })
  }
}