<template>
    <v-ons-page class="manager-order-index">
        <v-ons-toolbar inline="true" v-show="!isSearchActive" >
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">Заказы</div>

            <div class="right" >
                <v-ons-toolbar-button  @click="$emit('orders-update')" >
                    <v-ons-icon icon="md-refresh" :style="{color: hasNewOrder ? '#ffbb3d' : 'white'}" ></v-ons-icon>
                </v-ons-toolbar-button>
                <v-ons-toolbar-button  @click="searchActive" >
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item
                        text="Создать заказ"
                        icon="md-plus"
                        @click="$router.push('/manager/order/create')"
                    />

                    <toolbar-popover-menu-item
                        text="Опции просмотра"
                        icon="md-format-list-bulleted"
                        @click="showViewParamsModal"
                    />

                    <v-ons-list-header>Отображать заказы:</v-ons-list-header>

                    <ons-list-item @click="setFilterStatus('all')" >
                        <div class="left icon" >
                            <v-ons-icon v-show="filters.status === 'all'" icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" >Все</div>
                    </ons-list-item>

                    <ons-list-item @click="setFilterStatus('sended')" >
                        <div class="left icon" >
                            <v-ons-icon v-show="filters.status === 'sended'" icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" >Новые</div>
                    </ons-list-item>

                    <ons-list-item @click="setFilterStatus('marked_to_send')" >
                        <div class="left icon" >
                            <v-ons-icon v-show="filters.status === 'marked_to_send'" icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" >Выгрузка</div>
                    </ons-list-item>
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar inline="true" v-show="isSearchActive" >
            <div class="left">
                <v-ons-toolbar-button @click="searchDeactive">
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center" >
                <input id="search-input" v-model="searchQuery" style="
                    color: #fff;
                    background: none;
                    border: none;
                    height: 34px;
                    line-height: 34px;
                    border-bottom: 1px solid #fff;
                " />
            </div>

            <div class="right" >
                <v-ons-toolbar-button  @click="$emit('orders-update')" >
                    <v-ons-icon icon="md-refresh" :style="{color: hasNewOrder ? '#ffbb3d' : 'white'}" ></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item text="Опции просмотра" icon="md-format-list-bulleted" @click="showViewParamsModal" />

                    <v-ons-list-header>Отображать заказы:</v-ons-list-header>

                    <ons-list-item @click="setFilterStatus('all')" >
                        <div class="left icon" >
                            <v-ons-icon v-show="filters.status === 'all'" icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" >Все</div>
                    </ons-list-item>

                    <ons-list-item @click="setFilterStatus('sended')" >
                        <div class="left icon" >
                            <v-ons-icon v-show="filters.status === 'sended'" icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" >Новые</div>
                    </ons-list-item>
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-show="!error" >
                <div v-show="orders" >
                    <ons-list>
                        <ons-list-item
                            v-for="order in orders"
                            :key="order.id"
                            modifier="chevron longdivider"
                            tappable
                            @click="onOrderClick($event, order)"
                            v-if="shouldShowOrder(order)"
                            class="order-list-item"
                        >
                            <div class="center" >
                                <span :class="['list-item__title', order.status_id === 'sended' ? 'order-new' : '']" >
                                    Заказ №{{order.id}} <span v-show="order.sended_at" >от {{ order.sended_at | moment("L") }}</span>
                                    на сумму
                                    <span v-if="order.isNearlyCost" style="margin-left: 5px" >~</span>
                                    {{ order.hasDiscount ? order.totalCostWithDiscount : order.totalCost }} руб.
                                </span>
                                <span class="list-item__subtitle" >
                                    Статус: {{ order.status }}
                                </span>

                                <v-list-item-subtitles :item="order" :fields="$user.getOrderSubtitles()" :selected="$user.orderSelectedSubtitles" />

                                <div class="list-item__subtitle wrap-hide-canceled" v-show="['draft', 'deleted'].includes(order.status_id) && order.is_new_cancel" >
                                    <div class="wrap" style="margin-top: 13px" >
                                        <div class="col" >
                                            <span style="font-size: 16px; color: #ff6a00; font-weight: bold;" >
                                                Отменён
                                            </span>
                                        </div>
                                        <div class="col" style="margin-left: 25px">
                                            <v-ons-button @click="hideCanceled(order)">
                                                {{ filters.status === 'all' ? 'ОК' : 'Скрыть' }}
                                            </v-ons-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ons-list-item>

                        <div style="text-align: center; margin: 10px 0;" v-show="page < pageCount" >
                            <v-ons-button @click="loadMore" style="min-width: 145px;" >
                                <span v-show="!loadingMore" >Показать ещё</span>
                                <v-ons-icon v-show="loadingMore" size="30px" spin icon="md-spinner"></v-ons-icon>
                            </v-ons-button>
                        </div>
                    </ons-list>
                </div>

                <div v-show="orders.length === 0" style="text-align: center; font-size: 15px; margin-top: 50px" >Ничего не найдено</div>
            </div>

            <div v-show="error" style="text-align: center; font-size: 15px; margin-top: 50px" >
                <div style="margin-bottom: 15px; font-size: 20px;" >Произошла ошибка.</div>

                <v-ons-button @click="$emit('orders-update')">
                    Повторить
                </v-ons-button>
            </div>
        </div>

        <v-list-dialog ref="viewParamsModal" multiple="true" title="Опции просмотра" />
    </v-ons-page>
</template>

<script>

import ListDialog from '~/component/ListDialog'
import ListItemSubtitles from '~/component/ListItemSubtitles'

import $ from 'jquery'
import {from, merge, of} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, map, pluck, switchMap, tap} from 'rxjs/operators'

export default {
    data() {
        return {
            isSearchActive: false,
            searchQuery: '',
            orders: [],
            page: 1,
            pageCount: null,
            loading: false,
            loadingMore: false,
            error: false,
            hasNewOrder: false,
            filters: {
                q: '',
                status: 'all',
            },
        }
    },
    subscriptions () {
        const q$ = this.$watchAsObservable('searchQuery').pipe(
            pluck('newValue'),
            map((q) => q.trim()),
            distinctUntilChanged(),
            debounceTime(500),

            tap((q) => {
                this.filters.q = q;
                this.loading = true;
                this.orders = [];
                this.page = 1;
            })
        )

        const searchDeactivate$ = this.$eventToObservable('searchDeactive').pipe(
            tap(() => {
                this.filters.q = '';
                this.loading = true;
                this.orders = [];
                this.page = 1;
            })
        )

        const updateWatch$ = this.$eventToObservable('orders-update').pipe(
            tap(() => {
                this.filters.q = '';
                this.loading = true;
                this.orders = [];
                this.page = 1;
            })
        )

        const filterWatch$ = this.$eventToObservable('orders-filter').pipe(
            tap(() => {
                this.loading = true;
                this.orders = [];
                this.page = 1;
            })
        )

        const loadMore$ = this.$watchAsObservable('page').pipe(
            tap(() => this.loadingMore = true),
        )

        const init$ = from([1]).pipe(
            tap(() => this.loading = true),
        )

        return {
            results: merge(q$, loadMore$, init$, searchDeactivate$, updateWatch$, filterWatch$).pipe(
                tap(() => this.error = false),
                switchMap(() => {
                    return from(this.$http.get('/api/manager/order', {params: {filters: this.filters, page: this.page}})).pipe(
                        map(response => {
                            return response.data;
                        }),
                        catchError(() => {
                            this.error = true;
                            this.loading = false;

                            return of({
                                items: [],
                                pageCount: 1,
                            });
                        })
                    )
                }),
                map(response => {
                    this.orders = this.orders.concat(response.items);
                    this.pageCount = response.pageCount;
                }),
                tap(() => {
                    this.loadingMore = false;
                    this.loading = false;
                    this.hasNewOrder = false;
                }),
            )
        }
    },
    socket() {
        return [
            {
                namespace: '/orders',
                events: {
                    sended() {
                        this.hasNewOrder = true;
                    },
                    updated(data) {
                        this.updateOrder(data.id);
                    },
                    canceled() {
                        this.hasNewOrder = true;
                    },
                },
            }
        ]
    },
    created() {
        this.$bus.$on('order-update', id => {
            this.updateOrder(id);
        });
        this.$bus.$on('orders-update', () => {
            this.$emit('orders-update');
        });
    },
    destroyed() {
        this.$bus.$off('order-update');
        this.$bus.$off('orders-update');
    },
    methods: {
        updateOrder(id) {
            this.$http.get('/api/manager/orders/' + id).then(response => {
                let index = this.orders.findIndex(i => i.id == id);

                if (index === -1) {
                    return;
                }

                this.$set(this.orders, index, response.data.order)
            }, response => {
                if (response.status === 404) {
                    this.removeOrdersFromList([id]);
                }
            });
        },
        loadMore() {
            if (this.page != null && this.pageCount != null && this.page > this.pageCount) {
                return;
            }

            if (this.loadingMore) {
                return;
            }

            this.page += 1;
        },
        searchActive() {
            this.isSearchActive = true;
            this.searchQuery = '';
            this.$nextTick(() => document.getElementById("search-input").focus());
        },
        searchDeactive() {
            this.isSearchActive = false;
            this.$emit('searchDeactive');
        },
        setFilterStatus(status) {
            this.filters.status = status;
            this.$emit('orders-filter');
        },
        showViewParamsModal() {
            let items = this.$user.getOrderSubtitles().map(i => {
                return {
                    id: i.name,
                    name: i.label,
                }
            });

            this.$refs.viewParamsModal.show({items: items, selected: this.$user.orderSelectedSubtitles}).then(selected => {
                this.$user.setOrderSelectedSubtitles(selected);
            });
        },
        hideCanceled(order) {
            order.is_new_cancel = false;

            this.$http.post(`/api/manager/orders/${order.id}/mark-canceled-as-viewed`).then(() => {

            }, () => {
                order.is_new_cancel = true;

                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        onOrderClick(e, order) {
            let $target = $(e.target);

            if ($target.parents('.wrap-hide-canceled').length > 0) {
                return;
            }

            this.$router.push('/manager/order/' + order.id);
        },
        shouldShowOrder(order) {
            if (this.filters.status !== 'sended') {
                return true;
            }

            if (order.status_id !== 'draft') {
                return true;
            }

            return order.is_new_cancel;
        },
    },
    components: {
        'v-list-dialog': ListDialog,
        'v-list-item-subtitles': ListItemSubtitles,
    },
};
</script>