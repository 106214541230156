var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { visible: _vm.visible, cancelable: "" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "center" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Закрыть" }, on: { click: _vm.close } },
              [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            staticStyle: { top: "55px", bottom: "0" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _vm._v(
                  '\n                Все товары клиента из списка "Мои товары" будут отображаться с индикатором "В наличии", а так же клиент не будет получать уведомления, если заказ превысит остатки.\n            '
                )
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c(
                  "div",
                  { staticClass: "left", staticStyle: { "min-width": "30px" } },
                  [
                    _c("v-ons-checkbox", {
                      attrs: { "input-id": "alwaysAvailableLabel" },
                      model: {
                        value: _vm.alwaysAvailable,
                        callback: function($$v) {
                          _vm.alwaysAvailable = $$v
                        },
                        expression: "alwaysAvailable"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "alwaysAvailableLabel" }
                  },
                  [_vm._v('"Мои товары" всегда в наличии')]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ons-row",
            {
              staticClass: "text-center",
              staticStyle: { margin: "10px 0", "justify-content": "center" }
            },
            [
              _c(
                "ons-col",
                { attrs: { width: "50%" } },
                [
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { width: "100%" },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("ОК")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9ac41e10", { render: render, staticRenderFns: staticRenderFns })
  }
}