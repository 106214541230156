<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center" >Главная</div>
        </v-ons-toolbar>

        <div class="home-page" >
            <div class="links-block" >
                <div class="row-wrap" >
                    <router-link class="col-wrap button--material" v-if="$user.canSee('catalog')" to="/catalog">
                        <span>Каталог</span>
                    </router-link>
                </div>

                <div class="row-wrap" >
                    <router-link class="col-wrap button--material" to="/order/create">
                        <span>Написать заявку</span>
                    </router-link>

                    <router-link class="col-wrap button--material" to="/order">
                        <span>Заказы</span>
                    </router-link>
                </div>
            </div>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            user: {},
        }
    },
    created() {
        this.$http.get('/api/user').then(response => {
            this.user = response.data;
        });

        this.$bus.$on('authorized', this.onAuthorized);
    },
    destroyed() {
        this.$bus.$off('authorized');
    },
    methods: {
        onAuthorized() {
            this.$nextTick(() => {
                let element = document.getElementsByClassName('home-page')[0];
                if (element) {
                    element.parentElement.scrollTop = 0;
                }
            });
        },
    },
};
</script>