<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Округление цен</div>

            <div class="right">
                <v-ons-toolbar-button @click="save" v-show="!loading" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <ons-list-item>
                    <label for="params_enabled" class="center label-reset" >
                        Округлять копейки
                    </label>
                    <div class="right" >
                        <v-ons-checkbox
                            v-model="params.enabled"
                            :value="true"
                            input-id="params_enabled"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </ons-list-item>

                <ons-list-item>
                    <label for="params_enabled" class="center label-reset" >
                        для цен дороже
                    </label>
                    <div class="right" >
                        <input
                            v-model="params.compare"
                            id="params_enabled"
                            style="width: 50px; text-align: center;"
                            :disabled="!params.enabled"
                        />
                    </div>
                </ons-list-item>


                <v-ons-list-header>Способ округления</v-ons-list-header>

                <ons-list-item>
                    <label for="params_method_up" class="center label-reset" >
                        округлять вверх
                    </label>
                    <div class="right" >
                        <v-ons-radio
                            v-model="params.method"
                            input-id="params_method_up"
                            value="up"
                            style="width: 50px; text-align: center;"
                            :disabled="!params.enabled"
                        ></v-ons-radio>
                    </div>
                </ons-list-item>

                <ons-list-item>
                    <label for="params_method_down" class="center label-reset" >
                        округлять вниз
                    </label>
                    <div class="right" >
                        <v-ons-radio
                            v-model="params.method"
                            input-id="params_method_down"
                            value="down"
                            style="width: 50px; text-align: center;"
                            :disabled="!params.enabled"
                        ></v-ons-radio>
                    </div>
                </ons-list-item>

                <ons-list-item>
                    <label for="params_method_math" class="center label-reset" >
                        округлять математически
                    </label>
                    <div class="right" >
                        <v-ons-radio
                            v-model="params.method"
                            input-id="params_method_math"
                            value="math"
                            style="width: 50px; text-align: center;"
                            :disabled="!params.enabled"
                        ></v-ons-radio>
                    </div>
                </ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            changed: false,
            loaded: false,

            params: {
                enabled: false,
                compare: 0,
                method: 'up',
            },
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;

            let requests = [
                this.$http.get(
                    '/api/manager/catalog-settings/rounding-price',
                    {params: {storageId: this.$route.params.storage_id}}
                ),
            ];

            Promise.all(requests).then(responses => {
                let params = responses[0].data;

                if (params) {
                    this.params.enabled = params.enabled ? params.enabled : false;
                    this.params.compare = params.compare ? params.compare : 0;
                    this.params.method = params.method ? params.method : 'up';
                }

                this.$nextTick(() => {
                    this.loading = false;
                    this.loaded = true;
                });
            }, () => {
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        save() {
            this.loading = true;

            this.$http.post(
                '/api/manager/catalog-settings/rounding-price',
                {params: this.params},
                {params: {storageId: this.$route.params.storage_id}}
            ).then(() => {
                this.loading = false;

                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Сохранено',
                    buttonLabel: 'OK',
                });

                this.$router.go(-1);
            }, () => {
                this.loading = false;
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
    },
    watch: {
        params: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>