var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Out of stock")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                attrs: {
                  title: "Скачать список товаров в ожидании",
                  disabled: _vm.downloading
                },
                on: { click: _vm.downloadPdf }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-download" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                attrs: {
                  title: "Скачать список товаров в ожидании",
                  disabled: _vm.downloading
                },
                on: { click: _vm.showLifetimeOssModal }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-timer" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.storages.length > 1,
                  expression: "storages.length > 1"
                }
              ],
              attrs: { modifier: "chevron longdivider" },
              on: { click: _vm.showChooseStorage }
            },
            [
              _c("span", { staticClass: "list-item__title" }, [
                _vm._v("Склад: " + _vm._s(_vm.selectedStorageName))
              ]),
              _vm._v(" "),
              _vm.selectedStorage && !_vm.selectedStorage.is_configured
                ? _c(
                    "span",
                    {
                      staticClass: "list-item__subtitle",
                      staticStyle: { color: "red" }
                    },
                    [_vm._v("\n        Не настроено\n      ")]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("v-ons-card", [
            _c("p", [
              _vm._v(
                'Эти товары закончились, но отображаются в каталоге с отметкой "ждём".'
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "catalog-list-component" }, [
            _vm.items.length > 0
              ? _c("div", [
                  _c(
                    "table",
                    { staticClass: "table table-bordered table-catalog" },
                    [
                      _c(
                        "tbody",
                        _vm._l(_vm.items, function(item, key) {
                          return _c("tr", [
                            item.type === "category"
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "col-catname",
                                    attrs: { colspan: "4" }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "product"
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "col-name",
                                    staticStyle: { "border-right": "0" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push({
                                          name: "manager-oos-product-view",
                                          params: { prd_id: item.id }
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _c("div", [_vm._v(_vm._s(item.name))]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "list-item__subtitle list-item--material__subtitle"
                                      },
                                      [
                                        _c("div", { staticClass: "wrap" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col xl-1-2 xl-left"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                              " +
                                                      _vm._s(
                                                        item.usedCount > 0
                                                          ? "Связи: " +
                                                              item.usedCount +
                                                              "шт"
                                                          : "Товар не используется"
                                                      ) +
                                                      "\n                                          "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: item.is_special,
                                                  expression: "item.is_special"
                                                }
                                              ],
                                              staticClass: "col xl-1-2 xl-right"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vip-icon icon"
                                                    },
                                                    [_vm._v("VIP")]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "product"
                              ? _c(
                                  "td",
                                  {
                                    staticStyle: { "border-left": "0" },
                                    on: {
                                      click: function($event) {
                                        item.popoverMenuVisible = true
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "popover-menu",
                                        attrs: { id: "popoverMenu-" + key }
                                      },
                                      [
                                        _c("v-ons-icon", {
                                          staticStyle: {
                                            "font-size": "20px",
                                            margin: "0 10px"
                                          },
                                          attrs: { icon: "md-more-vert" }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "v-ons-popover",
                                          {
                                            staticClass:
                                              "wait-page-popover-menu",
                                            attrs: {
                                              cancelable: "",
                                              visible: item.popoverMenuVisible,
                                              target: "#popoverMenu-" + key,
                                              "cover-target": "true",
                                              direction:
                                                key === _vm.items.length - 1
                                                  ? "up"
                                                  : "down"
                                            },
                                            on: {
                                              "update:visible": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  item,
                                                  "popoverMenuVisible",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-ons-list",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    item.popoverMenuVisible = false
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-ons-list-item",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.unsetAvail(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "left icon"
                                                      },
                                                      [
                                                        _c("v-ons-icon", {
                                                          attrs: {
                                                            icon: "md-edit"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "center" },
                                                      [
                                                        _vm._v(
                                                          "Удалить из Исключений OOS"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                item.usedCount > 0
                                                  ? _c(
                                                      "v-ons-list-item",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.goToLinks(
                                                              item.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "left icon"
                                                          },
                                                          [
                                                            _c("v-ons-icon", {
                                                              attrs: {
                                                                icon: "md-link"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Перейти к связям товара"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.items.length === 0,
                    expression: "items.length === 0"
                  }
                ],
                staticStyle: {
                  "text-align": "center",
                  "font-size": "15px",
                  "margin-top": "50px"
                }
              },
              [_vm._v("Ничего не\n        найдено\n      ")]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "chooseStorageDialog",
        attrs: { title: "Выберите склад", items: _vm.storages }
      }),
      _vm._v(" "),
      _c("v-radio-dialog", {
        ref: "lifetimeOssModal",
        attrs: { title: "Срок жизни исключений" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0aef89ca", { render: render, staticRenderFns: staticRenderFns })
  }
}