<template>
    <catalog-page
        :toolbarButtons="toolbarButtons"
        :showBackButton="true"
        :showInputs="true"
        :allowInputs="true"
        :canOpenProductPage="true"
        :redirectToOrderWhenAddPositions="true"
        actionColType="favor-tiny"
        />
</template>

<script>

import CatalogPage from '~/component/CatalogPage'

export default {
    data() {
        return {
            toolbarButtons: [
                'showAssignTemplateModalButton',
                'downloadCatalogButton',
            ],
        }
    },
    components: {
        CatalogPage,
    },
};
</script>