<template>
    <v-ons-dialog :visible.sync="visible" modifier="fullscreen" >
        <v-ons-page>
            <v-ons-toolbar inline="true" >
                <div class="left">
                    <v-ons-toolbar-button @click="close" >
                        <v-ons-icon icon="md-close"></v-ons-icon>
                    </v-ons-toolbar-button>
                </div>
                <div class="center">Расчетные счета</div>
                <div class="right">
                    <v-ons-toolbar-button @click="save" >
                        <v-ons-icon icon="md-check"></v-ons-icon>
                    </v-ons-toolbar-button>
                </div>
            </v-ons-toolbar>

            <v-ons-list>
                <v-ons-list-header>Расчетный счет</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="settlementAccount.number" ></v-ons-input>
                </v-ons-list-item>

                <v-ons-list-header>Банк</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="settlementAccount.bank" ></v-ons-input>
                </v-ons-list-item>

                <v-ons-list-header>БИК</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="settlementAccount.bik" ></v-ons-input>
                </v-ons-list-item>

                <v-ons-list-header>Корр. счет</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="settlementAccount.corr_account" ></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>
        </v-ons-page>
    </v-ons-dialog>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            visible: false,
            resolve: null,
            reject: null,

            settlementAccount: {
                number: '',
                bank: '',
                bik: '',
                kpp: '',
                corr_account: '',
            }
        }
    },
    created() {

    },
    methods: {
        show(settlementAccount) {
            this.visible = true;
            this.reset();

            if (settlementAccount) {
                this.settlementAccount = {
                    number: settlementAccount.number,
                    bank: settlementAccount.bank,
                    bik: settlementAccount.bik,
                    kpp: settlementAccount.kpp,
                    corr_account: settlementAccount.corr_account,
                    is_default: settlementAccount.is_default,
                };
            }

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.visible = false;
        },
        save() {
            this.visible = false;
            this.resolve(this.settlementAccount);
        },
        reset() {
            this.settlementAccount = {
                number: '',
                bank: '',
                bik: '',
                kpp: '',
                corr_account: '',
            }
        }
    },
};
</script>
