import Index from '~/screen/manager/oos/Index'
import ProductRoute from '~/routes/ProductRoute'

let route = [
    {
        path: 'oos',
        name: 'manager-oos',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            ProductRoute('manager-oos'),
        ],
    },
]

export default route;