<template>
    <div :id="'category-' + item.id" :class="{'category-opened': item.isOpen}">
        <v-ons-list-item class="list-item-category" @click="$emit('clickCategory', item)" style="cursor:pointer;">
            <img class="arrow" src="/img/right-arrow.svg" alt="">
            {{ item.name }}
        </v-ons-list-item>

        <div v-if="item.loading" style="text-align: center; margin: 15px 0;">
            <v-ons-progress-circular indeterminate></v-ons-progress-circular>
        </div>

        <div v-if="item.isOpen">
            <product-item-list
                v-for="product in item.products"
                :key="product.id"
                :item="product"
                :actionColType="actionColType"
                :allowInputs="allowInputs"
                :canOpenProductPage="canOpenProductPage"
                :checked="checked"
                :counts="counts"
                :isDesktop="isDesktop"
                :lastImportDate="lastImportDate"
                :selectedPriceType="selectedPriceType"
                :showInputs="showInputs"
                :shouldShowRest="shouldShowRest"
                @clickGoToView="$emit('clickGoToView', $event)"
                @toggleFavor="$emit('toggleFavor', $event)"
                @countEnterDown="$emit('countEnterDown', $event)"
                @countShiftEnterDown="$emit('countShiftEnterDown', $event)"
            />
        </div>
    </div>
</template>

<script>

import ProductItemList from '~/component/catalog/ProductItemList'

export default {
    props: [
        'item',
        'isDesktop',
        'actionColType',
        'canOpenProductPage',
        'showInputs',
        'selectedPriceType',
        'counts',
        'checked',
        'lastImportDate',
        'allowInputs',
        'shouldShowRest',
    ],
    components: {
        ProductItemList,
    },
};
</script>