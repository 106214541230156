var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-ons-dialog",
        {
          attrs: { visible: _vm.dialogVisible, cancelable: "" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            preshow: _vm.loadGroups,
            prehide: _vm.prehide
          }
        },
        [
          _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
            _c("div", { staticClass: "center" }, [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSortable,
                      expression: "!isSortable"
                    }
                  ]
                },
                [
                  _c(
                    "v-ons-toolbar-button",
                    { on: { click: _vm.showNewGroupDialog } },
                    [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-ons-toolbar-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.isSortable = true
                        }
                      }
                    },
                    [
                      _c("v-ons-icon", {
                        attrs: { icon: "md-sort-amount-asc" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-ons-toolbar-button",
                    { on: { click: _vm.close } },
                    [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isSortable,
                      expression: "isSortable"
                    }
                  ]
                },
                [
                  _c(
                    "v-ons-toolbar-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.isSortable = false
                        }
                      }
                    },
                    [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isSortable,
                  expression: "!isSortable"
                }
              ]
            },
            [
              _c(
                "v-ons-list",
                { staticStyle: { height: "216px", "overflow-y": "auto" } },
                _vm._l(_vm.groups, function(group) {
                  return _c("v-ons-list-item", { key: group.id }, [
                    _c(
                      "div",
                      {
                        staticClass: "center",
                        on: {
                          click: function($event) {
                            return _vm.choose(group)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(group.name) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c("v-ons-icon", {
                          staticClass: "list-item__icon",
                          staticStyle: { margin: "0 4px", cursor: "pointer" },
                          attrs: { icon: "md-delete" },
                          on: {
                            click: function($event) {
                              return _vm.deleteGroup(group)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("v-ons-icon", {
                          staticClass: "list-item__icon",
                          staticStyle: { margin: "0 4px", cursor: "pointer" },
                          attrs: { icon: "md-edit" },
                          on: {
                            click: function($event) {
                              return _vm.showRenameGroupDialog(group)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSortable,
                  expression: "isSortable"
                }
              ]
            },
            [
              _c(
                "v-ons-list",
                { staticStyle: { height: "216px", "overflow-y": "auto" } },
                _vm._l(_vm.groups, function(group) {
                  return _c(
                    "v-ons-list-item",
                    { key: group.id, staticStyle: { padding: "0" } },
                    [
                      _c(
                        "div",
                        { staticClass: "center" },
                        [
                          _c("v-ons-icon", {
                            staticStyle: {
                              cursor: "pointer",
                              "line-height": "42px",
                              "font-size": "15px",
                              width: "35px",
                              "text-align": "center",
                              margin: "-14px 0"
                            },
                            attrs: { icon: "md-chevron-up" },
                            on: {
                              click: function($event) {
                                return _vm.orderUp(group)
                              }
                            }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(group.name) +
                              "\n                    "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("v-ons-icon", {
                            staticStyle: {
                              cursor: "pointer",
                              "line-height": "42px",
                              "font-size": "15px",
                              width: "35px",
                              "text-align": "center",
                              margin: "-14px 0"
                            },
                            attrs: { icon: "md-chevron-down" },
                            on: {
                              click: function($event) {
                                return _vm.orderDown(group)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        { attrs: { visible: _vm.newGroupDialogVisible } },
        [
          _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
            _c("div", { staticClass: "center" }, [_vm._v("Новая группа")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { margin: "20px 15px" } },
            [
              _c("v-ons-input", {
                staticStyle: { width: "100%" },
                attrs: { placeholder: "Название", model: _vm.name }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-row",
            [
              _c(
                "v-ons-col",
                [
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { modifier: "quiet" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("Отмена")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-col",
                [
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("Добавить")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ef9b8542", { render: render, staticRenderFns: staticRenderFns })
  }
}