<template>
    <div class="tile-item-product" :style="{width: width}">
        <div class="tile-item-product-inner">
            <div class="image-wrap">
                <img
                    v-if="item.images && item.images.thumb100"
                    :class="['product-image', canOpenProductPage ? 'cursor-pointer' : '', item.images.thumb100.hasImage ? 'has-product-image' : '']"
                    :src="item.images.thumb100.path"
                    :alt="item.name"
                    :title="item.name"
                    :width="item.images.thumb100.width"
                    :height="item.images.thumb100.height"
                    @click="clickGoToView"
                />
                <img
                    v-else
                    src="/img/placeholder.png"
                    :class="['product-image', canOpenProductPage ? 'cursor-pointer' : '']"
                    @click="clickGoToView"
                />
            </div>

            <div class="labels">
                <div v-if="actionColType === 'favor' || actionColType === 'favor-tiny'" class="cursor-pointer" @click="toggleFavor">
                    <v-ons-icon
                        icon="md-star"
                        :title="favorButtonTitle"
                        class="icon"
                        size="20px"
                        :style="{color: item.isInMyProducts ? '#ffe000' : '#e3e3e3'}"
                    ></v-ons-icon>
                </div>

                <div v-if="item.is_special" class="vip-icon icon" >VIP</div>
            </div>

            <div class="product-attributes">
                <div :class="['product-name', canOpenProductPage ? 'cursor-pointer' : '']" @click="clickGoToView">
                    {{ item.name }}
                </div>

                <div class="product-price-row" v-if="$user.canSeePrice">
                    <div class="product-price">
                        <span v-if="item.is_wait" >ждём</span>
                        <span
                            v-else
                            :class="[item.specialPrice.isShowMark ? 'is-low-special-price' : '']"
                        >
                            {{ calcPrice(item) }} р/{{ item.unit }}
                        </span>
                    </div>
                </div>

                <div class="product-add-button-wrap" v-if="allowInputs">
                    <div v-if="counts[item.id] > 0" class="button--material button product-counting">
                        <div @click="decCount" class="dec-button">
                            -
                        </div>

                        <div class="count-label">
                            {{ counts[item.id] }} {{ item.unit }}
                        </div>

                        <div @click="incCount" class="inc-button">
                            +
                        </div>
                    </div>

                    <div v-else @click="incCount" class="product-add-button button--material button cursor-pointer">
                        Купить
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import numeral from 'numeral'

export default {
    props: [
        'item',
        'isDesktop',
        'actionColType',
        'checked',
        'canOpenProductPage',
        'showInputs',
        'allowInputs',
        'selectedPriceType',
        'counts',
        'lastImportDate',
        'width',
    ],
    data() {
        return {
            isChecked: false,
        }
    },
    created() {
        this.isChecked = this.checked.includes(this.item.id);
        this.$bus.$on('product-update-favor-' + this.item.id, this.updateFavor);
    },
    destroyed() {
        this.$bus.$off('product-update-favor-' + this.item.id, this.updateFavor);
    },
    methods: {
        numeral(s) {
            return numeral(s);
        },
        toggleFavor() {
            this.$emit('toggleFavor', this.item);
        },
        updateFavor(value) {
            this.item.isInMyProducts = value;
        },
        clickGoToView() {
            this.$emit('clickGoToView', this.item);
        },
        showOverweightRestAlert(position) {
            let message = '';
            let lastImportDate = this.$moment.unix(this.lastImportDate).format("HH:mm от L");
            let rest = numeral(position.rest).format('0[.]00');

            if (parseInt(position.rest) === 0) {
                message += `Товар кончился: ${position.deletedAt} <br><br>`;
                message += `${position.name} <br> <b>Нет в наличии</b> <br><br>`;
            } else {
                message += `По данным на <b>${lastImportDate}</b> <br><br>`;
                message += `${position.name} <br> всего <b>${rest} ${position.unit}</b> <br><br>`;
            }

            message += `
                Вы можете добавить товар в полном объёме и отправить заказ как есть, или можете подобрать замену.<br><br>
                Если товар ещё не поступил, то менеджер по возможности предложит альтернативу.`;

            this.$ons.notification.alert({title: '', message: message});
        },
        calcPrice(item) {
            if (!this.$user.isManager || !item.prices) {
                return item.price;
            }

            if (!this.selectedPriceType) {
                return item.price;
            }

            let pr = item.prices.find(i => this.selectedPriceType == i.typeId);

            if (pr) {
                return pr.price;
            }

            return item.price;
        },
        incCount() {
            let mult = 1;
            let count = this.counts[this.item.id] || 0;

            if (this.item.mult) {
                mult = parseFloat(this.item.mult);
            }

            if (!count) {
                count = mult;
            } else {
                count = parseFloat(count) + mult;
            }

            this.$set(this.counts, this.item.id, numeral(count).format('0[.][000]'));
        },
        decCount() {
            let mult = 1;
            let count = this.counts[this.item.id] || 0;

            if (this.item.mult) {
                mult = parseFloat(this.item.mult);
            }

            count = parseFloat(count) - mult;

            if (count <= 0) {
                count = '';
            } else {
                count = numeral(count).format('0[.][000]');
            }

            this.$set(this.counts, this.item.id, numeral(count).format('0[.][000]'));
        },
        countKeydownHandler(e) {
            // plus
            if (e.keyCode === 107 || e.keyCode === 187) {
                e.preventDefault();
                this.incCount();
                return;
            }

            // minus
            if (e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
                this.decCount();
                return;
            }

            if ((e.keyCode === 13 || e.keyCode === 9) && e.shiftKey) {
                e.preventDefault();
                this.$emit('countShiftEnterDown', this.item);
                return;
            }

            if (e.keyCode === 13 || e.keyCode === 9) {
                e.preventDefault();
                this.$emit('countEnterDown', this.item);
            }
        },
    },
    computed: {
        shouldShowOverweightRestAlert() {
            if (!this.showInputs) {
                return;
            }

            if (this.item.isInMyProducts && this.$user.restAlwaysAvailable) {
                return;
            }

            return parseFloat(this.counts[this.item.id]) > parseFloat(this.item.rest);
        },
        favorButtonTitle() {
            if (this.item.isInMyProducts) {
                return 'Товар в избранном';
            }

            return 'Добавить в "Мои товары"';
        },
    },
    watch: {
        isChecked() {
            if (this.isChecked && !this.checked.includes(this.item.id)) {
                this.checked.push(this.item.id);
            }

            if (!this.isChecked && this.checked.includes(this.item.id)) {
                this.checked.splice(this.checked.indexOf(this.item.id), 1);
            }
        },
        checked() {
            this.isChecked = this.checked.includes(this.item.id);
        }
    },
};
</script>