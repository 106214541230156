var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Главная")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "home-page" }, [
        _c("div", { staticClass: "links-block" }, [
          _c(
            "div",
            { staticClass: "row-wrap" },
            [
              _vm.$user.canSee("catalog")
                ? _c(
                    "router-link",
                    {
                      staticClass: "col-wrap button--material",
                      attrs: { to: "/catalog" }
                    },
                    [_c("span", [_vm._v("Каталог")])]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row-wrap" },
            [
              _c(
                "router-link",
                {
                  staticClass: "col-wrap button--material",
                  attrs: { to: "/order/create" }
                },
                [_c("span", [_vm._v("Написать заявку")])]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "col-wrap button--material",
                  attrs: { to: "/order" }
                },
                [_c("span", [_vm._v("Заказы")])]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1bcf0cf1", { render: render, staticRenderFns: staticRenderFns })
  }
}