var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-splitter-side",
    {
      staticClass: "sidebar",
      attrs: {
        swipeable: "",
        width: "200px",
        collapse: _vm.$window.isDesktop ? "portrait" : "",
        side: "left",
        open: _vm.open
      },
      on: {
        "update:open": function($event) {
          _vm.open = $event
        }
      }
    },
    [
      _c(
        "v-ons-page",
        [
          _c("sidebar-list", {
            attrs: { type: _vm.userType },
            on: { click: _vm.onClick }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5eeeb0a1", { render: render, staticRenderFns: staticRenderFns })
  }
}