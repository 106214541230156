var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v(_vm._s(_vm.user.email))])
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        [
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "worker",
                  expression: "user.user_type === 'worker'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: "manager-user",
                    params: { user_id: _vm.director.id }
                  })
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Руководитель: " + _vm._s(_vm.director.email))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "customer",
                  expression: "user.user_type === 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-user-orders" })
                }
              }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Заказы")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "customer",
                  expression: "user.user_type === 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    _vm.$router.currentRoute.path + "/products"
                  )
                }
              }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Товары")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "customer",
                  expression: "user.user_type === 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: "manager-user-special-prices"
                  })
                }
              }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Спецпредложения")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "customer",
                  expression: "user.user_type === 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-user-catalog" })
                }
              }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Каталог клиента")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "customer",
                  expression: "user.user_type === 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-user-settings" })
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Коммерческие настройки")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: "manager-user-profile",
                    params: { user: _vm.user }
                  })
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Настройки профиля")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "customer",
                  expression: "user.user_type === 'customer'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: "manager-user-onec-connection",
                    params: { user: _vm.user }
                  })
                }
              }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Связь с 1С")])]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: "manager-user-admin",
                    params: { user: _vm.user }
                  })
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Администрирование")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.user_type === "manager",
                  expression: "user.user_type === 'manager'"
                }
              ],
              attrs: { modifier: "chevron longdivider", tappable: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "manager-user-permission" })
                }
              }
            },
            [_c("div", { staticClass: "center" }, [_vm._v("Права доступа")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2d3090ae", { render: render, staticRenderFns: staticRenderFns })
  }
}