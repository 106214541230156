var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Заказы")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading"
              }
            ],
            staticClass: "right"
          },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.choose } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "toolbar-popover-menu",
              { attrs: { "popover-class": "stick-right" } },
              [
                _c("toolbar-popover-menu-item", {
                  attrs: {
                    text: "Опции просмотра",
                    icon: "md-format-list-bulleted"
                  },
                  on: { click: _vm.showViewParamsModal }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.orders,
                expression: "orders"
              }
            ]
          },
          [
            _c(
              "ons-list",
              _vm._l(_vm.orders, function(order) {
                return _c(
                  "ons-list-item",
                  {
                    key: order.id,
                    staticStyle: { "padding-left": "0" },
                    attrs: { modifier: "longdivider" },
                    on: {
                      click: function($event) {
                        return _vm.toggleOrder(order.id)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "left",
                        staticStyle: {
                          width: "40px",
                          "justify-content": "center"
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.checked,
                              expression: "checked"
                            }
                          ],
                          attrs: { type: "checkbox", id: "chk-" + order.id },
                          domProps: {
                            value: order.id,
                            checked: Array.isArray(_vm.checked)
                              ? _vm._i(_vm.checked, order.id) > -1
                              : _vm.checked
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.checked,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = order.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checked = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checked = $$c
                              }
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "center" },
                      [
                        _c("span", { staticClass: "list-item__title" }, [
                          _vm._v(
                            "\n                            Заказ №" +
                              _vm._s(order.id) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: order.sended_at,
                                  expression: "order.sended_at"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "от " +
                                  _vm._s(_vm._f("moment")(order.sended_at, "L"))
                              )
                            ]
                          ),
                          _vm._v(
                            "\n                            на сумму\n                            "
                          ),
                          order.isNearlyCost
                            ? _c(
                                "span",
                                { staticStyle: { "margin-left": "5px" } },
                                [_vm._v("~")]
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n                            " +
                              _vm._s(order.totalCost) +
                              " руб.\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "list-item__subtitle" }, [
                          _vm._v(
                            "\n                            Статус: " +
                              _vm._s(order.status) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item-subtitles", {
                          attrs: {
                            item: order,
                            fields: _vm.$user.getOrderSubtitles(),
                            selected: _vm.$user.orderSelectedSubtitles
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.orders.length == 0,
                expression: "orders.length == 0"
              }
            ],
            staticStyle: {
              "text-align": "center",
              "font-size": "15px",
              "margin-top": "50px"
            }
          },
          [_vm._v("Ничего не найдено")]
        )
      ]),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "viewParamsModal",
        attrs: { multiple: "true", title: "Опции просмотра" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-58e29772", { render: render, staticRenderFns: staticRenderFns })
  }
}