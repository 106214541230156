var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Контроль минимальной цены")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                on: { click: _vm.save }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "params_enabled" }
                  },
                  [
                    _vm._v(
                      "\n                    Включить контроль\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: { value: true, "input-id": "params_enabled" },
                      model: {
                        value: _vm.params.enabled,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "enabled", $$v)
                        },
                        expression: "params.enabled"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Тип цен для сравнения:")]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "div",
                  { staticClass: "select-material select--material select" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.priceType,
                            expression: "params.priceType"
                          }
                        ],
                        staticClass: "select-input select-input--material",
                        attrs: { disabled: !_vm.params.enabled },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "priceType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.priceTypes, function(priceType) {
                        return _c(
                          "option",
                          {
                            key: priceType.id,
                            domProps: { value: priceType.id }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(priceType.name) +
                                "\n                        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "params_reprice" }
                  },
                  [
                    _vm._v(
                      "\n                    Переоценить товар, если импортируемая цена меньше\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: {
                        value: true,
                        disabled: !_vm.params.enabled,
                        "input-id": "params_reprice"
                      },
                      model: {
                        value: _vm.params.reprice,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "reprice", $$v)
                        },
                        expression: "params.reprice"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-35a778de", { render: render, staticRenderFns: staticRenderFns })
  }
}