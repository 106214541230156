var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Настройки каталога")])
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "", modifier: "chevron longdivider" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    _vm.$router.currentRoute.path + "/includes"
                  )
                }
              }
            },
            [_vm._v("\n            Выгрузка разделов\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "", modifier: "chevron longdivider" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    _vm.$router.currentRoute.path + "/pricing"
                  )
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Ценообразование разделов")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.pricing,
                        expression: "errors.pricing"
                      }
                    ],
                    staticStyle: { color: "red", "margin-left": "10px" }
                  },
                  [_vm._v("Есть ошибки")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "", modifier: "chevron longdivider" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    _vm.$router.currentRoute.path + "/categories"
                  )
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Управление разделами")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.categories,
                        expression: "errors.categories"
                      }
                    ],
                    staticStyle: { color: "red", "margin-left": "10px" }
                  },
                  [_vm._v("Есть ошибки")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "", modifier: "chevron longdivider" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    _vm.$router.currentRoute.path + "/product-rules"
                  )
                }
              }
            },
            [
              _c("div", { staticClass: "center" }, [
                _vm._v("Перемещение/Удаление товаров")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.productRules,
                        expression: "errors.productRules"
                      }
                    ],
                    staticStyle: { color: "red", "margin-left": "10px" }
                  },
                  [_vm._v("Есть ошибки")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "", modifier: "chevron longdivider" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    _vm.$router.currentRoute.path + "/control-min-price"
                  )
                }
              }
            },
            [_vm._v("\n            Контроль минимальной цены\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              staticClass: "cursor-pointer",
              attrs: { tappable: "", modifier: "chevron longdivider" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    _vm.$router.currentRoute.path + "/rounding-price"
                  )
                }
              }
            },
            [_vm._v("\n            Округление цен\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-34560d0f", { render: render, staticRenderFns: staticRenderFns })
  }
}