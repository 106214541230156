var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Инвентаризация")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasChecked,
                    expression: "hasChecked"
                  }
                ],
                on: { click: _vm.deleteInventories }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.items.length,
              expression: "items.length"
            }
          ]
        },
        _vm._l(_vm.items, function(item) {
          return _c(
            "ons-list-item",
            {
              key: item.id,
              staticClass: "cursor-pointer",
              attrs: { modifier: "chevron longdivider" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "left",
                  staticStyle: { "margin-right": "10px", "min-width": "0" }
                },
                [
                  _c("v-ons-checkbox", {
                    model: {
                      value: item.isChecked,
                      callback: function($$v) {
                        _vm.$set(item, "isChecked", $$v)
                      },
                      expression: "item.isChecked"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "center",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("inventory/view/" + item.id)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                Инвентаризация №" +
                      _vm._s(item.id) +
                      " от " +
                      _vm._s(_vm._f("moment")(item.created_at, "L")) +
                      "\n            "
                  )
                ]
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.items.length == 0,
              expression: "items.length == 0"
            }
          ],
          staticStyle: {
            "text-align": "center",
            "font-size": "15px",
            "margin-top": "50px"
          }
        },
        [_vm._v("Инвентаризация еще не проведена")]
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          attrs: { position: "bottom right", title: "Начать инвентаризацию" },
          on: {
            click: function($event) {
              return _vm.$router.push("inventory/create")
            }
          }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-55a234ea", { render: render, staticRenderFns: staticRenderFns })
  }
}