var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-password" },
    [
      _c("v-ons-input", {
        attrs: {
          type: _vm.showPassword ? "text" : "password",
          placeholder: _vm.placeholder,
          name: _vm.name
        },
        on: { input: _vm.handleInput },
        model: {
          value: _vm.password,
          callback: function($$v) {
            _vm.password = $$v
          },
          expression: "password"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "icon-wrap" },
        [
          _c("v-ons-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showPassword,
                expression: "!showPassword"
              }
            ],
            attrs: { icon: "md-eye-off" },
            on: {
              click: function($event) {
                _vm.showPassword = true
              }
            }
          }),
          _vm._v(" "),
          _c("v-ons-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPassword,
                expression: "showPassword"
              }
            ],
            attrs: { icon: "md-eye" },
            on: {
              click: function($event) {
                _vm.showPassword = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5ba17818", { render: render, staticRenderFns: staticRenderFns })
  }
}