<template>
    <v-ons-page>
        <v-ons-toolbar inline="true">
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>
            <div class="center">Ваш менеджер</div>

            <div class="right">
                <v-ons-toolbar-button @click="$router.push('/mymanager/routes')">
                    <v-ons-icon icon="md-calendar-note"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item v-if="manager.name" modifier="longdivider">
                <span class="list-item__title">Имя</span>
                <span class="list-item__subtitle">{{ manager.name }}</span>
            </v-ons-list-item>

            <v-ons-list-item v-if="manager.email" modifier="longdivider">
                <a class="center" :href="'mailto:' + manager.email" style="color: #1f1f21">
                    <span class="list-item__title" style="text-decoration: underline;">{{ manager.email }}</span>
                    <span class="list-item__subtitle">Email</span>
                </a>
                <div class="right cursor-pointer" @click="copyValue(manager.email)" style="margin: 0 5px;">
                    <v-ons-icon icon="md-copy" size="20px"></v-ons-icon>
                </div>
            </v-ons-list-item>

            <v-ons-list-item v-if="manager.phone" modifier="longdivider">
                <a class="center" :href="'tel:' + manager.phone" style="color: #1f1f21">
                    <span class="list-item__title" style="text-decoration: underline;">{{ manager.phone }}</span>
                    <span class="list-item__subtitle">Телефон</span>
                </a>
                <div class="right cursor-pointer">
                    <a v-show="whatsappLink" :href="whatsappLink"
                       style="line-height: 40px; width: 40px; text-align: center; margin: 0 5px;">
                        <img width="25" src="/img/whatsapp.svg" alt="whatsapp"/>
                    </a>

                    <a v-show="viberLink" :href="viberLink"
                       style="line-height: 40px; width: 40px; text-align: center; margin: 0 5px;">
                        <img width="25" src="/img/viber.svg" alt="viber"/>
                    </a>

                    <div @click="copyValue(manager.phone)" style="line-height: 40px; margin: 0 5px;">
                        <v-ons-icon icon="md-copy" size="20px"></v-ons-icon>
                    </div>
                </div>
            </v-ons-list-item>

            <v-ons-list-item v-if="manager.organisation" modifier="longdivider">
                <span class="list-item__title">{{ manager.organisation }}</span>
                <span class="list-item__subtitle">Фактическое наименование компании</span>
            </v-ons-list-item>

            <v-ons-list-item v-if="manager.office_address" modifier="longdivider">
                <span class="list-item__title">{{ manager.office_address }}</span>
                <span class="list-item__subtitle">Адрес офиса</span>
            </v-ons-list-item>

            <v-ons-list-item v-if="manager.office_work_schedule" modifier="longdivider">
                <span class="list-item__title">{{ manager.office_work_schedule }}</span>
                <span class="list-item__subtitle">График работы</span>
            </v-ons-list-item>

            <v-ons-list-item v-if="manager.storages && manager.storages.length > 0" modifier="longdivider">
                <span class="list-item__title">
                    <span v-for="storage in manager.storages" style="margin-bottom: 10px; display: block;">
                        {{ storage.name }}
                        <br>
                    </span>
                </span>
                <span class="list-item__subtitle">Адрес склада</span>
            </v-ons-list-item>

            <v-ons-list-item v-if="manager.about_company" modifier="longdivider">
                <span class="list-item__title">{{ manager.about_company }}</span>
                <span class="list-item__subtitle">О компании</span>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

import copy from 'copy-text-to-clipboard'

export default {
    data() {
        return {
            manager: {},
        }
    },
    created() {
        this.$http.get('/api/user/mymanager', {params: {manager: this.$bus.referralManager}}).then(response => {
            this.manager = response.data;
        });
    },
    methods: {
        copyValue(value) {
            copy(value);

            this.$ons.notification.toast({
                timeout: 3000,
                message: 'Скопировано',
                buttonLabel: 'OK',
            });
        },
    },
    computed: {
        phoneTrim() {
            if (!this.manager || !this.manager.phone) {
                return;
            }

            return this.manager.phone.replace(/[^\d]/g, '');
        },
        whatsappLink() {
            if (!this.phoneTrim) {
                return;
            }

            return 'whatsapp://send?phone=' + this.phoneTrim;
        },
        viberLink() {
            if (!this.phoneTrim) {
                return;
            }

            return 'viber://add?number=' + this.phoneTrim;
        },
    }
};
</script>