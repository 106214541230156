var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Выгрузка разделов")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading"
              }
            ],
            staticClass: "right"
          },
          [
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Сохранить" }, on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("v-ons-card", [
            _vm._v(
              "\n            Выберите какие разделы должны выгружаться в ваш Каталог\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list",
            _vm._l(_vm.categories, function(category, key) {
              return _c(
                "ons-list-item",
                {
                  key: category.id,
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleCheckbox(category)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "center" }, [
                    _c("span", { staticClass: "list-item__title" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(category.name) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: category.is_deleted,
                            expression: "category.is_deleted"
                          }
                        ],
                        staticClass: "list-item__subtitle",
                        staticStyle: { color: "red" }
                      },
                      [_vm._v("Раздел не найден")]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("v-ons-icon", {
                        style: {
                          fontSize: "20px",
                          color: _vm.includes.includes(category.id.toString())
                            ? "#009688"
                            : "#cccaca",
                          fontWeight: _vm.includes.includes(
                            category.id.toString()
                          )
                            ? "bold"
                            : ""
                        },
                        attrs: { icon: "md-check" }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-57266d1e", { render: render, staticRenderFns: staticRenderFns })
  }
}