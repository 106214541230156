<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
      </div>

      <div class="center">Out of stock</div>

      <div class="right">
        <v-ons-toolbar-button
          @click="downloadPdf"
          title="Скачать список товаров в ожидании"
          :disabled="downloading"
        >
          <v-ons-icon icon="md-download"></v-ons-icon>
        </v-ons-toolbar-button>

        <v-ons-toolbar-button
          @click="showLifetimeOssModal"
          title="Скачать список товаров в ожидании"
          :disabled="downloading"
        >
          <v-ons-icon icon="md-timer"></v-ons-icon>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>


    <div class="background">
      <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}">
        <v-ons-progress-circular indeterminate></v-ons-progress-circular>
      </div>
    </div>

    <div class="content">
      <ons-list-item v-show="storages.length > 1" modifier="chevron longdivider" @click="showChooseStorage">
        <span class="list-item__title">Склад: {{ selectedStorageName }}</span>
        <span v-if="selectedStorage && !selectedStorage.is_configured" style="color: red" class="list-item__subtitle">
          Не настроено
        </span>
      </ons-list-item>

      <v-ons-card>
        <p>Эти товары закончились, но отображаются в каталоге с отметкой "ждём".</p>
      </v-ons-card>

      <div class="catalog-list-component">
        <div v-if="items.length > 0">
          <table class="table table-bordered table-catalog">
            <tbody>
            <tr v-for="(item, key) in items">
              <td v-if="item.type === 'category'" colspan=4 class="col-catname">{{ item.name }}</td>

              <td
                v-if="item.type === 'product'"
                class="col-name"
                @click="$router.push({name: 'manager-oos-product-view', params: {prd_id: item.id}})"
                style="border-right: 0">

                <div>{{ item.name }}</div>

                <span class="list-item__subtitle list-item--material__subtitle">
                                    <div class="wrap">
                                        <div class="col xl-1-2 xl-left">
                                            <span style="font-size: 14px">
                                                {{
                                                (item.usedCount > 0) ? 'Связи: ' + item.usedCount + 'шт' : 'Товар не используется'
                                              }}
                                            </span>
                                        </div>

                                        <div class="col xl-1-2 xl-right" v-show="item.is_special">
                                            <span style="font-size: 14px">
                                                <div class="vip-icon icon">VIP</div>
                                            </span>
                                        </div>
                                    </div>
                                </span>
              </td>
              <td v-if="item.type === 'product'" @click="item.popoverMenuVisible = true" style="border-left: 0">
                <div class="popover-menu" :id="'popoverMenu-' + key">
                  <v-ons-icon icon="md-more-vert" style="font-size: 20px; margin: 0 10px;"></v-ons-icon>

                  <v-ons-popover cancelable
                                 :visible.sync="item.popoverMenuVisible"
                                 :target="'#popoverMenu-' + key"
                                 cover-target="true"
                                 :direction="key === items.length - 1 ? 'up' : 'down'"
                                 class="wait-page-popover-menu"
                  >
                    <v-ons-list @click="item.popoverMenuVisible = false;">
                      <v-ons-list-item @click="unsetAvail(item)">
                        <div class="left icon">
                          <v-ons-icon icon="md-edit"></v-ons-icon>
                        </div>
                        <div class="center">Удалить из Исключений OOS</div>
                      </v-ons-list-item>
                      <v-ons-list-item @click="goToLinks(item.id)" v-if="item.usedCount > 0">
                        <div class="left icon">
                          <v-ons-icon icon="md-link"></v-ons-icon>
                        </div>
                        <div class="center">Перейти к связям товара</div>
                      </v-ons-list-item>
                    </v-ons-list>
                  </v-ons-popover>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-show="items.length === 0" style="text-align: center; font-size: 15px; margin-top: 50px">Ничего не
          найдено
        </div>
      </div>
    </div>

    <v-list-dialog ref="chooseStorageDialog" title="Выберите склад" :items="storages"/>

    <v-radio-dialog ref="lifetimeOssModal" title="Срок жизни исключений" />
  </v-ons-page>
</template>

<script>

import ListDialog from "~/component/ListDialog";
import RadioDialog from "~/component/RadioDialog";

export default {
  data() {
    return {
      downloading: false,
      loading: true,
      products: [],
      categories: [],
      choseStorage: null,
      storages: [],
      waitTabDownloadButtonIsDisabled: false,
    }
  },
  created() {
    if (this.$user.selectedStorage) {
      this.choseStorage = this.$user.selectedStorage;
    }

    this.loadStorages().then(() => {
      this.load();
    });
  },
  methods: {
    load() {
      this.loading = true;

      this.$http.get(
        '/api/catalog/get-oos-avail',
        {params: {storageId: this.choseStorage}}
      ).then(response => {
        this.products = response.data.products
          .map(i => {
            i.popoverMenuVisible = false;
            return i;
          });

        this.categories = response.data.categories;

        this.$nextTick(() => {
          this.loading = false;
        });
      }, () => {
        this.$nextTick(() => {
          this.loading = false;
        });
        this.$ons.notification.toast({
          buttonLabel: 'OK',
          message: 'Произошла ошибка.',
        });
      });
    },
    unsetAvail(position) {
      let positions = [position.id];

      this.$http.post(
        '/api/catalog/unset-available',
        {positions: positions},
        {params: {storageId: this.choseStorage}}
      ).then(() => {
        this.load();
        this.$ons.notification.toast({
          timeout: 2500,
          message: 'Товары перемещены в список "OOS"',
          buttonLabel: 'OK'
        });
      }, () => {
        this.$ons.notification.toast({
          message: 'Произошла ошибка.',
          buttonLabel: 'OK'
        });
      });
    },
    goToLinks(id) {
      this.$router.push('/manager/product-avail/products/storage-' + this.choseStorage + '/' + id);
    },
    downloadPdf() {
      if (this.downloading) {
        return;
      }

      this.downloading = true;
      this.$http.post(
        '/api/catalog/get-oos-avail-pdf',
        {},
        {
          responseType: 'arraybuffer',
          params: {storageId: this.choseStorage}
        }
      ).then(response => {
        let disposition = response.headers.get('content-disposition')
        let matches = disposition.match(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i);
        let filename = matches[2];

        let blob = new Blob([response.data], {type: response.headers.get('content-type')});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        setTimeout(() => {
          this.downloading = false;
          this.$bus.$emit('waitTabDownloaded');
        }, 2000);
      }, () => {
        this.$ons.notification.toast({
          timeout: 2500,
          message: 'Произошла ошибка.',
          buttonLabel: 'OK'
        });

        this.downloading = false;
        this.$bus.$emit('waitTabDownloaded');
      });
    },
    loadStorages() {
      return this.$http.get(
        '/api/manager/storages'
      ).then(response => {
        this.storages = response.data;

        if (!this.choseStorage) {
          this.choseStorage = this.storages.find(s => s.is_default).id;
        }

        if (!this.choseStorage) {
          this.choseStorage = this.storages[0].id;
        }
      });
    },
    showChooseStorage() {
      this.$refs.chooseStorageDialog.show().then(response => {
        this.choseStorage = response;
        this.$user.selectedStorage = this.choseStorage;
        this.load();
      });
    },
    showLifetimeOssModal() {
      const items = [
        {
          id: 7,
          name: '7 дней',
        },
        {
          id: 14,
          name: '14 дней',
        },
        {
          id: 28,
          name: '28 дней',
        },
      ]

      this.$refs.lifetimeOssModal.show({items: items, selected: this.$user.lifetimeOss}).then(selected => {
        this.$http.post('/api/user/lifetime-oss', {lifetimeOss: selected}).then(() => {
          this.$user.lifetimeOss = selected
        }, () => {
          this.$ons.notification.toast({
            message: 'Произошла ошибка.',
            buttonLabel: 'OK'
          });
        });
      });
    }
  },
  computed: {
    items() {
      let items = [];

      this.categories
        .forEach(category => {
          category.type = 'category';

          let categoryProducts = this.products
            .filter((prd) => prd.category_id === category.id)
            .filter((prd) => {
              if (this.isSearchActive && this.searchQuery) {
                return prd.name.toLowerCase().includes(this.searchQuery.toLowerCase())
              }

              return true;
            });

          if (categoryProducts.length > 0) {
            items.push(category);
            categoryProducts.forEach(product => {
              product.type = 'product';
              items.push(product);
            });
          }
        });

      return items;
    },
    selectedStorage() {
      return this.storages.find(s => s.id === parseInt(this.choseStorage));
    },
    selectedStorageName() {
      let storage = this.storages.find(s => s.id === parseInt(this.choseStorage));

      if (!storage) {
        return 'Неизвестно';
      }

      return storage.name;
    },
  },
  components: {
    'v-list-dialog': ListDialog,
    'v-radio-dialog': RadioDialog,
  },
};
</script>