/**
 * This is where all the authorization login is stored
 */
export default function UserHasPermissions (router) {
    /**
     * Before each route we will see if the current user is authorized
     * to access the given route
     */
    router.beforeEach((to, from, next) => {
        if (typeof ym !== 'undefined') {
            ym(52291717, 'hit', to.path, {
                referer: from.path,
                title: to.name,
            });
        }
        next();
    })
}