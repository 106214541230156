var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-ons-dialog",
        {
          attrs: { visible: _vm.dialogVisible, cancelable: "" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            preshow: _vm.loadTemplates
          }
        },
        [
          _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
            _c("div", { staticClass: "center" }, [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "v-ons-toolbar-button",
                  { on: { click: _vm.showNewTemplateDialog } },
                  [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-ons-toolbar-button",
                  { on: { click: _vm.close } },
                  [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.templates.length === 0,
                  expression: "templates.length === 0"
                }
              ],
              staticStyle: {
                "line-height": "216px",
                "text-align": "center",
                "font-size": "20px",
                color: "#a8a8a8"
              }
            },
            [_vm._v("\n            Список пуст\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.templates.length > 0,
                  expression: "templates.length > 0"
                }
              ],
              staticStyle: { height: "216px", "overflow-y": "auto" }
            },
            _vm._l(_vm.templates, function(template) {
              return _c("v-ons-list-item", { key: template.id }, [
                _c(
                  "div",
                  {
                    staticClass: "center",
                    on: {
                      click: function($event) {
                        return _vm.choose(template)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(template.name) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-icon", {
                      staticClass: "list-item__icon",
                      staticStyle: { margin: "0 4px", cursor: "pointer" },
                      attrs: { icon: "md-delete" },
                      on: {
                        click: function($event) {
                          return _vm.deleteTemplate(template)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("v-ons-icon", {
                      staticClass: "list-item__icon",
                      staticStyle: { margin: "0 4px", cursor: "pointer" },
                      attrs: { icon: "md-edit" },
                      on: {
                        click: function($event) {
                          return _vm.showRenameTemplateDialog(template)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        { attrs: { visible: _vm.newTemplateDialogVisible } },
        [
          _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
            _c("div", { staticClass: "center" }, [_vm._v("Новый шаблон")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { margin: "20px 15px" } },
            [
              _c("v-ons-input", {
                staticStyle: { width: "100%" },
                attrs: { placeholder: "Название", model: _vm.name }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-row",
            [
              _c(
                "v-ons-col",
                [
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { modifier: "quiet" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("Отмена")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-col",
                [
                  _c(
                    "v-ons-button",
                    {
                      staticStyle: { width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("Добавить")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-464a484e", { render: render, staticRenderFns: staticRenderFns })
  }
}