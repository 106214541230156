<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Пригласить сотрудника</div>
            <div class="right">

            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-header>Email</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="email" ></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-item>
                <v-ons-button @click="send" :disabled="!email" >Пригласить</v-ons-button>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            email: ''
        }
    },
    methods: {
        send() {
            this.$http.post('/api/workers/invite', {email: this.email}).then(response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Приглашение отправлено',
                    buttonLabel: 'OK',
                });

                this.$router.go(-1);
            }, response => {
                let message = response.data.message;
                let buttonLabel = 'OK';

                if (message == 'Приглашение уже отправлено') {
                    buttonLabel = ['OK', 'Отправить повторно'];
                }

                this.$ons.notification.alert({
                    title: '',
                    message: message,
                    buttonLabel: buttonLabel,
                }).then(response => {
                    if (buttonLabel.length == 2 && response == 1) {
                        this.retry();
                    }
                });
            });
        },
        retry() {
            this.$http.post('/api/workers/invite', {email: this.email, retry: true}).then(response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Приглашение отправлено повторно',
                    buttonLabel: 'OK',
                });

                this.$router.go(-1);
            }, response => {
                this.$ons.notification.alert({
                    title: '',
                    message: response.data.message,
                });
            });
        },
    },
};
</script>
