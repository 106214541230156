<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">В ожидании</div>

            <div class="right" >
                <toolbar-popover-menu popover-class="stick-right" >
                    <ons-list-item @click="$router.push('/manager/product-link')" >Ошибки связей</ons-list-item>
                    <ons-list-header>Отображать по:</ons-list-header>
                    <ons-list-item @click="$router.push('/manager/product-avail/users')" >
                        <div class="left" style="min-width: 25px; justify-content: center;" >
                            <v-ons-icon icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" style="padding-left: 0" >
                            пользователям
                        </div>
                    </ons-list-item>
                    <ons-list-item @click="$router.push('/manager/product-avail/products')" >
                        <div class="left" style="min-width: 25px; justify-content: center;" >
                        </div>
                        <div class="center" style="padding-left: 0"  >
                            товарам
                        </div>
                    </ons-list-item>
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <ons-list-item v-show="storages.length > 1" modifier="chevron longdivider" @click="showChooseStorage">
                <span class="list-item__title">Склад: {{ selectedStorageName }}</span>
                <span v-if="selectedStorage && !selectedStorage.is_configured" style="color: red" class="list-item__subtitle">
                    Не настроено
                </span>
            </ons-list-item>

            <ons-list v-show="users" >
                <ons-list-item
                    v-for="user in users"
                    :key="user.id"
                    modifier="chevron longdivider"
                    tappable
                    @click="onClickUser(user)" >

                    <div class="left" style="min-width: 40px; text-align: center;" >
                        {{ user.id }}
                    </div>
                    <div class="center" >
                        {{ user.email }}
                    </div>
                </ons-list-item>
            </ons-list>

            <div v-show="users.length === 0" style="text-align: center; font-size: 15px; margin-top: 50px" >
                Ошибок связей не найдено
            </div>
        </div>

        <v-list-dialog ref="chooseStorageDialog" title="Выберите склад" :items="storages" />
    </v-ons-page>
</template>

<script>
import ListDialog from "~/component/ListDialog";

export default {
    data() {
        return {
            loaded: false,
            loading: true,

            choseStorage: null,
            storages: [],
            users: [],
        }
    },
    created() {
        this.loadStorages().then(() => {
            this.load();
        });

        if (this.$route.params.storage_id) {
            this.choseStorage = this.$route.params.storage_id;
        }

        this.$bus.$on('loadUsersAvail', this.load);
    },
    destroyed() {
        this.$bus.$off('loadUsersAvail');
    },
    methods: {
        load() {
            this.$http.get(
                '/api/manager/product-avail/get-users',
                {params: {storageId: this.choseStorage}}
            ).then(response => {
                this.users = response.data;

                this.$nextTick(() => {
                    this.loading = false;
                    this.loaded = true;
                });
            });
        },
        loadStorages() {
            return this.$http.get(
                '/api/manager/storages',
                {params: {withConfigured: true}}
            ).then(response => {
                this.storages = response.data;

                if (!this.choseStorage) {
                    let defaultStorage = this.storages.find(s => s.is_default) || this.storages[0];
                    this.choseStorage = defaultStorage.id;
                }
            });
        },
        showChooseStorage() {
            this.$refs.chooseStorageDialog.show().then(response => {
                this.choseStorage = response;
                this.load();
            });
        },
        onClickUser(user) {
            this.$router.push({
                name: 'manager-product-avail-users-user',
                params: {
                    user_id: user.id,
                    storage_id: this.choseStorage
                },
            });
        }
    },
    computed: {
        selectedStorage() {
            return this.storages.find(s => s.id === parseInt(this.choseStorage));
        },
        selectedStorageName() {
            let storage = this.storages.find(s => s.id === parseInt(this.choseStorage));

            if (!storage) {
                return 'Неизвестно';
            }

            return storage.name;
        },
    },
    components: {
        'v-list-dialog': ListDialog,
    },
};
</script>