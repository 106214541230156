var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Импорт")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.view === "repair" &&
                      !_vm.isSelectAll &&
                      _vm.doubles.length > 0,
                    expression:
                      "view === 'repair' && !isSelectAll && doubles.length > 0"
                  }
                ],
                attrs: { title: "Выбрать всё" },
                on: { click: _vm.selectAll }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.view === "repair" &&
                      _vm.isSelectAll &&
                      _vm.doubles.length > 0,
                    expression:
                      "view === 'repair' && isSelectAll && doubles.length > 0"
                  }
                ],
                attrs: { title: "Снять выделение" },
                on: { click: _vm.unselectAll }
              },
              [
                _c("v-ons-icon", {
                  staticStyle: { color: "yellow" },
                  attrs: { icon: "md-check-all" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "toolbar-popover-menu",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.view === "upload" &&
                      _vm.$upload.meta("file").status !== "sending" &&
                      !_vm.sending,
                    expression:
                      "view === 'upload' && $upload.meta('file').status !== 'sending' && !sending"
                  }
                ],
                attrs: { "popover-class": "stick-right" }
              },
              [
                _c("toolbar-popover-menu-item", {
                  attrs: { text: "Повторить импорт" },
                  on: { click: _vm.repeatLastImport }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  attrs: { text: "Отмена последнего импорта" },
                  on: { click: _vm.cancelLastImport }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  attrs: { text: "Скачать файл импорта" },
                  on: { click: _vm.downloadFile }
                }),
                _vm._v(" "),
                _vm.$user.isAdmin
                  ? _c("toolbar-popover-menu-item", {
                      attrs: { text: "Скачать JSON" },
                      on: { click: _vm.downloadJsonFile }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.view === "upload",
              expression: "view === 'upload'"
            }
          ],
          staticStyle: { "text-align": "center", "margin-top": "20px" }
        },
        [
          _c(
            "div",
            {
              staticClass: "page-loading",
              style: {
                height: _vm.$window.height - 56 + "px",
                flexDirection: "column"
              }
            },
            [
              _c(
                "v-ons-button",
                {
                  staticStyle: { width: "180px" },
                  attrs: {
                    disabled:
                      _vm.$upload.meta("file").status === "sending" ||
                      _vm.sending
                  },
                  on: {
                    click: function($event) {
                      return _vm.$upload.select("file")
                    }
                  }
                },
                [
                  _vm.$upload.meta("file").status === "sending" || _vm.sending
                    ? _c("span", [_vm._v("Загрузка...")])
                    : _c("span", [_vm._v("Загрузить файл")])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "15px", "font-size": "13px" } },
                [
                  _vm._v(
                    "Последнее обновление: " +
                      _vm._s(_vm._f("moment")(_vm.lastImportDate, "L в HH:mm"))
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.view === "repair",
              expression: "view === 'repair'"
            }
          ]
        },
        [
          _vm._l(_vm.doublesByManagers, function(doublesByManager, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "v-ons-list",
                  { staticStyle: { "margin-bottom": "50px" } },
                  [
                    doublesByManager.name !== "default"
                      ? _c("v-ons-list-header", [
                          _vm._v(_vm._s(doublesByManager.name))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(doublesByManager.products, function(item, indexd) {
                      return _c(
                        "div",
                        { key: indexd },
                        [
                          _c(
                            "v-ons-list-item",
                            { attrs: { modifier: "longdivider" } },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "label-reset center",
                                  attrs: { for: "chk-" + index }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.name) +
                                      "\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "label-reset right",
                                  attrs: { for: "chk-" + index }
                                },
                                [
                                  _c(
                                    "small",
                                    { staticStyle: { "margin-right": "5px" } },
                                    [_vm._v("Объединить")]
                                  ),
                                  _vm._v(" "),
                                  _c("v-ons-checkbox", {
                                    attrs: {
                                      "input-id": "chk-" + index,
                                      value: item.hash_name
                                    },
                                    model: {
                                      value: _vm.choosedDoubles,
                                      callback: function($$v) {
                                        _vm.choosedDoubles = $$v
                                      },
                                      expression: "choosedDoubles"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-ons-list-item",
                            { attrs: { modifier: "longdivider" } },
                            [
                              _c("span", { staticClass: "list-item__title" }, [
                                _vm._v("Прайс: " + _vm._s(item.first_name))
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "list-item__subtitle" },
                                [_vm._v("Цена: " + _vm._s(item.price))]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-ons-list-item",
                            { attrs: { modifier: "longdivider" } },
                            [
                              _c("span", { staticClass: "list-item__title" }, [
                                _vm._v(
                                  "Каталог: " + _vm._s(item.double.first_name)
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "list-item__subtitle" },
                                [_vm._v("Цена: " + _vm._s(item.double.price))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "45px" } }),
          _vm._v(" "),
          _c(
            "v-ons-button",
            {
              staticStyle: {
                position: "fixed",
                bottom: "5px",
                left: "50%",
                "margin-left": "-110px",
                width: "220px"
              },
              on: { click: _vm.runImport }
            },
            [_vm._v("Импорт")]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.view === "importing",
              expression: "view === 'importing'"
            }
          ]
        },
        [
          _c(
            "div",
            {
              staticClass: "page-loading",
              style: { height: _vm.$window.height - 56 + "px" }
            },
            [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.view === "report",
              expression: "view === 'report'"
            }
          ]
        },
        [
          _c(
            "div",
            {
              staticClass: "page-loading",
              style: {
                flexDirection: "column",
                height: _vm.$window.height - 56 + "px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "font-size": "20px", "margin-bottom": "20px" }
                },
                [
                  _vm._v(
                    "\n                Импорт успешно завершён\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-ons-button",
                { staticStyle: { width: "180px" }, on: { click: _vm.reset } },
                [_vm._v("OK")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("v-list-uploaded-files-dialog", { ref: "listUploadedFilesDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fb632238", { render: render, staticRenderFns: staticRenderFns })
  }
}