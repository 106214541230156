var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v(_vm._s(_vm.user.email))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "ons-list",
        _vm._l(_vm.products, function(product) {
          return _c(
            "ons-list-item",
            {
              key: product.id,
              attrs: {
                modifier:
                  product.replacement.id || product.avail
                    ? "longdivider"
                    : "chevron longdivider",
                tappable: ""
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "center",
                  on: {
                    click: function($event) {
                      return _vm.onClickProduct(product)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "list-item__title" }, [
                    _vm._v(_vm._s(product.name))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: product.replacement.id,
                          expression: "product.replacement.id"
                        }
                      ],
                      staticClass: "list-item__subtitle"
                    },
                    [_vm._v(_vm._s(product.replacement.name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: product.avail,
                          expression: "product.avail"
                        }
                      ],
                      staticClass: "list-item__subtitle"
                    },
                    [_vm._v("Ожидаем поступление")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: product.archive,
                          expression: "product.archive"
                        }
                      ],
                      staticClass: "list-item__subtitle"
                    },
                    [_vm._v("Удалить")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: product.replacement.id,
                      expression: "product.replacement.id"
                    }
                  ],
                  staticClass: "right",
                  on: {
                    click: function($event) {
                      return _vm.cancel(product)
                    }
                  }
                },
                [
                  _c(
                    "v-ons-button",
                    { attrs: { modifier: "quiet" } },
                    [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                    1
                  )
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2d189c88", { render: render, staticRenderFns: staticRenderFns })
  }
}