<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">{{ product.name }}</div>

            <div class="right">
                <v-ons-toolbar-button v-show="hasChecked" @click="uncheckAll" >
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="!hasChecked" @click="checkAll" >
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <ons-list v-show="users" >
            <ons-list-item
                v-for="user in users"
                :key="user.id"
                modifier="longdivider"
                @click="user.isChecked = !user.isChecked" >

                <div class="left" style="min-width: 40px; text-align: center;" >
                    <v-ons-checkbox v-model="user.isChecked" />
                </div>
                <div class="center" >
                    {{ user.id }} - {{ user.email }}
                </div>
            </ons-list-item>
        </ons-list>

        <v-ons-fab
            v-show="hasChecked"
            position="bottom right"
            @click="$router.push({name: 'manager-product-avail-products-product', params: {users: checkedUserIds}})" >
                <v-ons-icon icon="md-arrow-right"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            product: {},
            users: [],
        }
    },
    created() {
        this.load();
    },
    destroyed() {

    },
    methods: {
        checkAll() {
            this.users.forEach(i => {
                i.isChecked = true;
            })
        },
        uncheckAll() {
            this.users.forEach(i => {
                i.isChecked = false;
            })
        },
        load() {
            this.$http.get('/api/manager/product-avail/get-product-from-catalog', {params: {id: this.$route.params.product_id}}).then(response => {
                this.product = response.data;
            });

            this.$http.get(
                '/api/manager/product-avail/get-users-by-product',
                {
                    params: {
                        id: this.$route.params.product_id,
                        storageId: this.$route.params.storage_id,
                    }
                }
            ).then(response => {
                this.users = response.data.map(i => { i.isChecked = false; return i; });
            });
        },
    },
    computed: {
        hasChecked() {
            return this.users
                .filter(i => i.isChecked)
                .length > 0;
        },
        checkedUserIds() {
            return this.users
                .filter(i => i.isChecked)
                .map(i => i.id);
        }
    }
};
</script>