<template>
    <v-ons-page class="product-view" >
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div class="center">{{ product.name }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="save" v-show="!loading" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-header>Наименование</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="product.name" ></v-ons-input>
                    <div v-show="product.name !== product.original_name" style="font-size: 13px; color: #837f7f; padding-top: 2px;" >
                        {{ product.original_name }}
                    </div>
                </v-ons-list-item>

                <v-ons-list-header>Ед. изм</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-select v-model="product.unit_id" >
                        <option v-for="unit in units" :value="unit.id">
                            {{ unit.name }}
                        </option>
                    </v-ons-select>
                    <div v-show="product.unit !== product.unit_original" style="font-size: 13px; color: #837f7f; padding-top: 2px;" >
                        {{ product.unit_original }}
                    </div>
                </v-ons-list-item>

                <v-ons-list-header>Кратность</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="product.mult" ></v-ons-input>
                </v-ons-list-item>

                <v-ons-list-header>Ключ. слова</v-ons-list-header>
                <v-ons-list-item>
                    <textarea class="textarea" style="width: 100%" rows="3" v-model="product.words" ></textarea>
                </v-ons-list-item>

                <v-ons-list-header v-show="product.is_wait" >Информация о поступлении</v-ons-list-header>
                <v-ons-list-item  v-show="product.is_wait" >
                    <p>Это поле переехало на новую страницу <a :href="availabilityBalanceLink" >"Наличие и остаток"</a></p>
                </v-ons-list-item>
            </v-ons-list>

            <div v-if="!loading" style="text-align: center; margin-top: 20px" >
                <img
                    class="product-image"
                    :src="product.images.thumb220.path"
                    :alt="product.name"
                    :title="product.name"
                    :width="product.images.thumb220.width"
                    :height="product.images.thumb220.height"
                />

                <div style="text-align: center; margin-top: 10px;" >
                    <v-ons-button @click="$upload.select('image')" :disabled="$upload.meta('image').status === 'sending'">
                        <span v-show="$upload.meta('image').status !== 'sending'" >Добавить</span>
                        <span v-show="$upload.meta('image').status === 'sending'" >Загрузка...</span>
                    </v-ons-button>

                    <v-ons-button v-if="product.images.thumb220.hasImage" @click="deleteImage" >Удалить</v-ons-button>
                </div>

                <br />
            </div>

            <div v-if="product.characteristics" >
                <v-ons-list v-for="(characteristic, index) in product.characteristics" :key="characteristic.id" >
                    <v-ons-list-header>{{ characteristic.label }}</v-ons-list-header>
                    <v-ons-list-item>
                        <textarea class="textarea" style="width: 100%" rows="3" v-model="product.characteristics[index].value" ></textarea>
                    </v-ons-list-item>
                </v-ons-list>
            </div>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            changed: false,
            loaded: false,
            product: {},
            units: [],
            availabilityBalanceLink: ''
        }
    },
    created() {
        this.availabilityBalanceLink = this.$route.path.replace('update', 'availability-balance');

        this.$http.get('/api/product/get-units').then(response => {
            this.units = response.data;
        });

        this.$upload.new('image', {
            url: '/api/catalog/upload-image?product=' + this.$route.params.prd_id,
            extensions: ['jpeg', 'jpg', 'png'],
            onError() {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка при загрузке изображения.',
                    buttonLabel: 'OK'
                });
            },
            onSuccess() {
                this.loadImage();
                this.$bus.$emit('catalog-update-product', this.product.id);
            }
        });

        if (!this.$user.permissions.canUpdateProduct) {
            this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
            return;
        }

        this.load();
    },
    destroyed() {
        this.$upload.reset('image');
        delete this.$upload.watch.uploaders.image;
        this.$bus.$emit('catalogUpdated');
    },
    methods: {
        load() {
            this.$http.get(
                '/api/catalog/view',
                {params: {id: this.$route.params.prd_id, storageId: this.$user.selectedStorage}}
            ).then(response => {
                this.product = response.data;

                this.$nextTick(() => {
                    this.loading = false;
                    this.loaded = true;
                });
            });
        },
        loadImage() {
            this.$http.get(
                '/api/catalog/view',
                {params: {id: this.$route.params.prd_id, storageId: this.$user.selectedStorage}}
            ).then(response => {
                this.product.images = response.data.images;

                this.$nextTick(() => {
                    this.loading = false;
                    this.loaded = true;
                });
            });
        },
        save() {
            this.loading = true;

            let product = {
                id: this.product.id,
                name: this.product.name,
                unit_id: this.product.unit_id,
                mult: this.product.mult,
                words: this.product.words,
                characteristics: this.product.characteristics,
            };

            this.$http.post('/api/catalog/update', {product: product}).then(() => {
                this.$bus.$emit('catalog-update-product', product.id);
                this.$router.go(-1);
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Не удалось сохранить.',
                });
            });
        },
        deleteImage() {
            this.$http.post('/api/catalog/delete-image', {product: this.product.id}).then(() => {
                this.loadImage();
                this.$bus.$emit('catalog-update-product', product.id);
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка при удалении изображения.',
                    buttonLabel: 'OK'
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
    },
    watch: {
        product: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>