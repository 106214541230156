var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Связь с 1С")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.connections.length > 0,
                  expression: "connections.length > 0"
                }
              ]
            },
            _vm._l(_vm.connections, function(connection) {
              return _c(
                "div",
                {
                  key: connection.storage.id,
                  staticStyle: { "margin-bottom": "15px" }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "8px 16px 0px",
                        "font-size": "17px",
                        "font-weight": "bold"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(connection.storage.name) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-ons-list",
                    [
                      _c("v-ons-list-header", [_vm._v("ID менеджера")]),
                      _vm._v(" "),
                      _c(
                        "v-ons-list-item",
                        [
                          _c("v-ons-input", {
                            attrs: { type: "text" },
                            model: {
                              value: connection.onecId,
                              callback: function($$v) {
                                _vm.$set(connection, "onecId", $$v)
                              },
                              expression: "connection.onecId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-ons-list",
                    [
                      _c("v-ons-list-header", [
                        _vm._v("ID универсального клиента для выгрузки заказов")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-ons-list-item",
                        [
                          _c("v-ons-input", {
                            attrs: { type: "text" },
                            model: {
                              value: connection.defaultOnecUserId,
                              callback: function($$v) {
                                _vm.$set(connection, "defaultOnecUserId", $$v)
                              },
                              expression: "connection.defaultOnecUserId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c(
                "v-ons-list-item",
                {
                  staticClass: "cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/profile/manager-entities")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("v-ons-icon", {
                        staticStyle: { margin: "0 auto" },
                        attrs: { icon: "md-arrow-right", size: "20px" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "center" }, [
                    _vm._v(
                      "\n                    Перейти к списку юр.лиц поставщика\n                    "
                    ),
                    _c("span", { staticClass: "list-item__subtitle" }, [
                      _vm._v("ID устанавливается в каждом юр.лице поставщика")
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                {
                  staticClass: "cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/manager/users")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("v-ons-icon", {
                        staticStyle: { margin: "0 auto" },
                        attrs: { icon: "md-arrow-right", size: "20px" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "center" }, [
                    _vm._v(
                      "\n                    Перейти к списку клиентов\n                    "
                    ),
                    _c("span", { staticClass: "list-item__subtitle" }, [
                      _vm._v("ID устанавливается в профиле каждого клиента")
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.connections.length,
                  expression: "!connections.length"
                }
              ]
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "20px",
                    color: "#a8a8a8",
                    position: "absolute",
                    top: "50%",
                    "text-align": "center",
                    width: "100%",
                    "line-height": "50px",
                    "margin-top": "-25px"
                  }
                },
                [_vm._v("\n                Список пуст"), _c("br")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4ca5bf06", { render: render, staticRenderFns: staticRenderFns })
  }
}