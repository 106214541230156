<template>
    <v-ons-card>
        <h1>{{ product.name }}</h1>
        <p v-if="$user.isManager" >Артикул: {{ product.sku || '-' }}</p>
        <p>Ед. изм: {{ product.unit || '-' }}</p>
        <p>Кратность: {{ product.mult || '-' }}</p>

        <p v-if="$user.isGuest && !$user.guestSettings.showPrice" >
            <span style="line-height: 36px" >Цена:</span>
            <v-ons-button @click="$router.push('/register')" modifier="quiet">доступно после регистрации</v-ons-button>
        </p>

        <div v-if="($user.isGuest && $user.guestSettings.showPrice) || (!$user.isGuest && $user.canSeePrice)" >
            <div v-if="!product.is_deleted" >
                <div v-show="product.price" >
                    <div class="wrap wrap-price" >
                        <div class="col xl-1-2 col-price" >
                            <div :class="['product-price', product.specialPrice.isShowMark ? 'is-low-special-price' : '']" >
                                Цена: {{ product.price }} руб./{{ product.unit }}

                                <div v-if="product.specialPrice.isShowPreviousPrice" style="margin-left: 41px" class="special-price-base" >
                                    {{ product.specialPrice.basePrice }} руб./{{ product.unit }}
                                </div>
                            </div>
                        </div>

                        <div class="col xl-1-2 col-label xl-right" >
                            <div>
                                <v-ons-icon icon="md-check-circle"></v-ons-icon>
                                В наличии
                            </div>
                        </div>
                    </div>
                </div>

                <p v-show="!product.price" >Цена: -</p>
            </div>

            <div v-if="product.is_deleted" >
                <div class="wrap-price" >
                    <div :class="['product-price',  product.specialPrice.isShowMark ? 'is-low-special-price' : '']" v-show="product.price" style="margin-bottom: 10px;" >
                        Крайняя цена: {{ product.price }} руб./{{ product.unit }}

                        <div v-if="product.specialPrice.isShowPreviousPrice" style="margin-left: 98px" class="special-price-base" >
                            {{ product.specialPrice.basePrice }} руб./{{ product.unit }}
                        </div>
                    </div>

                    <p v-show="!product.price" >Крайняя цена: -</p>
                </div>

                <p>Товар кончился: {{ product.deletedAt }}</p>
                <p v-if="product.is_wait" >
                    Информация о поступлении: {{ product.info_about_admission ? product.info_about_admission : 'нет данных' }}
                </p>
            </div>
        </div>

        <div style="text-align: center; margin-top: 20px" >
            <img
                v-if="product && product.images && product.images.thumb220"
                class="product-image"
                :src="product.images.thumb220.path"
                :alt="product.name"
                :title="product.name"
                :width="product.images.thumb220.width"
                :height="product.images.thumb220.height"
            />
            <img v-else src="/img/placeholder.png" />

            <div v-if="!$user.isManager && !product.images.thumb220.hasImage" style="margin-top: 20px" >
                <v-ons-button style="width: 165px" v-if="!sendedRequestForPhoto" @click="sendRequestForPhoto" >
                    <span v-if="sendingRequestForPhoto">Отправка...</span>
                    <span v-else >Мне нужно фото</span>
                </v-ons-button>

                <v-ons-button v-else disabled >
                    Запрос отправлен
                </v-ons-button>
            </div>
        </div>

        <div class="characteristics-wrap" v-if="product.characteristics" >
            <div class="characteristic-wrap" v-for="characteristic in product.characteristics" v-show="characteristic.value" :key="characteristic.id" >
                <h2 class="characteristics-label" >{{ characteristic.label }}:</h2>
                <div class="characteristics-value" v-html="characteristic.value" ></div>
            </div>
        </div>
    </v-ons-card>
</template>

<script>
export default {
    props: [
        'product',
    ],
    data() {
        return {
            sendingRequestForPhoto: false,
            sendedRequestForPhoto: false,
        }
    },
    created() {

    },
    methods: {
        sendRequestForPhoto() {
            if (this.sendingRequestForPhoto || this.sendedRequestForPhoto) {
                return;
            }

            this.sendingRequestForPhoto = true;

            this.$http.post(
                '/api/catalog/send-request-for-photo',
                {id: this.product.id}
            ).then(() => {
                this.sendingRequestForPhoto = false;
                this.sendedRequestForPhoto = true;
            });
        },
    },
};
</script>