<template>
    <v-ons-page class="management-pickup-shipment-schedule" >
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">График отгрузки самовывоза</div>

            <div class="right" style="min-width: 100px;" >
                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <table class="table" >
            <tbody>
                <tr v-for="weekday in weekdays" >
                    <td>
                        {{ weekday.label }}
                    </td>

                    <td class="text-center" >
                        с
                        <input
                            type="time"
                            v-model="weekday.from_time"
                            style="text-align: center; margin: 0 auto; max-width: 100px"
                        />
                    </td>

                    <td class="text-center" >
                        до
                        <input
                            type="time"
                            v-model="weekday.to_time"
                            style="text-align: center; margin: 0 auto; max-width: 100px"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            changed: false,
            loaded: false,
            weekdays: [],
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.$http.get(
                '/api/manager/management/pickup-shipment-schedule',
                {params: {storageId: this.$route.params.storage_id}}
            ).then(response => {
                this.weekdays = response.data;

                this.$nextTick(() => {
                    this.loaded = true;
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        save() {
            this.$http.post(
                '/api/manager/management/pickup-shipment-schedule',
                {weekdays: this.weekdays},
                {params: {storageId: this.$route.params.storage_id}}
            ).then(() => {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });
                this.$router.go(-1);
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK',
                });
            });
        },
    },
    watch: {
        weekdays: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        }
    },
};
</script>