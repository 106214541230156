var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Уведомления о заказах")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                on: { click: _vm.send }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-item", { staticClass: "list-header--material" }, [
            _c("div", { staticClass: "center" }, [
              _vm._v(
                "\n                Дублировать уведомления о новых заказах\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "v-ons-button",
                  { on: { click: _vm.addEmail } },
                  [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.emails, function(email, index) {
            return _c("v-ons-list-item", { key: index }, [
              _c("div", { staticClass: "center" }, [
                _vm._v("\n                " + _vm._s(email) + "\n            ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c(
                    "v-ons-button",
                    {
                      attrs: { modifier: "outline" },
                      on: {
                        click: function($event) {
                          return _vm.remove(email)
                        }
                      }
                    },
                    [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                    1
                  )
                ],
                1
              )
            ])
          }),
          _vm._v(" "),
          _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("v-ons-checkbox", {
                  attrs: { "input-id": "isConfirmEmailSendingEnabledLabel" },
                  model: {
                    value: _vm.isConfirmEmailSendingEnabled,
                    callback: function($$v) {
                      _vm.isConfirmEmailSendingEnabled = $$v
                    },
                    expression: "isConfirmEmailSendingEnabled"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "center label-reset",
                attrs: { for: "isConfirmEmailSendingEnabledLabel" }
              },
              [_vm._v("Автоматически отправлять подтверждение заказа")]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5a38fc56", { render: render, staticRenderFns: staticRenderFns })
  }
}