var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Уведомления о спец. ценах")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                on: { click: _vm.save }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-ons-card", [
        _vm._v(
          "\n        Уведомления отправляются менеджеру, если товары из спецпредложения закончились или цены меньше минимально возможной\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("v-ons-checkbox", {
                  attrs: { "input-id": "settingsNotifyEveryImports" },
                  model: {
                    value: _vm.settings.notifyEveryImports,
                    callback: function($$v) {
                      _vm.$set(_vm.settings, "notifyEveryImports", $$v)
                    },
                    expression: "settings.notifyEveryImports"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "center label-reset",
                attrs: { for: "settingsNotifyEveryImports" }
              },
              [
                _vm._v(
                  "Уведомлять о новых ошибках, при каждом обновлении прайса"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("v-ons-checkbox", {
                  attrs: { "input-id": "settingsNotifySchedule" },
                  model: {
                    value: _vm.settings.notifySchedule,
                    callback: function($$v) {
                      _vm.$set(_vm.settings, "notifySchedule", $$v)
                    },
                    expression: "settings.notifySchedule"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "center label-reset",
                attrs: { for: "settingsNotifySchedule" }
              },
              [_vm._v("Присылать отчёт с ошибками спец. цен по графику")]
            )
          ]),
          _vm._v(" "),
          _c(
            "table",
            {
              staticClass: "table",
              staticStyle: { "margin-left": "72px", "max-width": "230px" }
            },
            [
              _c(
                "tbody",
                _vm._l(_vm.settings.weekdays, function(weekday) {
                  return _c("tr", [
                    _c("td", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(weekday.name) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: weekday.value,
                            expression: "weekday.value"
                          }
                        ],
                        staticStyle: {
                          "text-align": "center",
                          margin: "0 auto",
                          "max-width": "100px"
                        },
                        attrs: { type: "time" },
                        domProps: { value: weekday.value },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(weekday, "value", $event.target.value)
                          }
                        }
                      })
                    ])
                  ])
                }),
                0
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f8089b72", { render: render, staticRenderFns: staticRenderFns })
  }
}