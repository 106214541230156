<script>

import DefaultPage from './homePages/DefaultPage'
import ManagerPage from './homePages/ManagerPage'
import CustomerPage from './homePages/CustomerPage'
import WorkerPage from './homePages/WorkerPage'

export default {
    render(createElement) {
        let params = {
            on: {
                'toggle-sidebar': () => this.$emit('toggle-sidebar'),
            },
        };

        if (this.$user.isManager) {
            return createElement(ManagerPage, params);
        }

        if (this.$user.isCustomer) {
            return createElement(CustomerPage, params);
        }

        if (this.$user.isWorker) {
            return createElement(WorkerPage, params);
        }

        return createElement(DefaultPage, params);
    },
};
</script>