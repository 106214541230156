var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Вход в приложение")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "login-page" }, [
        _c(
          "form",
          {
            staticClass: "form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("v-ons-input", {
                  attrs: {
                    autocomplete: "off",
                    name: "email",
                    type: "text",
                    placeholder: "Email"
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("v-ons-password", {
                  attrs: { name: "password", placeholder: "Пароль" },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-submit" },
              [
                _c("v-ons-button", { on: { click: _vm.submit } }, [
                  _vm._v("Войти")
                ]),
                _vm._v(" "),
                _c("button", {
                  staticStyle: {
                    background: "none",
                    width: "0",
                    height: "0",
                    padding: "0",
                    border: "0"
                  },
                  attrs: { type: "submit" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "40px", "text-align": "center" } },
              [
                _c("p", [_vm._v("Первый раз здесь?")]),
                _vm._v(" "),
                _c(
                  "v-ons-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push({
                          name: "register",
                          params: { email: _vm.email, password: _vm.password }
                        })
                      }
                    }
                  },
                  [_vm._v("Создать аккаунт")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "40px", "text-align": "center" } },
              [
                _c(
                  "v-ons-button",
                  {
                    attrs: { modifier: "outline" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push({
                          name: "forgot",
                          params: { email: _vm.email }
                        })
                      }
                    }
                  },
                  [_vm._v("Восстановить пароль")]
                )
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        {
          attrs: { visible: _vm.loading },
          on: {
            "update:visible": function($event) {
              _vm.loading = $event
            }
          }
        },
        [
          _c(
            "v-ons-row",
            { attrs: { "vertical-align": "center" } },
            [
              _c(
                "v-ons-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { width: "70px" }
                },
                [
                  _c("v-ons-progress-circular", {
                    attrs: { indeterminate: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-col", [
                _c("div", { staticStyle: { "line-height": "100px" } }, [
                  _vm._v("Авторизация...")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-86a05d82", { render: render, staticRenderFns: staticRenderFns })
  }
}