<template>
    <div>
        <v-ons-dialog :visible.sync="visible" cancelable>
            <v-ons-toolbar inline="true">
                <div class="center">Фильтр по группам</div>
                <div class="right">
                    <v-ons-toolbar-button @click="close">
                        <v-ons-icon icon="md-close"></v-ons-icon>
                    </v-ons-toolbar-button>
                </div>
            </v-ons-toolbar>

            <div class="background">
                <div v-show="loading" class="page-loading" style="top: 56px; bottom: 0;" >
                    <v-ons-progress-circular indeterminate></v-ons-progress-circular>
                </div>
            </div>

            <div class="content">
                <v-ons-list v-show="groups.length > 0"
                            :style="{maxHeight: ($window.height - 110) + 'px', overflowY: 'auto'}">
                    <v-ons-list-item v-for="item in groups" :key="item.id">
                        <div class="left" style="margin-right: 10px; min-width: 0;">
                            <v-ons-checkbox v-model="choosed" :input-id="'chk-group-' + item.id" :value="item.id"/>
                        </div>

                        <label :for="'chk-group-' + item.id" class="label-reset center">
                            {{ item.name }}
                        </label>
                    </v-ons-list-item>
                </v-ons-list>

                <div v-show="groups.length === 0">
                    <p style="line-height: 216px; text-align: center; font-size: 20px; color: #a8a8a8;">Список пуст</p>
                </div>

                <v-ons-row class="text-center">
                    <v-ons-col width="50%">
                        <v-ons-button
                            modifier="quiet"
                            style=" margin: 5px 0;"
                            @click="reset"
                        >Сбросить
                        </v-ons-button>
                    </v-ons-col>

                    <v-ons-col width="50%">
                        <v-ons-button
                            style=" margin: 5px 0;"
                            @click="choose"
                        >Выбрать
                        </v-ons-button>
                    </v-ons-col>
                </v-ons-row>
            </div>
        </v-ons-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            visible: false,
            groups: [],
            choosed: [],

            resolve: null,
            reject: null,
        }
    },
    methods: {
        choose() {
            this.visible = false;
            this.resolve(this.choosed);
        },
        close() {
            this.visible = false;
        },
        reset() {
            this.choosed = [];
            this.visible = false;
            this.resolve(this.choosed);
        },
        show(choosed) {
            if (choosed) {
                this.choosed = choosed;
            }

            this.load();

            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        load() {
            this.loading = true;

            return this.$http.get(
                '/api/catalog/categories',
                {params: {storageId: this.$user.selectedStorage}},
            ).then(response => {
                this.groups = response.data;
                this.loading = false;
            }, () => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
};
</script>