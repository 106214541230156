var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-alert-dialog",
    {
      attrs: { modifier: "rowfooter", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("v-ons-alert-dialog-button", { on: { click: _vm.onOk } }, [
            _vm._v("Да")
          ]),
          _vm._v(" "),
          _c("v-ons-alert-dialog-button", { on: { click: _vm.onNo } }, [
            _vm._v("Нет")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2f923402", { render: render, staticRenderFns: staticRenderFns })
  }
}