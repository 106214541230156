var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-ons-list-item", { staticClass: "list-item-product" }, [
    _vm.actionColType === "checkbox"
      ? _c("label", { staticClass: "col-checkbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isChecked,
                expression: "isChecked"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.isChecked)
                ? _vm._i(_vm.isChecked, null) > -1
                : _vm.isChecked
            },
            on: {
              change: function($event) {
                var $$a = _vm.isChecked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.isChecked = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.isChecked = $$c
                }
              }
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: ["col-name", _vm.canOpenProductPage ? "cursor-pointer" : ""],
        on: {
          click: function($event) {
            return _vm.clickGoToView(_vm.item)
          }
        }
      },
      [
        _c("div", { staticClass: "name" }, [
          _vm._v("\n            " + _vm._s(_vm.item.name) + "\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product-icons" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.item.is_special,
                  expression: "item.is_special"
                }
              ],
              staticClass: "vip-icon icon"
            },
            [_vm._v("VIP")]
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.item.image,
                expression: "item.image"
              }
            ],
            staticClass: "has-image-icon icon",
            attrs: { title: "У этого товара есть фото" }
          })
        ])
      ]
    ),
    _vm._v(" "),
    _vm.isDesktop && _vm.$user.isManager
      ? _c("div", { staticClass: "col-rest" }, [
          _vm.item.is_wait
            ? _c("span", [_vm._v("0 " + _vm._s(_vm.item.unit))])
            : _c("span", [
                _vm._v(_vm._s(_vm.item.rest) + " " + _vm._s(_vm.item.unit))
              ]),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.showInputs &&
                    parseFloat(_vm.counts[_vm.item.id]) >
                      parseFloat(_vm.item.rest),
                  expression:
                    "showInputs && parseFloat(counts[item.id]) > parseFloat(item.rest)"
                }
              ],
              staticClass: "notify-icon",
              on: {
                click: function($event) {
                  return _vm.showOverweightRestAlert(_vm.item)
                }
              }
            },
            [_vm._v("!")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$user.canSeePrice,
            expression: "$user.canSeePrice"
          }
        ],
        class: [
          "col-price",
          _vm.item.specialPrice.isShowMark ? "is-low-special-price" : ""
        ]
      },
      [
        _c("div", { staticClass: "price-block text-right" }, [
          _vm.item.is_wait
            ? _c("span", [_vm._v("ждём")])
            : _c("span", [_vm._v(_vm._s(_vm.calcPrice(_vm.item)) + " р.")])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDesktop,
                expression: "isDesktop"
              }
            ],
            staticClass: "text-right"
          },
          [
            _vm._v("\n            " + _vm._s(_vm.item.unit) + "\n            "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.showInputs &&
                      parseFloat(_vm.counts[_vm.item.id]) >
                        parseFloat(_vm.item.rest),
                    expression:
                      "showInputs && parseFloat(counts[item.id]) > parseFloat(item.rest)"
                  }
                ],
                staticClass: "notify-icon",
                on: {
                  click: function($event) {
                    return _vm.showOverweightRestAlert(_vm.item)
                  }
                }
              },
              [_vm._v("!")]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isDesktop,
                expression: "!isDesktop"
              }
            ]
          },
          [
            _c("div", { staticClass: "wrap" }, [
              _c("div", { staticClass: "col xl-1-2 text-left" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.item.is_wait || _vm.showInputs,
                        expression: "!item.is_wait || showInputs"
                      }
                    ]
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.item.unit) +
                        "\n                    "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col xl-1-2 text-right" }, [
                _c("div", [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.showInputs &&
                            parseFloat(_vm.counts[_vm.item.id]) >
                              parseFloat(_vm.item.rest),
                          expression:
                            "showInputs && parseFloat(counts[item.id]) > parseFloat(item.rest)"
                        }
                      ],
                      staticClass: "notify-icon",
                      on: {
                        click: function($event) {
                          return _vm.showOverweightRestAlert(_vm.item)
                        }
                      }
                    },
                    [_vm._v("!")]
                  )
                ])
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    (_vm.showInputs || _vm.isDesktop) && !_vm.$user.isManager
      ? _c("div", { staticClass: "col-count" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.counts[_vm.item.id],
                expression: "counts[item.id]"
              },
              { name: "round", rawName: "v-round" }
            ],
            attrs: { type: "number", step: _vm.item.mult },
            domProps: { value: _vm.counts[_vm.item.id] },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.counts, _vm.item.id, $event.target.value)
              }
            }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ab1663ae", { render: render, staticRenderFns: staticRenderFns })
  }
}