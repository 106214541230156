<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">Импорт</div>

            <div class="right" >
                <v-ons-toolbar-button v-show="view === 'repair' && !isSelectAll && doubles.length > 0" @click="selectAll" title="Выбрать всё" >
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="view === 'repair' && isSelectAll && doubles.length > 0" @click="unselectAll" title="Снять выделение" >
                    <v-ons-icon style="color: yellow;" icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" v-show="view === 'upload' && $upload.meta('file').status !== 'sending' && !sending" >
                    <toolbar-popover-menu-item text="Повторить импорт" @click="repeatLastImport" />
                    <toolbar-popover-menu-item text="Отмена последнего импорта" @click="cancelLastImport" />
                    <toolbar-popover-menu-item text="Скачать файл импорта" @click="downloadFile" />

                    <toolbar-popover-menu-item
                        v-if="$user.isAdmin"
                        text="Скачать JSON"
                        @click="downloadJsonFile"
                    />
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div v-show="view === 'upload'" style="text-align: center; margin-top: 20px" >
            <div class="page-loading" :style="{height: $window.height - 56 + 'px', flexDirection: 'column'}" >
                <v-ons-button style="width: 180px;" @click="$upload.select('file')" :disabled="$upload.meta('file').status === 'sending' || sending">
                    <span v-if="$upload.meta('file').status === 'sending' || sending" >Загрузка...</span>
                    <span v-else >Загрузить файл</span>
                </v-ons-button>

                <div style="margin-top: 15px; font-size: 13px">Последнее обновление: {{ lastImportDate | moment("L в HH:mm") }}</div>
            </div>
        </div>

        <div v-show="view === 'repair'" >
            <div v-for="(doublesByManager, index) in doublesByManagers" :key="index" >
                <v-ons-list style="margin-bottom: 50px" >
                    <v-ons-list-header v-if="doublesByManager.name !== 'default'" >{{ doublesByManager.name }}</v-ons-list-header>

                    <div v-for="(item, indexd) in doublesByManager.products" :key="indexd" >
                        <v-ons-list-item modifier="longdivider">
                            <label :for="'chk-' + index" class="label-reset center" >
                                {{ item.name }}
                            </label>

                            <label :for="'chk-' + index" class="label-reset right" >
                                <small style="margin-right: 5px" >Объединить</small>
                                <v-ons-checkbox :input-id="'chk-' + index" v-model="choosedDoubles" :value="item.hash_name" />
                            </label>
                        </v-ons-list-item>

                        <v-ons-list-item modifier="longdivider">
                            <span class="list-item__title">Прайс: {{ item.first_name }}</span>
                            <span class="list-item__subtitle">Цена: {{ item.price }}</span>
                        </v-ons-list-item>

                        <v-ons-list-item modifier="longdivider">
                            <span class="list-item__title">Каталог: {{ item.double.first_name }}</span>
                            <span class="list-item__subtitle">Цена: {{ item.double.price }}</span>
                        </v-ons-list-item>
                    </div>
                </v-ons-list>
            </div>

            <div style="height: 45px;" ></div>

            <v-ons-button
                @click="runImport"
                style="position: fixed; bottom: 5px; left: 50%; margin-left: -110px; width: 220px;"
                >Импорт</v-ons-button>
        </div>

        <div v-show="view === 'importing'" >
            <div class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div v-show="view === 'report'" >
            <div class="page-loading" :style="{flexDirection: 'column', height: $window.height - 56 + 'px'}" >
                <div style="font-size: 20px; margin-bottom: 20px;">
                    Импорт успешно завершён
                </div>

                <v-ons-button style="width: 180px;" @click="reset" >OK</v-ons-button>
            </div>
        </div>

        <v-list-uploaded-files-dialog ref="listUploadedFilesDialog"/>
    </v-ons-page>
</template>

<script>

import ListUploadedFilesDialog from './ListUploadedFilesDialog'

export default {
    data() {
        return {
            fileName: null,
            view: 'upload',
            doublesByManagers: [],
            doubles: [],
            choosedDoubles: [],
            sending: false,
            lastImportDate: null,
        }
    },
    created() {
        this.$upload.new('file', {
            url: '/api/manager/import/upload',
            extensions: ['xls'],
            maxSizePerFile: 1024 * 1024 * 15,
            onError(response) {
                let message = 'Произошла ошибка при загрузке.';

                if (response.data.message && response.data.message === 'import_is_running') {
                    message = 'Импорт уже запущен';
                }

                this.$ons.notification.toast({
                    message: message,
                    buttonLabel: 'OK'
                });
            },
            onSuccess(response) {
                this.view = 'repair';
                this.fileName = response.data.file;
                this.doublesByManagers = response.data.doubles.itemsByManagers;
                this.doubles = response.data.doubles.items;
                this.choosedDoubles = this.doubles.map(i => i.hash_name);

                if (this.doubles.length === 0) {
                    this.runImport();
                }
            }
        });

        this.$http.get('/api/catalog/last-import-date').then(response => {
            this.lastImportDate = response.data;
        });
    },
    methods: {
        runImport() {
            this.view = 'importing';

            let mergers = this.doubles
                .filter(i => this.choosedDoubles.includes(i.hash_name))
                .map(i => {
                    return {
                        hash_name: i.hash_name,
                        prd_id: i.double.id,
                    };
                });

            this.$http.post(
                '/api/manager/import/run',
                {fileName: this.fileName, mergers: mergers}
            ).then(() => {
                this.view = 'report';

                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Импорт завершен.',
                    buttonLabel: 'OK'
                });
            }, response => {
                this.view = 'upload';
                let message = 'Произошла ошибка';

                if (response.data.message && response.data.message === 'import_is_running') {
                    message = 'Импорт уже запущен';
                }

                this.$ons.notification.toast({
                    message: message,
                    buttonLabel: 'OK'
                });
            });
        },
        reset() {
            this.view = 'upload';

            this.$http.get('/api/catalog/last-import-date').then(response => {
                this.lastImportDate = response.data;
            });
        },
        repeatLastImport() {
            this.sending = true;
            this.view = 'upload';

            this.$http.post('/api/manager/import/upload-last-import').then(response => {
                this.sending = false;
                this.view = 'repair';
                this.fileName = response.data.file;
                this.doublesByManagers = response.data.doubles.itemsByManagers;
                this.doubles = response.data.doubles.items;
                this.choosedDoubles = this.doubles.map(i => i.hash_name);

                if (this.doubles.length === 0) {
                    this.runImport();
                }
            }, () => {
                this.sending = false;

                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        cancelLastImport() {
            this.sending = true;
            this.view = 'upload';

            this.$http.post(
                '/api/manager/import/upload-second-last-import'
            ).then(response => {
                this.sending = false;
                this.view = 'repair';
                this.fileName = response.data.file;
                this.doublesByManagers = response.data.doubles.itemsByManagers;
                this.doubles = response.data.doubles.items;
                this.choosedDoubles = this.doubles.map(i => i.hash_name);

                if (this.doubles.length === 0) {
                    this.runImport();
                }
            }, () => {
                this.sending = false;

                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        downloadFile() {
            this.$refs.listUploadedFilesDialog.show();
        },
        selectAll() {
            this.choosedDoubles = this.doubles.map(i => i.hash_name);
        },
        unselectAll() {
            this.choosedDoubles = [];
        },
        downloadJsonFile() {
            this.view = 'importing';

            this.$http.get('/api/catalog/last-json-file', {responseType: 'arraybuffer'}).then(response => {
                let contentDisposition = response.headers.get('Content-Disposition') || '';
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(contentDisposition);
                let filename;

                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                } else {
                    filename = 'goods.json';
                }

                let blob = new Blob([response.data], { type: response.headers.get('content-type') });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                this.view = 'upload';
            }, () => {
                this.view = 'upload';
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
    computed: {
        isSelectAll() {
            return this.doubles.length > 0 && this.choosedDoubles.length === this.doubles.length;
        },
    },
    components: {
        'v-list-uploaded-files-dialog': ListUploadedFilesDialog,
    },
};
</script>