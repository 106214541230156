import User from '~/screen/manager/productLink/user/User';
import Users from '~/screen/manager/productLink/user/Index';
import Product from '~/screen/manager/productLink/user/Product';
import Products from '~/screen/manager/productLink/product/Index';
import ProductsUsers from '~/screen/manager/productLink/product/Users';
import ProductsProduct from '~/screen/manager/productLink/product/Product';
import ManagerUserProducts from '~/routes/Manager/Users/Products/index';
import ProductRoute from '~/routes/ProductRoute';
import TemplateView from '~/screen/userProduct/TemplateView';
import UserProducts from '~/screen/userProduct/List';
import ProductsHistory from '~/screen/userProduct/History';

let route = [
    {
        path: 'product-link',
        name: 'manager-product-link',
        component: Users,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'products',
                name: 'manager-product-link-products',
                component: Products,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'storage-:storage_id',
                        name: 'manager-product-link-products-storage',
                        component: Products,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                        children: [
                            {
                                path: ':type-:product_id',
                                name: 'manager-product-link-products-users',
                                component: ProductsUsers,
                                meta: {
                                    requiresLogin: true,
                                    requiredPermissions: ['manager'],
                                },
                                children: [
                                    {
                                        path: 'products-:user_id',
                                        name: 'manager-product-link-products-user-manager-user-products',
                                        component: UserProducts,
                                        meta: {
                                            requiresLogin: true,
                                            requiredPermissions: ['manager'],
                                        },
                                        children: [
                                            ProductRoute('manager-product-link-products-user-manager-user'),
                                            {
                                                path: 'template/:id',
                                                name: 'manager-product-link-products-user-manager-template-view',
                                                component: TemplateView,
                                                params: true,
                                                meta: {
                                                    requiresLogin: true,
                                                    requiredPermissions: ['manager'],
                                                },
                                            },
                                            {
                                                path: 'history',
                                                name: 'manager-product-link-products-user-manager-product-list-history',
                                                component: ProductsHistory,
                                                meta: {
                                                    requiresLogin: true,
                                                    requiredPermissions: ['manager'],
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        path: 'replace',
                                        name: 'manager-product-link-products-product',
                                        component: ProductsProduct,
                                        meta: {
                                            requiresLogin: true,
                                            requiredPermissions: ['manager'],
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'users',
                name: 'manager-product-link-users',
                component: Users,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'storage-:storage_id',
                        name: 'manager-product-link-users-storage',
                        component: Users,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                        children: [
                            {
                                path: 'user-:user_id',
                                name: 'manager-product-link-users-user',
                                component: User,
                                meta: {
                                    requiresLogin: true,
                                    requiredPermissions: ['manager'],
                                },
                                children: [
                                    ManagerUserProducts('manager-product-link-users-user'),
                                    {
                                        path: 'product-:product_id',
                                        name: 'manager-product-link-users-product',
                                        component: Product,
                                        meta: {
                                            requiresLogin: true,
                                            requiredPermissions: ['manager'],
                                        },
                                    },
                                ],
                            },
                        ]
                    },
                ],
            },
        ],
    },
];

export default route;