var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "order-index" },
    [
      _c("v-ons-toolbar", [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Заказы: " + _vm._s(_vm.user.email))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                attrs: { title: "Опции просмотра" },
                on: { click: _vm.showViewParamsModal }
              },
              [
                _c("v-ons-icon", { attrs: { icon: "md-format-list-bulleted" } })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                attrs: { title: "Обновить" },
                on: {
                  click: function($event) {
                    return _vm.$emit("update")
                  }
                }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-refresh" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "toolbar-popover-menu",
              { attrs: { "popover-class": "stick-right" } },
              [
                _c("toolbar-popover-menu-item", {
                  attrs: {
                    text: "Опции просмотра",
                    icon: "md-format-list-bulleted"
                  },
                  on: { click: _vm.showViewParamsModal }
                }),
                _vm._v(" "),
                _c("ons-list-header", [_vm._v("Отображать заказы:")]),
                _vm._v(" "),
                _c(
                  "ons-list-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.setFilterStatus("all")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left icon" },
                      [
                        _c("v-ons-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.filters.status === "all",
                              expression: "filters.status === 'all'"
                            }
                          ],
                          attrs: { icon: "md-check" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "center" }, [_vm._v("Все")])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ons-list-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.setFilterStatus("sended")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left icon" },
                      [
                        _c("v-ons-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.filters.status === "sended",
                              expression: "filters.status === 'sended'"
                            }
                          ],
                          attrs: { icon: "md-check" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "center" }, [_vm._v("Новые")])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ons-list-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.setFilterStatus("manager_draft")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left icon" },
                      [
                        _c("v-ons-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.filters.status === "manager_draft",
                              expression: "filters.status === 'manager_draft'"
                            }
                          ],
                          attrs: { icon: "md-check" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "center" }, [
                      _vm._v("Черновики менеджера")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ons-list-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.setFilterStatus("draft")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left icon" },
                      [
                        _c("v-ons-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.filters.status === "draft",
                              expression: "filters.status === 'draft'"
                            }
                          ],
                          attrs: { icon: "md-check" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "center" }, [
                      _vm._v("Черновики клиента")
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.user.discounts && _vm.user.discounts.orderAmountMoreMonth
            ? _c(
                "div",
                [
                  _c(
                    "v-ons-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.user.discounts.orderAmountMoreMonth.enabled,
                          expression:
                            "user.discounts.orderAmountMoreMonth.enabled"
                        }
                      ]
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.user.discounts.orderAmountMoreMonth.has,
                              expression:
                                "user.discounts.orderAmountMoreMonth.has"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                    Дополнительная скидка составляет " +
                              _vm._s(
                                _vm.user.discounts.orderAmountMoreMonth.percent
                              ) +
                              "%\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.user.discounts.orderAmountMoreMonth
                                .has,
                              expression:
                                "!user.discounts.orderAmountMoreMonth.has"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                    Сумма заказов за 30 дней - " +
                              _vm._s(
                                _vm.user.discounts.orderAmountMoreMonth
                                  .monthAmount
                              ) +
                              " р. Для получения скидки " +
                              _vm._s(
                                _vm.user.discounts.orderAmountMoreMonth.percent
                              ) +
                              "%, клиенту нужно выбрать товара всего на " +
                              _vm._s(
                                _vm.user.discounts.orderAmountMoreMonth.restSum
                              ) +
                              " р.\n                "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-ons-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.user.discounts.orderAmountMore.enabled,
                          expression: "user.discounts.orderAmountMore.enabled"
                        }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                Клиент может получить скидку " +
                          _vm._s(_vm.user.discounts.orderAmountMore.percent) +
                          "% сразу на текущий заказ от суммы " +
                          _vm._s(_vm.user.discounts.orderAmountMore.amount) +
                          " р.\n            "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.orders,
                  expression: "orders"
                }
              ]
            },
            _vm._l(_vm.orders, function(order) {
              return _c(
                "ons-list-item",
                {
                  key: order.id,
                  staticClass: "order-item",
                  attrs: { modifier: "chevron longdivider" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "left",
                      staticStyle: {
                        "min-width": "56px",
                        "padding-left": "16px",
                        "text-align": "center"
                      }
                    },
                    [
                      _c("v-ons-icon", {
                        staticClass: "list-item__icon",
                        attrs: {
                          icon: _vm.orderStatus(order).icon,
                          size: _vm.orderStatus(order).size
                            ? _vm.orderStatus(order).size
                            : "",
                          title: _vm.orderStatus(order).title
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "center cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("orders/" + order.id)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "list-item__title" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.orderTitle(order)) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "list-item__subtitle" }, [
                        _vm._v(
                          "\n                        Статус: " +
                            _vm._s(order.status) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-subtitles", {
                        attrs: {
                          item: order,
                          fields: _vm.$user.getOrderSubtitles(),
                          selected: _vm.$user.orderSelectedSubtitles
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.page < _vm.pageCount,
                  expression: "page < pageCount"
                }
              ],
              staticStyle: { "text-align": "center", margin: "10px 0" }
            },
            [
              _c(
                "v-ons-button",
                {
                  staticStyle: { "min-width": "145px" },
                  on: { click: _vm.loadMore }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.loadingMore,
                          expression: "!loadingMore"
                        }
                      ]
                    },
                    [_vm._v("Показать ещё")]
                  ),
                  _vm._v(" "),
                  _c("v-ons-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loadingMore,
                        expression: "loadingMore"
                      }
                    ],
                    attrs: { size: "30px", spin: "", icon: "md-spinner" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.orders.length === 0,
                  expression: "orders.length === 0"
                }
              ],
              staticClass: "list-empty",
              style: { height: _vm.$window.height - 56 + "px" }
            },
            [_vm._v("\n            Еще нет ни одного заказа\n        ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "viewParamsModal",
        attrs: { multiple: "true", title: "Опции просмотра" }
      }),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.user.manager_id == _vm.$user.id,
              expression: "user.manager_id == $user.id"
            }
          ],
          attrs: { position: "bottom right", title: "Создать заказ" },
          on: { click: _vm.createOrder }
        },
        [
          _c("v-ons-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.creatingOrder,
                expression: "!creatingOrder"
              }
            ],
            attrs: { icon: "md-plus" }
          }),
          _vm._v(" "),
          _c("v-ons-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.creatingOrder,
                expression: "creatingOrder "
              }
            ],
            attrs: { size: "30px", spin: "", icon: "md-spinner" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-217f72be", { render: render, staticRenderFns: staticRenderFns })
  }
}