var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { cancelable: "", visible: _vm.dialogVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "alert-dialog-container alert-dialog-container--material"
        },
        [
          _c(
            "div",
            {
              staticClass: "alert-dialog-content alert-dialog-content--material"
            },
            [_vm._v("\n            " + _vm._s(_vm.message) + "\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "0 20px 4px" } },
            _vm._l(_vm.buttons, function(button, key) {
              return _c(
                "v-ons-button",
                {
                  key: key,
                  staticStyle: { width: "100%", margin: "10px 0" },
                  on: {
                    click: function($event) {
                      return _vm.onClick(key)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(button) + "\n            "
                  )
                ]
              )
            }),
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-236c2bf0", { render: render, staticRenderFns: staticRenderFns })
  }
}