var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Правила работы: " + _vm._s(_vm.worker.email))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loaded,
                    expression: "loaded"
                  }
                ],
                on: { click: _vm.save }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Привязка к филиалу")]),
          _vm._v(" "),
          _vm._l(_vm.branches, function(branch) {
            return _c(
              "v-ons-list-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.branches.length > 0,
                    expression: "branches.length > 0"
                  }
                ],
                key: branch.id,
                attrs: { modifier: "longdivider" }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "branch-" + branch.id }
                  },
                  [
                    _c("span", { staticClass: "list-item__title" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(branch.alias) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "list-item__subtitle" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.branchSubtitle(branch)) +
                          "\n                "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      attrs: {
                        "input-id": "branch-" + branch.id,
                        value: branch.id
                      },
                      model: {
                        value: _vm.permissions.availableBranches,
                        callback: function($$v) {
                          _vm.$set(_vm.permissions, "availableBranches", $$v)
                        },
                        expression: "permissions.availableBranches"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.branches.length == 0,
                  expression: "branches.length == 0"
                }
              ],
              attrs: { modifier: "longdivider" }
            },
            [_vm._v("\n            Нет ни одного филиала\n        ")]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Способ отгрузки по умолчанию:")]),
          _vm._v(" "),
          _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
            _c(
              "div",
              { staticClass: "select-material select--material select" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.defaultShippingMethod,
                        expression: "defaultShippingMethod"
                      }
                    ],
                    staticClass: "select-input select-input--material",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.defaultShippingMethod = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: "" } }, [_vm._v("-")]),
                    _vm._v(" "),
                    _vm._l(_vm.shippingMethods, function(method) {
                      return _c("option", { domProps: { value: method.id } }, [
                        _vm._v(_vm._s(method.name))
                      ])
                    })
                  ],
                  2
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-56872388", { render: render, staticRenderFns: staticRenderFns })
  }
}