var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-ons-dialog",
        { attrs: { visible: _vm.dialogVisible } },
        [
          _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
            _c("div", { staticClass: "center" }, [_vm._v("Добавить в заказ")])
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list",
            { staticStyle: { height: "216px", "overflow-y": "auto" } },
            [
              _c(
                "v-ons-list-item",
                {
                  staticStyle: { cursor: "pointer", "text-align": "center" },
                  on: {
                    click: function($event) {
                      return _vm.choose("new")
                    }
                  }
                },
                [
                  _c("v-ons-button", { staticStyle: { width: "100%" } }, [
                    _vm._v(
                      "\n                    Создать новый заказ\n                "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.unsendedOrders && _vm.unsendedOrders.length > 0,
                      expression: "unsendedOrders && unsendedOrders.length > 0"
                    }
                  ]
                },
                [
                  _c("v-ons-list-header", [_vm._v("Добавить в черновик")]),
                  _vm._v(" "),
                  _vm._l(_vm.unsendedOrders, function(order) {
                    return _c(
                      "v-ons-list-item",
                      { key: order.id, staticStyle: { cursor: "pointer" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "center",
                            on: {
                              click: function($event) {
                                return _vm.choose(order)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                        №" +
                                _vm._s(order.id) +
                                " от " +
                                _vm._s(_vm._f("moment")(order.createdAt, "L")) +
                                " на сумму " +
                                _vm._s(order.totalCost) +
                                " руб.\n                    "
                            )
                          ]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-button",
            {
              staticStyle: { width: "100%", margin: "5px 0" },
              attrs: { modifier: "quiet" },
              on: { click: _vm.close }
            },
            [_vm._v("Отмена")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-37117e92", { render: render, staticRenderFns: staticRenderFns })
  }
}