<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div v-if="id" class="center">График работы</div>
            <div v-else class="center">Добавить график</div>

            <div class="right" v-show="!loading" style="min-width: 100px;" >
                <v-ons-toolbar-button v-if="id" @click="copy" >
                    <v-ons-icon icon="md-copy"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-if="id" @click="remove" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-header style="padding-bottom: 0" >Неделя:</v-ons-list-header>

                <v-ons-list-item>
                    <div class="center" style="padding-top: 0" >
                        <div class="select-material select--material select" >
                            <select class="select-input select-input--material" v-model="week" >
                                <option v-show="weekNotInRanger" :value="specialSchedule.from_date + ':' + specialSchedule.to_date" disabled >
                                    {{ specialSchedule.name }}
                                </option>
                                <option v-for="week in weeks" :value="week.value" :key="week.name" >
                                    {{ week.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <div>
                <div v-for="route in routes" >
                    <table class="table" >
                        <thead>
                            <tr>
                                <th>{{ route.name }}</th>
                                <th class="text-center" >
                                    <span v-if="route.id == 1" >Доступен</span>
                                    <span v-else >Доставка</span>
                                </th>
                                <th>День заказа</th>
                                <th class="text-center" >До</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="weekday in route.schedule" >
                                <td>
                                    {{ weekday.name }}
                                </td>

                                <td class="text-center" @click="weekday.hasDelivery = !weekday.hasDelivery" >
                                    <input type="checkbox" v-model="weekday.hasDelivery" />
                                </td>

                                <td class="text-center" >
                                    <v-ons-select v-model="weekday.beforeDay" >
                                        <option v-for="w in weekdays" :value="w.id" >
                                            {{ w.name }}
                                        </option>
                                    </v-ons-select>
                                </td>

                                <td class="text-center" >
                                    <input
                                        type="time"
                                        v-model="weekday.beforeTime"
                                        style="text-align: center; margin: 0 auto; max-width: 100px"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            id: null,
            loading: true,
            specialSchedule: {},
            changed: false,
            loaded: false,
            weekdays: [],
            routes: [],
            weeks: [],
            week: null,
            copied: [],
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;

            this.$http.get('/api/manager/management/special-schedule-week-list').then(response => {
                this.weeks = response.data;
            });

            if (this.copied.length > 0) {
                this.routes = this.copied;
                this.loading = false;
                this.copied = [];
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Расписание скопировано',
                    buttonLabel: 'OK',
                });
                return;
            }

            if (!this.$route.params.id) {
                this.$http.get(
                    '/api/manager/management/shipping-by-routes',
                    {params: {storageId: this.$route.params.storage_id}}
                ).then(response => {
                    this.routes = response.data.routes;
                    this.weekdays = response.data.weekdays;

                    this.$nextTick(() => {
                        this.loaded = true;
                        this.loading = false;
                    });
                });

                return;
            }

            this.id = this.$route.params.id;

            Promise.all([
                this.$http.get(
                    '/api/manager/management/shipping-by-routes',
                    {params: {storageId: this.$route.params.storage_id}}
                ),
                this.$http.get(
                    '/api/manager/management/special-schedule',
                    {params: {id: this.id, storageId: this.$route.params.storage_id}}
                    ),
            ]).then(responses => {
                this.weekdays = responses[0].data.weekdays;
                this.specialSchedule = responses[1].data;
                this.week = this.specialSchedule.from_date + ":" + this.specialSchedule.to_date;

                let specialRoutes = this.specialSchedule.routes;
                this.routes = responses[0].data.routes.map(route => {
                    let specialRoute = specialRoutes.find(ii => ii.route_id == route.id);

                    if (!specialRoute) {
                        return route;
                    }

                    route.schedule = specialRoute.schedule;

                    return route;
                });

                this.$nextTick(() => {
                    this.loaded = true;
                    this.loading = false;
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        save() {
            if (!this.week) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Укажите период',
                    buttonLabel: 'OK',
                });
                return;
            }

            let request = null;
            if (this.id) {
                request = this.$http.post(
                    '/api/manager/management/special-schedule/' + this.id,
                    {week: this.week, routes: this.routes},
                    {params: {storageId: this.$route.params.storage_id}}
                );
            } else {
                request = this.$http.post(
                    '/api/manager/management/special-schedules',
                    {week: this.week, routes: this.routes},
                    {params: {storageId: this.$route.params.storage_id}}
                );
            }

            request.then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Настройки сохранены',
                    buttonLabel: 'OK',
                });
                this.$bus.$emit('load');
                this.$router.go(-1);
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                });
            });
        },
        remove() {
            this.$ons.notification.confirm('Удалить график?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    return;
                }

                this.$http.delete(
                    '/api/manager/management/special-schedule/' + this.id
                ).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'График удален',
                        buttonLabel: 'OK',
                    });
                    this.$bus.$emit('load');
                    this.$router.go(-1);
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });

        },
        copy() {
            this.$router.replace({name: 'manager-management-special-schedules-create', params: {routes: this.routes}});
        },
    },
    computed: {
        weekNotInRanger() {
            if (!this.weeks.length) {
                return;
            }

            return !this.weeks.find(w => w.name === this.specialSchedule.name);
        },
    },
    watch: {
        routes: {
            handler() {
                this.routes.forEach(route => {
                    route.schedule.forEach(day => {
                        if (!day.hasDelivery) {
                            day.beforeDay = null;
                            day.beforeTime = null;
                        }
                    });
                });

                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
        '$route.params.routes'() {
            this.id = null;
            this.week = null;
            this.copied = this.$route.params.routes || [];
            this.load();
        }
    },
};
</script>