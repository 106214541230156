export default function RedirectIfAuthenticated (router) {
    /**
     * If the user is already authenticated he shouldn't be able to visit
     * pages like login, register, etc...
     */
    router.beforeEach((to, from, next) => {
        let token = window.localStorage.getItem('token');

        /**
         * Checks if there's a token and the next page name is none of the following
         */
        if ((token) && (to.name === 'login' || to.name === 'register')) {
            //router.replace('/')
            next('/');
            return;
        }

        if (!token) {
            // Logout
        }

        next()
    })
}
