var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.id
          ? _c("div", { staticClass: "center" }, [
              _vm._v("Юр. лицо: " + _vm._s(_vm.entity.alias))
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.id
          ? _c("div", { staticClass: "center" }, [_vm._v("Создать юр. лицо")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.id
              ? _c(
                  "v-ons-toolbar-button",
                  {
                    attrs: { title: "Удалить филиал" },
                    on: { click: _vm.remove }
                  },
                  [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Сохранить" }, on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Форма юр. лица")]),
          _vm._v(" "),
          _c("v-ons-list-item", [
            _c(
              "div",
              { staticClass: "select-material select--material select" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.entity.type_id,
                        expression: "entity.type_id"
                      }
                    ],
                    staticClass: "select-input select-input--material",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.entity,
                          "type_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.entityForms, function(entityForm) {
                    return _c(
                      "option",
                      { domProps: { value: entityForm.id } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(entityForm.name) +
                            "\n                    "
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c(
            "v-ons-list-header",
            [
              _c("v-ons-checkbox", {
                staticStyle: {
                  "vertical-align": "middle",
                  margin: "0 12px 0 0"
                },
                attrs: {
                  disabled: !_vm.availIsPayerNdc,
                  "input-id": "is-payer-ndc"
                },
                model: {
                  value: _vm.entity.is_payer_ndc,
                  callback: function($$v) {
                    _vm.$set(_vm.entity, "is_payer_ndc", $$v)
                  },
                  expression: "entity.is_payer_ndc"
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "is-payer-ndc" } }, [
                _vm._v("Плательщик НДС")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Юридическое наименование")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "legal_name" },
                model: {
                  value: _vm.entity.legal_name,
                  callback: function($$v) {
                    _vm.$set(_vm.entity, "legal_name", $$v)
                  },
                  expression: "entity.legal_name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Юридический адрес")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "legal_address" },
                model: {
                  value: _vm.entity.legal_address,
                  callback: function($$v) {
                    _vm.$set(_vm.entity, "legal_address", $$v)
                  },
                  expression: "entity.legal_address"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("ОГРН")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "ogrn" },
                model: {
                  value: _vm.entity.ogrn,
                  callback: function($$v) {
                    _vm.$set(_vm.entity, "ogrn", $$v)
                  },
                  expression: "entity.ogrn"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("ИНН")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { type: "number", name: "inn" },
                model: {
                  value: _vm.entity.inn,
                  callback: function($$v) {
                    _vm.$set(_vm.entity, "inn", $$v)
                  },
                  expression: "entity.inn"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shouldShowKppInput,
              expression: "shouldShowKppInput"
            }
          ]
        },
        [
          _c("v-ons-list-header", [_vm._v("КПП")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { type: "number", name: "kpp" },
                model: {
                  value: _vm.entity.kpp,
                  callback: function($$v) {
                    _vm.$set(_vm.entity, "kpp", $$v)
                  },
                  expression: "entity.kpp"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: parseInt(_vm.entity.type_id) === 2,
              expression: "parseInt(entity.type_id) === 2"
            }
          ]
        },
        [
          _c("v-ons-list-header", [_vm._v("Должность подписанта")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "position_signatory" },
                model: {
                  value: _vm.entity.position_signatory,
                  callback: function($$v) {
                    _vm.$set(_vm.entity, "position_signatory", $$v)
                  },
                  expression: "entity.position_signatory"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Почтовый адрес")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "postal_address" },
                model: {
                  value: _vm.entity.postal_address,
                  callback: function($$v) {
                    _vm.$set(_vm.entity, "postal_address", $$v)
                  },
                  expression: "entity.postal_address"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Скан печати для счета")]),
          _vm._v(" "),
          _vm.entity.scan_path_schet
            ? _c("div", { staticStyle: { padding: "0 20px" } }, [
                _c("img", {
                  staticStyle: {
                    "max-width": "90%",
                    "box-shadow": "0 1px 6px #e8e8e8"
                  },
                  attrs: {
                    src: "/images/" + _vm.entity.scan_path_schet,
                    alt: ""
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c(
                "v-ons-button",
                {
                  attrs: {
                    disabled:
                      _vm.$upload.meta("imageSchet").status === "sending"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$upload.select("imageSchet")
                    }
                  }
                },
                [
                  _c("v-ons-icon", {
                    attrs: { icon: "md-upload", size: "20px" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.entity.scan_path_schet,
                      expression: "entity.scan_path_schet"
                    }
                  ],
                  staticStyle: { "margin-left": "10px" },
                  on: {
                    click: function($event) {
                      _vm.entity.scan_path_schet = ""
                    }
                  }
                },
                [
                  _c("v-ons-icon", {
                    attrs: { icon: "md-delete", size: "20px" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.entity.scan_path_schet,
                      expression: "entity.scan_path_schet"
                    }
                  ],
                  staticStyle: { "margin-left": "10px" },
                  on: { click: _vm.downloadScan }
                },
                [
                  _c("v-ons-icon", {
                    attrs: { icon: "md-download", size: "20px" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("v-ons-list-header", [_vm._v("Расчетные счета")]),
          _vm._v(" "),
          _c(
            "v-ons-list",
            _vm._l(_vm.entity.settlementAccounts, function(settlementAccount) {
              return _c("v-ons-list-item", { key: settlementAccount.id }, [
                _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _c(
                      "v-ons-card",
                      { staticStyle: { width: "100%", cursor: "pointer" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "content",
                            on: {
                              click: function($event) {
                                return _vm.updateSettlementAccount(
                                  settlementAccount.id
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: settlementAccount.number,
                                    expression: "settlementAccount.number"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "Расчетный счет: " +
                                    _vm._s(settlementAccount.number) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: settlementAccount.bank,
                                    expression: "settlementAccount.bank"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "Банк: " + _vm._s(settlementAccount.bank)
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: settlementAccount.bik,
                                    expression: "settlementAccount.bik"
                                  }
                                ]
                              },
                              [_vm._v("БИК: " + _vm._s(settlementAccount.bik))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: settlementAccount.corr_account,
                                    expression: "settlementAccount.corr_account"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "Корр.счет:\n                                " +
                                    _vm._s(settlementAccount.corr_account) +
                                    "\n                            "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.entity.settlementAccounts.length > 1,
                                expression:
                                  "entity.settlementAccounts.length > 1"
                              }
                            ],
                            staticClass: "content",
                            staticStyle: {
                              "margin-top": "15px",
                              "margin-bottom": "-10px",
                              "padding-bottom": "10px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setDefaultSettlementAccount(
                                  settlementAccount.id
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                            Основной расчетный счет:\n                            "
                            ),
                            _c("v-ons-checkbox", {
                              staticStyle: { margin: "0 5px" },
                              model: {
                                value: settlementAccount.is_default,
                                callback: function($$v) {
                                  _vm.$set(settlementAccount, "is_default", $$v)
                                },
                                expression: "settlementAccount.is_default"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "v-ons-button",
                      {
                        attrs: { modifier: "quiet" },
                        on: {
                          click: function($event) {
                            return _vm.removeSettlementAccount(
                              settlementAccount.id
                            )
                          }
                        }
                      },
                      [
                        _c("v-ons-icon", {
                          attrs: { icon: "md-delete", size: "22px" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "0 15px" } },
            [
              _c(
                "v-ons-button",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  on: { click: _vm.addSettlementAccount }
                },
                [
                  _vm._v(
                    "\n                Добавить расчетный счет\n            "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Адрес ведения деятельности")]),
          _vm._v(" "),
          _vm._l(_vm.storages, function(storage, key) {
            return _c("v-ons-list-item", { key: storage.id }, [
              _c(
                "label",
                {
                  staticClass: "center label-reset",
                  attrs: { for: "storage-" + key }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(storage.name) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("v-ons-checkbox", {
                    staticStyle: {
                      "vertical-align": "middle",
                      margin: "0 12px 0 0"
                    },
                    attrs: { value: storage.id, "input-id": "storage-" + key },
                    model: {
                      value: _vm.entity.storageIds,
                      callback: function($$v) {
                        _vm.$set(_vm.entity, "storageIds", $$v)
                      },
                      expression: "entity.storageIds"
                    }
                  })
                ],
                1
              )
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("ID юр. лица в 1С")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { name: "onec_code" },
                model: {
                  value: _vm.entity.onec_code,
                  callback: function($$v) {
                    _vm.$set(_vm.entity, "onec_code", $$v)
                  },
                  expression: "entity.onec_code"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("settlement-account-dialog", { ref: "settlementAccountDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4731e960", { render: render, staticRenderFns: staticRenderFns })
  }
}