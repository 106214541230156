var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Оформление жёлтых ценников")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loaded,
                expression: "loaded"
              }
            ],
            staticClass: "right"
          },
          [
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Сохранить" }, on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loaded,
                expression: "!loaded"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _vm._l(_vm.modeOptions, function(modeOption) {
                return _c(
                  "v-ons-list-item",
                  { key: modeOption.id, attrs: { modifier: "longdivider" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "left",
                        staticStyle: {
                          "min-width": "36px",
                          "text-align": "center"
                        }
                      },
                      [
                        _c("v-ons-radio", {
                          attrs: {
                            "input-id": "modeOption-" + modeOption.id,
                            value: modeOption.id
                          },
                          model: {
                            value: _vm.settings.mode,
                            callback: function($$v) {
                              _vm.$set(_vm.settings, "mode", $$v)
                            },
                            expression: "settings.mode"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "center",
                        staticStyle: { "font-weight": "normal", margin: "0" },
                        attrs: { for: "modeOption-" + modeOption.id }
                      },
                      [_vm._v(_vm._s(modeOption.name))]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.settings.mode == "lowPrice",
                      expression: "settings.mode == 'lowPrice'"
                    }
                  ],
                  staticStyle: { "padding-left": "55px" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("v-ons-checkbox", {
                        attrs: { "input-id": "crossOutPriceLabel" },
                        model: {
                          value: _vm.settings.cross_out_price,
                          callback: function($$v) {
                            _vm.$set(_vm.settings, "cross_out_price", $$v)
                          },
                          expression: "settings.cross_out_price"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "center label-reset",
                      attrs: { for: "crossOutPriceLabel" }
                    },
                    [_vm._v("Отображать зачеркнутой прошлую цену")]
                  )
                ]
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-67935a5a", { render: render, staticRenderFns: staticRenderFns })
  }
}