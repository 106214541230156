<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-toolbar-button @click="$router.push('/')" >
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center" style="justify-content: center;">
                <div @click="$router.push('/register')" class="button--material"
                     style="background: #fff; color: #009688; cursor: pointer;">
                    Получить цены
                </div>
            </div>

            <div class="right">
                <v-ons-toolbar-button @click="$router.push('/login')">
                    <v-ons-icon icon="md-sign-in"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}">
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div class="catalog-list-component" v-show="itemsByRows.length > 0">
                <v-ons-list class="tile-catalog">
                    <v-ons-lazy-repeat
                        ref="lazyRepeat"
                        :render-item="renderItem"
                        :length="itemsLength"
                        :calculate-item-height="calculateItemHeight"
                    ></v-ons-lazy-repeat>
                </v-ons-list>
            </div>

            <div v-show="itemsByRows.length === 0" style="text-align: center; font-size: 15px; margin-top: 50px">Ничего
                не найдено
            </div>
        </div>
    </v-ons-page>
</template>

<script>

import Vue from "vue";
import CategoryItemTile from "~/component/catalog/CategoryItemTile";
import ProductItemTile from "./ProductItemTile";

export default {
    data() {
        return {
            storageId: null,
            loading: true,
            itemsByRows: [],
        }
    },
    created() {
        this.storageId = this.$route.params.storageId;

        this.$http.get(
            '/api/catalog/get-catalog',
            {
                params: {
                    manager: this.$bus.referralManager,
                    storageId: this.storageId,
                }
            }
        ).then(response => {
            this.setItemsByRows(response.data);
            this.loading = false;
        });
    },
    methods: {
        clickGoToView(item) {
            this.$router.push(this.$router.currentRoute.path + '/' + item.id)
        },
        calculateItemHeight(index) {
            let item;

            if (!index) {
                return 1;
            }

            item = this.itemsByRows[index];

            if (!item) {
                return 1;
            }

            if (item.length === 1 && item[0].type === 'category') {
                return 34;
            }

            return 273;
        },
        setItemsByRows(items) {
            let row = [];
            this.itemsByRows = [];

            for (let item of items) {
                if (item.type === 'category') {
                    if (row.length > 0) {
                        this.itemsByRows.push(row);
                        row = [];
                    }

                    this.itemsByRows.push([item]);
                    continue;
                }

                row.push(item);

                if (row.length === this.numberColumns) {
                    this.itemsByRows.push(row);
                    row = [];
                }
            }

            if (row.length > 0) {
                this.itemsByRows.push(row);
            }
        },
        renderItem(i) {
            let self = this;
            let item = this.itemsByRows[i];

            return new Vue({
                render(h) {
                    if (!item) {
                        return h('v-ons-list-item');
                    }

                    if (item.length === 1 && item[0].type === 'category') {
                        return h(CategoryItemTile, {
                            props: {
                                item: item[0],
                            }
                        });
                    }

                    let children = [];
                    for (let child of item) {
                        children.push(h(ProductItemTile, {
                            props: {
                                item: child,
                                isDesktop: self.isDesktop,
                                actionColType: self.actionColType,
                                canOpenProductPage: self.canOpenProductPage,
                                showInputs: self.showInputs,
                                allowInputs: self.allowInputs,
                                selectedPriceType: self.selectedPriceType,
                                counts: self.counts,
                                checked: self.checked,
                                lastImportDate: self.lastImportDate,
                                width: (100 / self.numberColumns) + '%',
                            },
                            on: {
                                clickGoToView: self.clickGoToView,
                            },
                        }))
                    }

                    return h('div',
                        {
                            attrs: {
                                class: 'tile-row-product',
                            },
                        },
                        children
                    );
                }
            });
        },
    },
    computed: {
        numberColumns() {
            if (this.$window.width > 800) {
                return 3;
            }

            return 2;
        },
        itemsLength() {
            return this.itemsByRows.length;
        },
    }
};
</script>