<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Приглашение</div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="!loaded" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <v-ons-card v-show="loaded" >
            <div class="content" style="color: #1f1f21" >
                <div>Вас приглашают стать сотрудником на платформе онлайн закупок prod-zakaz.ru</div>
                <div style="margin-top: 10px" >Контакты руководителя:</div>
                <div>{{ director.name }}</div>
                <div>{{ director.email }}</div>

                <div style="text-align: center;" >
                    <div style="font-size: 17px; margin: 20px 0 15px" >Стать сотрудником?</div>
                    <div class="wrap" style="text-align: center;" >
                        <div class="col" style="margin: 0 10px" >
                            <v-ons-button @click="accept" >Да</v-ons-button>
                        </div>
                        <div class="col" style="margin: 0 10px" >
                            <v-ons-button @click="reject" >Нет</v-ons-button>
                        </div>
                    </div>
                </div>
            </div>
        </v-ons-card>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            loaded: false,
            loading: false,
            inviteToken: '',
            director: {},
        }
    },
    created() {
        this.loading = false;
        this.inviteToken = this.$route.query['invite-token'];

        this.$http.get('/api/user/get-invite', {params: {token: this.inviteToken}}).then(response => {
            this.director = response.data.director;
            this.loaded = true;
        }, () => {
            this.$ons.notification.toast({
                message: 'Произошла ошибка',
                buttonLabels: 'Ок',
            });
        });

        if (!this.inviteToken) {
            this.$router.push('/');
        }
    },
    methods: {
        accept() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            let data = {
                token: this.inviteToken,
            }

            this.$http.post('/api/user/become-worker', data).then(() => {
                this.loading = false;

                this.$user.load().then(() => {
                    this.$ons.notification.toast({
                        message: 'Приглашение принято',
                        buttonLabels: 'Ок',
                    });

                    this.$router.push('/');
                });
            }, response => {
                this.loading = false;

                this.$ons.notification.toast({
                    message: response.data.message,
                    buttonLabels: 'Ок',
                });
            });
        },
        reject() {
            this.$router.push('/');
        },
    }
};
</script>