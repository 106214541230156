var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-alert-dialog",
    {
      attrs: { modifier: "rowfooter", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("Отправить заказ?")
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "10px" } }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.comment,
              expression: "comment"
            }
          ],
          staticClass: "textarea",
          staticStyle: { width: "100%" },
          attrs: { placeholder: "Комментарий к заказу", rows: "6" },
          domProps: { value: _vm.comment },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.comment = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("v-ons-alert-dialog-button", { on: { click: _vm.send } }, [
            _vm._v("Отправить")
          ]),
          _vm._v(" "),
          _c("v-ons-alert-dialog-button", { on: { click: _vm.cancel } }, [
            _vm._v("Отмена")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4b4994c5", { render: render, staticRenderFns: staticRenderFns })
  }
}