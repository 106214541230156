import Index from '~/screen/manager/users/catalog/Index';
import CatalogView from '~/screen/catalog/View';

let route = [
    {
        path: 'catalog',
        name: 'manager-user-catalog',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: ':prd_id',
                name: 'manager-user-catalog-view',
                props: true,
                component: CatalogView,
                meta: {
                    catalogViewEntrypoint: '/api/manager/users/$userId/catalog/$productId',
                    userProductAddEntrypoint: '/api/manager/users/$userId/user-products/add-products',
                    userProductDeleteEntrypoint: '/api/manager/users/$userId/user-products/delete-products',
                    hiddenButtons: [
                        'addCartButton',
                        'productLinksButton',
                        'productBalanceButton',
                    ]
                }
            },
        ]
    },
];

export default route;