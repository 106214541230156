<template>
    <v-ons-page>
        <v-ons-toolbar inline="true">
            <div class="left">
                <v-ons-back-button @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">{{ product.name }}</div>

            <div class="right">
                <v-ons-toolbar-button @click="save" v-show="!loading" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list-item modifier="chevron longdivider" @click="showChooseStorage">
                <span class="list-item__title">Склад: {{ selectedStorageName }}</span>
                <span v-if="selectedStorage && !selectedStorage.is_configured" style="color: red" class="list-item__subtitle">
                    Не настроено
                </span>
            </v-ons-list-item>

            <v-ons-list-item>
                <div v-if="!product.is_deleted">Остаток: {{ product.rest }} {{ product.unit }}</div>
                <div v-else>Товар кончился: {{ product.deletedAt }}</div>
            </v-ons-list-item>

            <v-ons-list-header v-show="product.is_deleted" >Информация о поступлении</v-ons-list-header>
            <v-ons-list-item  v-show="product.is_deleted" >
                <textarea class="textarea" style="width: 100%" rows="3" v-model="product.info_about_admission" ></textarea>
            </v-ons-list-item>

            <v-ons-list-item>
                <div class="left" >
                    <v-ons-checkbox input-id="productIsAvailLabel" v-model="product.is_avail" ></v-ons-checkbox>
                </div>
                <label for="productIsAvailLabel" class="center label-reset">
                    <span class="list-item__title" >Добавить товар в исключение OOS</span>
                    <span class="list-item__subtitle">Если товар кончится, то он всё равно будет отображаться в Каталоге со статусом "ждём"</span>
                </label>
            </v-ons-list-item>
        </div>

        <v-storage-list-dialog ref="chooseStorageDialog" :items="storages" :unit="product.unit" />
    </v-ons-page>
</template>

<script>

import StorageListDialog from './StorageListDialog';

export default {
    data() {
        return {
            changed: false,
            loading: true,
            unwatch: null,

            product: {},
            productId: null,
            storages: [],
            chosedStorage: null,
        }
    },
    created() {
        this.productId = this.$route.params.prd_id;
        this.load();
    },
    methods: {
        save() {
            this.$http.post(
                '/api/catalog/' + this.productId + '/available-and-balance',
                {
                    available: this.product.is_avail,
                    info_about_admission: this.product.info_about_admission,
                },
                {params: {storageId: this.chosedStorage}}
            ).then(() => {
                this.changed = false;

                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Сохранено',
                    timeout: 1000,
                });

                this.$bus.$emit('catalog-update-product', this.productId);
            }, () => {
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            })
        },
        load() {
            this.$http.get(
                '/api/catalog/' + this.productId + '/storages'
            ).then(response => {
                this.storages = response.data;
                let defaultStorage = this.$user.selectedStorage;

                if (!defaultStorage) {
                    defaultStorage = this.storages.find(s => s.is_default) || this.storages[0];
                    defaultStorage = defaultStorage.id;
                }

                this.chooseStorage(defaultStorage);
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });

                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            })
        },
        goBack() {
            if (!this.changed) {
                this.$router.goRouteBack();
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 0) {
                    this.save();
                }

                this.$router.goRouteBack();
            });
        },
        chooseStorage(storageId) {
            if (!storageId) {
                return;
            }

            this.loading = true;
            this.chosedStorage = storageId;

            this.$http.get(
                '/api/catalog/' + this.productId + '/available-and-balance',
                {params: {storageId: storageId}}
            ).then(response => {
                this.loading = false;
                if (this.unwatch !== null) {
                    this.unwatch();
                }
                this.changed = false;

                this.$nextTick(() => {
                    this.product = response.data;

                    this.unwatch = this.$watch('product', () => {
                        this.changed = true;
                    }, {deep: true})
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });

                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            })
        },
        showChooseStorage() {
            if (this.changed) {
                this.$ons.notification.confirm('Сохранить изменения?', {
                    title: 'Подтверждение',
                    buttonLabels: ['Да', 'Нет']
                }).then(response => {
                    if (response === 0) {
                        this.save();
                    }

                    this.$refs.chooseStorageDialog.show().then(response => {
                        this.chooseStorage(response);
                    });
                });
            } else {
                this.$refs.chooseStorageDialog.show().then(response => {
                    this.chooseStorage(response);
                });
            }

        },
    },
    computed: {
        selectedStorage() {
            return this.storages.find(s => s.id === parseInt(this.chosedStorage));
        },
        selectedStorageName() {
            let storage = this.storages.find(s => s.id === parseInt(this.chosedStorage));

            if (!storage) {
                return 'Неизвестно';
            }

            return storage.name;
        },
    },
    components: {
        'v-storage-list-dialog': StorageListDialog,
    },
};
</script>