var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _vm.inventory.id
          ? _c("div", { staticClass: "center" }, [
              _vm._v(
                "Изменить инвентаризацию №" +
                  _vm._s(_vm.$route.params.inventory)
              )
            ])
          : _c("div", { staticClass: "center" }, [
              _vm._v("Создание новой инвентаризации")
            ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                attrs: { title: "Выбрать шаблон" },
                on: {
                  click: function($event) {
                    return _vm.$refs.assignTemplateModal.show()
                  }
                }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-collection-item" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("assign-template-modal", {
        ref: "assignTemplateModal",
        attrs: { assignedTemplates: _vm.assignedTemplates },
        on: { choose: _vm.onChooseTemplate }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "inventory-create-page",
          staticStyle: { "padding-bottom": "100px" }
        },
        _vm._l(_vm.getGroups(), function(group) {
          return _c("div", { staticClass: "group-wrap" }, [
            _c("div", { staticClass: "group-name" }, [
              _vm._v(
                "\n                " + _vm._s(group.name) + "\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "positions" },
              _vm._l(_vm.getPositionsByGroup(group.id), function(
                position,
                index
              ) {
                return !position.isRemove && position.canShow
                  ? _c(
                      "div",
                      {
                        key: position.posId,
                        staticClass: "position-wrap",
                        class: {
                          "position-last": index == _vm.positions.length - 1
                        },
                        attrs: { id: "position-" + index }
                      },
                      [
                        _c("div", { staticClass: "row-wrap" }, [
                          _c("div", { staticClass: "col col-name" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(position.name) +
                                "\n                        "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col col-count",
                              class: {
                                highligh: position.count && position.count != 0
                              }
                            },
                            [
                              _c("div", { staticClass: "count-wrap" }, [
                                _c("div", { staticClass: "wrap" }, [
                                  _c("div", { staticClass: "col col-button" }, [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.dec(position)
                                          }
                                        }
                                      },
                                      [_vm._v("-")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col col-input" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: position.count,
                                          expression: "position.count"
                                        }
                                      ],
                                      staticClass: "input-count",
                                      attrs: { type: "number" },
                                      domProps: { value: position.count },
                                      on: {
                                        keydown: function($event) {
                                          return _vm.chooseCountHandler(
                                            $event,
                                            position,
                                            index
                                          )
                                        },
                                        focus: _vm.focusCountInput,
                                        blur: _vm.blurCountInput,
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            position,
                                            "count",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col col-button" }, [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.inc(position)
                                          }
                                        }
                                      },
                                      [_vm._v("+")]
                                    )
                                  ])
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col col-unit" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(position.unit) +
                                "\n                        "
                            )
                          ])
                        ])
                      ]
                    )
                  : _vm._e()
              }),
              0
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          attrs: { position: "bottom right", disabled: !_vm.canSubmit },
          on: { click: _vm.submit }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-dialog",
        { attrs: { visible: _vm.splashVisible } },
        [
          _c(
            "v-ons-row",
            { attrs: { "vertical-align": "center" } },
            [
              _c(
                "v-ons-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { width: "70px" }
                },
                [
                  _c("v-ons-progress-circular", {
                    attrs: { indeterminate: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-col", [
                _c("div", { staticStyle: { "line-height": "100px" } }, [
                  _vm._v("Отправка...")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-804914ae", { render: render, staticRenderFns: staticRenderFns })
  }
}