var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasChecked,
              expression: "!hasChecked"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [_vm._v("Каталог удалений")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                [
                  _c("v-ons-icon", {
                    style: {
                      color: _vm.filters.groups.length > 0 ? "#ffe000" : ""
                    },
                    attrs: {
                      title: "Разделы каталога",
                      icon: "md-filter-list"
                    },
                    on: { click: _vm.chooseGroupFilter }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChecked,
              expression: "hasChecked"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { title: "Отменить выделение" },
                  on: { click: _vm.uncheckAll }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v(
              "\n            Выбрано: " +
                _vm._s(_vm.checked.length) +
                "\n        "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.items.length > 0
          ? _c(
              "div",
              [
                _vm.isDesktop
                  ? _c(
                      "v-ons-list",
                      {
                        staticClass: "list-catalog is-desktop",
                        attrs: { id: "list-header-catalog" }
                      },
                      [
                        _c(
                          "v-ons-list-item",
                          {
                            staticClass: "table-header-wrap",
                            style: { width: _vm.listCatalogWidth + "px" }
                          },
                          [
                            _c("div", { staticClass: "col-name" }, [
                              _vm._v("Наименование")
                            ]),
                            _vm._v(" "),
                            _vm.isDesktop && _vm.$user.isManager
                              ? _c("div", { staticClass: "col-rest" }, [
                                  _vm._v("Остаток")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.$user.canSeePrice,
                                    expression: "$user.canSeePrice"
                                  }
                                ],
                                staticClass: "col-price"
                              },
                              [_vm._v("Цена")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.showInputs && !_vm.$user.isManager,
                                    expression: "showInputs && !$user.isManager"
                                  }
                                ],
                                staticClass: "col-count"
                              },
                              [_vm._v("Заказ")]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isFullCatalog
                  ? _c(
                      "v-ons-list",
                      {
                        staticClass: "list-catalog",
                        attrs: { id: "list-catalog" }
                      },
                      _vm._l(_vm.items, function(item, index) {
                        return _c("v-ons-catalog-item", {
                          key: index,
                          attrs: {
                            item: item,
                            isDesktop: _vm.isDesktop,
                            canOpenProductPage: _vm.canOpenProductPage,
                            showInputs: _vm.showInputs,
                            selectedPriceType: _vm.selectedPriceType,
                            counts: _vm.counts,
                            checked: _vm.checked
                          }
                        })
                      }),
                      1
                    )
                  : _c(
                      "v-ons-list",
                      { staticClass: "list-catalog" },
                      [
                        _c("v-ons-lazy-repeat", {
                          ref: "lazyRepeat",
                          attrs: {
                            "render-item": _vm.renderItem,
                            length: _vm.items.length
                          }
                        })
                      ],
                      1
                    ),
                _vm._v(" "),
                _c("div", { staticStyle: { width: "100%", height: "80px" } })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.items.length === 0,
                expression: "items.length === 0"
              }
            ],
            staticClass: "list-empty",
            style: {
              height: _vm.$window.height - 56 + "px",
              flexDirection: "column"
            }
          },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px", "font-size": "15px" } },
              [_vm._v("\n                Ничего не найдено\n            ")]
            ),
            _vm._v(" "),
            _c("v-ons-button", { on: { click: _vm.reset } }, [
              _vm._v("Сбросить")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "groupFilterModal",
        attrs: {
          multiple: "true",
          title: "Фильтр по группам",
          showResetButton: "false",
          allowEmptyList: "true"
        }
      }),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          attrs: { disabled: !_vm.hasChecked, position: "bottom right" },
          on: { click: _vm.undoDelete }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-undo" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-16c2ba68", { render: render, staticRenderFns: staticRenderFns })
  }
}