<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center">Перемещение/Удаление товаров</div>

            <div class="right">
                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item
                        text="Каталог удалений"
                        icon="md-delete"
                        @click="$router.push({name: 'manager-catalog-settings-product-rules-deleted-catalog'})" />
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content" style="padding-bottom: 160px" >
            <div v-show="rules.length > 0" >
                <div v-for="(rulesByType, key) in rulesByTypes" :key="key" >
                    <v-ons-list v-show="rulesByType.items.length > 0" style="margin-bottom: 30px" >
                        <v-ons-list-header style="margin-bottom: -10px;" >{{ rulesByType.name }}</v-ons-list-header>

                        <ons-list-item
                            v-for="(rule, key) in rulesByType.items"
                            :key="key" modifier="chevron longdivider"
                            @click="$router.push({name: 'manager-catalog-settings-product-rules-update', params: {product_id: rule.product.id}})"
                            >

                            <div class="center label-reset" >
                                <span class="list-item__title" >
                                    {{ rule.product.name }}
                                </span>

                                <span class="list-item__subtitle" style="color: red" v-if="rule.product.is_deleted" >
                                    Нет в наличии.
                                    {{ rule.product.countProductLinks > 0 ? 'Ждём' : '' }}
                                </span>

                                <span class="list-item__subtitle" >
                                    <span :style="{color: (!rule.categoryFrom || rule.categoryFrom.isDeleted)  ? 'red' : ''}" >
                                        "{{
                                            (rule.categoryFrom && rule.categoryFrom.name) ? rule.categoryFrom.name : 'Раздел не найден'
                                        }}"
                                    </span>

                                    <v-ons-icon icon="md-arrow-right"></v-ons-icon>

                                    <span v-show="rule.type === 'delete'" >
                                        Товар удалён
                                    </span>

                                    <span v-show="rule.type === 'replace'" :style="{color: rule.categoryTo.isDeleted ? 'red' : ''}" >
                                        "{{ rule.categoryTo.name || 'Раздел не найден' }}"
                                    </span>
                                </span>
                            </div>
                        </ons-list-item>
                    </v-ons-list>
                </div>
            </div>

            <div v-show="rules.length === 0" class="list-empty" :style="{flexFlow: 'column', height: $window.height - 56 + 'px'}" >
                <div style="margin-bottom: 10px; font-size: 15px;" >
                    Данные не обнаружены
                </div>
            </div>
        </div>

        <v-ons-fab position="bottom right" @click="$router.push({name: 'manager-catalog-settings-product-rules-create'})" >
            <v-ons-icon icon="md-plus"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,

            rules: [],
            rulesByTypes: [],
        }
    },
    created() {
        this.load();
        this.$bus.$on('update', this.load);
    },
    destroyed() {
        this.$bus.$off('update', this.load);
    },
    methods: {
        load() {
            this.loading = true;

            let requests = [
                this.$http.get(
                    '/api/manager/catalog-settings/product-rules',
                    {params: {storageId: this.$route.params.storage_id}}
                ),
            ];

            Promise.all(requests).then(responses => {
                this.rules = responses[0].data;

                this.rulesByTypes = ['replace', 'delete'].map(name => {
                    return {
                        name: (name === 'replace') ? 'Перемещения' : 'Удаления',
                        type: name,
                        items: this.rules.filter(r => r.type === name),
                    };
                });

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
    },
};
</script>