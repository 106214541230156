<template>
    <v-ons-dialog cancelable :visible.sync="isVisible" >
        <v-ons-toolbar inline="true" >
            <div class="center">Группировать по:</div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item tappable @click="choose('byCatalogGroup')" >Разделам прайса</v-ons-list-item>
            <v-ons-list-item tappable @click="choose('byUserGroup')" >Группам товара пользователя</v-ons-list-item>
            <v-ons-list-item v-show="isGrouped" tappable @click="choose(null)" >Отменить группировку</v-ons-list-item>
        </v-ons-list>
    </v-ons-dialog>

</template>

<script>

export default {
    props: [
        'isGrouped'
    ],
    data() {
        return {
            isVisible: false,
        }
    },
    created() {

    },
    methods: {
        show() {
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
        },
        choose(type) {
            this.$emit('choose', type);
            this.close();
        },
    },
}

</script>
