import Index from '~/screen/manager/users/Settings'
import Entity from '~/screen/manager/users/Entity'
import CustomPriceType from '~/screen/manager/users/CustomPriceType'

let route = [
    {
        path: 'settings',
        name: 'manager-user-settings',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'custom-price-type',
                name: 'manager-user-settings-custom-price-type',
                component: CustomPriceType,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'entities/create',
                name: 'manager-user-settings-entity-create',
                component: Entity,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
            {
                path: 'entities/:entity_id',
                name: 'manager-user-settings-entity',
                component: Entity,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
            },
        ],
    },
]

export default route;
