var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "report-special-prices" },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Отчет по специальным ценам")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                on: {
                  click: function($event) {
                    _vm.showMinPrice = !_vm.showMinPrice
                  }
                }
              },
              [
                _c("v-ons-icon", {
                  attrs: { icon: _vm.showMinPrice ? "md-eye" : "md-eye-off" }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.itemsByUsers.length > 0,
                expression: "itemsByUsers.length > 0"
              }
            ]
          },
          _vm._l(_vm.itemsByUsers, function(itemsByUser) {
            return _c("v-ons-card", { key: itemsByUser.id }, [
              _c(
                "div",
                {
                  staticClass: "title",
                  on: {
                    click: function($event) {
                      return _vm.goToUser(itemsByUser.id)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(itemsByUser.name) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(itemsByUser.items, function(item) {
                  return _c(
                    "div",
                    { key: item.product_id, staticClass: "product-wrap" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "product-name",
                          on: {
                            click: function($event) {
                              return _vm.$router.push(
                                "/manager/products/" + item.product_id
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.name) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("table", { staticClass: "table-params" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [_vm._v("Ед.")]),
                            _vm._v(" "),
                            _c("td", [_vm._v("Цена прайс")]),
                            _vm._v(" "),
                            _c("td", [_vm._v("Цена спец.")])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v(_vm._s(item.unit))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(item.productPrice) +
                                  "\n                                        "
                              ),
                              _c("span", { staticClass: "font-rouble" }, [
                                _vm._v("o")
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push(
                                      "/manager/users/" +
                                        item.user_id +
                                        "/special-prices"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(item.price) +
                                    "\n                                        "
                                ),
                                _c("span", { staticClass: "font-rouble" }, [
                                  _vm._v("o")
                                ])
                              ]
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n                            Наличие:\n                            "
                        ),
                        item.rest
                          ? _c("span", [_vm._v(_vm._s(item.rest))])
                          : _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("нет")
                            ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showMinPrice,
                              expression: "showMinPrice"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                            Мин. цена:\n                            "
                          ),
                          item.minPrice &&
                          parseFloat(item.price) < parseFloat(item.minPrice)
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(_vm._s(item.minPrice))
                              ])
                            : _c("span", [_vm._v("ok")])
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.itemsByUsers.length == 0,
                expression: "itemsByUsers.length == 0"
              }
            ],
            staticStyle: {
              "text-align": "center",
              "font-size": "15px",
              "margin-top": "50px"
            }
          },
          [_vm._v("Ничего не найдено")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e5b0a478", { render: render, staticRenderFns: staticRenderFns })
  }
}