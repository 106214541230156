<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center" >Заказ №{{ order.id }} от {{ order.createdAtFormat }}</div>

            <div class="right">
                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item
                        text="Отменить"
                        icon="md-block"
                        v-show="order && (!$user.isWorker || $user.orderSendMethod === 'direct')"
                        @click="cancelOrder()" />

                    <toolbar-popover-menu-item
                        text="Скачать в PDF"
                        icon="md-download"
                        v-show="order"
                        @click="downloadPdf()" />
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="content">
            <div class="order-view-page" >
                <div class="form-group" >
                    Номер заказа: {{ order.id }}
                </div>

                <div v-show="order.status_id === 'confirmed' && order.invoice_number" class="form-group invoice-number" >
                    Номер накладной: {{ order.invoice_number }}
                </div>

                <div class="form-group" >
                    Статус: {{ order.status }}
                </div>

                <div class="form-group" v-show="!$user.isWorker" >
                    Способ оплаты: {{ order.paymentMethodLabel }}
                </div>

                <div class="form-group" >
                    Способ отгрузки: {{ order.shippingMethodLabel }}
                </div>

                <div class="form-group" v-show="order.shippingMethod === 'delivery'" >
                    Адрес доставки:
                    <span v-if="order.branch" >
                        {{ order.branch.shortAddress }}
                    </span>
                    <span v-else >не указан</span>
                    <span v-if="order.entity" >
                        ({{ order.entity.legal_name }})
                    </span>
                </div>

                <div class="form-group"  v-if="order.shippingMethod === 'pickup' && order.entity" >
                    Юр. лицо: {{ order.entity.legal_name }}
                </div>

                <div class="form-group" >
                    Дата отгрузки: {{ order.shippingDate }}
                </div>

                <div class="form-group" >
                    Комментарий: {{ order.comment ? order.comment : 'не указан' }}
                </div>

                <br>

                <table class="table" >
                    <tr v-for="(position, index) in unwaitingPositions" class="cursor-pointer" @click="positionView(position)" >
                        <td>{{ index + 1 }}</td>
                        <td class="col-name" >{{ position.name }}</td>
                        <td class="col-count" >
                            {{ position.count }} {{ position.unit }}
                        </td>
                        <td :class="['col-price', position.specialPrice.isShowMark ? 'is-low-special-price' : '']" v-show="$user.canSeePrice" >
                            {{ position.price }} руб.
                        </td>
                    </tr>

                    <tr v-show="waitingPositions.length > 0">
                        <td colspan="4" style="font-weight: bold; background: #e3e3e3;" >
                            Добавить к заказу, если есть в наличии
                        </td>
                    </tr>

                    <tr v-for="(position, index) in waitingPositions" class="cursor-pointer" @click="positionView(position)" >
                        <td>{{ index + unwaitingPositions.length + 1 }}</td>
                        <td class="col-name" >{{ position.name }}</td>
                        <td class="col-count" >
                            {{ position.count }} {{ position.unit }}
                        </td>
                        <td :class="['col-price', position.specialPrice.isShowMark ? 'is-low-special-price' : '']" v-show="$user.canSeePrice" >
                            {{ position.price }} руб.
                        </td>
                    </tr>

                    <tr v-show="order.hasCostForShipping > 0 && $user.canSeePrice" >
                        <td></td>
                        <td class="col-name" >Доставка</td>
                        <td class="col-count" ></td>
                        <td class="col-price" >{{ order.cost_for_shipping }} руб.</td>
                    </tr>

                    <tr v-show="order.cost_for_shipping > 0 && !$user.canSeePrice" >
                        <td></td>
                        <td class="col-name" colspan="3" >Платная доставка</td>
                    </tr>

                    <tr v-show="order.paymentMethod > 0 && order.terminalTax && $user.canSeePrice" >
                        <td></td>
                        <td class="col-name" >Комиссия за оплату по терминалу</td>
                        <td class="col-count" ></td>
                        <td class="col-price" >{{ order.terminalTax }} руб.</td>
                    </tr>
                </table>

                <div class="total-order-wrap" v-show="$user.canSeePrice" >
                    <div class="wrap total-order">
                        <div class="col col-label">
                            <div>Сумма</div>
                        </div>
                        <div class="col col-total-price">
                            <div class="total-price-wrap">{{ order.totalCost }} руб.</div>
                        </div>
                    </div>

                    <div v-show="order.hasDiscount" class="wrap total-order">
                        <div class="col col-label">
                            <div>Сумма со скидкой</div>
                        </div>
                        <div class="col col-total-price">
                            <div class="total-price-wrap">{{ order.totalCostWithDiscount }} руб.</div>
                        </div>
                    </div>
                </div>

                <hr v-if="order.worker" >

                <div v-if="order.worker" >
                    <p>Создан: {{ order.createdAt | moment('L LT') }}</p>
                    <p>Отправлен: {{ sendedTimeLabel }}</p>
                    <p>Ответственный: <a :href="`/manager/users/${order.worker.id}`" >{{ order.worker.email }}</a></p>
                </div>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    props: [
        'orderProp',
    ],
    data() {
        return {
            order: null,
        }
    },
    created() {
        this.order = this.orderProp;
    },
    methods: {
        cancelOrder() {
            this.$ons.notification.confirm('Отменить заказ?', {
                title: 'Подтверждение',
                buttonLabels: ["Отмена", "OK"]
            }).then((response) => {
                if (response !== 1) {
                    return;
                }

                this.$http.post('/api/order/cancel', {id: this.order.id}).then(() => {
                    this.$bus.$emit('orders-update');
                    this.$ons.notification.toast({
                        timeout: 3000,
                        message: `Заказ №${this.order.id} отменен`,
                        buttonLabel: 'OK'
                    });
                    this.$router.push('/order');
                }, ({data}) => {
                    this.$ons.notification.toast({
                        timeout: 5000,
                        message: data.message,
                        buttonLabel: 'OK'
                    });
                })
            });
        },
        downloadPdf() {
            return this.$http.post('/api/order/download-order-pdf', {id: this.order.id}, {responseType: 'arraybuffer'}).then(response => {
                let filename = 'Заказ №' + this.order.id;

                let blob = new Blob([response.data], { type: response.headers.get('content-type') });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        positionView(position) {
            this.$router.push({name: 'order-position-view', params: {prd_id: position.prd_id}});
        },
    },
    computed: {
        sendedTimeLabel() {
            if (!this.order.sended_at) {
                return this.$user.isWorker ? 'Не отправлен поставщику' : 'Не отправлен';
            }

            if (this.order.sended_at) {
                return this.$moment(this.order.sended_at).format('L LT');
            }
        },
        unwaitingPositions() {
            if (!this.order || !this.order.positions) {
                return [];
            }

            return this.order.positions.filter(i => !i.is_available);
        },
        waitingPositions() {
            if (!this.order || !this.order.positions) {
                return [];
            }

            return this.order.positions.filter(i => i.is_available);
        },
    },
};
</script>