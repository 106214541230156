<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>

            <div class="center">Выбор типа цены</div>

            <div class="right">
                <v-ons-toolbar-button @click="save" v-show="!loading" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div style="padding: 10px 16px" >
                <div style="font-weight: bold; font-size: 13px; margin-bottom: 10px;" >
                    <span>
                        {{ categories.length > 1 ? 'Для разделов' : 'Для раздела' }}:
                    </span>
                    <span>
                        {{ categories.map(i => i.name).join(', ') }}.
                    </span>
                </div>

                <div class="select-material select--material select">
                    <select v-model="params.priceType" class="select-input select-input--material" >
                        <option disabled :value="null" >
                            Выберите тип цены
                        </option>

                        <option v-for="priceType in priceTypes" :key="priceType.id" :value="priceType.id" >
                            {{ priceType.name }}
                        </option>
                    </select>
                </div>

                <div class="wrap xl-gutter-8" style="padding: 15px 0 0 15px;" >
                    <div class="col" >
                        <div>% наценки</div>
                    </div>
                    <div class="col" >
                        <div><input type="number" v-model="params.priceMarkup" /></div>
                    </div>
                </div>
            </div>

            <v-ons-list>
                <v-ons-list-item modifier="longdivider" >
                    <div class="left" >
                        <v-ons-checkbox
                          :value="true"
                          v-model="params.calculateWhenNoPrice"
                          input-id="params-calculateWhenNoPrice"
                        ></v-ons-checkbox>
                    </div>

                    <label class="center label-reset" for="params-calculateWhenNoPrice" >Если нет цены, то ценообразовать</label>
                </v-ons-list-item>
            </v-ons-list>

            <div v-show="params.calculateWhenNoPrice" style="margin: 0 16px 0 30px;" >
                <div style="margin: 7px 0" >
                    <div class="select-material select--material select">
                        <select v-model="params.priceTypeSecond" class="select-input select-input--material" >
                            <option disabled :value="null" >
                                Выберите тип цены
                            </option>

                            <option v-for="priceType in priceTypes" :key="priceType.id" :value="priceType.id" >
                                {{ priceType.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div style="margin: 11px 0" >
                    <div class="wrap" >
                        <div class="col" >
                            <div>% наценки</div>
                        </div>
                        <div class="col" >
                            <div>
                                <input style="margin-left: 10px" type="number" v-model="params.priceMarkupSecond" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="padding: 5px; margin: 40px 10px 10px; border: 1px solid #000;" >
                Если в исходном прайс-листе у товара нет выбранной цены, то такой товар не будет выгружен.
            </div>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            priceTypes: [],

            ids: [],
            params: {
                priceType: null,
                priceMarkup: null,
                priceTypeSecond: null,
                priceMarkupSecond: null,
                calculateWhenNoPrice: false,
            },
        }
    },
    created() {
        this.categories = this.$route.params.categories || [];

        if (!this.categories || !this.categories.length) {
            this.$router.push({ name: 'manager-catalog-settings-pricing' });
            return;
        }

        this.ids = this.categories.map(i => i.category_id);

        let requests = [
            this.$http.get(
                '/api/manager/catalog-settings/price-types',
                {params: {storageId: this.$route.params.storage_id}}
            ),

            this.$http.get(
                '/api/manager/catalog-settings/pricing',
                {
                    params: {
                        storageId: this.$route.params.storage_id, ids: this.ids
                    }
                }
            )
        ];

        Promise.all(requests).then(responses => {
            this.priceTypes = responses[0].data;

            let params = responses[1].data;

            if (params) {
                this.params.priceType = params.priceType ? params.priceType.toString() : null;
                this.params.priceMarkup = params.priceMarkup ? params.priceMarkup : this.params.priceMarkup;
                this.params.priceTypeSecond = params.priceTypeSecond ? params.priceTypeSecond.toString() : null;
                this.params.priceMarkupSecond = params.priceMarkupSecond ? params.priceMarkupSecond : this.params.priceMarkupSecond;
                this.params.calculateWhenNoPrice = params.calculateWhenNoPrice ? params.calculateWhenNoPrice : this.params.calculateWhenNoPrice;
            }

            this.$nextTick(() => {
                this.loading = false;
            });
        }, () => {
            this.$ons.notification.toast({
                buttonLabel: 'OK',
                message: 'Произошла ошибка.',
            });
        });
    },
    methods: {
        save() {
            if (!this.params.priceType) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Выберите тип цены',
                    buttonLabel: 'OK',
                });
                return;
            }

            if (this.params.calculateWhenNoPrice && !this.params.priceTypeSecond) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Выберите тип цены для ценообразования',
                    buttonLabel: 'OK',
                });
                return;
            }

            this.loading = true;

            this.$http.post(
                '/api/manager/catalog-settings/pricing',
                {params: this.params},
                {params: {storageId: this.$route.params.storage_id, ids: this.ids}}
            ).then(() => {
                this.$bus.$emit('update');
                this.$bus.$emit('checkErrors');
                this.loading = false;

                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Сохранено',
                    buttonLabel: 'OK',
                });

                this.$router.push({ name: 'manager-catalog-settings-pricing' });
            }, () => {
                this.loading = false;
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        goBack() {
            if (this.loading) {
                return;
            }

            this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
        },
    },
};
</script>