var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-alert-dialog",
    {
      attrs: { modifier: "rowfooter", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.message))]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "5px" } },
        [
          _c("v-ons-checkbox", {
            staticStyle: { "vertical-align": "middle" },
            attrs: { "input-id": "chk", value: true },
            model: {
              value: _vm.isChecked,
              callback: function($$v) {
                _vm.isChecked = $$v
              },
              expression: "isChecked"
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticStyle: {
                "font-weight": "normal",
                "vertical-align": "middle",
                margin: "0"
              },
              attrs: { for: "chk" }
            },
            [_vm._v(_vm._s(_vm.checkboxLabel))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("v-ons-alert-dialog-button", { on: { click: _vm.cancel } }, [
            _vm._v("Отмена")
          ]),
          _vm._v(" "),
          _c("v-ons-alert-dialog-button", { on: { click: _vm.ok } }, [
            _vm._v("ОК")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6be37494", { render: render, staticRenderFns: staticRenderFns })
  }
}