<template>
    <v-ons-dialog :visible.sync="visible" :cancelable="canCancel" >
        <v-ons-toolbar inline="true" >
            <div class="center">Счёт на оплату</div>

            <div class="right">
                <v-ons-toolbar-button @click="visible = false" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-header>Поставщик</v-ons-list-header>

            <v-ons-list-item style="margin-top: -20px">
                <div class="select-material select--material select">
                    <select class="select-input select-input--material" v-model="entity" >
                        <option v-for="ent in entities" :key="ent.id" :value="ent.id" >
                            {{ ent.alias }}
                        </option>
                    </select>
                    <div v-if="settlementAccounts.length === 0" class="input-error" style="min-height: 20px;" >
                        не внесены реквизиты банка
                    </div>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list v-show="settlementAccounts.length > 0" >
            <v-ons-list-header>Расчетный счет</v-ons-list-header>

            <v-ons-list-item style="margin-top: -20px">
                <div class="select-material select--material select">
                    <select class="select-input select-input--material" v-model="settlementAccount" >
                        <option v-for="stlAcc in settlementAccounts" :key="stlAcc.id" :value="stlAcc.id" >
                            {{ stlAcc.bank }}
                        </option>
                    </select>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list v-show="availNds">
            <v-ons-list-header>НДС</v-ons-list-header>

            <v-ons-list-item style="margin-top: -20px">
                <div class="select-material select--material select">
                    <select class="select-input select-input--material" v-model="ndc" >
                        <option value="10" >10% {{ getAmountOfNdc(10) }}</option>
                        <option value="20" >20% {{ getAmountOfNdc(20) }}</option>
                        <option value="custom" >Ручной ввод</option>
                    </select>
                </div>

                <input
                    v-model="customNdc"
                    v-show="ndc === 'custom'"
                    style="margin-top: 20px; width: 100%"
                    type="number"
                    class="text-input text-input--material"
                    placeholder="Сумма НДС" />

                <span v-show="hasCustomNdcError" style="color: #ff1f1f; font-size: 12px;">Неверное значение</span>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list v-show="!availNds">
            <v-ons-list-item>
                НДС не облагается
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-row class="text-center" style="margin: 5px 0" >
            <v-ons-col width="50%">
                <v-ons-button @click="open" :disabled="!isValid" style="width: 90%">Открыть</v-ons-button>
            </v-ons-col>

            <v-ons-col width="50%">
                <v-ons-button @click="send" :disabled="!isValid || sending" style="width: 90%">
                    <span v-if="!sending" >Отправить</span>
                    <span v-else >Отправка...</span>
                </v-ons-button>
            </v-ons-col>
        </v-ons-row>
    </v-ons-dialog>
</template>

<script>
export default {
    props: [
        'orderId',
        'defaultEntity',
        'entities',
    ],
    data() {
        return {
            visible: false,
            sending: false,

            entity: null,
            settlementAccount: null,
            ndc: 'custom',
            customNdc: '',
            loaded: false,
            order: {},
        }
    },
    created() {
        if (this.entities.length === 1) {
            this.entity = this.entities[0].id.toString();
        }

        if (this.settlementAccounts.length === 1) {
            this.settlementAccount = this.settlementAccounts[0].id.toString();
        }

        this.loaded = true;
    },
    methods: {
        show(order) {
            this.order = order;

            let params = order.invoice_form_params;

            if (params) {
                this.entity = params.entity;
                this.settlementAccount = params.settlementAccount;
                this.ndc = params.ndc;
                this.customNdc = params.customNdc;
            }

            if (!this.entity && this.defaultEntity) {
                this.entity = this.defaultEntity;

                let defaultSettlementAccount = this.settlementAccounts.find(i => i.is_default);
                if (defaultSettlementAccount) {
                    this.settlementAccount = defaultSettlementAccount.id;
                }
            }

            this.visible = true;
        },
        open() {
            if (!this.isValid) {
                return;
            }

            let win = window.open(
                '/api/manager/order/invoice?' +
                'id=' + this.orderId +
                '&entity=' + this.entity +
                '&settlementAccount=' + this.settlementAccount +
                '&ndc=' + this.ndc +
                '&customNdc=' + this.customNdc +
                '&access-token=' + this.$user.token
            );
            win.focus();
        },
        send() {
            if (!this.isValid) {
                return;
            }

            this.sending = true;

            this.$http.post(
                '/api/manager/order/invoice',
                {params: this.params},
                {params: {id: this.orderId}}
            ).then(() => {
                this.visible = false;
                this.sending = false;
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Счёт на оплату отправлен',
                    buttonLabel: 'OK'
                });
                this.$emit('sended');
            }, () => {
                this.sending = false;
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        getAmountOfNdc(percent) {
            if (!this.order || !percent) {
                return;
            }

            let totalCost = 0;

            if (this.order.hasDiscount) {
                totalCost = this.order.totalCostWithDiscount;
            } else {
                totalCost = this.order.totalCost;
            }

            if (!totalCost) {
                return;
            }

            let amount = totalCost - totalCost / (1 + percent / 100);

            return ' - ' + amount.toFixed(2) + ' руб.';
        },
    },
    computed: {
        params() {
            return {
                entity: this.entity,
                settlementAccount: this.settlementAccount,
                ndc: this.ndc,
                customNdc: this.customNdc,
            };
        },
        canCancel() {
            return !this.sending;
        },
        settlementAccounts() {
            if (!this.entity) {
                return [];
            }

            let entity = this.entities.find(i => i.id === parseInt(this.entity));

            if (!entity) {
                return [];
            }

            return entity.settlementAccounts;
        },
        isValid() {
            if (!this.entity) {
                return false;
            }

            if (!this.settlementAccount) {
                return false;
            }

            if (this.availNds) {
                if (!this.ndc) {
                    return false;
                }

                if (this.ndc === 'custom' && !this.customNdc) {
                    return false;
                }

                if (this.hasCustomNdcError) {
                    return false;
                }
            }

            return true;
        },
        defaultSettlementAccountID() {
            let defaultSettlementAccount = this.settlementAccounts.find(i => i.is_default);

            if (!defaultSettlementAccount) {
                return;
            }

            return defaultSettlementAccount.id;
        },
        hasCustomNdcError() {
            let customNdc = parseFloat(this.customNdc);

            if (this.ndc !== 'custom') {
                return;
            }

            let total = this.order.hasDiscount ? this.order.totalCostWithDiscount : this.order.totalCost;
            total = parseFloat(total);

            if (customNdc === total) {
                return true;
            }

            let min = Math.floor(total / 110 * 10);
            let max = Math.ceil(total / 120 * 20);

            return customNdc < min || customNdc > max;
        },
        availNds() {
            if (!this.entity) {
                return false;
            }

            let entity = this.entities.find(entity => {
                return entity.id === parseInt(this.entity);
            });

            if (!entity) {
                return false;
            }

            return entity.is_payer_ndc;
        }
    },
    watch: {
        entity() {
            if (this.loaded) {
                this.$emit('change', this.params);

                if (this.defaultSettlementAccountID) {
                    this.settlementAccount = this.defaultSettlementAccountID;
                } else {
                    this.settlementAccount = null;
                }
            }
        },
        settlementAccount() {
            if (this.loaded) {
                this.$emit('change', this.params);
            }
        },
        ndc() {
            if (this.loaded) {
                this.$emit('change', this.params);
            }
        },
        customNdc() {
            if (this.loaded) {
                this.$emit('change', this.params);
            }
        },
    },
};
</script>