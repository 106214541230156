<template>
    <v-ons-alert-dialog modifier="rowfooter" :visible.sync="visible" >
        <span slot="title">Подтвердить заказ?</span>

        <div v-show="showInvoiceNumber" style="margin-top: 5px; margin-bottom: 15px" >
            <p>Номер накладной:</p>
            <div>
                <v-ons-input v-model="invoiceNumber" />
            </div>
        </div>

        <div v-if="showChoiceWithNoty" style="margin-top: 5px;" >
            <v-ons-checkbox input-id="confirm-order-dialog-with-noty" v-model="withNoty" style="vertical-align: middle;" ></v-ons-checkbox>
            <label for="confirm-order-dialog-with-noty" style="font-weight: normal; vertical-align: middle; margin: 0" >Отправить уведомление</label>
        </div>

        <template slot="footer">
            <v-ons-alert-dialog-button @click="ok">Подтвердить</v-ons-alert-dialog-button>
            <v-ons-alert-dialog-button @click="cancel">Отмена</v-ons-alert-dialog-button>
        </template>
    </v-ons-alert-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            showInvoiceNumber: false,
            invoiceNumber: '',
            showChoiceWithNoty: false,
            withNoty: false,

            resolve: null,
            reject: null,
        }
    },
    methods: {
        show(showInvoiceNumber, showChoiceWithNoty) {
            this.invoiceNumber = '';
            this.showInvoiceNumber = showInvoiceNumber || false;
            this.showChoiceWithNoty = showChoiceWithNoty;
            this.withNoty = false;

            window.addEventListener('keyup', this.keyupListener);
            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            window.removeEventListener('keyup', this.keyupListener);

            if (this.resolve) {
                this.resolve(-1);
            }
        },
        ok() {
            this.visible = false;
            window.removeEventListener('keyup', this.keyupListener);

            if (this.resolve) {
                this.resolve({invoice_number: this.invoiceNumber, withNoty: this.withNoty});
            }
        },
        keyupListener(event) {
            if (event.keyCode == 27) {
                this.cancel();
            }

            if (event.keyCode == 13) {
                this.ok();
            }
        },
    },
};
</script>
