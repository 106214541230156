var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "management-shipping-by-routes" },
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("График приемки заказов и доставки по маршрутам")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right", staticStyle: { "min-width": "100px" } },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.addRoute } },
              [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
              1
            ),
            _vm._v(" "),
            _vm.items.length > 0
              ? _c(
                  "v-ons-toolbar-button",
                  { on: { click: _vm.save } },
                  [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.items.length > 0
          ? _c(
              "table",
              { staticClass: "table" },
              _vm._l(_vm.items, function(item, key) {
                return _c(
                  "div",
                  {
                    directives: [{ name: "frag", rawName: "v-frag" }],
                    key: key
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticClass: "cursor-pointer",
                            on: {
                              click: function($event) {
                                item.menu.opened = true
                              }
                            }
                          },
                          [
                            _c("v-ons-icon", {
                              staticStyle: {
                                "font-size": "21px",
                                "vertical-align": "middle",
                                margin: "0 10px 0 10px"
                              },
                              attrs: {
                                icon: "md-more-vert",
                                id:
                                  "shipping-by-routes-page-popover-menu-" + key
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "v-ons-popover",
                              {
                                class: {
                                  "shipping-by-routes-page-popover-menu": true,
                                  "is-desktop": _vm.$window.isDesktop
                                },
                                attrs: {
                                  cancelable: "",
                                  visible: item.menu.opened,
                                  target:
                                    "#shipping-by-routes-page-popover-menu-" +
                                    key,
                                  "cover-target": "true",
                                  direction: "down"
                                },
                                on: {
                                  "update:visible": function($event) {
                                    return _vm.$set(item.menu, "opened", $event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "ons-list",
                                  {
                                    on: {
                                      click: function($event) {
                                        item.menu.opened = false
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "ons-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.viewRoute(item.route)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "left icon" },
                                          [
                                            _c("v-ons-icon", {
                                              attrs: { icon: "md-assignment" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "center" }, [
                                          _vm._v("Просмотр")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                !item.route.is_pickup
                                  ? _c(
                                      "ons-list",
                                      {
                                        on: {
                                          click: function($event) {
                                            item.menu.opened = false
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "ons-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.renameRoute(
                                                  item.route
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "left icon" },
                                              [
                                                _c("v-ons-icon", {
                                                  attrs: { icon: "md-edit" }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "center" },
                                              [_vm._v("Переименовать")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !item.route.is_pickup
                                  ? _c(
                                      "ons-list",
                                      {
                                        on: {
                                          click: function($event) {
                                            item.menu.opened = false
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "ons-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.removeRoute(
                                                  item.route
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "left icon" },
                                              [
                                                _c("v-ons-icon", {
                                                  attrs: { icon: "md-delete" }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "center" },
                                              [_vm._v("Удалить")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(
                              "\n\n                            " +
                                _vm._s(item.route.name) +
                                "\n                        "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-center" }, [
                          item.route.name === "Самовывоз"
                            ? _c("span", [_vm._v("Отгрузка")])
                            : _c("span", [_vm._v("Доставка")])
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("День заказа")]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-center" }, [_vm._v("До")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _vm._l(item.route.schedule, function(weekday) {
                          return _c("tr", [
                            _c("td", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(weekday.name) +
                                  "\n                        "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-center" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: weekday.hasDelivery,
                                    expression: "weekday.hasDelivery"
                                  }
                                ],
                                staticStyle: { width: "15px", height: "15px" },
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(weekday.hasDelivery)
                                    ? _vm._i(weekday.hasDelivery, null) > -1
                                    : weekday.hasDelivery
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = weekday.hasDelivery,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            weekday,
                                            "hasDelivery",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            weekday,
                                            "hasDelivery",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(weekday, "hasDelivery", $$c)
                                    }
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "v-ons-select",
                                  {
                                    model: {
                                      value: weekday.beforeDay,
                                      callback: function($$v) {
                                        _vm.$set(weekday, "beforeDay", $$v)
                                      },
                                      expression: "weekday.beforeDay"
                                    }
                                  },
                                  _vm._l(_vm.weekdays, function(w) {
                                    return _c(
                                      "option",
                                      { domProps: { value: w.id } },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(w.name) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-center" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: weekday.beforeTime,
                                    expression: "weekday.beforeTime"
                                  }
                                ],
                                staticStyle: {
                                  "text-align": "center",
                                  margin: "0 auto",
                                  "max-width": "100px"
                                },
                                attrs: { type: "time" },
                                domProps: { value: weekday.beforeTime },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      weekday,
                                      "beforeTime",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        }),
                        _vm._v(" "),
                        !item.route.is_pickup
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "4" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cursor-pointer",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleExtraBlock(
                                          item.route.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-ons-icon", {
                                      staticStyle: {
                                        "font-size": "25px",
                                        "vertical-align": "middle",
                                        width: "16px",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        icon: _vm.extraBlockUnfold.includes(
                                          item.route.id
                                        )
                                          ? "md-chevron-down"
                                          : "md-chevron-right"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "label-reset",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                          "margin-left": "10px"
                                        },
                                        attrs: {
                                          for:
                                            "extraBlockLabel-" + item.route.id
                                        }
                                      },
                                      [_vm._v("Дополнительные условия")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.extraBlockUnfold.includes(
                                          item.route.id
                                        ),
                                        expression:
                                          "extraBlockUnfold.includes(item.route.id)"
                                      }
                                    ]
                                  },
                                  [
                                    _c("table", { staticClass: "table" }, [
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th"),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Сумма")]
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v("Бесплатная доставка от")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      item.route
                                                        .free_delivery_from,
                                                    expression:
                                                      "item.route.free_delivery_from"
                                                  }
                                                ],
                                                staticClass:
                                                  "without-arrow text-center",
                                                staticStyle: { width: "70px" },
                                                attrs: { type: "number" },
                                                domProps: {
                                                  value:
                                                    item.route
                                                      .free_delivery_from
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item.route,
                                                      "free_delivery_from",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v("Стоимость доставки")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      item.route.delivery_cost,
                                                    expression:
                                                      "item.route.delivery_cost"
                                                  }
                                                ],
                                                staticClass:
                                                  "without-arrow text-center",
                                                staticStyle: { width: "70px" },
                                                attrs: { type: "number" },
                                                domProps: {
                                                  value:
                                                    item.route.delivery_cost
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item.route,
                                                      "delivery_cost",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v("Отказ от доставки до")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      item.route
                                                        .refusal_delivery_to,
                                                    expression:
                                                      "item.route.refusal_delivery_to"
                                                  }
                                                ],
                                                staticClass:
                                                  "without-arrow text-center",
                                                staticStyle: { width: "70px" },
                                                attrs: { type: "number" },
                                                domProps: {
                                                  value:
                                                    item.route
                                                      .refusal_delivery_to
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item.route,
                                                      "refusal_delivery_to",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ])
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", { attrs: { colspan: "4" } }, [
                            _c("br"),
                            _c("br"),
                            _c("br")
                          ])
                        ])
                      ],
                      2
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.items.length === 0,
                expression: "items.length === 0"
              }
            ],
            staticClass: "list-empty",
            style: {
              height: _vm.$window.height - 56 + "px",
              flexDirection: "column"
            }
          },
          [_c("div", [_vm._v("Ничего не найдено")])]
        )
      ]),
      _vm._v(" "),
      _c("route-view-modal", { ref: "routeViewModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-244928cf", { render: render, staticRenderFns: staticRenderFns })
  }
}