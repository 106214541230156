var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Отчёт по запросам")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isGenerated,
                    expression: "isGenerated"
                  }
                ],
                on: { click: _vm.reset }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("v-ons-card", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isGenerated,
                    expression: "!isGenerated"
                  }
                ]
              },
              [
                _c(
                  "ons-list",
                  [
                    _c("ons-list-header", [_vm._v("Период")]),
                    _vm._v(" "),
                    _c("ons-list-item", [
                      _c("div", { staticClass: "wrap xl-gutter-8" }, [
                        _c(
                          "div",
                          { staticClass: "col xl-1-2" },
                          [
                            _c("v-input-datepicker", {
                              attrs: { placeholder: "От" },
                              model: {
                                value: _vm.filters.dateFrom,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "dateFrom", $$v)
                                },
                                expression: "filters.dateFrom"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col xl-1-2" },
                          [
                            _c("v-input-datepicker", {
                              attrs: { placeholder: "До" },
                              model: {
                                value: _vm.filters.dateTo,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "dateTo", $$v)
                                },
                                expression: "filters.dateTo"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "text-align": "center"
                    }
                  },
                  [
                    _c(
                      "v-ons-button",
                      {
                        attrs: { disabled: !_vm.canGenerate },
                        on: { click: _vm.generate }
                      },
                      [
                        _vm._v(
                          "\n                        Сформировать\n                    "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isGenerated,
                    expression: "isGenerated"
                  }
                ]
              },
              [
                _c("div", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v("Список запросов, по которым ничего не найдено")
                ]),
                _vm._v(" "),
                _c("table", { staticClass: "table table-positions" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Запрос")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Кол-во строк")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Кол-во запросов")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.rowsZero.length,
                          expression: "rowsZero.length"
                        }
                      ]
                    },
                    _vm._l(_vm.rowsZero, function(row) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(row.query))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(row.found_count))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(row.query_count))])
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.rowsZero.length == 0,
                          expression: "rowsZero.length == 0"
                        }
                      ]
                    },
                    [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v("Ничего не найдено")
                        ])
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v("Список запросов, по которым найдено больше 10")
                ]),
                _vm._v(" "),
                _c("table", { staticClass: "table table-positions" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Запрос")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Кол-во строк")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Кол-во запросов")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.rowsMoreTen.length,
                          expression: "rowsMoreTen.length"
                        }
                      ]
                    },
                    _vm._l(_vm.rowsMoreTen, function(row) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(row.query))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(row.found_count))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(row.query_count))])
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.rowsMoreTen.length == 0,
                          expression: "rowsMoreTen.length == 0"
                        }
                      ]
                    },
                    [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v("Ничего не найдено")
                        ])
                      ])
                    ]
                  )
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-46927e7f", { render: render, staticRenderFns: staticRenderFns })
  }
}