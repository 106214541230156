var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ons-list-item", { on: { click: _vm.onClick } }, [
    _vm.icon
      ? _c(
          "div",
          { staticClass: "left icon" },
          [
            _c("v-ons-icon", {
              style: { color: _vm.iconColor },
              attrs: { icon: _vm.icon }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.icon
      ? _c(
          "div",
          {
            staticClass: "center",
            style: { color: _vm.disabled ? "#999999" : "#1f1f21" }
          },
          [_vm._v(_vm._s(_vm.text))]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.icon
      ? _c("span", { style: { color: _vm.disabled ? "#999999" : "#1f1f21" } }, [
          _vm._v("\n        " + _vm._s(_vm.text) + "\n    ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-41784627", { render: render, staticRenderFns: staticRenderFns })
  }
}