<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center">Управление разделами</div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content" style="padding-bottom: 160px" >
            <div v-show="categories.length > 0" >
                <v-ons-card>
                    Выберите разделы для объединения или переименования
                </v-ons-card>

                <v-ons-list>
                    <ons-list-item v-for="(category, key) in categories" :key="category.id" >
                        <label :for="'category-' + key" class="center label-reset" >
                            <span class="list-item__title" >
                                {{ category.name }}
                            </span>

                            <span class="list-item__subtitle" v-html="categoryHint(category)" ></span>
                        </label>
                        <div class="right" >
                            <v-ons-checkbox
                                v-model="checked"
                                :value="category.id"
                                :input-id="'category-' + key"
                                style="vertical-align: middle; margin: 0 12px 0 0;"
                            ></v-ons-checkbox>
                        </div>
                    </ons-list-item>
                </v-ons-list>
            </div>

            <div v-show="categories.length === 0" class="list-empty" :style="{flexFlow: 'column', height: $window.height - 56 + 'px'}" >
                <div style="margin-bottom: 10px; font-size: 15px;" >
                    Данные не обнаружены
                </div>

                <ons-button @click="$router.push('/manager/management/catalog-settings/includes')" >
                    Настройте выгрузку разделов
                </ons-button>
            </div>
        </div>

        <v-ons-fab v-show="hasCheckedOne" @click="rename" position="bottom right" title="Переименовать раздел" >
            <v-ons-icon icon="md-edit"></v-ons-icon>
        </v-ons-fab>

        <v-ons-fab v-show="hasCheckedMulty" @click="merge" position="bottom right" title="Объединить разделы" >
            <v-ons-icon icon="md-unfold-less"></v-ons-icon>
        </v-ons-fab>

        <v-ons-fab v-show="hasCheckedMerged" @click="unmerge" style="bottom: 90px;" position="bottom right" title="Разъединить разделы" >
            <v-ons-icon icon="md-unfold-more"></v-ons-icon>
        </v-ons-fab>

        <v-prompt-dialog title="Новое имя раздела" ref="prompt" />
        <v-merge-dialog ref="mergeDialog" />
    </v-ons-page>
</template>

<script>

import PromptDialog from './PromptDialog'
import MergeDialog from './MergeDialog'

export default {
    data() {
        return {
            loading: true,

            checked: [],
            categories: [],
            rules: [],
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;

            let requests = [
                this.$http.get(
                    '/api/manager/catalog-settings/categories',
                    {params: {storageId: this.$route.params.storage_id}}
                ),
            ];

            Promise.all(requests).then(responses => {
                this.categories = responses[0].data;

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        rename() {
            let category = this.checkedCategory;
            let originalTitle = '';

            if (category.includes.length === 1 && category.includes[0].name !== category.name) {
                originalTitle = category.includes[0].name;
            }

            this.$refs.prompt.show({value: category.name, originalTitle: originalTitle}).then(response => {
                this.$http.post(
                    '/api/manager/catalog-settings/rename',
                    {id: category.id, name: response},
                    {params: {storageId: this.$route.params.storage_id}}
            ).then(() => {
                    this.checked = [];
                    this.load();
                    this.$bus.$emit('checkErrors');
                }, () => {
                    this.$ons.notification.toast({
                        buttonLabel: 'OK',
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        merge() {
            let checked = this.categories.filter(i => this.checked.includes(i.id.toString()));

            this.$refs.mergeDialog.show(checked).then(name => {
                this.$http.post(
                    '/api/manager/catalog-settings/merge',
                    {ids: this.checked, name: name},
                    {params: {storageId: this.$route.params.storage_id}}
            ).then(() => {
                    this.checked = [];
                    this.load();
                    this.$bus.$emit('checkErrors');
                }, () => {
                    this.$ons.notification.toast({
                        buttonLabel: 'OK',
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        unmerge() {
            this.$ons.notification.confirm('Разъединить разделы?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response !== 0) {
                    return;
                }

                this.$http.post(
                    '/api/manager/catalog-settings/unmerge',
                    {id: this.checked[0]},
                    {params: {storageId: this.$route.params.storage_id}}
            ).then(() => {
                    this.checked = [];
                    this.load();
                    this.$bus.$emit('checkErrors');
                }, () => {
                    this.$ons.notification.toast({
                        buttonLabel: 'OK',
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        categoryHint(category) {
            let includes = category.includes;

            if (category.isDeleted) {
                return '<span style="color: red;" >Раздел не найден</span>';
            }

            if (includes.length === 1 && includes[0].name.trim() === category.name.trim()) {
                if (includes[0].is_broken) {
                    return '<span style="color: red;" >Раздел не найден</span>';
                }
                return;
            }

            return includes.map(i => {
                let name = i.name || 'Неизвестно'

                if (!i.is_broken) {
                    return name;
                }

                return '<span style="color: red;" >' + name + ' (раздел не найден)</span>';
            }).join(', ');
        },
    },
    computed: {
        hasCheckedOne() {
            return this.checked.length === 1;
        },
        hasCheckedMulty() {
            return this.checked.length > 1;
        },
        hasCheckedMerged() {
            if (this.checked.length !== 1) {
                return false;
            }

            let category = this.categories.find(i => i.id == this.checked[0]);

            if (!category || !category.includes) {
                return false;
            }

            return category.includes.length > 1;
        },
        checkedCategory() {
            if (this.checked.length !== 1) {
                return;
            }

            return this.categories.find(c => c.id.toString() == this.checked[0]);
        },
    },
    components: {
        'v-prompt-dialog': PromptDialog,
        'v-merge-dialog': MergeDialog,
    },
};
</script>