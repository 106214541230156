var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Склады")])
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.storages.length > 0,
              expression: "storages.length > 0"
            }
          ]
        },
        _vm._l(_vm.storages, function(storage) {
          return _c(
            "v-ons-list-item",
            {
              key: storage.id,
              style: { color: storage.is_active ? "" : "#ccc" },
              attrs: { modifier: "chevron longdivider" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "center cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        "/profile/manager-storages/" + storage.id
                      )
                    }
                  }
                },
                [
                  _c("span", { staticClass: "list-item__title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(storage.real_name) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: storage.is_default,
                          expression: "storage.is_default"
                        }
                      ],
                      staticClass: "list-item__subtitle"
                    },
                    [
                      _vm._v(
                        "\n                    По умолчанию\n                "
                      )
                    ]
                  )
                ]
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.storages.length,
              expression: "!storages.length"
            }
          ]
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "20px",
                color: "#a8a8a8",
                position: "absolute",
                top: "50%",
                "text-align": "center",
                width: "100%",
                "line-height": "50px",
                "margin-top": "-25px"
              }
            },
            [_vm._v("\n            Список пуст"), _c("br")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e7da69d6", { render: render, staticRenderFns: staticRenderFns })
  }
}