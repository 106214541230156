<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack" ></v-ons-back-button>
            </div>
            <div v-if="id" class="center">Юр. лицо: {{ entity.legal_name }}</div>
            <div v-if="!id" class="center">Создать юр. лицо</div>

            <div class="right">
                <v-ons-toolbar-button @click="remove" v-if="id" title="Удалить юр. лицо" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" title="Сохранить" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-header>Юридическое наименование</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.legal_name" name="legal_name" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Юридический адрес</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.legal_address" name="legal_address" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>ИНН</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.inn" type="number" name="inn" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>КПП</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.kpp" type="number" name="kpp" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>ОГРН</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.ogrn" type="number" name="ogrn" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Дата ОГРН</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.ogrn_created_at" name="ogrn_created_at" ></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            changed: false,
            loaded: false,

            id: null,
            entity: {},
        }
    },
    created() {
        this.id = this.$route.params.entity_id;
        this.load();
    },
    methods: {
        load() {
            if (!this.id) {
                this.$nextTick(() => {
                    this.loaded = true;
                });
                return;
            }

            this.$http.get('/api/settings/entities/' + this.id).then(response => {
                this.entity = response.data;

                this.$nextTick(() => {
                    this.loaded = true;
                });
            });
        },
        save() {
            let promise;
            if (this.id) {
                promise = this.$http.post('/api/settings/entities/' + this.id, this.entity);
            } else {
                promise = this.$http.post('/api/settings/entities', this.entity);
            }

            promise.then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Юр. лицо сохранено',
                    buttonLabel: 'OK',
                });
                this.$bus.$emit('load');
                this.$bus.$emit('entities-load');
                this.$router.go(-1);
            }, (response) => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                });
            });
        },
        remove() {
            this.$ons.notification.confirm('Удалить юр. лицо?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.delete('/api/settings/entities/' + this.id).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Юр. лицо удалено',
                        buttonLabel: 'OK',
                    });
                    this.$bus.$emit('load');
                    this.$router.go(-1);
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
    },
    watch: {
        entity: {
            handler() {
                if (!this.loaded) {
                    return;
                }
                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>
