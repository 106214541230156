var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isSearchActive,
                  expression: "!isSearchActive"
                }
              ]
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.product.name) +
                  "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSearchActive,
                  expression: "isSearchActive"
                }
              ],
              staticClass: "search-wrap"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchQuery,
                    expression: "searchQuery"
                  }
                ],
                staticStyle: {
                  color: "#fff",
                  background: "none",
                  border: "none",
                  height: "34px",
                  "line-height": "34px",
                  "border-bottom": "1px solid #fff"
                },
                attrs: { id: "search-input" },
                domProps: { value: _vm.searchQuery },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchQuery = $event.target.value
                  }
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isSearchActive,
                    expression: "!isSearchActive"
                  }
                ],
                on: { click: _vm.searchActive }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-search" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSearchActive,
                    expression: "isSearchActive"
                  }
                ],
                on: { click: _vm.searchDeactivate }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.items.length > 0
          ? _c(
              "div",
              [
                _c(
                  "v-ons-list",
                  [
                    _c("v-ons-lazy-repeat", {
                      ref: "lazyRepeat",
                      attrs: {
                        "render-item": _vm.renderItem,
                        length: _vm.items.length
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.items.length === 0,
                expression: "items.length === 0"
              }
            ],
            staticClass: "list-empty",
            style: {
              height: _vm.$window.height - 56 + "px",
              flexDirection: "column"
            }
          },
          [_c("div", [_vm._v("Ничего не найдено")])]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { height: "100px" } })
      ]),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "fab-text",
          attrs: { position: "bottom left" },
          on: { click: _vm.setArchive }
        },
        [_vm._v("\n        Удалить\n    ")]
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && _vm.type === "product",
              expression: "!loading && type === 'product'"
            }
          ],
          staticClass: "fab-text",
          attrs: { position: "bottom right" },
          on: { click: _vm.setAvail }
        },
        [_vm._v("\n        Ожидаем поступление\n    ")]
      ),
      _vm._v(" "),
      _c("v-confirm-relink-product-dialog", {
        ref: "confirmRelinkProductDialog"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6718cb86", { render: render, staticRenderFns: staticRenderFns })
  }
}