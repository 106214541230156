import Index from '~/screen/manager/import/Index'

let route = [
    {
        path: 'import',
        name: 'manager-import',
        component: Index,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
    },
]

export default route;

