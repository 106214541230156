var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Приглашение")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loaded,
                expression: "!loaded"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loaded,
              expression: "loaded"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "content", staticStyle: { color: "#1f1f21" } },
            [
              _c("div", [
                _vm._v(
                  "Вас приглашают стать сотрудником на платформе онлайн закупок prod-zakaz.ru"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v("Контакты руководителя:")
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.director.name))]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.director.email))]),
              _vm._v(" "),
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "17px", margin: "20px 0 15px" }
                  },
                  [_vm._v("Стать сотрудником?")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "wrap",
                    staticStyle: { "text-align": "center" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { margin: "0 10px" } },
                      [
                        _c("v-ons-button", { on: { click: _vm.accept } }, [
                          _vm._v("Да")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col", staticStyle: { margin: "0 10px" } },
                      [
                        _c("v-ons-button", { on: { click: _vm.reject } }, [
                          _vm._v("Нет")
                        ])
                      ],
                      1
                    )
                  ]
                )
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-dfb52a82", { render: render, staticRenderFns: staticRenderFns })
  }
}