var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { cancelable: "", visible: _vm.isVisible },
      on: {
        "update:visible": function($event) {
          _vm.isVisible = $event
        }
      }
    },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "center" }, [_vm._v("Группировать по:")])
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c(
            "v-ons-list-item",
            {
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.choose("byCatalogGroup")
                }
              }
            },
            [_vm._v("Разделам прайса")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.choose("byUserGroup")
                }
              }
            },
            [_vm._v("Группам товара пользователя")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isGrouped,
                  expression: "isGrouped"
                }
              ],
              attrs: { tappable: "" },
              on: {
                click: function($event) {
                  return _vm.choose(null)
                }
              }
            },
            [_vm._v("Отменить группировку")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-642a4bf4", { render: render, staticRenderFns: staticRenderFns })
  }
}