<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Вход в приложение</div>
        </v-ons-toolbar>

        <div class="login-page" >
            <form class="form" @submit.prevent="submit" >
                <div class="form-group" >
                    <v-ons-input v-model="email" autocomplete="off" name="email" type="text" placeholder="Email" />
                </div>
                <div class="form-group" >
                    <v-ons-password v-model="password" name="password" placeholder="Пароль" />
                </div>
                <div class="form-submit" >
                    <v-ons-button @click="submit" >Войти</v-ons-button>
                    <button type="submit" style="background: none; width: 0; height: 0; padding: 0; border: 0;" ></button>
                </div>

                <div style="margin-top: 40px; text-align: center;" >
                    <p>Первый раз здесь?</p>
                    <v-ons-button @click="$router.push({name: 'register', params: {email: email, password: password}})" >Создать аккаунт</v-ons-button>
                </div>

                <div style="margin-top: 40px; text-align: center;" >
                    <v-ons-button @click="$router.push({name: 'forgot', params: {email: email}})" modifier="outline" >Восстановить пароль</v-ons-button>
                </div>
            </form>
        </div>

        <v-ons-dialog :visible.sync="loading" >
            <v-ons-row vertical-align="center" >
                <v-ons-col width="70px" style="text-align: center"  >
                    <v-ons-progress-circular indeterminate></v-ons-progress-circular>
                </v-ons-col>

                <v-ons-col>
                    <div style="line-height: 100px" >Авторизация...</div>
                </v-ons-col>
            </v-ons-row>
        </v-ons-dialog>
    </v-ons-page>
</template>

<script>

import PasswordInput from '~/component/PasswordInput'

export default {
    data() {
        return {
            loading: false,
            email: null,
            password: null,
        }
    },
    methods: {
        submit() {
            if (this.loading) {
                return;
            }

            if (!this.email) {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Укажите логин',
                });
                return;
            }

            if (!this.password) {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Укажите пароль',
                });
                return;
            }

            this.loading = true;

            this.$http.post('/api/auth/sign-in', {email: this.email, password: this.password}).then(response => {
                this.loading = false;
                this.$user.setToken(response.data.token);

                this.$bus.$emit('authorized');
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/');
                }
            }, response => {
                this.loading = false;

                if (response.status == 401) {
                    this.$ons.notification.toast({
                        timeout: 3000,
                        message: response.data.message,
                    });
                    return;
                }

                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Произошла ошибка',
                });
            });
        }
    },
    components: {
        'v-ons-password': PasswordInput,
    },
};
</script>
