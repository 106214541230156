var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasChecked,
              expression: "!hasChecked"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [_c("v-ons-back-button", { on: { click: _vm.onBack } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v(_vm._s(_vm.template.name))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { disabled: _vm.templates.length === 0 },
                  on: {
                    click: function($event) {
                      return _vm.$refs.assignTemplateModal.show()
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-collection-item" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: {
                    title: "Создать заказ",
                    disabled: !_vm.hasPositions
                  },
                  on: { click: _vm.createOrder }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-shopping-cart" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  staticStyle: { "font-size": "28px" },
                  attrs: { disabled: !_vm.hasPositions },
                  on: { click: _vm.openCatalog }
                },
                [_c("span", { staticClass: "font-rouble" }, [_vm._v("o")])]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChecked,
              expression: "hasChecked"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.uncheckAll } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v("Выбрано: " + _vm._s(_vm.checkedCount))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.copyToTemplateModal.show()
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-copy" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.moveToTemplateModal.show()
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-replay" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.removeChecked } },
                [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("assign-template-modal", {
            ref: "assignTemplateModal",
            attrs: {
              title: "Добавить из шаблона",
              url: _vm.entrypoint + "/templates"
            },
            on: { choose: _vm.onBindTemplate }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "user-product-page",
              staticStyle: { position: "relative" },
              style: { "padding-bottom": _vm.paddingBottom }
            },
            [
              _c("div", { staticClass: "positions" }, [
                _c(
                  "div",
                  { staticClass: "tbody" },
                  [
                    _vm._l(_vm.positionsLocal, function(position) {
                      return _c("v-position", {
                        key: position.posId,
                        attrs: {
                          id:
                            "template-" +
                            _vm.template.id +
                            "-position-" +
                            position.pos_id,
                          position: position
                        },
                        on: {
                          remove: _vm.onRemovePosition,
                          update: _vm.onUpdatePosition
                        }
                      })
                    }),
                    _vm._v(" "),
                    _c("input-proposal-create", {
                      ref: "inputProposal",
                      attrs: {
                        scrollPageOnFocus: true,
                        disableScrollPage: true,
                        addedPositions: _vm.addedPositions,
                        urlSearch: _vm.urlSearch,
                        columns: _vm.$user.canSeePrice
                          ? ["name", "price"]
                          : ["name"]
                      },
                      on: { choose: _vm.chooseProposal }
                    })
                  ],
                  2
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("templates-modal", {
        ref: "moveToTemplateModal",
        attrs: { title: "Переместить в шаблон" },
        on: { choose: _vm.moveToTemplate }
      }),
      _vm._v(" "),
      _c("templates-modal", {
        ref: "copyToTemplateModal",
        attrs: { title: "Скопировать в шаблон" },
        on: { choose: _vm.copyToTemplate }
      }),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCheckedOne,
              expression: "hasCheckedOne"
            }
          ],
          staticStyle: { bottom: "90px" },
          attrs: { position: "bottom left" },
          on: { click: _vm.orderUp }
        },
        [_c("v-ons-icon", { attrs: { icon: "long-arrow-up" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCheckedOne,
              expression: "hasCheckedOne"
            }
          ],
          attrs: { position: "bottom left" },
          on: { click: _vm.orderDown }
        },
        [_c("v-ons-icon", { attrs: { icon: "long-arrow-down" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-372038e4", { render: render, staticRenderFns: staticRenderFns })
  }
}