<template>
    <v-ons-page>
        <v-ons-toolbar inline="true">
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center">Нет фото</div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <ons-list-item v-show="storages.length > 1" modifier="chevron longdivider" @click="showChooseStorage">
                <span class="list-item__title">Склад: {{ selectedStorageName }}</span>
                <span v-if="selectedStorage && !selectedStorage.is_configured" style="color: red" class="list-item__subtitle">
                    Не настроено
                </span>
            </ons-list-item>

            <v-ons-list v-if="items.length > 0" id="list-catalog" class="list-catalog">
                <div v-for="(item, index) in items" :key="index" >
                    <v-ons-list-item class="list-item-category" v-if="item.type === 'category'">
                        {{ item.name }}
                    </v-ons-list-item>

                    <v-ons-list-item class="list-item-product" v-else>
                        <div class="col-name cursor-pointer" @click="$router.push($router.currentRoute.path + '/' + item.id)">
                            <div class="name" >
                                {{ item.name }}
                            </div>
                        </div>
                    </v-ons-list-item>
                </div>
            </v-ons-list>

            <div v-show="items.length === 0">
                <p style="line-height: 216px; text-align: center; font-size: 20px; color: #a8a8a8;">Список пуст</p>
            </div>

            <v-list-dialog ref="chooseStorageDialog" title="Выберите склад" :items="storages" />
        </div>
    </v-ons-page>
</template>

<script>

import ListDialog from "~/component/ListDialog";

export default {
    data() {
        return {
            choseStorage: null,
            storages: [],
            loading: true,
            items: [],
        }
    },
    created() {
        this.choseStorage = this.$user.selectedStorage;
        this.loadStorages();
        this.load();
        this.$bus.$on('catalog-update-product', this.load);
    },
    destroyed() {
        this.$bus.$off('catalog-update-product', this.load);
    },
    methods: {
        load() {
            this.loading = true;
            this.items = [];
            this.$http.get(
                '/api/manager/reports/no-photos',
                {params: {storageId: this.choseStorage}}
            ).then(response => {
                this.items = response.data;
                this.loading = false;
            });
        },
        loadStorages() {
            this.$http.get(
                '/api/manager/storages',
                {params: {withConfigured: true}}
            ).then(response => {
                this.storages = response.data;

                if (!this.choseStorage) {
                    this.choseStorage = this.storages.find(s => s.is_default).id;
                    this.$user.selectedStorage = this.choseStorage;
                }

                if (!this.choseStorage) {
                    this.choseStorage = this.storages[0].id;
                    this.$user.selectedStorage = this.choseStorage;
                }
            });
        },
        showChooseStorage() {
            this.$refs.chooseStorageDialog.show().then(response => {
                this.choseStorage = response;
                this.$user.selectedStorage = this.choseStorage;
                this.load();
            });
        },
    },
    computed: {
        selectedStorage() {
            return this.storages.find(s => s.id === parseInt(this.choseStorage));
        },
        selectedStorageName() {
            let storage = this.storages.find(s => s.id === parseInt(this.choseStorage));

            if (!storage) {
                return 'Неизвестно';
            }

            return storage.name;
        },
    },
    components: {
        'v-list-dialog': ListDialog,
    },
};
</script>