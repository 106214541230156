<template>
    <v-ons-alert-dialog modifier="rowfooter" :visible.sync="visible" class="change-password-modal" >
        <span slot="title">Новый пароль</span>

        <v-ons-input v-model="password" />

        <template slot="footer">
            <v-ons-alert-dialog-button @click="ok">Изменить</v-ons-alert-dialog-button>
            <v-ons-alert-dialog-button @click="cancel">Отмена</v-ons-alert-dialog-button>
        </template>
    </v-ons-alert-dialog>
</template>

<script>

import PasswordInput from '~/component/PasswordInput'

export default {
    data() {
        return {
            visible: false,
            password: '',

            resolve: null,
            reject: null,
        }
    },
    methods: {
        show() {
            this.visible = true;
            this.password = '';

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;

            if (this.resolve) {
                this.resolve({response: false});
            }
        },
        ok() {
            if (this.password.length > 0 && this.password.length < 6) {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Пароль должен быть больше 6 символов',
                    buttonLabel: 'OK'
                });
                return;
            }

            this.visible = false;

            if (this.resolve) {
                this.resolve({response: true, password: this.password});
            }
        },
    },
    components: {
        'v-ons-password': PasswordInput,
    },
};
</script>
