<template>
    <v-ons-page class="order-index" >
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Заказы: {{ user.email }}</div>

            <div class="right" >
                <v-ons-toolbar-button  @click="showViewParamsModal" title="Опции просмотра" >
                    <v-ons-icon icon="md-format-list-bulleted"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button  @click="$emit('update')" title="Обновить" >
                    <v-ons-icon icon="md-refresh"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item text="Опции просмотра" icon="md-format-list-bulleted" @click="showViewParamsModal" />

                    <ons-list-header>Отображать заказы:</ons-list-header>

                    <ons-list-item @click="setFilterStatus('all')" >
                        <div class="left icon" >
                            <v-ons-icon v-show="filters.status === 'all'" icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" >Все</div>
                    </ons-list-item>

                    <ons-list-item @click="setFilterStatus('sended')" >
                        <div class="left icon" >
                            <v-ons-icon v-show="filters.status === 'sended'" icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" >Новые</div>
                    </ons-list-item>

                    <ons-list-item @click="setFilterStatus('manager_draft')" >
                        <div class="left icon" >
                            <v-ons-icon v-show="filters.status === 'manager_draft'" icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" >Черновики менеджера</div>
                    </ons-list-item>

                    <ons-list-item @click="setFilterStatus('draft')" >
                        <div class="left icon" >
                            <v-ons-icon v-show="filters.status === 'draft'" icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" >Черновики клиента</div>
                    </ons-list-item>
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-if="user.discounts && user.discounts.orderAmountMoreMonth" >
                <v-ons-card v-show="user.discounts.orderAmountMoreMonth.enabled" >
                    <span v-show="user.discounts.orderAmountMoreMonth.has" >
                        Дополнительная скидка составляет {{ user.discounts.orderAmountMoreMonth.percent }}%
                    </span>

                    <span v-show="!user.discounts.orderAmountMoreMonth.has" >
                        Сумма заказов за 30 дней - {{ user.discounts.orderAmountMoreMonth.monthAmount }} р. Для получения скидки {{ user.discounts.orderAmountMoreMonth.percent }}%, клиенту нужно выбрать товара всего на {{ user.discounts.orderAmountMoreMonth.restSum }} р.
                    </span>
                </v-ons-card>

                <v-ons-card v-show="user.discounts.orderAmountMore.enabled" >
                    Клиент может получить скидку {{ user.discounts.orderAmountMore.percent }}% сразу на текущий заказ от суммы {{ user.discounts.orderAmountMore.amount }} р.
                </v-ons-card>
            </div>

            <ons-list v-show="orders" >
                <ons-list-item v-for="order in orders" :key="order.id" modifier="chevron longdivider" class="order-item" >
                    <div class="left" style="min-width: 56px; padding-left: 16px; text-align: center;">
                        <v-ons-icon
                            :icon="orderStatus(order).icon"
                            :size="orderStatus(order).size ? orderStatus(order).size : ''"
                            :title="orderStatus(order).title"
                            class="list-item__icon"
                        />
                    </div>
                    <div class="center cursor-pointer" @click="$router.push('orders/' + order.id)" >
                        <span class="list-item__title" >
                            {{ orderTitle(order) }}
                        </span>
                        <span class="list-item__subtitle" >
                            Статус: {{ order.status }}
                        </span>

                        <v-list-item-subtitles :item="order" :fields="$user.getOrderSubtitles()" :selected="$user.orderSelectedSubtitles" />
                    </div>
                </ons-list-item>
            </ons-list>

            <div style="text-align: center; margin: 10px 0;" v-show="page < pageCount" >
                <v-ons-button @click="loadMore" style="min-width: 145px;" >
                    <span v-show="!loadingMore" >Показать ещё</span>
                    <v-ons-icon v-show="loadingMore" size="30px" spin icon="md-spinner"></v-ons-icon>
                </v-ons-button>
            </div>

            <div v-show="orders.length === 0" class="list-empty" :style="{height: $window.height - 56 + 'px'}" >
                Еще нет ни одного заказа
            </div>
        </div>

        <v-list-dialog ref="viewParamsModal" multiple="true" title="Опции просмотра" />

        <v-ons-fab v-show="user.manager_id == $user.id" position="bottom right" @click="createOrder" title="Создать заказ" >
              <v-ons-icon v-show="!creatingOrder" icon="md-plus"></v-ons-icon>
              <v-ons-icon v-show="creatingOrder " size="30px" spin icon="md-spinner"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

import ListDialog from '~/component/ListDialog'
import ListItemSubtitles from '~/component/ListItemSubtitles'

import {from, merge} from 'rxjs'
import {map, switchMap, tap} from 'rxjs/operators'

export default {
    data() {
        return {
            userId: null,
            user: {},
            orders: [],
            page: 1,
            pageCount: null,
            loading: false,
            loadingMore: false,
            creatingOrder: false,
            filters: {
                q: '',
                status: 'all',
            },
        }
    },
    subscriptions () {
        const loadMore$ = this.$watchAsObservable('page').pipe(
            tap(() => this.loadingMore = true),
        )

        const filterWatch$ = this.$eventToObservable('orders-filter').pipe(
            tap(() => {
                this.loading = true;
                this.orders = [];
                this.page = 1;
            })
        )

        const init$ = from([1]).pipe(
            tap(() => this.loading = true),
        )

        const update$ = this.$eventToObservable('update').pipe(
            tap(() => {
                this.loading = true;
                this.orders = [];
                this.page = 1;
            })
        )

        return {
            results: merge(loadMore$, update$, filterWatch$, init$).pipe(
                switchMap(() => from(
                    this.$http.get(
                        '/api/manager/users/' + this.$route.params.user_id + '/orders',
                        {params: {filters: this.filters, page: this.page}}
                    )
                )),
                map(response => {
                    let orders = response.data.items;
                    this.orders = this.orders.concat(orders);
                    this.pageCount = response.data.pageCount;
                }),
                tap(() => {
                    this.loadingMore = false;
                    this.loading = false;
                }),
            )
        }
    },
    created() {
        this.userId = this.$route.params.user_id;

        this.load();

        this.$bus.$on('orders-update', () => {
            this.$emit('update');
            this.load();
        });
        this.$bus.$on('order-remove', id => {
            this.removeOrdersFromList([id]);
        });
        this.$bus.$on('order-update', id => {
            this.updateOrder(id);
        });
        this.$on('update', this.onUpdate);
    },
    destroyed() {
        this.$bus.$off('orders-update');
        this.$bus.$off('order-update');
        this.$bus.$off('order-remove');
        this.$off('update', this.onUpdate);
    },
    methods: {
        load() {
            this.$http.get('/api/manager/users/' + this.userId).then(response => {
                this.user = response.data;
            });
        },
        loadMore() {
            if (this.page != null && this.pageCount != null && this.page > this.pageCount) {
                return;
            }

            if (this.loadingMore) {
                return;
            }

            this.page += 1;
        },
        updateOrder(id) {
            let index = this.orders.findIndex(i => i.id == id);

            if (index === -1) {
                this.$emit('update');
                this.load();
                return;
            }

            this.$http.get('/api/manager/orders/' + id).then(response => {
                this.$set(this.orders, index, response.data.order)
            }, response => {
                if (response.status === 404) {
                    this.removeOrdersFromList([id]);
                }
            });
        },
        removeOrdersFromList(ids) {
            this.orders = this.orders.filter(i => !ids.includes(i.id));
        },
        orderTitle(order) {
            let title = `Заказ №${order.id}`;

            if (order.status_id !== 'draft') {
                title += ` от ${order.createdAtFormat}`;
            }

            title += " на сумму ";
            title += order.isNearlyCost ? "~" : '';
            title += order.hasDiscount ? order.totalCostWithDiscount : order.totalCost;
            title += ' руб.';
            return title;
        },
        orderStatus(order) {
            if (order.status_id === 'manager_draft') {
                return {
                    icon: 'md-border-color',
                    title: 'Черновик менеджера',
                    size: '13px',
                };
            }

            if (order.status_id === 'manager_editing') {
                return {
                    icon: 'md-border-color',
                    title: 'Редактирование',
                    size: '13px',
                };
            }

            if (order.status_id === 'confirmed') {
                return {
                    icon: 'md-check',
                    title: 'Заказ подтверждён',
                };
            }

            if (order.status_id === 'generated' && !this.$user.isWorker) {
                return {
                    icon: 'md-comment-list',
                    title: 'Сформирован сотрудником',
                };
            }

            if (order.status_id === 'draft') {
                return {
                    icon: 'ion-ios-list',
                    title: 'Черновик заказа',
                };
            }

            if (order.status_id === 'sended' && order.worker) {
                return {
                    icon: 'ion-reply-all',
                    title: 'Заказ отправлен',
                };
            }

            if (order.status_id === 'sended') {
                return {
                    icon: 'ion-reply',
                    title: 'Заказ отправлен',
                };
            }

            if (order.status_id === 'generated' && this.$user.isWorker) {
                return {
                    icon: 'ion-reply',
                    title: 'Заказ отправлен',
                };
            }

            return {
                icon: '',
                title: '',
            };
        },
        setFilterStatus(status) {
            this.filters.status = status;
            this.$emit('orders-filter');
        },
        showViewParamsModal() {
            let items = this.$user.getOrderSubtitles().map(i => {
                return {
                    id: i.name,
                    name: i.label,
                }
            });

            this.$refs.viewParamsModal.show({items: items, selected: this.$user.orderSelectedSubtitles}).then(selected => {
                this.$user.setOrderSelectedSubtitles(selected);
            });
        },
        onUpdate() {
            this.load();
        },
        createOrder() {
            if (this.creatingOrder) {
                return false;
            }

            this.creatingOrder = true;

            this.$http.put(`/api/manager/orders`, {userId: this.userId}).then(response => {
                this.$emit('orders-update');
                this.$router.push('orders/' + response.data.id);
                this.creatingOrder = false;
            }, () => {
                this.creatingOrder = false;
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
    components: {
        'v-list-dialog': ListDialog,
        'v-list-item-subtitles': ListItemSubtitles,
    },
};
</script>