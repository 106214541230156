var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Получить скидку")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [
                _vm.sending
                  ? _c("v-ons-icon", {
                      attrs: { icon: "md-spinner", spin: "true" }
                    })
                  : _c("span", { staticStyle: { "font-size": "15px" } }, [
                      _vm._v("Отправить")
                    ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.$user.discounts.getDiscount.enabled &&
                !_vm.$user.params.contacts_sended,
              expression:
                "$user.discounts.getDiscount.enabled && !$user.params.contacts_sended"
            }
          ]
        },
        [
          _vm._v(
            "\n        Для получения скидки, вам нужно отправить ваши контакты и реквизиты. Ваш менеджер проверит данные и, если это возможно, активирует скидку (в некоторых случаях требуется заключение договора)\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$user.discounts.getDiscount.enabled,
              expression: "$user.discounts.getDiscount.enabled"
            }
          ]
        },
        [
          _vm._v("\n        Если у вас возникли любые вопросы, "),
          _c("router-link", { attrs: { to: "/mymanager" } }, [
            _vm._v("свяжитесь с менеджером.")
          ]),
          _vm._v(
            " Для оперативного ответа пишите на почту или на любой месседжер.\n    "
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.$user.discounts.getDiscount.enabled &&
                _vm.$user.params.contacts_sended,
              expression:
                "$user.discounts.getDiscount.enabled && $user.params.contacts_sended"
            }
          ]
        },
        [_vm._v("\n        Ваши данные отправлены.\n    ")]
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Имя")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-header", [_vm._v("Телефон")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { type: "tel" },
                model: {
                  value: _vm.form.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-header", [
            _vm._v("Фактическое название торговой точки")
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                model: {
                  value: _vm.form.actual_organisation_name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "actual_organisation_name", $$v)
                  },
                  expression: "form.actual_organisation_name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-header", [_vm._v("Фактический адрес")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-address-form", {
                attrs: { title: "Фактический адрес" },
                model: {
                  value: _vm.form.address,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-header", [_vm._v("Юр. название")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                model: {
                  value: _vm.form.legal_organisation_name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "legal_organisation_name", $$v)
                  },
                  expression: "form.legal_organisation_name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-header", [_vm._v("ИНН")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.inn,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "inn", $$v)
                  },
                  expression: "form.inn"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6e283d96", { render: render, staticRenderFns: staticRenderFns })
  }
}