var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Сотрудник: " + _vm._s(_vm.worker.email))
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              attrs: { tappable: "", modifier: "chevron longdivider" }
            },
            [_vm._v("\n            Изменить\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              attrs: { tappable: "", modifier: "chevron longdivider" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: "profile-worker-permissions"
                  })
                }
              }
            },
            [_vm._v("\n            Права доступа\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              attrs: { tappable: "", modifier: "chevron longdivider" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "profile-worker-rules" })
                }
              }
            },
            [_vm._v("\n            Правила работы\n        ")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            { attrs: { modifier: "longdivider" }, on: { click: _vm.dismiss } },
            [_vm._v("\n            Уволить\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-beac9c44", { render: render, staticRenderFns: staticRenderFns })
  }
}