<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>
            <div class="center">Юридические лица: {{ user.email }}</div>
        </v-ons-toolbar>

        <v-ons-list v-show="entities.length > 0" >
            <v-ons-list-item v-for="entity in entities" :key="entity.id" modifier="chevron longdivider" >
                <div class="center cursor-pointer" @click="$router.push({name: 'manager-user-entity', params: {entity_id: entity.id}})" >
                    <span class="list-item__title" >
                        {{ entity.legal_name }}
                    </span>
                    <span v-show="!entity.hasBranch" class="list-item__subtitle list-item__subtitle__warning" >
                        Не привязан филиал
                    </span>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <div v-show="!entities.length" class="" >
            <div style="font-size: 20px; color: #a8a8a8; position: absolute; top: 50%; text-align: center; width: 100%; line-height: 50px; margin-top: -25px;" >
                Список пуст<br>
                <v-ons-button @click="$router.push({name: 'manager-user-entity-create'})" >Добавить юр. лицо</v-ons-button>
            </div>
        </div>

        <v-ons-fab v-show="entities.length" position="bottom right" @click="$router.push({name: 'manager-user-entity-create'})" title="Создать новую точку доставки" >
            <v-ons-icon icon="md-plus"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>
    export default {
        data() {
            return {
                user: {},
                entities: [],
            }
        },
        created() {
            this.$http.get('/api/manager/users/' + this.$route.params.user_id).then(response => {
                this.user = response.data;
            });

            this.load();
            this.$bus.$on('load', this.load);
        },
        destroyed() {
            this.$bus.$off('load');
        },
        methods: {
            load() {
                this.$http.get('/api/manager/users/'+ this.$route.params.user_id  +'/entities').then(response => {
                    this.entities = response.data;
                });
            },
        },
    };
</script>
