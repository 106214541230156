<template>
    <users-list
        title="Выберите клиента"
        url="/api/manager/orders/users"
        showBackButton="true"
        @choose="choose"
    />
</template>

<script>

import UsersList from '../component/user/UsersList'

export default {
    data() {
        return {
            creatingOrder: false,
        }
    },
    methods: {
        choose(user) {
            if (this.creatingOrder) {
                return
            }

            this.$http.put(
                `/api/manager/orders`,
                {userId: user.id}
            ).then(response => {
                this.$router.push({
                    name: 'manager-create-order',
                    params: {user_id: user.id, order_id: response.data.id}
                })
            }, () => {
                this.creatingOrder = false;
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });

        }
    },
    components: {
        UsersList,
    }
};
</script>