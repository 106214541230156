var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { cancelable: "", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "center" }, [_vm._v("Настройки прайса")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading"
              }
            ],
            staticClass: "right"
          },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.ok } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            staticStyle: { top: "56px", bottom: "0" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c(
                "v-ons-list-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.storages.length > 1,
                      expression: "storages.length > 1"
                    }
                  ]
                },
                [
                  _c("div", { staticClass: "center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "select-material select--material select"
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedStorage,
                                expression: "selectedStorage"
                              }
                            ],
                            staticClass: "select-input select-input--material",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedStorage = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          _vm._l(_vm.storages, function(storage) {
                            return _c(
                              "option",
                              {
                                key: storage.id,
                                attrs: { disabled: !storage.is_configured },
                                domProps: { value: storage.id }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(storage.name) +
                                    "\n                                " +
                                    _vm._s(
                                      !storage.is_configured
                                        ? "(не настроено)"
                                        : ""
                                    ) +
                                    "\n                            "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Типы цен")]),
              _vm._v(" "),
              _vm._l(_vm.priceTypes, function(type, $index) {
                return _c("v-ons-list-item", { key: $index }, [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("v-ons-radio", {
                        attrs: {
                          "input-id": "price-radio-" + $index,
                          value: type.id
                        },
                        model: {
                          value: _vm.selectedPriceType,
                          callback: function($$v) {
                            _vm.selectedPriceType = $$v
                          },
                          expression: "selectedPriceType"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "center label-reset",
                      attrs: { for: "price-radio-" + $index }
                    },
                    [_vm._v(_vm._s(type.name))]
                  )
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-item", [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("v-ons-checkbox", {
                      attrs: { "input-id": "show-catalog-rest" },
                      model: {
                        value: _vm.showCatalogRest,
                        callback: function($$v) {
                          _vm.showCatalogRest = $$v
                        },
                        expression: "showCatalogRest"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "show-catalog-rest" }
                  },
                  [_vm._v("Отображать остатки")]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-item", [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("v-ons-checkbox", {
                      attrs: { "input-id": "show-special-products" },
                      model: {
                        value: _vm.showSpecialProducts,
                        callback: function($$v) {
                          _vm.showSpecialProducts = $$v
                        },
                        expression: "showSpecialProducts"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "show-special-products" }
                  },
                  [_vm._v("Отображать спец. товары")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6aaf4a49", { render: render, staticRenderFns: staticRenderFns })
  }
}