<template>
    <catalog-page
        orderEntrypoint="/api/manager/orders"
        :catalogEntrypoint="'/api/manager/orders/' + orderId + '/catalog'"
        :templateEntrypoint="'/api/manager/users/' + userId + '/user-products/templates'"
        :toolbarButtons="toolbarButtons"
        :showBackButton="true"
        :showInputs="true"
        :allowInputs="true"
        :canOpenProductPage="true"
        :redirectToOrderWhenAddPositions="true"
        />
</template>

<script>

import CatalogPage from '~/component/CatalogPage'

export default {
    data() {
        return {
            toolbarButtons: [
                'showAssignTemplateModalButton',
            ],
            orderId: null,
            userId: null,
        }
    },
    created() {
        this.orderId = this.$route.params.order_id;
        this.userId = this.$route.params.user_id;
    },
    components: {
        CatalogPage,
    },
};
</script>