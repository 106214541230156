var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { padding: "0 16px" } }, [
    _c("table", { staticClass: "table" }, [
      _c("tr", [
        _c(
          "td",
          { staticStyle: { width: "23px" } },
          [
            _c("v-ons-checkbox", {
              attrs: { "input-id": _vm.inputId },
              model: {
                value: _vm.isEnabledValue,
                callback: function($$v) {
                  _vm.isEnabledValue = $$v
                },
                expression: "isEnabledValue"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("td", { attrs: { colspan: "2" } }, [
          _c(
            "label",
            { staticClass: "label-reset", attrs: { for: _vm.inputId } },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.title) +
                  "\n                "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "tr",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasAmount && _vm.isEnabledValue,
              expression: "hasAmount && isEnabledValue"
            }
          ]
        },
        [
          _c("td"),
          _vm._v(" "),
          _c(
            "td",
            { attrs: { colspan: "2" } },
            [
              _c("v-ons-input", {
                attrs: { type: "number", placeholder: "сумма в руб." },
                model: {
                  value: _vm.amountValue,
                  callback: function($$v) {
                    _vm.amountValue = $$v
                  },
                  expression: "amountValue"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "tr",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isEnabledValue,
              expression: "isEnabledValue"
            }
          ]
        },
        [
          _c("td"),
          _vm._v(" "),
          _c("td", [_vm._v("Для типа цен:")]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c(
                "v-ons-select",
                {
                  model: {
                    value: _vm.forPriceTypeValue,
                    callback: function($$v) {
                      _vm.forPriceTypeValue = $$v
                    },
                    expression: "forPriceTypeValue"
                  }
                },
                [
                  _c("option", { domProps: { value: "" } }, [_vm._v("-")]),
                  _vm._v(" "),
                  _vm._l(_vm.priceTypes, function(type) {
                    return _c("option", { domProps: { value: type.id } }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(type.name) +
                          "\n                    "
                      )
                    ])
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "tr",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isEnabledValue,
              expression: "isEnabledValue"
            }
          ]
        },
        [
          _c("td"),
          _vm._v(" "),
          _c("td", [_vm._v("Переход на тип цен:")]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c(
                "v-ons-select",
                {
                  model: {
                    value: _vm.gotoPriceTypeValue,
                    callback: function($$v) {
                      _vm.gotoPriceTypeValue = $$v
                    },
                    expression: "gotoPriceTypeValue"
                  }
                },
                [
                  _c("option", { domProps: { value: "" } }, [_vm._v("-")]),
                  _vm._v(" "),
                  _vm._l(_vm.priceTypes, function(type) {
                    return _c("option", { domProps: { value: type.id } }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(type.name) +
                          "\n                    "
                      )
                    ])
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "tr",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isEnabledValue,
              expression: "isEnabledValue"
            }
          ]
        },
        [
          _c("td"),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticStyle: { "padding-top": "5px" } }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.finalPercent >= 0,
                      expression: "finalPercent >= 0"
                    }
                  ]
                },
                [_vm._v("Итого скидка")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.finalPercent < 0,
                      expression: "finalPercent < 0"
                    }
                  ]
                },
                [_vm._v("Увеличение цены")]
              )
            ])
          ]),
          _vm._v(" "),
          _c("td", [
            _c("div", { staticStyle: { "padding-top": "5px" } }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.finalPercent) +
                  "\n                "
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1bbd4aa9", { render: render, staticRenderFns: staticRenderFns })
  }
}