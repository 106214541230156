<template>
    <v-ons-page>
        <v-ons-toolbar inline="true">
            <div class="left">
                <v-ons-back-button title="Назад" @click.prevent="goBack"></v-ons-back-button>
            </div>
            <div v-if="id" class="center">Юр. лицо: {{ entity.alias }}</div>
            <div v-if="!id" class="center">Создать юр. лицо</div>

            <div class="right">
                <v-ons-toolbar-button @click="remove" v-if="id" title="Удалить филиал">
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="save" title="Сохранить">
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-header>Форма юр. лица</v-ons-list-header>
            <v-ons-list-item>
                <div class="select-material select--material select">
                    <select v-model="entity.type_id" class="select-input select-input--material">
                        <option v-for="entityForm in entityForms" :value="entityForm.id">
                            {{ entityForm.name }}
                        </option>
                    </select>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>
                <v-ons-checkbox
                    :disabled="!availIsPayerNdc"
                    v-model="entity.is_payer_ndc"
                    input-id='is-payer-ndc'
                    style="vertical-align: middle; margin: 0 12px 0 0;"
                ></v-ons-checkbox>
                <label for="is-payer-ndc">Плательщик НДС</label>
            </v-ons-list-header>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Юридическое наименование</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.legal_name" name="legal_name"></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Юридический адрес</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.legal_address" name="legal_address"></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>ОГРН</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.ogrn" name="ogrn"></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>ИНН</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.inn" type="number" name="inn"></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list v-show="shouldShowKppInput">
            <v-ons-list-header>КПП</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.kpp" type="number" name="kpp"></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list v-show="parseInt(entity.type_id) === 2">
            <v-ons-list-header>Должность подписанта</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.position_signatory" name="position_signatory"></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Почтовый адрес</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.postal_address" name="postal_address"></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>Скан печати для счета</v-ons-list-header>
            <div v-if="entity.scan_path_schet" style="padding: 0 20px" >
                <img :src="'/images/' + entity.scan_path_schet" alt="" style="max-width: 90%; box-shadow: 0 1px 6px #e8e8e8;">
            </div>
            <v-ons-list-item>
                <v-ons-button
                    @click="$upload.select('imageSchet')"
                    :disabled="$upload.meta('imageSchet').status === 'sending'"
                >
                    <v-ons-icon icon="md-upload" size="20px"></v-ons-icon>
                </v-ons-button>

                <v-ons-button
                    style="margin-left: 10px"
                    v-show="entity.scan_path_schet"
                    @click="entity.scan_path_schet = ''"
                >
                    <v-ons-icon icon="md-delete" size="20px"></v-ons-icon>
                </v-ons-button>

                <v-ons-button
                    style="margin-left: 10px"
                    v-show="entity.scan_path_schet"
                    @click="downloadScan"
                >
                    <v-ons-icon icon="md-download" size="20px"></v-ons-icon>
                </v-ons-button>
            </v-ons-list-item>
        </v-ons-list>

        <div>
            <v-ons-list-header>Расчетные счета</v-ons-list-header>

            <v-ons-list>
                <v-ons-list-item v-for="settlementAccount in entity.settlementAccounts" :key="settlementAccount.id">
                    <div class="center">
                        <v-ons-card style="width: 100%; cursor: pointer;">
                            <div class="content" @click="updateSettlementAccount(settlementAccount.id)">
                                <div v-show="settlementAccount.number">Расчетный счет: {{
                                        settlementAccount.number
                                    }}
                                </div>
                                <div v-show="settlementAccount.bank">Банк: {{ settlementAccount.bank }}</div>
                                <div v-show="settlementAccount.bik">БИК: {{ settlementAccount.bik }}</div>
                                <div v-show="settlementAccount.corr_account">Корр.счет:
                                    {{ settlementAccount.corr_account }}
                                </div>
                            </div>

                            <div class="content" v-show="entity.settlementAccounts.length > 1"
                                 @click="setDefaultSettlementAccount(settlementAccount.id)"
                                 style="margin-top: 15px; margin-bottom: -10px; padding-bottom: 10px;">
                                Основной расчетный счет:
                                <v-ons-checkbox
                                    v-model="settlementAccount.is_default"
                                    style="margin: 0 5px"
                                ></v-ons-checkbox>
                            </div>
                        </v-ons-card>
                    </div>
                    <div class="right">
                        <v-ons-button modifier="quiet" @click="removeSettlementAccount(settlementAccount.id)">
                            <v-ons-icon icon="md-delete" size="22px"></v-ons-icon>
                        </v-ons-button>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <div style="padding: 0 15px;">
                <v-ons-button style="margin-bottom: 10px;" @click="addSettlementAccount">
                    Добавить расчетный счет
                </v-ons-button>
            </div>
        </div>

        <v-ons-list>
            <v-ons-list-header>Адрес ведения деятельности</v-ons-list-header>
            <v-ons-list-item v-for="(storage, key) in storages" :key="storage.id" >
                <label :for="'storage-' + key" class="center label-reset" >
                    {{ storage.name }}
                </label>
                <div class="right">
                    <v-ons-checkbox
                        v-model="entity.storageIds"
                        :value="storage.id"
                        :input-id="'storage-' + key"
                        style="vertical-align: middle; margin: 0 12px 0 0;"
                    ></v-ons-checkbox>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list>
            <v-ons-list-header>ID юр. лица в 1С</v-ons-list-header>
            <v-ons-list-item>
                <v-ons-input v-model="entity.onec_code" name="onec_code"></v-ons-input>
            </v-ons-list-item>
        </v-ons-list>

        <settlement-account-dialog ref="settlementAccountDialog"/>
    </v-ons-page>
</template>

<script>

import SettlementAccountDialog from './SettlementAccountDialog'

export default {
    data() {
        return {
            changed: false,
            loaded: false,

            entityForms: [],
            id: null,
            entity: {
                settlementAccounts: [],
                storageIds: [],
            },
            storages: [],
        }
    },
    created() {
        this.id = this.$route.params.entity_id;

        this.$upload.new('imageSchet', {
            url: '/api/upload-image',
            extensions: ['jpeg', 'jpg', 'png'],
            onError() {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка при загрузке изображения.',
                    buttonLabel: 'OK'
                });
            },
            onSuccess(response) {
                this.entity.scan_path_schet = response.data;
            }
        });

        this.load();
    },
    destroyed() {
        this.$upload.reset('imageSchet');
        delete this.$upload.watch.uploaders.imageSchet;
    },
    methods: {
        goBack() {
            if (!this.changed) {
                this.$router.go(-1);
                return;
            }

            this.$ons.notification.confirm('Сохранить изменения?', {
                title: 'Подтверждение',
                buttonLabels: ['Да', 'Нет']
            }).then(response => {
                if (response === 1) {
                    this.$router.go(-1);
                }

                if (response === 0) {
                    this.save();
                }
            });
        },
        load() {
            let requests = [
                this.$http.get('/api/settings/entity-forms'),
                this.$http.get('/api/manager/storages'),
            ];

            if (this.id) {
                requests.push(this.$http.get('/api/settings/entities/' + this.id));
            }

            Promise.all(requests).then(responses => {
                this.entityForms = responses[0].data;
                this.storages = responses[1].data;

                if (responses[2]) {
                    this.entity = responses[2].data;
                }

                this.$nextTick(() => {
                    this.loaded = true;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loaded = true;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        save() {
            let promise;
            if (this.id) {
                promise = this.$http.post('/api/settings/entities/' + this.id, this.entity);
            } else {
                promise = this.$http.post('/api/settings/entities', this.entity);
            }

            promise.then(() => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Юр. лицо сохранено',
                    buttonLabel: 'OK',
                });
                this.$bus.$emit('load');
                this.$bus.$emit('entities-load');
                this.$router.go(-1);
            }, (response) => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: response.data.message,
                });
            });
        },
        remove() {
            this.$ons.notification.confirm('Удалить юр. лицо?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.delete('/api/settings/entities/' + this.id).then(() => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Юр. лицо удалено',
                        buttonLabel: 'OK',
                    });
                    this.$bus.$emit('load');
                    this.$router.go(-1);
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        addSettlementAccount() {
            this.$refs.settlementAccountDialog.show().then(settlementAccount => {
                this.entity.settlementAccounts.push(settlementAccount);
            });
        },
        updateSettlementAccount(id) {
            let index = this.entity.settlementAccounts.findIndex(i => i.id === id);
            let settlementAccount = this.entity.settlementAccounts[index];

            this.$refs.settlementAccountDialog.show(settlementAccount).then(settlementAccount => {
                settlementAccount.id = id;
                this.$set(this.entity.settlementAccounts, index, settlementAccount)
            });
        },
        setDefaultSettlementAccount(id) {
            let index = this.entity.settlementAccounts.findIndex(i => i.id === id);
            let settlementAccount = this.entity.settlementAccounts[index];

            this.entity.settlementAccounts.forEach((settlementAccount, index) => {
                this.entity.settlementAccounts[index].is_default = false;
            });

            settlementAccount.is_default = true;
        },
        removeSettlementAccount(id) {
            this.entity.settlementAccounts = this.entity.settlementAccounts.filter(i => i.id !== id);
        },
        downloadScan() {
            this.$http.get('/images/' + this.entity.scan_path_schet, {responseType: 'arraybuffer'}).then(response => {
                let blob = new Blob([response.data], {type: response.headers.get('content-type')});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = this.entity.scan_path_schet;
                link.click();
            }, () => {
                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
    computed: {
        // опция должна быть доступна для ИП, ООО, ОАО.
        availIsPayerNdc() {
            let typeId = parseInt(this.entity.type_id);
            return [1, 2, 3].includes(typeId);
        },
        shouldShowKppInput() {
            let typeId = parseInt(this.entity.type_id)
            return [2, 3].includes(typeId);
        },
    },
    components: {
        SettlementAccountDialog,
    },
    watch: {
        entity: {
            handler() {
                if (!this.loaded) {
                    return;
                }

                if (!this.availIsPayerNdc && this.entity.is_payer_ndc) {
                    this.entity.is_payer_ndc = false;
                }

                this.changed = true;
            },
            deep: true,
        },
    },
};
</script>