<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar inline="true" >
            <div class="center"></div>
            <div class="right" >
                <v-ons-toolbar-button @click="close" title="Закрыть" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" style="top: 55px; bottom: 0;" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-item modifier="longdivider" >
                    Все товары клиента из списка "Мои товары" будут отображаться с индикатором "В наличии", а так же клиент не будет получать уведомления, если заказ превысит остатки.
                </v-ons-list-item>

                <v-ons-list-item modifier="longdivider" >
                    <div class="left" style="min-width: 30px;">
                        <v-ons-checkbox
                          v-model="alwaysAvailable"
                          input-id="alwaysAvailableLabel"
                        ></v-ons-checkbox>
                    </div>
                    <label class="center label-reset" for="alwaysAvailableLabel">"Мои товары" всегда в наличии</label>
                </v-ons-list-item>
            </v-ons-list>

            <ons-row class="text-center" style="margin: 10px 0; justify-content: center;" >
                <ons-col width="50%">
                    <v-ons-button @click="save()" style="width: 100%">ОК</v-ons-button>
                </ons-col>
            </ons-row>
        </div>
    </v-ons-dialog>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            visible: false,
            userId: null,
            alwaysAvailable: false,

            resolve: null,
            reject: null,
        }
    },
    created() {
    },
    methods: {
        show(userId) {
            this.userId = userId;
            this.visible = true;
            this.loading = true;

            this.$http.get(`/api/manager/users/${this.userId}/rest-always-available`).then(response => {
                this.alwaysAvailable = response.data;

                this.$nextTick(() => {
                    this.loading = false;
                });
            });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.visible = false;
        },
        save() {
            this.loading = true;

            this.$http.post(`/api/manager/users/${this.userId}/rest-always-available`, {restAlwaysAvailable: this.alwaysAvailable}).then(response => {
                this.visible = false;
                this.resolve(this.alwaysAvailable);
            }, response => {
                this.loading = false;

                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK',
                });
            });
        },
    }
};
</script>
