var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Пригласить сотрудника")]),
        _vm._v(" "),
        _c("div", { staticClass: "right" })
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Email")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c(
                "v-ons-button",
                { attrs: { disabled: !_vm.email }, on: { click: _vm.send } },
                [_vm._v("Пригласить")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-71c72d8a", { render: render, staticRenderFns: staticRenderFns })
  }
}