<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div v-if="inventory.id" class="center">Изменить инвентаризацию №{{$route.params.inventory}}</div>
            <div v-else class="center">Создание новой инвентаризации</div>

            <div class="right" >
                <v-ons-toolbar-button @click="$refs.assignTemplateModal.show()" title="Выбрать шаблон" >
                    <v-ons-icon icon="md-collection-item"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <assign-template-modal
            :assignedTemplates="assignedTemplates"
            ref="assignTemplateModal"
            @choose="onChooseTemplate" />

        <div class="inventory-create-page" style="padding-bottom: 100px;" >
            <div v-for="group in getGroups()" class="group-wrap" >
                <div class="group-name" >
                    {{ group.name }}
                </div>

                <div class="positions" >
                    <div
                        v-for="(position, index) in getPositionsByGroup(group.id)"
                        class="position-wrap"
                        v-bind:id="'position-'+index"
                        v-bind:class="{'position-last': index == positions.length-1}"
                        v-if="!position.isRemove && position.canShow"
                        :key="position.posId"
                    >
                        <div class="row-wrap">
                            <div class="col col-name" >
                                {{ position.name }}
                            </div>

                            <div class="col col-count" :class="{highligh: position.count && position.count != 0}" >
                                <div class="count-wrap">
                                    <div class="wrap">
                                        <div class="col col-button" >
                                            <button type="button" v-on:click="dec(position)" >-</button>
                                        </div>

                                        <div class="col col-input">
                                            <input
                                                type="number"
                                                class="input-count"
                                                v-model="position.count"
                                                @keydown="chooseCountHandler($event, position, index)"
                                                @focus="focusCountInput"
                                                @blur="blurCountInput"
                                            >
                                        </div>
                                        <div class="col col-button" >
                                            <button type="button" v-on:click="inc(position)" >+</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-unit" >
                                {{ position.unit }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-ons-fab position="bottom right" @click="submit" :disabled="!canSubmit" >
            <v-ons-icon icon="md-check"></v-ons-icon>
        </v-ons-fab>

        <v-ons-dialog :visible="splashVisible" >
            <v-ons-row vertical-align="center" >
                <v-ons-col width="70px" style="text-align: center"  >
                    <v-ons-progress-circular indeterminate></v-ons-progress-circular>
                </v-ons-col>

                <v-ons-col>
                    <div style="line-height: 100px" >Отправка...</div>
                </v-ons-col>
            </v-ons-row>
        </v-ons-dialog>
    </v-ons-page>
</template>

<script>

import $ from 'jquery'
import Vue from 'vue'
import numeral from 'numeral'
import AssignTemplateModal from '../../component/AssignTemplateModal'

Vue.component('assign-template-modal', AssignTemplateModal);

export default {
    data() {
        return {
            inventory: {},
            positions: [],
            groups: [],
            splashVisible: false,
            assignedTemplates: [],
            loading: false,
        };
    },
    created() {
        this.loading = true;

        this.$http.get('/api/inventory/get-positions').then(response => {
            let positions = response.data.positions;
            positions.forEach(i => {
                i.count = '';
                i.isChecked = false;
                i.canShow = true;
            });
            this.positions = positions;
            this.groups = response.data.groups;

            // Добавляем группу "Без группы"
            this.groups.push({
                id: 0,
                name: 'Без группы',
            });

            if (this.$route.params.inventory) {
                this.$http.get('/api/inventory/view', {params: {id: this.$route.params.inventory}}).then(response => {
                    this.inventory = response.data;

                    this.$nextTick(() => {
                        this.onChooseTemplate(this.inventory.assigned_templates);

                        this.inventory.positions.forEach(position => {
                            let p = this.positions.find(i => i.id == position.usr_prd_id);
                            if (p) {
                                p.count = position.count;
                            }
                        });
                    });

                });
            } else {
                this.loading = false;
            }
        });
    },
    methods: {
        getGroups() {
            let ids = this.positions
                .filter(i => !i.isRemove && i.canShow)
                .map(i => i.group_id)
                .unique();

            return this.groups.filter(i => ids.indexOf(i.id) !== -1);
        },
        getPositionsByGroup(group) {
            return this.positions.filter(function (position) {
                return parseInt(position.group_id) === parseInt(group);
            });
        },
        onChooseTemplate(templates) {
            this.assignedTemplates = templates.map(i => i.id);

            let inTemplates = (position) => {
                if (templates.length === 0) {
                    return true;
                }

                return !!templates.find(tmp => {
                    return tmp.positions.find(pos => {
                        return pos.id == position.id;
                    });
                });
            }

            this.positions.forEach(i => {
                i.canShow = inTemplates(i);
            });
        },
        inc(position) {
            let mult = 1;

            if (position.product && position.mult) {
                mult = parseFloat(position.mult);
            }

            let count = position.count;
            if (!position.count) {
                count = mult;
            } else {
                count = parseFloat(count) + mult;
            }
            position.count = numeral(count).format('0[.][000]');
        },
        dec(position) {
            var mult = 1;

            if (position.product && position.mult) {
                mult = parseFloat(position.mult);
            }

            let count = parseFloat(position.count) - mult;
            position.count = numeral(count).format('0[.][000]');
        },
        chooseCountHandler(e, position, index) {
            let $position = $('#position-' + index);

            if (e.keyCode === 13) {
                $position.next().find('.col-input .input-count').focus();
            }
        },
        focusCountInput(e) {
            $(e.target).closest('.position-wrap').addClass('highligh');
        },
        blurCountInput(e) {
            $(e.target).closest('.position-wrap').removeClass('highligh');
        },
        submit() {
            let positions = this.positions
                .filter(i => !i.isRemove && i.count)
                .map(i => {
                    return {
                        prd_id: i.id,
                        count: i.count,
                    }
                });

            if (positions.length === 0) {
                return;
            }

            this.splashVisible = true;

            let request = {};

            if (this.inventory.id) {
                request = this.$http.post('/api/inventory/update', {
                    id: this.inventory.id,
                    positions: positions,
                    assigned_templates: this.assignedTemplates
                });
            } else {
                request = this.$http.post('/api/inventory/create', {
                    positions: positions,
                    assigned_templates: this.assignedTemplates,
                });
            }

            request.then(() => {
                this.splashVisible = false;

                this.$bus.$emit('inventory-list:update');

                this.$router.push({
                    name: 'inventory-list',
                });
            }, () => {
                this.splashVisible = false;
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
    computed: {
        canSubmit() {
            return this.positions
                .filter(i => !i.isRemove && i.count && parseFloat(i.count) !== 0)
                .length > 0;
        }
    },
}
</script>