var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "manager-user-settings" },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Настройки: " + _vm._s(_vm.user.email))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Тип цены:")]),
          _vm._v(" "),
          _vm._l(_vm.priceTypes, function(type) {
            return _c(
              "div",
              {
                directives: [{ name: "frag", rawName: "v-frag" }],
                key: type.id,
                attrs: { modifier: "longdivider" }
              },
              [
                _c(
                  "v-ons-list-item",
                  {
                    on: {
                      click: function($event) {
                        _vm.selectedPriceType = type.id.toString()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [
                        _c("v-ons-radio", {
                          attrs: { value: type.id },
                          model: {
                            value: _vm.selectedPriceType,
                            callback: function($$v) {
                              _vm.selectedPriceType = $$v
                            },
                            expression: "selectedPriceType"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "center label-reset",
                        attrs: {
                          for: "radio-" + _vm.templateId + "-" + type.id
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(type.name) +
                            "\n                "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-ons-list-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: type.id == 1 && _vm.selectedPriceType == type.id,
                        expression:
                          "type.id == 1 && selectedPriceType == type.id"
                      }
                    ],
                    staticStyle: { "padding-left": "55px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [
                        _c("v-ons-checkbox", {
                          attrs: { "input-id": "hideGetDiscountButton" },
                          model: {
                            value: _vm.hideGetDiscountButton,
                            callback: function($$v) {
                              _vm.hideGetDiscountButton = $$v
                            },
                            expression: "hideGetDiscountButton"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "center label-reset",
                        attrs: { for: "hideGetDiscountButton" }
                      },
                      [_vm._v('Скрыть "Получить скидку"')]
                    )
                  ]
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            { on: { click: _vm.goToSettingsCustomPriceType } },
            [
              _c(
                "div",
                { staticClass: "left" },
                [
                  _c("v-ons-icon", {
                    attrs: { icon: "md-arrow-right", size: "23px" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "center" }, [
                _vm._v("Индивидуальный тип цен")
              ])
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Способ отгрузки по умолчанию:")]),
          _vm._v(" "),
          _c("v-ons-list-item", [
            _c(
              "div",
              { staticClass: "select-material select--material select" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.defaultShippingMethod,
                        expression: "defaultShippingMethod"
                      }
                    ],
                    staticClass: "select-input select-input--material",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.defaultShippingMethod = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: "" } }, [
                      _vm._v("не выбрано")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.getShippingMethods, function(shippingMethod) {
                      return _c(
                        "option",
                        {
                          key: shippingMethod.id,
                          domProps: { value: shippingMethod.id }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(shippingMethod.name) +
                              "\n                    "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-item", { staticClass: "list-header--material" }, [
            _c("div", { staticClass: "center" }, [
              _vm._v(
                "\n                Заблокировать способ отгрузки:\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "v-ons-button",
                  {
                    attrs: {
                      disabled: _vm.getShippingMethodsForBlock.length === 0
                    },
                    on: { click: _vm.addBlockedShippingMethods }
                  },
                  [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.getBlockedShippingMethods, function(method) {
            return _c(
              "v-ons-list-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.blockedShippingMethods,
                    expression: "blockedShippingMethods"
                  }
                ],
                key: method.id
              },
              [
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(method.name) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "v-ons-button",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { modifier: "outline" },
                        on: {
                          click: function($event) {
                            return _vm.removeBlockedShippingMethods(method)
                          }
                        }
                      },
                      [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            { staticStyle: { "justify-content": "center" } },
            [
              _c("v-list-dialog", {
                ref: "shippingMethodsForBlockDialog",
                attrs: {
                  title: "Заблокировать способ отгрузки",
                  btnTitle: "Блокировать",
                  multiple: true,
                  items: _vm.getShippingMethodsForBlock
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Способы оплаты по умолчанию")]),
          _vm._v(" "),
          _vm.entities.length > 0
            ? _c(
                "div",
                _vm._l(_vm.entities, function(entity) {
                  return _c("v-ons-list-item", { key: entity.id }, [
                    _c("div", { staticClass: "center" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(entity.name) +
                          " - " +
                          _vm._s(entity.defaultPaymentMethod) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c(
                          "v-ons-button",
                          { staticStyle: { padding: "0 10px" } },
                          [
                            _c("v-ons-icon", {
                              staticClass: "cursor-pointer list-item__icon",
                              attrs: { icon: "md-edit", size: "14px" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    _vm.$router.currentRoute.path +
                                      "/entities/" +
                                      entity.id
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                }),
                1
              )
            : _c(
                "div",
                [
                  _c("v-ons-list-item", [
                    _c(
                      "div",
                      { staticClass: "center" },
                      [
                        _c(
                          "v-ons-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "manager-user-settings-entity-create"
                                })
                              }
                            }
                          },
                          [_vm._v("Добавить юр. лицо")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f8ccd0f4", { render: render, staticRenderFns: staticRenderFns })
  }
}