var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v(_vm._s(_vm.product.name))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                on: { click: _vm.save }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list-item",
            {
              attrs: { modifier: "chevron longdivider" },
              on: { click: _vm.showChooseStorage }
            },
            [
              _c("span", { staticClass: "list-item__title" }, [
                _vm._v("Склад: " + _vm._s(_vm.selectedStorageName))
              ]),
              _vm._v(" "),
              _vm.selectedStorage && !_vm.selectedStorage.is_configured
                ? _c(
                    "span",
                    {
                      staticClass: "list-item__subtitle",
                      staticStyle: { color: "red" }
                    },
                    [_vm._v("\n                Не настроено\n            ")]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("v-ons-list-item", [
            !_vm.product.is_deleted
              ? _c("div", [
                  _vm._v(
                    "Остаток: " +
                      _vm._s(_vm.product.rest) +
                      " " +
                      _vm._s(_vm.product.unit)
                  )
                ])
              : _c("div", [
                  _vm._v("Товар кончился: " + _vm._s(_vm.product.deletedAt))
                ])
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list-header",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.product.is_deleted,
                  expression: "product.is_deleted"
                }
              ]
            },
            [_vm._v("Информация о поступлении")]
          ),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.product.is_deleted,
                  expression: "product.is_deleted"
                }
              ]
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.product.info_about_admission,
                    expression: "product.info_about_admission"
                  }
                ],
                staticClass: "textarea",
                staticStyle: { width: "100%" },
                attrs: { rows: "3" },
                domProps: { value: _vm.product.info_about_admission },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.product,
                      "info_about_admission",
                      $event.target.value
                    )
                  }
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("v-ons-list-item", [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("v-ons-checkbox", {
                  attrs: { "input-id": "productIsAvailLabel" },
                  model: {
                    value: _vm.product.is_avail,
                    callback: function($$v) {
                      _vm.$set(_vm.product, "is_avail", $$v)
                    },
                    expression: "product.is_avail"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "center label-reset",
                attrs: { for: "productIsAvailLabel" }
              },
              [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v("Добавить товар в исключение OOS")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v(
                    'Если товар кончится, то он всё равно будет отображаться в Каталоге со статусом "ждём"'
                  )
                ])
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-storage-list-dialog", {
        ref: "chooseStorageDialog",
        attrs: { items: _vm.storages, unit: _vm.product.unit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-df85b424", { render: render, staticRenderFns: staticRenderFns })
  }
}