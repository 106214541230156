var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-list",
    { on: { click: _vm.onClick } },
    [
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Главная")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [_c("router-link", { attrs: { to: "/order" } }, [_vm._v("Заказы")])],
        1
      ),
      _vm._v(" "),
      _vm.$user.canSee("catalog")
        ? _c(
            "v-ons-list-item",
            { attrs: { tappable: "" } },
            [
              _c("router-link", { attrs: { to: "/catalog" } }, [
                _vm._v("Каталог")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$user.canSee("myproducts")
        ? _c(
            "v-ons-list-item",
            { attrs: { tappable: "" } },
            [
              _c("router-link", { attrs: { to: "/product-list" } }, [
                _vm._v("Мои товары")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$user.canSee("mymanager")
        ? _c(
            "v-ons-list-item",
            { attrs: { tappable: "" } },
            [
              _c("router-link", { attrs: { to: "/mymanager" } }, [
                _vm._v("Ваш менеджер")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$user.canSee("routes")
        ? _c(
            "v-ons-list-item",
            { attrs: { tappable: "" } },
            [
              _c("router-link", { attrs: { to: "/mymanager/routes" } }, [
                _vm._v("График доставки")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [_c("router-link", { attrs: { to: "/profile" } }, [_vm._v("Профиль")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list-item",
        { attrs: { tappable: "" } },
        [
          _c("router-link", { attrs: { to: "/settings" } }, [
            _vm._v("Настройки")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-12b3e806", { render: render, staticRenderFns: staticRenderFns })
  }
}