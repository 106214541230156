var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Выбор типа цены")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                on: { click: _vm.save }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticStyle: { padding: "10px 16px" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "font-weight": "bold",
                  "font-size": "13px",
                  "margin-bottom": "10px"
                }
              },
              [
                _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.categories.length > 1
                          ? "Для разделов"
                          : "Для раздела"
                      ) +
                      ":\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.categories
                          .map(function(i) {
                            return i.name
                          })
                          .join(", ")
                      ) +
                      ".\n                "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-material select--material select" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.priceType,
                        expression: "params.priceType"
                      }
                    ],
                    staticClass: "select-input select-input--material",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.params,
                          "priceType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c(
                      "option",
                      { attrs: { disabled: "" }, domProps: { value: null } },
                      [
                        _vm._v(
                          "\n                        Выберите тип цены\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.priceTypes, function(priceType) {
                      return _c(
                        "option",
                        {
                          key: priceType.id,
                          domProps: { value: priceType.id }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(priceType.name) +
                              "\n                    "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wrap xl-gutter-8",
                staticStyle: { padding: "15px 0 0 15px" }
              },
              [
                _c("div", { staticClass: "col" }, [
                  _c("div", [_vm._v("% наценки")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.priceMarkup,
                          expression: "params.priceMarkup"
                        }
                      ],
                      attrs: { type: "number" },
                      domProps: { value: _vm.params.priceMarkup },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.params,
                            "priceMarkup",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("v-ons-checkbox", {
                      attrs: {
                        value: true,
                        "input-id": "params-calculateWhenNoPrice"
                      },
                      model: {
                        value: _vm.params.calculateWhenNoPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "calculateWhenNoPrice", $$v)
                        },
                        expression: "params.calculateWhenNoPrice"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "params-calculateWhenNoPrice" }
                  },
                  [_vm._v("Если нет цены, то ценообразовать")]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.params.calculateWhenNoPrice,
                  expression: "params.calculateWhenNoPrice"
                }
              ],
              staticStyle: { margin: "0 16px 0 30px" }
            },
            [
              _c("div", { staticStyle: { margin: "7px 0" } }, [
                _c(
                  "div",
                  { staticClass: "select-material select--material select" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.priceTypeSecond,
                            expression: "params.priceTypeSecond"
                          }
                        ],
                        staticClass: "select-input select-input--material",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "priceTypeSecond",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { disabled: "" },
                            domProps: { value: null }
                          },
                          [
                            _vm._v(
                              "\n                            Выберите тип цены\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.priceTypes, function(priceType) {
                          return _c(
                            "option",
                            {
                              key: priceType.id,
                              domProps: { value: priceType.id }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(priceType.name) +
                                  "\n                        "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { margin: "11px 0" } }, [
                _c("div", { staticClass: "wrap" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", [_vm._v("% наценки")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.priceMarkupSecond,
                            expression: "params.priceMarkupSecond"
                          }
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "number" },
                        domProps: { value: _vm.params.priceMarkupSecond },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.params,
                              "priceMarkupSecond",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                padding: "5px",
                margin: "40px 10px 10px",
                border: "1px solid #000"
              }
            },
            [
              _vm._v(
                "\n            Если в исходном прайс-листе у товара нет выбранной цены, то такой товар не будет выгружен.\n        "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-79942f49", { render: render, staticRenderFns: staticRenderFns })
  }
}