var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-toggle-sidebar-button")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Главная")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "home-page" }, [
        _c("div", { staticClass: "links-block" }, [
          _c(
            "div",
            { staticClass: "row-wrap" },
            [
              _c(
                "router-link",
                {
                  staticClass: "col-wrap button--material",
                  attrs: { to: "/catalog" }
                },
                [_c("span", [_vm._v("Каталог")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row-wrap" },
            [
              _c(
                "router-link",
                {
                  staticClass: "col-wrap button--material",
                  class: { highlight: _vm.highlightCreateOrderLink },
                  attrs: { to: "/order/create" }
                },
                [_c("span", [_vm._v("Написать заявку")])]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "col-wrap button--material",
                  attrs: { to: "/order" }
                },
                [_c("span", [_vm._v("Заказы")])]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.user.id
          ? _c("div", [
              _vm.user.discounts.getDiscount.enabled
                ? _c(
                    "div",
                    [
                      _c("v-ons-card", [
                        _c(
                          "p",
                          [
                            _vm._v(
                              "Для получения скидки и статуса корпоративного клиента вам необходимо "
                            ),
                            _c(
                              "router-link",
                              { attrs: { to: "/contacts-for-discounts" } },
                              [_vm._v("ввести данные о вашей фирме")]
                            ),
                            _vm._v(
                              ". После проверки и подтверждения информации менеджер активирует вашу учетную запись и отправит вам уведомление"
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "wrap" }, [
                          _c(
                            "div",
                            { staticClass: "col xl-1-1 xl-center" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "button button--material send-contacts",
                                  attrs: { to: "/contacts-for-discounts" }
                                },
                                [
                                  _c("span", [
                                    _vm._v("Заполните анкету"),
                                    _c("br"),
                                    _vm._v("и получите скидку")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.user.hasUserGroupOrTemplate
                ? _c("div", [
                    !_vm.user.filledUserProduct
                      ? _c(
                          "div",
                          [
                            _c("v-ons-card", [
                              _c(
                                "p",
                                [
                                  _vm._v(
                                    "Собственный товарный перечень можно создать через раздел "
                                  ),
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/product-list" } },
                                    [_vm._v('"Мои товары"')]
                                  ),
                                  _vm._v(", добавляя каждую позицию вручную.")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                [
                                  _vm._v("А также в разделе "),
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/catalog" } },
                                    [_vm._v('"Каталог"')]
                                  ),
                                  _vm._v(
                                    ' можно выделять галочкой товары и нажимать кнопку "звёздочка", отправляя их в '
                                  ),
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/product-list" } },
                                    [_vm._v('"Мои товары"')]
                                  ),
                                  _vm._v(".")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Собственный товарный перечень поможет вам быстро делать выборку из каталога только по товарам, которые вы используете, а также создавать заказы по шаблонам."
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "wrap" }, [
                                _c(
                                  "div",
                                  { staticClass: "col xl-1-1 xl-center" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "button button--material",
                                        attrs: { to: "/product-list" }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Создайте собственную"),
                                          _c("br"),
                                          _vm._v("товарную подборку")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("v-ons-card", [
                              _c(
                                "p",
                                [
                                  _vm._v("Управляйте списком "),
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/product-list" } },
                                    [_vm._v('"Мои товары"')]
                                  ),
                                  _vm._v(
                                    " для получения более высокой скорости оформления ваших заказов."
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Выделяя товары галочкой, можно менять их положение в списке и размещать их по собственным товарным группам."
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "wrap" }, [
                                _c(
                                  "div",
                                  { staticClass: "col xl-1-1 xl-center" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "button button--material",
                                        attrs: { to: "/product-list" }
                                      },
                                      [_c("span", [_vm._v("Мои товары")])]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ])
                          ],
                          1
                        )
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "margin-top": "10px" } },
          [_c("v-discount-info")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ba39c35e", { render: render, staticRenderFns: staticRenderFns })
  }
}