<template>
    <v-ons-dialog :visible.sync="visible" cancelable @prehide="onPreHide">
        <div style="margin: 20px 5px; text-align: center;">
            <p style="margin-bottom: 15px">
                <b>{{ positions.length }} товаров(-а) не хватает:</b>
            </p>

            <v-ons-list :style="{maxHeight: ($window.height - 110) + 'px', overflowY: 'auto'}">
                <v-ons-list-item
                    v-for="position in positions"
                    :key="position.pos_id"
                    style="padding-left: 10px;"
                    @click="scrollTo(position.pos_id)"
                >
                    <div class="center">
                        <span>
                            {{ position.name }}
                            <b style="font-size: 16px; white-space: nowrap;">
                                -
                                {{ positionRest(position) }}
                                {{ position.unit }}
                            </b>
                        </span>
                    </div>
                    <div class="right" style="padding-right: 5px;">
                        <v-ons-icon icon="md-edit" size="20px"></v-ons-icon>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <div style="margin-top: 20px;">
                <v-ons-button @click="resume">
                    Продолжить
                </v-ons-button>

                <div style="margin-top: 5px; color: #757575;">и отправить заказ, как есть.</div>
            </div>
        </div>
    </v-ons-dialog>
</template>

<script>

import numeral from 'numeral'

export default {
    data() {
        return {
            positions: [],
            lastImportDate: null,
            visible: false,
            resolve: null,
            reject: null,
        }
    },
    methods: {
        show(params) {
            this.positions = params.positions;
            this.lastImportDate = params.lastImportDate;

            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        resume() {
            this.visible = false;
            this.resolve({command: 'resume'});
        },
        scrollTo(pos_id) {
            this.visible = false;
            this.resolve({command: 'scrollTo', pos_id: pos_id});
        },
        onPreHide() {
            this.reject();
        },
        positionRest(position) {
            return numeral(position.count - position.rest).format('0[.][000]')
        },
    },
};
</script>