var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "manager-user-entity" },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.id
          ? _c("div", { staticClass: "center" }, [
              _vm._v("Юр. лицо: " + _vm._s(_vm.entity.legal_name))
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.id
          ? _c("div", { staticClass: "center" }, [_vm._v("Создать юр. лицо")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.id
              ? _c(
                  "v-ons-toolbar-button",
                  {
                    attrs: { title: "Удалить юр. лицо" },
                    on: { click: _vm.remove }
                  },
                  [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Сохранить" }, on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Юридическое наименование")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { name: "legal_name" },
                    model: {
                      value: _vm.entity.legal_name,
                      callback: function($$v) {
                        _vm.$set(_vm.entity, "legal_name", $$v)
                      },
                      expression: "entity.legal_name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Юридический адрес")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { name: "legal_address" },
                    model: {
                      value: _vm.entity.legal_address,
                      callback: function($$v) {
                        _vm.$set(_vm.entity, "legal_address", $$v)
                      },
                      expression: "entity.legal_address"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [
                _vm._v("\n                ИНН\n                "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.entity.inn,
                        expression: "entity.inn"
                      }
                    ],
                    staticStyle: {
                      color: "#757575",
                      position: "absolute",
                      right: "0",
                      padding: "0 30px"
                    },
                    attrs: {
                      href: "https://ya.ru/search/?text=" + _vm.entity.inn,
                      target: "_blank",
                      title: "Проверить в Yandex"
                    }
                  },
                  [_c("v-ons-icon", { attrs: { icon: "md-google" } })],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { type: "number", name: "inn" },
                    model: {
                      value: _vm.entity.inn,
                      callback: function($$v) {
                        _vm.$set(_vm.entity, "inn", $$v)
                      },
                      expression: "entity.inn"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("КПП")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { type: "number", name: "kpp" },
                    model: {
                      value: _vm.entity.kpp,
                      callback: function($$v) {
                        _vm.$set(_vm.entity, "kpp", $$v)
                      },
                      expression: "entity.kpp"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("ОГРН")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { type: "number", name: "ogrn" },
                    model: {
                      value: _vm.entity.ogrn,
                      callback: function($$v) {
                        _vm.$set(_vm.entity, "ogrn", $$v)
                      },
                      expression: "entity.ogrn"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Дата ОГРН")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { name: "ogrn_created_at" },
                    model: {
                      value: _vm.entity.ogrn_created_at,
                      callback: function($$v) {
                        _vm.$set(_vm.entity, "ogrn_created_at", $$v)
                      },
                      expression: "entity.ogrn_created_at"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Договоры:")]),
              _vm._v(" "),
              _vm._l(_vm.contracts, function(contract) {
                return _c(
                  "div",
                  { key: contract.id },
                  [
                    _c(
                      "v-ons-list-item",
                      { attrs: { modifier: "longdivider" } },
                      [
                        _c(
                          "div",
                          { staticClass: "left" },
                          [
                            _c("v-ons-checkbox", {
                              attrs: {
                                "input-id": "checkbox-contract-" + contract.id,
                                value: contract.id
                              },
                              model: {
                                value: _vm.entity.contracts,
                                callback: function($$v) {
                                  _vm.$set(_vm.entity, "contracts", $$v)
                                },
                                expression: "entity.contracts"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "center",
                            staticStyle: {
                              "font-weight": "normal",
                              margin: "0"
                            },
                            attrs: { for: "checkbox-contract-" + contract.id }
                          },
                          [_vm._v(_vm._s(contract.name))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.entity.contracts.includes(contract.id.toString())
                      ? _c(
                          "div",
                          { staticClass: "wrap-contract-manager-entities" },
                          _vm._l(_vm.managerEntities, function(managerEntity) {
                            return _c(
                              "v-ons-list-item",
                              {
                                key: managerEntity.id,
                                attrs: { modifier: "longdivider" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "left" },
                                  [
                                    _c("v-ons-checkbox", {
                                      attrs: {
                                        "input-id":
                                          "checkbox-managerEntity-" +
                                          contract.id +
                                          "-" +
                                          managerEntity.id,
                                        value:
                                          contract.id + "-" + managerEntity.id
                                      },
                                      model: {
                                        value: _vm.signedContracts,
                                        callback: function($$v) {
                                          _vm.signedContracts = $$v
                                        },
                                        expression: "signedContracts"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "center",
                                    staticStyle: {
                                      "font-weight": "normal",
                                      margin: "0"
                                    },
                                    attrs: {
                                      for:
                                        "checkbox-managerEntity-" +
                                        contract.id +
                                        "-" +
                                        managerEntity.id
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(managerEntity.alias) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [
                _vm._v("Основное юр.лицо для работы с этим клиентом")
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c(
                  "div",
                  { staticClass: "select-material select--material select" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.entity.default_provider_entity,
                            expression: "entity.default_provider_entity"
                          }
                        ],
                        staticClass: "select-input select-input--material",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.entity,
                              "default_provider_entity",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { domProps: { value: "" } }, [
                          _vm._v("не выбрано")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.managerEntities, function(providerEntity) {
                          return _c(
                            "option",
                            {
                              key: providerEntity.id,
                              domProps: { value: providerEntity.id }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(providerEntity.alias) +
                                  "\n                        "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Способ оплаты по умолчанию:")]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c(
                  "div",
                  { staticClass: "select-material select--material select" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.entity.default_payment_method,
                            expression: "entity.default_payment_method"
                          }
                        ],
                        staticClass: "select-input select-input--material",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.entity,
                              "default_payment_method",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { domProps: { value: "" } }, [
                          _vm._v("не выбрано")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.getPaymentMethods, function(paymentMethod) {
                          return _c(
                            "option",
                            {
                              key: paymentMethod.id,
                              domProps: { value: paymentMethod.id }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(paymentMethod.name) +
                                  "\n                        "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-item", { staticClass: "list-header--material" }, [
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    "\n                    Заблокировать способ оплаты:\n                "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "v-ons-button",
                      {
                        attrs: {
                          disabled: _vm.getPaymentMethodsForBlock.length === 0
                        },
                        on: { click: _vm.addBlockedPaymentMethods }
                      },
                      [_c("v-ons-icon", { attrs: { icon: "md-plus" } })],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.getBlockedPaymentMethods, function(method) {
                return _c(
                  "v-ons-list-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.entity.blocked_payment_methods,
                        expression: "entity.blocked_payment_methods"
                      }
                    ],
                    key: method.id
                  },
                  [
                    _c("div", { staticClass: "center" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(method.name) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c(
                          "v-ons-button",
                          {
                            staticStyle: { "margin-left": "5px" },
                            attrs: { modifier: "outline" },
                            on: {
                              click: function($event) {
                                return _vm.removeBlockedPaymentMethods(method)
                              }
                            }
                          },
                          [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                { staticStyle: { "justify-content": "center" } },
                [
                  _c("v-list-dialog", {
                    ref: "paymentMethodsForBlockDialog",
                    attrs: {
                      title: "Заблокировать способ оплаты",
                      btnTitle: "Блокировать",
                      multiple: true,
                      items: _vm.getPaymentMethodsForBlock
                    }
                  })
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Адрес ведения деятельности")]),
              _vm._v(" "),
              _vm._l(_vm.branches, function(branch, key) {
                return _c("ons-list-item", { key: branch.id }, [
                  _c(
                    "label",
                    {
                      staticClass: "center label-reset",
                      attrs: { for: "branch-" + key }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            [branch.stringAddress, branch.actual_name]
                              .filter(function(i) {
                                return i
                              })
                              .join(", ")
                          ) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isOnlyEntity
                    ? _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("v-ons-checkbox", {
                            staticStyle: {
                              "vertical-align": "middle",
                              margin: "0 12px 0 0"
                            },
                            attrs: {
                              value: branch.id,
                              "input-id": "branch-" + key
                            },
                            model: {
                              value: _vm.entity.branchIds,
                              callback: function($$v) {
                                _vm.$set(_vm.entity, "branchIds", $$v)
                              },
                              expression: "entity.branchIds"
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("v-ons-checkbox", {
                            staticStyle: {
                              "vertical-align": "middle",
                              margin: "0 12px 0 0"
                            },
                            attrs: {
                              value: "true",
                              checked: "true",
                              disabled: "true"
                            },
                            model: {
                              value: _vm.isOnlyEntity,
                              callback: function($$v) {
                                _vm.isOnlyEntity = $$v
                              },
                              expression: "isOnlyEntity"
                            }
                          })
                        ],
                        1
                      )
                ])
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2af8f606", { render: render, staticRenderFns: staticRenderFns })
  }
}