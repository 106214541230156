var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tile-item-product", style: { width: _vm.width } },
    [
      _c("div", { staticClass: "tile-item-product-inner" }, [
        _c("div", { staticClass: "image-wrap" }, [
          _c("img", {
            class:
              "product-image cursor-pointer" +
              (_vm.item.images.thumb100.hasImage ? "has-product-image" : ""),
            attrs: {
              src: _vm.item.images.thumb100.path,
              alt: _vm.item.name,
              title: _vm.item.name,
              width: _vm.item.images.thumb100.width,
              height: _vm.item.images.thumb100.height
            },
            on: { click: _vm.clickGoToView }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product-attributes" }, [
          _c(
            "div",
            {
              staticClass: "product-name cursor-pointer",
              on: { click: _vm.clickGoToView }
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.item.name) + "\n            "
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-18a2796b", { render: render, staticRenderFns: staticRenderFns })
  }
}