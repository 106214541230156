<template>
    <v-ons-page class="report-special-prices" >
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Отчет по специальным ценам</div>

            <div class="right">
                <v-ons-toolbar-button @click="showMinPrice = !showMinPrice" >
                    <v-ons-icon :icon="showMinPrice ? 'md-eye' : 'md-eye-off'"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-show="itemsByUsers.length > 0" >
                <v-ons-card v-for="itemsByUser in itemsByUsers" :key="itemsByUser.id" >
                    <div class="title" @click="goToUser(itemsByUser.id)" >
                        {{ itemsByUser.name }}
                    </div>

                    <div>
                        <div v-for="item in itemsByUser.items" :key="item.product_id" class="product-wrap" >
                            <div @click="$router.push('/manager/products/' + item.product_id)" class="product-name" >
                                {{ item.name }}
                            </div>

                            <table class="table-params" >
                                <tbody>
                                    <tr>
                                        <td>Ед.</td>
                                        <td>Цена прайс</td>
                                        <td>Цена спец.</td>
                                    </tr>
                                    <tr>
                                        <td>{{ item.unit }}</td>
                                        <td>
                                            {{ item.productPrice }}
                                            <span class="font-rouble" >o</span>
                                        </td>
                                        <td @click="$router.push('/manager/users/'+ item.user_id +'/special-prices')" >
                                            {{ item.price }}
                                            <span class="font-rouble" >o</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div>
                                Наличие:
                                <span v-if="item.rest" >{{ item.rest }}</span>
                                <span v-else style="color: red" >нет</span>
                            </div>

                            <div v-show="showMinPrice" >
                                Мин. цена:
                                <span v-if="item.minPrice && parseFloat(item.price) < parseFloat(item.minPrice)" style="color: red" >{{ item.minPrice }}</span>
                                <span v-else >ok</span>
                            </div>
                        </div>
                    </div>
                </v-ons-card>
            </div>

            <div v-show="itemsByUsers.length == 0" style="text-align: center; font-size: 15px; margin-top: 50px" >Ничего не найдено</div>
        </div>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            loading: false,
            itemsByUsers: {},
            showMinPrice: true,
        }
    },
    created() {
        this.generate();
    },
    methods: {
        generate() {
            this.loading = true;

            this.$http.get('/api/manager/reports/special-prices').then(response => {
                this.loading = false;

                this.itemsByUsers.length = response.data.items.length;
                response.data.items.forEach(i => {
                    if (!this.itemsByUsers[i.user_id]) {
                        let name = i.user_id + ', ' + i.entities.join(',');
                        name = name.replace(/(,|\s)*$/, '')

                        this.itemsByUsers[i.user_id] = {
                            id: i.user_id,
                            name: name,
                            items: [],
                        };
                    }

                    this.itemsByUsers[i.user_id].items.push(i)
                });
            }, response => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });

                this.loading = false;
            });
        },
        goToUser(id) {
            window.open('/manager/users/'+ id, '_blank');
        }
    },
};
</script>