var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { visible: _vm.visible, cancelable: "" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        prehide: _vm.onPreHide
      }
    },
    [
      _c(
        "div",
        { staticStyle: { margin: "20px 5px", "text-align": "center" } },
        [
          _c("p", { staticStyle: { "margin-bottom": "15px" } }, [
            _c("b", [
              _vm._v(_vm._s(_vm.positions.length) + " товаров(-а) не хватает:")
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list",
            {
              style: {
                maxHeight: _vm.$window.height - 110 + "px",
                overflowY: "auto"
              }
            },
            _vm._l(_vm.positions, function(position) {
              return _c(
                "v-ons-list-item",
                {
                  key: position.pos_id,
                  staticStyle: { "padding-left": "10px" },
                  on: {
                    click: function($event) {
                      return _vm.scrollTo(position.pos_id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "center" }, [
                    _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(position.name) +
                          "\n                        "
                      ),
                      _c(
                        "b",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "white-space": "nowrap"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            -\n                            " +
                              _vm._s(_vm.positionRest(position)) +
                              "\n                            " +
                              _vm._s(position.unit) +
                              "\n                        "
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "right",
                      staticStyle: { "padding-right": "5px" }
                    },
                    [
                      _c("v-ons-icon", {
                        attrs: { icon: "md-edit", size: "20px" }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("v-ons-button", { on: { click: _vm.resume } }, [
                _vm._v("\n                Продолжить\n            ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "5px", color: "#757575" } },
                [_vm._v("и отправить заказ, как есть.")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f558be00", { render: render, staticRenderFns: staticRenderFns })
  }
}