<template>
    <v-ons-page>
        <v-ons-toolbar v-show="!hasChecked && !isSearchActive" >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>

            <div class="center">Перемещение/Удаление товаров</div>

            <div class="right">
                <v-ons-toolbar-button >
                    <v-ons-icon title="Разделы каталога" icon="md-filter-list" @click="chooseGroupFilter" :style="{color: filters.groups.length > 0 ? '#ffe000' : ''}" ></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button title="Поиск" @click="searchActive" >
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item text="Разделы каталога" icon="md-filter-list" @click="chooseGroupFilter" />
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="hasChecked" >
            <div class="left">
                <v-ons-toolbar-button title="Отменить выделение" @click="uncheckAll" >
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center" >
                Выбрано: {{ checked.length }}
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="isSearchActive" >
            <div class="left">
                <v-ons-toolbar-button @click="searchDeactive" >
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center" >
                <div class="search-wrap" >
                    <form action="" autocomplete="off" @submit.prevent="() => {}" >
                    <input
                        id="search-input"
                        v-model="searchQuery"
                        style="
                            color: #fff;
                            background: none;
                            border: none;
                            height: 34px;
                            line-height: 34px;
                            border-bottom: 1px solid #fff; "
                        autocomplete="off"
                    />
                    </form>
                </div>
            </div>
        </v-ons-toolbar>

        <group-filter-modal ref="groupFilterModal" />

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div id="catalog" class="content">
            <div v-if="catalog.length > 0">
                <v-ons-list v-if="isDesktop" id="list-header-catalog" class="list-catalog is-desktop">
                    <v-ons-list-item class="table-header-wrap" :style="{width: listCatalogWidth + 'px'}" >
                        <div class="col-checkbox" ></div>
                        <div class="col-name" >Наименование</div>
                        <div v-if="isDesktop && $user.isManager" class="col-rest" >Остаток</div>
                        <div class="col-price" v-show="$user.canSeePrice" >Цена</div>
                        <div v-show="showInputs && !$user.isManager" class="col-count" >Заказ</div>
                    </v-ons-list-item>
                </v-ons-list>

                <v-ons-list class="list-catalog">
                    <category-item-list
                      v-for="category in catalog"
                      :key="category.id"
                      :item="category"
                      :isDesktop="isDesktop"
                      :actionColType="actionColType"
                      :canOpenProductPage="canOpenProductPage"
                      :showInputs="showInputs"
                      :selectedPriceType="selectedPriceType"
                      :counts="counts"
                      :checked="checked"
                      :lastImportDate="lastImportDate"
                      @clickGoToView="clickGoToView"
                      @clickCategory="onClickCategory"
                    />
                </v-ons-list>

                <div style="width: 100%; height: 80px;" ></div>
            </div>

            <div v-show="catalog.length === 0" class="list-empty" :style="{height: $window.height - 56 + 'px', flexDirection: 'column'}" >
                <div style="margin-bottom: 10px; font-size: 15px;" >
                    Ничего не найдено
                </div>

                <v-ons-button @click="reset" >Сбросить</v-ons-button>
            </div>
        </div>

        <v-ons-fab :disabled="!hasChecked" @click="deleteRule" style="bottom: 90px" position="bottom right" >
            <v-ons-icon icon="md-delete"></v-ons-icon>
        </v-ons-fab>

        <v-ons-fab :disabled="!hasChecked" @click="replaceRule" position="bottom right" >
            <v-ons-icon icon="md-arrow-right"></v-ons-icon>
        </v-ons-fab>

        <list-dialog ref="categoriesDialog" title="Выберите раздел" :items="categories" />
    </v-ons-page>
</template>

<script>

import {from, merge, of} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, filter, map, pluck, switchMap, tap} from 'rxjs/operators'

import VueScrollTo from 'vue-scrollto';
import ListDialog from '~/component/ListDialog'
import CategoryItemList from '~/component/catalog/CategoryItemList'
import GroupFilterModal from '~/component/GroupFilterModal'
import $ from "jquery"

export default {
    props: {
        showInputs: {
            type: Boolean,
            default: false
        },
        canOpenProductPage: {
            type: Boolean,
            default: true
        },
        actionColType: {
            type: String,
            default: 'checkbox',
        },
    },
    data() {
        return {
            catalog: [],
            categories: [],
            filters: {
                q: '',
                groups: [],
                templates: [],
                showSpecialProducts: false,
            },
            checked: [],
            counts: {},
            loading: true,
            searchQuery: '',
            isSearchActive: false,
            isDesktop: false,
            selectedPriceType: null,
            groupBy: 'byCatalogGroup',
            lastImportDate: null,
            adding: false,
            listCatalogWidth: 0,
            isFullCatalog: false,
            searched: false,
        }
    },
    created() {
        this.isDesktop = document.body.clientWidth > 767;

        this.$http.get('/api/catalog/last-import-date').then(response => {
            this.lastImportDate = response.data;
        });

        this.$emit('update');

        this.$bus.$on('catalog-update-product', this.updateProduct);
        this.$window.$on('resize', this.updateListCatalogWidth);
        this.$on('updated', this.updateListCatalogWidth);

        this.$http.get(
            '/api/manager/catalog-settings/product-rule-categories',
            {params: {storageId: this.$route.params.storage_id}}
        ).then(response => {
            this.categories = response.data;
        }, () => {
            this.$ons.notification.toast({
                message: 'Произошла ошибка.',
                buttonLabel: 'OK'
            });
        });
    },
    destroyed() {
        this.$bus.$off('catalog-update-product', this.updateProduct);
        this.$window.$off('resize', this.updateListCatalogWidth);
        this.$off('updated', this.updateListCatalogWidth);

    },
    subscriptions () {
        const searchQuery$ = this.$watchAsObservable('searchQuery').pipe(
            filter(() => this.searchActive),
            pluck('newValue'),
            map(q => q.trim()),
            debounceTime(1000),
            tap((q) => {
                this.filters.q = q;
                this.searched = true;
            })
        )

        const searchDeactive$ = this.$eventToObservable('searchDeactive').pipe(
            tap(() => {
                this.searchQuery = '';
                this.filters.q = '';
                this.isSearchActive = false;
            }),
            map(() => this.searchQuery)
        )

        const search$ = merge(searchQuery$, searchDeactive$).pipe(
            filter(() => this.searched),
            distinctUntilChanged(),
            tap(() => {
                this.loading = true;
                this.items = [];
            })
        )

        const update$ = this.$eventToObservable('update').pipe(
            debounceTime(100),
            tap(() => {
                this.loading = true;
                this.items = [];
            })
        )

        return {
            results: merge(search$, update$).pipe(
                switchMap(() => from(
                    this.$http.get(
                        '/api/catalog/categories',
                        {
                            params: {
                                filters: this.filters,
                                groupBy: this.groupBy,
                                storageId: this.$route.params.storage_id,
                            }
                        }
                    )
                )),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of([]);
                }),
                tap(categories => {
                    this.loading = false;
                    this.catalog = categories;
                    this.$emit('updated');
                }),
            )
        }
    },
    methods: {
        chooseGroupFilter() {
            this.$refs.groupFilterModal.show().then(response => {
                this.filters.groups = response;
                this.$emit('update');
            })
        },
        deleteRule() {
            let checked = this.checked;
            this.checked = [];
            this.loading = true;

            this.$http.post(
                '/api/manager/catalog-settings/product-rules',
                {ids: checked, type: 'delete'},
                {params: {storageId: this.$route.params.storage_id}}
            ).then(() => {
                this.$bus.$emit('update');
                this.$router.go(-1);
            }, () => {
                this.loading = false;
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        replaceRule() {
            this.$refs.categoriesDialog.show().then(selected => {
                let checked = this.checked;
                this.checked = [];
                this.loading = true;

                this.$http.post(
                    '/api/manager/catalog-settings/product-rules',
                    {ids: checked, type: 'replace', category: selected},
                    {params: {storageId: this.$route.params.storage_id}}
            ).then(() => {
                    this.$bus.$emit('update');
                    this.$router.go(-1);
                }, () => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        buttonLabel: 'OK',
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        searchActive() {
            this.isSearchActive = true;
            this.$nextTick(() => document.getElementById("search-input").focus());
        },
        searchDeactive() {
            this.$emit('searchDeactive');
        },
        uncheckAll() {
            this.checked = [];
        },
        reset() {
            this.isSearchActive = false;
            this.filters = {
                q: '',
                groups: [],
                templates: [],
                showSpecialProducts: false,
            };
            this.$emit('update');
        },
        updateListCatalogWidth() {
            this.$nextTick(() => {
                let listCatalog = document.getElementById("list-header-catalog");
                if (!listCatalog) {
                    return;
                }
                this.listCatalogWidth = listCatalog.offsetWidth;
            });
        },
        clickGoToView(position) {
            if (!this.checked.includes(position.id)) {
                this.checked.push(position.id)
            } else {
                this.checked.splice(this.checked.indexOf(position.id), 1);
            }
        },
        onClickCategory(category) {
            let openCategory = this.catalog.find(c => c.isOpen)

            if (openCategory && openCategory.id === category.id) {
                return
            }

            let options = {
                container: '#catalog',
                easing: 'ease-in',
                lazy: false,
                offset: -28,
                force: true,
                cancelable: true,
                x: false,
                y: true
            }

            this.$nextTick(() => {
                VueScrollTo.scrollTo('#category-' + category.id, 100, options)
            })

            for (let c of this.catalog) {
                c.isOpen = false
            }

            if (category.products.length !== 0) {
                category.isOpen = true
                return
            }

            category.loading = true

            this.$http.get(
                '/api/catalog/get-product-by-category', {
                    params: {
                        filters: this.filters,
                        groupBy: this.groupBy,
                        storageId: this.$user.selectedStorage,
                        categoryId: category.id,
                    }
                }
            ).then(response => {
                category.products = response.data
                category.isOpen = true
                category.loading = false
                VueScrollTo.scrollTo('#category-' + category.id, 100, options)
                console.log($('.content.page__content'))
            })
        },
    },
    computed: {
        hasChecked() {
            return this.checked.length > 0;
        },
    },
    components: {
        GroupFilterModal,
        ListDialog,
        CategoryItemList,
    },
};
</script>
