<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button title="Назад" ></v-ons-back-button>
            </div>
            <div class="center">Оформление заказа</div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="!loaded" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <v-ons-list>
                <v-ons-list-item class="list-header--material" >
                    <div class="center" style="background: none" >
                        Способ отгрузки
                    </div>
                    <div class="right" v-show="shippingMethods.length !== allShippingMethods.length" @click="showAllShippingMethods" style="background: none" >
                        <v-ons-icon icon="md-tag-more" size="20px" ></v-ons-icon>
                    </div>
                </v-ons-list-item>

                <v-ons-list-item v-for="method in getShippingMethods" tappable :key="method.id" >
                    <label class="left">
                        <v-ons-radio :input-id="'radio-' + method.id" :value="method.id" name="shipping" v-model="shippingMethod" ></v-ons-radio>
                    </label>
                    <label :for="'radio-' + method.id" class="center">{{ method.name }}</label>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list v-show="shippingMethod === 'delivery'" >
                <v-ons-list-header>Адрес доставки</v-ons-list-header>

                <v-ons-list-item v-for="brn in branches" tappable :key="brn.id" >
                    <label class="left">
                        <v-ons-radio
                            :input-id="'radio-' + brn.id"
                            :value="brn.id"
                            name="branch"
                            v-model="branch" ></v-ons-radio>
                    </label>
                    <label :for="'radio-' + brn.id" class="center">
                        <span class="list-item__title">{{ brn.shortAddress }}</span>
                        <span v-show="brn.route && brn.route.id == 2" style="color: #ef3e29" class="list-item__subtitle">
                            Доставка не осуществляется
                        </span>
                        <span v-show="!brn.route" style="color: #ef3e29" class="list-item__subtitle">
                            Для этого адреса еще не согласован маршрут и график доставки.
                        </span>
                        <span v-show="!availBranchByOrderCost(brn)" style="color: #ef3e29" class="list-item__subtitle">
                            Минимальная сумма для заказа: {{ brn.route ? brn.route.refusal_delivery_to : '' }} руб.
                        </span>
                        <span v-show="hasDeliveryCharge(brn)" style="color: #ef3e29" class="list-item__subtitle">
                            Стоимость доставки: {{ brn.route ? brn.route.delivery_cost : '' }} руб.
                        </span>
                    </label>

                    <label class="right" v-show="!brn.route" @click="linkRoute(brn)" >
                        <span class="notify-icon" style="font-size: 13px;">
                            <v-ons-icon icon="md-gps-dot"></v-ons-icon>
                        </span>
                    </label>
                </v-ons-list-item>

                <v-ons-list-item v-show="!branches.length" >
                    <v-ons-button @click="$router.push({name: 'order-checkout-create-branch'})" >Добавить адрес</v-ons-button>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list v-show="canShowShippingDate" >
                <v-ons-list-header v-if="shippingMethod === 'delivery'" >Дата доставки</v-ons-list-header>
                <v-ons-list-header v-if="shippingMethod === 'pickup'" >Дата самовывоза</v-ons-list-header>

                <v-ons-list-item @click="openShippingDateDialog" >
                    <div style="width: 311px; border-bottom: 1px solid #afafaf; line-height: 28px;" >
                        <v-ons-icon icon="md-calendar" style="margin-right: 8px; font-size: 20px; vertical-align: middle;" ></v-ons-icon>
                        {{ shippingDate | moment("DD.MM.YYYY") }}
                    </div>

                    <v-ons-dialog cancelable :visible.sync="isShippingDateDialogVisible" >
                        <v-ons-toolbar inline="true" >
                            <div class="center" >
                                <div v-if="shippingMethod === 'delivery'" >Дата доставки</div>
                                <div v-if="shippingMethod === 'pickup'" >Дата самовывоза</div>
                            </div>
                            <div class="right" >
                                <v-ons-toolbar-button @click="isShippingDateDialogVisible = false;" >
                                    <v-ons-icon icon="md-close"></v-ons-icon>
                                </v-ons-toolbar-button>
                            </div>
                        </v-ons-toolbar>

                        <datepicker
                            v-model="shippingDate"
                            :inline="true"
                            :monday-first="true"
                            :language="ru"
                            :disabledDates="{customPredictor: disabledDates}"
                            @selected="isShippingDateDialogVisible = false"
                        ></datepicker>
                    </v-ons-dialog>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list v-show="whetherShowEntityList" >
                <v-ons-list-item class="list-header--material" >
                    <div class="center" style="background: none" >
                        Юр. лицо
                    </div>
                    <div class="right" v-show="getEntities.length !== entities.length" @click="showAllEntities" style="background: none" >
                        <v-ons-icon icon="md-tag-more" size="20px" ></v-ons-icon>
                    </div>
                </v-ons-list-item>

                <v-ons-list-item v-for="ent in getEntities" tappable :key="ent.id" >
                    <label class="left">
                        <v-ons-radio
                            :input-id="'entity-' + ent.id"
                            :value="ent.id"
                            name="entity"
                            v-model="entity" ></v-ons-radio>
                    </label>
                    <label :for="'entity-' + ent.id" class="center">
                        <span class="list-item__title">{{ ent.legal_name }}</span>
                    </label>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list v-show="whetherShowAssignEntityCheckbox" >
                <v-ons-list-item>
                    <label for="assign-entity-checkbox" class="left">
                        <v-ons-checkbox
                            input-id="assign-entity-checkbox"
                            v-model="isNeedToAssignEntity"
                            :disabled="!entity"
                            ></v-ons-checkbox>
                    </label>
                    <label for="assign-entity-checkbox" class="center label-reset" >
                        Привязать это юр.лицо к филиалу
                    </label>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list v-show="whetherShowPaymentMethods" >
                <v-ons-list-item class="list-header--material" >
                    <div class="center" style="background: none" >
                        Способ оплаты
                    </div>
                    <div class="right" v-show="getPaymentMethods.length !== paymentMethods.length" @click="showAllPaymentMethods" style="background: none" >
                        <v-ons-icon icon="md-tag-more" size="20px" ></v-ons-icon>
                    </div>
                </v-ons-list-item>


                <v-ons-list-item v-for="method in getPaymentMethods" tappable :key="method.id" >
                    <label class="left">
                        <v-ons-radio :input-id="'paymentMethod-' + method.id" :value="method.id" name="paymentMethod" v-model="paymentMethod" ></v-ons-radio>
                    </label>
                    <label :for="'paymentMethod-' + method.id" class="center">
                        <span class="list-item__title">{{ method.name }}</span>
                        <span v-show="method.id == 2 && method.tax > 0" style="color: #ef3e29" class="list-item__subtitle">
                            Доп. комиссия +{{ parseFloat(method.tax) }}%
                        </span>
                    </label>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Комментарий</v-ons-list-header>
                <v-ons-list-item>
                    <textarea
                        class="textarea"
                        placeholder="Комментарий к заказу для менеджера"
                        v-model="manager_comment"
                        style="width: 100%; height: 100px;"
                    ></textarea>
                </v-ons-list-item>
            </v-ons-list>

            <div style="width: 100%; height: 50px;" ></div>
        </div>

        <v-ons-dialog :visible.sync="splashVisible" >
            <v-ons-row vertical-align="center" >
                <v-ons-col width="70px" style="text-align: center"  >
                    <v-ons-progress-circular indeterminate></v-ons-progress-circular>
                </v-ons-col>

                <v-ons-col>
                    <div style="line-height: 100px" >Отправка заказа...</div>
                </v-ons-col>
            </v-ons-row>
        </v-ons-dialog>

        <pre-send-order-dialog ref="preSendOrderDialog" />

        <v-ons-fab position="bottom right" v-show="loaded" @click="preSendOrder" :disabled="!canSend" >
            <v-ons-icon icon="md-mail-send" title="Отправить заказ" ></v-ons-icon>
        </v-ons-fab>

        <v-radio-dialog ref="radioDialog" title="Опции менеджера" />
        <v-send-order-dialog ref="sendOrderDialog" />

        <v-list-dialog
            ref="linkRouteDialog"
            title="Привязать маршрут"
            :items="routes" />
    </v-ons-page>
</template>

<script>

import {from, merge} from 'rxjs'
import {debounceTime, distinctUntilChanged, filter, pluck, switchMap} from 'rxjs/operators'

import SendOrderDialog from './SendOrderDialog'
import PreSendOrderDialog from './PreSendOrderDialog';
import ListDialog from '~/component/ListDialog'
import RadioDialog from '~/component/RadioDialog'
import Datepicker from 'vuejs-datepicker';
import {ru} from 'vuejs-datepicker/dist/locale'

export default {
    data() {
        return {
            orderId: null,
            order: {},
            shippingMethod: null,
            shippingDate: null,
            paymentMethod: null,
            branch: null,
            entity: null,
            isShippingDateDialogVisible: false,
            availDays: [],
            isNeedToAssignEntity: false,

            shippingMethods: [],
            allShippingMethods: [],
            paymentMethods: [],
            branches: [],
            entities: [],
            allEntities: [],
            routes: [],
            manager_comment: '',
            splashVisible: false,
            ru: ru,

            loaded: false,
            sended: false,
        }
    },
    created() {
        this.orderId = this.$route.params.order_id;

        this.load();
        this.$bus.$on('load-branches', this.loadBranches);
    },
    beforeDestroy() {
        if (this.order == null || !this.orderId || this.sended) {
            return;
        }

        this.$http.post(`/api/manager/orders/${this.orderId}/checkout`, this.checkoutData);
    },
    subscriptions () {
        const shippingMethod$ = this.$watchAsObservable('shippingMethod').pipe(
            pluck('newValue'),
            filter(q => q),
            distinctUntilChanged(),
        )

        const entity$ = this.$watchAsObservable('entity').pipe(
            pluck('newValue'),
            distinctUntilChanged(),
            filter(q => q),
        )

        const branch$ = this.$watchAsObservable('branch').pipe(
            pluck('newValue'),
            distinctUntilChanged(),
            filter(q => q),
        )

        const shippingDate$ = this.$watchAsObservable('shippingDate').pipe(
            pluck('newValue'),
            distinctUntilChanged(),
            filter(q => q),
        )

        const paymentMethod$ = this.$watchAsObservable('paymentMethod').pipe(
            pluck('newValue'),
            distinctUntilChanged(),
            filter(q => q),
        )

        const comment$ = this.$watchAsObservable('manager_comment').pipe(
            pluck('newValue'),
            distinctUntilChanged(),
            filter(q => q),
        )

        const checkoutData$ = merge(
            shippingMethod$,
            entity$,
            branch$,
            shippingDate$,
            paymentMethod$,
            comment$).pipe(
                filter(() => this.loaded),
                debounceTime(1000),
            )

        return {
            results: checkoutData$.pipe(
                filter(() => !this.sended),
                switchMap(() => from(
                    this.$http.post(
                        `/api/manager/orders/${this.orderId}/checkout`,
                        this.checkoutData,
                        {params: {id: this.orderId}}
                    )
                )),
            )
        }
    },
    methods: {
        load() {
            this.$http.get(`/api/manager/orders/${this.orderId}/checkout`).then(response => {
                let data = response.data;

                this.order = data.order;
                this.shippingMethods = data.shippingMethods;
                this.allShippingMethods = data.allShippingMethods;
                this.paymentMethods = data.paymentMethods;
                this.allPaymentMethods = data.allPaymentMethods;
                this.manager_comment = data.manager_comment;
                this.branch = data.branch;
                this.entity = data.entity;
                this.branches = data.branches;
                this.entities = data.entities;
                this.shippingMethod = data.shippingMethod;
                this.availDays = data.availDays;
                this.routes = data.routes;

                this.updatePaymentDefault();

                // костыль
                let paymentMethod = data.paymentMethod;
                this.$nextTick(() => {
                    if (data.shippingDate) {
                        const [year, month, day] = data.shippingDate.split("-");
                        this.shippingDate = new Date(year, month - 1, day);
                        if (this.disabledDates(this.shippingDate)) {
                            this.shippingDate = null;
                        }
                    }

                    this.paymentMethod = null;
                    this.$nextTick(() => {
                        this.paymentMethod = paymentMethod;
                        this.loaded = true;
                    });
                });
            });
        },
        loadBranches() {
            this.$http.get(`/api/manager/orders/${this.orderId}/checkout`, {params: {id: this.orderId}}).then(response => {
                let data = response.data;
                this.branches = data.branches;
            });
        },
        updateShippingDate() {
            let startDay = new Date();
            startDay.setHours(0, 0, 0, 0);
            this.shippingDate = null;

            for (let i = 0; i < 30; i++) {
                if (!this.disabledDates(startDay)) {
                    this.shippingDate = startDay;
                    break;
                }

                startDay.setDate(startDay.getDate() + 1);
            }
        },
        disabledDates(date) {
            let day = ("0" + date.getDate()).slice(-2) + '.' + ("0" + (date.getMonth() + 1)).slice(-2);

            if (this.shippingMethod === 'pickup' && this.availDays['pickup']) {
                return this.availDays['pickup'].indexOf(day) === -1;
            }

            if (this.shippingMethod === 'delivery') {
                if (!this.branch) {
                    return false;
                }

                let branches = this.branches.find(i => i.id.toString() === this.branch);
                if (!branches || !branches.route || !this.availDays['route-' + branches.route.id]) {
                    return false;
                }

                return this.availDays['route-' + branches.route.id].indexOf(day) === -1;
            }

            return false;
        },
        openShippingDateDialog() {
            this.isShippingDateDialogVisible = true
        },
        preSendOrder() {
            if (!this.canSend) {
                return;
            }

            if (!this.shippingMethod && this.shippingMethods.length > 0) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Выберите способ доставки',
                    buttonLabel: 'OK'
                });
                return false;
            }

            if (this.shippingMethod === 'delivery' && !this.branch) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Выберите адрес доставки',
                    buttonLabel: 'OK'
                });
                return false;
            }

            if (this.getEntities.length > 0 && !this.entity) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Выберите юр. лицо',
                    buttonLabel: 'OK'
                });
                return false;
            }

            if (!this.paymentMethod && this.getPaymentMethods.length > 0) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Выберите способ оплаты',
                    buttonLabel: 'OK'
                });
                return false;
            }

            if (this.shippingMethod === 'pickup' && !this.shippingDate) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Выберите дату самовывоза',
                    buttonLabel: 'OK'
                });
                return false;
            }

            if (this.shippingMethod === 'delivery' && !this.shippingDate) {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Выберите дату доставки',
                    buttonLabel: 'OK'
                });
                return false;
            }

            this.$refs.sendOrderDialog.show().then(response => {
                this.sendOrder(response.withNoty);
            });
        },
        sendOrder(withNoty) {
            this.splashVisible = true;

            if (this.shippingMethod === 'pickup' && this.branches.length === 1) {
                this.branch = this.branches[0].id;
            }

            let shippingDate = '';

            if (this.shippingDate) {
                shippingDate = this.$moment(this.shippingDate).format("YYYY-MM-DD");
            }

            let data = {
                shippingMethod: this.shippingMethod,
                branch: this.branch,
                entity: this.entity,
                shippingDate: shippingDate,
                paymentMethod: this.paymentMethod,
                manager_comment: this.manager_comment,
                isNeedToAssignEntity: this.isNeedToAssignEntity,
            }

            this.$http.post(`/api/manager/orders/${this.orderId}/send`, {checkout: data, withNoty: withNoty}).then(() => {
                this.sended = true;
                this.$bus.$emit('order-sended');
                this.$router.push({
                    name: 'manager-user-orders',
                });

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Заказ отправлен',
                    buttonLabel: 'OK'
                });
            }, (response) => {
                this.splashVisible = false;

                if (response.data === 'already_sended') {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Заказ уже отправлен',
                        buttonLabel: 'OK'
                    });

                    this.$router.push({
                        name: 'order-list',
                    });
                    return;
                }

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Не удалось отправить заказ',
                    buttonLabel: 'OK'
                });
            });
        },
        availBranchByOrderCost(branch) {
            if (!branch.route) {
                return true;
            }

            return this.order.totalCost >= branch.route.refusal_delivery_to;
        },
        hasDeliveryCharge(branch) {
            if (!branch.route) {
                return false;
            }

            let route = branch.route;
            let totalCost = this.order.totalCost;

            return totalCost < route.free_delivery_from;
        },
        hasAddressError(branch) {
            if (!branch.route) {
                return true;
            }

            // маршрут "Нет доставки"
            if (branch.route.id == 2) {
                return true;
            }

            if (!this.availBranchByOrderCost(branch)) {
                return true;
            }

            return this.hasDeliveryCharge(branch);
        },
        updatePaymentDefault() {
            if (!this.entity) {
                this.paymentMethod = null;
                return;
            }

            let entity = this.entities.find(entity => entity.id.toString() === this.entity);

            if (!entity) {
                this.paymentMethod = null;
                return;
            }

            let defaultPaymentMethod = entity.default_payment_method;

            if (this.getPaymentMethods.find(paymentMethod => paymentMethod.id == defaultPaymentMethod)) {
                this.paymentMethod = defaultPaymentMethod.toString();
            } else {
                this.paymentMethod = null;
            }
        },
        updateEntityDefault() {
            if (!this.shippingMethod) {
                this.entity = null;
                return;
            }

            if (this.shippingMethod === 'delivery') {
                if (!this.branch) {
                    this.entity = null;
                    return;
                }

                let branch = this.branches.find(branch => branch.id.toString() === this.branch);

                if (branch.entities.length === 0) {
                    this.entity = null;
                    return;
                }

                if (branch.entities.length === 1 && this.getEntities.length === 1) {
                    this.entity = branch.entities[0].id.toString();
                    return;
                }

                if (!this.getEntities.find(entity => entity.id.toString() === this.entity)) {
                    this.entity = null;
                    return;
                }
            }

            if (this.shippingMethod === 'pickup') {
                if (!this.entities.find(entity => entity.id.toString() === this.entity)) {
                    this.entity = null;
                }

                if (!this.entity && this.entities.length === 1) {
                    this.entity = this.entities[0].id.toString();
                }
            }
        },
        showAllShippingMethods() {
            let params = {}

            params.selected = this.shippingMethod;
            params.items = this.allShippingMethods;

            this.$refs.radioDialog.show(params).then(response => {
                this.shippingMethod = response;
            });
        },
        showAllEntities() {
            let params = {}

            params.selected = this.entity;
            params.items = this.entities.map(i => {
                return {
                    id: i.id,
                    name: i.legal_name,
                }
            });

            this.$refs.radioDialog.show(params).then(response => {
                this.entity = response;
            });
        },
        showAllPaymentMethods() {
            let params = {}

            params.selected = this.paymentMethod;
            params.items = this.allPaymentMethods;

            this.$refs.radioDialog.show(params).then(response => {
                this.paymentMethod = response;
            });
        },
        pushSelectedUnavailableItem(value, generalItems, allItems) {
            let items = generalItems.filter(() => true);

            if (value) {
                if (!generalItems.find(i => i.id == value)) {
                    items.push(
                        allItems.find(i => i.id == value)
                    )
                }
            }

            return items;
        },
        linkRoute(branch) {
            this.$refs.linkRouteDialog.show().then(response => {
                this.$http.post(
                    `/api/manager/users/${this.order.user_id}/branches/${branch.id}/link-route`,
                    {route: response}
                ).then(() => {
                    this.$http.get(
                        `/api/manager/orders/${this.orderId}/checkout`
                    ).then(response => {
                        let data = response.data;
                        this.branches = data.branches;
                        this.availDays = data.availDays;

                        if (this.branch == branch.id) {
                            this.updateShippingDate();
                        }
                    });
                }, () => {
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                })
            })
        },
    },
    computed: {
        canSend() {
            if (!this.order || !this.order.positions) {
                return false;
            }

            return this.order.positions.filter((i => i.name && i.count > 0)).length !== 0;
        },
        getShippingMethods() {
            return this.pushSelectedUnavailableItem(
                this.shippingMethod,
                this.shippingMethods,
                this.allShippingMethods,
            );
        },
        getPaymentMethods() {
            let paymentMethods = this.paymentMethods;

            let entity = this.entities.find(i => this.entity == i.id);

            if (this.shippingMethod) {
                paymentMethods = paymentMethods.filter(i => {
                    return i.allow_by_shipping_method.includes(this.shippingMethod);
                });
            }

            // Если выбрана опция "Оф. договор", то добавляется способ оплаты: предоплата б/н
            paymentMethods = paymentMethods.filter(payment => {
                if (payment.id != 6) {
                    return true;
                }

                return !(!entity || !entity.contracts.find(c => c.id === 1));
            });

            // Если выбрана опция "договор отсрочки" то добавляется способ оплаты:
            // - наличными с отсрочкой
            // - на карту с отсрочкой
            paymentMethods = paymentMethods.filter(payment => {
                if (![4, 8].includes(payment.id)) {
                    return true;
                }

                return !(!entity || !entity.contracts.find(c => c.id === 2));
            });

            // Если выбраны опции "Оф. договор" и "договор отсрочки" то добавляется способ оплаты: оплата б/н с отсрочкой
            paymentMethods = paymentMethods.filter(payment => {
                if (payment.id != 7) {
                    return true;
                }

                return !(!entity || !entity.contracts.find(c => c.id === 1) || !entity.contracts.find(c => c.id === 2));
            });

            paymentMethods = paymentMethods.filter(payment => {
                if (!entity) {
                    return true;
                }

                return !entity.blocked_payment_methods.includes(payment.id.toString());
            });

            if (entity && entity.default_payment_method) {
                let defaultPaymentMethod = entity.default_payment_method.toString();

                paymentMethods.sort((a, b) => a.id == defaultPaymentMethod ? -1 : b.id == defaultPaymentMethod ? 1 : 0);
            }

            return this.pushSelectedUnavailableItem(
                this.paymentMethod,
                paymentMethods,
                this.allPaymentMethods,
            );
        },
        getEntities() {
            if (this.shippingMethod === 'pickup') {
                return this.entities;
            }

            if (this.shippingMethod !== 'delivery') {
                return this.entities;
            }

            if (!this.branch) {
                return this.entities;
            }

            let branch = this.branches.find(i => i.id == this.branch);

            // Показываем весь список юр.лиц, если он не пуст и если ничего не привязано к филиалу.
            // Для того чтобы была возможность привязать.
            if (branch.entities.length === 0 && this.entities.length > 0) {
                return this.entities;
            }

            return this.pushSelectedUnavailableItem(
                this.entity,
                branch.entities,
                this.entities,
            );
        },
        canShowShippingDate() {
            if (this.shippingMethod === 'pickup') {
                return true;
            }

            if (!this.branch) {
                return false;
            }

            let branch = this.branches.find(i => i.id.toString() === this.branch);
            return branch.route;
        },
        checkoutData() {
            let shippingDate = '';

            if (this.shippingDate) {
                shippingDate = this.$moment(this.shippingDate).format("YYYY-MM-DD");
            }

            return {
                shippingMethod: this.shippingMethod,
                branch: this.branch,
                entity: this.entity,
                shippingDate: shippingDate,
                paymentMethod: this.paymentMethod,
                manager_comment: this.manager_comment,
            }
        },
        whetherShowPaymentMethods() {
            if (!this.entity && this.getEntities.length > 1) {
                return false;
            }

            return this.shippingMethod;
        },
        whetherShowEntityList() {
            if (!this.shippingMethod) {
                return false;
            }

            if (this.shippingMethod === 'pickup') {
                return this.entities.length > 0;
            }

            // shippingMethod = 'delivery'
            if (!this.branch) {
                return false;
            }

            let branch = this.branches.find(i => i.id == this.branch);

            if (branch.entities.length === 0 && this.entities.length > 0) {
                return true;
            }

            return branch.entities.length > 0;
        },
        whetherShowAssignEntityCheckbox() {
            if (this.shippingMethod === 'pickup') {
                return false;
            }

            if (!this.branch) {
                return false;
            }

            let branch = this.branches.find(i => i.id == this.branch);

            return branch.entities.length === 0 && this.entities.length > 0
        },
    },
    watch: {
        shippingMethod() {
            this.updateEntityDefault();
            this.updatePaymentDefault();

            // костыль
            let paymentMethod = this.paymentMethod;
            this.$nextTick(() => {
                this.updateShippingDate();
                this.paymentMethod = null;
                this.$nextTick(() => {
                    this.paymentMethod = paymentMethod;
                });
            });

            if (this.shippingMethod === 'pickup') {
                this.isNeedToAssignEntity = false;
            }
        },
        entity() {
            this.updatePaymentDefault();

            // костыль
            let paymentMethod = this.paymentMethod;
            this.$nextTick(() => {
                this.updateShippingDate();
                this.paymentMethod = null;
                this.$nextTick(() => {
                    this.paymentMethod = paymentMethod;
                });
            });

            if (!this.entity) {
                this.isNeedToAssignEntity = false;
            }
        },
        branch() {
            this.$nextTick(() => {
                this.updateEntityDefault();
                this.updateShippingDate();
            });
        },
    },
    components: {
        Datepicker,
        PreSendOrderDialog,
        'v-radio-dialog': RadioDialog,
        'v-send-order-dialog': SendOrderDialog,
        'v-list-dialog': ListDialog,
    },
};
</script>