<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center" >
                <div v-show="!isSearchActive" >
                    {{ product.name }}
                </div>

                <div v-show="isSearchActive" class="search-wrap" >
                    <input id="search-input" v-model="searchQuery" style="
                        color: #fff;
                        background: none;
                        border: none;
                        height: 34px;
                        line-height: 34px;
                        border-bottom: 1px solid #fff;
                    " />
                </div>
            </div>

            <div class="right">
                <v-ons-toolbar-button v-show="!isSearchActive" @click="searchActive" >
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="isSearchActive" @click="isSearchActive = false" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content" >
            <div v-if="items.length > 0">
                <v-ons-list>
                    <v-ons-lazy-repeat
                        ref="lazyRepeat"
                        :render-item="renderItem"
                        :length="items.length"
                    >
                    </v-ons-lazy-repeat>
                </v-ons-list>
            </div>

            <div style="height: 100px;" ></div>
        </div>

        <v-ons-fab v-show="!loading" position="bottom left" class="fab-text" @click="setArchive" >
            Удалить
        </v-ons-fab>
    </v-ons-page>
</template>

<script>
import Vue from "vue";
import ProductAvailLinkItem from "~/screen/manager/component/ProductAvailLinkItem";

export default {
    data() {
        return {
            loading: true,
            isSearchActive: false,
            searchQuery: null,
            searchedItems: [],
            product: {},
            replacements: [],
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            let requests = [
                this.$http.get('/api/manager/product-avail/get-product', {params: {id: this.$route.params.product_id}}),
                this.$http.get(
                    '/api/manager/product-link/get-replacements',
                    {
                        params: {
                            id: this.$route.params.product_id,
                            storageId: this.$route.params.storage_id,
                        }
                    }
                ),
            ];

            Promise.all(requests).then(responses => {
                this.product = responses[0].data;
                this.replacements = responses[1].data;

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        choose(product) {
            this.$bus.$emit('chooseProduct', {product_id: this.product.id, replacement: product});
            this.$router.go(-1);
        },
        searchActive() {
            this.isSearchActive = true;
            this.searchQuery = '';
            this.$nextTick(() => document.getElementById("search-input").focus());
        },
        setArchive() {
            this.$bus.$emit('setArchive', {product_id: this.product.id});
            this.$router.go(-1);
        },
        renderItem(i) {
            let self = this;
            let item = this.items[i];

            return new Vue({
                render(h) {
                    return h(ProductAvailLinkItem, {
                        props: {
                            product: item,
                        },
                        on: {
                            choose: self.choose,
                        },
                    });
                }
            });
        },
    },
    computed: {
        items() {
            return this.replacements
                .filter((prd) => {
                    if (this.isSearchActive && this.searchQuery) {
                        return this.searchedItems.indexOf(prd.id) !== -1;
                    }

                    return true;
                });
        }
    },
    watch: {
        searchQuery() {
            if (!this.searchQuery) {
                return;
            }

            this.$http.get('/api/product/search', {params: {text: this.searchQuery}}).then(response => {
                this.searchedItems = response.data.map(i => i.id);
            });
        },
    }
};
</script>