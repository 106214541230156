var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "manager-order-position-view" }, [
    _c("td", [_vm._v(_vm._s(_vm.index))]),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "col-name cursor-pointer",
        on: {
          click: function($event) {
            return _vm.$router.push(
              _vm.$router.currentRoute.path + "/" + _vm.position.prd_id
            )
          }
        }
      },
      [_vm._v("\n        " + _vm._s(_vm.position.name) + "\n    ")]
    ),
    _vm._v(" "),
    _c("td", { staticClass: "col-count" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.position.count) +
          " " +
          _vm._s(_vm.position.unit) +
          "\n    "
      )
    ]),
    _vm._v(" "),
    _vm.hasDiscount
      ? _c("td", { staticClass: "col-price" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.position.price_discount) +
              " руб.\n        "
          ),
          _c("br"),
          _vm._v(" "),
          _c("small", { staticStyle: { "font-size": "11px" } }, [
            _vm._v("(" + _vm._s(_vm.position.price) + ")")
          ])
        ])
      : _c("td", { staticClass: "col-price" }, [
          _vm._v(_vm._s(_vm.position.price) + " руб.")
        ]),
    _vm._v(" "),
    _c(
      "td",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowColAction,
            expression: "isShowColAction"
          }
        ],
        staticClass: "col-action",
        on: { click: _vm.startEdit }
      },
      [
        _c("v-ons-icon", {
          style: _vm.editable.length > 0 ? "color: #a5a5a5;" : "",
          attrs: { icon: "md-edit" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6b82a74b", { render: render, staticRenderFns: staticRenderFns })
  }
}