var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.templates.length,
              expression: "!templates.length"
            }
          ],
          staticClass: "content"
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "20px",
                color: "#a8a8a8",
                position: "absolute",
                top: "50%",
                "text-align": "center",
                width: "100%",
                "line-height": "50px",
                "margin-top": "-25px"
              }
            },
            [_vm._v("\n            Список пуст\n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.templates.length > 0,
              expression: "templates.length > 0"
            }
          ]
        },
        _vm._l(_vm.templates, function(template) {
          return _c(
            "v-ons-list-item",
            { key: template.id, staticClass: "cursor-pointer" },
            [
              _c(
                "div",
                {
                  staticClass: "center",
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        _vm.$router.currentRoute.path +
                          "/template/" +
                          template.id
                      )
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(template.name) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("v-ons-icon", {
                    staticClass: "list-item__icon",
                    staticStyle: { margin: "0 4px", cursor: "pointer" },
                    attrs: { icon: "md-delete" },
                    on: {
                      click: function($event) {
                        return _vm.deleteTemplate(template)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("v-ons-icon", {
                    staticClass: "list-item__icon",
                    staticStyle: { margin: "0 4px", cursor: "pointer" },
                    attrs: { icon: "md-edit" },
                    on: {
                      click: function($event) {
                        return _vm.showRenameTemplateDialog(template)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ebecceb4", { render: render, staticRenderFns: staticRenderFns })
  }
}