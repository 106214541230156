<template>
    <v-ons-page>
        <div class="login-page" >
            <div v-show="view == 'error'" class="text-center" >
                <v-ons-card>
                    Ссылка для смены пароля неправильна или устарела. Пожалуйста, попробуйте запросить новую ссылку.
                </v-ons-card>

                <v-ons-button @click="$router.push({name: 'login'})" >Назад</v-ons-button>
            </div>

            <div v-show="view == 'password'" class="form" >
                <div class="form-group" >
                    <v-ons-password v-model="password" name="password" placeholder="Новый пароль" />
                </div>

                <div class="text-center" >
                    <v-ons-button @click="submit" >Готово</v-ons-button>
                </div>
            </div>

            <div v-show="view == 'sended'" class="text-center" >
                <v-ons-card>
                    Ваш пароль был успешно изменен.
                </v-ons-card>

                <v-ons-button @click="$router.push({name: 'login'})" >Ok</v-ons-button>
            </div>
        </div>

        <v-ons-dialog :visible.sync="loading" >
            <v-ons-row vertical-align="center" >
                <v-ons-col width="70px" style="text-align: center"  >
                    <v-ons-progress-circular indeterminate></v-ons-progress-circular>
                </v-ons-col>

                <v-ons-col>
                    <div style="line-height: 100px" >Загрузка...</div>
                </v-ons-col>
            </v-ons-row>
        </v-ons-dialog>
    </v-ons-page>
</template>

<script>

import PasswordInput from '~/component/PasswordInput'

export default {
    data() {
        return {
            loading: true,
            view: null,
            password: null,
            code: null,
        }
    },
    created() {
        this.code = this.$route.params.code;
        this.checkToken();
    },
    methods: {
        checkToken() {
            this.$http.get('/api/auth/check-token', {params: {code: this.code}}).then(() => {
                this.loading = false;
                this.view = 'password';
            }, () => {
                this.loading = false;
                this.view = 'error';
            });
        },
        submit() {
            if (this.loading) {
                return;
            }

            if (!this.password) {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Укажите пароль',
                });
                return;
            }

            this.loading = true;

            this.$http.post('/api/auth/reset', {password: this.password}, {params: {code: this.code}}).then(response => {
                this.loading = false;
                this.view = 'sended';

            }, response => {
                this.loading = false;

                if (response.status == 401) {
                    this.$ons.notification.toast({
                        timeout: 3000,
                        message: response.data.message,
                        buttonLabel: 'OK',
                    });
                    return;
                }

                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Произошла ошибка',
                    buttonLabel: 'OK',
                });
            });
        },
        back() {
            this.$router.go(-1);
        },
    },
    components: {
        'v-ons-password': PasswordInput,
    },
};
</script>

