var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("users-list", {
    attrs: {
      title: "Клиенты",
      url: "/api/manager/users",
      shouldShowCreateUserButton: "true",
      shouldShowViewParams: "true"
    },
    on: { choose: _vm.choose }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-37234bff", { render: render, staticRenderFns: staticRenderFns })
  }
}