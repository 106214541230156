var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Смена пароля")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { attrs: { disabled: !_vm.canSended }, on: { click: _vm.send } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("Текущий пароль")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-list-item"),
          _vm._v(" "),
          _c("v-ons-list-header", [_vm._v("Новый пароль")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.newPassword,
                  callback: function($$v) {
                    _vm.newPassword = $$v
                  },
                  expression: "newPassword"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2a8ab96d", { render: render, staticRenderFns: staticRenderFns })
  }
}