var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-alert-dialog",
    {
      staticClass: "change-password-modal",
      attrs: { modifier: "rowfooter", visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("Новый пароль")
      ]),
      _vm._v(" "),
      _c("v-ons-input", {
        model: {
          value: _vm.password,
          callback: function($$v) {
            _vm.password = $$v
          },
          expression: "password"
        }
      }),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("v-ons-alert-dialog-button", { on: { click: _vm.ok } }, [
            _vm._v("Изменить")
          ]),
          _vm._v(" "),
          _c("v-ons-alert-dialog-button", { on: { click: _vm.cancel } }, [
            _vm._v("Отмена")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b5361522", { render: render, staticRenderFns: staticRenderFns })
  }
}