var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { visible: _vm.visible, cancelable: "" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "center" }, [_vm._v("Выберите склад")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Закрыть" }, on: { click: _vm.close } },
              [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        {
          style: {
            maxHeight: _vm.$window.height - 110 + "px",
            overflowY: "auto"
          }
        },
        _vm._l(_vm.items, function(item) {
          return _c(
            "v-ons-list-item",
            {
              key: item.id,
              on: {
                click: function($event) {
                  return _vm.choose(item)
                }
              }
            },
            [
              _c(
                "label",
                {
                  staticClass: "center",
                  staticStyle: {
                    "font-weight": "400",
                    margin: "0",
                    padding: "8px 6px 8px 0"
                  },
                  attrs: { for: "chk-" + item.id }
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(item.name) + "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "right",
                  staticStyle: { padding: "8px 16px 8px 0" }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(item.rest) +
                      " " +
                      _vm._s(_vm.unit) +
                      "\n            "
                  )
                ]
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-140a1d96", { render: render, staticRenderFns: staticRenderFns })
  }
}