<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>
            <div class="center">Инвентаризация</div>

            <div class="right">
                <v-ons-toolbar-button v-show="hasChecked" @click="deleteInventories" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <ons-list v-show="items.length" >
            <ons-list-item
                v-for="item in items"
                modifier="chevron longdivider"
                class="cursor-pointer"
                :key="item.id"
            >
                <div class="left" style="margin-right: 10px; min-width: 0;">
                    <v-ons-checkbox v-model="item.isChecked" />
                </div>
                <div class="center" @click="$router.push('inventory/view/' + item.id)" >
                    Инвентаризация №{{ item.id }} от {{ item.created_at | moment("L") }}
                </div>
            </ons-list-item>
        </ons-list>

        <p v-show="items.length == 0" style="text-align: center; font-size: 15px; margin-top: 50px" >Инвентаризация еще не проведена</p>

        <v-ons-fab position="bottom right" @click="$router.push('inventory/create')" title="Начать инвентаризацию" >
            <v-ons-icon icon="md-plus"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            items: [],
        }
    },
    created() {
        this.loadPositions();
        this.$bus.$on('inventory-list:update', () => this.loadPositions());
    },
    destroyed() {
        this.$bus.$off('inventory-list:update');
    },
    methods: {
        loadPositions() {
            this.$http.get('/api/inventory').then(response => {
                let items = response.body;
                items.forEach(i => i.isChecked = false);
                this.items = items;
            });
        },
        deleteInventories() {
            let inventories = this.items
                .filter(i => i.isChecked)
                .map(i => i.id);

            this.$http.post('/api/inventory/delete', {inventories: inventories}).then(response => {
                this.loadPositions();

                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Инвентаризации удалены.',
                    buttonLabel: 'OK'
                });
            }, response => {
                this.$ons.notification.toast({
                    timeout: 1000,
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
    },
    computed: {
        hasChecked() {
            return this.items
                .filter(i => i.isChecked)
                .length > 0;
        }
    }
};
</script>
