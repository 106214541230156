<template>
    <catalog-page
        :catalogEntrypoint="'/api/manager/users/' + userId + '/catalog'"
        :templateEntrypoint="'/api/manager/users/' + userId + '/user-products/templates'"
        :userProductsEntrypoint="'/api/manager/users/' + userId + '/user-products'"
        :toolbarButtons="toolbarButtons"
        :showBackButton="true"
        :showInputs="false"
        :allowInputs="false"
        :canOpenProductPage="true"
        :redirectToOrderWhenAddPositions="true"
        actionColType="favor"
        :forceHideRest="true"
        :title="user.email"
    />
</template>

<script>

import CatalogPage from '~/component/CatalogPage'

export default {
    data() {
        return {
            toolbarButtons: [
                'showAssignTemplateModalButton',
                'downloadCatalogButton',
            ],
            userId: null,
            user: {},
        }
    },
    created() {
        this.userId = this.$route.params.user_id;

        this.$http.get('/api/manager/users/' + this.userId).then(response => {
            this.user = response.data;
        });
    },
    components: {
        CatalogPage,
    },
};
</script>