<template>
    <div v-frag>
        <span class="list-item__subtitle" v-for="subtitle in subtitles" >
            {{ subtitle.name }}: {{ subtitle.value }}
        </span>
    </div>
</template>

<script>
export default {
    props: [
        'item',
        'fields',
        'selected',
    ],
    computed: {
        subtitles() {
            return this.fields
                .filter(f => this.selected.includes(f.name))
                .map(f => {
                    let name = f.label;
                    let value = this.item[f.name];

                    if (f.getLabel) {
                        name = f.getLabel(this.item);
                    }

                    if (f.content) {
                        value = f.content(this.item)
                    }

                    return {
                        name: name,
                        value: value,
                    }
                })
                .filter(f => f.name && f.value)
        }
    },
};
</script>
