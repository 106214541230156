<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar inline="true" >
            <div class="center">Новый пользователь</div>
            <div class="right" >
                <v-ons-toolbar-button @click="close" title="Закрыть" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="alert-dialog-content alert-dialog-content--material">
            <div v-if="storages.length > 1">
                Склад:
                <select style="display: block; width: 100%; margin-bottom: 20px;" class="select-input select-input--material" v-model="storage" >
                    <option v-for="storage in storages" :key="storage.id" :value="storage.id" >
                        {{ storage.name }}
                    </option>
                </select>
            </div>

            <div>
                Email:
                <v-ons-input v-model="email" type="text" />

                <div class="input-error" style="min-height: 20px;" >
                    <div v-for="error in errors.email" >{{ error }}</div>
                </div>
            </div>

            <div>
                Пароль:
                <v-ons-input v-model="password" type="text" />

                <div class="input-error" style="min-height: 20px;" >
                    <div v-for="error in errors.password" >{{ error }}</div>
                </div>
            </div>
        </div>

        <div class="alert-dialog-footer alert-dialog-footer--rowfooter alert-dialog-footer--material alert-dialog-footer--rowfooter--material">
            <v-ons-button class="alert-dialog-button--material alert-dialog-button" @click="create()" :disabled="sending" modifier="quiet" >
                Создать
            </v-ons-button>
        </div>

    </v-ons-dialog>
</template>

<script>
export default {
    props: [
        'storages',
    ],
    data() {
        return {
            visible: false,

            errors: {},

            email: '',
            storage: null,
            password: '',

            sending: false,
        }
    },
    methods: {
        show() {
            this.visible = true;
            this.sending = false;
            this.errors = {};
            this.email = '';
            this.password = '';

            if (this.storages.length > 1) {
                let storage = this.storages.find(s => s.is_default)
                this.storage = storage ? storage.id : this.storages[0].id;
            }
        },
        close() {
            this.visible = false;
        },
        create() {
            this.sending = true;

            this.$http.post(
                '/api/manager/users/create',
                {email: this.email, storage: this.storage, password: this.password}
            ).then(() => {
                this.$ons.notification.toast({
                    timeout: 3000,
                    message: 'Пользователь создан',
                    buttonLabel: 'OK'
                });

                this.sending = false;
                this.visible = false;
            }, response => {
                this.sending = false;

                if (response.status === 400) {
                    this.errors = response.data.errors;
                } else {
                    this.errors = {
                        email: ['Произошла ошибка']
                    };
                }
            });
        },
    },
    watch: {
        email() {

        }
    },
};
</script>