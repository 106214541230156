var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v(_vm._s(_vm.product.name))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading && _vm.users.length > 0,
                expression: "!loading && users.length > 0"
              }
            ],
            staticClass: "right"
          },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasChecked,
                    expression: "hasChecked"
                  }
                ],
                on: { click: _vm.uncheckAll }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hasChecked,
                    expression: "!hasChecked"
                  }
                ],
                on: { click: _vm.checkAll }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check-all" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.users,
                  expression: "users"
                }
              ]
            },
            [
              _c(
                "ons-list-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.storages.length > 1,
                      expression: "storages.length > 1"
                    }
                  ],
                  attrs: { modifier: "chevron longdivider" },
                  on: { click: _vm.showChooseStorage }
                },
                [
                  _c("span", { staticClass: "list-item__title" }, [
                    _vm._v("Склад: " + _vm._s(_vm.selectedStorageName))
                  ]),
                  _vm._v(" "),
                  _vm.selectedStorage && !_vm.selectedStorage.is_configured
                    ? _c(
                        "span",
                        {
                          staticClass: "list-item__subtitle",
                          staticStyle: { color: "red" }
                        },
                        [
                          _vm._v(
                            "\n                    Не настроено\n                "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.users, function(user) {
                return _c(
                  "ons-list-item",
                  {
                    key: user.id,
                    attrs: { modifier: "longdivider" },
                    on: {
                      click: function($event) {
                        user.isChecked = !user.isChecked
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "left",
                        staticStyle: {
                          "min-width": "40px",
                          "text-align": "center"
                        }
                      },
                      [
                        _c("v-ons-checkbox", {
                          model: {
                            value: user.isChecked,
                            callback: function($$v) {
                              _vm.$set(user, "isChecked", $$v)
                            },
                            expression: "user.isChecked"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "center" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(user.id) +
                          " - " +
                          _vm._s(user.email) +
                          "\n                "
                      )
                    ])
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.users.length === 0,
                  expression: "users.length === 0"
                }
              ],
              staticClass: "list-empty",
              style: {
                height: _vm.$window.height - 56 + "px",
                flexDirection: "column"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-bottom": "10px", "font-size": "15px" }
                },
                [_vm._v("\n                Нет связей\n            ")]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChecked,
              expression: "hasChecked"
            }
          ],
          attrs: { position: "bottom right" },
          on: { click: _vm.goToReplace }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-arrow-right" } })],
        1
      ),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "chooseStorageDialog",
        attrs: { title: "Выберите склад", items: _vm.storages }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f5103d48", { render: render, staticRenderFns: staticRenderFns })
  }
}