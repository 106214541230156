<template>
    <v-ons-dialog :visible.sync="visible" cancelable >
        <v-ons-toolbar v-show="titleProp" inline="true" >
            <div class="center">{{ titleProp }}</div>
            <div class="right" >
                <v-ons-toolbar-button @click="close" title="Закрыть" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list :style="{maxHeight: ($window.height - 110) + 'px', overflowY: 'auto'}" >
            <v-ons-list-item v-for="item in itemsData" :key="item.id" >
                <label class="center" style="font-weight: 400; margin: 0; padding: 8px 6px 8px 0;" :for="'chk-' + item.id" >
                    {{ item.name }}
                </label>
                <div class="right" style="padding: 8px 16px 8px 0;" >
                    <v-ons-radio :input-id="'chk-' + item.id" v-model="selected" :value="item.id" />
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <ons-row class="text-center" style="margin-bottom: 5px; justify-content: center;" >
            <ons-col width="50%">
                <v-ons-button @click="choose()" >
                    {{ btnTitleProp }}
                </v-ons-button>
            </ons-col>
        </ons-row>
    </v-ons-dialog>
</template>

<script>
export default {
    props: [
        'title',
        'btnTitle',
        'items',
        'showResetButton',
    ],
    data() {
        return {
            titleProp: null,
            btnTitleProp: null,
            selected: null,
            visible: false,
            resolve: null,
            reject: null,

            itemsData: [],
        }
    },
    methods: {
        show(params) {
            params = params || {};
            this.titleProp = params.title || this.title;
            this.btnTitleProp = this.btnTitle || 'Выбрать'

            this.selected = params.selected || null;
            this.itemsData = params.items || this.items || [];
            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.visible = false;
        },
        choose(item) {
            this.visible = false;
            this.resolve(this.selected);
            this.selected = null;
        },
    },
};
</script>
