<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>
            <div class="center">Сотрудник: {{ worker.email }}</div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item v-show="false" tappable modifier="chevron longdivider" >
                Изменить
            </v-ons-list-item>

            <v-ons-list-item tappable modifier="chevron longdivider" @click="$router.push({name: 'profile-worker-permissions'})" >
                Права доступа
            </v-ons-list-item>

            <v-ons-list-item tappable modifier="chevron longdivider" @click="$router.push({name: 'profile-worker-rules'})" >
                Правила работы
            </v-ons-list-item>

            <v-ons-list-item modifier="longdivider" @click="dismiss" >
                Уволить
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>

export default {
    data() {
        return {
            id: null,
            worker: {},
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.load();
    },
    methods: {
        load() {
            this.$http.get('/api/workers/' + this.id).then(response => {
                this.worker = response.data;
            });
        },
        dismiss() {
            this.$ons.notification.confirm('Уволить сотрудника?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.post(`/api/workers/${this.id}/dismiss`).then(response => {
                    this.$bus.$emit('update-workers');
                    this.$ons.notification.toast({
                        timeout: 3000,
                        message: 'Сотруднки уволен',
                        buttonLabel: 'OK'
                    });
                    this.$router.go(-1);
                }, response => {
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
    },
};
</script>
