var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("catalog-page", {
    attrs: {
      catalogEntrypoint: "/api/manager/users/" + _vm.userId + "/catalog",
      templateEntrypoint:
        "/api/manager/users/" + _vm.userId + "/user-products/templates",
      userProductsEntrypoint:
        "/api/manager/users/" + _vm.userId + "/user-products",
      toolbarButtons: _vm.toolbarButtons,
      showBackButton: true,
      showInputs: false,
      allowInputs: false,
      canOpenProductPage: true,
      redirectToOrderWhenAddPositions: true,
      actionColType: "favor",
      forceHideRest: true,
      title: _vm.user.email
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-06b53eee", { render: render, staticRenderFns: staticRenderFns })
  }
}