<template>
    <v-ons-alert-dialog modifier="rowfooter" :visible.sync="visible" >
        <span slot="title">{{ title }}</span>

        <template slot="footer">
            <v-ons-alert-dialog-button @click="onOk">Да</v-ons-alert-dialog-button>
            <v-ons-alert-dialog-button @click="onNo">Нет</v-ons-alert-dialog-button>
        </template>
    </v-ons-alert-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            title: '',

            resolve: null,
            reject: null,
        }
    },
    created() {
        document.addEventListener('keydown', this.onKeydown);
    },
    destroyed() {
        document.removeEventListener('keydown', this.onKeydown);
    },
    methods: {
        show(title) {
            this.visible = true;
            this.title = title;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        onOk() {
            this.visible = false;
            this.resolve(true);
        },
        onNo() {
            this.visible = false;
            this.resolve(false);
        },
        onKeydown(event) {
            event.preventDefault();
            event.stopPropagation();

            if (event.keyCode == 13 || event.keyCode == 27) {
                if (event.keyCode == 13) {
                    this.onOk();
                }

                if (event.keyCode == 27) {
                    this.onNo();
                }

                event.preventDefault();
                return false;
            }

            return false;
        },
    },
};
</script>

