var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                attrs: { title: "Добавить из шаблона" },
                on: {
                  click: function($event) {
                    return _vm.$refs.assignTemplateModal.show()
                  }
                }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-collection-plus" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "toolbar-popover-menu",
              { attrs: { "popover-class": "stick-right" } },
              [
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: "id" in _vm.order,
                      expression: "'id' in order"
                    }
                  ],
                  attrs: { text: "Удалить заказ", icon: "md-delete" },
                  on: {
                    click: function($event) {
                      return _vm.deleteOrder()
                    }
                  }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: "id" in _vm.order,
                      expression: "'id' in order"
                    }
                  ],
                  attrs: { text: "Скачать в PDF", icon: "md-download" },
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf()
                    }
                  }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  attrs: {
                    text: "Добавить из шаблона",
                    icon: "md-collection-plus"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$refs.assignTemplateModal.show()
                    }
                  }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  attrs: {
                    text: "Добавить из каталога",
                    icon: "md-playlist-plus"
                  },
                  on: { click: _vm.openCatalog }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.order,
                      expression: "order"
                    }
                  ],
                  attrs: { text: "Изменить тип цен", icon: "md-money" },
                  on: {
                    click: function($event) {
                      return _vm.showPriceTypesDialog()
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("assign-template-modal", {
        ref: "assignTemplateModal",
        attrs: {
          title: "Добавить из шаблона",
          url:
            "/api/manager/users/" +
            _vm.order.user_id +
            "/user-products/templates"
        },
        on: { choose: _vm.onBindTemplate }
      }),
      _vm._v(" "),
      _c("group-positions-modal", {
        ref: "groupPositionsModal",
        attrs: { isGrouped: _vm.order && _vm.grouped }
      }),
      _vm._v(" "),
      _c("v-unavailable-position-modal", { ref: "unavailablePositionModal" }),
      _vm._v(" "),
      _c("order-comment-dialog", { ref: "orderCommentDialog" }),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "order-create-page manager-order-create-page",
          style: { "padding-bottom": _vm.paddingBottom }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.order,
                  expression: "order"
                }
              ],
              staticClass: "table-positions"
            },
            [
              _vm.grouped === "byUserGroup"
                ? _c(
                    "div",
                    { staticClass: "tbody" },
                    _vm._l(_vm.positionsByGroups, function(group) {
                      return _c(
                        "div",
                        { key: group.id, staticClass: "group-wrap" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    group.id != 0 ||
                                    _vm.positionsByGroups.length > 1,
                                  expression:
                                    "group.id != 0 || positionsByGroups.length > 1"
                                }
                              ],
                              staticClass: "group-name"
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(group.name) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(group.positions, function(position) {
                            return !_vm.removed.includes(position.pos_id)
                              ? _c("v-create-position", {
                                  key: position.pos_id,
                                  attrs: {
                                    id: "position-" + position.pos_id,
                                    position: position,
                                    inventoryPositions: _vm.inventoryPositions,
                                    units: _vm.units,
                                    addedPositions: _vm.addedPositions,
                                    index: position.index,
                                    lastImportDate: _vm.lastImportDate,
                                    canEditPrice: true,
                                    urlSearch:
                                      "/api/manager/order/catalog-search?id=" +
                                      _vm.order.id
                                  },
                                  on: {
                                    positionView: _vm.positionView,
                                    update: _vm.updatePosition,
                                    updateCount: _vm.updatePositionCount,
                                    updatePrice: _vm.updatePositionPrice,
                                    remove: _vm.onRemovePosition,
                                    showProposals: function($event) {
                                      _vm.fabsVisible = false
                                    },
                                    hideProposals: function($event) {
                                      _vm.fabsVisible = true
                                    },
                                    countEnterDown: _vm.onCountEnterDown,
                                    countShiftEnterDown:
                                      _vm.onCountShiftEnterDown
                                  }
                                })
                              : _vm._e()
                          })
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    { staticClass: "tbody" },
                    _vm._l(_vm.getPositions(), function(position) {
                      return !_vm.removed.includes(position.pos_id)
                        ? _c("v-create-position", {
                            key: position.pos_id,
                            attrs: {
                              id: "position-" + position.pos_id,
                              position: position,
                              inventoryPositions: _vm.inventoryPositions,
                              units: _vm.units,
                              index: position.index,
                              addedPositions: _vm.addedPositions,
                              lastImportDate: _vm.lastImportDate,
                              canEditPrice: true,
                              urlSearch:
                                "/api/manager/order/catalog-search?id=" +
                                _vm.order.id
                            },
                            on: {
                              positionView: _vm.positionView,
                              update: _vm.updatePosition,
                              updateCount: _vm.updatePositionCount,
                              updatePrice: _vm.updatePositionPrice,
                              remove: _vm.onRemovePosition,
                              showProposals: function($event) {
                                _vm.fabsVisible = false
                              },
                              hideProposals: function($event) {
                                _vm.fabsVisible = true
                              },
                              countEnterDown: _vm.onCountEnterDown,
                              countShiftEnterDown: _vm.onCountShiftEnterDown
                            }
                          })
                        : _vm._e()
                    }),
                    1
                  ),
              _vm._v(" "),
              _c("input-proposal-create", {
                ref: "inputProposal",
                attrs: {
                  scrollPageOnFocus: true,
                  disableScrollPage: true,
                  addedPositions: _vm.addedPositions,
                  urlSearch:
                    "/api/manager/order/catalog-search?id=" + _vm.order.id
                },
                on: {
                  choose: _vm.chooseProposal,
                  showProposals: function($event) {
                    _vm.fabsVisible = false
                  },
                  hideProposals: function($event) {
                    _vm.fabsVisible = true
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "total-order-wrap" }, [
            _c("div", { staticClass: "wrap total-order" }, [
              _c("div", { staticClass: "col col-label" }, [
                _c("div", [_vm._v("Сумма")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-total-price" }, [
                _c("div", { staticClass: "total-price-wrap" }, [
                  _vm.order.isNearlyCost ? _c("span", [_vm._v("~")]) : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.order.totalCost || 0))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-rouble" }, [_vm._v("o")])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.order && _vm.order.hasDiscount
              ? _c("div", { staticClass: "wrap total-order" }, [
                  _c("div", { staticClass: "col col-label" }, [
                    _c("div", [_vm._v("Сумма со скидкой")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col col-total-price" }, [
                    _c("div", { staticClass: "total-price-wrap" }, [
                      _vm.order.isNearlyCost
                        ? _c("span", [_vm._v("~")])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.order.totalCostWithDiscount || 0))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "font-rouble" }, [_vm._v("o")])
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && _vm.fabsVisible,
              expression: "!loading && fabsVisible"
            }
          ],
          attrs: { position: "bottom left", title: "Перейти в режим каталога" },
          on: { click: _vm.openCatalog }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-playlist-plus" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && _vm.fabsVisible,
              expression: "!loading && fabsVisible"
            }
          ],
          attrs: {
            position: "bottom right",
            title: "Перейти к отправке заказа"
          },
          on: { click: _vm.checkout }
        },
        [
          _c("v-ons-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.orderCheckouting,
                expression: "!orderCheckouting"
              }
            ],
            attrs: { icon: "md-mail-send" }
          }),
          _vm._v(" "),
          _c("v-ons-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.orderCheckouting,
                expression: "orderCheckouting "
              }
            ],
            attrs: { size: "30px", spin: "", icon: "md-spinner" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-price-types-dialog", { ref: "priceTypesDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2b7d1d99", { render: render, staticRenderFns: staticRenderFns })
  }
}