<template>
    <v-ons-page>
        <v-ons-toolbar inline="true">
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Заказы</div>

            <div class="right" v-show="!loading" >
                <v-ons-toolbar-button  @click="choose" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" >
                    <toolbar-popover-menu-item text="Опции просмотра" icon="md-format-list-bulleted" @click="showViewParamsModal" />
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-show="orders" >
                <ons-list>
                    <ons-list-item v-for="order in orders" :key="order.id" modifier="longdivider" style="padding-left: 0;" @click="toggleOrder(order.id)" >
                        <div class="left" style="width: 40px; justify-content: center;" >
                            <input type="checkbox" :id="'chk-' + order.id" v-model="checked" :value="order.id" >
                        </div>
                        <div class="center" >
                            <span class="list-item__title" >
                                Заказ №{{order.id}} <span v-show="order.sended_at" >от {{ order.sended_at | moment("L") }}</span>
                                на сумму
                                <span v-if="order.isNearlyCost" style="margin-left: 5px" >~</span>
                                {{ order.totalCost }} руб.
                            </span>
                            <span class="list-item__subtitle" >
                                Статус: {{ order.status }}
                            </span>

                            <v-list-item-subtitles :item="order" :fields="$user.getOrderSubtitles()" :selected="$user.orderSelectedSubtitles" />
                        </div>
                    </ons-list-item>
                </ons-list>
            </div>

            <div v-show="orders.length == 0" style="text-align: center; font-size: 15px; margin-top: 50px" >Ничего не найдено</div>
        </div>

        <v-list-dialog ref="viewParamsModal" multiple="true" title="Опции просмотра" />
    </v-ons-page>
</template>

<script>

import ListDialog from '~/component/ListDialog'
import ListItemSubtitles from '~/component/ListItemSubtitles'

export default {
    data() {
        return {
            checked: [],
            orders: [],
            loading: true,
            filters: {},
        }
    },
    created() {
        this.filters = this.$route.params.filters;

        this.$http.get('/api/manager/reports/orders', {params: {filters: this.filters}}).then(response => {
            this.orders = response.data;
            this.loading = false;
        });
    },
    methods: {
        showViewParamsModal() {
            let items = this.$user.getOrderSubtitles().map(i => {
                return {
                    id: i.name,
                    name: i.label,
                }
            });

            this.$refs.viewParamsModal.show({items: items, selected: this.$user.orderSelectedSubtitles}).then(selected => {
                this.$user.setOrderSelectedSubtitles(selected);
            });
        },
        toggleOrder(id) {
            if (this.checked.includes(id)) {
                this.checked = this.checked.filter(i => i != id);
            } else {
                this.checked.push(id);
            }
        },
        choose() {
            if (this.checked.length == 0) {
                this.$router.go(-1);
            } else {
                this.$router.push({
                    name: 'report-sales-for-period',
                    params: {checkedOrders: this.checked},
                });
            }
        },
    },
    components: {
        'v-list-dialog': ListDialog,
        'v-list-item-subtitles': ListItemSubtitles,
    },
};
</script>
