<template>
    <v-ons-page class="order-index" >
        <v-ons-toolbar v-show="!hasChecked" >
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">Заказы</div>

            <div class="right" >
                <v-ons-toolbar-button  @click="showViewParamsModal" title="Опции просмотра" >
                    <v-ons-icon icon="md-format-list-bulleted"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button  @click="$emit('update')" title="Обновить" >
                    <v-ons-icon icon="md-refresh"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="hasChecked" >
            <div class="left">
                <v-ons-toolbar-button @click="uncheckAll" >
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center">Выбрано: {{ checkedCount }}</div>

            <div class="right">
                <v-ons-toolbar-button @click="deleteOrders" title="Удалить" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-if="!failed">
                <v-discount-info />

                <ons-list v-show="orders" >
                    <ons-list-item v-for="order in orders" :key="order.id" modifier="chevron longdivider" :class="['order-item', order.isChecked ? 'checked' : '']" >
                        <div class="left" style="min-width: 56px; padding-left: 16px; text-align: center;" @click="checkOrder(order)" >
                            <v-ons-icon
                                class="list-item__icon"
                                :icon="orderStatus(order).icon"
                                :title="orderStatus(order).title"
                                :style="orderStatus(order).style"
                            ></v-ons-icon>
                        </div>
                        <div class="center cursor-pointer" @click="$router.push('order/'+order.id)" >
                        <span class="list-item__title" >
                            {{ orderTitle(order) }}
                        </span>
                            <span class="list-item__subtitle" >
                            Статус: {{ order.status }}
                        </span>

                            <v-list-item-subtitles :item="order" :fields="$user.getOrderSubtitles()" :selected="$user.orderSelectedSubtitles" />
                        </div>
                    </ons-list-item>
                </ons-list>

                <div style="text-align: center; margin: 10px 0;" v-show="page < pageCount" >
                    <v-ons-button @click="loadMore" style="min-width: 145px;" >
                        <span v-show="!loadingMore" >Показать ещё</span>
                        <v-ons-icon v-show="loadingMore" size="30px" spin icon="md-spinner"></v-ons-icon>
                    </v-ons-button>
                </div>

                <div v-show="orders.length === 0" class="list-empty" :style="{height: $window.height - 56 + 'px'}" >
                    Еще нет ни одного заказа
                </div>
            </div>

            <div v-show="failed" class="page-failed" :style="{height: $window.height - 56 + 'px'}" >
                <div class="message" >Произошла ошибка.</div>

                <v-ons-button @click="$emit('update')">
                    Повторить
                </v-ons-button>
            </div>
        </div>

        <v-list-dialog ref="viewParamsModal" multiple="true" title="Опции просмотра" />

        <v-ons-fab position="bottom right" v-show="!loading" @click="$router.push('order/create')" title="Создать заказ" >
            <v-ons-icon icon="md-plus"></v-ons-icon>
        </v-ons-fab>
    </v-ons-page>
</template>

<script>

import ListDialog from '../../component/ListDialog'
import ListItemSubtitles from '../../component/ListItemSubtitles'

import {from, merge, of} from 'rxjs'
import {catchError, concatMap, map, tap} from 'rxjs/operators'
import DiscountSection from "~/component/DiscountSection";
import DiscountInfoComponent from "~/component/DiscountInfoComponent";

export default {
    data() {
        return {
            orders: [],
            page: 1,
            pageCount: null,
            loading: false,
            failed: false,
            loadingMore: false,
        }
    },
    socket() {
        return [
            {
                namespace: '/orders',
                events: {
                    confirm(data) {
                        this.updateOrder(data.id);
                    },
                    canceled(data) {
                        this.updateOrder(data.id);
                    },
                    viewed(data) {
                        this.updateOrder(data.id);
                    },
                },
            },
        ];
    },
    subscriptions () {
        const $loadMore = this.$watchAsObservable('page').pipe(
            tap(() => this.loadingMore = true),
        )

        const $init = from([1]).pipe(
            tap(() => this.loading = true),
        )

        const $update = this.$eventToObservable('update').pipe(
            tap(() => {
                this.loading = true;
                this.orders = [];
                this.page = 1;
            })
        )

        return {
            results: merge($loadMore, $update, $init).pipe(
                tap(() => this.failed = false),
                concatMap(() => {
                    return from(this.$http.get('/api/order', {params: {page: this.page}})).pipe(
                        map(response => {
                            return response.data;
                        }),
                        catchError(() => {
                            this.loading = false;
                            this.failed = true;
                            return of({
                                items: [],
                                page: 0,
                                pageCount: 0,
                            });
                        }),
                    );
                }),
                map(data => {
                    let orders = data.items.map(i => {
                        i.isChecked = false;
                        return i;
                    });
                    this.orders = this.orders.concat(orders);
                    this.pageCount = data.pageCount;
                }),
                tap(() => {
                    this.loadingMore = false;
                    this.loading = false;
                }),
            )
        }
    },
    created() {
        this.$bus.$on('orders-update', () => {
            this.$emit('update');
            this.$user.update();
        });
        this.$bus.$on('order-remove', id => {
            this.removeOrdersFromList([id]);
        });
        this.$bus.$on('order-update', id => {
            this.updateOrder(id);
        });
        this.$on('update', this.onUpdate);
    },
    destroyed() {
        this.$bus.$off('orders-update');
        this.$bus.$off('order-update');
        this.$bus.$off('order-remove');
        this.$off('update', this.onUpdate);
    },
    methods: {
        loadMore() {
            if (this.page != null && this.pageCount != null && this.page > this.pageCount) {
                return;
            }

            if (this.loadingMore) {
                return;
            }

            this.page += 1;
        },
        updateOrder(id) {
            let index = this.orders.findIndex(i => i.id == id);

            if (index === -1) {
                this.$emit('update');
                this.$user.update();
                return;
            }

            this.$http.get('/api/orders/' + id).then(response => {
                let order = response.data;
                order.isChecked = false;
                this.$set(this.orders, index, response.data)
            }, response => {
                if (response.status === 404) {
                    this.removeOrdersFromList([id]);
                }
            });
        },
        removeOrdersFromList(ids) {
            this.orders = this.orders.filter(i => !ids.includes(i.id));
        },
        deleteOrders() {
            let orders = this.orders
                .filter(i => i.isChecked)
                .map(i => i.id);

            this.$ons.notification.confirm('Удалить заказы?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.post('/api/order/delete', {orders: orders}).then(() => {
                    this.removeOrdersFromList(orders);

                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Заказы удалены.',
                        buttonLabel: 'OK'
                    });
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            })
        },
        checkOrder(order) {
            if (order.status_id !== 'draft') {
                return;
            }

            order.isChecked = !order.isChecked;
        },
        uncheckAll() {
            this.orders.forEach(i => i.isChecked = false);
        },
        orderTitle(order) {
            let title = `Заказ №${order.id}`;

            if (order.status_id !== 'draft') {
                title += ` от ${order.createdAtFormat}`;
            }

            if (!this.$user.canSeePrice) {
                return title;
            }

            title += " на сумму ";
            title += order.isNearlyCost ? "~" : '';
            title += order.hasDiscount ? order.totalCostWithDiscount : order.totalCost;
            title += ' руб.';
            return title;
        },
        orderStatus(order) {
            if (order.status_id === 'manager_editing') {
                return {
                    icon: 'md-check',
                    title: 'Заказ в редактировании',
                };
            }

            if (order.status_id === 'confirmed') {
                return {
                    icon: 'md-check',
                    title: 'Заказ подтверждён',
                    style: {color: '#009688'}
                };
            }

            if (order.status_id === 'generated' && !this.$user.isWorker) {
                return {
                    icon: 'md-comment-list',
                    title: 'Сформирован сотрудником',
                };
            }

            if (order.status_id === 'draft') {
                return {
                    icon: 'ion-ios-list',
                    title: 'Черновик заказа',
                };
            }

            if (order.status_id === 'sended' && order.worker) {
                let style = {};

                if (order.is_viewed_by_manager) {
                    style.color = '#009688';
                }

                return {
                    icon: 'ion-reply-all',
                    title: 'Заказ отправлен',
                    style: style,
                };
            }

            if (order.status_id === 'sended') {
                let style = {};

                if (order.is_viewed_by_manager) {
                    style.color = '#009688';
                }

                return {
                    icon: 'ion-reply',
                    title: 'Заказ отправлен',
                    style: style,
                };
            }

            if (order.status_id === 'generated' && this.$user.isWorker) {
                return {
                    icon: 'ion-reply',
                    title: 'Заказ отправлен',
                };
            }

            return {
                icon: '',
                title: '',
            };
        },
        showViewParamsModal() {
            let items = this.$user.getOrderSubtitles().map(i => {
                return {
                    id: i.name,
                    name: i.label,
                }
            });

            this.$refs.viewParamsModal.show({items: items, selected: this.$user.orderSelectedSubtitles}).then(selected => {
                this.$user.setOrderSelectedSubtitles(selected);
            });
        },
        onUpdate() {
            this.$user.update();
        },
    },
    computed: {
        hasChecked() {
            return this.checkedCount > 0;
        },
        checkedCount() {
            return this.orders
                .filter(i => i.isChecked)
                .length
        },
    },
    components: {
        DiscountSection,
        'v-list-dialog': ListDialog,
        'v-list-item-subtitles': ListItemSubtitles,
        'v-discount-info': DiscountInfoComponent,
    },
};
</script>