var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "product-view" },
    [
      _c("v-ons-toolbar", [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v(_vm._s(_vm.product.name))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                on: { click: _vm.save }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Наименование")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    model: {
                      value: _vm.product.name,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "name", $$v)
                      },
                      expression: "product.name"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.product.name !== _vm.product.original_name,
                          expression: "product.name !== product.original_name"
                        }
                      ],
                      staticStyle: {
                        "font-size": "13px",
                        color: "#837f7f",
                        "padding-top": "2px"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.product.original_name) +
                          "\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Ед. изм")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c(
                    "v-ons-select",
                    {
                      model: {
                        value: _vm.product.unit_id,
                        callback: function($$v) {
                          _vm.$set(_vm.product, "unit_id", $$v)
                        },
                        expression: "product.unit_id"
                      }
                    },
                    _vm._l(_vm.units, function(unit) {
                      return _c("option", { domProps: { value: unit.id } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(unit.name) +
                            "\n                    "
                        )
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.product.unit !== _vm.product.unit_original,
                          expression: "product.unit !== product.unit_original"
                        }
                      ],
                      staticStyle: {
                        "font-size": "13px",
                        color: "#837f7f",
                        "padding-top": "2px"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.product.unit_original) +
                          "\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Кратность")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    model: {
                      value: _vm.product.mult,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "mult", $$v)
                      },
                      expression: "product.mult"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Ключ. слова")]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.product.words,
                      expression: "product.words"
                    }
                  ],
                  staticClass: "textarea",
                  staticStyle: { width: "100%" },
                  attrs: { rows: "3" },
                  domProps: { value: _vm.product.words },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.product, "words", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "v-ons-list-header",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.product.is_wait,
                      expression: "product.is_wait"
                    }
                  ]
                },
                [_vm._v("Информация о поступлении")]
              ),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.product.is_wait,
                      expression: "product.is_wait"
                    }
                  ]
                },
                [
                  _c("p", [
                    _vm._v("Это поле переехало на новую страницу "),
                    _c("a", { attrs: { href: _vm.availabilityBalanceLink } }, [
                      _vm._v('"Наличие и остаток"')
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "20px" }
                },
                [
                  _c("img", {
                    staticClass: "product-image",
                    attrs: {
                      src: _vm.product.images.thumb220.path,
                      alt: _vm.product.name,
                      title: _vm.product.name,
                      width: _vm.product.images.thumb220.width,
                      height: _vm.product.images.thumb220.height
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "10px"
                      }
                    },
                    [
                      _c(
                        "v-ons-button",
                        {
                          attrs: {
                            disabled:
                              _vm.$upload.meta("image").status === "sending"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$upload.select("image")
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$upload.meta("image").status !==
                                    "sending",
                                  expression:
                                    "$upload.meta('image').status !== 'sending'"
                                }
                              ]
                            },
                            [_vm._v("Добавить")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$upload.meta("image").status ===
                                    "sending",
                                  expression:
                                    "$upload.meta('image').status === 'sending'"
                                }
                              ]
                            },
                            [_vm._v("Загрузка...")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.product.images.thumb220.hasImage
                        ? _c(
                            "v-ons-button",
                            { on: { click: _vm.deleteImage } },
                            [_vm._v("Удалить")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br")
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.product.characteristics
            ? _c(
                "div",
                _vm._l(_vm.product.characteristics, function(
                  characteristic,
                  index
                ) {
                  return _c(
                    "v-ons-list",
                    { key: characteristic.id },
                    [
                      _c("v-ons-list-header", [
                        _vm._v(_vm._s(characteristic.label))
                      ]),
                      _vm._v(" "),
                      _c("v-ons-list-item", [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.product.characteristics[index].value,
                              expression: "product.characteristics[index].value"
                            }
                          ],
                          staticClass: "textarea",
                          staticStyle: { width: "100%" },
                          attrs: { rows: "3" },
                          domProps: {
                            value: _vm.product.characteristics[index].value
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.product.characteristics[index],
                                "value",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f3b1dcb6", { render: render, staticRenderFns: staticRenderFns })
  }
}