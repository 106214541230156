<template>
    <div>
        <v-ons-dialog
            :visible.sync="dialogVisible"
            cancelable
            @preshow="loadGroups"
            @prehide="prehide"
        >
            <v-ons-toolbar inline="true" >
                <div class="center">{{ title }}</div>
                <div class="right">
                    <div v-show="!isSortable" >
                        <v-ons-toolbar-button @click="showNewGroupDialog" >
                            <v-ons-icon icon="md-plus"></v-ons-icon>
                        </v-ons-toolbar-button>

                        <v-ons-toolbar-button @click="isSortable = true" >
                            <v-ons-icon icon="md-sort-amount-asc"></v-ons-icon>
                        </v-ons-toolbar-button>

                        <v-ons-toolbar-button @click="close" >
                            <v-ons-icon icon="md-close"></v-ons-icon>
                        </v-ons-toolbar-button>
                    </div>
                    <div v-show="isSortable" >
                        <v-ons-toolbar-button @click="isSortable = false" >
                            <v-ons-icon icon="md-check"></v-ons-icon>
                        </v-ons-toolbar-button>
                    </div>
                </div>
            </v-ons-toolbar>

            <div v-show="!isSortable" >
                <v-ons-list style="height: 216px; overflow-y: auto" >
                    <v-ons-list-item v-for="group in groups" :key="group.id" >
                        <div class="center" @click="choose(group)" >
                            {{ group.name }}
                        </div>
                        <div class="right" >
                            <v-ons-icon
                                @click="deleteGroup(group)"
                                icon="md-delete"
                                class="list-item__icon"
                                style="margin: 0 4px; cursor: pointer;"
                            ></v-ons-icon>

                            <v-ons-icon
                                @click="showRenameGroupDialog(group)"
                                icon="md-edit"
                                class="list-item__icon"
                                style="margin: 0 4px; cursor: pointer;"
                            ></v-ons-icon>
                        </div>
                    </v-ons-list-item>
                </v-ons-list>
            </div>
            <div v-show="isSortable" >
                <v-ons-list style="height: 216px; overflow-y: auto" >
                    <v-ons-list-item v-for="group in groups" :key="group.id" style="padding: 0" >
                        <div class="center" >
                            <v-ons-icon icon="md-chevron-up" @click="orderUp(group)" style="cursor: pointer; line-height: 42px; font-size: 15px; width: 35px; text-align: center; margin: -14px 0;" ></v-ons-icon>
                            {{ group.name }}
                        </div>
                        <div class="right" >
                            <v-ons-icon icon="md-chevron-down" @click="orderDown(group)" style="cursor: pointer; line-height: 42px; font-size: 15px; width: 35px; text-align: center; margin: -14px 0;" ></v-ons-icon>
                        </div>
                    </v-ons-list-item>
                </v-ons-list>
            </div>
        </v-ons-dialog>

        <v-ons-dialog
            :visible="newGroupDialogVisible"
        >
            <v-ons-toolbar inline="true" >
                <div class="center">Новая группа</div>
            </v-ons-toolbar>

            <div style="margin: 20px 15px;" >
                <v-ons-input style="width: 100%" placeholder="Название" :model="name" />
            </div>

            <v-ons-row>
                <v-ons-col>
                    <v-ons-button
                        modifier="quiet"
                        style="width: 100%;"
                        @click="dialogVisible = false"
                    >Отмена</v-ons-button>
                </v-ons-col>

                <v-ons-col>
                    <v-ons-button
                        style="width: 100%;"
                        @click="dialogVisible = false"
                    >Добавить</v-ons-button>
                </v-ons-col>
            </v-ons-row>

        </v-ons-dialog>
    </div>
</template>

<script>
export default {
    props: [
        'title',
    ],
    data() {
        return {
            dialogVisible: false,
            newGroupDialogVisible: false,
            groups: [],
            name: '',
            isSortable: false,
            type: '',
            entrypoint: '/api/user-products',
        }
    },
    created() {
        if (this.$route.meta.userProductEntrypoint) {
            let userProductEntrypoint = this.$route.meta.userProductEntrypoint;
            this.entrypoint = userProductEntrypoint.replace('$userId', this.$route.params.user_id)
        }
    },
    methods: {
        showNewGroupDialog() {
            this.dialogVisible = false;
            this.$ons.notification.prompt('Название', {
                title: 'Добавить новую группу',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (!response) {
                    this.dialogVisible = true;
                    return;
                }

                this.$http.put(
                    this.entrypoint + '/groups',
                    {name: response}
                ).then(() => {
                    this.loadGroups();
                    this.dialogVisible = true;
                });
            });
        },
        deleteGroup(group) {
            this.dialogVisible = false;
            this.$ons.notification.confirm('Удалить группу?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.post(
                    this.entrypoint + `/groups/${group.id}/delete`
                ).then(() => {
                    this.loadGroups();
                    this.$emit('deleteGroup');
                    this.dialogVisible = true;
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });

                    this.dialogVisible = true;
                });
            });
        },
        showRenameGroupDialog(group) {
            this.dialogVisible = false;
            this.$ons.notification.prompt('Название', {
                title: 'Переименовать группу',
                defaultValue: group.name,
                buttonLabels: ['Отмена', 'OK']
            }).then(name => {
                if (!name || name === group.name) {
                    this.dialogVisible = true;
                    return;
                }

                this.$http.post(
                    this.entrypoint + `/groups/${group.id}/rename`,
                    {name: name}
                ).then(() => {
                    this.loadGroups();
                    this.$emit('renameGroup', {group: group, name: name});
                    this.dialogVisible = true;
                });
            });
        },
        choose(group) {
            if (this.type === 'show') {
                return;
            }

            this.dialogVisible = false;
            this.$emit('choose', {group: group.id});
        },
        move() {
            this.type = 'move';
            this.dialogVisible = true;
        },
        show() {
            this.type = 'show';
            this.dialogVisible = true;
        },
        loadGroups() {
            this.$http.get(this.entrypoint + '/groups').then(response => {
                this.groups = response.body;
            });
        },
        prehide() {
            this.isSortable = false;
            this.$emit('update');
        },
        close() {
            this.dialogVisible = false;
            this.isSortable = false;
            this.$emit('update');
        },
        orderUp(group) {
            this.$http.post(
                this.entrypoint + `/groups/${group.id}/reorder`,
                {order: 'up'}
            ).then(() => {
                this.loadGroups();
            });
        },
        orderDown(group) {
            this.$http.post(
                this.entrypoint + `/groups/${group.id}/reorder`,
                {order: 'down'}
            ).then(() => {
                this.loadGroups();
            });
        },
    }
};
</script>