var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.id
          ? _c("div", { staticClass: "center" }, [
              _vm._v("Филиал: " + _vm._s(_vm.branch.actual_name))
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.id
          ? _c("div", { staticClass: "center" }, [_vm._v("Создать филиал")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.id
              ? _c(
                  "v-ons-toolbar-button",
                  {
                    attrs: { title: "Удалить филиал" },
                    on: { click: _vm.remove }
                  },
                  [_c("v-ons-icon", { attrs: { icon: "md-delete" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              { attrs: { title: "Сохранить" }, on: { click: _vm.save } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [
            _vm._v("Фактическое наименование (Вывеска)")
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                model: {
                  value: _vm.branch.actual_name,
                  callback: function($$v) {
                    _vm.$set(_vm.branch, "actual_name", $$v)
                  },
                  expression: "branch.actual_name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [
            _vm._v("Фактический адрес (Адрес доставки)")
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-address-form", {
                attrs: { title: "Фактический адрес (Адрес доставки)" },
                model: {
                  value: _vm.branch.address,
                  callback: function($$v) {
                    _vm.$set(_vm.branch, "address", $$v)
                  },
                  expression: "branch.address"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("v-ons-list-header", [_vm._v("График работы")]),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            [
              _c("v-ons-input", {
                model: {
                  value: _vm.branch.work_time,
                  callback: function($$v) {
                    _vm.$set(_vm.branch, "work_time", $$v)
                  },
                  expression: "branch.work_time"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-list",
        [
          _c("ons-list-header", [_vm._v("Юр. лицо")]),
          _vm._v(" "),
          _vm._l(_vm.entities, function(entity, key) {
            return _c("ons-list-item", { key: entity.id }, [
              _c(
                "label",
                {
                  staticClass: "center label-reset",
                  attrs: { for: "entity-" + key }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(entity.legal_name) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("v-ons-checkbox", {
                    staticStyle: {
                      "vertical-align": "middle",
                      margin: "0 12px 0 0"
                    },
                    attrs: {
                      value: entity.id,
                      "input-id": "entity-" + key,
                      disabled: _vm.entities.length == 1
                    },
                    model: {
                      value: _vm.branch.entities,
                      callback: function($$v) {
                        _vm.$set(_vm.branch, "entities", $$v)
                      },
                      expression: "branch.entities"
                    }
                  })
                ],
                1
              )
            ])
          }),
          _vm._v(" "),
          _c(
            "v-ons-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.entities.length === 0,
                  expression: "entities.length === 0"
                }
              ]
            },
            [
              _c(
                "v-ons-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "order-checkout-create-entity"
                      })
                    }
                  }
                },
                [_vm._v("Добавить юр. лицо")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bb088a0e", { render: render, staticRenderFns: staticRenderFns })
  }
}