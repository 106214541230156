var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("\n            Объединение товаров\n        ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.merge } },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Источник данных")]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.fromProduct.name) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v(
                    "\n                    Остаток: " +
                      _vm._s(_vm.fromProduct.rest) +
                      " " +
                      _vm._s(_vm.fromProduct.unit) +
                      " /\n                    Кратность: " +
                      _vm._s(
                        _vm.numeral(_vm.fromProduct.mult).format("0[.][000]")
                      ) +
                      " " +
                      _vm._s(_vm.fromProduct.unit) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v(
                    "\n                    Цена: " +
                      _vm._s(_vm.fromProduct.price) +
                      " руб.\n                "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", { on: { click: _vm.swap } }, [
                _c(
                  "div",
                  {
                    staticClass: "center",
                    staticStyle: {
                      "justify-content": "center",
                      padding: "30px 0"
                    }
                  },
                  [
                    _c("v-ons-icon", {
                      staticStyle: { "font-size": "40px" },
                      attrs: { icon: "md-refresh" }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Целевой товар")]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c("span", { staticClass: "list-item__title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.toProduct.name) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v(
                    "\n                    Остаток: " +
                      _vm._s(_vm.toProduct.rest) +
                      " " +
                      _vm._s(_vm.toProduct.unit) +
                      " /\n                    Кратность: " +
                      _vm._s(
                        _vm.numeral(_vm.toProduct.mult).format("0[.][000]")
                      ) +
                      " " +
                      _vm._s(_vm.toProduct.unit) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "list-item__subtitle" }, [
                  _vm._v(
                    "\n                    Цена: " +
                      _vm._s(_vm.toProduct.price) +
                      " руб.\n                "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("v-ons-list-header", [
                _vm._v("Дополнительные опции копирования данных:")
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("v-ons-checkbox", {
                      attrs: { "input-id": "optionsCopyUnit" },
                      model: {
                        value: _vm.options.copyUnit,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "copyUnit", $$v)
                        },
                        expression: "options.copyUnit"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "optionsCopyUnit" }
                  },
                  [_vm._v("единица измерения")]
                )
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("v-ons-checkbox", {
                      attrs: { "input-id": "optionsCopyMult" },
                      model: {
                        value: _vm.options.copyMult,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "copyMult", $$v)
                        },
                        expression: "options.copyMult"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "optionsCopyMult" }
                  },
                  [_vm._v("кратность")]
                )
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", { attrs: { modifier: "longdivider" } }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("v-ons-checkbox", {
                      attrs: {
                        "input-id": "optionsLinks",
                        disabled: !_vm.storage
                      },
                      model: {
                        value: _vm.options.copyLinks,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "copyLinks", $$v)
                        },
                        expression: "options.copyLinks"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "optionsLinks" }
                  },
                  [
                    _vm._v(
                      "\n                    связи товара и спец.цены (склад: " +
                        _vm._s(_vm.storage ? _vm.storage.name : "Неизвестно") +
                        ")\n                "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-ons-card", [
            _vm._v(
              "\n            После нажатия на галочку из товара-источника в целевой товар скопируются все данные (название, фото, кратность, единица изм., ключевые слова) и все свойства (связи товара, спец. цены).\n            Если товара-источника нет в наличии, то он пропадёт из каталога.\n        "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-283179c4", { render: render, staticRenderFns: staticRenderFns })
  }
}