<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center" >
                <div v-show="!isSearchActive" >
                    {{ product.name }}
                </div>

                <div v-show="isSearchActive" class="search-wrap" >
                    <input id="search-input" v-model="searchQuery" style="
                        color: #fff;
                        background: none;
                        border: none;
                        height: 34px;
                        line-height: 34px;
                        border-bottom: 1px solid #fff;
                    " />
                </div>
            </div>

            <div class="right">
                <v-ons-toolbar-button v-show="!isSearchActive" @click="searchActive" >
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button v-show="isSearchActive" @click="searchDeactivate" >
                    <v-ons-icon icon="md-close"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content" >
            <div v-if="items.length > 0">
                <v-ons-list>
                    <v-ons-lazy-repeat
                        ref="lazyRepeat"
                        :render-item="renderItem"
                        :length="items.length"
                    >
                    </v-ons-lazy-repeat>
                </v-ons-list>
            </div>

            <div v-show="items.length === 0" class="list-empty" :style="{height: $window.height - 56 + 'px', flexDirection: 'column'}" >
                <div>Ничего не найдено</div>
            </div>

            <div style="height: 100px;" ></div>
        </div>

        <v-ons-fab v-show="!loading" position="bottom left" class="fab-text" @click="setArchive" >
            Удалить
        </v-ons-fab>

        <v-ons-fab v-show="!loading && type === 'product'" position="bottom right" class="fab-text" @click="setAvail" >
            Ожидаем поступление
        </v-ons-fab>

        <v-confirm-relink-product-dialog ref="confirmRelinkProductDialog" />
    </v-ons-page>
</template>

<script>

import ConfirmRelinkProductDialog from '~/component/ConfirmRelinkProductDialog'
import Vue from "vue";
import ProductAvailLinkItem from "~/screen/manager/component/ProductAvailLinkItem";
import {catchError, concatMap, debounceTime, distinctUntilChanged, filter, map, pluck, tap} from "rxjs/operators";
import {from, merge, of} from "rxjs";

export default {
    data() {
        return {
            loading: true,
            isSearchActive: false,
            searchQuery: '',
            product: {},
            items: [],
            id: null,
            type: '',
            users: [],
            storageId: null,
        }
    },
    created() {
        if (!this.$route.params.users) {
            this.$router.go(-1);
            return;
        }

        this.id = this.$route.params.product_id;
        this.type = this.$route.params.type;
        this.users = this.$route.params.users;
        this.storageId = this.$route.params.storage_id;

        this.loadProduct();
        this.$emit('update');
    },
    subscriptions () {
        const searchQuery$ = this.$watchAsObservable('searchQuery').pipe(
            filter(() => this.searchActive),
            pluck('newValue'),
            map(q => q.trim()),
            debounceTime(1000),
        );

        const searchDeactivate$ = this.$eventToObservable('searchDeactivate').pipe(
            tap(() => {
                this.searchQuery = '';
                this.isSearchActive = false;
            }),
            map(() => this.searchQuery)
        );

        const search$ = merge(searchQuery$, searchDeactivate$).pipe(
            distinctUntilChanged(),
            tap(() => {
                this.loading = true;
                this.items = [];
            })
        );

        const update$ = this.$eventToObservable('update').pipe(
            debounceTime(100),
            tap(() => {
                this.loading = true;
                this.items = [];
            })
        )

        return {
            results: merge(search$, update$).pipe(
                concatMap(() => {
                    return from(this.loadReplacements());
                }),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of([]);
                }),
                tap(items => {
                    this.loading = false;
                    this.items = items;
                }),
            )
        }
    },
    methods: {
        loadProduct() {
            let request = null;

            if (this.type === 'product') {
                request = this.$http.get(
                    '/api/manager/product-link/get-product-from-catalog',
                    {params: {id: this.$route.params.product_id}}
                );
            } else {
                request = this.$http.get(
                    '/api/manager/product-link/get-product',
                    {params: {id: this.$route.params.product_id}}
                )
            }

            request.then(response => {
                this.product = response.data;
            });
        },
        loadReplacements() {
            if (this.type === 'product') {
                return this.$http.get(
                    '/api/manager/product-link/get-replacements-by-product',
                    {
                            params: {
                                id: this.$route.params.product_id,
                                storageId: this.storageId,
                                query: this.searchQuery,
                            }
                        }
                )
            }

            return this.$http.get(
                '/api/manager/product-link/get-replacements',
                {
                        params: {
                            id: this.$route.params.product_id,
                            storageId: this.storageId,
                            query: this.searchQuery,
                        }
                    }
            )
        },
        searchDeactivate() {
            this.$emit('searchDeactivate');
        },
        choose(product) {
            let request = null;
            let data = {};
            let params = {
                productNameFrom: this.product.name,
                productNameTo: product.name,
            }

            this.$refs.confirmRelinkProductDialog.show(params).then(response => {
                if (!response) {
                    return;
                }

                if (this.type === 'product') {
                    data = {
                        replacement: product.id,
                        replaceable: this.$route.params.product_id,
                        users: this.users,
                    };

                    request = this.$http.post('/api/manager/product-link/relink-by-users', data);
                } else {
                    data = {
                        user_product_id: this.product.id,
                        product_id: product.id,
                    };

                    request = this.$http.post('/api/manager/product-link/relink-user-product', data);
                }

                request.then(() => {
                    this.$bus.$emit('loadProductsLink');
                    this.$router.push({name: 'manager-product-link-products'});
                }, () => {
                    this.$ons.notification.toast({
                        buttonLabel: 'OK',
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        setArchive() {
            this.$noty.confirm('Удалить?').then(response => {
                if (!response) {
                    return;
                }

                let params = {users: this.users}

                if (this.type === 'product') {
                    params.product_id = this.product.id;
                } else {
                    params.user_product_id = this.product.id;
                }

                this.$http.post('/api/manager/product-link/remove-product', params).then(() => {
                    this.$bus.$emit('loadProductsLink');
                    this.$router.push({name: 'manager-product-link-products'});
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        setAvail() {
            this.$noty.confirm('Подтвердить?').then(response => {
                if (!response) {
                    return;
                }

                this.$http.post(
                    '/api/manager/product-link/set-avail-product',
                    {
                        id: this.$route.params.product_id,
                    },
                    {
                        params: {storageId: this.storageId}
                    },
                ).then(() => {
                    this.$bus.$emit('loadProductsLink');
                    this.$router.push({name: 'manager-product-link-products'});
                }, () => {
                    this.$ons.notification.toast({
                        timeout: 4000,
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
        searchActive() {
            this.isSearchActive = true;
            this.searchQuery = '';
            this.$nextTick(() => document.getElementById("search-input").focus());
        },
        renderItem(i) {
            let self = this;
            let item = this.items[i];

            return new Vue({
                render(h) {
                    return h(ProductAvailLinkItem, {
                        props: {
                            product: item,
                        },
                        on: {
                            choose: self.choose,
                        },
                    });
                }
            });
        },
    },
    components: {
        'v-confirm-relink-product-dialog': ConfirmRelinkProductDialog,
    },
};
</script>