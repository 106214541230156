<template>
    <v-ons-list @click="onClick" >
        <v-ons-list-item tappable >
            <router-link to="/">Главная</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/order">Заказы</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/catalog">Каталог</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/product-list">Мои товары</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/mymanager">Ваш менеджер</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/mymanager/routes">График доставки</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/profile">Профиль</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable >
            <router-link to="/settings">Настройки</router-link>
        </v-ons-list-item>

        <v-ons-list-item tappable @click="reload">
            Обновить
        </v-ons-list-item>

        <v-ons-list-item tappable class="info-bar" v-show="$user.discounts.getDiscount.enabled" >
            <router-link to="/contacts-for-discounts">Получить скидку</router-link>
        </v-ons-list-item>
    </v-ons-list>
</template>

<script>
export default {
    methods: {
        onClick() {
            this.$emit('click');
        },
        reload() {
            location.reload();
        },
    },
}
</script>
