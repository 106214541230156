var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "manager-order-index" },
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSearchActive,
              expression: "!isSearchActive"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [_c("v-ons-toggle-sidebar-button")],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [_vm._v("Заказы")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("orders-update")
                    }
                  }
                },
                [
                  _c("v-ons-icon", {
                    style: { color: _vm.hasNewOrder ? "#ffbb3d" : "white" },
                    attrs: { icon: "md-refresh" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchActive } },
                [_c("v-ons-icon", { attrs: { icon: "md-search" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "toolbar-popover-menu",
                { attrs: { "popover-class": "stick-right" } },
                [
                  _c("toolbar-popover-menu-item", {
                    attrs: { text: "Создать заказ", icon: "md-plus" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/manager/order/create")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: "Опции просмотра",
                      icon: "md-format-list-bulleted"
                    },
                    on: { click: _vm.showViewParamsModal }
                  }),
                  _vm._v(" "),
                  _c("v-ons-list-header", [_vm._v("Отображать заказы:")]),
                  _vm._v(" "),
                  _c(
                    "ons-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setFilterStatus("all")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "left icon" },
                        [
                          _c("v-ons-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filters.status === "all",
                                expression: "filters.status === 'all'"
                              }
                            ],
                            attrs: { icon: "md-check" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "center" }, [_vm._v("Все")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ons-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setFilterStatus("sended")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "left icon" },
                        [
                          _c("v-ons-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filters.status === "sended",
                                expression: "filters.status === 'sended'"
                              }
                            ],
                            attrs: { icon: "md-check" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "center" }, [_vm._v("Новые")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ons-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setFilterStatus("marked_to_send")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "left icon" },
                        [
                          _c("v-ons-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filters.status === "marked_to_send",
                                expression:
                                  "filters.status === 'marked_to_send'"
                              }
                            ],
                            attrs: { icon: "md-check" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "center" }, [_vm._v("Выгрузка")])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSearchActive,
              expression: "isSearchActive"
            }
          ],
          attrs: { inline: "true" }
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchDeactive } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery"
                }
              ],
              staticStyle: {
                color: "#fff",
                background: "none",
                border: "none",
                height: "34px",
                "line-height": "34px",
                "border-bottom": "1px solid #fff"
              },
              attrs: { id: "search-input" },
              domProps: { value: _vm.searchQuery },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchQuery = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("orders-update")
                    }
                  }
                },
                [
                  _c("v-ons-icon", {
                    style: { color: _vm.hasNewOrder ? "#ffbb3d" : "white" },
                    attrs: { icon: "md-refresh" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "toolbar-popover-menu",
                { attrs: { "popover-class": "stick-right" } },
                [
                  _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: "Опции просмотра",
                      icon: "md-format-list-bulleted"
                    },
                    on: { click: _vm.showViewParamsModal }
                  }),
                  _vm._v(" "),
                  _c("v-ons-list-header", [_vm._v("Отображать заказы:")]),
                  _vm._v(" "),
                  _c(
                    "ons-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setFilterStatus("all")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "left icon" },
                        [
                          _c("v-ons-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filters.status === "all",
                                expression: "filters.status === 'all'"
                              }
                            ],
                            attrs: { icon: "md-check" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "center" }, [_vm._v("Все")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ons-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setFilterStatus("sended")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "left icon" },
                        [
                          _c("v-ons-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filters.status === "sended",
                                expression: "filters.status === 'sended'"
                              }
                            ],
                            attrs: { icon: "md-check" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "center" }, [_vm._v("Новые")])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.error,
                expression: "!error"
              }
            ]
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.orders,
                    expression: "orders"
                  }
                ]
              },
              [
                _c(
                  "ons-list",
                  [
                    _vm._l(_vm.orders, function(order) {
                      return _vm.shouldShowOrder(order)
                        ? _c(
                            "ons-list-item",
                            {
                              key: order.id,
                              staticClass: "order-list-item",
                              attrs: {
                                modifier: "chevron longdivider",
                                tappable: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onOrderClick($event, order)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "center" },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: [
                                        "list-item__title",
                                        order.status_id === "sended"
                                          ? "order-new"
                                          : ""
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Заказ №" +
                                          _vm._s(order.id) +
                                          " "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: order.sended_at,
                                              expression: "order.sended_at"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "от " +
                                              _vm._s(
                                                _vm._f("moment")(
                                                  order.sended_at,
                                                  "L"
                                                )
                                              )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                                на сумму\n                                "
                                      ),
                                      order.isNearlyCost
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px"
                                              }
                                            },
                                            [_vm._v("~")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            order.hasDiscount
                                              ? order.totalCostWithDiscount
                                              : order.totalCost
                                          ) +
                                          " руб.\n                            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "list-item__subtitle" },
                                    [
                                      _vm._v(
                                        "\n                                Статус: " +
                                          _vm._s(order.status) +
                                          "\n                            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-subtitles", {
                                    attrs: {
                                      item: order,
                                      fields: _vm.$user.getOrderSubtitles(),
                                      selected: _vm.$user.orderSelectedSubtitles
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            ["draft", "deleted"].includes(
                                              order.status_id
                                            ) && order.is_new_cancel,
                                          expression:
                                            "['draft', 'deleted'].includes(order.status_id) && order.is_new_cancel"
                                        }
                                      ],
                                      staticClass:
                                        "list-item__subtitle wrap-hide-canceled"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "wrap",
                                          staticStyle: { "margin-top": "13px" }
                                        },
                                        [
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "16px",
                                                  color: "#ff6a00",
                                                  "font-weight": "bold"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Отменён\n                                        "
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col",
                                              staticStyle: {
                                                "margin-left": "25px"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-ons-button",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.hideCanceled(
                                                        order
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.filters.status ===
                                                          "all"
                                                          ? "ОК"
                                                          : "Скрыть"
                                                      ) +
                                                      "\n                                        "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.page < _vm.pageCount,
                            expression: "page < pageCount"
                          }
                        ],
                        staticStyle: {
                          "text-align": "center",
                          margin: "10px 0"
                        }
                      },
                      [
                        _c(
                          "v-ons-button",
                          {
                            staticStyle: { "min-width": "145px" },
                            on: { click: _vm.loadMore }
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.loadingMore,
                                    expression: "!loadingMore"
                                  }
                                ]
                              },
                              [_vm._v("Показать ещё")]
                            ),
                            _vm._v(" "),
                            _c("v-ons-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loadingMore,
                                  expression: "loadingMore"
                                }
                              ],
                              attrs: {
                                size: "30px",
                                spin: "",
                                icon: "md-spinner"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.orders.length === 0,
                    expression: "orders.length === 0"
                  }
                ],
                staticStyle: {
                  "text-align": "center",
                  "font-size": "15px",
                  "margin-top": "50px"
                }
              },
              [_vm._v("Ничего не найдено")]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.error,
                expression: "error"
              }
            ],
            staticStyle: {
              "text-align": "center",
              "font-size": "15px",
              "margin-top": "50px"
            }
          },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "15px", "font-size": "20px" } },
              [_vm._v("Произошла ошибка.")]
            ),
            _vm._v(" "),
            _c(
              "v-ons-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$emit("orders-update")
                  }
                }
              },
              [_vm._v("\n                Повторить\n            ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "viewParamsModal",
        attrs: { multiple: "true", title: "Опции просмотра" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52b3e5c5", { render: render, staticRenderFns: staticRenderFns })
  }
}