<template>
    <div style="padding: 0 16px" >
        <table class="table" >
            <tr>
                <td style="width: 23px;" >
                    <v-ons-checkbox v-model="isEnabledValue" :input-id="inputId" ></v-ons-checkbox>
                </td>
                <td colspan=2 >
                    <label :for="inputId" class="label-reset" >
                        {{ title }}
                    </label>
                </td>
            </tr>

            <tr v-show="hasAmount && isEnabledValue" >
                <td></td>
                <td colspan=2 >
                    <v-ons-input type="number" placeholder="сумма в руб." v-model="amountValue" > </v-ons-input>
                </td>
            </tr>

            <tr v-show="isEnabledValue" >
                <td></td>
                <td>Для типа цен:</td>
                <td>
                    <v-ons-select v-model="forPriceTypeValue" >
                        <option :value="''" >-</option>
                        <option v-for="type in priceTypes" :value="type.id" >
                            {{ type.name }}
                        </option>
                    </v-ons-select>
                </td>
            </tr>

            <tr v-show="isEnabledValue" >
                <td></td>
                <td>Переход на тип цен:</td>
                <td>
                    <v-ons-select v-model="gotoPriceTypeValue" >
                        <option :value="''" >-</option>
                        <option v-for="type in priceTypes" :value="type.id" >
                            {{ type.name }}
                        </option>
                    </v-ons-select>
                </td>
            </tr>

            <tr v-show="isEnabledValue" >
                <td></td>
                <td>
                    <div style="padding-top: 5px" >
                        <span v-show="finalPercent >= 0" >Итого скидка</span>
                        <span v-show="finalPercent < 0" >Увеличение цены</span>
                    </div>
                </td>
                <td>
                    <div style="padding-top: 5px" >
                        {{ finalPercent }}
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        priceTypes: {
            type: Array,
            default: [],
        },
        forPriceType: {
            type: String,
            default: null,
        },
        gotoPriceType: {
            type: String,
            default: null,
        },
        isEnabled: {
            type: Boolean,
            default: false,
        },
        hasAmount: {
            type: Boolean,
            default: false,
        },
        amount: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            forPriceTypeValue: '',
            gotoPriceTypeValue: '',
            isEnabledValue: false,
            amountValue: null,
            inputId: null,
        }
    },
    created() {
        this.inputId = 'discount-input-' + this.getUniqueId();
    },
    computed: {
        finalPercent() {
            let totalPercent = null;
            let forPriceType = this.priceTypes.find(i => i.id == this.forPriceTypeValue);
            let gotoPriceType = this.priceTypes.find(i => i.id == this.gotoPriceTypeValue);

            if (!forPriceType || !gotoPriceType) {
                return;
            }

            forPriceType = parseFloat(forPriceType.markup)
            gotoPriceType = parseFloat(gotoPriceType.markup)

            totalPercent = (100 * (1 + forPriceType / 100) - 100 * (1 + gotoPriceType / 100)) / (100 * (1 + forPriceType / 100)) * 100;

            return totalPercent.toFixed(2);
        }
    },
    watch: {
        forPriceTypeValue(val) {
            this.$emit('update:forPriceType', val);
        },
        forPriceType(val, old) {
            if (val != old) {
                this.forPriceTypeValue = val;
            }
        },
        gotoPriceTypeValue(val) {
            this.$emit('update:gotoPriceType', val);
        },
        gotoPriceType(val, old) {
            if (val != old) {
                this.gotoPriceTypeValue = val;
            }
        },
        isEnabledValue(val) {
            this.$emit('update:isEnabled', val);
        },
        isEnabled(val, old) {
            if (val != old) {
                this.isEnabledValue = val;
            }
        },
        amountValue(val) {
            this.$emit('update:amount', val);
        },
        amount(val, old) {
            if (val != old) {
                this.amountValue = val;
            }
        },
    },
};
</script>
