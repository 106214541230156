<template>
    <div>
        <v-ons-dialog :visible="dialogVisible" >
            <v-ons-toolbar inline="true" >
                <div class="center">Добавить в заказ</div>
            </v-ons-toolbar>

            <v-ons-list style="height: 216px; overflow-y: auto" >
                <v-ons-list-item style="cursor: pointer; text-align: center" @click="choose('new')">
                    <v-ons-button style="width: 100%">
                        Создать новый заказ
                    </v-ons-button>
                </v-ons-list-item>

                    <div v-show="unsendedOrders && unsendedOrders.length > 0" >
                    <v-ons-list-header>Добавить в черновик</v-ons-list-header>

                    <v-ons-list-item v-for="order in unsendedOrders" :key="order.id" style="cursor: pointer;" >
                        <div class="center" @click="choose(order)" >
                            №{{ order.id }} от {{ order.createdAt | moment("L") }} на сумму {{ order.totalCost }} руб.
                        </div>
                    </v-ons-list-item>
                </div>
            </v-ons-list>

            <v-ons-button
                modifier="quiet"
                style="width: 100%; margin: 5px 0;"
                @click="close"
            >Отмена</v-ons-button>
        </v-ons-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            unsendedOrders: null,
            positions: [],
            groupBy: null,

            resolve: null,
            reject: null,
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.$http.get('/api/order/get-unsended').then(response => {
                this.unsendedOrders = response.data;
            });
        },
        choose(order) {
            this.dialogVisible = false;

            let grouped = this.groupBy || 'byCatalogGroup';
            let url;
            if (order === 'new') {
                url = '/api/orders';
            } else {
                url = '/api/orders/' + order.id + '/positions';
            }

            this.$http.put(url, {positions: this.positions, grouped: grouped}).then(response => {
                let order = response.data;
                this.load();
                this.resolve(order);
                this.$bus.$emit('orders-update', order.id);

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Товар добавлен в заказ',
                    buttonLabel: 'Открыть',
                }).then(response => {
                    if (response !== 0) {
                        return;
                    }

                    this.$router.push('/order/' + order.id)
                });
            }, () => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Произошла ошибка.',
                });
            })
        },
        add(positions, groupBy) {
            this.positions = positions;
            this.groupBy = groupBy;

            if (this.unsendedOrders === null || this.unsendedOrders.length > 0) {
                this.dialogVisible = true;
            } else {
                this.choose('new');
            }

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.dialogVisible = false;
        },
    }
};
</script>