export default {
    webSocketUrl: 'wss://' + location.host + '/ws',
    socket: null,
    listeners: {},
    delay: 1000,
    numberAttempts: 0,

    onopen() {
        this.socket.send(JSON.stringify({token: this.token}));
    },
    onmessage(message) {
        let data = JSON.parse(message.data);

        Object.values(this.listeners)
            .filter(i => i.event === data.event)
            .filter(i => {
                if (!i.namespace) {
                    return true;
                }

                return i.namespace === data.namespace || data.namespace.indexOf(i.namespace) === 0;
            })
            .forEach(i => {
                i.func(data.msg);
            });
    },
    onclose() {
        this.reconnect();
    },
    onerror(e) {

    },
    connect(token) {
        this.token = token;
        this.socket = new WebSocket(this.webSocketUrl);
        this.socket.onopen = e => this.onopen(e);
        this.socket.onmessage = e => this.onmessage(e);
        this.socket.onclose = e => this.onclose(e);
        this.socket.onerror = e => this.onerror(e);
    },
    reconnect() {
        this.numberAttempts += 1;
        this.delay = Math.exp(this.numberAttempts) * 1000;

        setTimeout(() => {
            this.connect(this.token);
        }, this.delay);
    },
    disconnect() {
        this.socket.close();
        this.socket = null;
        this.token = null;
        this.numberAttempts = 0;
        this.delay = 1000;
    },
    install(Vue) {
        let listeners = this.listeners;

        Vue.prototype.$socket = this;

        let addListeners = function() {
            if (!this.$options.socket) {
                return;
            }

            let socket = this.$options.socket;

            if (typeof socket === 'function') {
                socket = socket.call(this);
            }

            socket.forEach(conf => {
                if (!conf.events) {
                    return;
                }

                let namespace = conf.namespace || '';

                Object.keys(conf.events).forEach(event => {
                    let key = this._uid + namespace + event;
                    listeners[key] = {
                        namespace: namespace,
                        event: event,
                        func: conf.events[event].bind(this),
                    };
                });
            });
        };

        let removeListeners = function() {
            if (!this.$options.socket) {
                return;
            }

            let conf = this.$options.socket;

            if (typeof conf === 'function') {
                conf = conf.call(this);
            }

            if (!conf.events) {
                return;
            }

            let namespace = conf.namespace || '';

            Object.keys(conf.events).forEach(event => {
                let key = this._uid + namespace + event;
                delete listeners[key];
            });
        };

        Vue.mixin({
            beforeCreate: addListeners,
            beforeDestroy: removeListeners
        });
    }
};