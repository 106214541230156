var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "report-sales-for-period" },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              attrs: { title: "Назад" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Обороты за период")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isGenerated && _vm.report.length > 0,
                    expression: "isGenerated && report.length > 0"
                  }
                ],
                on: { click: _vm.print }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-print" } })],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isGenerated,
                expression: "!isGenerated"
              }
            ]
          },
          [
            _c(
              "ons-list",
              [
                _c("ons-list-header", [_vm._v("Дата отгрузки")]),
                _vm._v(" "),
                _c("ons-list-item", [
                  _c("div", { staticClass: "wrap xl-gutter-8" }, [
                    _c(
                      "div",
                      { staticClass: "col xl-1-2" },
                      [
                        _c("v-input-datepicker", {
                          attrs: {
                            highlighted: _vm.highlightedDatepicker,
                            placeholder: "От"
                          },
                          model: {
                            value: _vm.filters.dateShipmentFrom,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "dateShipmentFrom", $$v)
                            },
                            expression: "filters.dateShipmentFrom"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col xl-1-2" },
                      [
                        _c("v-input-datepicker", {
                          attrs: {
                            highlighted: _vm.highlightedDatepicker,
                            placeholder: "До"
                          },
                          model: {
                            value: _vm.filters.dateShipmentTo,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "dateShipmentTo", $$v)
                            },
                            expression: "filters.dateShipmentTo"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("ons-list-item", [
                  _c("div", { staticClass: "center" }, [
                    _vm._v(
                      "\n                        Статус заказа\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "right" }, [
                    _c(
                      "div",
                      {
                        staticClass: "select-material select--material select"
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.orderStatus,
                                expression: "filters.orderStatus"
                              }
                            ],
                            staticClass: "select-input select-input--material",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.filters,
                                  "orderStatus",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.orderStatuses, function(status) {
                            return _c(
                              "option",
                              { domProps: { value: status.id } },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(status.name) +
                                    "\n                                "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("ons-list-item", [
                  _c(
                    "label",
                    {
                      staticClass: "center label-reset",
                      attrs: { for: "showEntity" }
                    },
                    [
                      _vm._v(
                        "\n                        Показать клиентов\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("v-ons-checkbox", {
                        staticStyle: {
                          "vertical-align": "middle",
                          margin: "0 12px 0 0"
                        },
                        attrs: { "input-id": "showEntity" },
                        model: {
                          value: _vm.filters.showEntity,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "showEntity", $$v)
                          },
                          expression: "filters.showEntity"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("ons-list-item", [
                  _c(
                    "label",
                    {
                      staticClass: "center label-reset",
                      attrs: { for: "showProduct" }
                    },
                    [
                      _vm._v(
                        "\n                        Показать товары\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("v-ons-checkbox", {
                        staticStyle: {
                          "vertical-align": "middle",
                          margin: "0 12px 0 0"
                        },
                        attrs: { "input-id": "showProduct" },
                        model: {
                          value: _vm.filters.showProduct,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "showProduct", $$v)
                          },
                          expression: "filters.showProduct"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.$user.isAdmin
                  ? _c("ons-list-item", [
                      _c("div", { staticClass: "center" }, [
                        _vm._v(
                          "\n                        Менеджеры\n                        "
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filters.managers.length > 0,
                                expression: "filters.managers.length > 0"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "(" + _vm._s(_vm.filters.managers.length) + ")"
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "v-ons-button",
                            { on: { click: _vm.chooseManagers } },
                            [_vm._v("Выбрать")]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("ons-list-item", [
                  _c("div", { staticClass: "center" }, [
                    _vm._v(
                      "\n                        Фильтр по заказам\n                        "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.filters.ids.length > 0,
                            expression: "filters.ids.length > 0"
                          }
                        ]
                      },
                      [_vm._v("(" + _vm._s(_vm.filters.ids.length) + ")")]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c(
                        "v-ons-button",
                        {
                          attrs: {
                            disabled: _vm.filterByOrdersButtonIsDisabled
                          },
                          on: { click: _vm.chooseOrders }
                        },
                        [
                          _vm._v(
                            "\n                            Выбрать\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "10px", "text-align": "center" } },
              [
                _c("v-ons-button", { on: { click: _vm.generate } }, [
                  _vm._v("\n                    Сформировать\n                ")
                ])
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isGenerated,
                expression: "isGenerated"
              }
            ]
          },
          [
            _vm.report.length > 0
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: { "text-align": "center", margin: "5px 0" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.periodLabel) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("table", { staticClass: "table" }, [
                    _c(
                      "tbody",
                      _vm._l(_vm.report, function(item) {
                        return _c(
                          "tr",
                          {
                            class:
                              "tr-" +
                              item.type +
                              (item.level ? " level-" + item.level : "")
                          },
                          [
                            item.type === "header"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "frag", rawName: "v-frag" }
                                    ]
                                  },
                                  [
                                    _c("td", { attrs: { colspan: "2" } }, [
                                      _vm._v(_vm._s(item.content))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "td-amount",
                                        attrs: { colspan: "2" }
                                      },
                                      [_vm._v(_vm._s(item.amount) + " р.")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "row"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "frag", rawName: "v-frag" }
                                    ]
                                  },
                                  [
                                    _c("td", [_vm._v(_vm._s(item.id))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.name))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.count))]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "td-amount" }, [
                                      _vm._v(_vm._s(item.amount) + " р.")
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "footer"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "frag", rawName: "v-frag" }
                                    ]
                                  },
                                  [
                                    _c("td", { attrs: { colspan: "2" } }, [
                                      _vm._v(_vm._s(item.content))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "td-amount",
                                        attrs: { colspan: "2" }
                                      },
                                      [_vm._v(_vm._s(item.amount) + " р.")]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.report.length === 0,
                    expression: "report.length === 0"
                  }
                ],
                staticStyle: {
                  "text-align": "center",
                  "font-size": "15px",
                  "margin-top": "50px"
                }
              },
              [_vm._v("Ничего не найдено")]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "managersDialog",
        attrs: {
          multiple: "true",
          allowselectall: "true",
          title: "Менеджеры",
          items: _vm.managers
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-305112a6", { render: render, staticRenderFns: staticRenderFns })
  }
}