var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasChecked && !_vm.isSearchActive,
              expression: "!hasChecked && !isSearchActive"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _vm.showBackButton
                ? _c("v-ons-back-button", { attrs: { title: "Назад" } })
                : _c("v-ons-toggle-sidebar-button")
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-ons-toolbar-button",
                [
                  _c("v-ons-icon", {
                    style: {
                      color: _vm.filters.groups.length > 0 ? "#ffe000" : ""
                    },
                    attrs: {
                      title: "Разделы каталога",
                      icon: "md-filter-list"
                    },
                    on: { click: _vm.chooseGroupFilter }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.toolbarButtons.includes("catalogSettingsButton")
                ? _c(
                    "v-ons-toolbar-button",
                    { on: { click: _vm.showCatalogSettingsDialog } },
                    [_c("v-ons-icon", { attrs: { icon: "md-settings" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.toolbarButtons.includes("showAssignTemplateModalButton")
                ? _c(
                    "v-ons-toolbar-button",
                    {
                      attrs: { title: "Выборка по Моим товарам" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.assignTemplateModal.show()
                        }
                      }
                    },
                    [
                      _c("v-ons-icon", {
                        attrs: { icon: _vm.getAssignTemplateModalIconName() }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                { attrs: { title: "Поиск" }, on: { click: _vm.searchActive } },
                [_c("v-ons-icon", { attrs: { icon: "md-search" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: {
                    id: "toolbarButtonSettings",
                    title: "Дополнительные функции"
                  },
                  on: {
                    click: function($event) {
                      _vm.popoverToolbarMenuVisible = true
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-more-vert" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChecked,
              expression: "hasChecked"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: { title: "Отменить выделение" },
                  on: { click: _vm.uncheckAll }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm._v(
              "\n            Выбрано: " +
                _vm._s(_vm.checked.length) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _vm.$user.isManager
                ? _c(
                    "v-ons-toolbar-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasCheckedOne,
                          expression: "hasCheckedOne"
                        }
                      ],
                      attrs: { title: "Изменить" },
                      on: { click: _vm.clickGoToUpdate }
                    },
                    [_c("v-ons-icon", { attrs: { icon: "md-edit" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$user.isManager
                ? _c(
                    "v-ons-toolbar-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasCheckedTwo,
                          expression: "hasCheckedTwo"
                        }
                      ],
                      attrs: { title: "Копировать данные" },
                      on: { click: _vm.clickCopyData }
                    },
                    [_c("v-ons-icon", { attrs: { icon: "md-link" } })],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSearchActive,
              expression: "isSearchActive"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "v-ons-toolbar-button",
                { on: { click: _vm.searchDeactivate } },
                [_c("v-ons-icon", { attrs: { icon: "md-arrow-left" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _c("div", { staticClass: "search-wrap" }, [
              _c(
                "form",
                {
                  attrs: { action: "", autocomplete: "off" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return function() {}.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchQuery,
                        expression: "searchQuery"
                      }
                    ],
                    staticStyle: {
                      color: "#fff",
                      background: "none",
                      border: "none",
                      height: "34px",
                      "line-height": "34px",
                      "border-bottom": "1px solid #fff"
                    },
                    attrs: {
                      id: "search-input",
                      autocomplete: "off",
                      spellcheck: "false"
                    },
                    domProps: { value: _vm.searchQuery },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchQuery = $event.target.value
                      }
                    }
                  })
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _vm.$user.isManager
                ? _c(
                    "v-ons-toolbar-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasCheckedTwo,
                          expression: "hasCheckedTwo"
                        }
                      ],
                      attrs: { title: "Копировать данные" },
                      on: { click: _vm.clickCopyData }
                    },
                    [_c("v-ons-icon", { attrs: { icon: "md-link" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                [
                  _c("v-ons-icon", {
                    style: {
                      color: _vm.filters.groups.length > 0 ? "#ffe000" : ""
                    },
                    attrs: { icon: "md-filter-list" },
                    on: { click: _vm.chooseGroupFilter }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.toolbarButtons.includes("showAssignTemplateModalButton")
                ? _c(
                    "v-ons-toolbar-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$refs.assignTemplateModal.show()
                        }
                      }
                    },
                    [
                      _c("v-ons-icon", {
                        attrs: { icon: _vm.getAssignTemplateModalIconName() }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$user.isManager &&
              _vm.toolbarButtons.includes("showCatalogSettingsDialog")
                ? _c(
                    "v-ons-toolbar-button",
                    { on: { click: _vm.showCatalogSettingsDialog } },
                    [_c("v-ons-icon", { attrs: { icon: "md-settings" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-ons-toolbar-button",
                {
                  attrs: {
                    id: "toolbarButtonSettingsSearching",
                    title: "Дополнительные функции"
                  },
                  on: {
                    click: function($event) {
                      _vm.popoverToolbarMenuVisible = true
                    }
                  }
                },
                [_c("v-ons-icon", { attrs: { icon: "md-more-vert" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-popover",
        {
          staticClass: "popover-menu stick-right",
          attrs: {
            cancelable: "",
            visible: _vm.popoverToolbarMenuVisible,
            target: _vm.isSearchActive
              ? "#toolbarButtonSettingsSearching"
              : "#toolbarButtonSettings",
            "cover-target": "true",
            direction: "down"
          },
          on: {
            "update:visible": function($event) {
              _vm.popoverToolbarMenuVisible = $event
            }
          }
        },
        [
          _c(
            "ons-list",
            {
              on: {
                click: function($event) {
                  _vm.popoverToolbarMenuVisible = false
                }
              }
            },
            [
              _c("toolbar-popover-menu-item", {
                attrs: { text: "Разделы каталога", icon: "md-filter-list" },
                on: { click: _vm.chooseGroupFilter }
              }),
              _vm._v(" "),
              _vm.toolbarButtons.includes("downloadCatalogButton")
                ? _c("toolbar-popover-menu-item", {
                    attrs: { text: "Скачать прайс", icon: "md-download" },
                    on: { click: _vm.showDownloadCatalogPromptDialog }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.toolbarButtons.includes("showAssignTemplateModalButton")
                ? _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: "Выборка по Моим товарам",
                      icon: _vm.getAssignTemplateModalIconName()
                    },
                    on: {
                      click: function($event) {
                        return _vm.$refs.assignTemplateModal.show()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isShowShoppingCartFab
                ? _c("toolbar-popover-menu-item", {
                    attrs: {
                      disabled: !_vm.hasPositionsToAdd,
                      text: "Добавить к заказу",
                      icon: "md-shopping-cart"
                    },
                    on: { click: _vm.add }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.$user.isManager &&
              _vm.catalogCountsKeeper.getSessions().length > 0
                ? _c("toolbar-popover-menu-item", {
                    attrs: {
                      text: "Восстановление данных",
                      icon: "md-time-restore"
                    },
                    on: { click: _vm.openCatalogCountsKeeper }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Отображать как:")]),
              _vm._v(" "),
              _c("toolbar-popover-menu-item", {
                attrs: {
                  text: "Список",
                  icon: "md-view-list",
                  iconColor: _vm.catalogViewType === "list" ? "#000" : ""
                },
                on: {
                  click: function($event) {
                    return _vm.changeCatalogViewType("list")
                  }
                }
              }),
              _vm._v(" "),
              _c("toolbar-popover-menu-item", {
                attrs: {
                  text: "Плитка",
                  icon: "md-view-module",
                  iconColor: _vm.catalogViewType === "tile" ? "#000" : ""
                },
                on: {
                  click: function($event) {
                    return _vm.changeCatalogViewType("tile")
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("catalog-settings-dialog", { ref: "catalogSettingsDialog" }),
      _vm._v(" "),
      _c("list-dialog", {
        ref: "catalogCountsKeeperDialog",
        attrs: {
          title: "Восстановление данных",
          items: _vm.catalogCountsKeeperItems
        }
      }),
      _vm._v(" "),
      _c("assign-template-modal", {
        ref: "assignTemplateModal",
        attrs: {
          assignedTemplates: _vm.assignedTemplates,
          allowSelectGroup: true,
          groupedBy: _vm.groupBy,
          url: _vm.templateEntrypoint
        },
        on: { setGroupBy: _vm.setGroupBy, choose: _vm.onChooseTemplate }
      }),
      _vm._v(" "),
      _c("group-filter-modal", { ref: "groupFilterModal" }),
      _vm._v(" "),
      _c("download-catalog-prompt-dialog", {
        ref: "downloadCatalogPromptDialog",
        attrs: { userProductsEntrypoint: _vm.userProductsEntrypoint }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.catalog.length > 0
          ? _c(
              "div",
              [
                _vm.isDesktop && _vm.catalogViewType === "list"
                  ? _c(
                      "v-ons-list",
                      {
                        staticClass: "list-catalog is-desktop",
                        attrs: { id: "list-header-catalog" }
                      },
                      [
                        _c(
                          "v-ons-list-item",
                          {
                            staticClass: "table-header-wrap",
                            style: { width: _vm.listCatalogWidth + "px" }
                          },
                          [
                            _c("div", { staticClass: "col-checkbox" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-name" }, [
                              _vm._v("Наименование")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isDesktop && _vm.shouldShowRest,
                                    expression: "isDesktop && shouldShowRest"
                                  }
                                ],
                                staticClass: "col-rest"
                              },
                              [_vm._v("Остаток\n                    ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.$user.canSeePrice,
                                    expression: "$user.canSeePrice"
                                  }
                                ],
                                staticClass: "col-price"
                              },
                              [_vm._v("Цена")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.allowInputs &&
                                      (_vm.showInputs || _vm.isDesktop),
                                    expression:
                                      "allowInputs && (showInputs || isDesktop)"
                                  }
                                ],
                                staticClass: "col-count"
                              },
                              [_c("span", [_vm._v("Заказ")])]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-ons-list",
                  {
                    class:
                      _vm.catalogViewType === "list"
                        ? "list-catalog"
                        : "tile-catalog",
                    attrs: { id: "list-catalog" }
                  },
                  [
                    _vm.catalogViewType === "list"
                      ? _c(
                          "div",
                          _vm._l(_vm.catalog, function(category) {
                            return _c("category-item-list", {
                              key: category.id,
                              attrs: {
                                item: category,
                                isDesktop: _vm.isDesktop,
                                actionColType: _vm.actionColType,
                                canOpenProductPage: _vm.canOpenProductPage,
                                showInputs: _vm.showInputs,
                                selectedPriceType: _vm.selectedPriceType,
                                counts: _vm.counts,
                                checked: _vm.checked,
                                shouldShowRest: _vm.shouldShowRest,
                                lastImportDate: _vm.lastImportDate,
                                allowInputs: _vm.allowInputs
                              },
                              on: {
                                clickGoToView: _vm.clickGoToView,
                                toggleFavor: _vm.toggleFavor,
                                countEnterDown: _vm.onCountEnterDown,
                                countShiftEnterDown: _vm.onCountShiftEnterDown,
                                clickCategory: _vm.onClickCategory
                              }
                            })
                          }),
                          1
                        )
                      : _c(
                          "div",
                          _vm._l(_vm.catalog, function(category) {
                            return _c("category-item-tile", {
                              key: category.id,
                              attrs: {
                                item: category,
                                isDesktop: _vm.isDesktop,
                                actionColType: _vm.actionColType,
                                canOpenProductPage: _vm.canOpenProductPage,
                                showInputs: _vm.showInputs,
                                selectedPriceType: _vm.selectedPriceType,
                                counts: _vm.counts,
                                checked: _vm.checked,
                                lastImportDate: _vm.lastImportDate,
                                allowInputs: _vm.allowInputs
                              },
                              on: {
                                clickGoToView: _vm.clickGoToView,
                                toggleFavor: _vm.toggleFavor,
                                countEnterDown: _vm.onCountEnterDown,
                                countShiftEnterDown: _vm.onCountShiftEnterDown,
                                clickCategory: _vm.onClickCategory
                              }
                            })
                          }),
                          1
                        )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { width: "100%", height: "80px" } })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.catalog.length === 0,
                expression: "catalog.length === 0"
              }
            ],
            staticClass: "list-empty",
            style: {
              height: _vm.$window.height - 56 + "px",
              flexDirection: "column"
            }
          },
          [
            _c("div", [_vm._v("Ничего не найдено")]),
            _vm._v(" "),
            _c("v-ons-button", { on: { click: _vm.reset } }, [
              _vm._v("Сбросить")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("select-add-method-modal", { ref: "selectAddMethodModal" }),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowShoppingCartFab,
              expression: "isShowShoppingCartFab"
            }
          ],
          attrs: {
            title: "Добавить к заказу",
            position: "bottom right",
            disabled: !_vm.hasPositionsToAdd
          },
          on: { click: _vm.add }
        },
        [
          _c("v-ons-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.adding,
                expression: "!adding"
              }
            ],
            attrs: { icon: "md-shopping-cart" }
          }),
          _vm._v(" "),
          _c("v-ons-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.adding,
                expression: "adding"
              }
            ],
            attrs: { size: "30px", spin: "", icon: "md-spinner" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-38f2b30d", { render: render, staticRenderFns: staticRenderFns })
  }
}