<template>
    <v-ons-page>
        <v-ons-toolbar inline="true"  >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">Профиль: {{ user.email }}</div>
        </v-ons-toolbar>

        <ons-list >
            <ons-list-item
                modifier="chevron longdivider"
                tappable
                @click="$router.push({name: 'manager-user-auth'})" >

                <div class="center" >Авторизоваться</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                @click="changePassword" >

                <div class="center" >Изменить пароль</div>
            </ons-list-item>

            <ons-list-item
                modifier="longdivider"
                tappable
                v-show="user.user_type == 'customer' && !user.hasWorkers"
                @click="$router.push({name: 'manager-choose-director'})" >

                <div class="center" >Сделать сотрудником</div>
            </ons-list-item>

            <ons-list-item
                modifier="chevron longdivider"
                tappable
                v-show="$user.isAdmin && user.user_type == 'customer'"
                @click="$router.push({name: 'manager-choose-manager'})" >

                <div class="center" >Закрепить менеджера</div>
            </ons-list-item>

            <ons-list-item
                modifier="longdivider"
                tappable
                v-show="$user.isAdmin && user.user_type == 'customer'"
                @click="turnToManager" >

                <div class="center" >Сделать менеджером</div>
            </ons-list-item>

            <ons-list-item
                modifier="longdivider"
                tappable
                v-show="$user.isAdmin && user.user_type == 'manager'"
                @click="turnToCustomer" >

                <div class="center" >Сделать клиентом</div>
            </ons-list-item>

            <ons-list-item
                modifier="longdivider"
                tappable
                v-show="user.user_type == 'worker'"
                @click="leaveDirector" >

                <div class="center" >Сделать независимым клиентом</div>
            </ons-list-item>
        </ons-list>

        <confirm ref="confirmTurnToManagerDialog" ></confirm>
        <change-password ref="changePasswordModal" ></change-password>
    </v-ons-page>
</template>

<script>

import ConfirmTurnToManagerDialog from '~/component/ConfirmWithCheckboxDialog'
import ChangePasswordModal from './ChangePasswordModal'

export default {
    data() {
        return {
            user: {},
        }
    },
    created() {
        if (!this.$route.params.user) {
            this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
            return;
        }

        this.user = this.$route.params.user;
    },
    methods: {
        load() {
            this.$http.get('/api/manager/users/' + this.$route.params.user_id).then(response => {
                this.user = response.data;

                if (this.user.user_type == 'worker') {
                    this.$http.get(`/api/manager/users/${this.user.id}/director`).then(response => {
                        this.director = response.data;
                    });
                }
            });
        },
        changePassword() {
            this.$refs.changePasswordModal.show().then(response => {
                if (!response.response || !response.password) {
                    return;
                }

                this.$http.post('/api/manager/users/change-password', {password: response.password}, {params: {id: this.$route.params.user_id}}).then(response => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Пароль изменен',
                        buttonLabel: 'OK'
                    });
                }, response => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        turnToManager() {
            let params = {
                title: 'Подтверждение',
                message: 'Сделать менеджером?',
                checkboxLabel: 'Скопировать настройки',
            }

            this.$refs.confirmTurnToManagerDialog.confirm(params).then(response => {
                if (!response.response) {
                    return;
                }

                this.$http.post('/api/manager/users/turn-to-manager', {isCopySettings: response.isChecked}, {params: {id: this.$route.params.user_id}}).then(response => {
                    this.load();
                }, response => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        },
        turnToCustomer() {
            this.$ons.notification.confirm('Сделать клиентом?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.post('/api/manager/users/turn-to-customer', {}, {params: {id: this.$route.params.user_id}}).then(response => {
                    this.load();
                }, response => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            })
        },
        leaveDirector() {
            this.$ons.notification.confirm('Сделать независимым клиентом?', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }

                this.$http.post(`/api/manager/users/${this.user.id}/leave-director`).then(() => {
                    this.load();
                }, response => {
                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            })
        },
    },
    components: {
        'confirm': ConfirmTurnToManagerDialog,
        'change-password': ChangePasswordModal,
    },
    watch: {
        '$route.params.user_id'(newId, oldId) {
            this.load();
        }
    }
};
</script>
