var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [_c("v-ons-back-button", { attrs: { title: "Назад" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [_vm._v("Управление разделами")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content", staticStyle: { "padding-bottom": "160px" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.categories.length > 0,
                  expression: "categories.length > 0"
                }
              ]
            },
            [
              _c("v-ons-card", [
                _vm._v(
                  "\n                Выберите разделы для объединения или переименования\n            "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-ons-list",
                _vm._l(_vm.categories, function(category, key) {
                  return _c("ons-list-item", { key: category.id }, [
                    _c(
                      "label",
                      {
                        staticClass: "center label-reset",
                        attrs: { for: "category-" + key }
                      },
                      [
                        _c("span", { staticClass: "list-item__title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(category.name) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "list-item__subtitle",
                          domProps: {
                            innerHTML: _vm._s(_vm.categoryHint(category))
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c("v-ons-checkbox", {
                          staticStyle: {
                            "vertical-align": "middle",
                            margin: "0 12px 0 0"
                          },
                          attrs: {
                            value: category.id,
                            "input-id": "category-" + key
                          },
                          model: {
                            value: _vm.checked,
                            callback: function($$v) {
                              _vm.checked = $$v
                            },
                            expression: "checked"
                          }
                        })
                      ],
                      1
                    )
                  ])
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.categories.length === 0,
                  expression: "categories.length === 0"
                }
              ],
              staticClass: "list-empty",
              style: {
                flexFlow: "column",
                height: _vm.$window.height - 56 + "px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-bottom": "10px", "font-size": "15px" }
                },
                [_vm._v("\n                Данные не обнаружены\n            ")]
              ),
              _vm._v(" "),
              _c(
                "ons-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        "/manager/management/catalog-settings/includes"
                      )
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                Настройте выгрузку разделов\n            "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCheckedOne,
              expression: "hasCheckedOne"
            }
          ],
          attrs: { position: "bottom right", title: "Переименовать раздел" },
          on: { click: _vm.rename }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-edit" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCheckedMulty,
              expression: "hasCheckedMulty"
            }
          ],
          attrs: { position: "bottom right", title: "Объединить разделы" },
          on: { click: _vm.merge }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-unfold-less" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCheckedMerged,
              expression: "hasCheckedMerged"
            }
          ],
          staticStyle: { bottom: "90px" },
          attrs: { position: "bottom right", title: "Разъединить разделы" },
          on: { click: _vm.unmerge }
        },
        [_c("v-ons-icon", { attrs: { icon: "md-unfold-more" } })],
        1
      ),
      _vm._v(" "),
      _c("v-prompt-dialog", {
        ref: "prompt",
        attrs: { title: "Новое имя раздела" }
      }),
      _vm._v(" "),
      _c("v-merge-dialog", { ref: "mergeDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-744301a2", { render: render, staticRenderFns: staticRenderFns })
  }
}