<template>
    <v-ons-alert-dialog modifier="rowfooter" :visible.sync="visible" @prehide="onPreHide" >
        <span slot="title" style="font-size: 18px" >Провести замену связей?</span>

        <div>{{ productNameFrom }}</div>

        <div style="text-align: center; margin: 10px 0" >
            <v-ons-icon icon="md-format-valign-bottom" size="30px"></v-ons-icon>
        </div>

        <div>{{ productNameTo }}</div>

        <template slot="footer">
            <v-ons-alert-dialog-button @click="onOk">Да</v-ons-alert-dialog-button>
            <v-ons-alert-dialog-button @click="onNo">Нет</v-ons-alert-dialog-button>
        </template>
    </v-ons-alert-dialog>
</template>

<script>
export default {
    data() {
        return {
            productNameFrom: '',
            productNameTo: '',

            visible: false,

            resolve: null,
        }
    },
    methods: {
        show(params) {
            this.visible = true;
            this.productNameTo = params.productNameTo;
            this.productNameFrom = params.productNameFrom;

            document.addEventListener('keydown', this.onKeydown);

            return new Promise(resolve => {
                this.resolve = resolve;
            });
        },
        onNo() {
            this.visible = false;
            this.resolve(false);
        },
        onOk() {
            this.visible = false;
            this.resolve(true);
        },
        onKeydown(event) {
            event.preventDefault();
            event.stopPropagation();

            if (event.keyCode === 13 || event.keyCode === 27) {
                if (event.keyCode === 13) {
                    this.onOk();
                }

                if (event.keyCode === 27) {
                    this.onNo();
                }

                event.preventDefault();
                return false;
            }

            return false;
        },
        onPreHide() {
            document.removeEventListener('keydown', this.onKeydown);
        },
    },
};
</script>
