var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-ons-card", [
    _c("h1", [_vm._v(_vm._s(_vm.product.name))]),
    _vm._v(" "),
    _vm.$user.isManager
      ? _c("p", [_vm._v("Артикул: " + _vm._s(_vm.product.sku || "-"))])
      : _vm._e(),
    _vm._v(" "),
    _c("p", [_vm._v("Ед. изм: " + _vm._s(_vm.product.unit || "-"))]),
    _vm._v(" "),
    _c("p", [_vm._v("Кратность: " + _vm._s(_vm.product.mult || "-"))]),
    _vm._v(" "),
    _vm.$user.isGuest && !_vm.$user.guestSettings.showPrice
      ? _c(
          "p",
          [
            _c("span", { staticStyle: { "line-height": "36px" } }, [
              _vm._v("Цена:")
            ]),
            _vm._v(" "),
            _c(
              "v-ons-button",
              {
                attrs: { modifier: "quiet" },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/register")
                  }
                }
              },
              [_vm._v("доступно после регистрации")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    (_vm.$user.isGuest && _vm.$user.guestSettings.showPrice) ||
    (!_vm.$user.isGuest && _vm.$user.canSeePrice)
      ? _c("div", [
          !_vm.product.is_deleted
            ? _c("div", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.product.price,
                        expression: "product.price"
                      }
                    ]
                  },
                  [
                    _c("div", { staticClass: "wrap wrap-price" }, [
                      _c("div", { staticClass: "col xl-1-2 col-price" }, [
                        _c(
                          "div",
                          {
                            class: [
                              "product-price",
                              _vm.product.specialPrice.isShowMark
                                ? "is-low-special-price"
                                : ""
                            ]
                          },
                          [
                            _vm._v(
                              "\n                            Цена: " +
                                _vm._s(_vm.product.price) +
                                " руб./" +
                                _vm._s(_vm.product.unit) +
                                "\n\n                            "
                            ),
                            _vm.product.specialPrice.isShowPreviousPrice
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "special-price-base",
                                    staticStyle: { "margin-left": "41px" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.product.specialPrice.basePrice
                                        ) +
                                        " руб./" +
                                        _vm._s(_vm.product.unit) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col xl-1-2 col-label xl-right" },
                        [
                          _c(
                            "div",
                            [
                              _c("v-ons-icon", {
                                attrs: { icon: "md-check-circle" }
                              }),
                              _vm._v(
                                "\n                            В наличии\n                        "
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.product.price,
                        expression: "!product.price"
                      }
                    ]
                  },
                  [_vm._v("Цена: -")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.is_deleted
            ? _c("div", [
                _c("div", { staticClass: "wrap-price" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.product.price,
                          expression: "product.price"
                        }
                      ],
                      class: [
                        "product-price",
                        _vm.product.specialPrice.isShowMark
                          ? "is-low-special-price"
                          : ""
                      ],
                      staticStyle: { "margin-bottom": "10px" }
                    },
                    [
                      _vm._v(
                        "\n                    Крайняя цена: " +
                          _vm._s(_vm.product.price) +
                          " руб./" +
                          _vm._s(_vm.product.unit) +
                          "\n\n                    "
                      ),
                      _vm.product.specialPrice.isShowPreviousPrice
                        ? _c(
                            "div",
                            {
                              staticClass: "special-price-base",
                              staticStyle: { "margin-left": "98px" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.product.specialPrice.basePrice) +
                                  " руб./" +
                                  _vm._s(_vm.product.unit) +
                                  "\n                    "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.product.price,
                          expression: "!product.price"
                        }
                      ]
                    },
                    [_vm._v("Крайняя цена: -")]
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("Товар кончился: " + _vm._s(_vm.product.deletedAt))
                ]),
                _vm._v(" "),
                _vm.product.is_wait
                  ? _c("p", [
                      _vm._v(
                        "\n                Информация о поступлении: " +
                          _vm._s(
                            _vm.product.info_about_admission
                              ? _vm.product.info_about_admission
                              : "нет данных"
                          ) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "text-align": "center", "margin-top": "20px" } },
      [
        _vm.product && _vm.product.images && _vm.product.images.thumb220
          ? _c("img", {
              staticClass: "product-image",
              attrs: {
                src: _vm.product.images.thumb220.path,
                alt: _vm.product.name,
                title: _vm.product.name,
                width: _vm.product.images.thumb220.width,
                height: _vm.product.images.thumb220.height
              }
            })
          : _c("img", { attrs: { src: "/img/placeholder.png" } }),
        _vm._v(" "),
        !_vm.$user.isManager && !_vm.product.images.thumb220.hasImage
          ? _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                !_vm.sendedRequestForPhoto
                  ? _c(
                      "v-ons-button",
                      {
                        staticStyle: { width: "165px" },
                        on: { click: _vm.sendRequestForPhoto }
                      },
                      [
                        _vm.sendingRequestForPhoto
                          ? _c("span", [_vm._v("Отправка...")])
                          : _c("span", [_vm._v("Мне нужно фото")])
                      ]
                    )
                  : _c("v-ons-button", { attrs: { disabled: "" } }, [
                      _vm._v("\n                Запрос отправлен\n            ")
                    ])
              ],
              1
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.product.characteristics
      ? _c(
          "div",
          { staticClass: "characteristics-wrap" },
          _vm._l(_vm.product.characteristics, function(characteristic) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: characteristic.value,
                    expression: "characteristic.value"
                  }
                ],
                key: characteristic.id,
                staticClass: "characteristic-wrap"
              },
              [
                _c("h2", { staticClass: "characteristics-label" }, [
                  _vm._v(_vm._s(characteristic.label) + ":")
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "characteristics-value",
                  domProps: { innerHTML: _vm._s(characteristic.value) }
                })
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-53b1d9e4", { render: render, staticRenderFns: staticRenderFns })
  }
}