var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tile-item-product", style: { width: _vm.width } },
    [
      _c("div", { staticClass: "tile-item-product-inner" }, [
        _c("div", { staticClass: "image-wrap" }, [
          _vm.item.images && _vm.item.images.thumb100
            ? _c("img", {
                class: [
                  "product-image",
                  _vm.canOpenProductPage ? "cursor-pointer" : "",
                  _vm.item.images.thumb100.hasImage ? "has-product-image" : ""
                ],
                attrs: {
                  src: _vm.item.images.thumb100.path,
                  alt: _vm.item.name,
                  title: _vm.item.name,
                  width: _vm.item.images.thumb100.width,
                  height: _vm.item.images.thumb100.height
                },
                on: { click: _vm.clickGoToView }
              })
            : _c("img", {
                class: [
                  "product-image",
                  _vm.canOpenProductPage ? "cursor-pointer" : ""
                ],
                attrs: { src: "/img/placeholder.png" },
                on: { click: _vm.clickGoToView }
              })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "labels" }, [
          _vm.actionColType === "favor" || _vm.actionColType === "favor-tiny"
            ? _c(
                "div",
                {
                  staticClass: "cursor-pointer",
                  on: { click: _vm.toggleFavor }
                },
                [
                  _c("v-ons-icon", {
                    staticClass: "icon",
                    style: {
                      color: _vm.item.isInMyProducts ? "#ffe000" : "#e3e3e3"
                    },
                    attrs: {
                      icon: "md-star",
                      title: _vm.favorButtonTitle,
                      size: "20px"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.is_special
            ? _c("div", { staticClass: "vip-icon icon" }, [_vm._v("VIP")])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product-attributes" }, [
          _c(
            "div",
            {
              class: [
                "product-name",
                _vm.canOpenProductPage ? "cursor-pointer" : ""
              ],
              on: { click: _vm.clickGoToView }
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.item.name) + "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _vm.$user.canSeePrice
            ? _c("div", { staticClass: "product-price-row" }, [
                _c("div", { staticClass: "product-price" }, [
                  _vm.item.is_wait
                    ? _c("span", [_vm._v("ждём")])
                    : _c(
                        "span",
                        {
                          class: [
                            _vm.item.specialPrice.isShowMark
                              ? "is-low-special-price"
                              : ""
                          ]
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.calcPrice(_vm.item)) +
                              " р/" +
                              _vm._s(_vm.item.unit) +
                              "\n                    "
                          )
                        ]
                      )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.allowInputs
            ? _c("div", { staticClass: "product-add-button-wrap" }, [
                _vm.counts[_vm.item.id] > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "button--material button product-counting"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "dec-button",
                            on: { click: _vm.decCount }
                          },
                          [
                            _vm._v(
                              "\n                        -\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "count-label" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.counts[_vm.item.id]) +
                              " " +
                              _vm._s(_vm.item.unit) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "inc-button",
                            on: { click: _vm.incCount }
                          },
                          [
                            _vm._v(
                              "\n                        +\n                    "
                            )
                          ]
                        )
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "product-add-button button--material button cursor-pointer",
                        on: { click: _vm.incCount }
                      },
                      [_vm._v("\n                    Купить\n                ")]
                    )
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5ba8e8bf", { render: render, staticRenderFns: staticRenderFns })
  }
}