<template>
    <v-ons-page>
        <v-ons-toolbar v-show="!hasChecked" inline="true"  >
            <div class="left">
                <v-ons-back-button></v-ons-back-button>
            </div>

            <div class="center">История</div>

            <div class="right"></div>
        </v-ons-toolbar>

        <v-ons-toolbar v-show="hasChecked" inline="true"  >
            <div class="left">
                <v-ons-toolbar-button @click="uncheckAll" >
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center">Выбрано: {{ checkedCount }}</div>

            <div class="right">
                <v-ons-toolbar-button @click="checkAll" >
                    <v-ons-icon icon="md-check-all"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="deleteChecked" >
                    <v-ons-icon icon="md-delete"></v-ons-icon>
                </v-ons-toolbar-button>

                <v-ons-toolbar-button @click="restore" >
                    <v-ons-icon icon="md-undo"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <div v-show="positions.length > 0" class="table-positions" >
                <div class="tbody">
                    <div
                        v-for="(position, index) in positions"
                        class="position-wrap"
                        :id="'position-'+index"
                        :key="position.posId"
                    >
                        <div class="row-wrap">
                            <div class="col col-remove" >
                                <div class="remove-wrap" >
                                    <v-ons-checkbox v-model="position.isChecked" />
                                </div>
                            </div>

                            <div class="col col-name" @click="$router.push({name: 'product-list-history-product-view', params: {prd_id: position.id}})" >
                                <div class="name-wrap">
                                    {{ position.name }}
                                </div>
                            </div>

                            <div class="col col-unit" >
                                <div class="unit-wrap">
                                    {{position.unit}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="positions.length == 0" class="list-empty" :style="{height: $window.height - 56 + 'px'}" >
                История пуста
            </div>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            positions: [],
            loading: true,
            entrypoint: '/api/user-products',
        }
    },
    created() {
        if (this.$route.meta.userProductEntrypoint) {
            let userProductEntrypoint = this.$route.meta.userProductEntrypoint;
            this.entrypoint = userProductEntrypoint.replace('$userId', this.$route.params.user_id)
        }

        this.loadPositions();
    },
    methods: {
        checkAll() {
            this.positions.forEach(i => i.isChecked = true);
        },
        uncheckAll() {
            this.positions.forEach(i => i.isChecked = false);
        },
        loadPositions() {
            this.$http.get('/api/user-products/get-history').then(response => {
                this.positions = response.data.positions.map(i => { i.isChecked = false; return i; });
                this.loading = false;
            });
        },
        deleteChecked() {
            let removeds = this.positions
                .filter(i => i.isChecked)
                .map(i => i.id)

            this.positions.forEach(i => i.isChecked = false)

            if (removeds.length == 0) {
                return;
            }

            this.$http.post('/api/user-products/delete', {positions: removeds}).then(response => {
                this.loadPositions();

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Удалено '+ removeds.length +' позиции(-й)',
                    buttonLabel: 'ОТМЕНИТЬ'
                }).then(resp => {
                    if (resp === 0) {
                        this.$http.post('/api/user-products/undo-delete', {positions: removeds}).then(response => {
                            this.loadPositions();
                        });
                    }

                    removeds = [];
                });
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Не удалось удалить позиции.',
                });
            });
        },
        restore() {
            let checkeds = this.positions
                .filter(i => i.isChecked)
                .map(i => i.id);

            this.positions.forEach(i => i.isChecked = false);

            if (checkeds.length == 0) {
                return;
            }

            this.$http.post('/api/user-products/undo-delete-to-history', {positions: checkeds}).then(response => {
                this.loadPositions();
                this.$bus.$emit('productTab.loadPositions');

                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Восстановлено '+ checkeds.length +' позиции(-й)',
                });
            }, response => {
                this.$ons.notification.toast({
                    timeout: 4000,
                    message: 'Не удалось восстановить позиции.',
                });
            });
        },
    },
    computed: {
        hasChecked() {
            return this.checkedCount > 0;
        },
        checkedCount() {
            return this.positions
                .filter(i => i.isChecked)
                .length
        },
    }
};
</script>