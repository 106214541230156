import Users from '~/screen/manager/productAvail/user/Index';
import User from '~/screen/manager/productAvail/user/User';
import Product from '~/screen/manager/productAvail/user/Product';
import Products from '~/screen/manager/productAvail/product/Index';
import ProductsUsers from '~/screen/manager/productAvail/product/Users';
import ProductsProduct from '~/screen/manager/productAvail/product/Product';

let route = [
    {
        path: 'product-avail',
        name: 'manager-product-avail',
        component: Users,
        meta: {
            requiresLogin: true,
            requiredPermissions: ['manager'],
        },
        children: [
            {
                path: 'products',
                name: 'manager-product-avail-products',
                component: Products,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'storage-:storage_id',
                        name: 'manager-product-avail-products-storage',
                        component: Products,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                        children: [
                            {
                                path: ':product_id',
                                name: 'manager-product-avail-products-users',
                                component: ProductsUsers,
                                meta: {
                                    requiresLogin: true,
                                    requiredPermissions: ['manager'],
                                },
                                children: [
                                    {
                                        path: 'replace',
                                        name: 'manager-product-avail-products-product',
                                        component: ProductsProduct,
                                        params: true,
                                        meta: {
                                            requiresLogin: true,
                                            requiredPermissions: ['manager'],
                                        },
                                    },
                                ],
                            },
                        ]
                    },
                ],
            },
            {
                path: 'users',
                name: 'manager-product-avail-users',
                component: Users,
                meta: {
                    requiresLogin: true,
                    requiredPermissions: ['manager'],
                },
                children: [
                    {
                        path: 'storage-:storage_id',
                        name: 'manager-product-avail-users-storage',
                        component: Users,
                        meta: {
                            requiresLogin: true,
                            requiredPermissions: ['manager'],
                        },
                        children: [
                            {
                                path: ':user_id',
                                name: 'manager-product-avail-users-user',
                                component: User,
                                meta: {
                                    requiresLogin: true,
                                    requiredPermissions: ['manager'],
                                },
                                children: [
                                    {
                                        path: ':product_id',
                                        name: 'manager-product-avail-users-product',
                                        component: Product,
                                        meta: {
                                            requiresLogin: true,
                                            requiredPermissions: ['manager'],
                                        },
                                    },
                                ],
                            },
                        ]
                    },
                ]
            },
        ],
    },
];

export default route;