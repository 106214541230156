<template>
    <v-ons-page>
        <v-ons-toolbar inline="true" v-show="!isSearchActive" >
            <div class="left">
                <v-ons-back-button v-if="showBackButton" ></v-ons-back-button>
                <v-ons-toggle-sidebar-button v-else ></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">{{ title }}</div>

            <div class="right">
                <v-ons-toolbar-button @click="searchActive">
                    <v-ons-icon icon="md-search"></v-ons-icon>
                </v-ons-toolbar-button>

                <toolbar-popover-menu popover-class="stick-right" v-if="shouldShowViewParams || shouldShowCreateUserButton">
                    <toolbar-popover-menu-item
                        text="Опции просмотра"
                        icon="md-format-list-bulleted"
                        v-if="shouldShowViewParams"
                        @click="showViewParamsModal"/>

                    <toolbar-popover-menu-item
                        text="Создать пользователя"
                        icon="md-plus"
                        v-if="shouldShowCreateUserButton"
                        @click="createUserModal"/>
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <v-ons-toolbar inline="true" v-show="isSearchActive" >
            <div class="left">
                <v-ons-toolbar-button @click="searchDeactive">
                    <v-ons-icon icon="md-arrow-left"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>

            <div class="center" >
                <form action="" autocomplete="off" @submit.prevent="() => {}" >
                <input
                    id="search-input"
                    v-model="searchQuery"
                    style="
                        color: #fff;
                        background: none;
                        border: none;
                        height: 34px;
                        line-height: 34px;
                        border-bottom: 1px solid #fff; "
                    autocomplete="off"
                    name="input-search"
                />
                </form>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <ons-list v-show="users" >
                <ons-list-item
                    v-for="user in users"
                    :key="user.id"
                    modifier="chevron longdivider"
                    tappable
                    @click="$emit('choose', user)" >

                    <div class="left" style="min-width: 40px; text-align: center;" >
                        {{ user.id }}
                    </div>
                    <div class="center" >
                        <span class="list-item__title" >
                            {{ user.email }}
                            <span v-show="user.user_type === 'manager'" style="margin-left: 5px; color: #ff5a00" >M</span>
                            <span v-show="user.user_type === 'worker'" style="margin-left: 5px; color: #001fff" >W</span>
                        </span>

                        <v-list-item-subtitles :item="user" :fields="viewParams" :selected="$user.usersSelectedSubtitles" />
                    </div>
                </ons-list-item>

                <div style="text-align: center; margin: 10px 0;" v-show="page < pageCount" >
                    <v-ons-button @click="loadMore" style="min-width: 145px;" >
                        <span v-show="!loadingMore" >Показать ещё</span>
                        <v-ons-icon v-show="loadingMore" size="30px" spin icon="md-spinner"></v-ons-icon>
                    </v-ons-button>
                </div>
            </ons-list>

            <div v-show="users.length === 0" class="list-empty" :style="{height: $window.height - 56 + 'px'}" >
                Ничего не найдено
            </div>
        </div>

        <v-list-dialog ref="viewParamsModal" multiple="true" title="Опции просмотра" />
        <v-create-user-modal :storages="storages" ref="createUserModal" />
    </v-ons-page>
</template>

<script>

import {from, merge, of} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, filter, map, pluck, switchMap, tap} from 'rxjs/operators'

import ListDialog from '~/component/ListDialog'
import ListItemSubtitles from '~/component/ListItemSubtitles'
import CreateUserModal from './CreateUserModal'

export default {
    props: [
        'title',
        'url',
        'showBackButton',
        'shouldShowCreateUserButton',
        'shouldShowViewParams',
    ],
    data() {
        return {
            loading: false,
            loadingMore: false,
            isSearchActive: false,
            searchQuery: '',
            viewParams: [
                {
                    name: 'name',
                    label: 'Имя',
                },
                {
                    name: 'phone',
                    label: 'Телефон',
                },
                {
                    name: 'legal_names',
                    label: 'Юр. лицо',
                },
                {
                    name: 'actual_names',
                    label: 'Наименование',
                }
            ],
            storages: [],
            users: [],
            page: 1,
            pageCount: null,
            filters: {
                q: '',
            },
        }
    },
    created() {
        this.$emit('update');

        this.$http.get('/api/manager/storages').then(response => {
            this.storages = response.data;
        });
    },
    subscriptions () {
        const $q = this.$watchAsObservable('searchQuery').pipe(
            filter(() => this.searchActive),
            pluck('newValue'),
            map(q => q.trim()),
            filter(q => q.length > 2),
            debounceTime(1000),
            distinctUntilChanged(),

            tap((q) => {
                this.filters.q = q;
                this.loading = true;
                this.users = [];
                this.page = 1;
            })
        );

        const $searchDeactive = this.$eventToObservable('searchDeactive').pipe(
            filter(() => this.filters.q.length > 0),
            tap(() => {
                this.filters.q = '';
                this.loading = true;
                this.users = [];
                this.page = 1;
            })
        );

        const $loadMore = this.$watchAsObservable('page').pipe(
            tap(() => this.loadingMore = true),
        )

        const $update = this.$eventToObservable('update').pipe(
            debounceTime(100),
            tap(() => {
                this.loading = true;
                this.users = [];
                this.page = 1;
            })
        );

        return {
            results: merge($q, $searchDeactive, $update, $loadMore).pipe(
                switchMap(() => from(this.$http.get(this.url, {params: {filters: this.filters, page: this.page}}))),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of([]);
                }),
                map(response => {
                    this.users = this.users.concat(response.items);
                    this.pageCount = response.pageCount;
                }),
                tap(() => {
                    this.loadingMore = false;
                    this.loading = false;
                }),
            )
        }
    },
    methods: {
        loadMore() {
            if (this.page != null && this.pageCount != null && this.page > this.pageCount) {
                return;
            }

            if (this.loadingMore) {
                return;
            }

            this.page += 1;
        },
        showViewParamsModal() {
            let items = this.viewParams.map(i => {
                return {
                    id: i.name,
                    name: i.label,
                }
            });

            this.$refs.viewParamsModal.show({items: items, selected: this.$user.usersSelectedSubtitles}).then(selected => {
                this.$user.setUsersSelectedSubtitles(selected);
            });
        },
        createUserModal() {
            this.$refs.createUserModal.show();
        },
        searchActive() {
            this.isSearchActive = true;
            this.searchQuery = '';
            this.$nextTick(() => document.getElementById("search-input").focus());
        },
        searchDeactive() {
            this.isSearchActive = false;
            this.$emit('searchDeactive');
        }
    },
    components: {
        'v-list-dialog': ListDialog,
        'v-list-item-subtitles': ListItemSubtitles,
        'v-create-user-modal': CreateUserModal,
    },
};
</script>